<template>
    <div class="season-card-item">
        <div class="season-card-item__name">{{ props.season.name }}</div>
        <div class="season-card-item__period">{{ formatDate(props.season.startTime, 'Do MMMM') }} - {{
            formatDate(props.season.endTime, 'Do MMMM') }}</div>
        <div class="season-card-item__description">{{ props.season.description }}</div>
    </div>
</template>
<script setup lang="ts">
import { formatDate } from '@/composables/common/Util'
const props = defineProps(['season'])
</script>
<style>
.season-card-item {
    display: grid;
    grid-template-rows: 4rem 3rem 1fr;
    justify-content: space-evenly;
    border-radius: var(--default-border-rad);
    cursor: pointer;
    background-color: var(--bright-font-clr);
    aspect-ratio: 1;
    gap: 1rem;
    padding: 1rem;
    transition: ease-in-out transform 200ms;
    width: var(--season-card-item-width, 18em);
}
.season-card-item:hover {
  transform: scale(1.1);
}
.season-card-item__name {
  font-size: 1.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-align: center;
  text-wrap: balance;
}
.season-card-item__period {
  text-align: center;
}
.season-card-item__description {
  display: flex;
  align-items: center;
  text-align: center;
}
</style>