<template>
    <TheOrganizerDashboard :onUserLoad="onUserLoaded">
        <template #heading>
            <router-link :to="{ name: 'Organizations' }">
                <div class="page-heading">
                    Organizations
                </div>
            </router-link>
            <router-link :to="{ name: 'Organization Menu', params: { id: organization?.id } }">
                <div class="page-heading">
                    {{ organization?.name }}
                </div>
            </router-link>
            <router-link :to="{ name: 'Organization Members', params: { id: organization?.id } }">
                <div class="page-heading">
                    Members
                </div>
            </router-link>
            <router-link :to="{ name: 'Organization Invitations', params: { id: organization?.id } }">
                <div class="page-heading">
                    Invitations
                </div>
            </router-link>
        </template>
        <template #default>
            <div class="organizations-wrapper">
                <button v-if="false" @click="loadUsers(), invitePopup.openModal()" class="btn add-usr-btn invite-member-btn">
                    Invite Members
                </button>
                <button @click="inviteAnonymousPopup.openModal()" class="btn send-btn invite-member-btn">
                    Invite New User
                </button>
                <div class="organization-invitations-wrapper">
                    <template v-if="getOrganizationInvitations().length > 0" v-for="organizationInvitation in getOrganizationInvitations()">
                        <OrganizationInvitationItem @revoke="onRevoke(organizationInvitation)" @delete="onDelete(organizationInvitation)"
                            :invitation="organizationInvitation" />
                    </template>
                    <template v-else>
                        <div class="spz-system-message">
                            There are no invitations yet
                        </div>
                    </template>
                </div>
            </div>
            <StepBasedModal ref="invitePopup" @onApplyClick="onInviteFormSubmit" :start-step="inviteProcessStep"
                :action-process-total-steps="inviteProcessTotalSteps" :step-validators="stepValidators">
                <template #header>
                    <h1>Invite</h1>
                </template>
                <template #popup-step-1>
                    <div class="popup-step">
                        <div class="users-filters">
                            <div class="spz-text-input-group narrow">
                                <label for="search-term">Search:</label>
                                <input @input="onSearchTermInput" id="search-term" type="text" placeholder="Search">
                            </div>
                            <SportzoneSelect @selectOptionChange="onSportSelectChange" id="new-match-sport" v-if="organization"
                                    :options="['Sport', ...SPORT_TYPES]" selectedOption="Sport">
                            </SportzoneSelect>
                            <SportzoneSelect @selectOptionChange="onSelectNationality" id="nationality-filter"
                                v-model:selectedOption="nationalityFilter"
                                :options="['Nationality', ...nationalitiesComputed]">
                            </SportzoneSelect>
                        </div>
                        <div class="users-wrapper" @scroll="onUsersWrapperScroll">
                            <div @click="toggleUserItemSelect(user)"
                                v-for="user in getUsers(userSearchTerm, nationalityFilter)"
                                class="spz-user-item small-item badge-item selectable-item"
                                :class="{ 'selected-item': selectedUsers[user.id] }">
                                <div class="badge-item-col spz-user-item-name">
                                    <div class="badge-info-heading">
                                        Name
                                    </div>
                                    <div class="badge-info-value">
                                        {{ user.firstName }} {{ user.lastName }}
                                    </div>
                                </div>
                                <div class="badge-item-col spz-user-item-nat">
                                    <div class="badge-info-heading">
                                        Nationality
                                    </div>
                                    <div class="badge-info-value">
                                        {{ user.nationality }}
                                    </div>
                                </div>
                                <div class="badge-item-col spz-user-item-email">
                                    <div class="badge-info-heading">
                                        Sex
                                    </div>
                                    <div class="badge-info-value">
                                        {{ user.sex }}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </template>
                <template #popup-step-2>
                    <div class="popup-step">
                        <div class="invitation-details">
                            <div class="spz-text-input-group">
                                <label for="invite-message">Message</label>
                                <textarea id="invite-message" v-model="newInvitation.message" type="text"
                                    placeholder="Invitation message" required />
                                <div v-show="!validMessage" class="spz-form-err-msg">
                                    Please provide a message.
                                </div>
                            </div>
                            <div class="spz-checkbox-group">
                                <input id="send-emails" v-model="sendInvitesAsEmails" type="checkbox" />
                                <label for="send-emails">Send as Email</label>
                            </div>
                        </div>
                    </div>
                </template>
                <template #action-btn>
                    <button class="btn send-btn">Invite</button>
                </template>
            </StepBasedModal>
            <StepBasedModal ref="inviteAnonymousPopup" @onApplyClick="onInviteAnonymousFormSubmit" :start-step="inviteAnonymousProcessStep"
                :action-process-total-steps="inviteAnonymousProcessTotalSteps" :step-validators="anonymousStepValidators">
                <template #header>
                    <h1>Invite New User</h1>
                </template>
                <template #popup-step-1>
                    <div class="popup-step">
                        <div class="spz-text-input-group narrow">
                                <label for="inviteeFirstName">First Name:</label>
                                <input id="inviteeFirstName" v-model="newInvitation.inviteeFirstName" type="text" placeholder="First Name">
                            </div>
                            <div class="spz-text-input-group narrow">
                                <label for="inviteeLastName">Last Name:</label>
                                <input  id="inviteeLastName" v-model="newInvitation.inviteeLastName" type="text" placeholder="Last Name">
                            </div>
                            <div class="spz-text-input-group narrow" v-if="false">
                                <label for="inviteeNationality">Nationality:</label>
                                <input id="inviteeNationality" v-model="newInvitation.inviteeNationality" type="text" placeholder="Nationality">
                            </div>
                            <div class="spz-select-group narrow">
                                <label for="nationality">Nationality:</label>
                                <SportzoneSelect @selectOptionChange="onSelectNationality" id="nationality"
                                    v-model:selectedOption="newInvitation.inviteeNationality"
                                    :options="['Nationality', ...nationalitiesComputed]">
                                </SportzoneSelect>
                            </div>
                            <div class="spz-text-input-group narrow">
                                <label for="inviteeEmail">Email:</label>
                                <input  id="inviteeEmail" v-model="newInvitation.email" type="text" placeholder="Email">
                            </div>
                            <div class="invitation-details">
                            <div class="spz-text-input-group">
                                <label for="invite-message">Message</label>
                                <textarea id="invite-message" v-model="newInvitation.message" type="text" rows="5"
                                    placeholder="Invitation message" required />
                                <div v-show="!validMessage" class="spz-form-err-msg">
                                    Please provide a message.
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template #action-btn>
                    <button class="btn send-btn">Invite</button>
                </template>
            </StepBasedModal>
        </template>
    </TheOrganizerDashboard>
</template>
<script lang="ts">
import { createNamespacedHelpers, useStore } from 'vuex';
import { defineComponent, Ref, ref, computed, onMounted } from 'vue';
import TheOrganizerDashboard from '@/components/main/TheOrganizerDashboard.vue';
import OrganizationInvitationItem from '@/components/main/OrganizationInvitationItem.vue';
import StepBasedModal from '@/components/main/StepBasedModal.vue';
import SportzoneSelect from '@/components/util/SportzoneSelect.vue';
import { key } from '@/store';
import GenericDictionary from '@/components/util/GenericDictionary';
import { User } from '@/models/main/User';
import { useRoute } from 'vue-router';
import { Organization } from '@/models/main/Organization';
import { OrganizationInvitation, INVITE_STATUSES } from '@/models/main/OrganizationInvitation';
import '@vuepic/vue-datepicker/dist/main.css';
import { SPORT_TYPES } from '@/models/main/CommonTypes';
const nationalities = require('i18n-nationality');
nationalities.registerLocale(require('i18n-nationality/langs/en.json'));
const organizationInvitationStoreHelpers = createNamespacedHelpers('organizationInvitationStore');
const userStoreHelpers = createNamespacedHelpers('userStore');
export default defineComponent({
    components: {
        TheOrganizerDashboard,
        OrganizationInvitationItem,
        StepBasedModal,
        SportzoneSelect
    },
    computed: {
        ...organizationInvitationStoreHelpers.mapGetters(
            ['getOrganizationInvitations']
        ),
        ...userStoreHelpers.mapGetters(
            ['getUsers']
        )
    },
    setup() {
        const store = useStore(key);
        const selectedUsers: Ref<GenericDictionary<User>> = ref({});
        const now = new Date();
        const route = useRoute();
        const organizationId: Ref<number> = ref(parseInt(route.params.id as string));
        const validForDays = 14;
        const validUntil = new Date(now.getTime() + (validForDays * 24 * 60 * 60 * 1000));
        const userSearchTerm: Ref<string | undefined> = ref();
        const nationalityFilter: Ref<string> = ref('Nationality');
        const organization: Ref<Organization | undefined> = ref();
        const invitePopup: Ref<any> = ref(null);
        const inviteAnonymousPopup: Ref<any> = ref(null);
        const inviteProcessStep: Ref<number> = ref(1);
        const inviteProcessTotalSteps = ref(2);
        const inviteAnonymousProcessTotalSteps = ref(1);
        const inviteAnonymousProcessStep = ref(1);
        const stepValidators: Array<Function> = [
            () => {
                return true;
            },
            () => {
                return true;
            }
        ];

        const anonymousStepValidators: Array<Function> = [() => { return true }]
        const defaultInvitation: OrganizationInvitation = {
            id: 0,
            dateCreated: now,
            message: '',
            organizationId: organizationId.value,
            userId: 0,
            user: null,
            status: INVITE_STATUSES[0],
            validUntil: validUntil
        };
        const newInvitation: Ref<OrganizationInvitation> = ref(defaultInvitation);
        const validMessage: Ref<boolean> = ref(true);
        const sendInvitesAsEmails: Ref<boolean> = ref(false);
        const onUserLoaded = (user: User) => {
        };
        const loadUsers = async (searchTerm?: string, nationality?: string) => {
            await store.dispatch('userStore/listUsers', {
                searchTerm,
                nationality
            });
        };
        const onRevoke = async (invitation: OrganizationInvitation) => {
            invitation.status = INVITE_STATUSES[2];
            await store.dispatch('organizationInvitationStore/editOrganizationInvitation', invitation);
        };

        const onDelete = async (invitation: OrganizationInvitation) => {
            await store.dispatch('organizationInvitationStore/deleteOrganizationInvitation', invitation)
        }
        const onInviteFormSubmit = async () => {
            const createInvitationCalls = [];
            for (let userId in selectedUsers.value) {
                const user = selectedUsers.value[userId];
                const invitation: OrganizationInvitation = {
                    id: 0,
                    dateCreated: now,
                    message: newInvitation.value.message,
                    organizationId: organizationId.value,
                    userId: user?.id ?? undefined,
                    user: null,
                    status: INVITE_STATUSES[0],
                    validUntil: validUntil
                };
                createInvitationCalls.push(store.dispatch('organizationInvitationStore/createOrganizationInvitation', invitation));
            }
            await Promise.all(createInvitationCalls);
        };

        const onInviteAnonymousFormSubmit = async () => {
            const invitation: OrganizationInvitation = {
                    id: 0,
                    dateCreated: now,
                    message: newInvitation.value.message,
                    organizationId: organizationId.value,
                    user: null,
                    status: INVITE_STATUSES[0],
                    validUntil: validUntil,
                    email: newInvitation.value.email,
                    inviteeFirstName: newInvitation.value.inviteeFirstName,
                    inviteeLastName: newInvitation.value.inviteeLastName,
                    inviteeNationality: newInvitation.value.inviteeNationality
            };

            await store.dispatch('organizationInvitationStore/createOrganizationInvitation', invitation)
        }
        const onSearchTermInput = async (event: any) => {
            userSearchTerm.value = event.target.value;
            if (userSearchTerm.value !== undefined && (userSearchTerm.value.length >= 3 || userSearchTerm.value.length === 0)) {
                selectedUsers.value = {};
                loadUsers(userSearchTerm.value, nationalityFilter.value);
            }
        };
        const onSelectNationality = () => {
            selectedUsers.value = {};
            loadUsers(userSearchTerm.value, nationalityFilter.value);
        };
        const toggleUserItemSelect = (user: User) => {
            if (user && user.id) {
                if (selectedUsers.value[user.id]) {
                    delete selectedUsers.value[user.id];
                    return;
                }
                selectedUsers.value[user.id] = user;
            }
        };
        const nationalitiesComputed = computed(() => {
            const list = nationalities.getNames('en');
            const result = Object
                .keys(list)
                .map((key) => (list[key]));
            return result;
        });
        const loadOrganizationInvitations = async () => {
            organization.value = await store.dispatch('organizationStore/getOrganization', organizationId.value);
            const invitations = await store.dispatch('organizationInvitationStore/fetchOrganizationInvitations', organizationId.value);
        };

        let scrollTimeout: any;
        let loadInProcess: Ref<boolean> = ref(false);
        const onUsersWrapperScroll = (event: any) => {
            const totalScrollHeight = event.target.scrollHeight;
            const scrollTop = event.target.scrollTop;
            const offsetHeight = event.target.offsetHeight;
            const margin = 20;
            let bottomOfWindow = totalScrollHeight - scrollTop - offsetHeight < margin;
            if (bottomOfWindow) {
                if (scrollTimeout) {
                    clearTimeout(scrollTimeout);
                }
                scrollTimeout = setTimeout(onScrollStopped, 200);
            }
        };
        const onScrollStopped = () => {
            if (!loadInProcess.value) {
                loadInProcess.value = true;
                loadUsers(userSearchTerm.value, nationalityFilter.value)
                    .then(() => {
                        loadInProcess.value = false;
                    });
            }
        };

        const onSportSelectChange = async() => {
            selectedUsers.value = {};
            loadUsers(userSearchTerm.value, nationalityFilter.value);
        }
        onMounted(loadOrganizationInvitations)
        return {
            selectedUsers,
            organizationId,
            userSearchTerm,
            nationalityFilter,
            organization,
            invitePopup,
            inviteProcessStep,
            inviteProcessTotalSteps,
            stepValidators,
            newInvitation,
            validMessage,
            sendInvitesAsEmails,
            nationalitiesComputed,
            SPORT_TYPES,
            inviteAnonymousPopup,
            inviteAnonymousProcessStep,
            inviteAnonymousProcessTotalSteps,
            anonymousStepValidators,
            onInviteAnonymousFormSubmit,
            onSportSelectChange,
            onUsersWrapperScroll,
            onUserLoaded,
            loadUsers,
            onRevoke,
            onInviteFormSubmit,
            onSearchTermInput,
            onSelectNationality,
            toggleUserItemSelect,
            onDelete
        };
    }
});
</script>
<style>
.organization-invitations-wrapper {
    margin-bottom: 2em;
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
}

.users-wrapper {
    width: 100%;
    padding: 0 0.2em;
    height: 68vh;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
}

.spz-user-item {
    cursor: pointer;
    --nationality-width: 12em;
}

.spz-user-item-name,
.spz-user-item-email {
    width: calc((100% - var(--nationality-width))/ 2);
}

.spz-user-item-nat {
    width: var(--nationality-width);
}

.invitation-details {
    width: 100%;
}
</style>