import { IRootState, key } from '@/store';
import { Store, useStore } from 'vuex';
import { Container, CONTAINER_STATUSES } from '@/models/main/Container';
import { computed, Ref } from '@vue/runtime-core';
import { Entity } from '@/models/main/Entity';
import Livestream from '@/models/main/Livestream';
import { Player } from '@/models/main/Player';
import GenericDictionary from '@/components/util/GenericDictionary';
import { Event, EventType } from '@/models/main/Event';
import { ref } from 'vue';
import { addAccessKeyQueryParam } from '../common/Util';
import { useRoute } from 'vue-router';
import userContainerOfflineEventManipulations from './UseContainerOfflineEventManipulations';

export default function useCurrentContainerComputeds(currentContainerId: Ref<number>) {

    const store = useStore(key);
    const advancedEditEnabled: Ref<boolean> = ref(false)
    const playerCurrentUnixTime: Ref<number | undefined> = ref() 

    const getCurrentContainer = computed((): Container | undefined => {
        return store.getters['containerStore/getContainer'](currentContainerId.value)
    })

    const getCurrentHomeEntity = computed((): Entity | undefined => {
        return store.getters['containerStore/getHomeEntity'](currentContainerId.value)
    })

    const getCurrentAwayEntity = computed((): Entity | undefined => {
        const awayEntity = store.getters['containerStore/getAwayEntity'](currentContainerId.value)
        return awayEntity
    })

    const getCurrentContainerEvents = computed((): GenericDictionary<Event> | undefined => {
        return store.getters['containerEventStore/getEvents'](currentContainerId.value)
    })

    const getCurrentHomePlayersOrdered = computed((): Player[] | undefined => {
        const homePlayersDictionary: GenericDictionary<Player> | undefined = getCurrentHomeEntity.value?.players
        if(homePlayersDictionary !== undefined) {
            let homePlayers: Player[] = []
            for(const playerKey in homePlayersDictionary) {
                const player = homePlayersDictionary[playerKey]
                homePlayers.push(player)
            }

            return homePlayers.sort((a, b) => {
                return (a.number ?? 0) - (b.number ?? 0)
            })
        }
    })

    const getCurrentAwayPlayersOrdered = computed((): Player[] | undefined => {
        const awayPlayersDictionary: GenericDictionary<Player> | undefined = getCurrentAwayEntity.value?.players
        if(awayPlayersDictionary !== undefined) {
            let awayPlayers: Player[] = []
            for(const playerKey in awayPlayersDictionary) {
                const player = awayPlayersDictionary[playerKey]
                awayPlayers.push(player)
            }

            return awayPlayers.sort((a, b) => {
                return (a.number ?? 0) - (b.number ?? 0)
            })
        }
    })
    
    const route = useRoute()
    const infoPopUp: Ref<any> = ref(null);
    let accessKey: string | undefined = route.query?.accessKey as string;
    const shareLink: Ref<string | null> = ref(null);
    const onShareLinkButtonClicked = async () => {
        if (currentContainerId.value) {
            accessKey = await store.dispatch('containerStore/createOperateAccess', currentContainerId.value);
            if (accessKey !== undefined) {
                shareLink.value = addAccessKeyQueryParam(route.query, accessKey);
            }
            infoPopUp.value.openModal();
        }
    };

    const {
        offlineDataDownloadLinkUri,
        createEvent,
        addEvent,
        editEvent,
        addRemoveLastEventToActionsQueue,
        addRemoveEventToActionsQueue,
    } = userContainerOfflineEventManipulations(getCurrentContainer, accessKey)

    const formIsDisabled: Ref<boolean> = ref(false)
    const loadContainer = async () => {
        if (currentContainerId.value) {
            const container = await store.dispatch('containerStore/getPublicContainer', currentContainerId.value)
            formIsDisabled.value = container?.status === CONTAINER_STATUSES[2];
        }

    }

    const onOpenMatch = async () => {
        if (getCurrentContainer.value !== undefined) {
            getCurrentContainer.value.status = CONTAINER_STATUSES[1];
            formIsDisabled.value = getCurrentContainer.value.status === CONTAINER_STATUSES[2];
            if (getCurrentContainer.value.livestreams && Object.prototype.hasOwnProperty.call(getCurrentContainer.value.livestreams, 0)) {
                store.dispatch('livestreamStore/startLivestream', getCurrentContainer.value.livestreams[0].id)
            }

            await store.dispatch('containerStore/editContainer', { container: getCurrentContainer.value, homeEntityId: getCurrentHomeEntity.value?.id, awayEntity: getCurrentAwayEntity.value?.id });
        }
    };
    const onReopenMatch = async () => {
        if (getCurrentContainer.value !== undefined) {
            getCurrentContainer.value.status = CONTAINER_STATUSES[1];
            formIsDisabled.value = getCurrentContainer.value.status === CONTAINER_STATUSES[2];
            if (getCurrentContainer.value.livestreams && Object.prototype.hasOwnProperty.call(getCurrentContainer.value.livestreams, 0)) {
                store.dispatch('livestreamStore/startLivestream', getCurrentContainer.value.livestreams[0].id)
            }
            await store.dispatch('containerStore/editContainer', { container: getCurrentContainer.value, homeEntityId: getCurrentHomeEntity.value?.id, awayEntity: getCurrentAwayEntity.value?.id });
        }
    };
    const onCompleteMatch = async (callback: () => Promise<any>) => {
        if (getCurrentContainer.value !== undefined) {
            getCurrentContainer.value.status = CONTAINER_STATUSES[2];
            formIsDisabled.value = getCurrentContainer.value.status === CONTAINER_STATUSES[2];
            await callback()
            if (getCurrentContainer.value.livestreams && Object.prototype.hasOwnProperty.call(getCurrentContainer.value.livestreams, 0)) {
                store.dispatch('livestreamStore/stopLivestream', getCurrentContainer.value.livestreams[0].id)
            }
            await store.dispatch('containerStore/editContainer', { container: getCurrentContainer.value, homeEntityId: getCurrentHomeEntity.value?.id, awayEntity: getCurrentAwayEntity.value?.id });
        }
    };

    const onReverseClick = async () => {
        if (getCurrentContainer.value !== undefined && getCurrentContainer.value.id) {
            formIsDisabled.value = true
            const result = addRemoveLastEventToActionsQueue(getCurrentContainer.value.id)
            formIsDisabled.value = false
            return result
        }
    }

    const removeEvent = (eventId: number) => {
        addRemoveEventToActionsQueue(eventId);
    }

    const onEventEdit = (event: Event) => {
        if (selectedEventToEdit.value?.id === event.id) {
            selectedEventToEdit.value = undefined
        } else {
            selectedEventToEdit.value = event
        }
    }

    const selectedHomePlayerId: Ref<number> = ref(0)
    const selectedAwayPlayerId: Ref<number> = ref(0)
    const selectedEventToEdit: Ref<Event | undefined> = ref()

    const toggleHomePlayerSelect = (playerId: number) => {
        if (selectedHomePlayerId.value === playerId) {
            selectedHomePlayerId.value = 0
        } else {
            selectedHomePlayerId.value = playerId
        }
    }
    const toggleAwayPlayerSelect = (playerId: number) => {
        if (selectedAwayPlayerId.value === playerId) {
            selectedAwayPlayerId.value = 0
        } else {
            selectedAwayPlayerId.value = playerId
        }
    }
    const onHomePointBoxClick = async (pointTypeValue: string, pointType: EventType = "Point") => {
        if (getCurrentHomeEntity.value?.id !== undefined && currentContainerId.value !== undefined) {
            const event = createEvent(currentContainerId.value, pointType, getCurrentHomeEntity.value.id, pointTypeValue,
                selectedHomePlayerId.value !== 0 ? selectedHomePlayerId.value : undefined,
                selectedEventToEdit.value ? selectedEventToEdit.value : undefined)
            let success = false;
            if(selectedEventToEdit.value?.id) { // edit
                success = await editEvent(event)
            } else {
                success = await addEvent(event)
            }
            if (success) {
                toggleHomePlayerSelect(0)
            }
        }
    }
    const onAwayPointBoxClick = async (pointTypeValue: string, pointType: EventType = "Point") => {
        if (getCurrentAwayEntity.value?.id !== undefined && currentContainerId.value !== undefined) {
            const event = createEvent(currentContainerId.value, pointType, getCurrentAwayEntity.value.id, pointTypeValue,
            selectedAwayPlayerId.value !== 0 ? selectedAwayPlayerId.value : undefined,
                selectedEventToEdit.value ? selectedEventToEdit.value : undefined)
            let success = false;
            if(selectedEventToEdit.value?.id) { // edit
                success = await editEvent(event)
            } else {
                success = await addEvent(event)
            }

            if (success) {
                toggleAwayPlayerSelect(0)
            }
        }
    }
    

    return {
        getCurrentContainer,
        getCurrentHomeEntity,
        getCurrentAwayEntity,
        getCurrentContainerEvents,
        getCurrentHomePlayersOrdered,
        getCurrentAwayPlayersOrdered,
        formIsDisabled,
        onOpenMatch,
        onReopenMatch,
        onCompleteMatch,
        loadContainer,
        onShareLinkButtonClicked,
        accessKey,
        infoPopUp,
        shareLink,
        offlineDataDownloadLinkUri,
        onReverseClick,
        createEvent,
        addEvent,
        editEvent,
        removeEvent,
        onEventEdit,
        addRemoveLastEventToActionsQueue,
        addRemoveEventToActionsQueue,
        onHomePointBoxClick,
        onAwayPointBoxClick,
        toggleHomePlayerSelect,
        toggleAwayPlayerSelect,
        selectedHomePlayerId,
        selectedAwayPlayerId,
        selectedEventToEdit,
        advancedEditEnabled,
        playerCurrentUnixTime
    }
}