import DefaultHttpClient from "@/components/util/DefaultHttpClient";
import DefaultWebsocketClient from "@/components/util/DefaultWebsocketClient";
import { Method } from "axios";

export default class ConnectionManager {
    private _webSocketClient: DefaultWebsocketClient
    private _httpClient: DefaultHttpClient
    private readonly serverURI: string = 'https://bowlzone.azurewebsites.net'

    get webSocketClient(): DefaultWebsocketClient {
        return this._webSocketClient;
    }

    get httpClient() {
        return this._httpClient;
    }

    constructor(userId: string) {
        this._httpClient = new DefaultHttpClient(this.serverURI);
        this._webSocketClient = new DefaultWebsocketClient(this.serverURI, userId);
    }

    httpSend(url: string, data: any, httpMethod: Method) {
        return this._httpClient.send(url, data, httpMethod);
    }

}