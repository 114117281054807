import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "org-dashboard-wrapper" }
const _hoisted_2 = { class: "nav-history" }
const _hoisted_3 = {
  key: 0,
  class: "page-heading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheNavigationBar = _resolveComponent("TheNavigationBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TheNavigationBar, {
      user: _ctx.getUser,
      navigationItems: _ctx.navigationItems
    }, null, 8, ["user", "navigationItems"]),
    _createElementVNode("main", {
      class: "organizer-main-container",
      onScroll: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onScroll && _ctx.onScroll(...args)))
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.title)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.title), 1))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "heading")
      ]),
      _renderSlot(_ctx.$slots, "default")
    ], 32)
  ]))
}