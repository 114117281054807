import DataModelMapper from "@/components/util/DataModelMapper";
import GenericDictionary from "@/components/util/GenericDictionary";
import { Sport } from "@/models/main/CommonTypes";
import { Container, ContainerStatus, ContainerType } from "@/models/main/Container";
import DataModel from "@/models/main/DataModel";
import EntityContainerDataModel from "./EntityContainerDataModel";
import EventDataModel from "./EventDataModel";
import LiveDataModel from "./LiveDataModel";
import OrganizationDataModel from "./OrganizationDataModel";
import Season from "@/models/main/Season";

export class ContainerDataModel extends DataModel implements Container {
    public static readonly MODEL_NAME: string = "Container"
    
    private _id?: number;
    private _title?: string;
    private _type?: ContainerType;
    private _status?: ContainerStatus
    private _startTime?: string;
    private _endTime?: string;
    private _sport?: Sport
    private _events: GenericDictionary<EventDataModel> = {};
    private _livestreams: GenericDictionary<LiveDataModel> = {};
    private _entityContainers: GenericDictionary<EntityContainerDataModel> = {};
    private _organization: OrganizationDataModel = new OrganizationDataModel()
    private _seasons: Season[] = []

    // #region Fields Keys
    static get IDKey() {
        return "id";
    }

    static get titleKey() {
        return "title";
    }

    static get typeKey() {
        return "type";
    }

    static get statusKey() {
        return "status";
    }

    static get startTimeKey() {
        return "startTime";
    }

    static get endTimeKey() {
        return "endTime";
    }

    static get sportKey() {
        return "spot";
    }

    static get eventsKey() {
        return "events";
    }

    static get livestreamsKey() {
        return "livestreams";
    }

    static get entityContainersKey() {
        return "entityContainers";
    }

    static get organizationKey() {
        return "organization";
    }

    // #endregion

    // #region accessors/mutations 
    get id() {
        return this._id;
    }

    set id(id) {
        this._id = id;
    }

    get title() {
        return this._title;
    }

    set title(title) {
        this._title = title;
    }

    get type() {
        return this._type;
    }

    set type(type) {
        this._type = type;
    }

    get status() {
        return this._status;
    }

    set status(status) {
        this._status = status;
    }

    get startTime() {
        return this._startTime;
    }

    set startTime(startTime) {
        this._startTime = startTime;
    }

    get endTime() {
        return this._endTime;
    }

    set endTime(endTime) {
        this._endTime = endTime;
    }

    get sport() {
        return this._sport;
    }

    set sport(sport) {
        this._sport = sport;
    }

    /**
     *
     * @returns {E[]}
     */
    get events() {
        return this._events;
    }

    set events(events) {
        this._events = events;
    }

    /**
     *
     * @returns {Live[]}
     */
    get livestreams() {
        return this._livestreams;
    }

    set livestreams(livestreams) {
        this._livestreams = livestreams;
    }

    /**
     *
     * @returns {En[]}
     */
    get entities() {
        return this._entityContainers;
    }

    set entities(entityContainers) {
        this._entityContainers = entityContainers;
    }

    get organization() {
        return this._organization
    }

    set organization(organization) {
        this._organization = organization
    }

    get seasons() {
        return this._seasons
    }

    set seasons(seasons: Season[]) {
        this._seasons = seasons;
    }

    //#endregion

    constructor(modelID?: string, modelData?: any) {
        super();
        this.parseMinifiedData(modelData);
        if(this.id !== undefined) {
            const modelId = DataModel.generateModelID(this, {"id": this.id});
            if(modelId !== false) {
                this.modelID = modelId
            }
        }
    }


    // #region FieldMappings Getters/Setters

    dataModelName() {
        return ContainerDataModel.MODEL_NAME
    }

    getKeyByMinifiedKey(minKey: string ) {
        switch (minKey) {
            case "1":
                return ContainerDataModel.IDKey;
            case "2":
                return ContainerDataModel.titleKey;
            case "3":
                return ContainerDataModel.typeKey;
            case "4":
                return ContainerDataModel.statusKey;
            case "5":
                return ContainerDataModel.startTimeKey;
            case "6":
                return ContainerDataModel.endTimeKey;
            case "7":
                return ContainerDataModel.sportKey;
            case "8":
                return ContainerDataModel.eventsKey;
            case "9":
                return ContainerDataModel.livestreamsKey;
            case "10":
                return ContainerDataModel.entityContainersKey;
            case "11":
                return ContainerDataModel.organizationKey;
            default:
                return ""
        }
    }

    getProperty(propertyKey: string) {
        switch (propertyKey) {
            case ContainerDataModel.IDKey:
                return this.id;
            case ContainerDataModel.titleKey:
                return this.title;
            case ContainerDataModel.typeKey:
                return this.type;
            case ContainerDataModel.statusKey:
                return this.status;
            case ContainerDataModel.startTimeKey:
                return this.startTime;
            case ContainerDataModel.endTimeKey:
                return this.endTime;
            case ContainerDataModel.sportKey:
                return this.sport;
            case ContainerDataModel.eventsKey:
                return this.events;
            case ContainerDataModel.livestreamsKey:
                return this.livestreams;
            case ContainerDataModel.entityContainersKey:
                return this._entityContainers;
            case ContainerDataModel.organizationKey:
                return this.organization;
        }
    }

    setProperty(propertyKey: string, value: any) {
        let livestreams = null;
        let entityContainers = null;
        let events = null;
        switch (propertyKey) {
            case ContainerDataModel.IDKey:
                this.id = value;
                break;
            case ContainerDataModel.titleKey:
                this.title = value;
                break;
            case ContainerDataModel.typeKey:
                this.type = value;
                break;
            case ContainerDataModel.statusKey:
                this.status = value;
                break;
            case ContainerDataModel.startTimeKey:
                this.startTime = value;
                break;
            case ContainerDataModel.endTimeKey:
                this.endTime = value;
                break;
            case ContainerDataModel.sportKey:
                this.sport = value;
                break;
            case ContainerDataModel.eventsKey:
                events = value;
                for (const eventKey in events) {
                    if (Object.prototype.hasOwnProperty.call(events, eventKey)) {
                        const event = events[eventKey];
                        let eventModel = null;
                        if (event instanceof DataModel) {
                            eventModel = event;
                        } else if(event !== null) {
                            eventModel = DataModelMapper.createFromObject(event);
                        }
                        if (eventModel instanceof EventDataModel) {
                            this.events[eventKey] = eventModel;
                        } else if (event === null) {
                            delete this.events[eventKey];
                        }
                    }
                }
                break;
            case ContainerDataModel.livestreamsKey:
                livestreams = value;
                for (const livestreamKey in livestreams) {
                    if (Object.prototype.hasOwnProperty.call(livestreams, livestreamKey)) {
                        const livestream = livestreams[livestreamKey];
                        let livestreamModel = null;
                        if (livestream instanceof DataModel) {
                            livestreamModel = livestream;
                        } else if(livestream !== null) {
                            livestreamModel = DataModelMapper.createFromObject(livestream);
                        }
                        if (livestreamModel instanceof LiveDataModel) {
                            this.livestreams[livestreamKey] = livestreamModel;
                        } else if (livestream === null) {
                            delete this.livestreams[livestreamKey];
                        }
                    }
                }
                break;
            case ContainerDataModel.entityContainersKey:
                entityContainers = value;
                for (const entitiesKey in entityContainers) {
                    if (Object.prototype.hasOwnProperty.call(entityContainers, entitiesKey)) {
                        const entity = entityContainers[entitiesKey];
                        let entityModel = null;
                        if (entity instanceof DataModel) {
                            entityModel = entity;
                        } else if(entity !== null) {
                            entityModel = DataModelMapper.createFromObject(entity);
                        }

                        if (entityModel instanceof EntityContainerDataModel) {
                            this.entities[entitiesKey] = entityModel;
                        } else if (entity === null) {
                            delete this.entities[entitiesKey];
                        }
                    }
                }
                break;
            case ContainerDataModel.organizationKey:
                let organizationModel = null;
                if (value instanceof DataModel) {
                    organizationModel = value;
                } else if(value !== null) {
                    organizationModel = DataModelMapper.createFromObject(value);
                }

                if (organizationModel instanceof OrganizationDataModel) {
                    this.organization = organizationModel;
                } else if (value === null) {
                    this.organization = value
                }
                
                break;
        }
    }

    //#endregion
}