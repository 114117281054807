<template>
    <TheOrganizerDashboard>
        <div class="controls">
            <button @click="$refs.cameraPopup.openModal()" class="controls__create-camera-btn btn add-btn"
                :title="!selectedStation ? 'Select Station' : 'Create'" :disabled="!selectedStation">Camera</button>
            <a target="_blank"
                href="https://sportzonestorageaccount.blob.core.windows.net/sportzone-station-download/Sportzone Station.zip"
                class="btn download-btn">Download Station</a>
        </div>
        <div class="stations-and-cameras-wrapper">
            <div class="stations-list">
                <template v-if="getUser.stations">
                    <div @click="selectStation(station.id)" class="stations-list__item"
                        :class="{ selected: station.id === selectedStation }" v-for="station in getUser.stations"
                        :key="station.id">
                        <div class="stations-list__item_name">{{ station.name }}</div>
                        <div class="stations-list__item_controls">
                            <button class="stations-list__item_controls_delete btn delete-btn" title="Delete"
                                role="button">Delete</button>
                        </div>
                    </div>
                </template>
            </div>
            <div class="cameras-list">
                <div class="cameras-list__item" :class="camera.status" v-for="camera in this.getStationById"
                    :key="camera.id">
                    <div class="cameras-list__item_name">{{ camera.name }}</div>
                    <div class="cameras-list__item_controls">
                        <button @click="deleteCamera(camera.id)" class="cameras-list__item_controls_delete btn delete-btn"
                            title="Delete">Delete</button>
                    </div>
                </div>
            </div>
        </div>
        <Modal ref="cameraPopup">
            <template v-slot:header>
                <h1>Create Camera</h1>
            </template>
            <template v-slot:body>
                <div class="popup__input-group spz-text-input-group">
                    <label for="camera-name">Name</label>
                    <input id="camera-name" v-model="newCamera.name" placeholder="Name" />
                </div>
                <div class="popup__input-group spz-text-input-group">
                    <label for="camera-input">Input Link</label>
                    <input id="camera-input" v-model="newCamera.inputLink" placeholder="Input Link" />
                </div>
                <div class="popup__input-group spz-checkbox">
                    <input id="hasAudio" v-model="newCamera.hasAudio" type="checkbox" />
                    <label for="hasAudio">Audio</label>
                </div>
                <div class="popup__input-group spz-checkbox">
                    <input id="isManual" v-model="isManual" type="checkbox" />
                    <label for="isManual">Manual</label>
                </div>
                <div class="popup__input-group spz-text-input-group" v-if="isManual">
                    <label for="new-endpoint-name">Endpoint Name</label>
                    <input id="new-endpoint-name" v-model="newEndpoint.name" placeholder="Endpoint Name" />
                </div>
                <div class="popup__input-group spz-text-input-group" v-if="isManual">
                    <label for="new-endpoint-digest">Digest Input</label>
                    <input id="new-endpoint-digest" v-model="newEndpoint.digestEndpoint"
                        placeholder="Stream URL + Stream key" />
                </div>
                <div class="popup__input-group spz-select-group" v-if="isManual">
                    <label for="new-endpoint-quality">Max Quality</label>
                    <select id="new-endpoint-quality" v-model="newEndpoint.maxQuality">
                        <option disabled value="">Please select one</option>
                        <option v-for="quality in STREAM_QUALITIES" :key="quality" :value="quality">{{ quality }}</option>
                    </select>
                </div>
                <div class="popup__input-group spz-select-group" v-if="isManual">
                    <label for="new-endpoint-channel">Channel</label>
                    <select id="new-endpoint-channel" v-model="newEndpoint.channelId">
                        <option disabled value="">Please select one</option>
                        <option v-for="channel in getUserChannels" :key="channel.id" :value="channel.id">{{ channel.name }}
                        </option>
                    </select>
                </div>
            </template>
            <template v-slot:footer>
                <div>
                    <button @click="$refs.cameraPopup.closeModal(), saveCamera()" class="btn add-btn">Create</button>
                    <button @click="$refs.cameraPopup.closeModal()" class="btn btn-inverse">Cancel</button>
                </div>
            </template>
        </Modal>
    </TheOrganizerDashboard>
</template>
<script lang="ts">
import TheOrganizerDashboard from '@/components/main/TheOrganizerDashboard.vue';
import Modal from '@/components/main/Modal.vue';
import { createNamespacedHelpers, useStore } from 'vuex';
import { defineComponent, Ref, ref, onMounted } from 'vue';
import { Camera } from '@/models/main/Camera';
import Station from '@/models/main/Station';
import { key } from '@/store';
import { SaveStreamingEndpoint } from '@/models/main/StreamingEndpoint';
import { STREAM_QUALITIES } from '@/models/main/CommonTypes';
const { mapGetters } = createNamespacedHelpers('stationStore');
const userStoreHelpers = createNamespacedHelpers('userStore');
export default defineComponent({
    components: {
        TheOrganizerDashboard,
        Modal,
    },
    computed: {
        ...mapGetters([
            'getStation',
            'getCameras',
        ]),
        ...userStoreHelpers.mapGetters([
            'getId',
            'getUser',
            'getUserChannels',
        ]),
        getStationById() {
            let cameras: Array<Camera> = [];
            if (this.getUser && this.getUser.stations !== null) {
                const station = this.getUser.stations.find((s: Station) => {
                    return s.id === this.selectedStation;
                });
                if (station && station.cameras !== null) {
                    cameras = station.cameras;
                }
            }
            return cameras;
        },
    },
    watch: {
        getId(userId: number) {
            this.store.dispatch('userStore/getUserStations', userId);
        },
    },
    setup() {
        const selectedStation: Ref<string | null> = ref(null);
        const store = useStore(key);
        const camera: Camera = {
            inputLink: null,
            name: null,
            status: 'Idle',
            station: null,
            stationId: null,
            hasAudio: false,
        };
        const newCamera: any = ref(camera);
        const endpoint: SaveStreamingEndpoint = {
            name: null,
            maxQuality: STREAM_QUALITIES[0],
            digestEndpoint: null,
            channelId: null,
            cameraId: undefined,
        };
        const newEndpoint: any = ref(endpoint);
        const isManual: Ref<boolean> = ref(false);
        const selectStation = (stationId: string) => {
            selectedStation.value = stationId;
        };
        const deleteCamera = (cameraId?: number) => {
            if (cameraId !== undefined) {
                store
                    .dispatch('userStore/deleteCamera', cameraId)
                    .then(() => {
                        fetchUserStations();
                    });
            }
        };
        const fetchUserStations = () => {
            store.dispatch('userStore/getUserStations', store.getters['userStore/getId']);
        };
        const saveCamera = () => {
            newCamera.value.stationId = selectedStation.value;
            store
                .dispatch('userStore/saveCamera', newCamera.value)
                .then((createdCamera: Camera) => {
                    newEndpoint.value.cameraId = createdCamera.id;
                    store.dispatch('channelStore/saveStreamingEndpoint', newEndpoint.value);
                    fetchUserStations();
                });
        };
        const fetchUserChannels = () => {
            store.dispatch('userStore/getUserChannels', store.getters['userStore/getId']);
        };
        fetchUserChannels();
        onMounted(fetchUserStations);
        return {
            selectedStation,
            store,
            newCamera,
            STREAM_QUALITIES,
            newEndpoint,
            isManual,
            selectStation,
            deleteCamera,
            saveCamera,
        };
    },
});
</script>
<style>
.stations-list {
    max-height: 100%;
    width: 40%;
    margin-top: 1em;
    padding-right: 1ch;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: var(--main-font-clr) var(--bright-font-clr);
}

.stations-list__item {
    padding: 0.56em 1em;
    border-radius: var(--default-border-rad);
    background-color: var(--bright-font-clr);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    margin-bottom: var(--entity-item-margin-bottom);
}

.stations-list__item.selected {
    background-color: var(--select-background-color);
}

.cameras-list {
    max-height: 100%;
    padding-left: 1em;
    width: 60%;
    margin-top: 1em;
}

.cameras-list__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.625em;
    font-weight: 600;
    background-color: var(--bright-font-clr);
    padding: 0.84em 0.75em;
}

.stations-and-cameras-wrapper {
    max-height: 100%;
    display: flex;
    width: 100%;
}

.controls {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
}

.spz-checkbox input[type="checkbox"] {
    margin-right: 0.5em;
}
</style>