<template>
    <div ref="streamPlayer" class="stream-player" :class="{ fullscreen: isFullScreen, 'not-playing': playerState !== 1, 'is-mobile' : isMobile }">
        <div id="video-player">
        </div>
        <!-- <iframe v-if="videoId" :src="`https://www.youtube.com/live_chat?v=${videoId}&embed_domain=localhost`"></iframe>  -->
        <button v-show="playerState > 0" @click="onFullScreenBtnClick" v-if="livestream?.streamLink" class="live-player-full-btn"
            :title="isFullScreen ? 'Exit' : 'Full screen'"></button>
        <button @click="onPlayBtnClick" v-if="false && livestream?.streamLink" class="live-player-play-btn"
            :class="{ 'on-pause': playerState !== 1 }" :title="playerState === 1 ? 'Pause' : 'Play'"></button>
        <button v-if="false" @click="onSeekTo(-15)" class="backward-btn" title="Backward"></button>
        <button v-if="false" @click="onSeekTo(15)" class="forward-btn" title="Forward"></button>
        <div v-if="false" class="volume-slider-wrapper"><input @change="onVolumeChange" class="player-volume-slider" type="range" v-model="volume" min="0" max="100"></div>
        <button v-show="playerState > 0" @click="onShareBtnClick" class="share-button" :class="{ 'is-active': shareClipLength ?? 0 > 0 }">
            <div class="clip-length">
                {{ shareClipLength === 0 ? '|' : shareClipLength }}
            </div>
        </button>
        <button v-if="false && isLoggedIn" @click="addToFavourites(livestream.id)" class="add-to-favourites-btn" title="Add to favourites"></button>
        <slot />
        <Modal ref="infoPopUp">
            <template v-slot:header>
                <h1>Share</h1>
            </template>
            <template v-slot:body>
                <div class="link-section">
                    {{ shareLink }}
                </div>
            </template>
            <template v-slot:footer>
                <div>
                    <button class="btn" v-if="false">Download</button>
                    <button @click="infoPopUp.closeModal(), copyToClipboard(shareLink)" class="btn">Copy</button>
                    <button @click="infoPopUp.closeModal()" class="btn btn-inverse">Cancel</button>
                </div>
            </template>
        </Modal>
    </div>
</template>
<script lang="ts">
/// <reference types="youtube"/>
import { defineComponent, PropType, Ref, ref, onMounted } from 'vue';
import Modal from '@/components/main/Modal.vue';
import LiveDataModel from '@/composables/dataModels/LiveDataModel';
import { store } from '@/store';
import authModule from '@/main';
import { copyToClipboard, createBasicShareLink, deviceIsMobile } from '@/composables/common/Util';
import Livestream from '@/models/main/Livestream';
const LivePlayer = defineComponent({
    components: {
        Modal,
    },
    props: {
        livestream: {
            type: Object as PropType<Livestream>,
            required: true,
        },
        startPlayingAt: {
            type: Number,
            required: true,
        },
        endPlayingAt: {
            type: Number,
            required: false,
        },
        startFullScreen: {
            type: Boolean,
            required: false
        },
        livePlayerShouldSeekToggle: {
            type: Boolean,
            required: false
        },
        isRecordMode: {
            type: Boolean,
            required: false
        }
    },
    watch: {
        startPlayingAt: function (newVal) {
            if (this.player) {
                this.player.seekTo(newVal, true);
            }
        },
        livePlayerShouldSeekToggle: function () {
            if (this.player) {
                this.player.seekTo(this.startPlayingAt, true);
            }
        },
    },
    emits: ['fullscreenToggle', 'playerDurationChange', 'shareButtonClick'],
    setup(props, { emit }) {
        const isLoggedIn = ref(authModule.isAuthenticated)
        const isFullScreen: Ref<boolean> = ref(props.startFullScreen);
        const isFallbackFullScreen: Ref<boolean> = ref(false);
        const streamPlayer: any = ref(null);
        const playerState: Ref<number> = ref(0);
        const player: Ref<any> = ref();
        const VOLUME_STORAGE_KEY = 'SPZ_VOLUME';
        let storedVolume = localStorage.getItem(VOLUME_STORAGE_KEY);
        let defaultVolume = 30;
        if (storedVolume !== null) {
            defaultVolume = parseInt(storedVolume);
        }

        const volume: Ref<number> = ref(defaultVolume);
        const playerDuration: Ref<number> = ref(0);
        const videoId = ref('');
        const isMobile = deviceIsMobile();
        const onFullScreenBtnClick = () => {
            isFullScreen.value = !isFullScreen.value;
            const currentDoc: any = document;
            if (currentDoc.fullscreenElement === undefined && currentDoc.webkitFullscreenElement === undefined && currentDoc.mozFullScreenElement === undefined && currentDoc.msFullscreenElement === undefined) {
                isFallbackFullScreen.value = true;
            }
            if (currentDoc.fullscreenElement || currentDoc.webkitFullscreenElement || currentDoc.mozFullScreenElement || currentDoc.msFullscreenElement) {
                if (currentDoc.exitFullscreen) {
                    currentDoc.exitFullscreen();
                }
                else if (currentDoc.mozCancelFullScreen) {
                    currentDoc.mozCancelFullScreen();
                }
                else if (currentDoc.webkitExitFullscreen) {
                    currentDoc.webkitExitFullscreen();
                }
                else if (currentDoc.msExitFullscreen) {
                    currentDoc.msExitFullscreen();
                }
            }
            else {
                if (streamPlayer.value.requestFullscreen) {
                    streamPlayer.value.requestFullscreen();
                }
                else if (streamPlayer.value.mozRequestFullScreen) {
                    streamPlayer.value.mozRequestFullScreen();
                }
                else if (streamPlayer.value.webkitRequestFullscreen) {
                    streamPlayer.value.webkitRequestFullscreen((Element as any).ALLOW_KEYBOARD_INPUT);
                }
                else if (streamPlayer.value.msRequestFullscreen) {
                    streamPlayer.value.msRequestFullscreen();
                } else if (streamPlayer.value.webkitEnterFullscreen) {
                    //streamPlayer.value.webkitEnterFullscreen();
                    isFallbackFullScreen.value = true;
                    currentDoc.scrollIntoView(false)
                } else {
                    isFallbackFullScreen.value = true;
                }
            }
            emit('fullscreenToggle', isFullScreen.value, isFallbackFullScreen.value);
        };
        const onPlayBtnClick = () => {
            if (playerState.value === 1) {
                player.value.pauseVideo();
            }
            else {
                player.value.playVideo();
            }
        };
        const onVolumeChange = () => {
            localStorage.setItem(VOLUME_STORAGE_KEY, volume.value.toString());
            player.value.setVolume(volume.value);
        };

        const emitPlayerDuration = () => {
            if (player.value) {
                const currentPlayerDur: number = Math.ceil(player.value.getCurrentTime());
                if (playerDuration.value !== currentPlayerDur) {
                    playerDuration.value = currentPlayerDur;
                    if (startShareClipTime.value !== null) {
                        shareClipLength.value =  playerDuration.value - (startShareClipTime.value as number);
                    }
                    emit('playerDurationChange', playerDuration.value);
                }
            }
        }
        const onEverySecondTimeoutHandler = () => {
            emitPlayerDuration()
            setTimeout(onEverySecondTimeoutHandler, 1000);
        };
        const loadPlayer = () => {
            if (!window.YT) {
                (window as any).onYouTubeIframeAPIReady = () => {
                    initPlayer();
                };
                var tag = document.createElement('script');
                tag.src = 'https://www.youtube.com/iframe_api';
                var firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag?.parentNode?.insertBefore(tag, firstScriptTag);
            }
            else {
                initPlayer();
            }
        };
        const initPlayer = () => {
            new window.YT.Player('video-player', {
                width: '100%',
                height: '100%',
                videoId: videoId.value,
                events: {
                    'onReady': onPlayerReady,
                    'onStateChange': onStateChange,
                },
                playerVars: {
                    autoplay: 1,
                    controls: 1,
                    enablejsapi: 1,
                    fs: 1,
                    modestbranding: 1,
                    playsinline: 1,
                    rel: 0,
                    start: props.startPlayingAt,
                    end: props.endPlayingAt,
                },
            });
        };
        const onPlayerReady = (event: any) => {
            player.value = event.target;
            player.value.setVolume(volume.value);
            player.value.seekTo(props.startPlayingAt, true);
            if (player.value.playerVars) {
                player.value.playerVars.end = props.endPlayingAt;
            }
            event.target.playVideo();
            emitPlayerDuration();
            if(props.isRecordMode) {
                window.open("sportzone:start", '_self', 'noreferrer');
            }
            if(isMobile) {
                window.scrollTo(0, 1); 
            }
        };
        const onStateChange = (event: any) => {
            playerState.value = event.data;
            if (props.isRecordMode && (event.data == window.YT.PlayerState.PAUSED || event.data == window.YT.PlayerState.ENDED)) {
                window.open("sportzone:stop", '_self', 'noreferrer');
            }
        };
        if (props.livestream?.streamLink !== undefined) {
            let result = /(^\D+embed\/)(.+)[?]/.exec(props.livestream?.streamLink);
            if (result !== null && result.length === 3) {
                videoId.value = result[2];
            }
        }
        setTimeout(onEverySecondTimeoutHandler, 1000);
        onMounted(loadPlayer);
        const onSeekTo = (seconds: number) => {
            const currentStreamTime: number = Math.ceil(player.value.getCurrentTime());
            player.value.seekTo(currentStreamTime + seconds, true);
        };
        const addToFavourites = (livestreamId: number | undefined) => {
            if (livestreamId !== undefined) {
                store.dispatch('userStore/addFavouriteLivestream', livestreamId);
            }
        };


        const startShareClipTime: Ref<number | null> = ref(null);
        const endShareClipTime: Ref<number | null> = ref(null);
        const shareLink: Ref<string | null> = ref(null);
        const shareClipLength: Ref<number> = ref(0);
        const infoPopUp: Ref<any> = ref(null);
        const onShareBtnClick = () => {
            const currentPlayerTime = parseInt(player.value.getCurrentTime());
            if (props.livestream !== null && props.livestream !== undefined) {
                if (startShareClipTime.value === null) {
                    startShareClipTime.value = currentPlayerTime
                }
                else {
                    endShareClipTime.value = currentPlayerTime
                    shareLink.value = createBasicShareLink(props.livestream, startShareClipTime.value, endShareClipTime.value);
                    infoPopUp.value.openModal();
                    startShareClipTime.value = null;
                    endShareClipTime.value = null;
                    shareClipLength.value = 0;
                }
            }
            emit('shareButtonClick', currentPlayerTime)
        };
        
        return {
            isFullScreen,
            isFallbackFullScreen,
            streamPlayer,
            playerState,
            player,
            volume,
            isLoggedIn,
            videoId,
            copyToClipboard,
            shareClipLength,
            infoPopUp,
            shareLink,
            onFullScreenBtnClick,
            onPlayBtnClick,
            onVolumeChange,
            onShareBtnClick,
            loadPlayer,
            initPlayer,
            onSeekTo,
            addToFavourites,
            isMobile,
        };
    },
});
export default LivePlayer;
</script>
<style>
.fullscreen.fallback-fullscreen .hide-on-full {
    display: none;
}

:-webkit-full-screen .hide-on-full {
    display: none;
}

:-moz-full-screen .hide-on-full {
    display: none;
}

:-ms-fullscreen .hide-on-full {
    display: none;
}

:fullscreen .hide-on-full {
    display: none;
}

/* :fullscreen .live-player-full-btn {
    background-image: url(/exit-fullscreen-icon.svg);
} */

.stream-player {
    --opacity-animation-wait-time: 3000ms;
    position: relative;
}

.stream-player::after {
    /* content: ''; */
    position: absolute;
    background-color: #000;
    bottom: 0;
    left: 0;
    height: 4ch;
    width: 100%;
    opacity: 0.2;
    transition: opacity 200ms ease-in-out var(--opacity-animation-wait-time);
}

.stream-player.not-playing::after,
.stream-player.not-playing .live-player-play-btn,
.stream-player.not-playing .volume-slider-wrapper,
.stream-player.not-playing .backward-btn,
.stream-player.not-playing .forward-btn {
    opacity: 1;
}

.stream-player:hover::after,
.stream-player:hover .live-player-play-btn,
.stream-player:hover .volume-slider-wrapper,
.stream-player:hover .backward-btn,
.stream-player:hover .forward-btn {
    opacity: 1;
    transition: opacity 1ms ease-in-out;
}

.live-player-full-btn {
    position: absolute;
    bottom: 7px;
    right: 19px;
    cursor: pointer;
    z-index: 2;
    width: 26px;
    height: 26px;
    background-image: url(/fullscreen-icon.svg);
    background-color: transparent;
    background-size: 20px;
    border: none;
    background-repeat: no-repeat;
    font-size: 1em;
    background-position: center;
}

/* .live-player-full-btn:hover {
    transition: all 200ms ease;
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
} */

.live-player-full-btn::after {
    content: '';
    position: absolute;
    top: -0.25em;
    bottom: -0.25em;
    left: -0.5em;
    right: 0;
    background-color: transparent;
    z-index: 1;
}

.live-player-play-btn {
    opacity: 0;
    width: 2.25ch;
    height: 1.5em;
    font-size: 1em;
    background: transparent;
    position: absolute;
    bottom: 0.3em;
    left: 2%;
    z-index: 1;
    cursor: pointer;
    border-left: 0.4em solid white;
    border-right: 0.4em solid white;
    border-top: none;
    border-bottom: none;
    transition: border-right 300ms ease-in-out, opacity 200ms ease-in-out var(--opacity-animation-wait-time);
}

.live-player-play-btn.on-pause {
    border-top: 0.75em solid transparent;
    border-bottom: 0.75em solid transparent;
    border-left: 1.25em solid var(--light-homepage-color);
    border-right: none;
    transition: border-left 50ms linear, opacity 200ms ease-in-out var(--opacity-animation-wait-time);
}

.player-volume-slider {
    margin-left: 2em;
    cursor: pointer;
    max-width: calc(100% - 2em);
}

.share-button {
    width: 3em;
    height: 1.25em;
    position: absolute;
    bottom: 10px;
    right: 230px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    --share-btn-clr: white;
    background: url(/trim_icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    font-size: 1em;
}


.share-button.is-active {
    --share-btn-clr: var(--archive-bck-color);
}

.left-section,
.right-section {
    position: relative;
    height: 100%;
    width: 22.5%;
}

.clip-length {
    height: 100%;
    width: 55%;
    font-size: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--share-btn-clr);
}

.left-section::before,
.left-section::after,
.right-section::before,
.right-section::after {
    content: '';
    position: absolute;
    border-bottom: 3px solid var(--share-btn-clr);
    width: 215%;
    left: -35%;
}

.left-section::before {
    transform: rotate(-60deg);
    top: 22.5%;
}

.left-section::after {
    transform: rotate(60deg);
    bottom: 22.5%;
}

.right-section::before {
    transform: rotate(-120deg);
    top: 22.5%;
    left: -80%;
}

.right-section::after {
    transform: rotate(120deg);
    bottom: 22.5%;
    left: -80%;
}

.backward-btn,
.forward-btn,
.add-to-favourites-btn {
    opacity: 0;
    width: 1.53em;
    height: 1.53em;
    font-size: 1em;
    position: absolute;
    bottom: 0.3em;
    z-index: 1;
    cursor: pointer;
    border: none;
    transition: opacity 200ms ease-in-out var(--opacity-animation-wait-time);
}

.forward-btn {
    left: min(20%, 6em);
    background-image: url(/seek-right-icon.svg);
    background-color: transparent;
}

.backward-btn {
    left: min(10%, 4em);
    background-image: url(/seek-left-icon.svg);
    background-color: transparent;
}

.add-to-favourites-btn {
    opacity: 1;
    right: 225px;
    background-color: transparent;
    background-image: url(/favourite-icon.svg);
    background-size: cover;
    width: 1.25em;
    height: 1.25em;
    bottom: 10px;
}

.add-to-favourites-btn:hover {
  transition: all 200ms ease;
  transform: scale(1.2);
  background-image: url(/favourite-full-icon.svg);
}

.volume-slider-wrapper {
    opacity: 0;
    position: absolute;
    bottom: 0.3em;
    left: min(30%, 9em);
    z-index: 1;
    cursor: pointer;
    transition: opacity 200ms ease-in-out var(--opacity-animation-wait-time);
    display: flex;
    justify-content: center;
    align-content: center;
    max-width: 15ch;
    height: 1.6em;
}

/* .volume-slider-wrapper::before {
  content: '';
  background-image: url(/volume-icon.svg);
  width: 1.53em;
  height: 1.53em;
  margin-right: 0.25em;
} */

.volume-slider-wrapper::before {
  content: '';
  background-image: url(/volume-icon.svg);
  width: 1.53em;
  height: 1.53em;
  margin-right: 0.25em;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* SHARE MODAL CSS */
.stream-player .modal__dialog {
    background-color: var(--darker-bckgrnd-color);
}

.stream-player .modal__body {
    min-height: auto;
}

.stream-player .modal__footer .btn {
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    font-size: 1em;
}

.stream-player .link-section {
    padding: 1em;
    background-color: var(--light-bckgrnd-color);
    border-radius: 5px;
}
/*END */

.stream-player.is-mobile .live-player-full-btn {
    bottom: 11px;
    right: 23px;
}
    
.stream-player.is-mobile .share-button {
    bottom: 14px;
}

@media screen and (min-width: 875px) {

    .stream-player {
        font-size: 1em;
    }

    .volume-slider-wrapper {
        max-width: 20ch;
    }
}
</style>