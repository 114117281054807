/// <reference types="gapi" />

export default class SportzoneGoogleClient {
    private static clientId: string
    private static clientInstance: gapi.auth2.GoogleAuth
    private static _instance: SportzoneGoogleClient

    static get instance(): SportzoneGoogleClient {
        if(!SportzoneGoogleClient._instance) {
            SportzoneGoogleClient._instance = new SportzoneGoogleClient()
        }

        return SportzoneGoogleClient._instance
    }

    get client() {
        return SportzoneGoogleClient.clientInstance
    }

    googleClient = (scope: string | undefined, discoveryDocs: Array<string>, onSignedInCallbak: (signedId: boolean) => any) => {
        if(!SportzoneGoogleClient.clientInstance) {
            this.loadGoogleClient(scope, discoveryDocs, onSignedInCallbak)
        } else {
            if(typeof scope !== "undefined") {
                const user = SportzoneGoogleClient.clientInstance.currentUser.get();
                const isAuthorized = user.hasGrantedScopes(scope);
                if(!isAuthorized) {
                    this.grantScopes(scope)
                }
            }

            SportzoneGoogleClient.clientInstance.isSignedIn.listen(onSignedInCallbak);
        }
    }

    grantScopes = (scope: string) => {
        console.info("grantScopes")
        // Retrieve the GoogleUser object for the current user.
        const googleUser = SportzoneGoogleClient.clientInstance.currentUser.get();
        googleUser.grant({scope: scope});
    }

    listLivestreams() {
        return window.gapi.client.youtube.liveStreams.list({
          "part": [
            "snippet",
            "cdn",
            "status"
          ],
          "maxResults": 20,
          "mine": true
        }).then(response => response.result, err => console.error(err))
    }

    listChannels(): Promise<gapi.client.youtube.ChannelListResponse | null> {
        return window.gapi.client.youtube.channels.list({
          "part": [
            "id",
            "contentDetails",
            "snippet",
            "status"
          ],
          "maxResults": 20,
          "mine": true
        }).then(response => response.result, 
            (err) =>  { 
            console.error(err)
            return null
        })
    }

    private loadGoogleClient = (scope: string | undefined, discoveryDocs: Array<string>, onSignedInCallbak: (signedId: boolean) => any) => {
        if (!window.gapi) {
            throw new Error(
                '"https://apis.google.com/js/api:client.js" needs to be included as a <script>.'
            );
        }
        // if (!SportzoneGoogleClient.clientId) {
        //     SportzoneGoogleClient.clientId = process.env.VUE_APP_GOOGLE_CLIENT_ID
        // }
        if (!SportzoneGoogleClient.clientId) {
            SportzoneGoogleClient.clientId = "535872749999-rkoqcck8jaskp9upsn0ka21pvmebitam.apps.googleusercontent.com";
        }

        console.error("LOADING API HERE");

        try {
            window.gapi.load("auth2", () => {
                try {
                    window.gapi.client.init({
                        clientId: SportzoneGoogleClient.clientId,
                        discoveryDocs: discoveryDocs, //["https://developers.google.com/youtube/v3/docs"],
                        scope: scope,
                    }).then(() => {
                        const googleAuthInstance = window.gapi.auth2.getAuthInstance();
                        SportzoneGoogleClient.clientInstance = googleAuthInstance
                        googleAuthInstance.isSignedIn.listen(onSignedInCallbak)
                        window.gapi.client.load("youtube", "v3")
                        .then(
                            function() { 
                                console.log("GAPI client loaded for API"); 
                                onSignedInCallbak(true)
                            },
                            function(err) { 
                                console.error("Error loading GAPI client for API", err); 
                            }
                        );   
                    },
                    (error) => {
                        console.error(error)
                    }).catch((err) => {
                        console.error(err)
                    })
                } catch(ex) {
                    console.error(ex)
                }
    
            });
        } catch(sEx) {
            console.error(sEx)
        }

    }
}