<template>
<svg style="width:100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 480">
<g><path class="vly-icon" d="M240,0C107.45,0,0,107.45,0,240S107.45,480,240,480,480,372.55,480,240,372.49.15,240,0Zm2.56,231C230,217.08,185.56,162.12,199,95c31-4.26,193.16-18.43,265,146.66a222.49,222.49,0,0,1-21.25,93.53C396.39,207.36,268.49,225.83,242.56,231ZM133.64,347.37C124.32,335,19.36,188.71,136.26,41.56a222.4,222.4,0,0,1,84.92-24.71c-86.89,103.37-8.49,204.21,9.61,224.9C224.61,260.49,198.69,326.12,133.64,347.37ZM460.32,199.81C383.26,72.3,245.34,74.59,203.51,78.58a168.58,168.58,0,0,1,39.87-62.49A224.28,224.28,0,0,1,460.32,199.81ZM100.94,64.59c-79,132.56-4.31,257.33,16,287a169.28,169.28,0,0,1-73.23-3.68A223.55,223.55,0,0,1,100.94,64.59ZM55.44,366.74a194.79,194.79,0,0,0,34.5,3.32c106.74,0,147.51-98.81,156-123.44,17.52-3.78,88.49-15.4,140.39,30.51-8.76,21.64-78.86,178.25-263,154A225.71,225.71,0,0,1,55.44,366.74ZM240,464a223,223,0,0,1-78.87-14.38c152.35-.44,221.06-125.49,237.22-160.28a168.37,168.37,0,0,1,33.9,65.46A224.08,224.08,0,0,1,240,464Z" transform="translate(0 0)" />
</g></svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({

})
</script>

<style>
.vly-icon {
    fill: var(--_icon-fill, #283380);
}

.active-filter .vly-icon {
  fill: var(--archive-bck-color);
}
</style>