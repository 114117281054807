import GenericDictionary from "@/components/util/GenericDictionary";
import { PagedResponse, PaginatedItemsStoreGetters, PaginatedItemsStoreMutations } from "@/composables/common/Pagination";
import authModule from "@/main";
import { Sport } from "@/models/main/CommonTypes";
import { Organization } from "@/models/main/Organization";
import { OrganizationManager } from "@/models/main/OrganizationRole";
import { IRootState } from "@/store";
import { ActionContext, Module } from "vuex";

const state: OrganizationManagerStore = {
    paginatedItems: {
        items: {},
        pageSize: 30,
        currentPage: 1,
    }
};
export interface OrganizationManagerStore {
    paginatedItems: PagedResponse<OrganizationManager>,
    searchedTeam?: string,
};

const getters = {
    getOrganizationManagers: (state: OrganizationManagerStore) => (searchTerm?: string) => {
        let filteredOrganizationManagers: Array<OrganizationManager> = [];
        const lowerCaseSearchTerm = searchTerm?.toLowerCase().toLowerCase();
        for (const organizationInvitationId in state.paginatedItems.items) {
            if (Object.prototype.hasOwnProperty.call(state.paginatedItems.items, organizationInvitationId)) {
                const organizationManager: OrganizationManager = state.paginatedItems.items[organizationInvitationId] as OrganizationManager;
                const user = organizationManager.user;
                const managerUserName = user.firstName + (user.middleName ? ' ' + user.middleName + ' ' : ' ') + user.lastName;
                const managerUserNameLowerCased = managerUserName.toLowerCase();
                const searchedTeamIsOk = lowerCaseSearchTerm === undefined || managerUserNameLowerCased.includes(lowerCaseSearchTerm);
                if (searchedTeamIsOk) {
                    filteredOrganizationManagers.push(organizationManager);
                }
            }
        }
        filteredOrganizationManagers = filteredOrganizationManagers.sort((a, b) => {
            const aUser = a.user;
            const aName = aUser.firstName + (aUser.middleName ? ' ' + aUser.middleName + ' ' : ' ') + aUser.lastName;
            const bUser = b.user;
            const bName = bUser.firstName + (bUser.middleName ? ' ' + bUser.middleName + ' ' : ' ') + bUser.lastName;
            return (aName).localeCompare(bName);
        });
        return filteredOrganizationManagers;
    },
    getOrganizationManager: (state: OrganizationManagerStore) => (organizationManagerId: number) => {
        return state.paginatedItems.items[organizationManagerId];
    },
    ...PaginatedItemsStoreGetters(),
}

const mutations = {
    addOrganizationManager(state: OrganizationManagerStore, organizationManager: OrganizationManager) {
        state.paginatedItems.items[organizationManager.id] = organizationManager;
    },
    editOrganizationManager(state: OrganizationManagerStore, organizationManager: OrganizationManager) {
        state.paginatedItems.items[organizationManager.id] = organizationManager;   
    },
    removeOrganizationManager(state: OrganizationManagerStore, organizationManagerId: number) {
        if (Object.prototype.hasOwnProperty.call(state.paginatedItems.items, organizationManagerId)) {
            delete state.paginatedItems.items[organizationManagerId];
        }
    },
    ...PaginatedItemsStoreMutations(),
}

const actions = {
    addOrganizationManager({ commit }: ActionContext<OrganizationManagerStore, IRootState>, organizationManager: OrganizationManager) {
        commit('addOrganizationManager', organizationManager);
    },
    createOrganizationMember({ dispatch }: ActionContext<OrganizationManagerStore, IRootState>, organizationManager: OrganizationManager) {
        return authModule.post(`/api/organization/${organizationManager.organization.id}/manager`, organizationManager)
            .then((response) => {
                const organizationManager: OrganizationManager = response.data as unknown as OrganizationManager;
                dispatch('addOrganizationManager', organizationManager);
                return organizationManager;
            });
    },
    editOrganizationManager({ commit }: ActionContext<OrganizationManagerStore, IRootState>, organizationManager: OrganizationManager) {
        return authModule.patch(`/api/organization/${organizationManager.organization.id}/manager/${organizationManager.id}`, organizationManager)
            .then((response) => {
                const organizationManager: OrganizationManager = response.data as unknown as OrganizationManager;
                commit('editOrganizationManager', organizationManager);
                return organizationManager;
            });
    },
    deleteOrganizationManager({ commit }: ActionContext<OrganizationManagerStore, IRootState>, organizationManager: OrganizationManager) {
        return authModule.delete(`/api/organization/${organizationManager.organization.id}/manager/${organizationManager.id}`)
            .then((response) => {
                const deletedOrganizationManagerId: number = response.data as unknown as number;
                commit('removeOrganizationManager', deletedOrganizationManagerId);
            });
    },
    fetchOrganizationManagers({ commit, dispatch, getters }: ActionContext<OrganizationManagerStore, IRootState>, organizationId: number ) {
        let filtersChanged = false;
        let pageToRequest = getters['getNextPage'];
        const pageSize = getters['getPageSize'];
        if (filtersChanged) {
            pageToRequest = 1;
        }
        if (!filtersChanged && getters['getNextPage'] === 1 && getters['getCurrentPage'] > 1) {
            return;
        }
        return authModule.get(`/api/organization/${organizationId}/manager`, {
            params: {
                page: pageToRequest,
                pageSize: pageSize,
            },
        })
        .then((response) => {
            const pagedResponse: PagedResponse<OrganizationManager> = response.data as unknown as PagedResponse<OrganizationManager>;
            commit('setCurrentPage', pagedResponse.currentPage);
            commit('setPageSize', pagedResponse.pageSize);
            commit('setNextPage', pagedResponse.nextPage);
            commit('setPreviousPage', pagedResponse.previousPage);
            for (const containerIndex in pagedResponse.items) {
                dispatch('addOrganizationManager', pagedResponse.items[containerIndex]);
            }
        })
        .catch((error) => {
            console.error(error);
        });
    },
}

const organizationManagerStore: Module<OrganizationManagerStore, IRootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
export default organizationManagerStore;