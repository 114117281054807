import GenericDictionary from "@/components/util/GenericDictionary";
import PhaseRankingModel from "@/composables/dataModels/bowling/PhaseRankingDataModel"
import { computed, ref, Ref } from "vue";
import PhaseEntityDataModel from "../dataModels/bowling/PhaseEntityDataModel";
import PhaseGameDataModel from "../dataModels/bowling/PhaseGameDataModel";
import PhaseSerieDataModel from "../dataModels/bowling/PhaseSerieDataModel";

export type BowlingRankingPageInfo = { playersCountPerEntity: number, hasHandicap: boolean, hasStartScore: boolean, maxSeriesForPlayer: number, legend: GenericDictionary<any> }

export default function useParsePhaseRankingForRender(phaseRanking: Ref<PhaseRankingModel | undefined>, pageNumber?: Ref<number>, pageSize?: Ref<number>, squadId?: Ref<number>, fromLane?: Ref<number>, toLane?: Ref<number>) {

    const getCurrentRankingPageInfo = computed((): BowlingRankingPageInfo | undefined => {
        const currentRankingPageInfo: BowlingRankingPageInfo = {
            hasHandicap: false,
            hasStartScore: false,
            maxSeriesForPlayer: 0,
            playersCountPerEntity: 0, // getting the players count of entity with least players in it,
            legend: {'for': {}, 'from': {}}
        } 
        if(phaseRanking.value === undefined || phaseRanking.value === null || phaseRanking.value.entities === undefined) {
            return
        }
        if(pageNumber === undefined) {
            pageNumber = ref(0)
        }
        if(pageSize === undefined) {
            pageSize = ref(Number.MAX_SAFE_INTEGER)
        }

        const rankingEntities = phaseRanking.value.entities;
        const playerSeries: GenericDictionary<string[]> = {};

        let qualifiedForClassNum = 1;
        let qualifiedFromClassNum = 1;
        let legendColorCount = 0;
        const limit = (pageNumber.value * pageSize.value) + pageSize.value;
        for (let i = pageNumber.value * pageSize.value; i < limit && i < rankingEntities.length; i++) {
            const entity = rankingEntities[i];
            if (currentRankingPageInfo.hasHandicap || (entity.handicap && !isNaN(entity.handicap) && entity.handicap > 0)) {
                currentRankingPageInfo.hasHandicap = true;
            }
            if (currentRankingPageInfo.hasStartScore || (entity.startScore && !isNaN(entity.startScore) && entity.startScore > 0)) {
                currentRankingPageInfo.hasStartScore = true;
            }

            if (entity.qualifiedForPhase && !currentRankingPageInfo.legend.for[entity.qualifiedForPhase]) {
                currentRankingPageInfo.legend.for[entity.qualifiedForPhase] = {
                    name: entity.qualifiedFroPhaseName,
                    "rowClass": "qualified_for_row_" + qualifiedForClassNum++
                };
                legendColorCount++;
            }
            if (entity.qualifiedFromPhase && !currentRankingPageInfo.legend.from[entity.qualifiedFromPhase]) {
                currentRankingPageInfo.legend.from[entity.qualifiedFromPhase] = {
                    name: entity.qualifiedFromPhaseName,
                    "rowClass": "qualified_from_row_" + qualifiedFromClassNum++
                };
                legendColorCount++;
            }

            const seriesData = entity.series;
            for (const serieId in seriesData) {
                if (Object.prototype.hasOwnProperty.call(seriesData, serieId) && !isNaN(parseInt(serieId))) {
                    const players = seriesData[serieId].players;
                    let playersCount = 0;
                    for (const playerId in players) {
                        if (Object.prototype.hasOwnProperty.call(playerSeries, playerId)) {
                            playerSeries[playerId].push(serieId);
                        } else {
                            playerSeries[playerId] = [serieId];
                        }

                        if (playerSeries[playerId].length > currentRankingPageInfo.maxSeriesForPlayer) {
                            currentRankingPageInfo.maxSeriesForPlayer = playerSeries[playerId].length;
                        }

                        playersCount++;
                    }

                    if (currentRankingPageInfo.playersCountPerEntity < playersCount) {
                        currentRankingPageInfo.playersCountPerEntity = playersCount;
                    }
                }
            }

        }

        
        if (legendColorCount > 0) {
            for (const s in currentRankingPageInfo.legend) {
                for (const j in currentRankingPageInfo.legend[s]) {
                    switch (currentRankingPageInfo.legend[s][j].rowClass) {
                        case 'qualified_for_row_1' :
                            currentRankingPageInfo.legend[s][j].colorCss = '#aa916e';//#d1b471, #b4a082, #286932
                            break;
                        case 'qualified_for_row_2' :
                            currentRankingPageInfo.legend[s][j].colorCss = '#7478c6';// '#6c6ce1';//#8B8EDB
                            break;
                        case 'qualified_for_row_3' :
                            currentRankingPageInfo.legend[s][j].colorCss = '#589160';
                            break;
                        default:
                    }
                }
            }
        }

        return currentRankingPageInfo
    })

    const phaseCutAverage = computed(() => {
        let nonNullGames = 0;
        if(phaseRanking.value?.cut) {
            const cut = phaseRanking.value.cut
            if(cut.maxSerieID && cut.series) {
                const maxSeries = cut.series[cut.maxSerieID];
                for(const key in maxSeries.players) {
                    const games = maxSeries.players[key]?.games;
                    for(const gameK in games) {
                        const game = games[parseInt(gameK)] as PhaseGameDataModel
                        if(game.score && game.score > 0) {
                            nonNullGames++    
                        }
                    }
                }
            } else {
                for(const serieK in cut.series) {
                    const currentSerie = cut.series[serieK]
                    for(const key in currentSerie.players) {
                        const games = currentSerie.players[key]?.games;
                        for(const gameK in games) {
                            const game = games[parseInt(gameK)] as PhaseGameDataModel
                            if(game.score && game.score > 0) {
                                nonNullGames++    
                            }
                        }
                    }
                }
            }

            const actualResult =  ((cut.startScore ?? 0) > 0 ? (cut.startScore ?? 0) : 0);
            const average = (cut.result == actualResult ? 0 : ((cut.result ?? 0) - actualResult) / nonNullGames).toFixed(1);
            return average
        }

        return 0.0
    })

    const displayPlayers = computed(() => {
        type DisplayGameModel = { game: PhaseGameDataModel, gameIsActiveOrIsLastOne: boolean }
        type DisplayPlayerModel = { id: number, name: string, position: number, country: string, games: Array<DisplayGameModel>, handicap?: number, rankingInfo: DisplayPlayerRankingInfo, playerIsInCameraLanes: boolean }
        const playersToDisplay: Array<DisplayPlayerModel> = []
        if(phaseRanking.value === undefined || phaseRanking.value.entities === undefined) {
            return playersToDisplay
        }

        for(let i = 0; i < phaseRanking.value.entities.length; i++) {
            const entity = phaseRanking.value.entities[i];
            const entitySeries = entity.series;
            for(const seriesId in entitySeries) {
                if(entitySeries[seriesId] && (squadId === undefined || entitySeries[seriesId].squadID == squadId.value)) { // current squad it is
                    const entitySerie = entitySeries[seriesId];
                    const players = entitySerie.players;
                    for(const playerId in players) {
                        const currentPlayer = players[playerId];
                        const playerTitle = currentPlayer.firstName + " " + currentPlayer.lastName;
                        const rankingInfo = getPlayerRankingInfo(entity, parseInt(playerId), parseInt(seriesId));
                        if(!rankingInfo || rankingInfo.currentSerie === null) {
                            continue;
                        }

                        const games = currentPlayer.games;
                        const displayGames: Array<DisplayGameModel> = []
                        let playerIsInCameraLanes = false
                        if(games) {
                            for(let k = 0; k < games.length; k++) {
                                const currentGame = games[k]
                                playerIsInCameraLanes = parseInt(games[k].lane ?? '0') >= (fromLane?.value ?? 0) && parseInt(games[k].lane ?? '0') <= (toLane?.value ?? 100);
                                const gameIsActiveOrIsLastOne: boolean = (games[k].frames && games[k].frames.length > 0 && (k === games.length - 1 || (!games[k + 1].frames || games[k + 1].frames.length === 0))) || (k === games.length - 1 || games[k + 1].score === 0);
                                const displayGame: DisplayGameModel = { game: currentGame, gameIsActiveOrIsLastOne }
                                displayGames.push(displayGame)
                            }
                        }
                        
                        const displayPlayer: DisplayPlayerModel = { 
                            id: currentPlayer.ID ?? 0, 
                            name: playerTitle, 
                            position: i + 1,
                            country: currentPlayer.country ?? 'Unset',
                            playerIsInCameraLanes: playerIsInCameraLanes,
                            games: displayGames,
                            rankingInfo: rankingInfo
                        }

                        if(entity.handicap) {
                            displayPlayer.handicap = entity.handicap
                        }

                        playersToDisplay.push(displayPlayer)
                    }
                }
            }
        }

        return playersToDisplay
    })

    type DisplayPlayerRankingInfo = { isPredicted: boolean, qualifies: boolean, currentAvg: number, currentSerie: PhaseSerieDataModel, currentSeriesScore: number, bestSeriesScore: number, startScore: number, rankingResult: number }

    const getPlayerRankingInfo = (phaseEntity: PhaseEntityDataModel, playerId: number, currentSeriesID: number): DisplayPlayerRankingInfo | undefined => {
        if(phaseEntity.series === undefined || phaseEntity.startScore === undefined || phaseEntity.result === undefined) {
            return
        }

        let currentSeriesScore = -1;
        let bestSeriesScore = 0;
        let currentSerie = null;
        let average = 0.0;
        let isPredicted = false;
        let qualifies = false;
        let startScore = 0;
        let rankingResult = 0;
        if(!qualifies && phaseEntity.qualifiedForPhase !== null && phaseEntity.qualifiedForPhase > 0) {
            qualifies = true;
        }


        currentSerie = phaseEntity.series[currentSeriesID];
        startScore = phaseEntity.startScore;
        rankingResult = phaseEntity.result;
        if(currentSeriesScore === -1) { // no need to find avg more than once
            if(currentSerie.players !== undefined) {
                const games = currentSerie.players[playerId].games;
                let totalScore = 0;
                let totalNonZeroGames = 0;
                for(const gI in games) {
                    const game = games[parseInt(gI)]
                    if(!isPredicted && game.isPredicted) {
                        isPredicted = true;
                    }

                    if(game.score > 0) {
                        totalScore += game.score;
                        totalNonZeroGames++;
                    }
                }

                average = (totalScore / (totalNonZeroGames > 0 ? totalNonZeroGames : 1));
            }
        }

        currentSeriesScore = phaseEntity.series[currentSeriesID].points ? phaseEntity.series[currentSeriesID].points ?? 0 : 0;
        if(phaseEntity.maxSerieID === currentSeriesID) { // current serie is the best game
            let nextBestSerieScore = 0;
            for(const sId in phaseEntity.series) {
                const serie = phaseEntity.series[sId]
                const serieId = parseInt(sId)
                if(serieId != currentSeriesID && nextBestSerieScore < (serie.points ?? 0)) {
                    nextBestSerieScore = (serie.points ?? 0);
                }
            }

            bestSeriesScore = nextBestSerieScore;
        } else {
            const currentPhaseBestScore = phaseEntity.result;
            if(currentPhaseBestScore > bestSeriesScore) {
                bestSeriesScore = currentPhaseBestScore;
            }
        }
        

        return {"isPredicted" : isPredicted, "qualifies" : qualifies, "currentAvg" : average, "currentSerie": currentSerie, "currentSeriesScore" : currentSeriesScore, "bestSeriesScore" : bestSeriesScore, "startScore" : startScore, "rankingResult" : rankingResult}
    }

    return {
        getCurrentRankingPageInfo,
        phaseCutAverage,
        displayPlayers
    }
}