import DataModel from "@/models/main/DataModel";

export default class BowlingCameraDataModel extends DataModel {
    public static readonly MODEL_NAME: string = "Camera"

    private _id?: number;
    private _name?: string;
    private _fromLane?: number;
    private _toLane?: number;
    private _streamKey?: string;

    
    // #region Fields Keys
    static get cameraIDKey() {
        return "camera_id";
    }

    static get cameraNameKey() {
        return "camera_name";
    }

    static get cameraFromLaneKey() {
        return "from_lane";
    }

    static get cameraToLaneKey() {
        return "to_lane";
    }

    static get cameraStreamKeyKey() {
        return "stream_key";
    }
    // #endregion

    // #region accessors/mutations 
    get ID() {
        return this._id;
    }

    set ID(ID) {
        this._id = ID;
    }

    get name() {
        return this._name;
    }

    set name(name) {
        this._name = name;
    }

    get fromLane() {
        return this._fromLane;
    }

    set fromLane(fromLane) {
        this._fromLane = fromLane;
    }

    get toLane() {
        return this._toLane;
    }

    set toLane(toLane) {
        this._toLane = toLane;
    }

    get streamKey() {
        return this._streamKey;
    }

    set streamKey(streamKey) {
        this._streamKey = streamKey;
    }
    // #endregion

    constructor(modelID?: string, modelData?: any) {
        super();
        this.parseMinifiedData(modelData);
        if(this.ID !== undefined) {
            const modelId = DataModel.generateModelID(this, {"id": this.ID});
            if(modelId !== false) {
                this.modelID = modelId
            }
        } else if(modelID !== undefined){
            this.modelID = modelID;
        }
    }

    // #region FieldMappings Getters/Setters
    dataModelName() {
        return BowlingCameraDataModel.MODEL_NAME
    }

    getKeyByMinifiedKey(minKey: string) {
        switch (minKey) {
            case "0":
                return BowlingCameraDataModel.cameraIDKey;
            case "1":
                return BowlingCameraDataModel.cameraNameKey;
            case "2":
                return BowlingCameraDataModel.cameraFromLaneKey;
            case "3":
                return BowlingCameraDataModel.cameraToLaneKey;
            case "4":
                return BowlingCameraDataModel.cameraStreamKeyKey;
            default:
                return ""
        }
    }

    getProperty(propertyKey: string) {
        switch (propertyKey) {
            case BowlingCameraDataModel.cameraIDKey:
                return this.ID;
            case BowlingCameraDataModel.cameraNameKey:
                return this.name;
            case BowlingCameraDataModel.cameraFromLaneKey:
                return this.fromLane;
            case BowlingCameraDataModel.cameraToLaneKey:
                return this.toLane;
            case BowlingCameraDataModel.cameraStreamKeyKey:
                return this.streamKey;
        }
    }

    setProperty(propertyKey: string, value: any) {
        switch (propertyKey) {
            case BowlingCameraDataModel.cameraIDKey:
                this.ID = parseInt(value);
                break;
            case BowlingCameraDataModel.cameraNameKey:
                this.name = value;
                break;
            case BowlingCameraDataModel.cameraFromLaneKey:
                this.fromLane = parseInt(value);
                break;
            case BowlingCameraDataModel.cameraToLaneKey:
                this.toLane = parseInt(value);
                break;
            case BowlingCameraDataModel.cameraStreamKeyKey:
                this.streamKey = value;
                break;
        }
    }

    //#endregion
}