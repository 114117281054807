<template>
    <TheUserDashboard :onUserLoad="onUserLoaded" title="Dashboard">
        <div v-if="getCurrentHomeEntity && getCurrentAwayEntity" class="buttons-wrapper">
            <div @click="changeActiveButton('info')" class="buttons-heading"
                :class="{ 'active-button': filterValue === 'info' }">
                INFO
            </div>
            <div @click="changeActiveButton('line-ups')" class="buttons-heading"
                :class="{ 'active-button': filterValue === 'line-ups' }">
                LINE-UPS
            </div>
            <div @click="changeActiveButton('summary')" class="buttons-heading"
                :class="{ 'active-button': filterValue === 'summary' }">
                SUMMARY
            </div>
        </div>
        <div v-if="getCurrentHomeEntity && getCurrentAwayEntity" class="section-info"
            :class="{ 'active-filter': filterValue === 'info' }">
            <div class="volleyball-info-wrapper">
                <div class="volleyball-info-heading">
                    <div class="volleyball-info-heading-row">
                        TEAM\GAME
                    </div>
                    <div v-for="i in getHighestNumGameInContainerEvents" :key="i">
                        <div class="volleyball-info-heading-row">
                            {{ i }}
                        </div>
                    </div>
                    <div class="volleyball-info-heading-row">
                        GAMES
                    </div>
                </div>
                <div class="volleyball-info-body">
                    <div class="volleyball-info-body-row">
                        <div class="volleyball-info-body-row-value">
                            {{ getCurrentHomeEntity.name }}
                        </div>
                        <div class="volleyball-info-body-row-value">
                            {{ getCurrentAwayEntity.name }}
                        </div>
                    </div>
                    <div v-for="i in getHighestNumGameInContainerEvents" :key="i" class="volleyball-info-body-row">
                        <div class="volleyball-info-body-row-value">
                            {{ calculateEntityPoints(getCurrentHomeEntity.id ?? 0, match?.events ?? {}, undefined, i) }}
                        </div>
                        <div class="volleyball-info-body-row-value">
                            {{ calculateEntityPoints(getCurrentAwayEntity.id ?? 0, match?.events ?? {}, undefined, i) }}
                        </div>
                    </div>
                    <div class="volleyball-info-body-row">
                        <div class="volleyball-info-body-row-value">
                            {{ currentHomeEntityGamePoints }}
                        </div>
                        <div class="volleyball-info-body-row-value">
                            {{ currentAwayEntityGamePoints }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="getCurrentHomeEntity && getCurrentAwayEntity" class="section-line-ups"
            :class="{ 'active-filter': filterValue === 'line-ups' }">
            <div class="volleyball-line-ups-wrapper">
                <div class="volleyball-line-ups-home-team">
                    <div class="volleyball-line-ups-home-team-name">
                        {{ getCurrentHomeEntity.name }}
                    </div>
                    <div v-for="(playerStat, playerId) in getCurrentContainerBasicStats?.homeEntity[0].playersStats"
                        :key="playerId">
                        <div v-if="getCurrentHomeEntity.players && getCurrentHomeEntity.players[playerId]">
                            <div>
                                {{ getCurrentHomeEntity.players[playerId].number }}. {{
        getCurrentHomeEntity.players[playerId].firstName }} {{
        getCurrentHomeEntity.players[playerId].lastName }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="volleyball-line-ups-away-team">
                    <div class="volleyball-line-ups-away-team-name">
                        {{ getCurrentAwayEntity.name }}
                    </div>
                    <div v-for="(playerStat, playerId) in getCurrentContainerBasicStats?.awayEntity[0].playersStats"
                        :key="playerId">
                        <div v-if="getCurrentAwayEntity.players && getCurrentAwayEntity.players[playerId]">
                            <div>
                                {{ getCurrentAwayEntity.players[playerId].number }}. {{
        getCurrentAwayEntity.players[playerId].firstName }} {{
        getCurrentAwayEntity.players[playerId].lastName }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="getCurrentHomeEntity && getCurrentAwayEntity" class="section-summary"
            :class="{ 'active-filter': filterValue === 'summary' }">
            <div class="volleyball-summary-wrapper">
                <div class="volleyball-summary-teams">
                    <div class="events-sec">
                        <div class="info-section">
                            <div id="home-entity-name">
                                {{ getCurrentHomeEntity.name }}
                            </div>
                            <div id="away-entity-name">
                                {{ getCurrentAwayEntity.name }}
                            </div>
                            <div @click="onChoseGameClick(i)" v-for="i in getHighestNumGameInContainerEvents" :key="i"
                                class="game-num summary-game-num" :class="{ 'current-game': i === currentGame }">
                                {{ (i) }}
                            </div>
                        </div>
                        <div v-for="(gameArray, g) in getCurrentContainerEventsGroupedByGames" :key="g"
                            v-show="currentGame == g" class="game-wrapper">
                            <div v-for="(eventRow, e) in gameArray.events" :key="e"
                                :set="player = getEventPlayer(eventRow.event)" class="event-row">
                                <div class="score-box">
                                    <div class="home-team-score">
                                        {{ eventRow.homeTeamScore }}
                                    </div>
                                    <div class="away-team-score">
                                        {{ eventRow.awayTeamScore }}
                                    </div>
                                </div>
                                <div @click="displayPersonsalStat(eventRow.event.entityId, eventRow.event.playerId)"
                                    class="event-info-box">
                                    <div class="player-name"
                                        :class="{ 'highlight': selectedPersonalStatPlayer?.id === eventRow.event.playerId }">
                                        <div v-if="player" class="player-firstName">
                                            {{ player?.firstName.substring(0, 1) }}
                                        </div>
                                        <div class="player-lastName">
                                            {{ player?.lastName }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </TheUserDashboard>
</template>
<script lang='ts'>
import { defineComponent, ref, Ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import TheUserDashboard from '@/components/main/TheUserDashboard.vue'
import useVolleyballContainerComputeds from '@/composables/volleyball/UseCurrentContainerComputeds'
import useInterpretVolleyballEvents from '@/composables/volleyball/UseInterpretVolleyballEvents'
import { Container } from '@/models/main/Container'
import { Entity } from '@/models/main/Entity'
import { Event } from '@/models/main/Event'
import { Player } from '@/models/main/Player'
import { User } from '@/models/main/User'
import { key } from '@/store'
import { DefaultUser } from '@/store/modules/main/UserStore'
export default defineComponent({
    components: {
        TheUserDashboard
    },
    setup() {
        const router = useRouter()
        const user: User = new DefaultUser()
        const currentUser: any = ref(user)
        const filterValue: Ref<string> = ref('info')
        const chosenGame: Ref<number> = ref(0)
        const selectedPersonalStatPlayersEntity: Ref<Entity | undefined> = ref()
        const selectedPersonalStatPlayer: Ref<Player | undefined> = ref()
        const showPersonalStats: Ref<boolean> = ref(false)
        let timeout: any | null = null
        const personalStatsPinned: Ref<boolean> = ref(false)
        const match: Ref<Container | undefined> = ref()
        const matchId: Ref<number> = ref(710)
        const store = useStore(key)
        const {
            getCurrentHomeEntity,
            getCurrentAwayEntity,
            currentHomeEntityGamePoints,
            currentAwayEntityGamePoints,
            getCurrentContainerBasicStats,
            getCurrentContainerEventsGroupedByGames
        } = useVolleyballContainerComputeds(matchId)
        const {
            calculateEntityPoints
        } = useInterpretVolleyballEvents()
        const onUserLoaded = (user: User) => {
            currentUser.value = user
        }
        const changeActiveButton = (selectedSection: string) => {
            filterValue.value = selectedSection
        }
        const onChoseGameClick = (gameNum: number) => {
            chosenGame.value = gameNum
        }
        const getEventPlayer = (event: Event): Player | undefined => {
            if (event.playerId === undefined || event.playerId === null) {
                return undefined
            }
            const eventEntityId = event.entityId
            const eventPlayerId = event.playerId
            return getPlayer(eventEntityId, eventPlayerId)
        }
        const getPlayer = (entityId: number | undefined, playerId: number | undefined) => {
            if (playerId === undefined) {
                return playerId
            }
            if (entityId !== undefined) {
                let currentEntity: Entity | undefined
                switch (entityId) {
                    case getCurrentHomeEntity.value?.id:
                        currentEntity = getCurrentHomeEntity.value
                        break
                    case getCurrentAwayEntity.value?.id:
                        currentEntity = getCurrentAwayEntity.value
                        break
                }
                for (const i in currentEntity?.players) {
                    const currentPlayer = currentEntity?.players[i]
                    if (currentPlayer?.id === playerId) {
                        return currentEntity?.players[i]
                    }
                }
            }
            return undefined
        }
        const displayPersonsalStat = (entityId: number | undefined, playerId: number | undefined) => {
            if (getCurrentHomeEntity.value?.id === entityId) {
                selectedPersonalStatPlayersEntity.value = getCurrentHomeEntity.value
            }
            if (getCurrentAwayEntity.value?.id === entityId) {
                selectedPersonalStatPlayersEntity.value = getCurrentAwayEntity.value
            }
            selectedPersonalStatPlayer.value = getPlayer(entityId, playerId)
            showPersonalStats.value = true
            if (timeout !== null) {
                clearTimeout(timeout)
                timeout = null
            }
            if (!personalStatsPinned.value) {
                timeout = setTimeout(() => {
                    showPersonalStats.value = false
                    selectedPersonalStatPlayer.value = undefined
                    timeout = null
                }, 20000)
            }
        }
        const getHighestNumGameInContainerEvents = computed(() => {
            let highestNum = 1
            for (const g in getCurrentContainerEventsGroupedByGames.value) {
                const game = parseInt(g)
                if (highestNum < game) {
                    highestNum = game
                }
            }
            return highestNum
        })
        const currentGame = computed(() => {
            if (chosenGame.value !== 0) {
                if (chosenGame.value === getHighestNumGameInContainerEvents.value) {
                    chosenGame.value = 0
                    return getHighestNumGameInContainerEvents.value
                }
                return chosenGame.value
            }
            return getHighestNumGameInContainerEvents.value
        })
        const getMatch = async () => {
            match.value = await store.dispatch('containerStore/getContainer', matchId.value)
        }
        onMounted(getMatch)
        return {
            router,
            currentUser,
            filterValue,
            selectedPersonalStatPlayersEntity,
            selectedPersonalStatPlayer,
            showPersonalStats,
            personalStatsPinned,
            match,
            store,
            getCurrentHomeEntity,
            getCurrentAwayEntity,
            currentHomeEntityGamePoints,
            currentAwayEntityGamePoints,
            getCurrentContainerBasicStats,
            getCurrentContainerEventsGroupedByGames,
            getHighestNumGameInContainerEvents,
            currentGame,
            calculateEntityPoints,
            onUserLoaded,
            changeActiveButton,
            onChoseGameClick,
            getEventPlayer,
            getPlayer,
            displayPersonsalStat
        }
    }
})
</script>
<style>
.buttons-wrapper {
    width: 20%;
    display: flex;
    justify-content: space-between;
    text-align: center;
}

.buttons-heading:hover {
    cursor: pointer;
}

.active-button {
    background-color: var(--archive-bck-color);
}

.section-info {
    display: none;
    width: 35%;
}

.volleyball-info-wrapper {
    margin-top: 2em;
}

.volleyball-info-heading {
    display: flex;
    justify-content: space-between;
}

.volleyball-info-heading-row {
    align-items: center;
    display: flex;
    height: 3em;
    justify-content: center;
    width: 3em;
}

.volleyball-info-body {
    display: flex;
    justify-content: space-between;
}

.volleyball-info-body-row {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.volleyball-info-body-row-value {
    align-items: center;
    display: flex;
    height: 5em;
    justify-content: center;
    width: 3em;
    text-align: center;
}

.section-line-ups {
    display: none;
    width: 30%;
}

.volleyball-line-ups-wrapper {
    justify-content: space-between;
    display: flex;
}

.volleyball-line-ups-home-team {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.volleyball-line-ups-home-team-name {
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
    margin-bottom: 0.5em;
}

.volleyball-line-ups-away-team {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.volleyball-line-ups-away-team-name {
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
    margin-bottom: 0.5em;
}

.section-summary {
    display: none;
}

.summary-game-num {
    text-align: center;
}

.section-summary .event-row:nth-child(2n) {
    background: #bae9fb
}

.active-filter {
    display: block;
}
</style>