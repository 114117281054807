<template>
    <TheUserDashboard :onUserLoad="onUserLoaded" title="Dashboard">
        <a :href="downloadLinkUri" download="sync_calendar.ics" class="btn download-btn sync-calendar-btn" type="button" title="Download Calendar">Download Calendar</a>
        <ScheduleXCalendar :calendar-app="calendarApp" />
  </TheUserDashboard>
</template>
<script lang="ts">
import { Ref, defineComponent, onMounted, ref } from "vue";
import { key } from "@/store";
import { useStore } from "vuex";
import TheUserDashboard from "@/components/main/TheUserDashboard.vue";
import { useRouter } from "vue-router";
import { User } from "@/models/main/User";
import { default  as SportzoneTooltip } from '@/components/main/Tooltip.vue';
import { ScheduleXCalendar } from '@schedule-x/vue'
import {
  createCalendar,
  viewMonthGrid,
  viewMonthAgenda,
  viewDay,
  viewWeek,
} from '@schedule-x/calendar'
import '@schedule-x/theme-default/dist/index.css'
import { Container } from "@/models/main/Container";
import { getSportWatchViewName, formatStringDate } from '@/composables/common/Util'

type SportzoneCalendarEvent = { id: number, title: string, start: string, end: string }

export default defineComponent({
    components: {
    TheUserDashboard,
    SportzoneTooltip,
    ScheduleXCalendar,
    },
  setup() {
    const calendarApp = createCalendar({
        views: [viewMonthGrid, viewMonthAgenda, viewWeek, viewDay],
        defaultView: viewMonthGrid.name,
        events: [
        ],
    })
    const router = useRouter();
    const store = useStore(key);
    const currentUser: Ref<User | undefined> = ref();
    const onUserLoaded = async (user: User) => {
        currentUser.value = user
    }

    const calendarEvents: SportzoneCalendarEvent[] = []
    const loadContainers = async () => {
        await store.dispatch('containerStore/fetchContainers', {
            status: null,
            type: "Match",
            sport: null,
            organizationId: 17,
        });

        const containersList: Container[] = store.getters['containerStore/listContainers']({})
        for(let i = 0; i < containersList.length; i++) {
            const container = containersList[i]
            const containerAsEvent: SportzoneCalendarEvent = {
                    id: container.id ?? 0,
                    title: container.title ?? '',
                    start: container.startTime ?? '',//,
                    end: container?.endTime ?? container.startTime ?? '', 
                }
            calendarApp.events.add(containerAsEvent)
            calendarEvents.push(containerAsEvent)
        }
        generateCalendarDownloadLink(calendarEvents)
        console.info(calendarEvents)
    };
    const downloadLinkUri: Ref<string> = ref('')
    let icsContent = 'data:text/ics;charset=utf-8,'
    const newLine = '\n'
    const generateCalendarDownloadLink = (calendarEvents: SportzoneCalendarEvent[]) => {
      const timeNow = new Date()
      const calendarEventDateFormat = 'YYYYMMDDTHHmmss'
      let calendarEventAsString = 'BEGIN:VCALENDAR' + newLine
      calendarEventAsString += 'VERSION:2.0' + newLine
      calendarEventAsString += 'PRODID:-//portal.sportzone.live//Sportzone Calendar 1.0//EN' + newLine
      for (const calendarEvent of calendarEvents) {
        console.log(calendarEvent)
        calendarEventAsString += 'BEGIN:VEVENT' + newLine
        calendarEventAsString += 'UID:' + calendarEvent.id + newLine
        calendarEventAsString += 'SUMMARY:' + calendarEvent.title + newLine
        calendarEventAsString += 'DTSTAMP:' + formatStringDate(timeNow.toISOString(), calendarEventDateFormat) + newLine
        calendarEventAsString += 'DTSTART:' + formatStringDate(calendarEvent.start, calendarEventDateFormat) + newLine
        calendarEventAsString += 'DTEND:' + formatStringDate(calendarEvent.end, calendarEventDateFormat) + newLine
        calendarEventAsString += 'END:VEVENT' + newLine
      }
      calendarEventAsString += 'END:VCALENDAR'
      icsContent += calendarEventAsString
      downloadLinkUri.value = encodeURI(icsContent)
    }
    onMounted(loadContainers)

    return {
      store,
      router,
      currentUser,
      calendarApp,
      downloadLinkUri,
      onUserLoaded,
      getSportWatchViewName,
      generateCalendarDownloadLink
    };
  },
})

</script>

<style>
.sx-vue-calendar-wrapper {
    height: calc(100% - 0.75rem);
    width: 100%;
    margin-top: 0.75rem;
}
.sx__month-agenda-wrapper {
    height: 100%;
    display: grid;
    grid-template-rows: 4rem 1fr 1fr;
}
.sx__month-agenda-week {
  font-size: var(--sx-font-extra-large);
}

.sx__month-agenda-day-names {
  font-size: var(--sx-font-extra-large);
}


.sx__month-grid-day__header-day-name {
  font-size: var(--sx-font-extra-large);
}

.sx__month-grid-day__header-date {
  font-size: var(--sx-font-extra-large);
  margin-top: 0.5rem;
  height: 2rem;
  width: 2rem;
}

.sync-calendar-btn {
    margin-left: auto;
}
</style>