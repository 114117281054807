import GenericDictionary from '@/components/util/GenericDictionary';
import { Event } from '@/models/main/Event';
import { SnookerPointType, SNOOKER_IS_POSITIVE_POINT, SNOOKER_POINT_INCREMENT, SnookerGameControlType } from './SnookerEventTypes';
export default function useInterpretSnookerEvents() {
    const calculateEntityPoints = (entityId: number, events: GenericDictionary<Event>, eventsBefore?: number, forSpecificframeOnly?: number): number => {
        let points = 0;
        let currentFrame = 1;
        for (const i in events) {
            const event = events[i];
            const eventTimeStartInUnixSeconds = new Date(event.startTimestamp ?? 0).getTime() / 1000;
            if (eventsBefore === undefined || eventTimeStartInUnixSeconds <= eventsBefore) {
                const eventEntityId = event.entityId;
                if (event.type === 'Point' && (forSpecificframeOnly === undefined || currentFrame === forSpecificframeOnly)) {
                    const pointType = event.description as unknown as SnookerPointType;
                    if (eventEntityId === entityId) {
                        if (SNOOKER_IS_POSITIVE_POINT[pointType]) {
                            points += SNOOKER_POINT_INCREMENT[pointType];
                        }
                    }
                    else {
                        if (!SNOOKER_IS_POSITIVE_POINT[pointType]) {
                            points += SNOOKER_POINT_INCREMENT[pointType];
                        }
                    }
                }
                else if (event.type === 'GameControl') {
                    const gameControlType = event.description as unknown as SnookerGameControlType;
                    if (gameControlType === 'Snoo_FE') {
                        if (forSpecificframeOnly !== undefined && currentFrame === forSpecificframeOnly) {
                            return points;
                        }
                        else {
                            points = 0;
                            currentFrame++;
                        }
                    }
                    else if (gameControlType === 'Snoo_ME') {
                        if (forSpecificframeOnly !== undefined && currentFrame === forSpecificframeOnly) {
                            return points;
                        }
                        else {
                            points = 0;
                            currentFrame = 1;
                        }
                    }
                }
            }
        }
        return points;
    };
    const calculateEntityFrames = (entityId: number, events: GenericDictionary<Event>, eventsBefore?: number): number => {
        let frames = 0;
        for (const i in events) {
            const event = events[i];
            const eventTimeStartInUnixSeconds = new Date(event.startTimestamp ?? 0).getTime() / 1000;
            if (eventsBefore === undefined || eventTimeStartInUnixSeconds <= eventsBefore) {
                const eventEntityId = event.entityId;
                if (event.type === 'GameControl') {
                    const gameControlType = event.description as unknown as SnookerGameControlType;
                    if (gameControlType === 'Snoo_FE' && eventEntityId === entityId) {
                        frames++;
                    }
                    else if (gameControlType === 'Snoo_ME') {
                        frames = 0;
                    }
                }
            }
        }
        return frames;
    };
    const calculateEntityFaults = (entityId: number, events: GenericDictionary<Event>, eventsBefore?: number): any => {
        let faults: any = {
            Snoo_F4: 0,
            Snoo_F5: 0,
            Snoo_F6: 0,
            Snoo_F7: 0,
        };
        for (const i in events) {
            const event = events[i];
            const eventTimeStartInUnixSeconds = new Date(event.startTimestamp ?? 0).getTime() / 1000;
            if (eventsBefore === undefined || eventTimeStartInUnixSeconds <= eventsBefore) {
                const eventEntityId = event.entityId;
                if (event.type === 'Point') {
                    const pointType = event.description as unknown as SnookerPointType;
                    if (eventEntityId === entityId && !SNOOKER_IS_POSITIVE_POINT[pointType]) {
                        faults[pointType]++;
                    }
                }
                else if (event.type === 'GameControl') {
                    const gameControlType = event.description as unknown as SnookerGameControlType;
                    if (gameControlType === 'Snoo_FE' || gameControlType === 'Snoo_ME') {
                        faults = {
                            Snoo_F4: 0,
                            Snoo_F5: 0,
                            Snoo_F6: 0,
                            Snoo_F7: 0,
                        };
                    }
                }
            }
        }
        return faults;
    };
    return {
        calculateEntityPoints,
        calculateEntityFrames,
        calculateEntityFaults,
    };
};