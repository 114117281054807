<template>
  <TheOrganizerDashboard :onUserLoad="onUserLoaded">
    <div class="organizer-main-dashboard">
            <div class="badges-wrapper">
                <div class="main-badge">Organizations</div>
                <div class="main-badge">Events</div>
            </div>
            <TheMainSection>
            <template v-slot:default>
                <slot />
            </template>
        </TheMainSection>
    </div>
  </TheOrganizerDashboard>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { createNamespacedHelpers, useStore } from "vuex";
import { key } from "@/store";
import TheOrganizerDashboard from "@/components/main/TheOrganizerDashboard.vue";
import TheMainSection from "@/components/main/TheMainSection.vue";
import { useRouter } from "vue-router";
import { User } from "@/models/main/User";
import { DefaultUser } from "@/store/modules/main/UserStore";
const userStoreHelpers = createNamespacedHelpers("userStore");

export default defineComponent({
  components: {
    TheOrganizerDashboard,
    TheMainSection
  },
  ...userStoreHelpers.mapGetters(["getUser"]),
  setup() {
    const router = useRouter();
    const store = useStore(key);
    const user: User = new DefaultUser();
    const currentUser: any = ref(user);
    const onUserLoaded = (user: User) => {
        currentUser.value = user
    }
    const onFormSubmit = () => {
      store.dispatch("userStore/saveUser", currentUser.value).then((successfullyCreated) => {
        if (successfullyCreated) {
          router.push({ name: "Dashboard" });
        }
      });
    };

    return {
      store,
      router,
      currentUser,
      onFormSubmit,
      onUserLoaded,
    };
  },
});
</script>

<style>
.organizer-main-dashboard {
    width: 100%;
    height: 100%;
    background: url(/triangle_background.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.badges-wrapper {
  display: flex;
  justify-content: space-evenly;
  height: 100%;
}

div.main-badge {
    background: url(/sportzone-green-triangle.svg);
    background-repeat: no-repeat;
    height: 18ch;
    background-size: contain;
    display: inline-block;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    line-height: 18ch;
    width: 16ch;
    font-size: 36px;
    font-weight: bold;
    cursor: pointer;
}

div.main-badge:hover {
    background-color: var(--main-font-clr);
}
</style>