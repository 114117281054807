import GenericDictionary from "@/components/util/GenericDictionary";
import { PagedResponse, PaginatedItemsStoreGetters, PaginatedItemsStoreMutations } from "@/composables/common/Pagination";
import authModule from "@/main";
import { Sport } from "@/models/main/CommonTypes";
import { Organization } from "@/models/main/Organization";
import { OrganizationMember } from "@/models/main/OrganizationMember";
import { IRootState } from "@/store";
import { ActionContext, Module } from "vuex";

const state: OrganizationMemberStore = {
    paginatedItems: {
        items: {},
        pageSize: 15,
        currentPage: 1,
        nextPage: 1,
    }
};
export interface OrganizationMemberStore {
    paginatedItems: PagedResponse<OrganizationMember>,
    searchTerm?: string,
    nationality?: string
};

const getters = {
    getOrganizationMembers: (state: OrganizationMemberStore) => (searchTerm?: string, nationality?: string, organizationId?: number) => {
        let filteredOrganizationMembers: Array<OrganizationMember> = [];
        for (const organizationMemberId in state.paginatedItems.items) {
            if (Object.prototype.hasOwnProperty.call(state.paginatedItems.items, organizationMemberId)) {
                const organizationMember: OrganizationMember = state.paginatedItems.items[organizationMemberId] as OrganizationMember;
                let searchTermIsOk = false
                const nationalityIsOk = nationality === undefined || nationality === 'Nationality' || organizationMember.nationality === nationality
                const organizationIsOk = organizationId === undefined || organizationMember.organizationId === organizationId
                if(searchTerm && searchTerm.length > 2) {
                    searchTerm = searchTerm.toLowerCase().trim();
                    const matchesFirstName = organizationMember.firstName.toLowerCase().includes(searchTerm)
                    const matchesMiddleName = organizationMember.middleName?.toLowerCase().includes(searchTerm)
                    const matchesLastName = organizationMember.lastName.toLowerCase().includes(searchTerm)
                    searchTermIsOk = matchesFirstName || matchesMiddleName || matchesLastName;
                } else {
                    searchTermIsOk = true
                }
                if (searchTermIsOk && nationalityIsOk && organizationIsOk) {
                    filteredOrganizationMembers.push(organizationMember);
                }
            }
        }
        filteredOrganizationMembers = filteredOrganizationMembers.sort((a, b) => {
            const firstNameCompare = a.firstName.localeCompare(b.firstName)
            return  firstNameCompare === 0 ? a.lastName.localeCompare(b.lastName) : firstNameCompare;
        });
        return filteredOrganizationMembers;
    },
    getOrganizationMember: (state: OrganizationMemberStore) => (organizationMemberId: number) => {
        return state.paginatedItems.items[organizationMemberId];
    },
    getSearchTerm(state: OrganizationMemberStore) {
        return state.searchTerm
    },
    getNationality(state: OrganizationMemberStore) {
        return state.nationality
    },
    ...PaginatedItemsStoreGetters(),
}

const mutations = {
    addOrganizationMember(state: OrganizationMemberStore, organizationMember: OrganizationMember) {
        state.paginatedItems.items[organizationMember.id] = organizationMember;
    },
    editOrganizationMember(state: OrganizationMemberStore, organizationMember: OrganizationMember) {
        state.paginatedItems.items[organizationMember.id] = organizationMember;   
    },
    removeOrganizationMember(state: OrganizationMemberStore, organizationMemberId: number) {
        if (Object.prototype.hasOwnProperty.call(state.paginatedItems.items, organizationMemberId)) {
            delete state.paginatedItems.items[organizationMemberId];
        }
    },
    setSearchTerm(state: OrganizationMemberStore, searchTerm: string) {
        state.searchTerm = searchTerm
    },
    setNationality(state: OrganizationMemberStore, nationality: string) {
        state.nationality = nationality
    },
    ...PaginatedItemsStoreMutations(),
}

const actions = {
    addOrganizationMember({ commit }: ActionContext<OrganizationMemberStore, IRootState>, organizationMember: OrganizationMember) {
        commit('addOrganizationMember', organizationMember);
    },
    createOrganizationMember({ dispatch }: ActionContext<OrganizationMemberStore, IRootState>, organizationMember: OrganizationMember) {
        return authModule.post(`/api/organization/${organizationMember.organizationId}/member`, organizationMember)
            .then((response) => {
                const organizationMember: OrganizationMember = response.data as unknown as OrganizationMember;
                dispatch('addOrganizationMember', organizationMember);
                return organizationMember;
            });
    },
    editOrganizationMember({ commit }: ActionContext<OrganizationMemberStore, IRootState>, organizationMember: OrganizationMember) {
        return authModule.patch(`/api/organization/${organizationMember.organizationId}/member/${organizationMember.id}`, organizationMember)
            .then((response) => {
                const organizationMember: OrganizationMember = response.data as unknown as OrganizationMember;
                commit('editOrganizationMember', organizationMember);
                return organizationMember;
            });
    },
    deleteOrganizationMember({ commit }: ActionContext<OrganizationMemberStore, IRootState>, organizationMember: OrganizationMember) {
        return authModule.delete(`/api/organization/${organizationMember.organizationId}/member/${organizationMember.id}`)
            .then((response) => {
                const deletedOrganizationMemberId: number = response.data as unknown as number;
                commit('removeOrganizationMember', deletedOrganizationMemberId);
            });
    },
    fetchOrganizationMembers({ commit, dispatch, getters }: ActionContext<OrganizationMemberStore, IRootState>, { organizationId, searchTerm, nationality }: { organizationId: number, searchTerm?: string, nationality?: string }) {
        let filtersChanged = false;
        searchTerm = searchTerm?.trim()
        const currentSearchTerm = getters['getSearchTerm']
        if (currentSearchTerm !== searchTerm) {
            commit('setSearchTerm', searchTerm)
            filtersChanged = true
        }
        const currentNationality = getters['getNationality']
        if (nationality === 'Nationality') {
            nationality = undefined
        }
        if (currentNationality !== nationality) {
            commit('setNationality', nationality)
            filtersChanged = true
        }
        let pageToRequest = getters['getNextPage'];
        const pageSize = getters['getPageSize'];
        if (filtersChanged) {
            pageToRequest = 1;
        }
        if (!filtersChanged && getters['getNextPage'] === 1 && getters['getCurrentPage'] > 1) {
            return;
        }

        return authModule.get(`/api/organization/${organizationId}/member`, {
            params: {
                searchTerm: searchTerm !== undefined && searchTerm !== '' ? searchTerm : null,
                nationality: nationality !== undefined && nationality !== '' ? nationality : null,
                page: pageToRequest,
                pageSize: pageSize,
            },
        })
        .then((response) => {
            const pagedResponse: PagedResponse<OrganizationMember> = response.data as unknown as PagedResponse<OrganizationMember>;
            commit('setCurrentPage', pagedResponse.currentPage);
            commit('setPageSize', pagedResponse.pageSize);
            commit('setNextPage', pagedResponse.nextPage);
            commit('setPreviousPage', pagedResponse.previousPage);
            for (const containerIndex in pagedResponse.items) {
                dispatch('addOrganizationMember', pagedResponse.items[containerIndex]);
            }
            return pagedResponse.items
        })
        .catch((error) => {
            console.error(error);
        });
    },
    uploadPicture({ }: ActionContext<OrganizationMemberStore, IRootState>, { memberId, memberPicture }: { memberId: number, memberPicture: File }) {
        return authModule
            .post(`/api/member/${memberId}/picture`, memberPicture, {
                headers: {
                    'Content-Type': memberPicture.type,
                },
            });
    },
}

const organizationMemberStore: Module<OrganizationMemberStore, IRootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
export default organizationMemberStore;