<template>
    <TheOrganizerDashboard :onUserLoad="onUserLoaded">
        <template #heading>
            <router-link :to="{ name: 'Organizations' }">
                <div class="page-heading">
                    Organizations
                </div>
            </router-link>
            <router-link :to="{ name: 'Organization Menu', params: { id: organization?.id } }">
                <div class="page-heading">
                    {{ organization?.name }}
                </div>
            </router-link>
            <router-link :to="{ name: 'Organization Members', params: { id: organization?.id } }">
                <div class="page-heading">
                    Individuals
                </div>
            </router-link>
        </template>
        <template #default>
            <div class="organizations-wrapper">
                <div class="filter-members-wrapper">
                    <div class="spz-text-input-group narrow">
                        <label for="search-term" v-if="false">Search: </label>
                        <input v-model.trim="organizationMemberStore.searchTerm" id="search-term" type="search" placeholder="Search">
                    </div>
                    <SportzoneSelect id="nationality-filter"
                        v-model:selectedOption="organizationMemberStore.nationality" :options="['Nationality', ...nationalitiesComputed]" />
                    <button @click="onCreateMemberClick" class="btn add-btn add-member-btn" title="Add">
                        Member</button>
                    <router-link :to="{ name: 'Organization Applications', params: { id: organization?.id } }"
                        class="btn accept-btn btn-link application-members-link">Applications</router-link>
                    <router-link :to="{ name: 'Organization Invitations', params: { id: organization?.id } }"
                        class="btn send-btn btn-link invite-members-link">Invite</router-link>
                </div>
                <div class="organization-members-wrapper" v-infinite-scroll="[organizationMemberStore.fetchOrganizationMembers, { interval: 400, canLoadMore: () => { return !organizationMemberStore.showLoadingIcon && organizationMemberStore.thereIsNextMembersPage } }]" >
                    <template v-if="organizationMemberStore.getOrganizationMembers.length > 0" >
                        <OrganizationMemberItem v-for="organizationMember in organizationMemberStore.getOrganizationMembers" :editable="true"
                        :key="organizationMember.id" @edit="onEditMemberClick" @delete="onDeleteMember"
                            :member="organizationMember" />
                    </template>
                    <template v-else>
                        <div class="spz-system-message">
                            There are no members yet
                        </div>
                    </template>
                </div>
            </div>
            <Modal ref="memberPopup">
                <template v-slot:header>
                    <h1>{{ isCreate ? 'Create Member' : 'Edit Member' }}</h1>
                </template>
                <template v-slot:body v-if="currentMember">
                    <div class="spz-img-input-group player-picture-input">
                        <label for="player-picture">
                            <img :src="memberPictureUrl" @error="setDefaultMemberPicture" :alt="currentMember.firstName"
                                width="68" height="68" />
                        </label>
                        <input v-show="false" type="file" id="player-picture" @change="onMemberPictureChange"
                            accept="image/jpeg, image/png, image/webp, image/avif" />
                    </div>
                    <div class="spz-text-input-group" v-if="false">
                        <label for="player_number">Member ID</label>
                        <input v-model="currentMember.memberUID" type="text" id="member_uid"
                            placeholder="Example: 14264813" />
                    </div>
                    <div class="spz-text-input-group">
                        <label for="first_name">First Name</label>
                        <input v-model.lazy="currentMember.firstName" type="text" id="first_name" required />
                    </div>
                    <div class="spz-text-input-group">
                        <label for="middle_name">Middle Name</label>
                        <input v-model.lazy="currentMember.middleName" type="text" id="middle_name" />
                    </div>
                    <div class="spz-text-input-group">
                        <label for="last_name">Last Name</label>
                        <input v-model.lazy="currentMember.lastName" type="text" id="last_name" />
                    </div>
                    <div class="spz-text-input-group">
                        <label for="birthday">Birthday</label>
                        <input v-model.lazy="currentMember.birthday" type="text" id="birthday" />
                    </div>
                    <div class="spz-multi-toggle-group">
                        <div class="spz-multi-toggle-name">Sex</div>
                        <div class="spz-multi-toggle_options">
                            <div class="spz-multi-toggle_option">
                                <input type="radio" id="sex-female" value="Female" name="sex" v-model="currentMember.sex"
                                    required />
                                <label for="sex-female">Female</label>
                            </div>
                            <div class="spz-multi-toggle_option">
                                <input type="radio" id="sex-male" value="Male" name="sex" v-model="currentMember.sex"
                                    required />
                                <label for="sex-male">Male</label>
                            </div>
                            <div class="spz-multi-toggle_option">
                                <input type="radio" id="sex-other" value="Other" name="sex" v-model="currentMember.sex"
                                    required />
                                <label for="sex-other">Other</label>
                            </div>
                        </div>
                    </div>
                    <div class="spz-select-group">
                        <label for="nationality">Nationality</label>
                        <SportzoneSelect id="nationality" v-model:selectedOption="currentMember.nationality"
                            :options="['Please select one', ...nationalitiesComputed]" />
                    </div>
                    <!-- <div class="spz-text-input-group">
                    <label for="height">Height</label>
                    <input v-model.lazy="currentMember.height" id="height" type="text" placeholder="Height" />
                    </div>
                    <div class="spz-text-input-group">
                    <label for="weight">Weight</label>
                    <input v-model.lazy="currentMember.weight" id="weight" type="text" placeholder="Weight" />
                    </div> -->
                    <div class="spz-multi-toggle-group" v-if="false">
                        <div class="spz-multi-toggle_options">
                            <div class="spz-multi-toggle_option">
                                <input type="radio" id="active" value="true" name="active"
                                    v-model="currentMember.isActive" />
                                <label for="active">Active</label>
                            </div>
                            <div class="spz-multi-toggle_option">
                                <input type="radio" id="inactive" value="false" name="active"
                                    v-model="currentMember.isActive" />
                                <label for="inactive">Inactive</label>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-slot:footer>
                    <div>
                        <button @click="memberPopup.closeModal(), onFormSubmitMember()" class="btn"
                            :class="{ 'add-btn': isCreate, 'edit-btn': !isCreate }">
                            {{ isCreate ? 'Create' : 'Edit' }}</button>
                        <button @click="memberPopup.closeModal()" class="btn btn-inverse">Cancel</button>
                    </div>
                </template>
            </Modal>
        </template>
    </TheOrganizerDashboard>
</template>
<script setup lang="ts">
import { useStore } from 'vuex';
import { Ref, ref, computed, onMounted } from 'vue';
import TheOrganizerDashboard from '@/components/main/TheOrganizerDashboard.vue';
import { Organization } from '@/models/main/Organization';
import { key } from '@/store';
import { useRoute } from 'vue-router';
import { User } from '@/models/main/User';
import { getMemberPictureSrc, setDefaultMemberPicture } from '@/composables/common/Util';
import '@vuepic/vue-datepicker/dist/main.css';
import { OrganizationMember } from '@/models/main/OrganizationMember';
import SportzoneSelect from '@/components/util/SportzoneSelect.vue'
import { useToast } from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import Modal from '@/components/main/Modal.vue'
import OrganizationMemberItem from '@/components/main/OrganizationMemberItem.vue';
import { vInfiniteScroll } from '@vueuse/components'
import { useOrganizationMembersStoreStore } from '@/stores/OrganizationMembersStore'

const nationalities = require('i18n-nationality')
nationalities.registerLocale(require('i18n-nationality/langs/en.json'))

const organization: Ref<Organization | undefined> = ref();
const store = useStore(key);
const organizationMemberStore = useOrganizationMembersStoreStore()
const route = useRoute();
const organizationId: Ref<number> = ref(parseInt(route.params.id as string));
organizationMemberStore.setOrganizationId(organizationId.value)
const $toast = useToast()
const onUserLoaded = (user: User) => {
};

const isCreate: Ref<boolean> = ref(false)
const memberPopup: Ref<any> = ref(null)
onMounted(async () => {
    organizationMemberStore.fetchOrganizationMembers()
    organization.value = await store.dispatch('organizationStore/getOrganization', organizationId.value);
    // await store.dispatch('organizationMemberStore/mergeOrganizationMembers', { organizationId: organizationId.value, survivorMemberId: 1013, victimMemberIds: [1112]})
})
const currentMember: Ref<OrganizationMember | undefined> = ref()
const onDeleteMember = async (organizationMember: OrganizationMember) => {
    await organizationMemberStore.deleteOrganizationMember(organizationMember);
    $toast.success('Deleted')
};

const onEditMemberClick = (organizationMember: OrganizationMember) => {
    isCreate.value = false
    memberUploadedPictureUrl.value = undefined
    currentMember.value = organizationMember
    memberPopup.value.openModal()
}


const onCreateMemberClick = () => {
    memberUploadedPictureUrl.value = undefined
    const defaultNewMember: OrganizationMember = {
        organizationId: organizationId.value,
        isActive: true,
        id: 0,
        dateEnrolled: new Date(),
        firstName: '',
        lastName: '',
        memberUID: '',
        sex: currentMember.value?.sex,
        nationality: currentMember.value?.nationality
    }
    currentMember.value = defaultNewMember
    isCreate.value = true
    memberPopup.value.openModal()
}

const onFormSubmitMember = async () => {
    if (!currentMember.value) {
        console.error("No Member to save!")
        return
    }

    if (isCreate.value) {
        const createdMember = await organizationMemberStore.createOrganizationMember(currentMember.value)
        $toast.success("Created")
        currentMember.value.id = createdMember.id
        if (memberPictureToUpload.value) {
            await organizationMemberStore.uploadPicture({ memberId: currentMember.value.id, memberPicture: memberPictureToUpload.value })
            $toast.success("Uploaded")
        }
    } else {
        await organizationMemberStore.editOrganizationMember(currentMember.value)
        $toast.success("Edited")
        if (memberPictureToUpload.value) {
            await organizationMemberStore.uploadPicture({ memberId: currentMember.value.id, memberPicture: memberPictureToUpload.value })
            $toast.success("Uploaded")
        }
    }
}

const nationalitiesComputed = computed(() => {
    const list = nationalities.getNames('en')
    const result = Object
        .keys(list)
        .map((key) => (list[key]))
    return result
})

const memberUploadedPictureUrl: Ref<string | undefined> = ref()
const memberPictureUrl = computed(() => {
    return memberUploadedPictureUrl.value ?? getMemberPictureSrc(currentMember.value)
})
const memberPictureToUpload: Ref<File | undefined> = ref()
const onMemberPictureChange = (e: Event) => {
    if (e.target) {
        const inputElement = e.target as HTMLInputElement
        if (inputElement.files && inputElement.files.length > 0) {
            const imageFile = inputElement.files[0];
            const tempImageURL = URL.createObjectURL(imageFile)
            memberUploadedPictureUrl.value = tempImageURL
            memberPictureToUpload.value = imageFile
        }
    }
}
</script>
<style>
.organization-members-wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;
    scrollbar-width: thin;
    max-height: calc(100% - 10em);
    overflow-y: auto;
    scrollbar-color: var(--main-font-clr) var(--bright-font-clr);
}

.organization-member-list-badge {
    --organization-member-list-badge-height: 5em;
    height: var(--organization-member-list-badge-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: var(--default-border-rad);
    background-color: var(--bright-font-clr);
    margin-bottom: 0.5em;
    padding-right: 1em;
}

.organization-member-image.badge-item-col {
    height: 100%;
    width: var(--organization-member-list-badge-height);
    background-repeat: no-repeat;
    background-size: contain;
    align-items: center;
    padding: 0.25em 0;
}

.organization-member-image>object,
.organization-member-image>img {
    border-radius: var(--default-border-rad);
    max-width: 100%;
}

.organization-member-name {
    width: calc((70% - var(--organization-member-list-badge-height)) / 2);
    font-weight: 500;
}

.organization-member-enrolled-date {
    width: calc((70% - var(--organization-member-list-badge-height)) / 2);
}

.invite-member-btn {
    margin-bottom: 1em;
    float: right;
    margin-left: 0.5em;
}

.invite-member-btn:last-child {
    margin-left: 0;
}

.organization-member-status.status-pending {
    position: relative;
    padding-left: 1.5em;
}

.organization-member-status.status-pending::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background-image: url('/pending-icon.svg');
    height: 1.25em;
    width: 1.25em;
    background-size: cover;
}

/* .add-member-btn,
.invite-members-link,
.application-members-link {
    float: right;
} */

.application-members-link {
    margin-right: 1em;
}

.organization-member-name,
.organization-member-enrolled-date,
.organization-member-status {
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 1em;
}

.organization-member-status,
.organization-member-edit-control,
.organization-member-delete-control {
    width: 10%;
}

.filter-members-wrapper {
    display: flex;
    align-items: center;
    gap: 2rem;
    justify-content: flex-start;
    margin-bottom: 1.5rem;
}

.filter-members-wrapper>a.btn,
.filter-members-wrapper>* {
    margin: 0;
}

.filter-members-wrapper>.btn.add-member-btn {
    margin-left: auto;
    /* why this works: https://stackoverflow.com/questions/32551291/in-css-flexbox-why-are-there-no-justify-items-and-justify-self-properties */
}</style>