import { SportzoneAuth } from '@/components/util/SportzoneAuth';
import GenericDictionary from '@/components/util/GenericDictionary';
import authModule from '@/main';
import { Camera } from '@/models/main/Camera';
import Channel from '@/models/main/Channel';
import Livestream, { SaveLivestream } from '@/models/main/Livestream';
import { IdentityProvider, ProviderUser } from '@/models/main/ProviderUser';
import Station from '@/models/main/Station';
import StreamingEndpoint from '@/models/main/StreamingEndpoint';
import { Sex, SportzoneRole, User } from '@/models/main/User';
import { IRootState } from '@/store';
import { ActionContext, Module } from 'vuex';
import axios from 'axios';
import { PagedResponse, PaginatedItemsStore, PaginatedItemsStoreGetters, PaginatedItemsStoreMutations } from '@/composables/common/Pagination';
import UserFavouriteLivestream from '@/models/main/UserFavouriteLivestream';
import { useRouter } from 'vue-router';
import { UserRequest } from '@/models/main/UserRequest'
import { Player } from '@/models/main/Player';
import { Sport } from '@/models/main/CommonTypes'
import { UserSportPreference } from '@/models/main/UserSportPreference'
import { OrganizationFollow } from '@/models/main/OrganizationFollow';
import { EntityFollow } from '@/models/main/EntityFollow'
import { PlayerFollow } from '@/models/main/PlayerFollow'
export class DefaultUser implements User {
    id = 0;
    firstName = '';
    middleName = '';
    lastName = '';
    sex: Sex = 'Not Set';
    birthday?: Date;
    email = '';
    nationality = '';
    providerUsers: ProviderUser[] = [];
    stations: Station[] = [];
    channels: Channel[] = [];
    userRoles: SportzoneRole[] = [];
    favouriteLivestreams: GenericDictionary<Livestream> = {};
    players: GenericDictionary<Player> = {}
};
export interface UserStore extends PaginatedItemsStore<User> {
    currentUser: User,
    users: GenericDictionary<User>,
    paginatedItems: PagedResponse<User>,
    nationality?: string,
    searchTerm?: string,
};
const state: UserStore = {
    currentUser: new DefaultUser(),
    users: {},
    paginatedItems: {
        items: {},
        pageSize: 30,
        currentPage: 1,
    },
};
const getters = {
    getUser(state: UserStore) {
        return state.currentUser;
    },
    getId(state: UserStore) {
        return state.currentUser?.id;
    },
    getUserLivestreams(state: UserStore): Array<Livestream> {
        if (state.currentUser?.channels) {
            const flattedLivestreams = state.currentUser.channels.flatMap(c => c.streamingEndpoints.flatMap(e => e.livestreams ? e.livestreams : []));
            return flattedLivestreams.sort((a, b) => {
                if (a.startTime && b.startTime) {
                    const aDate = new Date(a.startTime);
                    const bDate = new Date(b.startTime);
                    let aDateValue;
                    let bDateValue;
                    return isFinite(aDateValue = aDate.valueOf()) && isFinite(bDateValue = bDate.valueOf()) ? bDateValue - aDateValue : 0;
                }
                else {
                    return 0;
                }
            });
        }
        return [];
    },
    getUserStreamingEndpoints(state: UserStore): Array<StreamingEndpoint> {
        if (state.currentUser?.channels) {
            const flattedStreamingEndpoints = state.currentUser.channels.flatMap(c => c.streamingEndpoints ? c.streamingEndpoints : []);
            return flattedStreamingEndpoints;
        }
        return [];
    },
    getUserCameras(state: UserStore): Array<Camera> {
        if (state.currentUser?.stations) {
            const flattedCameras = state.currentUser.stations.flatMap(s => s.cameras ? s.cameras : []);
            return flattedCameras;
        }
        return [];
    },
    getUserStations(state: UserStore): Array<Station> {
        if(state.currentUser?.stations) {
            return state.currentUser.stations;
        }

        return []
    },
    getUserChannels(state: UserStore): Array<Channel> {
        return state.currentUser.channels ?? [];
    },
    getFavouriteLivestreams: (state: UserStore) => () => {
        const sort: string = 'DESC'
        const userId = state.currentUser.id;
        if (userId) {
            const user = state.currentUser
            const filteredFavouriteLivestreams: Array<Livestream> = [];
            for(const livestreamId in user.favouriteLivestreams) {
                const livestream = user.favouriteLivestreams[livestreamId]
                filteredFavouriteLivestreams.push(livestream)
            }

            const sortedLivestreams = filteredFavouriteLivestreams.sort((a, b) => {
                if (a.startTime && b.startTime) {
                    const aDate = new Date(a.startTime);
                    const bDate = new Date(b.startTime);
                    let aDateValue;
                    let bDateValue;
                    return isFinite(aDateValue = aDate.valueOf()) && isFinite(bDateValue = bDate.valueOf())
                        ? (sort === 'ASC'
                            ? aDateValue - bDateValue
                            : bDateValue - aDateValue)
                        : 0;
                }
                else {
                    return 0;
                }
            });
            console.log(sortedLivestreams);
            return sortedLivestreams;
        }

        return []
    },
    getUsers: (state: UserStore) => (searchTerm?: string, nationality?: string) => {
        let filteredUsers: Array<User> = [];
        for (const userId in state.paginatedItems.items) {
            if (Object.prototype.hasOwnProperty.call(state.paginatedItems.items, userId)) {
                const user: User = state.paginatedItems.items[userId] as User;
                const nationalityIsOk = nationality === undefined || nationality === 'Nationality' || user.nationality === nationality;
                let searchTermIsOk = false;
                if(searchTerm) {
                    searchTerm = searchTerm.toLowerCase().trim();
                    const matchesFirstName = user.firstName?.toLowerCase().includes(searchTerm) ?? true;
                    const matchesMiddleName = user.middleName?.toLowerCase().includes(searchTerm) ?? true;
                    const matchesLastName = user.lastName?.toLowerCase().includes(searchTerm) ?? true;
                    searchTermIsOk = matchesFirstName || matchesMiddleName || matchesLastName;
                } else {
                    searchTermIsOk = true;
                }
                if (nationalityIsOk && searchTermIsOk) {
                    filteredUsers.push(user);
                }
            }
        }
        filteredUsers = filteredUsers.sort((a, b) => {
            return (a.firstName ?? '').localeCompare(b.firstName ?? '');
        });
        return filteredUsers;
    },
    getNationalityFilter(state: UserStore) {
        return state.nationality;
    },
    getSearchTerm(state: UserStore) {
        return state.searchTerm;
    },
    ...PaginatedItemsStoreGetters(),
};
const mutations = {
    setId(state: UserStore, payload: number) {
        state.currentUser.id = payload;
    },
    setFirstName(state: UserStore, payload: string) {
        state.currentUser.firstName = payload;
    },
    setMiddleName(state: UserStore, payload: string) {
        state.currentUser.middleName = payload;
    },
    setLastName(state: UserStore, payload: string) {
        state.currentUser.lastName = payload;
    },
    setSex(state: UserStore, payload: Sex) {
        state.currentUser.sex = payload;
    },
    setBirthday(state: UserStore, payload: Date) {
        state.currentUser.birthday = payload;
    },
    setEmail(state: UserStore, payload: string) {
        state.currentUser.email = payload;
    },
    setNationality(state: UserStore, payload: string) {
        state.currentUser.nationality = payload;
    },
    setProviderUsers(state: UserStore, payload: ProviderUser[]) {
        state.currentUser.providerUsers = payload;
    },
    setRoles(state: UserStore, userRoles: SportzoneRole[]) {
        state.currentUser.userRoles = userRoles;
    },
    setStations(state: UserStore, payload: Station[]) {
        state.currentUser.stations = payload;
    },
    setCamera(state: UserStore, payload: Camera) {
        const stationId = payload.station?.id;
        if (stationId) {
            for (const stationKey in state.currentUser.stations) {
                const station = state.currentUser.stations[stationKey];
                if (station.id === stationId) {
                    state.currentUser.stations[stationKey].cameras.push(payload);
                    break;
                }
            }
        }
    },
    removeCamera(state: UserStore, cameraId: number) {
        for (const stationKey in state.currentUser.stations) {
            state.currentUser.stations[stationKey].cameras = state.currentUser.stations[stationKey].cameras.filter((c) => c.id !== cameraId);
        }
    },
    setChannels(state: UserStore, payload: Array<Channel>) {
        state.currentUser.channels = payload;
    },
    addChannel(state: UserStore, payload: Channel) {
        state.currentUser.channels.push(payload);
    },
    removeChannel(state: UserStore, channelId: number) {
        state.currentUser.channels = state.currentUser.channels.filter(c => c.id !== channelId);
    },
    addUser(state: UserStore, user: User) {
        if (user.id !== undefined)
            state.paginatedItems.items[user.id] = user;
    },
    addFavouriteLivestream(state: UserStore, userFavouriteLivestream: UserFavouriteLivestream) {
        if (userFavouriteLivestream.userId !== undefined) {
            state.currentUser.favouriteLivestreams[userFavouriteLivestream.livestreamId] = userFavouriteLivestream.livestream
        }
    },
    removeUser(state: UserStore, userId: number) {
        if (Object.prototype.hasOwnProperty.call(state.paginatedItems.items, userId)) {
            delete state.paginatedItems.items[userId];
        }
    },
    setNationalityFilter(state: UserStore, nationality: string) {
        state.nationality = nationality;
    },
    resetUsers(state: UserStore) {
        state.paginatedItems.items = {};
    },
    setSearchTerm(state: UserStore, searchTerm: string) {
        state.searchTerm = searchTerm;
    },
    ...PaginatedItemsStoreMutations(),
};
const actions = {
    getAPIAccessToken({ commit }: ActionContext<UserStore, IRootState>, { authBody, provider }: { authBody: Record<string, unknown>, provider: IdentityProvider }) {
        if (!SportzoneAuth.prodApiUri) {
            console.error('Define VUE_APP_PROD_API_URI ENV VARIABLE');
            return null;
        }
        return axios
            .post(`${SportzoneAuth.prodApiUri}/.auth/login/${provider}`, authBody)
            .then((response) => {
                if (response.data.authenticationToken) {
                    authModule.token = response.data.authenticationToken;
                    return response.data.authenticationToken;
                }
                else {
                    console.error('Received invalid auth response!');
                }
            })
            .catch((error) => {
                console.error(error);
            });
    },
    loginUser({ commit }: ActionContext<UserStore, IRootState>) {
        return authModule
            .post(`/api/user/login`)
            .then((response) => {
                const providerUser: ProviderUser = response.data as unknown as ProviderUser;
                commit('setProviderUsers', [providerUser]);
            })
            .catch((error) => {
                console.error(error);
            });
    },
    setUser({ commit }: ActionContext<UserStore, IRootState>, user: User) {
        commit('setId', user.id);
        commit('setFirstName', user.firstName);
        commit('setMiddleName', user.middleName);
        commit('setLastName', user.lastName);
        commit('setSex', user.sex);
        commit('setBirthday', user.birthday);
        commit('setEmail', user.email);
        commit('setNationality', user.nationality);
        commit('setRoles', user.userRoles);
    },
    getCurrentUser({ commit, dispatch }: ActionContext<UserStore, IRootState>) {
        return authModule
            .get(`/api/user/current`)
            .then((response) => {
                const user: User = response.data as unknown as User;
                dispatch('setUser', user);
                return user;
            }, (err) => {
                if (err.response.status === 404) {
                    console.log('User is not yet created');
                    return null;
                }

                throw err;
            });
    },
    listUsers({ commit, dispatch, getters }: ActionContext<UserStore, IRootState>, { searchTerm, nationality }: { searchTerm?: string, nationality?: string }) {
        searchTerm = searchTerm?.trim();
        const currentNationality = getters['getNationalityFilter'];
        const currentSearchTerm = getters['getSearchTerm'];
        if(nationality === 'Nationality') {
            nationality = undefined
        }
        let filtersChanged = false;
        if (currentNationality !== nationality) {
            commit('setNationality', nationality);
            filtersChanged = true;
        }
        if (currentSearchTerm !== searchTerm) {
            commit('setSearchTerm', searchTerm);
            filtersChanged = true;
        }
        let pageToRequest = getters['getNextPage'];
        const pageSize = getters['getPageSize'];
        if (filtersChanged) {
            pageToRequest = 1;
        }
        if (!filtersChanged && getters['getNextPage'] === 1 && getters['getCurrentPage'] > 1) {
            return;
        }
        return authModule.get(`/api/user`, {
            params: {
                nationality: nationality !== undefined && nationality !== '' ? nationality : null,
                searchTerm: searchTerm !== undefined && searchTerm !== '' ? searchTerm : null,
                page: pageToRequest,
                pageSize: pageSize,
            },
        }).then((response) => {
            const pagedResponse: PagedResponse<User> = response.data as unknown as PagedResponse<User>;
            commit('setCurrentPage', pagedResponse.currentPage);
            commit('setPageSize', pagedResponse.pageSize);
            commit('setNextPage', pagedResponse.nextPage);
            commit('setPreviousPage', pagedResponse.previousPage);
            for (const userIndex in pagedResponse.items) {
                commit('addUser', pagedResponse.items[userIndex]);
            }
            return pagedResponse.items;
        }, (err) => {
            if (err.response.status !== 404) {
                console.error(err);
                return null;
            }
            console.log('Could not get users!');
            return null;
        });
    },
    getUserStations({ commit }: ActionContext<UserStore, IRootState>, userId: number) {
        return authModule
            .get(`/api/user/station`)
            .then((response) => {
                const userStations: Array<Station> = response.data as unknown as Array<Station>;
                commit('setStations', userStations);
            })
            .catch((error) => {
                console.error(error);
            });
    },
    saveCamera({ commit }: ActionContext<UserStore, IRootState>, camera: Camera) {
        return authModule
            .put(`/api/camera`, camera)
            .then((response) => {
                const createdCamera: Camera = response.data as unknown as Camera;
                commit('setCamera', createdCamera);
                return createdCamera;
            });
    },
    deleteCamera({ commit }: ActionContext<UserStore, IRootState>, cameraId: number) {
        return authModule
            .delete(`/api/camera/${cameraId}`)
            .then((response) => {
                const deletedCameraId: number = response.data as unknown as number;
                commit('removeCamera', deletedCameraId);
            });
    },
    saveUser({ commit, dispatch, rootGetters }: ActionContext<UserStore, IRootState>, userToSave: User) {
        if (userToSave.id === 0) {
            return authModule
                .post(`/api/user`, userToSave)
                .then((response) => {
                    const user: User = response.data as unknown as User;
                    dispatch('setUser', user);
                    const providerUser = rootGetters['providerUserStore/getProviderUser'];
                    if (providerUser === null) {
                        console.error('User has not logged in! No user provider found!');
                        return false;
                    }
                    return true;
                });
        }
        else {
            return authModule
                .patch(`/api/user/${userToSave.id}`, userToSave)
                .then((response) => {
                    const updatedUser: User = response.data as unknown as User;
                    commit('addUser', updatedUser);
                    return false;
                });
        }
    },
    createUser({ commit }: ActionContext<UserStore, IRootState>, user: User) {
        return authModule
            .post(`/api/organizer/user`, user)
            .then((response) => {
                const user: User = response.data as unknown as User;
                commit('addUser', user);
                return user;
            });
    },
    editUser({ commit }: ActionContext<UserStore, IRootState>, user: User) {
        return authModule
            .patch(`/api/user/${user.id}`, user)
            .then((response) => {
                const user: User = response.data as unknown as User;
                commit('addUser', user);
                return user;
            });
    },
    getUserChannels({ commit }: ActionContext<UserStore, IRootState>, userId: number) {
        return authModule
            .get(`/api/user/channel`)
            .then((response) => {
                const userChannels: Array<Channel> = response.data as unknown as Array<Channel>;
                commit('setChannels', userChannels);
            })
            .catch((error) => {
                console.error(error);
            });
    },
    saveChannel({ commit }: ActionContext<UserStore, IRootState>, { channel, accessCode, streamingPlatform }: { channel: Channel, accessCode: string, streamingPlatform: string }) {
        return authModule
            .put(`/api/channel/`, { referrer: document.location.origin, accessCode, streamingPlatform, ...channel })
            .then((response) => {
                const createdChannel: Channel = response.data as unknown as Channel;
                commit('addChannel', createdChannel);
            });
    },
    deleteChannel({ commit }: ActionContext<UserStore, IRootState>, channelId: number) {
        return authModule
            .delete(`/api/channel/${channelId}`)
            .then((response) => {
                const deletedChannelId: number = response.data as unknown as number;
                commit('removeChannel', deletedChannelId);
            });
    },
    deleteUser({ commit }: ActionContext<UserStore, IRootState>, userId: number) {
        return authModule
            .delete(`/api/user/${userId}`)
            .then((response) => {
                const deletedUserId: number = response.data as unknown as number;
                commit('removeUser', deletedUserId);
            });
    },
    deleteData({ commit }: ActionContext<UserStore, IRootState>) {
        return authModule
            .delete(`/api/user`)
            .then((response) => {
                const deletedUserId: number = response.data as unknown as number;
                commit('removeUser', deletedUserId);
            });
    },
    uploadProfilePicture({ commit }: ActionContext<UserStore, IRootState>, { userId, profilePicture }: { userId: number, profilePicture: File }) {
        return authModule
            .post(`/api/user/${userId}/picture`, profilePicture, {
                headers: {
                    'Content-Type': profilePicture.type,
                },
            })
            .then((response) => {
                const createdLivestream: Livestream = response.data as unknown as Livestream;
            });
    },
    getFavouriteLivestreams({ commit, getters }: ActionContext<UserStore, IRootState>) {
        const pageToRequest = getters['getNextPage'];
        const pageSize = getters['getPageSize'];
        if (getters['getNextPage'] === 0 && getters['getCurrentPage'] > 0) {
            return;
        }
        return authModule
            .get(`/api/user/livestream/favourite?page=${pageToRequest}&pageSize=${pageSize}`)
            .then((response) => {
                const pagedResponse: PagedResponse<UserFavouriteLivestream> = response.data as unknown as PagedResponse<UserFavouriteLivestream>;
                commit('setCurrentPage', pagedResponse.currentPage);
                commit('setPageSize', pagedResponse.pageSize);
                commit('setNextPage', pagedResponse.nextPage);
                commit('setPreviousPage', pagedResponse.previousPage);
                for (const livestreamIndex in pagedResponse.items) {
                    commit('addFavouriteLivestream', pagedResponse.items[livestreamIndex]);
                }
            });
    },
    addFavouriteLivestream({ commit }: ActionContext<UserStore, IRootState>, livestreamId: number) {
        return authModule
            .put(`/api/user/livestream/favourite/${livestreamId}`)
            .then((response) => {
                const addedFavouriteLivestream: UserFavouriteLivestream = response.data as unknown as UserFavouriteLivestream;
                commit('addFavouriteLivestream', addedFavouriteLivestream);
            });
    },
    createUserRequest({ commit }: ActionContext<UserStore, IRootState>, userRequest: UserRequest) {
        return authModule
            .post(`/api/user/request`, userRequest)
            .then((response) => {
                const createdUserRequest: UserRequest = response.data as unknown as UserRequest
                commit('addUserRequest', createdUserRequest) //TODO: Add mutation
            })
    },
    updateUserSportPreferences({}: ActionContext<UserStore, IRootState>, { sportNames, defaultSport }: { sportNames: Sport[], defaultSport: Sport }) {
        return authModule
            .put('/api/user/prefered/sport', {
                sport: sportNames,
                defaultSport: defaultSport
            })
            .then((response) => {
                const updatedUserSportPreferences: UserSportPreference[] = response.data as unknown as UserSportPreference[]
                return updatedUserSportPreferences
            })
    },
    followOrganization({ }: ActionContext<UserStore, IRootState>, organizationFollow: OrganizationFollow) {
        return authModule
            .post(`/api/user/organization/${organizationFollow.organizationId}/follow`, organizationFollow)
            .then((response) => {
                const createdOrganizationFollow: OrganizationFollow = response.data as unknown as OrganizationFollow
                return createdOrganizationFollow
            })
    },
    unfollowOrganization({ }: ActionContext<UserStore, IRootState>, organizationFollowId: number) {
        return authModule
            .delete(`/api/user/organization/${organizationFollowId}/follow`)
            .then((response) => {
                const deletedOrganizationFollowId: number = response.data as unknown as number
                return deletedOrganizationFollowId
            })
    },
    followEntity({ }: ActionContext<UserStore, IRootState>, entityFollow: EntityFollow) {
        return authModule
            .post(`/api/user/entity/${entityFollow.entityId}/follow`, entityFollow)
            .then((response) => {
                const createdEntityFollow: EntityFollow = response.data as unknown as EntityFollow
                return createdEntityFollow
            })
    },
    unfollowEntity({ }: ActionContext<UserStore, IRootState>, entityFollowId: number) {
        return authModule
            .delete(`/api/user/entity/${entityFollowId}/follow`)
            .then((response) => {
                const deletedEntityFollowId: number = response.data as unknown as number
                return deletedEntityFollowId
            })
    },
    followPlayer({ }: ActionContext<UserStore, IRootState>, playerFollow: PlayerFollow) {
        return authModule
            .post(`/api/user/player/${playerFollow.playerId}/follow`, playerFollow)
            .then((response) => {
                const createdPlayerFollow: PlayerFollow = response.data as unknown as PlayerFollow
                return createdPlayerFollow
            })
    },
    unfollowPlayer({ }: ActionContext<UserStore, IRootState>, playerFollowId: number) {
        return authModule
            .delete(`/api/user/player/${playerFollowId}/follow`)
            .then((response) => {
                const deletedPlayerFollowId: number = response.data as unknown as number
                return deletedPlayerFollowId
            })
    }
};
const userStore: Module<UserStore, IRootState> =
{
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
export default userStore;