import { Event } from '@/models/main/Event';
import { VLY_ACE, VLY_ATTACK, VLY_BLOCK, VLY_ERROR, VLY_FOUL, VLY_SERVICE_ERROR, VolleyballGameControlType, VolleyballPointType, VOLLEYBALL_POINT_TITLE_PAIRS, VLY_POINT, VLY_RECIEVE_ERROR } from '@/composables/volleyball/VolleyballEventTypes';
import GenericDictionary from '@/components/util/GenericDictionary';

export type BasicVolleyballStat = GenericDictionary<{ pointType: VolleyballPointType, count: number }>

export default function useInterpretVolleyballEvents() {
    const calculateEntityPoints = (entityId: number, events: GenericDictionary<Event>, eventsBefore?: number, forSpecificGameOnly?: number): number => {
        let points = 0
        let currentGame = 1
        for(const i in events) {
            const event = events[i]
            const eventTimeStartInUnixSeconds = new Date(event.startTimestamp ?? 0).getTime() / 1000
            if(eventsBefore === undefined || eventTimeStartInUnixSeconds <= eventsBefore) {
                const eventEntityId = event.entityId
                if(event.type === "Point" && (forSpecificGameOnly === undefined || currentGame === forSpecificGameOnly)) {
                    const pointType = event.description as unknown as VolleyballPointType | null
                    switch(pointType) {
                        case "Vly_P":
                        case "Vly_A":
                        case "Vly_At":
                        case "Vly_Bl":
                        case null: //undefined point for event entity
                        case undefined:
                            if(eventEntityId == entityId) {
                                points++
                            }
                            break;
                        case "Vly_Err":
                        case "Vly_SE":
                        case "Vly_RE":
                        case "Vly_F":
                            if(eventEntityId != entityId) { // these cases give points to opponent
                                points++
                            }
                            break;
                    } 
                } else if (event.type === "GameControl") {
                    const gameControlType = event.description as unknown as VolleyballGameControlType
                    if(gameControlType === "Vly_G") {
                        if(forSpecificGameOnly !== undefined && currentGame === forSpecificGameOnly) {
                            return points
                        } else {
                            points = 0
                            currentGame++
                        }
                    }
                }
            }

        }

        return points
    }


    const countBasicStats = (homeEntityId: number, awayEntityId: number, events: GenericDictionary<Event>, eventsBefore?: number) => {

        type BasciStatsTuple = {
            homeEntity: GenericDictionary<{ entityStats: BasicVolleyballStat, playersStats: GenericDictionary<BasicVolleyballStat> }>,
            awayEntity: GenericDictionary<{ entityStats: BasicVolleyballStat, playersStats: GenericDictionary<BasicVolleyballStat> }>,
        }

        const homeEntityStat = basicStatObj()

        
        const awayEntityStat = basicStatObj()
        
        const basicStatsGroupedByGames: BasciStatsTuple = {
            homeEntity: { 0 : { entityStats: homeEntityStat, playersStats: {} } },
            awayEntity: { 0 : { entityStats: awayEntityStat, playersStats: {} } }
        }

        let currentGame = 1
        for(const i in events) {
            const event = events[i]
            const eventTimeStartInUnixSeconds = new Date(event.startTimestamp ?? 0).getTime() / 1000
            if(eventsBefore === undefined || eventTimeStartInUnixSeconds <= eventsBefore) {
                const eventEntityId = event.entityId
                if(event.type === "Point") {
                    let pointType = event.description as unknown as VolleyballPointType | null
                    if(pointType === undefined || pointType === null) {
                        pointType = VLY_ATTACK
                    }
    
                    if(eventEntityId == homeEntityId) {
                        basicStatsGroupedByGames.homeEntity[0].entityStats[pointType].count++

                        if(!Object.prototype.hasOwnProperty.call(basicStatsGroupedByGames.homeEntity, currentGame)) {
                            const homeEnStat = basicStatObj()
                            basicStatsGroupedByGames.homeEntity[currentGame] = { entityStats: homeEnStat, playersStats: {} }
                        }
    
                        basicStatsGroupedByGames.homeEntity[currentGame].entityStats[pointType].count++
    
                        if(event.playerId) {
                            const currentPlayerId = event.playerId

                            if(!Object.prototype.hasOwnProperty.call(basicStatsGroupedByGames.homeEntity[0].playersStats, currentPlayerId)) { //match stat (total)
                                const playerStat = basicStatObj()
                                basicStatsGroupedByGames.homeEntity[0].playersStats[currentPlayerId] = playerStat
                            }
    
                            if(!Object.prototype.hasOwnProperty.call(basicStatsGroupedByGames.homeEntity[currentGame].playersStats, currentPlayerId)) {
                                const playerStat = basicStatObj()
                                basicStatsGroupedByGames.homeEntity[currentGame].playersStats[currentPlayerId] = playerStat
                            }
    
                            basicStatsGroupedByGames.homeEntity[0].playersStats[currentPlayerId][pointType].count++
                            basicStatsGroupedByGames.homeEntity[currentGame].playersStats[currentPlayerId][pointType].count++
                        }
                    } else if(eventEntityId == awayEntityId) {
                        basicStatsGroupedByGames.awayEntity[0].entityStats[pointType].count++
    
                        if(!Object.prototype.hasOwnProperty.call(basicStatsGroupedByGames.awayEntity, currentGame)) {
                            const awayEnStat = basicStatObj()
                            basicStatsGroupedByGames.awayEntity[currentGame] = { entityStats: awayEnStat, playersStats: {} }
                        }
    
                        basicStatsGroupedByGames.awayEntity[currentGame].entityStats[pointType].count++
    
                        if(event.playerId) {
                            const currentPlayerId = event.playerId
    
                            if(!Object.prototype.hasOwnProperty.call(basicStatsGroupedByGames.awayEntity[0].playersStats, currentPlayerId)) { // match stat (total)
                                const playerStat = basicStatObj()
                                basicStatsGroupedByGames.awayEntity[0].playersStats[currentPlayerId] = playerStat
                            }
                            if(!Object.prototype.hasOwnProperty.call(basicStatsGroupedByGames.awayEntity[currentGame].playersStats, currentPlayerId)) {
                                const playerStat = basicStatObj()
                                basicStatsGroupedByGames.awayEntity[currentGame].playersStats[currentPlayerId] = playerStat
                            }
    
                            basicStatsGroupedByGames.awayEntity[0].playersStats[currentPlayerId][pointType].count++
                            basicStatsGroupedByGames.awayEntity[currentGame].playersStats[currentPlayerId][pointType].count++
                        }
                    } else {
                        console.error("Something is not right!")
                    }
                } else if (event.type === "GameControl") {
                    const gameControlType = event.description as unknown as VolleyballGameControlType
                    if(gameControlType === "Vly_G") {
                        currentGame++
                    }
                }
            }
        }

        return basicStatsGroupedByGames
        
    }

    const basicStatObj = () => {
        const basicStatObj: BasicVolleyballStat = {}
        basicStatObj[VLY_POINT] = { pointType: VLY_POINT, count: 0}
        basicStatObj[VLY_ACE] = { pointType: VLY_ACE, count: 0 }
        basicStatObj[VLY_ATTACK] = { pointType: VLY_ATTACK, count: 0 }
        basicStatObj[VLY_BLOCK] = { pointType: VLY_BLOCK, count: 0 }
        basicStatObj[VLY_SERVICE_ERROR] = { pointType: VLY_SERVICE_ERROR, count: 0 }
        basicStatObj[VLY_ERROR] = { pointType: VLY_ERROR, count: 0 }
        basicStatObj[VLY_FOUL] = { pointType: VLY_FOUL, count: 0 }
        basicStatObj[VLY_RECIEVE_ERROR] = {pointType: VLY_RECIEVE_ERROR, count: 0}

        return basicStatObj
    }

    const groupEventsByGames = (homeEntityId: number, awayEntityId: number, events: GenericDictionary<Event>, currentTime?: number, eventsBefore?: number) => {
        let currentGame = 1
        let homePoints = 0
        let awayPoints = 0
        let prevEventWasGame = false
        const groupedEvents: GenericDictionary<{ isLive: boolean, events: GenericDictionary<{event: Event, homeTeamScore: number, awayTeamScore: number, isLive: boolean}>, eventsCount: number }> = {}
        groupedEvents[currentGame] = { isLive: false, events: {}, eventsCount: 0 }
        let eventsArrIndex = 0
        let closestToLiveGame = 1
        let closestToLiveEventIndex = 0
        for(const i in events) {
            const eventIsLive = false
            const event = events[i]
            if(closestToLiveEventIndex == 0 && event.id !== undefined) {
                closestToLiveEventIndex = event.id
            }
            const eventTimeStartInUnixSeconds = new Date(event.startTimestamp ?? 0).getTime() / 1000
            if(event.id !== undefined && (eventsBefore === undefined || eventTimeStartInUnixSeconds <= eventsBefore)) {
                const eventEntityId = event.entityId
                if(event.type === "Point") {
                    if(prevEventWasGame) {
                        currentGame++
                        homePoints = 0
                        awayPoints = 0
                        prevEventWasGame = false
    
                        if(!Object.prototype.hasOwnProperty.call(groupedEvents, currentGame)) {
                            groupedEvents[currentGame] = { isLive: false, events: {}, eventsCount: 0 }
                            eventsArrIndex = 0
                        }
                    }
    
                    const pointType = event.description as unknown as VolleyballPointType | null
                    switch(pointType) {
                        case "Vly_P":
                        case "Vly_A":
                        case "Vly_At":
                        case "Vly_Bl":
                        case null: //undefined point for event entity
                        case undefined:
                            if(eventEntityId == homeEntityId) {
                                homePoints++
                            } else {
                                awayPoints++
                            }
                            break;
                        case "Vly_Err":
                        case "Vly_SE":
                        case "Vly_RE":
                        case "Vly_F":
                            if(eventEntityId != homeEntityId) { // these cases give points to opponent
                                homePoints++
                            } else {
                                awayPoints++
                            }
                            break;
                    } 
    
                    if(currentTime !== undefined && (currentGame > 1 || eventsArrIndex > 0)) { // we skip first of first game as it is default one
                        const untilNowBestLiveEvent = groupedEvents[closestToLiveGame] && groupedEvents[closestToLiveGame].events[closestToLiveEventIndex] ? groupedEvents[closestToLiveGame].events[closestToLiveEventIndex].event : null
                        if(untilNowBestLiveEvent  !== null) {
                            const bestLiveEventTime = new Date(untilNowBestLiveEvent.startTimestamp ?? 0).getTime() / 1000
                            if(eventTimeStartInUnixSeconds <= currentTime && eventTimeStartInUnixSeconds > bestLiveEventTime) {
                                closestToLiveGame = currentGame
                                closestToLiveEventIndex = eventsArrIndex
                            }
                        }
                    }

                    groupedEvents[currentGame].events[event?.id] = {event, homeTeamScore: homePoints, awayTeamScore: awayPoints, isLive: eventIsLive}
                    groupedEvents[currentGame].eventsCount++
                    eventsArrIndex = event?.id
                } else if (event.type === "GameControl") {
                    const gameControlType = event.description as unknown as VolleyballGameControlType
                    if(gameControlType === "Vly_G") {
                        prevEventWasGame = true
                    }
    
    
                }
            }
        }
        groupedEvents[closestToLiveGame].isLive = true
        if(groupedEvents[closestToLiveGame].eventsCount > 0) {
            groupedEvents[closestToLiveGame].events[closestToLiveEventIndex].isLive = true
        }
        return groupedEvents
    }

    const calculateEntityGames = (entityId: number, events: GenericDictionary<Event>, eventsBefore?: number): number => {
        let games = 0
        for(const i in events) {
            const event = events[i]
            const eventTimeStartInUnixSeconds = new Date(event.startTimestamp ?? 0).getTime() / 1000
            if(eventsBefore === undefined || eventTimeStartInUnixSeconds <= eventsBefore) {
                const eventEntityId = event.entityId
                if (event.type === "GameControl") {
                    const gameControlType = event.description as unknown as VolleyballGameControlType
                    if(gameControlType === "Vly_G" && eventEntityId === entityId) {
                        games++
                    }
                }
            }
        }

        return games
    }

    return {
        calculateEntityPoints,
        calculateEntityGames,
        groupEventsByGames,
        countBasicStats
    }
}