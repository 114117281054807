import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    active: _ctx.$route.matched.some(({ name }) => name === _ctx.navigationItem.routerLinkName),
    class: "menu-link",
    to: {name: _ctx.navigationItem.routerLinkName}
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.navigationItem.title), 1)
    ]),
    _: 1
  }, 8, ["active", "to"]))
}