<template>
    <div class="usr-dashboard-wrapper" :class="{'subpages-wrapper' : hasSubPages}">
        <TheUserNavigationBar :user="getUser" :navigationItems="navigationItems" :page-heading="title"/>
        <main class="usr-main-container" @scroll="onScroll">
            <slot />
        </main>
    </div>
</template>
<script lang="ts">
import { Ref, defineComponent, onMounted, reactive, ref, watch } from 'vue';
import { NavigationItem } from './NavigationBarItem.vue';
import { useStore, createNamespacedHelpers } from 'vuex';
import { key } from '@/store';
import { useRouter } from 'vue-router';
import { User } from '@/models/main/User';
import authModule from '@/main';
import TheUserNavigationBar from '@/components/main/TheUserNavigationBar.vue';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../util/AuthConfig';
import { useMsalAuthentication } from '@/composables/auth/UseMSALAuthentication';

const userStoreMapper = createNamespacedHelpers('userStore');
const defaultNavigationItems: NavigationItem[] = [
    //{title: "Home", routerLinkName: "Home", params: null, iconLink: null, allowedRoles: [] } as NavigationItem,
    {title: "Channels", routerLinkName: "Channels", params: null, iconLink: null, allowedRoles: ["Admin", "Organizer"] } as NavigationItem,
    {title: "Stations", routerLinkName: "Stations", params: null, iconLink: null, allowedRoles: ["Admin", "Organizer"] } as NavigationItem,
    {title: "My Livestreams", routerLinkName: "User Livestream", params: null, iconLink: null, allowedRoles: ['Player'] } as NavigationItem,
   // {title: "User Management", routerLinkName: "UserManagement", params: null, iconLink: null, allowedRoles: ["Admin"] } as NavigationItem,
    {title: "Teams", routerLinkName: "Team Management", params: null, iconLink: null, allowedRoles: ["Admin", "Organizer"] } as NavigationItem,
   // {title: "Tournaments", routerLinkName: "Volleyball Tournament", params: null, iconLink: null, allowedRoles: ["Admin"] } as NavigationItem,
    {title: "Matches", routerLinkName: "Volleyball Match", params: null, iconLink: null, allowedRoles: ["Admin", "Organizer", "Operator"] } as NavigationItem,
    {title: "Livestreams", routerLinkName: "Livestreams", params: null, iconLink: null, allowedRoles: ["Admin", "Organizer"] } as NavigationItem,
    {title: "Live", routerLinkName: "Livestreams Dashboard", params: null, iconLink: null, allowedRoles: ["Admin", "Organizer", "Operator", "Player"] } as NavigationItem,
    {title: "Archive", routerLinkName: "Livestreams Archive", params: null, iconLink: null, allowedRoles: ["Admin", "Organizer", "Operator", "Player"] } as NavigationItem,
    //{title: "Profile", routerLinkName: "Profile", params: null, iconLink: null, allowedRoles: ["Admin", "Organizer", "Operator", "Player"] } as NavigationItem,
    // {title: "Rally Score", routerLinkName: "Rally Score", params: null, iconLink: null, allowedRoles: ["Admin", "Organizer", "Operator"] } as NavigationItem,

]
const TheUserDashboard = defineComponent({
    props: {
        title: {
            type: String,
        },
        onUserLoad: {
            type: Function,
            required: false,
        },
        hasSubPages: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        ...userStoreMapper.mapGetters([
            'getUser',
        ]),
    },
    emits: ['scroll'],
    setup(props: any, context) {
        const store = useStore(key);
        const router = useRouter();
        const {result, inProgress} = useMsalAuthentication(InteractionType.Redirect, { ...loginRequest, forceRefresh: false,  })
        console.debug(result);
        watch(inProgress, (newProgress: boolean) => {
            if(!newProgress) {
                getLoggedInUser()
            }
        })
        const getLoggedInUser = () => {
            console.debug(result.value)
            if(result.value && result.value.accessToken) {
                authModule.token = result.value.accessToken
            }

            store.dispatch('userStore/getCurrentUser').then((user: User) => {
                if (authModule.isAuthenticated && user === null) {
                   router.push({ name: 'Profile' });
                }
                else if (props?.onUserLoad) {
                    props.onUserLoad(user);
                }
            });
        };
        const logoutUser = () => {
            authModule.logout();
            router.push({ name: 'Login' });
        };
        const onScroll = (event: any) => {
            context.emit('scroll', event);
        };
        const publicPath = process.env.BASE_URL;
        const defaultProfilePicRelativePath = 'default-profile-pic.svg';
        const navigationItems = reactive<Array<NavigationItem>>(defaultNavigationItems);
        return {
            navigationItems,
            publicPath,
            defaultProfilePicRelativePath,
            logoutUser,
            onScroll,
        };
    },
    components: {
        TheUserNavigationBar
    },
});
export default TheUserDashboard;
</script>
<style>
.usr-dashboard-wrapper {
    --sky-blue-clr: #bae9fb;
    --background-clr: #f2f4f7;
    --nav-contour-clr: #283380;
    --bright-font-clr: white;
    --darker-font-clr: #283380;
    --nav-height: 4rem;
    --nav-line-margin-btm: 0.25rem;
    --nav-contour-height: 1rem;
    --max-width: min(100vw, 1920px);
    --max-content-width: min(100vw, 1920px);
    --sportzone-logo-width: clamp(8rem, 25vw, 12rem);
    --user-nav-side-width: 14rem;
    --submenu-width: 12rem;
    font-family: Roboto;
    background-color: var(--background-clr);
    display: flex;
    background-image: url(/Sportzone-live-background.svg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
}

.usr-main-container {
    color: var(--main-font-clr);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: calc(var(--max-width) - var(--user-nav-side-width));
    position: relative;
    margin: 0 auto;
    text-align: left;
    padding: 0.5em;
    width: var(--max-width);
    height: calc(100vh - var(--nav-height));
    align-items: center;
    overflow-y: scroll;
    scrollbar-width: none;
    --scrollbar-width: 0;
    margin-top: calc(1.5 * var(--nav-height));
    background-color: var(--background-clr);
    position: fixed;
    left: calc(((100% - var(--max-width)) / 2) + var(--user-nav-side-width));
}

.usr-dashboard-wrapper .page-heading {
    width: calc(100% - var(--user-profile-section-width) - 3rem);
    font-weight: 500;
    position: relative;
    padding-left: 1.2em;
    line-height: 1em;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.usr-dashboard-wrapper .page-heading::before {
    content: '';
    width: 1em;
    position: absolute;
    left: 0;
    top: calc((100% - 1em) / 2);
    height: 1em;
    background-image: url('/sportzone-green-triangle.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

.usr-dashboard-wrapper.bwl {
    background: url('../../../public/grey_bowling_icon.svg');
}

.usr-dashboard-wrapper.tbltenn {
    background: url('../../../public/grey_table_tennis_icon.svg');
}

.usr-dashboard-wrapper.tenn {
    background: url('../../../public/grey_tennis_icon.svg');
}

.usr-dashboard-wrapper.vly {
    background: url('../../../public/grey_volleyball_icon.svg');
}

.usr-dashboard-wrapper.bwl,
.usr-dashboard-wrapper.tbltenn,
.usr-dashboard-wrapper.tenn,
.usr-dashboard-wrapper.vly {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 35%;
}

.usr-dashboard-wrapper .modal__dialog {
    background-color: var(--background-clr);
}

.usr-dashboard-wrapper .modal__header {
    color: var(--darker-font-clr);
}

@media screen and (min-width: 680px) {
    .usr-main-container { 
        padding: 1.5rem;
        margin-top: var(--nav-height);
    }

    .usr-dashboard-wrapper {
        --nav-height: 4.5rem;
        --nav-contour-height: 1.4rem;
    }

}

@media screen and (min-width: 1000px) {
}
</style>