import DataModel from "@/models/main/DataModel";

export default class FrameDataModel extends DataModel {
    public static readonly MODEL_NAME: string = "Frame"

    private _gameID?: number;
    private _firstRollScore?: number;
    private _firstRollSpeed?: number;
    private _firstRollTime?: any;
    private _frameNumber?: number;
    private _isSplit?: number;
    private _scoreAtFrame?: number;
    private _secondRollScore?: number;
    private _secondRollSpeed?: number;
    private _secondRollTime?: any;

    // #region Fields Keys
    static get gameIDKey() {
        return "game_id";
    }

    static get firstRollScoreKey() {
        return "first_roll_score";
    }

    static get firstRollSpeedKey() {
        return "first_roll_speed";
    }

    static get firstRollTimeKey() {
        return "first_roll_time";
    }

    static get frameNumberKey() {
        return "frame_number";
    }

    static get isSplitKey() {
        return "is_split";
    }

    static get scoreAtFrameKey() {
        return "score_at_frame";
    }

    static get secondRollScoreKey() {
        return "second_roll_score";
    }

    static get secondRollSpeedKey() {
        return "second_roll_speed";
    }

    static get secondRollTimeKey() {
        return "second_roll_time";
    }

    // #endregion

    // #region accessors/mutations 

    get gameID() {
        return this._gameID;
    }

    set gameID(gameID) {
        this._gameID = gameID;
    }

    get firstRollScore() {
        return this._firstRollScore;
    }

    set firstRollScore(firstRollScore) {
        this._firstRollScore = firstRollScore;
    }

    get firstRollSpeed() {
        return this._firstRollSpeed;
    }

    set firstRollSpeed(firstRollSpeed) {
        this._firstRollSpeed = firstRollSpeed;
    }

    get firstRollTime() {
        return this._firstRollTime;
    }

    set firstRollTime(firstRollTime) {
        this._firstRollTime = firstRollTime;
    }


    get frameNumber() {
        return this._frameNumber;
    }

    set frameNumber(frameNumber) {
        this._frameNumber = frameNumber;
    }

    get isSplit() {
        return this._isSplit;
    }

    set isSplit(isSplit) {
        this._isSplit = isSplit;
    }

    get scoreAtFrame() {
        return this._scoreAtFrame;
    }

    set scoreAtFrame(scoreAtFrame) {
        this._scoreAtFrame = scoreAtFrame;
    }

    get secondRollScore() {
        return this._secondRollScore;
    }

    set secondRollScore(secondRollScore) {
        this._secondRollScore = secondRollScore;
    }

    get secondRollSpeed() {
        return this._secondRollSpeed;
    }

    set secondRollSpeed(secondRollSpeed) {
        this._secondRollSpeed = secondRollSpeed;
    }

    get secondRollTime() {
        return this._secondRollTime;
    }

    set secondRollTime(secondRollTime) {
        this._secondRollTime = secondRollTime;
    }
    //#endregion

    constructor(modelID?: string, modelData?: any) {
        super();
        this.firstRollScore = -1;
        this.firstRollSpeed = 0.0;
        this.secondRollScore = -1;
        this.secondRollSpeed = 0.0;
        this.isSplit = 0;
        this.scoreAtFrame = 0;
        this.parseMinifiedData(modelData);
        if(this.gameID !== undefined && this.frameNumber !== undefined) {
            const modelId = DataModel.generateModelID(this, {"gameID" : this.gameID, "number" : this.frameNumber});
            if(modelId !== false) {
                this.modelID = modelId
            }
        } else if(modelID !== undefined){
            this.modelID = modelID;
        }
    }

    // #region FieldMappings Getters/Setters
    dataModelName() {
        return FrameDataModel.MODEL_NAME
    }

    getKeyByMinifiedKey(minKey: string) {
        switch (minKey) {
            case "0":
                return FrameDataModel.gameIDKey;
            case "1":
                return FrameDataModel.firstRollScoreKey;
            case "2":
                return FrameDataModel.firstRollSpeedKey;
            case "3":
                return FrameDataModel.secondRollScoreKey;
            case "4":
                return FrameDataModel.secondRollSpeedKey;
            case "5":
                return FrameDataModel.frameNumberKey;
            case "6":
                return FrameDataModel.scoreAtFrameKey;
            case "7":
                return FrameDataModel.isSplitKey;
            case "8":
                return FrameDataModel.firstRollTimeKey;
            case "9":
                return FrameDataModel.secondRollTimeKey;
            default:
                return ""
        }
    }

    setProperty(propertyKey: string, value: any) {
        switch (propertyKey) {
            case FrameDataModel.gameIDKey:
                this.gameID = parseInt(value);
                break;
            case FrameDataModel.firstRollScoreKey:
                this.firstRollScore = parseInt(value);
                break;
            case FrameDataModel.firstRollSpeedKey:
                this.firstRollSpeed = value;
                break;
            case FrameDataModel.secondRollScoreKey:
                this.secondRollScore = parseInt(value);
                break;
            case FrameDataModel.secondRollSpeedKey:
                this.secondRollSpeed = value;
                break;
            case FrameDataModel.frameNumberKey:
                this.frameNumber = parseInt(value);
                break;
            case FrameDataModel.scoreAtFrameKey:
                this.scoreAtFrame = parseInt(value);
                break;
            case FrameDataModel.isSplitKey:
                this.isSplit = value;
                break;
            case FrameDataModel.firstRollTimeKey:
                this.firstRollTime = value;
                break;
            case FrameDataModel.secondRollTimeKey:
                this.secondRollTime = value;
                break;
        }
    }

    getProperty(propertyKey: string) {
        switch (propertyKey) {
            case FrameDataModel.gameIDKey:
                return this.gameID;
            case FrameDataModel.firstRollScoreKey:
                return this.firstRollScore;
            case FrameDataModel.firstRollSpeedKey:
                return this.firstRollSpeed;
            case FrameDataModel.secondRollScoreKey:
                return this.secondRollScore;
            case FrameDataModel.secondRollSpeedKey:
                return this.secondRollSpeed;
            case FrameDataModel.frameNumberKey:
                return this.frameNumber;
            case FrameDataModel.scoreAtFrameKey:
                return this.scoreAtFrame;
            case FrameDataModel.isSplitKey:
                return this.isSplit;
            case FrameDataModel.firstRollTimeKey:
                return this.firstRollTime;
            case FrameDataModel.secondRollTimeKey:
                return this.secondRollTime;
        }
    }

    //#endregion
}