<template>
    <div class="sport-watch-wrapper"
        :class="{ 'fallback-fullscreen': isFallbackFullScreenMode, 'fullscreen': isFullScreenMode }">
        <div v-if="!livestream" class="loading-livestream-icon-div">
            <LoadingIcon />
        </div>
        <div class="live-central-panel">
            <LivePlayer v-if="livestream"
             :startPlayingAt="seekPlayerTo" :endPlayingAt="endVideoTime" :livestream="livestream"
                @playerDurationChange="playerDurationChange" @fullscreenToggle="onFullScreenToggle" 
                 :startFullScreen="isFullScreenMode">
                <div class="in-player-result-board" v-if="getCurrentHomeEntity && getCurrentAwayEntity && getCurrentHomeEntity?.id && getCurrentAwayEntity?.id">
                    <div class="in-player-result-board__home-team">
                        <div class="in-player-result-board__cell in-player-result-board__team-name">
                            {{ getCurrentHomeEntity?.name }}
                        </div>
                            <div class="in-player-result-board__cell in-player-result-board__games_cell">
                            </div>
                        <div class="in-player-result-board__cell in-player-result-board__points_cell">
                            {{ 0 }}
                        </div>
                    </div>
                    <div class="in-player-result-board__away-team">
                        <div class="in-player-result-board__cell in-player-result-board__team-name">
                            {{ getCurrentAwayEntity?.name }}
                        </div>
                        <div class="in-player-result-board__cell in-player-result-board__games_cell">
                        </div>
                        <div class="in-player-result-board__cell in-player-result-board__points_cell">
                            {{ 0 }}</div>
                    </div>
                </div>
                <div class="break-pane">
                    <div class="break-pane__stats">Statistics</div>
                </div>
            </LivePlayer>
            <div class="main-scoreboard-wrapper hide-on-full" v-if="getCurrentHomeEntity && getCurrentAwayEntity && getCurrentHomeEntity?.id && getCurrentAwayEntity?.id">
                <div class="main-scoreboard">
                    <div class="sport-home-scoreboard">
                        <div class="sport-player-scoreboard">
                            <div class="player-profile-img"></div>
                            <div class="team-name">{{ getCurrentHomeEntity?.name }}</div>
                            <div class="player-score">
                                <div class="player-sets">
                                    <div class="player-game-score" >
                                    </div>
                                </div>
                                <div class="player-current-game-score">
                                    {{ 0 }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sport-away-scoreboard">
                        <div class="sport-player-scoreboard">
                            <div class="player-profile-img"></div>
                            <div class="team-name">{{ getCurrentAwayEntity?.name }}</div>
                            <div class="player-score">
                                <div class="player-sets">
                                    <div class="player-game-score">
                                    </div>
                                </div>
                                <div class="player-current-game-score">
                                    {{ 0 }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="main-sport-stats-wrapper">
                    <div class="entity-stat-headings">
                        <div class="entity-stat-heading">
                            {{ "Stat Heading 1" }}
                        </div>
                        <div class="entity-stat-heading">
                            {{ "Stat Heading 2" }}
                        </div>
                        <div class="entity-stat-heading">
                            {{ "Stat Heading 3" }}
                        </div>
                        <div class="entity-stat-heading">
                            {{ "Stat Heading 4" }}
                        </div>
                        <div class="entity-stat-heading">
                            {{ "Stat Heading 5" }}
                        </div>
                        <div class="entity-stat-heading">
                            {{ "Stat Heading 6" }}
                        </div>
                        <div class="entity-stat-heading">
                            {{ "Stat Heading 7" }}
                        </div>
                    </div>
                    <div class="home-entity-stats">
                        <div class="entity-stat">
                            {{ 1 }}
                        </div>
                        <div class="entity-stat">
                            {{ 2 }}
                        </div>
                        <div class="entity-stat">
                            {{ 3 }}
                        </div>
                        <div class="entity-stat">
                            {{ 4 }}
                        </div>
                        <div class="entity-stat">
                            {{ 5 }}
                        </div>
                        <div class="entity-stat">
                            {{ 6 }}
                        </div>
                        <div class="entity-stat">
                            {{ 7 }}
                        </div>
                    </div>
                    <div class="away-entity-stats">
                        <div class="entity-stat">
                            {{ 1 }}
                        </div>
                        <div class="entity-stat">
                            {{ 2 }}
                        </div>
                        <div class="entity-stat">
                            {{ 3 }}
                        </div>
                        <div class="entity-stat">
                            {{ 4 }}
                        </div>
                        <div class="entity-stat">
                            {{ 5 }}
                        </div>
                        <div class="entity-stat">
                            {{ 6 }}
                        </div>
                        <div class="entity-stat">
                            {{ 7 }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <h2>{{ getCurrentContainer?.title }}</h2>
        <div class="live-right-panel hide-on-full" v-if="getCurrentHomeEntity && getCurrentAwayEntity && getCurrentHomeEntity?.id && getCurrentAwayEntity?.id">
            <div class="sport-events-section">
                <div class="sport-game-events">
                <div class="sport-game-event-score">
                    <div class="sport-game-event-score-home">
                    </div>
                    <!-- <div class="sport-game-event-score-separator">:</div> -->
                    <div class="sport-game-event-score-away">
                    </div>
                </div>
                    <div class="sport-game-event">
                        <div class="sport-game-event-home"></div>
                        <!-- <div class="sport-game-event-separator">:</div> -->
                        <div class="sport-game-event-away"></div>
                    </div>
                </div>
            </div>
            <div class="logos-wrapper">
                <div class="logo-wrapper">
                    <a :href="SPORTZONE_MAIN_PAGE_URL"><img src="/sportzone-logo.svg" style="width: 100%;"
                        alt="Sportzone"></a>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent } from "@vue/runtime-core";
import LivePlayer from "@/components/main/LivePlayer.vue";
import PinIcon from "@/components/main/PinIcon.vue";
import Modal from '@/components/main/Modal.vue';
import { Ref, ref } from "vue";
import LiveDataModel from "@/composables/dataModels/LiveDataModel";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { key } from '@/store'
import { Event } from '@/models/main/Event';
import { SportzoneAuth } from "@/components/util/SportzoneAuth";
import useCurrentContainerComputeds from "@/composables/generic/UseCurrentContainerComputeds";
import useDataManagerHelpers from "@/composables/dataModels/UseDataManagerHelpers";
import { SPORTZONE_MAIN_PAGE_URL } from "@/composables/common/Constants";
import { shouldSeekToValue } from "@/composables/common/Util";
import LoadingIcon from '@/components/util/LoadingIcon.vue';
export default defineComponent({
    components: {
        LivePlayer,
        PinIcon,
        Modal,
        LoadingIcon,
    },
    setup() {

        const route = useRoute()
        const store = useStore(key)

        /* Constants, Reactive variables, URL link query params */
        const apiUri = SportzoneAuth.apiUri
        const currentContainerId: Ref<number> = ref(0)
        const livestream: Ref<LiveDataModel | null> = ref(null)
        const liveId = parseInt(route.params.id as string);
        const isSpoiledModeOn: Ref<boolean> = ref(route.query.unspoiled !== "1")
        const isFullScreenMode: Ref<boolean> = ref(false)
        const isFallbackFullScreenMode: Ref<boolean> = ref(false) 

        let startReplayEventId: number | undefined = parseInt(route.query?.startEvent as string)
        let endReplayEventId: number | undefined = parseInt(route.query?.endEvent as string)
        let startVideoTime: number | undefined = parseInt(route.query?.start as string)
        let endVideoTime: number | undefined = parseInt(route.query?.end as string)
        if (isNaN(startVideoTime)) {
            startVideoTime = undefined
        }
        if (isNaN(endVideoTime)) {
            endVideoTime = undefined
        }
        if (startVideoTime !== undefined || isNaN(startReplayEventId)) {
            startReplayEventId = undefined
        }
        if (endVideoTime !== undefined || isNaN(endReplayEventId)) {
            endReplayEventId = undefined
        }
        const playerCurrentUnixTime: Ref<number> = ref(0)
        const seekPlayerTo: Ref<number> = ref(startVideoTime !== undefined ? startVideoTime : 0)
        const advanceSecs = 8


    /*Sport specific reusable functions, you will need to create for each sport and upgrade depending on the need*/
        const {
            getCurrentContainer,
            getCurrentHomeEntity,
            getCurrentAwayEntity,
        } = useCurrentContainerComputeds(currentContainerId)


        /* Getting Data manager initializing function and initializing Data Manager, that handles data changes (like Container, Livestream data) */
        const {
            initDataManager
        } = 
        useDataManagerHelpers(store)
        initDataManager(liveId).then((result) => {
            if(result?.livestreamDataModel) {
                livestream.value = result.livestreamDataModel
            }
            if (result?.containerDataModel !== undefined && result.containerDataModel.id !== undefined) {
                currentContainerId.value = result.containerDataModel.id
            }
        })


        /* Basic Functions relating view */
        const onFullScreenToggle = (isFullScreen: boolean, isFallbackFullScreen: boolean) => {
            isFullScreenMode.value = isFullScreen
            isFallbackFullScreenMode.value = isFallbackFullScreen
        }

        const seekPlayer = (event: Event) => {
            if(livestream.value) {
                const shouldSeekTo = shouldSeekToValue(event, livestream.value, advanceSecs)
                if(shouldSeekTo) {
                    seekPlayerTo.value = shouldSeekTo
                }
            }
        }

        const playerDurationChange = (currentDuration: number) => {
            let liveStarttime = 0
            if (livestream.value?.streamActualStartTime) {
                liveStarttime = new Date(livestream.value.streamActualStartTime).getTime() / 1000
            }

            playerCurrentUnixTime.value = currentDuration + liveStarttime - advanceSecs
        }

        return {
            store,
            livestream,
            currentContainerId,
            isFullScreenMode,
            isFallbackFullScreenMode,
            getCurrentContainer,
            getCurrentHomeEntity,
            getCurrentAwayEntity,
            playerDurationChange,
            onFullScreenToggle,
            seekPlayer,
            endVideoTime,
            seekPlayerTo,
            apiUri,
            SPORTZONE_MAIN_PAGE_URL,
        }
    }
})
</script>
<style>
.sport-watch-wrapper {
    --vly-watch-min-height: 105vh;
    height: var(--vly-watch-min-height);
    color: var(--light-homepage-color);
    --player-def-width: 1584px;
    --player-def-height: 891px;
    overflow-y: scroll;
    scrollbar-width: none;
    background-color: var(--darker-bckgrnd-color);
}

.sport-watch-wrapper .live-central-panel {
    width: 100%;
}

.sport-watch-wrapper::-webkit-scrollbar {
    display: none;
}

.sport-watch-wrapper::after {
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
}

.sport-watch-wrapper .logos-wrapper {
    display: none;
}

.sport-watch-wrapper .in-player-result-board {
  bottom: 4ch;
  left: 4ch;
  font-size: 1em;
  top: auto;
  display: none;
  position: absolute;
}

.fullscreen.fallback-fullscreen .in-player-result-board, :fullscreen .in-player-result-board {
  display: flex;
}

.sport-watch-wrapper .live-right-panel {
    display: none;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.sport-watch-wrapper .in-player-result-board__team-name {
    width: 20ch;
    text-align: left;
    padding: 0.5ch 1ch;
    background-color: var(--deep-blue-bck-color);
    border-right: 2px solid #cecccc;
}

.sport-watch-wrapper .in-player-result-board__games_cell {
    background-color: var(--deep-blue-bck-color);
    width: 4ch;
    padding: 0.5ch 0;
}

.sport-watch-wrapper .in-player-result-board__points_cell {
    background-color: var(--archive-bck-color);
    color: var(--light-homepage-color);
    width: 4ch;
    padding: 0.5ch 0;
}

.sport-player-scoreboard {
    display: flex;
}
.sport-player-scoreboard .player-score {
    display: flex;
}
.sport-player-scoreboard .player-game-score {
    width: 4ch;
    border-left: 1px solid #cecccc;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sport-player-scoreboard .team-name {
    width: 20ch;
    padding: 0.5ch 1ch;
    background-color: var(--deep-blue-bck-color);
    color: white;
    border: 1px solid #cecccc;
    font-size: 0.9em;
}

/* STATS */

.main-sport-stats-wrapper .entity-stat, .main-sport-stats-wrapper .entity-stat-heading {
  width: 14.28%;
}

.sport-player-scoreboard .player-sets {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #cecccc;
    border-bottom: 1px solid #cecccc;
    background-color: var(--deep-blue-bck-color);
}

.sport-player-scoreboard .player-current-game-score {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cecccc;
  width: 4ch;
  background-color: var(--archive-bck-color);
}

.sport-watch-wrapper .main-scoreboard {
  font-size: 1.25em;
  width: 35ch;
}


.main-sport-stats-wrapper {
    display: none;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: calc(100% - 35ch);
    flex-direction: column;
}

.sport-game-event-score {
  background-color: var(--archive-bck-color);
  font-size: 1.25em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--light-homepage-color);
  font-weight: bold;
  padding: 0.25ch 0;
}

.sport-events-section {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: calc(100% - 20ch);
    overflow-y: auto;
    scrollbar-width: thin;
    font-size: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.sport-game-event {
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sport-game-event-score {
  background-color: var(--archive-bck-color);
  font-size: 1.25em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sport-game-event:nth-child(2n) {
  background: var(--deep-blue-bck-color);
}

.sport-game-event-score-home, .sport-game-event-score-away, .sport-game-event-home, .sport-game-event-away {
  width: calc((100% - 1ch) / 2);
  display: flex;
  justify-content: center;
}

.sport-game-event.active {
    position: relative;
}

.sport-game-event:hover {
  background: #38778E;
}

.sport-game-event-home, .sport-game-event-score-home {
  justify-content: flex-end;
  padding-right: 1ch;
}

.sport-game-event-away, .sport-game-event-score-away {
  justify-content: flex-start;
  padding-left: 1ch;
}
.sport-game-event.active::before {
    content: '';
    width: 3ch;
    top: 0;
    bottom: 0;
    left: 0ch;
    background-color: #dade49;
    position: absolute;
}


@media screen and (min-height: 450px) {
    .sport-watch-wrapper {
        --vly-watch-min-height: 100vh;
    }
}

@media screen and (min-width: 680px) {
    .sport-watch-wrapper {
        --vly-watch-min-width: 100vw;
    }

    .sport-watch-wrapper .main-sport-stats-wrapper {
        display: flex;
    }
}

@media screen and (min-width: 980px){
    .sport-watch-wrapper .live-right-panel {
        display: flex;
    }
    .sport-watch-wrapper .live-central-panel {
        width: calc(100% - 35.5ch - 16px);
    }
    .sport-watch-wrapper .in-player-result-board {
        font-size: 1.25em;
    }
    .sport-watch-wrapper .main-scoreboard {
        font-size: 1.8em;
    }

    .home-entity-stats, .away-entity-stats {
        font-size: 1.5em;
    }

    .entity-stat-headings {
        height: 4.5ch;
    }
}

@media screen and (min-width: 1280px) {
    .sport-watch-wrapper .main-scoreboard-wrapper {
        width: 100%;
    }

    .sport-watch-wrapper .logos-wrapper {
        display: flex;
    }
}
</style>