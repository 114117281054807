import DataModelMapper from "@/components/util/DataModelMapper";
import { Sport } from "@/models/main/CommonTypes";
import DataModel from "@/models/main/DataModel";
import { Player } from "@/models/main/Player";
import { Sex } from "@/models/main/User";
import EntityDataModel from "./EntityDataModel";
import UserDataModel from "./UserDataModel";

export default class PlayerDataModel extends DataModel implements Player {
    public static readonly MODEL_NAME = "P"

    private _id?: number
    private _number?: number
    private _firstName?: string
    private _middleName?: string
    private _lastName?: string
    private _sex?: Sex
    private _birthday?: Date
    private _nationality?: string
    private _sport?: Sport
    private _additionalData?: string
    private _user?: UserDataModel
    private _entity?: EntityDataModel

    //#region Fields Keys
    static get IDKey() {
        return "id";
    }

    static get numberKey() {
        return "number";
    }

    static get firstNameKey() {
        return "firstName";
    }

    static get middleNameKey() {
        return "middleName";
    }

    static get lastNameKey() {
        return "lastName";
    }

    static get sexKey() {
        return "sex";
    }

    static get birthdayKey() {
        return "birthday";
    }

    static get nationalityKey() {
        return "nationality";
    }

    static get sportKey() {
        return "sport";
    }

    static get additionalDataKey() {
        return "additionalData";
    }

    static get userKey() {
        return "user";
    }

    static get entityKey() {
        return "entity";
    }

    //#endregion

    // #region accessors/mutations 
    get id() {
        return this._id;
    }

    set id(id) {
        this._id = id;
    }

    get number() {
        return this._number;
    }

    set number(number) {
        this._number = number;
    }

    get firstName() {
        return this._firstName;
    }

    set firstName(firstName) {
        this._firstName = firstName;
    }

    get middleName() {
        return this._middleName;
    }

    set middleName(middleName) {
        this._middleName = middleName;
    }

    get lastName() {
        return this._lastName;
    }

    set lastName(lastName) {
        this._lastName = lastName;
    }

    get sex() {
        return this._sex;
    }

    set sex(sex) {
        this._sex = sex;
    }

    get birthday() {
        return this._birthday;
    }

    set birthday(birthday) {
        this._birthday = birthday;
    }

    get nationality() {
        return this._nationality;
    }

    set nationality(nationality) {
        this._nationality = nationality;
    }

    get sport() {
        return this._sport;
    }

    set sport(sport) {
        this._sport = sport;
    }

    get additionalData() {
        return this._additionalData;
    }

    set additionalData(additionalData) {
        this._additionalData = additionalData;
    }

    get user() {
        return this._user;
    }

    set user(user) {
        this._user = user;
    }

    get entity() {
        return this._entity;
    }

    set entity(entity) {
        this._entity = entity;
    }
    //#endregion

    constructor(modelID:string, modelData: any) {
        super();
        this.parseMinifiedData(modelData);
        if(this.id !== undefined) {
            const modelId = DataModel.generateModelID(this, {"id": this.id});
            if(modelId !== false) {
                this.modelID = modelId
            }
        }
    }

    // #region FieldMappings Getters/Setters

    dataModelName() {
        return PlayerDataModel.MODEL_NAME
    }

    getKeyByMinifiedKey(minKey: string) {
        switch (minKey) {
            case "1":
                return PlayerDataModel.IDKey;
            case "2":
                return PlayerDataModel.numberKey;
            case "3":
                return PlayerDataModel.firstNameKey;
            case "4":
                return PlayerDataModel.middleNameKey;
            case "5":
                return PlayerDataModel.lastNameKey;
            case "6":
                return PlayerDataModel.sexKey;
            case "7":
                return PlayerDataModel.birthdayKey;
            case "8":
                return PlayerDataModel.nationalityKey;
            case "9":
                return PlayerDataModel.sportKey;
            case "10":
                return PlayerDataModel.additionalDataKey;
            case "11":
                return PlayerDataModel.userKey;
            case "12":
                return PlayerDataModel.entityKey;
            default:
                return ""
        }
    }

    getProperty(propertyKey: string) {
        switch (propertyKey) {
            case PlayerDataModel.IDKey:
                return this.id;
            case PlayerDataModel.numberKey:
                return this.number;
            case PlayerDataModel.firstNameKey:
                return this.firstName;
            case PlayerDataModel.middleNameKey:
                return this.middleName;
            case PlayerDataModel.lastNameKey:
                return this.lastName;
            case PlayerDataModel.sexKey:
                return this.sex;
            case PlayerDataModel.birthdayKey:
                return this.birthday;
            case PlayerDataModel.nationalityKey:
                return this.nationality;
            case PlayerDataModel.sportKey:
                return this.sport;
            case PlayerDataModel.additionalDataKey:
                return this.additionalData;
            case PlayerDataModel.userKey:
                return this.user;
            case PlayerDataModel.entityKey:
                return this.entity;
        }
    }

    setProperty(propertyKey: string, value: any) {
        switch (propertyKey) {
            case PlayerDataModel.IDKey:
                this.id = value;
                break;
            case PlayerDataModel.numberKey:
                this.number = value;
                break;
            case PlayerDataModel.firstNameKey:
                this.firstName = value;
                break;
            case PlayerDataModel.middleNameKey:
                this.middleName = value;
                break;
            case PlayerDataModel.lastNameKey:
                this.lastName = value;
                break;
            case PlayerDataModel.sexKey:
                this.sex = value;
                break;
            case PlayerDataModel.birthdayKey:
                this.birthday = value;
                break;
            case PlayerDataModel.nationalityKey:
                this.nationality = value;
                break;
            case PlayerDataModel.sportKey:
                this.sport = value;
                break;
            case PlayerDataModel.additionalDataKey:
                this.additionalData = value;
                break;
            case PlayerDataModel.userKey:
                if (value !== null) {
                    let userModel = null;
                    if (value instanceof DataModel) {
                        userModel = value;
                    } else {
                        userModel = DataModelMapper.createFromObject(value);
                    }
                    if (userModel instanceof UserDataModel) {
                        this.user = userModel;
                    }
                }
                break;
            case PlayerDataModel.entityKey:
                if (value !== null) {
                    let entityModel = null;
                    if (value instanceof DataModel) {
                        entityModel = value;
                    } else {
                        entityModel = DataModelMapper.createFromObject(value);
                    }
                    if (entityModel instanceof EntityDataModel) {
                        this.entity = entityModel;
                    }
                }
                break;
        }
    }

    //#endregion
}