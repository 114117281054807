<template>
    <div class="admin-section">
    <TheUserBar :user="getUser" />

    <div class="central-section">
        <TheNavigationBar :user="getUser" :navigationItems="navigationItems" />
        <TheMainSection>
            <template v-slot:default>
                <h1>{{title}}</h1>
                <slot />
            </template>
        </TheMainSection>
    </div>
    </div>

</template>

<script lang="ts">
import { defineComponent, reactive  } from 'vue'
import { NavigationItem } from './NavigationBarItem.vue'
import TheNavigationBar from './TheNavigationBar.vue'
import TheMainSection from './TheMainSection.vue'
import TheUserBar from './TheUserBar.vue'
import { useStore, createNamespacedHelpers} from 'vuex'
import { key } from '@/store'
import { useRouter } from 'vue-router'
import { User } from '@/models/main/User'
import { useMsalAuthentication } from '@/composables/auth/UseMSALAuthentication'
import { InteractionType, RedirectRequest } from '@azure/msal-browser'
import { organizerLoginRequest } from '../util/AuthConfig'

const userStoreMapper = createNamespacedHelpers('userStore')

const defaultNavigationItems: NavigationItem[] = [
    {title: "Home", routerLinkName: "Home", params: null, iconLink: null, allowedRoles: [] } as NavigationItem,
    // {title: "Dashboard", routerLinkName: "Dashboard", params: null, iconLink: null} as NavigationItem,
    {title: "Channels", routerLinkName: "Channels", params: null, iconLink: null, allowedRoles: ["Admin", "Organizer"] } as NavigationItem,
    {title: "Stations", routerLinkName: "Stations", params: null, iconLink: null, allowedRoles: ["Admin", "Organizer"] } as NavigationItem,
    {title: "User Management", routerLinkName: "UserManagement", params: null, iconLink: null, allowedRoles: ["Admin"] } as NavigationItem,
    {title: "Team Management", routerLinkName: "Team Management", params: null, iconLink: null, allowedRoles: ["Admin", "Organizer"] } as NavigationItem,
    {title: "Tournaments", routerLinkName: "Volleyball Tournament", params: null, iconLink: null, allowedRoles: ["Admin"] } as NavigationItem,
    {title: "Matches", routerLinkName: "Volleyball Match", params: null, iconLink: null, allowedRoles: ["Admin", "Organizer", "Operator"] } as NavigationItem,
    {title: "Livestreams", routerLinkName: "Livestreams", params: null, iconLink: null, allowedRoles: ["Admin", "Organizer"] } as NavigationItem,
    {title: "Live", routerLinkName: "Livestreams Dashboard", params: null, iconLink: null, allowedRoles: ["Admin", "Organizer", "Operator", "Player"] } as NavigationItem,
    {title: "Archive", routerLinkName: "Livestreams Archive", params: null, iconLink: null, allowedRoles: ["Admin", "Organizer", "Operator", "Player"] } as NavigationItem,
    //{title: "Profile", routerLinkName: "Profile", params: null, iconLink: null, allowedRoles: ["Admin", "Organizer", "Operator", "Player"] } as NavigationItem,
    // {title: "Rally Score", routerLinkName: "Rally Score", params: null, iconLink: null, allowedRoles: ["Admin", "Organizer", "Operator"] } as NavigationItem,

]

const TheAdminDashboard = defineComponent({
    props: {
        title: {
            type: String
        },
        onUserLoad: {
            type: Function,
            default(user: User) {
                console.info("User Loaded.")
                console.info(user)
            }
        }
    },
    computed: {
        ...userStoreMapper.mapGetters([
                'getUser'
            ]
        )
    },
    setup(props: any) {
        const store = useStore(key);
        const router = useRouter()
        const redirectRequest: RedirectRequest = organizerLoginRequest
        const { result } = useMsalAuthentication(InteractionType.Redirect, redirectRequest);
        console.info("HELLO")
        console.log(result);
        const getLoggedInUser = () => {
            store.dispatch('userStore/getCurrentUser').then((user: User) => {               
                if(user === null) {
                    router.push({name: "Profile"})
                } else {
                    props.onUserLoad(user)
                }
            })
        }
        //getLoggedInUser()

        const navigationItems = reactive<Array<NavigationItem>>(defaultNavigationItems)
        return { navigationItems }
    },
    components: {
        TheNavigationBar,
        TheMainSection,
        TheUserBar,
    }
})

export default TheAdminDashboard
</script>

<style>
.admin-section {
    --min-central-section-width: 100vw;
    --max-central-section-width: 1000px;
    --nav-width: 30vw;
    --nav-main-section-margin: 15px;
    display: flex;
    width: 100%;
    margin: 0 auto;
    position: relative;
    letter-spacing: 0.5px;
    --white-shade-bck-clr: #eee;
    --darker-font-clr: #4d406c;
    align-items: flex-start;
    flex-wrap: wrap;
    max-height: 100vh;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: var(--deep-blue-bck-color) var(--watch-bck-color);
}


.central-section {
    width: 100%;
    display: flex;
    min-height: calc(100% - 8rem);
    width: clamp(var(--min-central-section-width), 95vw, var(--max-central-section-width));
    margin: 0 auto;
    flex-wrap: wrap;
    position: relative;
    margin-top: 8rem;
}


@media  screen and (min-width: 875px) {
    .admin-section {
        --min-central-section-width: 875px;
        --nav-width: 225px;
    }
}

</style>