<template>
    <TheOrganizerDashboard  v-if="authModule.isAuthenticated && autoScroll != 1">
        <template #heading v-if="containerRankingView">
            <!-- <router-link :to="{ name: 'Organization Menu', params: { id: container?.organizationId }}">
                <div class="page-heading">{{ organization?.name }}</div>
            </router-link> -->
            <router-link :to="{ name: 'Container Menu', params: { organizationId: containerRankingView?.container?.organizationId, id : containerRankingView?.container?.id } }">
                <div class="page-heading">
                    Menu
                </div>
            </router-link>
            <router-link v-if="containerRankingView?.container" :to="{ name: 'Container Views', params: { id: containerRankingView?.container?.id }}">
                <div class="page-heading">
                    Rankings
                </div>
            </router-link>
            <div class="page-heading">
                {{ containerRankingView?.title }}
            </div>
        </template> 
        <img v-if="false" class="container-views-qr-code" :src="qrcode" alt="Link QR code" />
        <ContainerResultElimination v-if="containerRankingView?.viewType == 'Elimination'"/>
        <ContainerResultRoundRobin v-else-if="containerRankingView?.viewType == 'RoundRobin'"/>
        <ContainerResult v-else-if="containerRankingView?.viewType == 'Standard'"/>
    </TheOrganizerDashboard>
    <ThePublicHomeLayout v-else :title="containerRankingView?.title">
        <div class="public-container-view-wrapper">
            <h1>{{ containerRankingView?.title }}</h1>
            <img class="container-views-qr-code" :src="qrcode" alt="Link QR code" />
            <ContainerResultElimination v-if="containerRankingView?.viewType == 'Elimination'"/>
            <ContainerResultRoundRobin v-else-if="containerRankingView?.viewType == 'RoundRobin'"/>
            <ContainerResult v-else-if="containerRankingView?.viewType == 'Standard'"/>
        </div>
    </ThePublicHomeLayout>
</template>
<script setup lang="ts">
import TheOrganizerDashboard from '@/components/main/TheOrganizerDashboard.vue'
import ThePublicHomeLayout from '@/components/main/ThePublicHomeLayout.vue'
import ContainerResult from '@/components/main/container/ContainerResult.vue'
import ContainerResultElimination from '@/components/main/container/ContainerResultElimination.vue'
import ContainerResultRoundRobin from '@/components/main/container/ContainerResultRoundRobin.vue'
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { computed, ComputedRef, onMounted, ref, Ref } from 'vue'
import { key } from '@/store';
import { useQRCode } from '@vueuse/integrations/useQRCode'
import authModule from '@/main';

const store = useStore(key);
const route = useRoute()
let autoScroll: number = parseInt(route.query.scroll as string);
const containerRankingViewId: Ref<number> = ref(parseInt(route.params.id as string));
const containerRankingView: Ref<any | undefined> = ref()
const text = ref('https://portal.sportzone.live/container/4543/views')
const qrcode = useQRCode(text)

onMounted(() => {
    store.dispatch('containerStore/getContainerRankingView', containerRankingViewId.value)
    .then((response) => {
        console.log(response);
        containerRankingView.value = response
    })
})

</script>
<style>
.container-views-qr-code {
    display: none;
    position: fixed;
    top: var(--nav-height);
    right: 1rem;
    border-radius: var(--default-border-rad);
    z-index: 10;
}

.public-container-view-wrapper {
    padding:1rem 1rem;
    background-color:var(--background-clr);
    z-index:1;
    height:100%;
    width:100%
}

@media screen and (min-width: 1000px){
    .container-views-qr-code {
        display: block;
    }
}
</style>