import { IRootState, key } from '@/store';
import { Store, useStore } from 'vuex';
import { Container } from '@/models/main/Container';
import { computed, Ref } from '@vue/runtime-core';
import { Entity } from '@/models/main/Entity';
import useInterpretTableTennisEvents from './UseInterpretTableTennisEvents';
import Livestream from '@/models/main/Livestream';
import { TableTennisGameControlType } from './TableTennisEventTypes';
import { useRoute } from 'vue-router';
import useCurrentContainerComputeds from "../generic/UseCurrentContainerComputeds";
import GenericDictionary from '@/components/util/GenericDictionary';
import { Event } from '@/models/main/Event';

export default function useTableTennisContainerComputeds(currentContainerId: Ref<number>, liveStream?: Ref<Livestream | null>, playerCurrentUnixTime?: Ref<number>, isSpoiledModeOn?: Ref<boolean>) {
    const store = useStore(key);
    const route = useRoute()
    const {
        getCurrentContainer,
        getCurrentHomeEntity,
        getCurrentAwayEntity,
        formIsDisabled,
        onOpenMatch,
        onCompleteMatch,
        onReopenMatch,
        loadContainer,
        onShareLinkButtonClicked,
        accessKey,
        infoPopUp,
        shareLink,
        offlineDataDownloadLinkUri,
        onReverseClick,
        createEvent,
        addEvent,
        editEvent,
        onEventEdit,
        removeEvent,
        onHomePointBoxClick,
        onAwayPointBoxClick,
        toggleHomePlayerSelect,
        toggleAwayPlayerSelect,
        selectedHomePlayerId,
        selectedAwayPlayerId,
        selectedEventToEdit,
        advancedEditEnabled,
        getCurrentContainerEvents,
    } = useCurrentContainerComputeds(currentContainerId)


    const onMatchFinishedClick = async (entityId: number) => {
        if(currentContainerId.value !== undefined) {
            const matchFinishedEventValue: TableTennisGameControlType = "TblTenn_M"
            const event = createEvent(currentContainerId.value, "GameControl", entityId, matchFinishedEventValue,
                undefined,
                selectedEventToEdit.value ? selectedEventToEdit.value : undefined)
            if(selectedEventToEdit.value?.id) { // edit
                await editEvent(event)
            } else {
                await addEvent(event)
            }
        }
    }

    const onGameBoxClick = async (entityId: number) => {
        if(currentContainerId.value !== undefined) {
            const gameFinished: TableTennisGameControlType = "TblTenn_G"
            const event = createEvent(currentContainerId.value, "GameControl", entityId, gameFinished,
                undefined,
                selectedEventToEdit.value ? selectedEventToEdit.value : undefined)
            if(selectedEventToEdit.value?.id) { // edit
                await editEvent(event)
            } else {
                await addEvent(event)
            }
        }
    }

    const { calculateEntityPoints, calculateEntityGames, groupEventsByGames } = useInterpretTableTennisEvents()

    const getCurrentContainerEventsGroupedByGames = computed(() => {
        const homeEntityId = getCurrentHomeEntity.value?.id
        const awayEntityId = getCurrentAwayEntity.value?.id
        const currentContainer = getCurrentContainer.value
        const events: GenericDictionary<Event> = getCurrentContainerEvents.value ?? {}
        if(homeEntityId !== undefined && awayEntityId !== undefined && currentContainer !== undefined) {
            return groupEventsByGames(homeEntityId, awayEntityId, events, liveStream?.value?.status === "Stopping" || liveStream?.value?.status === "Completed" ? playerCurrentUnixTime?.value : undefined, isSpoiledModeOn?.value ? undefined : playerCurrentUnixTime?.value)
        }

        return {}
    })

    const getCurrentHomeEntityPoints = computed((): number => {
        const homeEntityId = getCurrentHomeEntity.value?.id
        const currentContainer = getCurrentContainer.value
        const events: GenericDictionary<Event> = getCurrentContainerEvents.value ?? {}
        if(homeEntityId !== undefined && currentContainer !== undefined) {
            return calculateEntityPoints(homeEntityId, events, liveStream?.value?.status === "Stopping" || liveStream?.value?.status === "Completed" ? playerCurrentUnixTime?.value : undefined)
        }

        return 0
    })

    const getCurrentAwayEntityPoints = computed((): number => {
        const awayEntityId = getCurrentAwayEntity.value?.id
        const currentContainer = getCurrentContainer.value
        const events: GenericDictionary<Event> = getCurrentContainerEvents.value ?? {}
        if(awayEntityId !== undefined && currentContainer !== undefined) {
            return calculateEntityPoints(awayEntityId, events, liveStream?.value?.status === "Stopping" || liveStream?.value?.status === "Completed" ? playerCurrentUnixTime?.value : undefined)
        }

        return 0
    })

    const currentHomeEntityGamePoints = computed((): number => {
        const homeEntityId = getCurrentHomeEntity.value?.id
        const currentContainer = getCurrentContainer.value
        const events: GenericDictionary<Event> = getCurrentContainerEvents.value ?? {}
        if(homeEntityId !== undefined && currentContainer !== undefined) {
            return calculateEntityGames(homeEntityId, events, liveStream?.value?.status === "Stopping" || liveStream?.value?.status === "Completed" ? playerCurrentUnixTime?.value : undefined)
        }
        return 0
    })

    const currentAwayEntityGamePoints = computed((): number => {
        const awayEntityId = getCurrentAwayEntity.value?.id
        const currentContainer = getCurrentContainer.value
        const events: GenericDictionary<Event> = getCurrentContainerEvents.value ?? {}
        if(awayEntityId !== undefined && currentContainer !== undefined) {
            return calculateEntityGames(awayEntityId, events, liveStream?.value?.status === "Stopping" || liveStream?.value?.status === "Completed" ? playerCurrentUnixTime?.value : undefined)
        }

        return 0
    })

    return {
        getCurrentContainer,
        getCurrentHomeEntity,
        getCurrentAwayEntity,
        formIsDisabled,
        onOpenMatch,
        onCompleteMatch,
        onReopenMatch,
        loadContainer,
        onShareLinkButtonClicked,
        accessKey,
        infoPopUp,
        shareLink,
        offlineDataDownloadLinkUri,
        onReverseClick,
        createEvent,
        addEvent,
        editEvent,
        onEventEdit,
        removeEvent,
        onHomePointBoxClick,
        onAwayPointBoxClick,
        toggleHomePlayerSelect,
        toggleAwayPlayerSelect,
        selectedHomePlayerId,
        selectedAwayPlayerId,
        selectedEventToEdit,
        advancedEditEnabled,
        calculateEntityPoints,
        calculateEntityGames,
        groupEventsByGames,
        getCurrentContainerEventsGroupedByGames,
        onMatchFinishedClick,
        onGameBoxClick,
        getCurrentHomeEntityPoints,
        getCurrentAwayEntityPoints,
        currentHomeEntityGamePoints,
        currentAwayEntityGamePoints,
    }
}