<template>
    <TheOrganizerDashboard>
        <template #heading v-if="getCurrentContainer">
            <router-link :to="{ name: 'Organizations' }">
                <div class="page-heading">
                    Organizations
                </div>
            </router-link>
            <router-link :to="{ name: 'Organization Menu', params: { id: getCurrentContainer.organization?.id } }">
                <div class="page-heading">
                    {{ getCurrentContainer.organization?.name }}
                </div>
            </router-link>
            <router-link
                :to="{ name: 'Organization Containers', params: { id: getCurrentContainer.organization?.id } }">
                <div class="page-heading">
                    Matches
                </div>
            </router-link>
            <router-link :to="{ name: 'Volleyball Score', params: { id: getCurrentContainer.id } }">
                <div class="page-heading">
                    Score
                </div>
            </router-link>
        </template>
        <div class="vly-container-manipulate-wrapper" :class="{'advanced' : advancedEditEnabled}">
            <LivePlayer v-if="advancedEditEnabled && livestream"
                :startPlayingAt="playerAtSeconds" :livestream="livestream" :live-player-should-seek-toggle="livePlayerShouldSeekToggle"
                @playerDurationChange="onPlayerDurationChange">
            </LivePlayer>
            <div class="vly-events" v-if="advancedEditEnabled && livestream">
                <div class="vly-event-item" v-for="event in getCurrentContainerEvents" :key="event.id" :class="{ 'selected' : event.id === selectedEventToEdit?.id}" 
                :set="player = event.playerId ? getPlayer(event.playerId, event.entityId) : undefined">
                    <template v-if="event && event.id" >
                        <div class="vly-event-item__start" role="button" @click="onEventStartTimeClick(event)" v-if="livestream">{{ getEventRelativeStartTime(livestream, event) }}</div>
                        <div class="vly-event-item__description">
                        {{ event.description ? event.type == "Point" ?  VOLLEYBALL_POINT_TITLE_PAIRS[event.description] : VOLLEYBALL_GAME_CONTROL_TYPES[event.description] : '' }}</div>
                        <div class="vly-event-item__entity">{{ event.entityId === getCurrentHomeEntity?.id ? getCurrentHomeEntity?.name : getCurrentAwayEntity?.name }}</div>
                        <div class="vly-event-item__player">{{ player ? player.number : '' }}</div>
                        <div class="vly-event-item__action">
                            <button class="btn mini edit-btn" @click="onEventEdit(event), onEventStartTimeClick(event)"></button>
                        </div>
                        <div class="vly-event-item__action">
                            <button class="btn mini delete-btn" @click="removeEvent(event.id)">Delete</button>
                        </div>
                    </template>
                </div>
            </div>
            <div class="vly-score">
                <div class="container-title">{{ getCurrentContainer?.title }}</div>
                <div class="score-form"
                    :class="{ 'disabled': formIsDisabled, 'finished': getCurrentContainer?.status === 'Finished' }">
                    <div class="break-controls">
                        <button class="btn" type="button" title="Stats" @click="showStats">Show Stats</button>
                        <a v-show="offlineDataDownloadLinkUri"
                            :download="getCurrentContainer?.title + '_offline' + '.csv'" class="btn" type="button"
                            :href="offlineDataDownloadLinkUri" title="Save Stats">Download
                            Changes</a>
                    </div>
                    <div class="break-controls">
                        <button @click="onOpenMatch" v-if="getCurrentContainer?.status === CONTAINER_STATUSES[0]"
                            class="btn edit-btn" type="button" title="Start Match">Start</button>
                        <button @click="onReopenMatch" v-else-if="getCurrentContainer?.status === CONTAINER_STATUSES[2]"
                            class="btn edit-btn" type="button" title="Edit Match">Edit</button>
                        <button @click="onCompleteMatch" v-else class="btn accept-btn" type="button"
                            title="Complete Match">Complete</button>
                    </div>
                    <div class="break-controls">
                        <button @click="onShareLinkButtonClicked" class="btn btn-inverse" type="button"
                            title="Share">Share</button>
                        <div class="spz-checkbox-group advanced-mode-control">
                            <label class="spz-toggle">
                                <span class="spz-toggle-label">Advanced</span>
                                <input id="enable-advanced" class="spz-toggle-checkbox" @change="onAdvancedModeChange" v-model="advancedEditEnabled" type="checkbox" />
                                <div class="spz-toggle-switch"></div>
                            </label>
                        </div>
                    </div>
                    <div class="match-controls">
                        <div class="left-control">
                            <div class="event-inputs-wrapper" v-if="advancedEditEnabled && livestream">
                                <div class="spz-text-input-group">
                                    <label for="event-start-time">Seconds</label>
                                    <input type="text" id="event-start-time" name="event-start-time"
                                        v-bind:value="getEventRelativeTimeOnPlayer(livestream.streamActualStartTime, selectedEventToEdit?.startTimestamp)"/>
                                </div>
                                <div class="spz-text-input-group" v-if="false" >
                                    <label for="event-end-time">End</label>
                                    <input type="text" id="event-end-time" name="event-end-time" placeholder="End" 
                                        v-bind:value="getEventRelativeTimeOnPlayer(livestream.streamActualStartTime, selectedEventToEdit?.endTimestamp)"/>
                                </div>
                            </div>
                            <button v-if="false" @click="onMatchFinishedClick(getCurrentHomeEntity?.id)"
                                class="match-control-btn default-box" type="button" title="Match Finished"
                                :disabled="formIsDisabled">Match Finished</button>
                            <button v-if="false" @click="onTimeoutClick(getCurrentHomeEntity?.id)"
                                class="match-control-btn default-box" type="button" title="Timeout"
                                :disabled="formIsDisabled">Timeout</button>
                        </div>
                        <div class="middle-control">
                            <button @click="onReverseClick" class="btn delete-btn" type="button" title="Undo"
                                :disabled="formIsDisabled">Undo</button>
                        </div>
                        <div class="right-control">
                            <button v-if="false" @click="onTimeoutClick(getCurrentAwayEntity?.id)"
                                class="match-control-btn default-box" type="button" title="Timeout"
                                :disabled="formIsDisabled">Timeout</button>
                            <button v-if="false" @click="onMatchFinishedClick(getCurrentAwayEntity?.id)"
                                class="match-control-btn default-box" type="button" title="Match Finished"
                                :disabled="formIsDisabled">Match Finished</button>
                        </div>
                    </div>
                    <div class="match-scoreboard">
                        <div class="home-team">
                            <div class="main-scoreboard">
                                <div class="scoreboard-top">
                                    <div class="team-name default-box">{{ getCurrentHomeEntity?.name }}</div>
                                    <ActionBox :isDisabled="formIsDisabled" class="game-point"
                                        @click="onHomePointBoxClick">
                                        {{ getCurrentHomeEntityPoints }}</ActionBox>
                                </div>
                                <div class="scoreboard-bottom">
                                    <ActionBox :isDisabled="formIsDisabled"
                                        @click="onGameBoxClick(getCurrentHomeEntity?.id)" style="float:left;">
                                        {{ currentHomeEntityGamePoints }}</ActionBox>
                                    <div v-if="false" class="timeouts-box">
                                        <input type="checkbox" value="1" v-model="homeTimeouts" />
                                        <input type="checkbox" value="2" v-model="homeTimeouts" />
                                    </div>
                                </div>
                            </div>
                            <div class="detail-points-board">
                                <div class="home-team-competitors">
                                    <template v-for="player in getCurrentHomePlayersOrdered" :key="player.id">
                                        <CompetitorNameToggle v-if="player.isActive" :isDisabled="formIsDisabled"
                                            @click="toggleHomePlayerSelect(player.id)"
                                            :isSelected="selectedHomePlayerId === player.id" :number="player.number"
                                            :firstName="player.firstName" :middleName="player.middleName"
                                            :lastName="player.lastName" />
                                    </template>

                                </div>
                                <div class="home-point-types">
                                    <PointTypesSet :isDisabled="formIsDisabled" @click="onHomePointBoxClick" />
                                </div>
                            </div>
                        </div>
                        <div class="splitter">:</div>
                        <div class="away-team">
                            <div class="main-scoreboard">
                                <div class="scoreboard-top">
                                    <div class="team-name default-box">{{ getCurrentAwayEntity?.name }}</div>
                                    <ActionBox :isDisabled="formIsDisabled" class="game-point"
                                        @click="onAwayPointBoxClick">
                                        {{ getCurrentAwayEntityPoints }}</ActionBox>
                                </div>
                                <div class="scoreboard-bottom">
                                    <div v-if="false" class="timeouts-box">
                                        <input type="checkbox" value="1" v-model="awayTimeouts" />
                                        <input type="checkbox" value="2" v-model="awayTimeouts" />
                                    </div>
                                    <ActionBox :isDisabled="formIsDisabled"
                                        @click="onGameBoxClick(getCurrentAwayEntity?.id)" style="float:right;">
                                        {{ currentAwayEntityGamePoints }}</ActionBox>
                                </div>
                            </div>
                            <div class="detail-points-board">
                                <div class="away-team-competitors">
                                    <template v-for="player in getCurrentAwayPlayersOrdered" :key="player.id">
                                        <CompetitorNameToggle v-if="player?.isActive" :isDisabled="formIsDisabled"
                                            @click="toggleAwayPlayerSelect(player.id)"
                                            :isSelected="selectedAwayPlayerId === player.id" :number="player.number"
                                            :firstName="player.firstName" :middleName="player.middleName"
                                            :lastName="player.lastName" />
                                    </template>

                                </div>
                                <div class="away-point-types">
                                    <PointTypesSet :isDisabled="formIsDisabled" @click="onAwayPointBoxClick" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal ref="infoPopUp" class="mini">
                    <template v-slot:header>
                        <h1>Share</h1>
                    </template>
                    <template v-slot:body>
                        <div class="link-section">
                            {{ shareLink }}
                        </div>
                    </template>
                    <template v-slot:footer>
                        <div>
                            <button @click="infoPopUp.closeModal(), copyToClipboard(shareLink)"
                                class="btn">Copy</button>
                            <button @click="infoPopUp.closeModal()" class="btn btn-inverse">Cancel</button>
                        </div>
                    </template>
                </Modal>
                <Modal ref="statsPopup" class="modal-large">
                    <template v-slot:header>
                        <h1>Match Stats</h1>
                    </template>
                    <template v-slot:body>
                        <div class="entity-stats-table-wrapper">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Team</th>
                                        <th>Player</th>
                                        <th>Player Number</th>
                                        <th>{{ VOLLEYBALL_POINT_TITLE_PAIRS[VLY_ACE] }}</th>
                                        <th>{{ VOLLEYBALL_POINT_TITLE_PAIRS[VLY_ATTACK] }}</th>
                                        <th>{{ VOLLEYBALL_POINT_TITLE_PAIRS[VLY_BLOCK] }}</th>
                                        <th>{{ VOLLEYBALL_POINT_TITLE_PAIRS[VLY_SERVICE_ERROR] }}</th>
                                        <th>{{ VOLLEYBALL_POINT_TITLE_PAIRS[VLY_ERROR] }}</th>
                                        <th>{{ VOLLEYBALL_POINT_TITLE_PAIRS[VLY_FOUL] }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(playerStat, playerId) in getCurrentContainerBasicStats?.homeEntity[0].playersStats"
                                        :key="playerId">
                                        <template
                                            v-if="getCurrentHomeEntity && getCurrentHomeEntity?.players && getCurrentHomeEntity.players[playerId]">
                                            <td>
                                                {{ getCurrentHomeEntity.name }}
                                            </td>
                                            <td>{{ getCurrentHomeEntity.players[playerId].firstName }} {{
            getCurrentHomeEntity.players[playerId].lastName }}</td>
                                            <td>{{ getCurrentHomeEntity.players[playerId].number }}</td>
                                            <td>{{ playerStat[VLY_ACE]?.count ?? 0 }}</td>
                                            <td>{{ playerStat[VLY_ATTACK]?.count ?? 0 }}</td>
                                            <td>{{ playerStat[VLY_BLOCK]?.count ?? 0 }}</td>
                                            <td>{{ playerStat[VLY_SERVICE_ERROR]?.count ?? 0 }}</td>
                                            <td>{{ playerStat[VLY_ERROR]?.count ?? 0 }}</td>
                                            <td>{{ playerStat[VLY_FOUL]?.count ?? 0 }}</td>
                                        </template>
                                    </tr>
                                    <tr v-for="(playerStat, playerId) in getCurrentContainerBasicStats?.awayEntity[0].playersStats"
                                        :key="playerId">
                                        <template
                                            v-if="getCurrentAwayEntity && getCurrentAwayEntity?.players && getCurrentAwayEntity.players[playerId]">
                                            <td>
                                                {{ getCurrentAwayEntity.name }}
                                            </td>
                                            <td>{{ getCurrentAwayEntity.players[playerId].firstName }}
                                                {{ getCurrentAwayEntity.players[playerId].middleName }}{{
            (getCurrentAwayEntity.players[playerId].middleName ? " " : "") }}{{
            getCurrentAwayEntity.players[playerId].lastName }}</td>
                                            <td>{{ getCurrentAwayEntity.players[playerId].number }}</td>
                                            <td>{{ playerStat[VLY_ACE]?.count ?? 0 }}</td>
                                            <td>{{ playerStat[VLY_ATTACK]?.count ?? 0 }}</td>
                                            <td>{{ playerStat[VLY_BLOCK]?.count ?? 0 }}</td>
                                            <td>{{ playerStat[VLY_SERVICE_ERROR]?.count ?? 0 }}</td>
                                            <td>{{ playerStat[VLY_ERROR]?.count ?? 0 }}</td>
                                            <td>{{ playerStat[VLY_FOUL]?.count ?? 0}}</td>
                                        </template>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>
                    <template v-slot:footer>
                        <div>
                            <button @click="statsPopup.closeModal()" class="btn btn-inverse">Close</button>
                        </div>
                    </template>
                </Modal>
            </div>
        </div>
    </TheOrganizerDashboard>
</template>
<script setup lang="ts">
import TheOrganizerDashboard from '@/components/main/TheOrganizerDashboard.vue'
import CompetitorNameToggle from '@/components/volleyball/CompetitorNameToggle.vue'
import PointTypesSet from '@/components/volleyball/PointTypesSet.vue'
import ActionBox from '@/components/volleyball/ActionBox.vue'
import { useStore } from 'vuex'
import { computed, onMounted, ref, Ref, watch } from 'vue'
import { key } from '@/store'
import { CONTAINER_STATUSES, Container } from '@/models/main/Container'
import { Event } from "@/models/main/Event";
import { useRoute } from 'vue-router'
import { VLY_ACE, VLY_ATTACK, VLY_BLOCK, VLY_ERROR, VLY_FOUL, VLY_SERVICE_ERROR, VOLLEYBALL_POINT_TITLE_PAIRS, VOLLEYBALL_GAME_CONTROL_TYPES } from '@/composables/volleyball/VolleyballEventTypes'
import useVolleyballContainerActions from '@/composables/volleyball/UseVolleyballContainerActions'
import Modal from '@/components/main/Modal.vue';
import { copyToClipboard } from '@/composables/common/Util';
import LivePlayer from '@/components/main/LivePlayer.vue'
import Livestream from '@/models/main/Livestream'
import { Player } from '@/models/main/Player'
const store = useStore(key);
const route = useRoute()
const statsPopup: Ref<any> = ref(null);
    const {
    getCurrentContainer,
    getCurrentContainerEvents,
    getCurrentHomeEntity,
    getCurrentAwayEntity,
    getCurrentHomeEntityPoints,
    getCurrentAwayEntityPoints,
    currentHomeEntityGamePoints,
    currentAwayEntityGamePoints,
    getCurrentHomePlayersOrdered,
    getCurrentAwayPlayersOrdered,
    getCurrentContainerBasicStats,
    offlineDataDownloadLinkUri,
    homeTimeouts,
    awayTimeouts,
    selectedHomePlayerId,
    selectedAwayPlayerId,
    toggleHomePlayerSelect,
    toggleAwayPlayerSelect,
    onHomePointBoxClick,
    onAwayPointBoxClick,
    onMatchFinishedClick,
    onReverseClick,
    onGameBoxClick,
    onTimeoutClick,
    onShareLinkButtonClicked,
    onOpenMatch,
    onReopenMatch,
    onCompleteMatch,
    loadContainer,
    formIsDisabled,
    shareLink,
    infoPopUp,
    removeEvent,
    onEventEdit,
    advancedEditEnabled,
    selectedEventToEdit,
    playerCurrentUnixTime,
} = useVolleyballContainerActions()

const showStats = () => {
    statsPopup.value.openModal();
}

let containerFirstLivestreamId: number | undefined = undefined
const livestream = computed((): Livestream | undefined => {
    if(getCurrentContainer.value?.id) {
        return store.getters['livestreamStore/getLivestream'](containerFirstLivestreamId)
    }
})

const livePlayerShouldSeekToggle: Ref<boolean> = ref(false)
const playerAtSeconds: Ref<number> = ref(0)  
const getEventRelativeStartTime = (livestream: Livestream, event: Event) => {
    const streamStart = livestream.streamActualStartTime
    const eventStart = event.startTimestamp
    if(streamStart && eventStart) {
        const diffInMilliseconds = eventStart.getTime() - streamStart.getTime();
        const diffInSeconds = Math.ceil(diffInMilliseconds / 1000);
        const timerMinutes = Math.floor(diffInSeconds / 60);
        const timerSeconds = diffInSeconds - (timerMinutes * 60)
        const timerString = (timerMinutes < 10 ? '0' + timerMinutes : timerMinutes) + ':' + (timerSeconds < 10 ? '0' + timerSeconds : timerSeconds)
        return timerString
    }

    return 0
}
const advanceSecs = 0;
const onPlayerDurationChange = (currentDuration: number) => {
    if(advancedEditEnabled.value && livestream.value) {
        let liveStarttime = 0
        if (livestream.value.streamActualStartTime) {
            liveStarttime = new Date(livestream.value.streamActualStartTime).getTime() / 1000
        }

        playerCurrentUnixTime.value = currentDuration + liveStarttime - advanceSecs
        if(!selectedEventToEdit.value || selectedEventToEdit.value.id === undefined) {
            const defaultNewEvent: Event = {
                type: null,
                startTimestamp: new Date(playerCurrentUnixTime.value * 1000),
                endTimestamp: new Date(playerCurrentUnixTime.value * 1000),
                description: null
            }
            selectedEventToEdit.value = defaultNewEvent
        }
    } else if(selectedEventToEdit.value) {
        selectedEventToEdit.value = undefined
    }
}

const onEventStartTimeClick = (event: Event) => {
    if(livestream.value) {
        const atSeconds = getEventRelativeTimeOnPlayer(livestream.value.streamActualStartTime, event.startTimestamp)
        if(playerAtSeconds.value === atSeconds) {
            livePlayerShouldSeekToggle.value = !livePlayerShouldSeekToggle.value // trick to force Live player to seek even if the value is the same
        }
        playerAtSeconds.value = atSeconds
    }
}

const getEventRelativeTimeOnPlayer = (streamStartTime?: Date | null, eventAbsoluteTime?: Date | null) => {
    if(streamStartTime && eventAbsoluteTime) {
        const diffInMilliseconds = eventAbsoluteTime.getTime() - streamStartTime.getTime();
        return  Math.ceil(diffInMilliseconds / 1000);
    }

    return 0
}

const getPlayer = (playerId: number, entityId?: number): Player | undefined =>  {
    let firstTeamPlayers: Player[] = getCurrentHomePlayersOrdered.value ?? []
    let searchSecondTeam = true
    if(entityId) { // assuming entityId is valid for the player sought
        searchSecondTeam = false // its either home or away we already have home as default
        if(getCurrentAwayEntity.value?.id === entityId) { // so we now only check if its away
            firstTeamPlayers = getCurrentAwayPlayersOrdered.value ?? []
        }
    }

    for(let i = 0; i < firstTeamPlayers.length; i++) {
        const player = firstTeamPlayers[i]
        if(player.id === playerId) {
            return player
        }
    }

    if(searchSecondTeam) {
        const awayTeamPlayers = getCurrentAwayPlayersOrdered.value ?? []
        for(let i = 0; i < awayTeamPlayers.length; i++ ){
            const player = awayTeamPlayers[i]
            if(player.id === playerId) {
                return player
            }
        }
    }

    return
}

const onAdvancedModeChange = () => {
    if(!advancedEditEnabled.value) {
        playerCurrentUnixTime.value = undefined;
    }
}

watch(getCurrentContainer, (container: Container | undefined) => {
    if(container?.livestreams && container?.livestreams[0]) {
        const livestreamToFetch = container?.livestreams[0]
        containerFirstLivestreamId = livestreamToFetch.id
        store.dispatch('livestreamStore/getLivestream', containerFirstLivestreamId)
    }
})

onMounted(loadContainer);
</script>

<style>
.vly-container-manipulate-wrapper {
    display: grid;
    grid-template-areas: 
    "score" ;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: 100%;
    width: 100%;
    gap: 1rem;
}

.vly-container-manipulate-wrapper.advanced {
    grid-template-areas: 
    "player player player player score score score score score" 
    "player player player player score score score score score" 
    "player player player player score score score score score" 
    "events events events events score score score score score" 
    "events events events events score score score score score" 
    "events events events events score score score score score"
    "events events events events score score score score score";
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(7, 1fr);


}



.vly-container-manipulate-wrapper .stream-player {
    grid-area: player;
}

.vly-container-manipulate-wrapper .vly-score {
    grid-area: score;
    justify-self: center;
}

.vly-container-manipulate-wrapper .vly-events {
    grid-area: events;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-height: 100%;
    overflow-y: auto;
    scrollbar-width: thin;
}

.vly-event-item {
    display: flex;
    gap: 0.5rem;
    background-color: var(--bright-font-clr);
    border-radius: var(--default-border-rad);
    padding: 0.5rem 1rem;
}

.vly-event-item.selected {
    background-color: var(--darker-font-clr);
    color: var(--bright-font-clr);
}

.vly-event-item > * ~ * {
  border-left: 2px solid var(--main-font-clr);
  padding-left: 0.5rem;
}

.vly-event-item > * {
    flex: 1;
    display: flex;
    align-items: center;
}

.vly-event-item__description {
    flex-basis: 4rem;
}

.vly-event-item__entity {
  flex-basis: 5rem;
}

.vly-event-item__start:hover {
    color: var(--archive-bck-color);
    font-weight: 500;
}
.vly-event-item__start {
    cursor: pointer;
}

.advanced-mode-control {
    justify-content: flex-end;
    margin: 0;
}

.event-inputs-wrapper {
    display: flex;
    gap: 0.5rem;
    width: 100%;
}

.event-inputs-wrapper .spz-text-input-group {
  margin: 0;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

.vly-score h1 {
    font-size: 1.5rem;
}

.vly-score .score-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    --splitter-width: 0.5rem;
    --point-types-width: 8em;
    width: 100%;
    flex-wrap: wrap;
}

.vly-score .container-title {
    font-size: 1.5em;
    font-weight: 500;
    text-align: center;
}

.score-form.disabled:not(.finished) {
    cursor: progress;
}

.away-team .scoreboard-top {
    flex-direction: row-reverse;
}

.match-controls {
    display: flex;
    justify-content: space-between;
}

.match-scoreboard {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 2ch;
}

.home-team,
.away-team {
    display: flex;
    flex-direction: column;
    width: calc((100% - var(--splitter-width))/2);
}

.detail-points-board {
    display: flex;
    font-weight: 600;
}

.home-team-competitors,
.away-team-competitors,
.away-point-types,
.home-point-types {
    display: flex;
    flex-direction: column;
}

.away-point-types,
.home-point-types {
    width: var(--point-types-width);
    align-items: flex-end;
}

.away-team .action-box {
    margin-left: 0;
}

.home-team .action-box,
.away-team .action-box {
    margin-top: 0.1ch;
}


.home-point-types .action-box,
.away-point-types .action-box {
    justify-content: center;
    width: calc(100% - 2ch);
}

.home-point-types .action-box:nth-child(4),
.away-point-types .action-box:nth-child(4) {
    margin-bottom: 2.9em;
}

.vly-score .home-point-types .action-box:nth-child(5),
.vly-score .away-point-types .action-box:nth-child(5),
.vly-score .home-point-types .action-box:nth-child(6),
.vly-score .away-point-types .action-box:nth-child(6),
.vly-score .home-point-types .action-box:nth-child(7),
.vly-score .away-point-types .action-box:nth-child(7),
.vly-score .home-point-types .action-box:nth-child(8),
.vly-score .away-point-types .action-box:nth-child(8) {
    color: var(--watch-bck-color);
}


.home-point-types .action-box,
.home-team .action-box {
    margin-right: 0;
}

.away-point-types .action-box {
    margin-left: 0;
}

.home-team-competitors,
.away-team-competitors {
    width: 4em;
}

.scoreboard-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.away-team .scoreboard-bottom {
    justify-content: right;
    /*TODO: TEMP*/
}


.splitter {
    font-size: 2em;
    width: var(--splitter-width);
    text-align: center;
}

.default-box {
    border: 2px solid #ccc;
    background-color: #fafafa;
    border-radius: var(--default-border-rad);
}

.team-name {
    padding: 0.25ch 0.5ch;
    width: calc(100% - 3.5ch);
    color: var(--darker-font-clr);
    font-weight: 600;
    letter-spacing: .05em;
    font-size: 1.1em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.game-point {
    width: 4ch;
    justify-content: center;
    align-items: center;
    height: 4ch;
    padding: 0;
    margin: 0;
}

.home-team .game-point {
    margin-left: 0.5ch;
}

.away-team .game-point {
    margin-right: 0.5ch;
}

.left-control {
    text-align: left;
}

.right-control {
    text-align: right;
}

.match-controls {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 .75em;
    margin-bottom: .5em;
}

.match-control-btn {
    color: var(--watch-bck-color);
    font-size: 1rem;
    cursor: pointer;
    padding: 0.5ch 1ch;
    border-radius: var(--default-border-rad);
}

.match-control-btn[disabled] {
    cursor: no-drop;
}

.vly-score .break-controls {
    margin-bottom: 10px;
    display: flex;
    width: 33.333%;
    justify-content: center;
}

.vly-score .break-controls:nth-child(1) {
    justify-content: flex-start;
}

.vly-score .break-controls:nth-child(3) {
    justify-content: center;
    flex-direction: column;
    align-items: flex-end;
}

.score-form .action-box>span {
    display: none;
}

.score-form .action-box>.cmp-number {
    display: block;
}

.entity-stats-table-wrapper table {
    width: 95%;
}

@media screen and (min-width: 1050px) {
    .score-form {
        /* max-width: 55em; */
        --point-types-width: 10em;
    }

    .vly-score {
        max-width: 1000px;
    }
}


@media screen and (min-width: 485px) {
    .score-form .action-box>span {
        display: block;
    }

    .home-team-competitors,
    .away-team-competitors {
        width: calc(100% - var(--point-types-width));
    }
}
</style>