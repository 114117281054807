import { SportzoneAuth } from './components/util/SportzoneAuth';
import { createApp } from 'vue';
import App from './App.vue';
import VueAxios from 'vue-axios';
import axios from 'axios';
import router from './router';
import { store, key } from './store';
import contextualizer from '@/plugins/Contextualizer';
import { TERMS_IN_SPORT_CONTEXT } from './composables/common/Constants';
import { AuthenticationResult, EventType } from '@azure/msal-browser';
import { msalPlugin } from './plugins/MSALPlugin';
import { CustomNavigationClient } from './router/NavigationClient';
import { msalInstance } from './components/util/AuthConfig';
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import VueGtag from 'vue-gtag';
import { createPinia } from 'pinia'

// The next 2 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
const navigationClient = new CustomNavigationClient(router);
msalInstance.setNavigationClient(navigationClient);

msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

const authModule: SportzoneAuth = new SportzoneAuth(router);
export default authModule;

function startApp() {
    const pinia = createPinia()
    const app = createApp(App);
    app.use(pinia)
    app.use(VueAxios, axios);
    app.use(contextualizer, TERMS_IN_SPORT_CONTEXT)
    app.use(router);
    app.use(store, key);
    app.use(msalPlugin, msalInstance);
    app.use(ToastPlugin);
    app.use(VueGtag, {
        config: {

            id: 'G-DXT887BF0E'//process.env.GOOGLE_ANALYTICS_PROPERTY_ID

        }
    });
    router.isReady().then(() => {
        console.info("App is ready")
        // Waiting for the router to be ready prevents race conditions when returning from a loginRedirect or acquireTokenRedirect
        app.mount('#app');
    });
}

startApp();
