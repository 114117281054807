import { Sport } from "./CommonTypes"
import { Entity } from "./Entity"
import { Organization } from "./Organization"
import { User } from "./User"

export const INVITE_STATUSES = ["Pending", "Accepted", "Revoked", "Rejected"]
export type InviteStatus = typeof INVITE_STATUSES[number]

export interface OrganizationInvitation {
    id: number,
    organizationId: number,
    organization?: Organization,
    userId?: number,
    user: User | null,
    email?: string,
    inviteeFirstName?: string,
    inviteeLastName?: string,
    inviteeNationality?: string,
    dateCreated: Date,
    validUntil: Date,
    status: InviteStatus,
    message: string,
}