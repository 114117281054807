<template>
    <ThePublicHomeLayout>
    <div class="privacy-policy-wrapper">
        <div><strong><span data-custom-class="title">PRIVACY NOTICE</span></strong>
        </div>
        <div><strong><span data-custom-class="subtitle">Last updated December 20, 2021</span></strong></div>
        <div style="line-height: 1.5;">
            <span data-custom-class="body_text">Thank you for choosing to be part of our community at AtiaSoft EOOD
                    ("<strong>Company</strong>","<strong>we</strong>","<strong>us</strong>" or "<strong>our</strong>"). We are committed to protecting your personal information and your right to privacy. 
                    If you have any questions or concerns about this privacy notice or our practices with regard to your personal information, please contact us at bowlzoneatia@gmail.com.
            </span>
        </div>
        <div style="line-height: 1.5;">
            <span data-custom-class="body_text">This privacy notice describes how we might use your information if you:</span>
        </div>
        <ul>
            <li style="line-height: 1.5;">
                <span data-custom-class="body_text">Visit our website at 
                    <a href="http://www.sportzone.live" target="_blank" data-custom-class="link">http://www.sportzone.live</a>
                </span>
            </li>
            <li style="line-height: 1.5;">
                    <span data-custom-class="body_text">Engage with us in other related ways ― including any sales, marketing, or events</span>
            </li>
        </ul>
        <div>In this privacy notice, if we refer to:</div>
        <ul>
            <li style="line-height: 1.5;">
                <span data-custom-class="body_text">
                    "<strong>Website</strong>," we are referring to any website of ours that references or links to this policy
                </span>
            </li>
            <li style="line-height: 1.5;">
                    <span data-custom-class="body_text">"<strong>Services</strong>", we are referring to our Website, and other related services, including any sales, marketing, or events
                    </span>
            </li>
        </ul>
        <div>
                <span data-custom-class="body_text">The purpose of this privacy notice is to explain to you in the clearest way possible what information we collect, 
                        how we use it, and what rights you have in relation to it. 
                        If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Services immediately.
                </span>
                <span data-custom-class="body_text">
                        <strong>Please read this privacy notice carefully, as it will help you understand what we do with the information that we collect.</strong>
                </span>
        </div>
        <h1>TABLE OF CONTENTS</h1>
        <div class="table-of-contents">
                <a data-custom-class="link" href="#infocollect">1. WHAT INFORMATION DO WE COLLECT?</a>
                <a data-custom-class="link" href="#infouse">2. HOW DO WE USE YOUR INFORMATION?</a>
                <a data-custom-class="link" href="#infoshare">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a>
                <a data-custom-class="link" href="#whoshare">4. WHO WILL YOUR INFORMATION BE SHARED WITH?</a>
                <a data-custom-class="link" href="#cookies">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a>
                <a data-custom-class="link" href="#sociallogins">6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>
                <a data-custom-class="link" href="#inforetain">7. HOW LONG DO WE KEEP YOUR INFORMATION?</a>
                <a data-custom-class="link" href="#infosafe">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
                <a data-custom-class="link" href="#privacyrights">9. WHAT ARE YOUR PRIVACY RIGHTS?</a>
                <a data-custom-class="link" href="#DNT">10. CONTROLS FOR DO-NOT-TRACK FEATURES</a>
                <a data-custom-class="link" href="#caresidents">11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a>
                <a data-custom-class="link" href="#policyupdates">12. DO WE MAKE UPDATES TO THIS NOTICE?</a>
                <a data-custom-class="link" href="#contact">13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
                <a data-custom-class="link" href="#request">14. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?</a>
        </div>
        <div id="infocollect" style="line-height: 1.5;">
                <h1>1. WHAT INFORMATION DO WE COLLECT?</h1>
                <strong>Personal information you disclose to us</strong>
                <strong><em>In Short: </em></strong><em>We collect personal information that you provide to us.</em>
                <span data-custom-class="body_text">
                        We collect personal information that you voluntarily provide to us when you register on the Website,
                        express an interest in obtaining information about us or our products and Services, when you participate in activities on the
                        Website or otherwise when you contact us.
                </span>
                <span data-custom-class="body_text">The personal information that we collect depends on the context of your interactions with us and the Website
                        the choices you make and the products and features you use. 
                        The personal information we collect may include the following:
                </span>
                <span data-custom-class="body_text">
                        <strong>Personal Information Provided by You.</strong> We collect names, email addresses, contact or authentication data and other similar information.
                </span>
                <span data-custom-class="body_text">
                        <strong>Social Media Login Data. </strong>We may provide you with the option to register with us using your existing social media account details, 
                        like your Facebook, Twitter or other social media account. 
                        If you choose to register in this way, we will collect the information described in the section called 
                        "<a data-custom-class="link" href="#sociallogins">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>" below.
                </span>
                <span data-custom-class="body_text">All personal information that you provide to us must be true, complete and accurate, 
                        and you must notify us of any changes to such personal information.
                </span>
                <strong>Information collected from other sources</strong>
                <span data-custom-class="body_text">
                        <em><strong>In Short: </strong>
                        We may collect limited data from public databases, marketing partners, social media platforms,and other outside sources.</em>
                        In order to enhance our ability to provide relevant marketing, offers and services to you and update our records, 
                        we may obtain information about you from other sources, such as public databases, joint marketing partners, affiliate programs, data providers, 
                        social media platforms, as well as from other third parties. This information includes mailing addresses, job titles, email addresses, phone numbers, 
                        intent data (or user behavior data), Internet Protocol (IP) addresses, social media profiles, social media URLs and custom profiles, 
                        for purposes of targeted advertising and event promotion.If you interact with us on a social media platform using your social media account 
                        (e.g. Facebook or Twitter), we receive personal information about you such as your name, email address, and gender. 
                        Any personal information that we collect from your social media account depends on your social media account's privacy settings.
                </span>
        </div>
        <div id="infouse" style="line-height: 1.5;">
                <h1>2. HOW DO WE USE YOUR INFORMATION?</h1>
                <em><strong>In Short:  </strong>We process your information for purposes based on legitimate business interests, 
                        the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.</em>
                <span data-custom-class="body_text">We use personal information collected via our Website for a variety of business purposes described below. 
                        We process your personal information for these purposes in reliance on our legitimate business interests, 
                        in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. 
                        We indicate the specific processing grounds we rely on next to each purpose listed below.</span>
                <span data-custom-class="body_text">We use the information we collect or receive:</span>
                <ul>
                        <li style="line-height: 1.5;">
                                <strong>To facilitate account creation and logon process.</strong> If you choose to link your account with us to a 
                                third-party account (such as your Google or Facebook account), we use the information you allowed us to collect from those third parties to 
                                facilitate account creation and logon process for the performance of the contract. See the section below headed 
                                "<a data-custom-class="link" href="#sociallogins">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>" for further information.
                        </li>
                        <li style="line-height: 1.5;">
                                <strong>To post testimonials.</strong> We post testimonials on our Website that may contain personal information. 
                                Prior to posting a testimonial, we will obtain your consent to use your name and the content of the testimonial. 
                                If you wish to update, or delete your testimonial, please contact us at bowlozneatia@gmail.com and be sure to include your 
                                name, testimonial location, and contact information.
                        </li>
                        <li style="line-height: 1.5;">
                                <strong>Request feedback. </strong>We may use your information to request feedback and to contact you about your use of our Website
                        </li>
                        <li style="line-height: 1.5;">
                                <strong>To enable user-to-user communications.</strong> We may use your information in order to enable user-to-user communications with each user's consent.
                        </li>
                        <li style="line-height: 1.5;">
                                <strong>To manage user accounts. </strong>We may use your information for the purposes of managing our account and keeping it in working order.
                        </li>
                        <li style="line-height: 1.5;">
                                <strong>To send administrative information to you. </strong>We may use your personal information to send you product, 
                                service and new feature information and/or information about changes to our terms, conditions, and policies.
                        </li>
                        <li style="line-height: 1.5;">
                                <strong>To protect our Services. </strong>We may use your information as part of our efforts to keep our Website
                                safe and secure (for example, for fraud monitoring and prevention).
                        </li>
                        <li style="line-height: 1.5;">
                                <strong>To enforce our terms, conditions and policies for business purposes, 
                                        to comply with legal and regulatory requirements or in connection with our contract.
                                </strong>
                        </li>
                        <li style="line-height: 1.5;">
                                <strong>To respond to legal requests and prevent harm. </strong>If we receive a subpoena or other legal request, 
                                we may need to inspect the data we hold to determine how to respond.
                        </li>
                        <li style="line-height: 1.5;">
                                <strong>Fulfill and manage your orders. </strong>We may use your information to fulfill and manage your orders, payments, returns,
                                and exchanges made through the Website.
                        </li>
                        <li style="line-height: 1.5;">
                                <strong>Administer prize draws and competitions.</strong> We may use your information to administer prize draws and 
                                competitions when you elect to participate in our competitions.
                        </li>
                        <li style="line-height: 1.5;">
                                <strong>To deliver and facilitate delivery of services to the user.</strong> We may use your information to provide you with the requested service.
                        </li>
                        <li style="line-height: 1.5;">
                                <strong>To respond to user inquiries/offer support to users.</strong> We may use your information to respond to your inquiries and 
                                solve any potential issues you might have with the use of our Services.
                        </li>
                        <li style="line-height: 1.5;">
                                <strong>To send you marketing and promotional communications.</strong> We and/or our third-party marketing partners 
                                may use the personal information you send to us for our marketing purposes,
                                if this is in accordance with your marketing preferences. For example, when expressing an interest in obtaining information about us or our Website, 
                                subscribing to marketing or otherwise contacting us, we will collect personal information from you. 
                                You can opt-out of our marketing emails at any time (see the "<a
                                data-custom-class="link" href="#privacyrights">WHAT ARE YOUR PRIVACY RIGHTS?</a>" below).
                        </li>
                        <li style="line-height: 1.5;">
                                <strong>Deliver targeted advertising to you.</strong> We may use your information to develop and 
                                display personalized content and advertising (and work with third parties who do so) tailored to your interests 
                                and/or location and to measure its effectiveness.
                        </li>
                        <li style="line-height: 1.5;">
                                <strong>For other business purposes.</strong> We may use your information for other business purposes, 
                                such as data analysis, identifying usage trends, determining the effectiveness of our 
                                promotional campaigns and to evaluate and improve our Website, 
                                products, marketing and your experience. We may use and store this information in aggregated and anonymized form 
                                so that it is not associated with individual end users and does not include personal information. 
                                We will not use identifiable personal information without your consent.
                        </li>
                </ul>
        </div>
        <div id="infoshare" style="line-height: 1.5;">
                <h1>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h1>
                <span data-custom-class="body_text">
                        <em>
                                <strong>In Short:</strong> We only share information with your consent, 
                                to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.
                        </em>
                </span>
                <span data-custom-class="body_text">We may process or share your data that we hold based on the following legal basis:</span>
                <ul>
                        <li style="line-height: 1.5;">
                                <strong>Consent:</strong> We may process your data if you have given us specific consent to use your personal information for a specific purpose.
                        </li>
                        <li style="line-height: 1.5;">
                                <strong>Legitimate Interests:</strong> We may process your data when it is reasonably necessary to achieve our legitimate business interests.
                        </li>
                        <li style="line-height: 1.5;">
                                <strong>Performance of a Contract:</strong> Where we have entered into a contract with you, 
                                we may process your personal information to fulfill the terms of our contract.
                        </li>
                        <li style="line-height: 1.5;">
                                <strong>Legal Obligations:</strong> We may disclose your information where we are legally required to do 
                                so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, 
                                or legal process, such as in response to a court order or 
                                a subpoena (including in response to public authorities to meet national security or law enforcement requirements).
                        </li>
                        <li style="line-height: 1.5;">
                                <strong>Vital Interests:</strong> We may disclose your information where we believe it is necessary to investigate,
                                prevent, or take action regarding potential violations of our policies, suspected fraud, 
                                situations involving potential threats to the safety of any person and illegal activities, 
                                or as evidence in litigation in which we are involved.
                        </li>
                </ul>
                <span data-custom-class="body_text">More specifically, we may need to process your data or share your personal information in the following situations:</span>
                <ul>
                        <li style="line-height: 1.5;">
                                <strong>Business Transfers.</strong> We may share or transfer your information in connection with, 
                                or during negotiations of, any merger, sale of company assets, financing, or 
                                acquisition of all or a portion of our business to another company.
                        </li>
                        <li style="line-height: 1.5;">
                                <strong>Vendors, Consultants and Other Third-Party Service Providers.</strong> 
                                We may share your data with third-party vendors, service providers, contractors or agents 
                                who perform services for us or on our behalf and require access to such information to do that work. 
                                Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. 
                                We may allow selected third parties to use tracking technology on the Website, 
                                which will enable them to collect data on our behalf about how you interact with our Website over time. 
                                This information may be used to, among other things, analyze and track data, determine the popularity of certain 
                                content, pages or features, and better understand online activity. Unless described in this notice, we do not share, 
                                sell, rent or trade any of your information with third parties for their promotional purposes. We have contracts in place with our data processors, 
                                which are designed to help safeguard your personal information. This means that they cannot do anything with your personal information 
                                unless we have instructed them to do it. They will also not share your personal information with any organization 
                                apart from us. They also commit to protect the data they hold on our behalf and to retain it for the period we instruct.
                        </li>
                        <li style="line-height: 1.5;">
                                <strong>Affiliates.</strong> We may share your information with our affiliates, in which case we will require 
                                those affiliates to honor this privacy notice. Affiliates include our parent company and any subsidiaries, 
                                joint venture partners or other companies that we control or that are under common control with us.
                        </li>
                </ul>
        </div>
        <div id="whoshare" style="line-height: 1.5;">
                <h1>4. WHO WILL YOUR INFORMATION BE SHARED WITH?</h1>
                <span data-custom-class="body_text">
                        <em><strong>In Short:</strong> We only share information with the following categories ofthird parties.</em>
                </span>
                <span data-custom-class="body_text">We only share and disclose your information with the following categories of third parties. 
                        If we have processed your data based on your consent and you wish to revoke your consent, 
                        please contact us using the contact details provided in the section below titled "<a data-custom-class="link"
                        href="#contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>".
                </span>
                <ul>
                        <li style="line-height: 1.5;">
                                <span data-custom-class="body_text">Cloud Computing Services</span>
                        </li>
                        <li style="line-height: 1.5;">
                                <span data-custom-class="body_text">Ad Networks</span>
                        </li>
                        <li style="line-height: 1.5;">
                                <span data-custom-class="body_text">Data Analytics Services</span>
                        </li>
                        <li style="line-height: 1.5;">
                                <span data-custom-class="body_text">Data Storage Service Providers</span>
                        </li>
                        <li style="line-height: 1.5;">
                                <span data-custom-class="body_text">Payment Processors</span>
                        </li>
                        <li style="line-height: 1.5;">
                                <span data-custom-class="body_text">Product Engineering & Design Tools</span>
                        </li>
                        <li style="line-height: 1.5;">
                                <span data-custom-class="body_text">Social Networks</span>
                        </li>
                        <li style="line-height: 1.5;">
                                <span data-custom-class="body_text">Testing Tools</span>
                        </li>
                </ul>
        </div>
        <div id="cookies" style="line-height: 1.5;">
                <h1>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h1>
                <span data-custom-class="body_text">
                        <em><strong>In Short:</strong> We may use cookies and other tracking technologies to collect and store your information.</em>
                </span>
                <span data-custom-class="body_text">We may use cookies and similar tracking technologies (like web beacons and pixels) 
                        to access or store information. Specific information about how we use such technologies and how you 
                        can refuse certain cookies is set out in our Cookie Notice.
                </span>
        </div>
        <div id="sociallogins" style="line-height: 1.5;">
                <h1>6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h1>
                <span data-custom-class="body_text">
                        <em><strong>In Short: </strong>If you choose to register or log in to our services using a 
                                social media account, we may have access to certain information about you.</em>
                </span>
                <span data-custom-class="body_text">
                        Our Website offers you the ability to register and login using your third-party social media account details (like your Facebook or Twitter logins). 
                        Where you choose to do this, we will receive certain profile information about you from your social media provider. 
                        The profile information we receive may vary depending on the social media provider concerned, 
                        but will often include your name, email address, friends list, profile picture as well as other information you choose to make public on such social media platform.
                </span>
                <span data-custom-class="body_text">
                        We will use the information we receive only for the purposes that are described in this privacy notice 
                        or that are otherwise made clear to you on the relevant Website . Please note that we do not control, and are not responsible for, 
                        other uses of your personal information by your third-party social media provider. 
                        We recommend that you review their privacy notice to understand how they collect, 
                        use and share your personal information, and how you can set your privacy preferences on their sites and apps.
                </span>
        </div>
        <div id="inforetain" style="line-height: 1.5;">
                <h1>
                        7. HOW LONG DO WE KEEP YOUR INFORMATION?
                </h1>
                <span data-custom-class="body_text">
                        <em><strong>In Short:</strong> We keep your information for as long as necessary to fulfill the purposes 
                                outlined in this privacy notice unless otherwise required by law.</em>
                </span>
                <span data-custom-class="body_text">We will only keep your personal information for as long as it is necessary 
                        for the purposes set out in this privacy notice, unless a longer retention period is 
                        required or permitted by law (such as tax, accounting or other legal requirements). 
                        No purpose in this notice will require us keeping your personal information for longer than
                        the period of time in which users have an account with us.
                </span>
                <span data-custom-class="body_text">
                        When we have no ongoing legitimate business need to process your personal information, 
                        we will either delete or anonymize such information, or, if this is not possible (for example, 
                        because your personal information has been stored in backup archives), 
                        then we will securely store your personal information and isolate it from any 
                        further processing until deletion is possible.
                </span>
        </div>
        <div id="infosafe" style="line-height: 1.5;">
                <h1>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h1>
                <span data-custom-class="body_text">
                        <em><strong>In Short: </strong>We aim to protect your personal information through a system of organizational and technical security measures.</em>
                </span>
                <span data-custom-class="body_text">
                        We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. 
                        However, despite our safeguards and efforts to secure your information, 
                        no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, 
                        so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, 
                        and improperly collect, access, steal, or modify your information. 
                        Although we will do our best to protect your personal information, transmission of personal information to and from our Website
                        is at your own risk. You should only access the Website  within a secure environment.
                </span>
        </div>
        <div id="privacyrights" style="line-height: 1.5;">
                <h1>9. WHAT ARE YOUR PRIVACY RIGHTS?</h1>
                <span data-custom-class="body_text">
                        <em><strong>In Short:</strong> In some regions, such as the European Economic Area (EEA) and United Kingdom (UK), 
                                you have rights that allow you greater access to and control over your personal information. 
                                You may review, change, or terminate your account at any time.</em>
                </span>
                <span data-custom-class="body_text">
                        In some regions (like the EEA and UK), you have certain rights under applicable data protection laws. 
                        These may include the right (i) to request access and obtain a copy of your personal information, 
                        (ii) to request rectification or erasure; 
                        (iii) to restrict the processing of your personal information; 
                        and (iv) if applicable, to data portability. In certain circumstances, 
                        you may also have the right to object to the processing of your personal information. 
                        To make such a request, please use the <a data-custom-class="link" href="#contact">contact details</a> 
                        provided below. We will consider and act upon any request in accordance with applicable data protection laws.
                </span>
                <span data-custom-class="body_text">
                        If we are relying on your consent to process your personal information, 
                        you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal, 
                        nor will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
                </span>
                <span data-custom-class="body_text">
                        If you are a resident in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here:
                        <a data-custom-class="link" href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                rel="noopener noreferrer"
                                target="_blank">https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>
                </span>
                <span data-custom-class="body_text">
                        If you are a resident in Switzerland, the contact details for the data protection authorities are available here:
                        <a data-custom-class="link" href="https://www.edoeb.admin.ch/edoeb/en/home.html" rel="noopener noreferrer"
            target="_blank">https://www.edoeb.admin.ch/edoeb/en/home.html</a>
                </span>
                <h2>Account Information</h2>
                <span data-custom-class="body_text">If you would at any time like to review or change the information in your account or terminate your account, you can:</span>
                <ul>
                        <li style="line-height: 1.5;">
                                <span data-custom-class="body_text">
                                        Log in to your account settings and update your user account.
                                </span>
                        </li>
                </ul>
                <span data-custom-class="body_text">
                        Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. 
                        However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, 
                        enforce our Terms of Use and/or comply with applicable legal requirements.
                </span>
                <span data-custom-class="body_text">
                        <strong><u>Cookies and similar technologies:</u></strong> 
                        Most Web browsers are set to accept cookies by default. 
                        If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. 
                        If you choose to remove cookies or reject cookies, this could affect certain features or services of our 
                        Website. To opt-out of interest-based advertising by advertisers on our Website visit
                        <a data-custom-class="link" href="http://www.aboutads.info/choices/"
                                rel="noopener noreferrer"
                                target="_blank">http://www.aboutads.info/choices/</a>
                </span>
                <span data-custom-class="body_text">
                        <strong><u>Opting out of email marketing:</u></strong> 
                        You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe 
                        link in the emails that we send or by contacting us using the details provided below. 
                        You will then be removed from the marketing email list — however, we may still communicate with you, 
                        for example to send you service-related emails that are necessary for the administration and use of your account, 
                        to respond to service requests, or for other non-marketing purposes. To otherwise opt-out, you may:
                </span>
                <ul>
                        <li style="line-height: 1.5;">
                            <span data-custom-class="body_text">Access your account settings and update your preferences.</span>
                        </li>
                </ul>
        </div>
        <div id="DNT" style="line-height: 1.5;">
                <h1>10. CONTROLS FOR DO-NOT-TRACK FEATURES</h1>
                <span data-custom-class="body_text">
                        Most web browsers and some mobile operating systems and mobile applications include a 
                        Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference 
                        not to have data about your online browsing activities monitored and collected. 
                        At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. 
                        As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. 
                        If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
                </span>
        </div>
        <div id="caresidents" style="line-height: 1.5;">
                <h1>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h1>
                <span data-custom-class="body_text">
                        <em><strong>In Short:</strong>Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</em>
                </span>
                <span data-custom-class="body_text">California Civil Code Section 1798.83, also known as the "Shine The Light" law, 
                        permits our users who are California residents to request and obtain from us, once a year and free of charge, 
                        information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes 
                        and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. 
                        If you are a California resident and would like to make such a request, 
                        please submit your request in writing to us using the contact information provided below.
                </span>
                <span data-custom-class="body_text">
                        If you are under 18 years of age, reside in California, and have a registered account with the Website, 
                        you have the right to request removal of unwanted data that you publicly post on the Website
                        . To request removal of such data, please contact us using the contact information provided below, 
                        and include the email address associated with your account and a statement that you reside in California. 
                        We will make sure the data is not publicly displayed on the Website, 
                        but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.).
                </span>
        </div>
        <div id="policyupdates" style="line-height: 1.5;">
                <h1>12. DO WE MAKE UPDATES TO THIS NOTICE?</h1>
                <span data-custom-class="body_text">
                        <em><strong>In Short: </strong> Yes, we will update this notice as necessary to stay compliant with relevant laws.</em>
                </span>
                <span data-custom-class="body_text">
                        We may update this privacy notice from time to time. 
                        The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. 
                        If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or 
                        by directly sending you a notification. We encourage you to review this privacy notice frequently to be 
                        informed of how we are protecting your information.
                </span>
        </div>
        <div id="contact" style="line-height: 1.5;">
                <h1>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h1>
                <span data-custom-class="body_text">If you have questions or comments about this notice, you may
                        email us at bowlozneatia@gmail.com or by post to:
                </span>
                <span data-custom-class="body_text">AtiaSoft EOOD</span>
                <span data-custom-class="body_text">bulevard „Doctor G. M. Dimitrov“ 54 blvd, 1797 g.k. Iztok, Sofia</span>
                <span data-custom-class="body_text">Sofia, Sofia 1113</span>
                <span data-custom-class="body_text">Bulgaria</span>
        </div>
        <div id="request" style="line-height: 1.5;">
                <h1>14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h1>
                <span data-custom-class="body_text">
                        Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, 
                        change that information, or delete it in some circumstances. 
                        To request to review, update, or delete your personal information, please visit:
                        <a href="http://www.sportzone.live/profile" target="_blank" data-custom-class="link">http://www.sportzone.live/profile</a>.
                </span>
        </div>
        <div>
    </div>
    </div>
    </ThePublicHomeLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ThePublicHomeLayout from '@/components/main/ThePublicHomeLayout.vue';

export default defineComponent({
    components: {
        ThePublicHomeLayout
    },
})

</script>

<style>
    [data-custom-class='title'], [data-custom-class='title'] * {
        font-size: 26px !important;
    }

    [data-custom-class='subtitle'], [data-custom-class='subtitle'] * {
        font-size: 14px !important;
    }

    [data-custom-class='heading_1'], [data-custom-class='heading_1'] * {
        font-size: 19px !important;
        color: #000000 !important;
    }

    [data-custom-class='heading_2'], [data-custom-class='heading_2'] * {
        font-size: 17px !important;
        color: #000000 !important;
    }

    [data-custom-class='body_text'], [data-custom-class='body_text'] * {
        font-size: 14px !important;
    }

    [data-custom-class='link'], [data-custom-class='link'] * {
        font-size: 14px !important;
        word-break: break-word !important;
    }

    ul {
        list-style-type: square;
    }

    ul > li > ul {
        list-style-type: circle;
    }

    ul > li > ul > li > ul {
        list-style-type: square;
    }

    ol li {
        font-family: Arial;
    }

    .table-of-contents a {
        display: block;
    }
</style>