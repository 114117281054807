<template>
    <div class="player-info-container">
        <div class="user-player-wrapper">
            <section class="user-player-item player-main-info">
                <img :src="'/api/player/' + currentPlayer.id + '/picture'" class="player-main-info-box" />
                <div class="player-main-info-box">{{ currentPlayer.firstName }} {{ currentPlayer.middleName }} {{
                    currentPlayer.lastName }}</div>
            </section>
            <section class="user-player-item player-info">
                <h3>Info</h3>
                <div class="player-info-div info-wrapper">
                    <img :src="'/api/entity/' + currentPlayer.entity?.id + '/logo'" class="player-info-box" />
                    <div class="player-info-box">{{ currentPlayer.entity?.name }}</div>
                    <div class="player-info-box">Nationality: {{ currentPlayer.nationality }}</div>
                    <div class="player-info-box">Role: {{ currentPlayer.role }}</div>
                    <div class="player-info-box">Number: {{ currentPlayer.number }}</div>
                    <div class="player-info-box">Age: {{ currentPlayer.birthday ?
                    getAgeFromBirthday(currentPlayer.birthday)
                    : ''
                        }}y</div>
                    <div class="player-info-box">Height: {{ currentPlayer.height }}cm</div>
                    <div class="player-info-box">Weight: {{ currentPlayer.weight }}kg</div>
                </div>
            </section>
            <section class="user-player-item player-positive-stats">
                <h3>Positive Stats</h3>
                <div class="info-wrapper">
                    <div class="player-stats-box">
                        <div>Aces</div>
                        <div>{{ getPlayerStatsSummed[VLY_ACE] }}</div>
                    </div>
                    <div class="player-stats-box">
                        <div>Attacks</div>
                        <div>{{ getPlayerStatsSummed[VLY_ATTACK] }}</div>
                    </div>
                    <div class="player-stats-box">
                        <div>Blocks</div>
                        <div>{{ getPlayerStatsSummed[VLY_BLOCK] }}</div>
                    </div>
                    <div class="player-stats-box">
                        <div>Other</div>
                        <div>{{ getPlayerStatsSummed[VLY_POINT] }}</div>
                    </div>
                </div>
            </section>
            <section class="user-player-item player-negative-stats">
                <h3>Negative Stats</h3>
                <div class="info-wrapper">
                    <div class="player-stats-box">
                        <div>Service Errors</div>
                        <div class="negative-stats">{{ getPlayerStatsSummed[VLY_SERVICE_ERROR] }}</div>
                    </div>
                    <div class="player-stats-box">
                        <div>Fouls</div>
                        <div class="negative-stats">{{ getPlayerStatsSummed[VLY_FOUL] }}</div>
                    </div>
                    <div class="player-stats-box">
                        <div>Other</div>
                        <div class="negative-stats">{{ getPlayerStatsSummed[VLY_ERROR] }}</div>
                    </div>
                </div>
            </section>
            <section class="user-player-item player-matches">
                <h3>Matches</h3>
                <div class="player-matches-div info-wrapper">
                    <div v-for="(playerMatch, i) of getPlayerMatches" :key="playerMatch.id">{{ i + 1 }}. {{
                    playerMatch.title }} {{ formatDate(playerMatch.startTime, 'Do MMMM, HH:mm') }}</div>
                </div>
            </section>
        </div>
    </div>
</template>
<script lang='ts'>
import { defineComponent, PropType, Ref, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import GenericDictionary from '../util/GenericDictionary'
import { getAgeFromBirthday, formatDate } from '@/composables/common/Util'
import { VolleyballPointType, VLY_ACE, VLY_ATTACK, VLY_BLOCK, VLY_POINT, VLY_SERVICE_ERROR, VLY_FOUL, VLY_ERROR } from '@/composables/volleyball/VolleyballEventTypes'
import { Player } from '@/models/main/Player'
import { User } from '@/models/main/User'
import { key } from '@/store'
const UserPlayerDashboard = defineComponent({
    props: {
        user: {
            type: Object as PropType<User>,
            required: true
        },
        player: {
            type: Object as PropType<Player>,
            required: true
        }
    },
    setup(props) {
        const router = useRouter()
        const store = useStore(key)
        const localUser: Ref<User> = ref(props.user)
        const currentPlayer: Ref<Player> = ref(props.player)
        type PointType = VolleyballPointType
        const getUserStats = computed(() => {
            return store.getters['userStatStore/getUserStats']
        })
        const getPlayerStatsSummed = computed(() => {
            const userStats = getUserStats.value
            const pointTypesSummed: GenericDictionary<number> = {}
            const player = userStats[1]
            if (player.id === currentPlayer.value.id) {
                const entity = player.entity
                const entityContainers = entity.entityContainers
                for (const ecIndex in entityContainers) {
                    const entityContainer = entityContainers[ecIndex]
                    const container = entityContainer.container
                    const volleyballPlayerStats = container.volleyballPlayerStats
                    if (volleyballPlayerStats) {
                        for (const vpsIndex in volleyballPlayerStats) {
                            const pointType: PointType = volleyballPlayerStats[vpsIndex].type as unknown as PointType
                            const statCount = volleyballPlayerStats[vpsIndex].statCount
                            if (!pointTypesSummed[pointType]) {
                                pointTypesSummed[pointType] = statCount
                            } else {
                                pointTypesSummed[pointType] += statCount
                            }
                        }
                    }
                }
            }
            return pointTypesSummed
        })
        const getPlayerMatches = computed(() => {
            const userStats = getUserStats.value
            const playerMatches: {
                id: number,
                title: string,
                startTime: Date
            }[] = []
            const player = userStats[1]
            if (player.id === currentPlayer.value.id) {
                const entity = player.entity
                const entityContainers = entity.entityContainers
                for (const ecIndex in entityContainers) {
                    const entityContainer = entityContainers[ecIndex]
                    const container = entityContainer.container
                    const id = container.id
                    const title = container.title
                    const startTime = container.startTime
                    const playerMatchObj = {
                        id,
                        title,
                        startTime
                    }
                    playerMatches.push(playerMatchObj)
                }
            }
            return playerMatches
        })
        return {
            router,
            store,
            localUser,
            currentPlayer,
            getUserStats,
            getPlayerStatsSummed,
            getPlayerMatches,
            VLY_ACE,
            VLY_ATTACK,
            VLY_BLOCK,
            VLY_POINT,
            VLY_SERVICE_ERROR,
            VLY_FOUL,
            VLY_ERROR,
            getAgeFromBirthday,
            formatDate
        }
    }
})
export default UserPlayerDashboard
</script>
<style>
.player-info-container {
    container: player-info-container / inline-size;
    width: 100%;
}

.user-player-wrapper {
    display: grid;
    grid-template-areas:
        "player-main-info player-positive-stats player-negative-stats"
        "player-main-info player-positive-stats player-negative-stats"
        "player-main-info player-positive-stats player-negative-stats"
        "player-info player-matches player-matches"
        "player-info player-matches player-matches"
        "player-info player-matches player-matches"
        "player-info player-matches player-matches"
        "player-info player-matches player-matches";
    grid-template-rows: repeat(8, 1fr);
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
    width: 100%;
    height: 100%;
}

.player-main-info {
    grid-area: player-main-info;
}

.player-info {
    grid-area: player-info;
}

.player-positive-stats {
    grid-area: player-positive-stats;
}

.player-negative-stats {
    grid-area: player-negative-stats;
}

.player-matches {
    grid-area: player-matches;
}

.user-player-item {
    background-color: var(--bright-font-clr);
    border-radius: var(--default-border-rad);
    padding: 1em;
    gap: 0.5em;
}

.info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
}

.player-main-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.player-main-info-box {
    margin-bottom: 0.5em;
}

.player-info-box {
    margin-bottom: 0.5em;
}

.player-stats-box {
    min-width: 30%;
    background-color: var(--background-clr);
    border-radius: var(--default-border-rad);
    text-align: center;
    margin-bottom: 0.5em;
    padding: 0.5em;
}

.negative-stats {
    color: var(--btn-error-background-color);
}

.user-player-wrapper img {
    max-height: calc(var(--badge-item-height) * 2.5);
}

.user-player-wrapper h3 {
    margin: 0;
}

@container (width < 61em) {
    .user-player-wrapper {
        grid-template-areas:
            "player-main-info"
            "player-info"
            "player-positive-stats"
            "player-negative-stats"
            "player-matches";
        grid-template-rows: repeat(5, 1fr);
        grid-template-columns: repeat(1, 1fr);
    }
}

@container (width < 30em) {
    .player-matches .info-wrapper {
        display: flex;
        align-items: start;
    }
}
</style>