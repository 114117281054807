import { PaginatedItemsStore, PagedResponse, PaginatedItemsStoreGetters, PaginatedItemsStoreMutations } from '@/composables/common/Pagination';
import Livestream, { LivestreamStatus, StreamPrivacy } from '@/models/main/Livestream';
import { Sport } from '@/models/main/CommonTypes';
import { ActionContext, Module } from 'vuex';
import { IRootState } from '@/store';
import authModule from '@/main';
import StationJob from '@/models/main/StationJob';
import { UserLivestreamClip } from '@/models/main/UserLivestreamClip';
export interface LivestreamPublicStore extends PaginatedItemsStore<Livestream> {
    paginatedItems: PagedResponse<Livestream>,
    status?: LivestreamStatus,
    sport?: Sport,
    privacy?: StreamPrivacy,
};
const state: LivestreamPublicStore = {
    paginatedItems: {
        items: {},
    },
};
const getters = {
    getLivestream(state: LivestreamPublicStore, livestreamId: number) {
        return state.paginatedItems.items[livestreamId];
    },
    getLivestreams: (state: LivestreamPublicStore) => ({ sort = 'DESC', statuses, sport, privacy }: { sort: 'ASC' | 'DESC', statuses: LivestreamStatus[] | undefined, sport: Sport | undefined, privacy: StreamPrivacy | undefined }) => {
        const filteredLivestreams: Array<Livestream> = [];
        for (const livestreamId in state.paginatedItems.items) {
            if (Object.prototype.hasOwnProperty.call(state.paginatedItems.items, livestreamId)) {
                const livestream = state.paginatedItems.items[livestreamId];
                if (statuses === undefined || statuses.includes(livestream.status ?? '')) {
                    if (sport === undefined || livestream.sport === sport) {
                        if (privacy === undefined || livestream.privacy === privacy) {
                            filteredLivestreams.push(livestream);
                        }
                    }
                }
            }
        }
        const sortedLivestreams = filteredLivestreams.sort((a, b) => {
            if (a.startTime && b.startTime) {
                const aDate = new Date(a.startTime);
                const bDate = new Date(b.startTime);
                let aDateValue;
                let bDateValue;
                return isFinite(aDateValue = aDate.valueOf()) && isFinite(bDateValue = bDate.valueOf())
                    ? (sort === 'ASC'
                        ? aDateValue - bDateValue
                        : bDateValue - aDateValue)
                    : 0;
            }
            else {
                return 0;
            }
        });
        return sortedLivestreams;
    },
    getStatus(state: LivestreamPublicStore) {
        return state.status;
    },
    getSport(state: LivestreamPublicStore) {
        return state.sport;
    },
    getPrivacy(state: LivestreamPublicStore) {
        return state.privacy;
    },
    ...PaginatedItemsStoreGetters(),
};
const mutations = {
    addLivestream(state: LivestreamPublicStore, livestream: Livestream) {
        if (livestream.id !== undefined) {
            state.paginatedItems.items[livestream.id] = livestream;
        }
    },
    setStatus(state: LivestreamPublicStore, status: LivestreamStatus) {
        state.status = status;
    },
    setSport(state: LivestreamPublicStore, sport: Sport) {
        state.sport = sport;
    },
    setPrivacy(state: LivestreamPublicStore, privacy: StreamPrivacy) {
        state.privacy = privacy;
    },
    ...PaginatedItemsStoreMutations(),
};
const actions = {
    getPublicLivestreams({ commit, getters }: ActionContext<LivestreamPublicStore, IRootState>, { statuses, sport}: { statuses: LivestreamStatus[], sport?: Sport}) {
        const currentStatus = getters['getStatus'];
        const currentSport = getters['getSport'];
        let filtersChanged = false;
        const status = statuses.join(',')
        if (currentStatus !== status) {
            commit('setStatus', status);
            filtersChanged = true;
        }
        if (currentSport !== sport) {
            commit('setSport', sport);
            filtersChanged = true;
        }
        let pageToRequest = getters['getNextPage'];
        const pageSize = getters['getPageSize'];
        if (filtersChanged) {
            pageToRequest = 0;
        }
        const statusFilterQuery = status
            ? `&status=${status}`
            : '';
        const sportFilterQuery = sport
            ? `&sport=${sport}`
            : '';
        if (!filtersChanged && getters['getNextPage'] === 0 && getters['getCurrentPage'] > 0) {
            return;
        }
        return authModule
            .get(`/api/livestream/public?page=${pageToRequest}&pageSize=${pageSize}${statusFilterQuery}${sportFilterQuery}`)
            .then((response) => {
                const pagedResponse: PagedResponse<Livestream> = response.data as unknown as PagedResponse<Livestream>;
                console.log(pagedResponse);
                commit('setCurrentPage', pagedResponse.currentPage);
                commit('setPageSize', pagedResponse.pageSize);
                commit('setNextPage', pagedResponse.nextPage);
                commit('setPreviousPage', pagedResponse.previousPage);
                for (const livestreamIndex in pagedResponse.items) {
                    commit('addLivestream', pagedResponse.items[livestreamIndex]);
                }

                return pagedResponse;
            });
    }
};
const livestreamStore: Module<LivestreamPublicStore, IRootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
export default livestreamStore;