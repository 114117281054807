import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserPreferences = _resolveComponent("UserPreferences", true)!
  const _component_TheUserDashboard = _resolveComponent("TheUserDashboard")!

  return (_openBlock(), _createBlock(_component_TheUserDashboard, { onUserLoad: _ctx.onUserLoaded }, {
    default: _withCtx(() => [
      (_ctx.currentUser)
        ? (_openBlock(), _createBlock(_component_UserPreferences, {
            key: 0,
            user: _ctx.currentUser
          }, null, 8, ["user"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onUserLoad"]))
}