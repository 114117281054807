<template>
    <TheUserDashboard :onUserLoad="onUserLoaded" title="Organizations">
        <div class="organizations-wrapper">
            <div class="sport-wrapper" v-for="(organizationsGrouped, sport) in getOrganizationsGroupedBySport()">
                <div class="sport-icon-wrapper">
                    <VolleyballIcon class="organization-sport-icon" v-if="sport == 'Volleyball'"/>
                    <TennisIcon class="organization-sport-icon"  v-else-if="sport == 'Tennis'"/>
                    <TableTennisIcon class="organization-sport-icon"  v-else-if="sport == 'TableTennis'"/>
                    <BowlingIcon class="organization-sport-icon"  v-else-if="sport == 'Bowling'"/>
                    <BadmintonIcon class="organization-sport-icon"  v-else-if="sport == 'Badminton'"/>
                </div>
                <div class="sport-organizations">                           
                    <OrganizationPublicBadgeItem class="organization-wrapper" v-for="organization in organizationsGrouped" :organization="organization"/>
                </div>
            </div>
        </div>
    </TheUserDashboard>
  </template>
  <script lang="ts">
  import { Ref, defineComponent, ref} from "vue";
import { createNamespacedHelpers, useStore } from "vuex";
import { key } from "@/store";
import TheUserDashboard from "@/components/main/TheUserDashboard.vue";
import BowlingIcon from '@/components/bowling/BowlingIcon.vue'
import VolleyballIcon from '@/components/volleyball/VolleyballIcon.vue'
import TableTennisIcon from '@/components/tableTennis/TableTennisIcon.vue'
import BadmintonIcon from "@/components/badminton/BadmintonIcon.vue"
import TennisIcon from '@/components/tennis/TennisIcon.vue'
import StepBasedModal from '@/components/main/StepBasedModal.vue';
import SportzoneSelect from '@/components/util/SportzoneSelect.vue';
import { useRouter } from "vue-router";
import { User } from "@/models/main/User";
import { SPORT_TYPES, Sport } from "@/models/main/CommonTypes";
import { ORGANIZATION_PRIVACIES, Organization } from "@/models/main/Organization";
import moment from "moment";
import { getUserLastSelectedSport } from "@/composables/common/Util";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import OrganizationPublicBadgeItem from "@/components/main/OrganizationPublicBadgeItem.vue";

const publicOrganizationStoreHelpers = createNamespacedHelpers('organizationPublicStore');

export default defineComponent({
    components: {
        TheUserDashboard,
        VolleyballIcon,
        TableTennisIcon,
        TennisIcon,
        BadmintonIcon,
        BowlingIcon,
        StepBasedModal,
        VueDatePicker,
        SportzoneSelect,
        OrganizationPublicBadgeItem,
    },
    computed: {
        ...publicOrganizationStoreHelpers.mapGetters(
            ['getOrganizationsGroupedBySport'],
        ),
    },
    setup() {
        const store = useStore(key);

        const organizationPopup: Ref<any> = ref(null);
        const initialSportValue = getUserLastSelectedSport() ?? SPORT_TYPES[0]
        const defaultOrganization: Organization = {
            id: 0,
            owner: undefined,
            ownerId: undefined,
            parentOrganization: undefined,
            name: '',
            dateCreated: moment().toDate(),
            establishedOn: moment().toDate(),
            allowApplication: true,
            autoAcceptApplication: true,
            isSystemGenerated: false,
            sport: initialSportValue,
            subOrganizations: {},
            language: "English",
            acceptSubOrganizationMembers: true,
            subOrganizationsCanAccessMembers: true,
            privacy: ORGANIZATION_PRIVACIES[0]
        };
        const newOrganization: Ref<Organization> = ref(defaultOrganization);
        const validName: Ref<boolean> = ref(true);
        const validEstablishedDate: Ref<boolean> = ref(true)
        const mainDetailsValidator = () => {
            validName.value = newOrganization.value.name !== undefined && newOrganization.value.name?.length > 0
            validEstablishedDate.value = newOrganization.value.establishedOn !== undefined
            return validName.value && validEstablishedDate.value
        }
        const stepValidators: Array<Function> = [mainDetailsValidator, ()=>{ return true}]
        const organizationProcessStep: Ref<number> = ref(1);
        const organizationProcessTotalSteps = ref(1);
        

        const onUserLoaded = (user: User) => {
        }

        const loadOrganizations = async () => {
            //showLoadingIcon.value = true;
            await store.dispatch('organizationPublicStore/fetchPublicOrganizations', {
                sport: null,
            });

            const organizationsGroupedBySport = store.getters['organizationPublicStore/getOrganizationsGroupedBySport']();
            //showLoadingIcon.value = false;
        };

        const createOrganizationPrompt = (sport: Sport) => {
            newOrganization.value.sport = sport
            organizationPopup.value.openModal()
        }
        loadOrganizations();


        return {
            createOrganizationPrompt,
            onUserLoaded,
            SPORT_TYPES,
            newOrganization,
            stepValidators,
            validName,
            organizationProcessStep,
            organizationProcessTotalSteps,
            organizationPopup
            
        }
    }
})
</script>
<style>

</style>