<template>
    <svg style="width:100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 397 437.6">
        <path class="bwl-icon" d="M430.94,217.31c-.05-.09-13.86-21.39-13.86-21.39-6.39-9.86-16-39.75-13.91-63.25,0,0,8.83-47.79,8.85-48,1.52-21.42-13.77-41.15-37.26-43.87,0,0-8.79,0-11.88.29C341,43.5,325.3,63.19,326.83,84.65c0,.23,8.85,48,8.85,48,2.05,23.75-7.55,53.43-13.92,63.25l-13.7,21.13c-.06.08-4.69,8.61-6.63,12.94a145,145,0,1,0,1.83,205c1.95-2,3.83-4,5.66-6.1,3.74,11.92,7.39,22.56,10.37,30.92a28,28,0,0,0,26.36,18.56h47.54a28,28,0,0,0,26.36-18.56c9.9-27.71,27.06-80.37,30.82-121.8,4.67-51.35-1.5-89.7-19.43-120.71ZM365.46,52.82l7.77,0C389.38,54.5,401,68,400.07,83.51l-8.74,47.18a80.23,80.23,0,0,0,0,18.48,129.27,129.27,0,0,0,2.44,16.37H345.05a130.91,130.91,0,0,0,2.49-16.95c.14-1.74,1-10.34,0-17.9l-8.74-47.18a29.19,29.19,0,0,1,26.68-30.69ZM342.1,177.54h54.64a139.08,139.08,0,0,0,4.68,13.75h-64A136.53,136.53,0,0,0,342.1,177.54ZM200,466.38c-61.65,1.29-132-49.38-133-133,1.65-75.74,59.31-131.41,133-133a133,133,0,0,1,0,265.93ZM438.42,336.93C434.77,377,418,428.61,408.25,455.78a16,16,0,0,1-15.06,10.6H345.65a16,16,0,0,1-15.06-10.6c-3.58-10-8.13-23.4-12.64-38.28a144.52,144.52,0,0,0-7.57-177.89,129.49,129.49,0,0,1,7.83-16.17l13.07-20.15h76.29l13.06,20.15c16.56,28.74,22.22,64.81,17.79,113.49Z" transform="translate(-54.88 -40.78)" 
        />
        <path class="bwl-icon" d="M202.62,282.14A24.24,24.24,0,1,0,212.51,315C221.17,297.5,208.67,284.83,202.62,282.14Zm-.68,27.15a12.24,12.24,0,1,1-5-16.57C205.33,297.5,203.47,306.43,201.94,309.29Z" transform="translate(-54.88 -40.78)" />
        <path class="bwl-icon" d="M149.28,313.47a24.25,24.25,0,1,0,9.9,32.83C167.83,328.83,155.33,316.17,149.28,313.47Zm-.67,27.16a12.24,12.24,0,1,1-5-16.58C152,328.83,150.14,337.76,148.61,340.63Z" transform="translate(-54.88 -40.78)" />
        <path class="bwl-icon" d="M123,294.53a24.24,24.24,0,0,0,32.82-9.9c8.66-17.46-3.84-30.13-9.89-32.82A24.24,24.24,0,1,0,123,294.53Zm.68-27.15a12.24,12.24,0,0,1,16.58-5c8.39,4.79,6.53,13.72,5,16.58a12.24,12.24,0,0,1-21.57-11.58Z" transform="translate(-54.88 -40.78)" />
    </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({

})
</script>

<style>
.bwl-icon {
    fill: var(--_icon-fill, #283380);
}

.active-filter .bwl-icon {
  fill: var(--archive-bck-color);
}
</style>