<template>
    <div class="organization-invitation-item badge-item">
        <div class="organization-invitation-name badge-item-col">
            <div class="badge-info-heading">Name</div>
            <div class="badge-info-value" v-if="invitation.inviteeFirstName">
                {{ invitation.inviteeFirstName }} {{ invitation.inviteeLastName }}
            </div>
            <div class="badge-info-value" v-else>
                {{ invitation.user?.firstName }} {{ invitation.user?.middleName ? invitation.user.middleName + ' ' : ''
                }}{{ invitation.user?.lastName }}
            </div>
        </div>
        <div class="organization-invitation-email badge-item-col">
            <div class="badge-info-heading">Email</div>
            <div class="badge-info-value" v-if="invitation.email">
                {{ invitation.email }}
            </div>
            <div class="badge-info-value" v-else>
                {{ invitation.user?.email }}
            </div>
        </div>
        <div class="organization-invitation-date badge-item-col">
            <div class="badge-info-heading">Invited on</div>
            <div class="badge-info-value">{{ formatStringDate(invitation.dateCreated) }}</div>
        </div>
        <div class="organization-invitation-date badge-item-col">
            <div class="badge-info-heading">Valid until</div>
            <div class="badge-info-value">{{ formatStringDate(invitation.validUntil) }}</div>
        </div>
        <div class="organization-invitation-status badge-item-col badge-status"
            v-if="invitationIsStillValid(invitation)" :class="invitation.status">{{ invitation.status }}</div>
        <div class="organization-invitation-status badge-item-col badge-status " :class="revokedStatus" v-else>Expired
        </div>
        <div class="organization-invitation-delete-control badge-item-col">
            <button class="btn delete-btn" v-if="invitation.status === 'Pending'"
                @click="$emit('revoke')">Revoke</button>
            <button class="btn delete-btn" v-else @click="$emit('delete')">Delete</button>
        </div>
    </div>
</template>
<script lang="ts">
import { INVITE_STATUSES, OrganizationInvitation } from '@/models/main/OrganizationInvitation';
import { PropType, defineComponent } from 'vue';
import { formatStringDate } from '@/composables/common/Util';

const OrganizationInvitationItem = defineComponent({
    props: {
        invitation: {
            type: Object as PropType<OrganizationInvitation>,
            required: true
        }
    },
    emits: ['revoke', 'delete'],
    setup(props) {
        const invitationIsStillValid = (invitation: OrganizationInvitation): boolean => {
            const validUntilDate: Date = new Date(invitation.validUntil)
            const now: Date = new Date();
            return validUntilDate >= now
        }
        const revokedStatus = INVITE_STATUSES[2];
        return {
            revokedStatus,
            invitationIsStillValid,
            formatStringDate
        }
    }
})

export default OrganizationInvitationItem;
</script>
<style>
.users-filters {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 1em;
}

.badge-item {
    min-height: var(--badge-item-height);
    height: var(--badge-item-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: var(--default-border-rad);
    background-color: var(--bright-font-clr);
    margin-bottom: 0.5em;
    padding: 0.5em 1em;
    gap: 0.5em;
}

.badge-item:hover {
    background-color: var(--select-background-color);
}

.badge-item.small-item {
    font-size: 0.75em;
}

.badge-item-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-width: 5em;
    height: 100%;
}

.badge-item-col+.badge-item-col {
    padding-left: 1em;
}

.badge-info-heading,
.badge-info-value,
.badge-status {
    font-size: 1.125em;
    overflow: hidden;
}

.badge-info-value,
.badge-status {
    font-weight: bold;
}

.badge-info-value {
    height: 66.666%;
    display: flex;
    align-items: flex-start;
}


.badge-item-col.badge-status {
    position: relative;
    padding-left: 1.5em;
    text-transform: uppercase;
    min-width: 8em;
}

.badge-status.Revoked,
.badge-status.Rejected,
.badge-status.Expired {
    color: var(--btn-error-background-color);
}

.badge-status.Accepted,
.badge-status.Completed,
.badge-status.Finished {
    color: var(--green-clr);
}


.badge-status::after {
    --status-icon-height: 1.25em;
    content: '';
    position: absolute;
    top: calc((100% - var(--status-icon-height)) / 2);
    left: 0;
    display: block;
    height: var(--status-icon-height);
    width: var(--status-icon-height);
    background-size: contain;
    background-repeat: no-repeat;
}

.badge-status.Pending::after,
.badge-status.Starting::after,
.badge-status.NotStarted::after {
    background-image: url('/pending-icon.svg');
}

.badge-status.Revoked::after,
.badge-status.Rejected::after,
.badge-status.Expired::after {
    background-image: url(/red-cross.svg);
}

.badge-status.Accepted::after,
.badge-status.Completed::after,
.badge-status.Finished::after {
    background-image: url(/green-tick.svg);
}

.badge-status.Streaming::after,
.badge-status.Open::after {
    background: var(--light-blue-triangle-background);
}

.organization-invitation-email.badge-item-col,
.organization-invitation-name.badge-item-col {
    width: 18em;
}

.organization-invitation-date.badge-item-col {
    width: 16em;
}
</style>