import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheOrganizerDashboard = _resolveComponent("TheOrganizerDashboard")!

  return (_openBlock(), _createBlock(_component_TheOrganizerDashboard, null, {
    default: _withCtx(() => [
      _createElementVNode("button", {
        class: "btn delete-btn",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.deleteUserData && _ctx.deleteUserData(...args)))
      }, "Delete my data")
    ]),
    _: 1
  }))
}