import DataModel from "@/models/main/DataModel";
import ConnectionManager from "@/components/util/ConnectionManager";
import GenericDictionary from "./GenericDictionary";
import DataModelMapper from "./DataModelMapper";

export default class DataManager {
    private _userId = ''
    private _subscriptions: GenericDictionary<Array<(updatedModel: DataModel) => void>> = {}
    private _dataModels: GenericDictionary<Promise<DataModel | null>> = {}
    private _modelUrl = ''
    private _connectionManager: ConnectionManager
    private static _dataManager: DataManager
    public static APIRequestURL = {
        SUBSCRIBE_TO_MODEL: "live/subscribeusertomodel",
        UNSUBSCRIBE_FROM_MODEL: "live/unsubscribeuserfrommodel",
        GET_MODEL: "getmodel",
    }

    get userId() {
        return this._userId;
    }

    set userId(userId) {
        this._userId = userId;
    }

    get subscriptions() {
        return this._subscriptions;
    }

    set subscriptions(subscriptions) {
        this._subscriptions = subscriptions;
    }

    get dataModels() {
        return this._dataModels;
    }

    set dataModels(dataModels) {
        this._dataModels = dataModels;
    }

    get modelUrl() {
        return this._modelUrl;
    }

    set modelUrl(modelUrl) {
        this._modelUrl = modelUrl;
    }

    private static get _subscriptionRefreshTimeInMinutes() {
        return 14;
    }

    /**
     *
     * @returns {DataManager}
     */
    public static instance(onInitializedCallback: ((value: void) => void) | undefined) {
        if (typeof DataManager._dataManager === "undefined") {
            DataManager._dataManager = new DataManager(onInitializedCallback);
        }

        return DataManager._dataManager;
    }

    protected constructor(onInitializedCallback: ((value: void) => void) | undefined) {
        this.subscriptions = {};
        this.dataModels = {};
        this.modelUrl = 'https://api.bowlzone.atia.com/bowling/squad/getmodel';
        this.userId = DataManager._generateUserId();
        this._connectionManager = new ConnectionManager(this.userId);
        this._connectionManager.webSocketClient.onNewDataEventHandler = DataManager._onDataReceive;
        this._connectionManager.webSocketClient.onClose = DataManager._onClose;
        if (typeof onInitializedCallback === "function") {
            this._connectionManager.webSocketClient.onConnect = onInitializedCallback;
        }
        this._connectionManager.webSocketClient._connect()
        this._updateModel = this._updateModel.bind(this);
        this._applyModelPatch = this._applyModelPatch.bind(this);
    }

    public getDataModel(modelID: string): Promise<DataModel | null> {
        if (typeof this.dataModels[modelID] !== "undefined" && this.dataModels[modelID] !== null) {
            return this.dataModels[modelID];
        } else {
            const dataManager = DataManager.instance(undefined);
            const fetchPromise = this._connectionManager.httpClient._httpRequest(this.modelUrl + "", { 'ModelID': modelID }, "POST")
            if(fetchPromise !== undefined) {
                dataManager.dataModels[modelID] = fetchPromise.then(function ({ data }) {
                    const dataModel = DataModelMapper.createFromObject(data);
                    if (dataModel) {
                        return dataModel;
                    } else {
                        console.error("Invalid Data Model Object Received!");
                        return Promise.reject(null);
                    }
                });

                return dataManager.dataModels[modelID];
            } else {
                console.error("Could not fetch DataModel!")
                return Promise.reject(null)
            }
        }
    }

    public subscribeToDataModel(modelID: string, onEventCallback: any, isRefresh: boolean): Promise<DataModel | null> {
        if (!isRefresh && typeof onEventCallback !== "function") {
            console.error("On Event Callback is not a function!");
            return Promise.reject(null)
        }

        if (typeof this._subscriptions[modelID] === "undefined") {
            this._subscriptions[modelID] = [];//on event functions array
        }

        isRefresh = typeof isRefresh === "undefined" ? false : isRefresh;
        if (!isRefresh) {
            this._subscriptions[modelID].push(onEventCallback);
        }

        if(isRefresh && this._subscriptions[modelID].length < 1) {
            console.error("Called subscribe as Refresh but there are no modelID subscriptions!")
        }
        const dataManager = DataManager.instance(undefined);

        if (isRefresh || this._subscriptions[modelID].length === 1) { // just added first subscription
            const ajaxPromise = this._subscribeToModelChanges(modelID, undefined);
            if (ajaxPromise !== false) {
                dataManager.dataModels[modelID] = ajaxPromise.then(({ data }: any) => {
                    const dataModel = DataModelMapper.createFromObject(data);
                    if (dataModel) {
                        const refreshDataModelInSeconds = DataManager._subscriptionRefreshTimeInMinutes * 60;
                        const secondsEarlierThanRefreshTime = Math.floor(refreshDataModelInSeconds * 0.10);
                        const refreshTimeoutInterval = (refreshDataModelInSeconds - secondsEarlierThanRefreshTime) * 1000;
                        setTimeout(() => {
                            if(typeof this._subscriptions[modelID] !== "undefined" && this._subscriptions[modelID]) {
                                const subscriptions = this._subscriptions[modelID]
                                if(subscriptions.length > 0) {
                                    this.subscribeToDataModel(modelID, null, true);
                                }
                            }
                        }, refreshTimeoutInterval);
                        return dataModel;
                    } else {
                        console.error("Invalid Data Model Object Received!");
                        return null
                    }
                });
                return dataManager.dataModels[modelID];
            } else {
                console.error("Something is not right!")
                return Promise.reject(null)
            }
        }
        
        if(!Object.prototype.hasOwnProperty.call(dataManager.dataModels, modelID)) {
            console.error("There should be already dataModel but there is not!")
            return Promise.reject(null);
        }

        return dataManager.dataModels[modelID]; // executed when there is second sub for same modelID     
    }

    public unsubscribeFromDataModel(modelID: string, onEventCallbackToUnsubscribe: any) {
        if (typeof onEventCallbackToUnsubscribe !== "function") {
            console.error("On Event Callback To Unsubscribe is not a function!");
            return Promise.reject("On Event Callback To Unsubscribe is not a function!");
        }

        if (typeof this._subscriptions[modelID] === "undefined" || this._subscriptions[modelID].length <= 0) {// no event callbacks registered for the current model
            return Promise.reject("No event callbacks registered for the current model!");
        }

        for (let i = 0; i < this._subscriptions[modelID].length; i++) {
            if (this._subscriptions[modelID][i] === onEventCallbackToUnsubscribe) {
                this._subscriptions[modelID].splice(i, 1);
                i--;
                if (this._subscriptions[modelID].length === 0) {
                    setTimeout(() => {
                        if (this._subscriptions[modelID].length === 0) { // no more need to listen for changes concerning the model
                            return this._unsubscribeFromModelChanges(modelID);
                        }
                    }, 5000); // after 5 seconds check if still no subscriptions then unsubscribe
                }
            }
        }

        return Promise.resolve("Successfully Unsubscribed!")
    }

    /**
     *
     * @param {DataModel} modelPatch
     */
    private _updateModel(modelPatch: any) {
        const currentModelPromise = this.dataModels[modelPatch._ID];
        if (currentModelPromise) {
            const updateModelPromise = currentModelPromise.then((currentModel: any) => {
                const updatedModels = {};
                try {
                    return this._applyModelPatch(currentModel, Object.assign({}, modelPatch), updatedModels); //changed
                } catch (e) {
                    console.log(currentModel, modelPatch);
                }

            });
            this.dataModels[modelPatch._ID] = updateModelPromise;
            return updateModelPromise;
        }
    }

    private static _searchModelInObject(searchedModelId: string, object: any): any {
        if (object !== null && typeof object === "object" && typeof object["_modelID"] !== "undefined" && object["_modelID"] === searchedModelId) {
            return object;
        }

        for (const objProp in object) {
            if (Object.prototype.hasOwnProperty.call(object, objProp) && typeof object[objProp] === "object") {
                const searchResult = DataManager._searchModelInObject(searchedModelId, object[objProp]);
                if (searchResult !== null) {
                    return searchResult;
                }
            }
        }

        return null;
    }

    /**
     *
     * @param {object} currentModel
     * @param {object} modelPatch
     * @param {object} updatedModels
     * @private
     */
    private _applyModelPatch(currentModel: any, modelPatch: any, updatedModels: any) {
        if (modelPatch === null) {
            return null;
        }

        if (Object.prototype.hasOwnProperty.call(modelPatch, "_ID") && Object.prototype.hasOwnProperty.call(modelPatch, "_Data") && (currentModel === null || currentModel instanceof DataModel)) { // we have model
            const patchData = modelPatch._Data;
            if (patchData === "_ref") {
                const referencedModelID = modelPatch._ID;
                if (typeof updatedModels[referencedModelID] === "undefined") {
                    for (const updatedModelId in updatedModels) {
                        if (Object.prototype.hasOwnProperty.call(updatedModels, updatedModelId)) {
                            const updatedModel = updatedModels[updatedModelId];
                            const searchResult = DataManager._searchModelInObject(referencedModelID, updatedModel);
                            if (searchResult !== null) {
                                return searchResult;
                            }
                        }
                    }

                    console.error("Something is not right!");
                } else {
                    return updatedModels[referencedModelID];
                }
            }

            for (const dataMinifiedProperty in patchData) {
                if (Object.prototype.hasOwnProperty.call(patchData, dataMinifiedProperty)) {
                    const dataPropertyKey = currentModel.getKeyByMinifiedKey(dataMinifiedProperty);
                    if (typeof patchData[dataMinifiedProperty] !== "undefined" && typeof patchData[dataMinifiedProperty] !== "object") {
                        currentModel.setProperty(dataPropertyKey, patchData[dataMinifiedProperty]);
                        updatedModels[currentModel.modelID] = currentModel;
                    } else if (typeof patchData[dataMinifiedProperty] === "object") {
                        const modelPropertyValue = currentModel.getProperty(dataPropertyKey);
                        if (typeof modelPropertyValue !== "object") { // current model's property is probably null
                            currentModel.setProperty(dataPropertyKey, patchData[dataMinifiedProperty]);
                            updatedModels[currentModel.modelID] = currentModel;
                        } else {
                            const mergedModel = this._applyModelPatch(modelPropertyValue, patchData[dataMinifiedProperty], updatedModels);
                            currentModel.setProperty(dataPropertyKey, mergedModel);
                            updatedModels[currentModel.modelID] = currentModel;
                        }
                    } else {
                        console.log("Somewhere here in some ELSE!");
                    }
                }
            }
        } else { // it is object

            const cachedPatchModels: GenericDictionary<any> = {};
            for (const patchPropKey in modelPatch) {
                if (Object.prototype.hasOwnProperty.call(modelPatch, patchPropKey)) {
                    const patchPropValue = modelPatch[patchPropKey];
                    if (patchPropValue !== null 
                        && Object.prototype.hasOwnProperty.call(patchPropValue, "_ID") 
                    && Object.prototype.hasOwnProperty.call(patchPropValue, "_Data")) { // modelData we need to prepare for search
                        cachedPatchModels[patchPropValue._ID] = [patchPropKey, patchPropValue];
                    }
                }
            }

            let mergedChanges: GenericDictionary<any> = {};
            const currentModelIsArray = currentModel instanceof Array;
            if (currentModelIsArray) {
                mergedChanges = [];
            }
            for (const currentModelPropKey in currentModel) {
                if (Object.prototype.hasOwnProperty.call(currentModel, currentModelPropKey)) {
                    const currentModelPropValue = currentModel[currentModelPropKey];
                    if (currentModelPropValue instanceof DataModel) {
                        if (Object.prototype.hasOwnProperty.call(cachedPatchModels, currentModelPropValue.modelID)) { // we have update for it
                            const mergedModel = this._applyModelPatch(currentModelPropValue, cachedPatchModels[currentModelPropValue.modelID][1], updatedModels);
                            mergedChanges[cachedPatchModels[currentModelPropValue.modelID][0]] = mergedModel;

                            updatedModels[mergedModel.modelID] = mergedModel;
                        }
                    } else {
                        if (Object.prototype.hasOwnProperty.call(modelPatch, currentModelPropKey)) {//we have update for that key
                            if (typeof modelPatch[currentModelPropKey] === "number" || typeof modelPatch[currentModelPropKey] === "string" || typeof modelPatch[currentModelPropKey] === "boolean") {
                                mergedChanges[currentModelPropKey] = modelPatch[currentModelPropKey];
                            } else {
                                const mergedModel = this._applyModelPatch(currentModelPropValue, modelPatch[currentModelPropKey], updatedModels);
                                mergedChanges[currentModelPropKey] = mergedModel;
                                updatedModels[currentModelPropKey] = mergedModel;
                            }

                            delete modelPatch[currentModelPropKey];

                        } else {
                            if (currentModelPropValue !== null) {
                                updatedModels[currentModelPropValue.modelID] = currentModelPropValue;
                            }

                            mergedChanges[currentModelPropKey] = currentModelPropValue;
                        }
                    }
                }
            }

            for (const currentModelPropKeySecond in currentModel) {
                if (Object.prototype.hasOwnProperty.call(currentModel, currentModelPropKeySecond)) {
                    const currentModelPropValue = currentModel[currentModelPropKeySecond];
                    if (currentModelPropValue instanceof DataModel) {
                        if (Object.prototype.hasOwnProperty.call(cachedPatchModels, currentModelPropValue.modelID)) { // we have update for it
                            delete modelPatch[cachedPatchModels[currentModelPropValue.modelID][0]];
                        } else {
                            if (currentModelIsArray) {
                                for (let i = 0; i <= mergedChanges.length; i++) {
                                    if (typeof mergedChanges[i] === "undefined") { // find next min index
                                        const changeIsToDelete = Object.prototype.hasOwnProperty.call(modelPatch, i) && modelPatch[i] === null;// if key is set to null that means deleted
                                        if (!changeIsToDelete) {
                                            mergedChanges[i] = currentModelPropValue;
                                        }
                                        break;
                                    }
                                }

                            } else if(modelPatch[currentModelPropKeySecond] !== null) { // merge if not null - null means deleted/delete
                                mergedChanges[currentModelPropKeySecond] = currentModelPropValue;
                            }

                            updatedModels[currentModelPropValue.modelID] = currentModelPropValue;
                        }
                    } else {
                        if (typeof modelPatch === "object") {
                            for (const patchProp in modelPatch) {
                                if (Object.prototype.hasOwnProperty.call(modelPatch, patchProp)) {
                                    mergedChanges[patchProp] = modelPatch[patchProp];
                                }
                            }
                        } else {
                            console.log("Not an instance of DataModel and object!");
                        }
                    }
                }
            }

            for (const patchPropKeySecond in modelPatch) {
                if (Object.prototype.hasOwnProperty.call(modelPatch, patchPropKeySecond) && typeof mergedChanges[patchPropKeySecond] === "undefined") {
                    const patchPropValue = modelPatch[patchPropKeySecond];
                    if (patchPropValue !== null 
                        && Object.prototype.hasOwnProperty.call(patchPropValue, "_ID") 
                        && Object.prototype.hasOwnProperty.call(patchPropValue, "_Data")) {
                        mergedChanges[patchPropKeySecond] = DataModelMapper.createFromObject(patchPropValue);
                        updatedModels[mergedChanges[patchPropKeySecond].modelID] = mergedChanges[patchPropKeySecond];

                    } else {
                        mergedChanges[patchPropKeySecond] = modelPatch[patchPropKeySecond];
                    }
                }
            }

            currentModel = Object.assign({}, mergedChanges) //changed
        }

        return currentModel;
    }

    private static _onDataReceive(dataModelPatch: any) {
        console.log("Update");
        console.log(dataModelPatch);
        const dataManager = DataManager.instance(undefined);
        const updatedModelPromise = dataManager._updateModel(dataModelPatch);// keep data model even if there are no local subscriptions
        if (updatedModelPromise) {
            updatedModelPromise.then((updatedModel: any) => {
                if (updatedModel) {
                    if (typeof dataManager.subscriptions[updatedModel.modelID] !== "undefined" && dataManager.subscriptions[updatedModel.modelID] !== null) {
                        const subscribedEventHandlers = dataManager.subscriptions[updatedModel.modelID];
                        for (let i = 0; i < subscribedEventHandlers.length; i++) {
                            subscribedEventHandlers[i](updatedModel);
                        }
                    }
                } else {
                    console.error("Invalid Data Model Received!");
                }
            });
        } else {
            console.warn("Something is not right maybe!");
        }
    }

    private static _onClose() {
        console.warn("Connection disconnected!");
        console.log("In Data managers handler!");
    }

    private _subscribeToModelChanges(modelID: string, getData: boolean | undefined) {
        getData = typeof getData === "undefined" ? true : getData;
        const requestData = {
            "ModelID": modelID,
            "UserID": this.userId,
            "FetchData": getData,
            "GetDataFromURL": this.modelUrl
        };

        return this._connectionManager.httpSend(DataManager.APIRequestURL.SUBSCRIBE_TO_MODEL, requestData, "POST");
    }

    private _unsubscribeFromModelChanges(modelID: string) {
        const requestData = { "ModelID": modelID, "UserID": this.userId };

        return this._connectionManager.httpSend(DataManager.APIRequestURL.UNSUBSCRIBE_FROM_MODEL, requestData, "POST");
    }

    private static _generateUserId() {
        const cacheUserIdKey = "sportzone-live-user-id";
        let userId = window.localStorage.getItem(cacheUserIdKey);
        if (!userId) { // not found in browser storage
            const estimated49YearsTimestamp = 1545264000; // 49 years in timestamp
            const currentTimeStamp = Math.floor(new Date().valueOf() / 1000) - estimated49YearsTimestamp;
            const randomNumber = Math.floor(Math.random() * Math.floor(1024));
            userId = "anonspz_" + currentTimeStamp.toString() + randomNumber.toString();
            window.localStorage.setItem(cacheUserIdKey, userId);
        }

        return userId;
    }
}