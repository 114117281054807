import DataModelMapper from "@/components/util/DataModelMapper";
import GenericDictionary from "@/components/util/GenericDictionary";
import Channel from "@/models/main/Channel";
import DataModel from "@/models/main/DataModel";
import { ProviderUser } from "@/models/main/ProviderUser";
import Station from "@/models/main/Station";
import { Sex, SportzoneRole, User } from "@/models/main/User";
import PlayerDataModel from "./PlayerDataModel";
import Livestream from "@/models/main/Livestream";

export default class UserDataModel extends DataModel implements User {
    public static readonly MODEL_NAME = "U"

    private _id?: number
    private _firstName?: string
    private _middleName?: string
    private _lastName?: string
    private _sex?: Sex
    private _email?: string
    private _birthday?: Date
    private _nationality?: string
    private _players: GenericDictionary<PlayerDataModel> = {}
    private _providerUsers: ProviderUser[] = []
    private _stations: Station[] = []
    private _channels: Channel[] = []
    private _userRoles: SportzoneRole[] = []
    private _favouriteLivestreams: GenericDictionary<Livestream> = {}

    //#region Fields Keys
    static get IDKey() {
        return "id";
    }

    static get firstNameKey() {
        return "firstName";
    }

    static get middleNameKey() {
        return "middleName";
    }

    static get lastNameKey() {
        return "lastName";
    }

    static get sexKey() {
        return "sex";
    }

    static get emailKey() {
        return "email";
    }

    static get birthdayKey() {
        return "birthday";
    }

    static get nationalityKey() {
        return "nationality";
    }

    static get playersKey() {
        return "players";
    }

    static getFavouriteLivestreamsKey() {
        return "favouriteLivestreams";
    }

    //#endregion

    // #region accessors/mutations
    get id() {
        return this._id;
    }

    set id(id) {
        this._id = id;
    }

    get firstName() {
        return this._firstName;
    }

    set firstName(firstName) {
        this._firstName = firstName;
    }

    get middleName() {
        return this._middleName;
    }

    set middleName(middleName) {
        this._middleName = middleName;
    }

    get lastName() {
        return this._lastName;
    }

    set lastName(lastName) {
        this._lastName = lastName;
    }

    get sex() {
        return this._sex;
    }

    set sex(sex) {
        this._sex = sex;
    }

    get email() {
        return this._email;
    }

    set email(email) {
        this._email = email;
    }

    get birthday() {
        return this._birthday;
    }

    set birthday(birthday) {
        this._birthday = birthday;
    }

    get nationality() {
        return this._nationality;
    }

    set nationality(nationality) {
        this._nationality = nationality;
    }

    get players() {
        return this._players;
    }

    set players(players) {
        this._players = players;
    }

    get providerUsers()  {
        console.error("Do not use this! It will not be populated!")
        return this._providerUsers
    }  
    get stations()  {
        console.error("Do not use this! It will not be populated!")
        return this._stations
    }  
    get channels()  {
        console.error("Do not use this! It will not be populated!")
        return this._channels
    }  

    get userRoles()  {
        console.error("Do not use this! It will not be populated!")
        return this._userRoles
    }  

    get favouriteLivestreams() {
        console.error("Do not use this! It will not be populated!")
        return this._favouriteLivestreams 
    }

    //#endregion

    constructor(modelID:string, modelData: any) {
        super();
        this.parseMinifiedData(modelData);
        if(this.id !== undefined) {
            const modelId = DataModel.generateModelID(this, {"id": this.id});
            if(modelId !== false) {
                this.modelID = modelId
            }
        }
    }

    // #region FieldMappings Getters/Setters

    dataModelName() {
        return UserDataModel.MODEL_NAME
    }

    getKeyByMinifiedKey(minKey: string) {
        switch (minKey) {
            case "1":
                return UserDataModel.IDKey;
            case "2":
                return UserDataModel.firstNameKey;
            case "3":
                return UserDataModel.middleNameKey;
            case "4":
                return UserDataModel.lastNameKey;
            case "5":
                return UserDataModel.sexKey;
            case "6":
                return UserDataModel.emailKey;
            case "7":
                return UserDataModel.birthdayKey;
            case "8":
                return UserDataModel.nationalityKey;
            case "9":
                return UserDataModel.playersKey;
            default:
                return ""
        }
    }

    getProperty(propertyKey: string) {
        switch (propertyKey) {
            case UserDataModel.IDKey:
                return this.id;
            case UserDataModel.firstNameKey:
                return this.firstName;
            case UserDataModel.middleNameKey:
                return this.middleName;
            case UserDataModel.lastNameKey:
                return this.lastName;
            case UserDataModel.sexKey:
                return this.sex;
            case UserDataModel.emailKey:
                return this.email;
            case UserDataModel.birthdayKey:
                return this.birthday;
            case UserDataModel.nationalityKey:
                return this.nationality;
            case UserDataModel.playersKey:
                return this.players;
        }
    }

    setProperty(propertyKey: string, value: any) {
        let players = null;
        switch (propertyKey) {
            case UserDataModel.IDKey:
                this.id = value;
                break;
            case UserDataModel.firstNameKey:
                this.firstName = value;
                break;
            case UserDataModel.middleNameKey:
                this.middleName = value;
                break;
            case UserDataModel.lastNameKey:
                this.lastName = value;
                break;
            case UserDataModel.sexKey:
                this.sex = value;
                break;
            case UserDataModel.emailKey:
                this.email = value;
                break;
            case UserDataModel.birthdayKey:
                this.birthday = value;
                break;
            case UserDataModel.nationalityKey:
                this.nationality = value;
                break;
            case UserDataModel.playersKey:
                players = value;
                for (const playerKey in players) {
                    if (Object.prototype.hasOwnProperty.call(players, playerKey)) {
                        const player = players[playerKey];
                        let playerModel = null;
                        if (player instanceof DataModel) {
                            playerModel = player;
                        } else if(player !== null) {
                            playerModel = DataModelMapper.createFromObject(player);
                        }
                        if (playerModel instanceof PlayerDataModel) {
                            this.players[playerKey] = playerModel;
                        } else if (player === null) {
                            delete this.players[playerKey];
                        }
                    }
                }
                break;
        }
    }

    //#endregion
}