import { computed, Ref } from '@vue/runtime-core';
import useInterpretVolleyballEvents from './UseInterpretVolleyballEvents';
import useCurrentContainerComputeds from '@/composables/generic/UseCurrentContainerComputeds'
import GenericDictionary from '@/components/util/GenericDictionary';
import { Event } from '@/models/main/Event';


export default function useVolleyballContainerComputeds(currentContainerId: Ref<number>, playerCurrentUnixTime?: Ref<number | undefined>, isSpoiledModeOn?: Ref<boolean>) {

     const { 
        getCurrentHomeEntity,
        getCurrentAwayEntity,
        getCurrentContainer,
        getCurrentContainerEvents,
        getCurrentHomePlayersOrdered,
        getCurrentAwayPlayersOrdered
     } = useCurrentContainerComputeds(currentContainerId)

    const { calculateEntityPoints, calculateEntityGames, groupEventsByGames, countBasicStats } = useInterpretVolleyballEvents()

    const getCurrentContainerBasicStats = computed(() => {
        const homeEntityId = getCurrentHomeEntity.value?.id
        const awayEntityId = getCurrentAwayEntity.value?.id
        const currentContainer = getCurrentContainer.value
        const events: GenericDictionary<Event> = getCurrentContainerEvents.value ?? {}
        if(homeEntityId !== undefined && awayEntityId !== undefined && currentContainer !== undefined) {
            return countBasicStats(homeEntityId, awayEntityId, events, playerCurrentUnixTime?.value)
        }
    })

    const getCurrentContainerEventsGroupedByGames = computed(() => {
        const homeEntityId = getCurrentHomeEntity.value?.id
        const awayEntityId = getCurrentAwayEntity.value?.id
        const currentContainer = getCurrentContainer.value
        const events: GenericDictionary<Event> = getCurrentContainerEvents.value ?? {}
        if(homeEntityId !== undefined && awayEntityId !== undefined && currentContainer !== undefined) {
            return groupEventsByGames(homeEntityId, awayEntityId, events, playerCurrentUnixTime?.value, isSpoiledModeOn?.value ? undefined : playerCurrentUnixTime?.value)
        }

        return {}
    })

    const getCurrentHomeEntityPoints = computed((): number => {
        const homeEntityId = getCurrentHomeEntity.value?.id
        const currentContainer = getCurrentContainer.value
        const events: GenericDictionary<Event> = getCurrentContainerEvents.value ?? {}
        if(homeEntityId !== undefined && currentContainer !== undefined) {
            return calculateEntityPoints(homeEntityId, events, playerCurrentUnixTime?.value)
        }

        return 0
    })

    const getCurrentAwayEntityPoints = computed((): number => {
        const awayEntityId = getCurrentAwayEntity.value?.id
        const currentContainer = getCurrentContainer.value
        const events: GenericDictionary<Event> = getCurrentContainerEvents.value ?? {}
        if(awayEntityId !== undefined && currentContainer !== undefined) {
            return calculateEntityPoints(awayEntityId, events, playerCurrentUnixTime?.value)
        }

        return 0
    })

    const currentHomeEntityGamePoints = computed((): number => {
        const homeEntityId = getCurrentHomeEntity.value?.id
        const currentContainer = getCurrentContainer.value
        const events: GenericDictionary<Event> = getCurrentContainerEvents.value ?? {}
        if(homeEntityId !== undefined && currentContainer !== undefined) {
            return calculateEntityGames(homeEntityId, events, playerCurrentUnixTime?.value)
        }
        return 0
    })

    const currentAwayEntityGamePoints = computed((): number => {
        const awayEntityId = getCurrentAwayEntity.value?.id
        const currentContainer = getCurrentContainer.value
        const events: GenericDictionary<Event> = getCurrentContainerEvents.value ?? {}
        if(awayEntityId !== undefined && currentContainer !== undefined) {
            return calculateEntityGames(awayEntityId, events, playerCurrentUnixTime?.value)
        }

        return 0
    })

    return {
        calculateEntityPoints,
        calculateEntityGames,
        groupEventsByGames,
        getCurrentContainerBasicStats,
        getCurrentContainerEvents,
        getCurrentContainerEventsGroupedByGames,
        getCurrentContainer,
        getCurrentHomeEntity,
        getCurrentAwayEntity,
        getCurrentHomeEntityPoints,
        getCurrentAwayEntityPoints,
        currentHomeEntityGamePoints,
        currentAwayEntityGamePoints,
        getCurrentHomePlayersOrdered,
        getCurrentAwayPlayersOrdered
    }
}