<template>
    <TheOrganizerDashboard>
        <div class="tenn-score">
            <div class="container-title">{{getCurrentContainer?.title}}</div>
            <div class="score-form" :class="{ 'disabled': formIsDisabled }">
                <div class="break-controls">
                </div>
                <div class="match-controls">
                    <div class="left-control">
                        <label class="spz-toggle">
                            <span class="spz-toggle-label">Tiebreak</span>
                            <input class="spz-toggle-checkbox" @click.stop.prevent="onSetGameAsTiebreak" :disabled="formIsDisabled" type="checkbox" :checked="getCurrentMatchStats?.matchScores.isTiebreakGame">
                            <div class="spz-toggle-switch"></div>
                        </label>
                        <!-- <button class="match-control-btn default-box" type="button" title="Break"
                         @click="onSetGameAsTiebreak">Tiebreak Game</button> -->

                    </div>
                    <div class="middle-control">
                        <div class="who-is-serving-section">
                            <div class="tenn-srv-green-circle" @click="onServingClick(getCurrentHomeEntity?.id)" :class="{ 'active' : getCurrentMatchStats?.matchScores.servingEntityId === getCurrentHomeEntity?.id }"></div>
                            <div class="tenn-srv-green-circle" @click="onServingClick(getCurrentAwayEntity?.id)" :class="{ 'active' : getCurrentMatchStats?.matchScores.servingEntityId === getCurrentAwayEntity?.id }"></div>
                        </div>
                        <button v-show="false" @click="onShareLinkButtonClicked" class="match-control-btn default-box" type="button" title="Share">Share</button>
                        <Modal ref="infoPopUp">
                            <template v-slot:header>
                                <h1>Share</h1>
                            </template>
                            <template v-slot:body>
                                <div class="link-section">
                                    {{ shareLink }}
                                </div>
                            </template>
                            <template v-slot:footer>
                                <div>
                                    <button @click="infoPopUp.closeModal(), copyToClipboard(shareLink)" class="btn">Copy</button>
                                    <button @click="infoPopUp.closeModal()" class="btn btn-inverse">Cancel</button>
                                </div>
                            </template>
                        </Modal>
                    </div>
                    <div class="right-control">
                        <button @click="onReverseClick" class="btn delete-btn" type="button" title="Revert"
                            :disabled="formIsDisabled">Undo</button>
                    </div>
                </div>
                <div class="match-scoreboard">
                    <div class="home-team">
                        <div class="main-scoreboard">
                            <div class="game-control-actions">
                                <ActionBox @click="onHomeGameBoxClick" class="additional-btn" :isDisabled="formIsDisabled" style="float: left;">Game Point</ActionBox>
                                <ActionBox @click="onHomeSetClick" class="additional-btn" :isDisabled="formIsDisabled">Set Point</ActionBox>
                            </div>
                            <div class="scoreboard-top">
                                <div class="team-name">
                                    {{ getCurrentHomeEntity?.name }}
                                </div>
                            </div>
                            <div @click="editScoreboard" class="scoreboard-bottom">
                                <div class="scoreboard-points">{{ getCurrentHomeEntityPoints }}</div>
                                <div class="scoreboard-games">{{ currentHomeEntityGamePoints }}</div>
                                <div class="scoreboard-sets">{{ currentHomeEntitySetPoints }}</div>
                            </div>
                            <div class="home-point-types">
                                <PointTypesSet @click="onHomePointBoxClick" :isDisabled="formIsDisabled" />
                            </div>
                        </div>
                    </div>
                    <div class="splitter"></div>
                    <div class="away-team">
                        <div class="main-scoreboard">
                            <div class="game-control-actions">
                                <ActionBox @click="onAwayGameBoxClick" class="additional-btn" :isDisabled="formIsDisabled">Game Point</ActionBox>
                                <ActionBox @click="onAwaySetClick" class="additional-btn" :isDisabled="formIsDisabled">Set Point</ActionBox>
                            </div>
                            <div class="scoreboard-top">
                                <div class="team-name">
                                    {{ getCurrentAwayEntity?.name }}
                                </div>
                            </div>
                            <div @click="editScoreboard" class="scoreboard-bottom">
                                <div class="scoreboard-points">{{ getCurrentAwayEntityPoints }}</div>
                                <div class="scoreboard-games">{{ currentAwayEntityGamePoints }}</div>
                                <div class="scoreboard-sets">{{ currentAwayEntitySetPoints }}</div>
                            </div>
                            <div class="away-point-types">
                                <PointTypesSet @click="onAwayPointBoxClick" :isDisabled="formIsDisabled" />
                            </div>
                        </div>
                    </div>
                </div>
                <button @click="toggleLivestream" type="button">{{ isClosed ? 'Show' : 'Hide' }}</button>
                <button v-if="getCurrentContainer?.livestreams && !isClosed" @click="onAddPointOfInterestBtnClicked" type="button" title="Add Point Of Interest">Add Point Of Interest</button>
            </div>
            <LivePlayer v-if="getCurrentContainer?.livestreams && !isClosed" @playerDurationChange="onPlayerDurationChange" :livestream="getCurrentContainer?.livestreams[0]" :startPlayingAt="whereToPlay" />
        </div>
        <div class="numpad-modal-wrapper">
            <button v-show="false" @click="numpadPopup.openModal()" id="openNumpadButton">Numpad</button>
            <Modal ref="numpadPopup">
                <template v-slot:body>
                    <NumpadModal @numpadValueChange="onNumpadValueChange" />
                </template>
                <template v-slot:footer>
                    <button @click="onEnterButtonClicked()" id="enterButton">Enter</button>
                    <button @click="onCancelButtonClicked()" id="cancelButton">Cancel</button>
                </template>
            </Modal>
        </div>
        <Modal ref="scoreboardPopup">
            <template v-slot:header>
                <h1>Override Score</h1>
            </template>
            <template v-slot:body>
                <div class="scoreboard-modal-wrapper">
                    <div>
                        <h3>{{ getCurrentHomeEntity?.name }}</h3>
                        <div class="scoreboard-controls">
                            <button class="tennis-action-box" @click="updatePoints(tempHomePoints, 'home', '-')" type="button">-</button>
                            <div class="tennis-action-box">
                                {{ tempHomePoints }}
                            </div>
                            <button class="tennis-action-box" @click="updatePoints(tempHomePoints, 'home', '+')" type="button">+</button>
                        </div>
                        <div class="scoreboard-controls">
                            <button class="tennis-action-box" @click="tempHomeGamePoints--" :disabled="tempHomeGamePoints === 0">-</button>
                            <div class="tennis-action-box">
                                {{ tempHomeGamePoints }}
                            </div>
                            <button class="tennis-action-box" @click="tempHomeGamePoints++" type="button">+</button>
                        </div>
                        <div class="scoreboard-controls">
                            <button class="tennis-action-box" @click="tempHomeSetPoints--" :disabled="tempHomeSetPoints === 0">-</button>
                            <div class="tennis-action-box">
                                {{ tempHomeSetPoints }}
                            </div>
                            <button class="tennis-action-box" @click="tempHomeSetPoints++" type="button">+</button>
                        </div>
                    </div>
                    <div class="override-row-headings">
                        <div class="tennis-action-box">Points</div>
                        <div class="tennis-action-box">Games</div>
                        <div class="tennis-action-box">Sets</div>
                    </div>
                    <div>
                        <h3>{{ getCurrentAwayEntity?.name }}</h3>
                        <div class="scoreboard-controls">
                            <button class="tennis-action-box" @click="updatePoints(tempAwayPoints, 'away', '-')" type="button">-</button>
                            <div class="tennis-action-box">
                                {{ tempAwayPoints }}
                            </div>
                            <button class="tennis-action-box" @click="updatePoints(tempAwayPoints, 'away', '+')" type="button">+</button>
                        </div>
                        <div class="scoreboard-controls">
                            <button class="tennis-action-box" @click="tempAwayGamePoints--" :disabled="tempAwayGamePoints === 0">-</button>
                            <div class="tennis-action-box">
                                {{ tempAwayGamePoints }}
                            </div>
                            <button class="tennis-action-box" @click="tempAwayGamePoints++" type="button">+</button>
                        </div>
                        <div class="scoreboard-controls">
                            <button class="tennis-action-box" @click="tempAwaySetPoints--" :disabled="tempAwaySetPoints === 0">-</button>
                            <div class="tennis-action-box">
                                {{ tempAwaySetPoints }}
                            </div>
                            <button class="tennis-action-box" @click="tempAwaySetPoints++" type="button">+</button>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <button @click="overwriteScore(), scoreboardPopup.closeModal()" class="btn edit-btn" type="button" title="Override">Override</button>
                <button @click="scoreboardPopup.closeModal()" class="btn btn-inverse" type="button" title="Cancel">Cancel</button>
            </template>
        </Modal>
    </TheOrganizerDashboard>
</template>
<script lang="ts">
import { createNamespacedHelpers, useStore } from 'vuex';
import { defineComponent, Ref, ref, onMounted } from 'vue';
import TheOrganizerDashboard from '@/components/main/TheOrganizerDashboard.vue';
import ActionBox from '@/components/tennis/ActionBox.vue';
import PointTypesSet from '@/components/tennis/PointTypesSet.vue';
import Modal from '@/components/main/Modal.vue';
import NumpadModal from '@/components/main/NumpadModal.vue';
import { Container } from '@/models/main/Container';
import { key } from '@/store';
import { useRoute } from 'vue-router';
import useInterpretTennisEvents from '@/composables/tennis/UseInterpretTennisEvents';
import useCurrentContainerComputeds from '@/composables/tennis/UseCurrentContainerComputeds';
import { EventType, Event } from '@/models/main/Event';
import { TennisGameControlType, TennisOverrideData, TennisPointType } from '@/composables/tennis/TennisEventTypes';
import { copyToClipboard, addAccessKeyQueryParam } from '@/composables/common/Util';
import LivePlayer from '@/components/main/LivePlayer.vue';
import { PointOfInterest } from '@/models/main/PointOfInterest';
const { mapGetters } = createNamespacedHelpers('containerStore');
export default defineComponent({
    components: {
        TheOrganizerDashboard,
        ActionBox,
        PointTypesSet,
        Modal,
        NumpadModal,
        LivePlayer,
    },
    computed: {
        ...mapGetters([
            'getContainer',
            'getHomeEntity',
            'getAwayEntity',
        ]),
    },
    setup() {
        const numpadInputValue: Ref<string> = ref('');
        const numpadPopup: Ref<any> = ref(null);
        const match: Ref<Container | undefined> = ref();
        const store = useStore(key);
        const route = useRoute();
        const matchId: Ref<number> = ref(parseInt(route.params.id as string));
        let accessKey: string | undefined = route.query?.accessKey as string;
        const shareLink: Ref<string | null> = ref(null);
        const infoPopUp: Ref<any> = ref(null);
        const scoreboardPopup: Ref<any> = ref(null);
        const tempHomePoints: Ref<any> = ref(0);
        const tempAwayPoints: Ref<any> = ref(0);
        const tempHomeGamePoints: Ref<number> = ref(0);
        const tempAwayGamePoints: Ref<number> = ref(0);
        const tempHomeSetPoints: Ref<number> = ref(0);
        const tempAwaySetPoints: Ref<number> = ref(0);
        const isClosed: Ref<boolean> = ref(false);
        const whereToPlay: Ref<number> = ref(0);
        let currentPlayerDuration = 0;
        const {
            countBasicStats,
            getNextPointScore,
            getPreviousPointScore,
        } = useInterpretTennisEvents();
        const {
            getCurrentHomeEntity,
            getCurrentContainer,
            getCurrentAwayEntity,
            getCurrentHomeEntityPoints,
            getCurrentAwayEntityPoints,
            currentHomeEntityGamePoints,
            currentAwayEntityGamePoints,
            currentHomeEntitySetPoints,
            currentAwayEntitySetPoints,
            getCurrentMatchStats
        } = useCurrentContainerComputeds(store, matchId);
        const formIsDisabled: Ref<boolean> = ref(false);
        const homeTimeouts: Ref<string[]> = ref([]);
        const awayTimeouts: Ref<string[]> = ref([]);
        const selectedHomePlayerId: Ref<number> = ref(0);
        const selectedAwayPlayerId: Ref<number> = ref(0);
        const onReverseClick = async () => {
            if(match.value !== undefined) {
                formIsDisabled.value = true
                console.log(formIsDisabled.value)
                const result  = await store.dispatch('containerStore/removeLastEvent', match.value.id)
                console.log(formIsDisabled.value)
                formIsDisabled.value = false
                return result
            }
        };
        const onHomePointBoxClick = async (pointType: TennisPointType) => {
            if(getCurrentHomeEntity.value?.id !== undefined) {
                const addedOk = await addEvent("Point", getCurrentHomeEntity.value.id, pointType)
                if(addedOk && getCurrentMatchStats.value?.matchScores.pointsScore.entities[getCurrentHomeEntity.value.id].score === "G") {
                    onHomeGameBoxClick()
                }
            }
        };
        const changeServingEntity = async() => {
            if(getCurrentMatchStats.value?.matchScores.servingEntityId && getCurrentHomeEntity.value?.id && getCurrentAwayEntity.value?.id) {
                let nextServingEntity = getCurrentHomeEntity.value.id
                if(getCurrentMatchStats.value.matchScores.servingEntityId === getCurrentHomeEntity.value?.id) {
                    nextServingEntity = getCurrentAwayEntity.value.id
                }

                const serveGameControlPoint: TennisGameControlType = "Tenn_Srv";
                const changeServerAsync = await addEvent('GameControl', nextServingEntity, serveGameControlPoint);
            }
        }

        const onHomeGameBoxClick = async () => {
            if(getCurrentHomeEntity.value?.id !== undefined && getCurrentAwayEntity.value?.id !== undefined) {
                const gameControlPoint: TennisGameControlType = "Tenn_G";
                await addEvent('GameControl', getCurrentHomeEntity.value.id, gameControlPoint);
                changeServingEntity()
            }
        };
        const onAwayPointBoxClick = async (pointType: TennisPointType) => {
            if(getCurrentAwayEntity.value?.id !== undefined) {
                const addedOk = await addEvent("Point", getCurrentAwayEntity.value.id, pointType)
                if(addedOk && getCurrentMatchStats.value?.matchScores.pointsScore.entities[getCurrentAwayEntity.value.id].score === "G") {
                    onAwayGameBoxClick()
                }
            }
        };
        const onAwayGameBoxClick = async () => {
            if(getCurrentAwayEntity.value?.id !== undefined && getCurrentHomeEntity.value?.id !== undefined) {
                const gameControlPoint: TennisGameControlType = "Tenn_G";
                await addEvent("GameControl", getCurrentAwayEntity.value.id, gameControlPoint)
                changeServingEntity()
            }
        };

        const onHomeSetClick = async() => {
            if(getCurrentHomeEntity.value?.id !== undefined) {
                const gameControlPoin: TennisGameControlType = "Tenn_S";
                const addedOk = await addEvent("GameControl", getCurrentHomeEntity.value.id, gameControlPoin)
            }
        }

        const onAwaySetClick = async() => {
            if(getCurrentAwayEntity.value?.id !== undefined) {
                const gameControlPoin: TennisGameControlType = "Tenn_S";
                const addedOk = await addEvent("GameControl", getCurrentAwayEntity.value.id, gameControlPoin)
            }
        }

        const onServingClick = async(entityId: number | undefined) => {
            if(entityId) {
                const gameControlPoin: TennisGameControlType = "Tenn_Srv";
                const addedOk = await addEvent("GameControl", entityId, gameControlPoin)
            }
        }

        const onSetGameAsTiebreak = async() => {
            if(getCurrentHomeEntity.value?.id !== undefined) {
                const addedOk = await addEvent("GameControl", getCurrentHomeEntity.value.id, "Tenn_TG")
            }
        }
        const onNumpadValueChange = (numpadInputValueOut: string) => {
            numpadInputValue.value = numpadInputValueOut;
        };
        const onEnterButtonClicked = () => {
            if (numpadInputValue.value) {
                numpadInputValue.value = '';
                numpadPopup.value.closeModal();
            }
        };
        const onCancelButtonClicked = () => {
            numpadInputValue.value = '';
            numpadPopup.value.closeModal();
        };
        const getMatch = async () => {
            match.value = await store.dispatch('containerStore/getContainer', matchId.value);
            console.log(match.value);
            if (match.value?.events) {
                if(getCurrentHomeEntity.value?.id && getCurrentAwayEntity.value?.id) {
                    const scores = countBasicStats(getCurrentHomeEntity.value?.id, getCurrentAwayEntity.value?.id, match.value.events)
                    console.log(scores);
                }
            }
        };
        const addEvent = async (type: EventType, entityId: number, description: string, playerId?: number, additionalData?: string): Promise<boolean> => {
            if (getCurrentHomeEntity.value && getCurrentHomeEntity.value && match.value !== undefined && match.value.id !== null) {
                const currentTimeStamp = new Date();
                const event: Event = {
                    type: type,
                    entityId: entityId,
                    playerId: playerId,
                    description: description,
                    containerId: match.value?.id,
                    startTimestamp: currentTimeStamp,
                    endTimestamp: currentTimeStamp,
                    additionalData: additionalData,
                };
                formIsDisabled.value = true;
                const result = await store.dispatch('containerStore/storeEvent', {
                    event,
                    accessKey,
                });
                formIsDisabled.value = false;
                return result;
            }
            return false;
        };
        const onShareLinkButtonClicked = async () => {
            accessKey = await store.dispatch('containerStore/createOperateAccess', matchId.value);
            if (accessKey !== undefined) {
                shareLink.value = addAccessKeyQueryParam(route.query, accessKey);
            }
            infoPopUp.value.openModal();
        };
        const editScoreboard = () => {
            tempHomePoints.value = getCurrentHomeEntityPoints.value;
            tempAwayPoints.value = getCurrentAwayEntityPoints.value;
            tempHomeGamePoints.value = currentHomeEntityGamePoints.value;
            tempAwayGamePoints.value = currentAwayEntityGamePoints.value;
            tempHomeSetPoints.value = currentHomeEntitySetPoints.value;
            tempAwaySetPoints.value = currentAwayEntitySetPoints.value;
            scoreboardPopup.value.openModal();
        };
        const updatePoints = (point: any, team: string, sign: string) => {
            if (team === 'home') {
                if (sign === '+') {
                    tempHomePoints.value = getNextPointScore(point);
                }
                else if (sign === '-') {
                    tempHomePoints.value = getPreviousPointScore(point);
                }
            }
            else if (team === 'away') {
                if (sign === '+') {
                    tempAwayPoints.value = getNextPointScore(point);
                }
                else if (sign === '-') {
                    tempAwayPoints.value = getPreviousPointScore(point);
                }
            }
        };
        const overwriteScore = async () => {
            if (getCurrentHomeEntity.value?.id !== undefined && getCurrentAwayEntity.value?.id !== undefined) {
                const gameControlType: TennisGameControlType = 'Tenn_O';
                const homeEntityOverrides: TennisOverrideData = {};
                if (getCurrentHomeEntityPoints.value !== tempHomePoints.value) {
                    homeEntityOverrides['overridePoint'] = tempHomePoints.value;
                }
                if (currentHomeEntityGamePoints.value !== tempHomeGamePoints.value) {
                    homeEntityOverrides['overrideGame'] = tempHomeGamePoints.value;
                }
                if (currentHomeEntitySetPoints.value !== tempHomeSetPoints.value) {
                    homeEntityOverrides['overrideSet'] = tempHomeSetPoints.value;
                }
                if (Object.keys(homeEntityOverrides).length > 0) {
                    const additionalData = JSON.stringify(homeEntityOverrides);
                    const addedHomeChanges = await addEvent('GameControl', getCurrentHomeEntity.value.id, gameControlType, undefined, additionalData);
                }
                const awayEntityOverrides: TennisOverrideData = {};
                if (getCurrentAwayEntityPoints.value !== tempAwayPoints.value) {
                    awayEntityOverrides['overridePoint'] = tempAwayPoints.value;
                }
                if (currentAwayEntityGamePoints.value !== tempAwayGamePoints.value) {
                    awayEntityOverrides['overrideGame'] = tempAwayGamePoints.value;
                }
                if (currentAwayEntitySetPoints.value !== tempAwaySetPoints.value) {
                    awayEntityOverrides['overrideSet'] = tempAwaySetPoints.value;
                }
                if (Object.keys(awayEntityOverrides).length > 0) {
                    const additionalData = JSON.stringify(awayEntityOverrides);
                    const addedAwayChanges = await addEvent('GameControl', getCurrentAwayEntity.value.id, gameControlType, undefined, additionalData);
                }
            }
        };
        const toggleLivestream = () => {
            isClosed.value = !isClosed.value;
            whereToPlay.value = currentPlayerDuration;
        };
        const onPlayerDurationChange = (playerDuration: number) => {
            currentPlayerDuration = playerDuration;
        };

        const onAddPointOfInterestBtnClicked = async () => {
            const currentTimestamp = new Date();
            const pointOfInterest: PointOfInterest = {
                id: undefined,
                description: null,
                creator: null,
                creatorId: 4,
                timestamp: currentTimestamp,
                relativeTimestamp: null,
                livestream: null,
                livestreamId: getCurrentContainer.value?.livestreams[0].id,
            };
            await store.dispatch('pointOfInterestStore/savePointOfInterest', pointOfInterest);
        };
        onMounted(getMatch);
        return {
            numpadInputValue,
            numpadPopup,
            match,
            matchId,
            getCurrentHomeEntity,
            getCurrentContainer,
            getCurrentAwayEntity,
            getCurrentHomeEntityPoints,
            getCurrentAwayEntityPoints,
            currentHomeEntityGamePoints,
            currentAwayEntityGamePoints,
            currentHomeEntitySetPoints,
            currentAwayEntitySetPoints,
            formIsDisabled,
            homeTimeouts,
            awayTimeouts,
            selectedHomePlayerId,
            selectedAwayPlayerId,
            getCurrentMatchStats,
            accessKey,
            shareLink,
            infoPopUp,
            scoreboardPopup,
            tempHomePoints,
            tempAwayPoints,
            tempHomeGamePoints,
            tempAwayGamePoints,
            tempHomeSetPoints,
            tempAwaySetPoints,
            isClosed,
            whereToPlay,
            onReverseClick,
            onHomePointBoxClick,
            onHomeGameBoxClick,
            onAwayPointBoxClick,
            onAwayGameBoxClick,
            onNumpadValueChange,
            onEnterButtonClicked,
            onCancelButtonClicked,
            onServingClick,
            onHomeSetClick,
            onAwaySetClick,
            onSetGameAsTiebreak,
            onShareLinkButtonClicked,
            copyToClipboard,
            getNextPointScore,
            getPreviousPointScore,
            editScoreboard,
            updatePoints,
            overwriteScore,
            toggleLivestream,
            onPlayerDurationChange,
            onAddPointOfInterestBtnClicked,
        };
    },
});
</script>
<style>

/* SPZ Toggle BUTTON CSS */
.spz-toggle {
  cursor: pointer;
  display: inline-block;
}

.spz-toggle-switch {
  display: inline-block;
  background: #ccc;
  width: 3.75em;
  height: 2em;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
  border-radius: 2em;
}
.spz-toggle-switch:before, .spz-toggle-switch:after {
  content: "";
}
.spz-toggle-switch:before {
  display: block;
  background: linear-gradient(to bottom, #fff 0%, #eee 100%);
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
  width: 1.5em;
  height: 1.5em;
  position: absolute;
  top: 4px;
  left: 4px;
  transition: left 0.25s;
}
.spz-toggle:hover .spz-toggle-switch:before {
  background: linear-gradient(to bottom, #fff 0%, #fff 100%);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
}
.spz-toggle-checkbox:checked + .spz-toggle-switch {
  background: var(--archive-bck-color);
}
.spz-toggle-checkbox:checked + .spz-toggle-switch:before {
    left: 1.8em;
}

.spz-toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

.spz-toggle-label {
  margin-right: 1ch;
  position: relative;
  top: 2px;
}

/* END */

.tenn-score .scoreboard-bottom {
    padding: 0 1em;
    cursor: pointer;
    justify-content: space-between;
}

.tenn-score .home-team .scoreboard-bottom {
  flex-direction: row-reverse;
}

.tenn-score .away-team .scoreboard-bottom {
    justify-content: space-between;
}

.tennis-main-scoreboard-set-points-wrapper {
  display: flex;
  width: 100%;
}

.away-team .tennis-main-scoreboard-set-points-wrapper {
  justify-content: flex-end;
}

.tenn-score {
    align-self: center;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.tenn-score .container-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 1em;
  width: 100%;
  text-align: center;
}

.main-scoreboard {
  width: 100%;
}

.tenn-score .game-control-actions {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.home-team .game-control-actions {
    float: left;
    margin-right: 1ch;
}

.away-team .game-control-actions {
    float: right;
    margin-left: 1ch;
}

.game-control-actions .tennis-action-box {
  background-color: var(--bright-font-clr);
}

.home-point-types .tennis-action-box, .away-point-types .tennis-action-box  {
    background-color: #b6f5b7;
}

.home-point-types .tennis-action-box.is-negative, .away-point-types .tennis-action-box.is-negative{
    color: white;
    background-color: var(--watch-bck-color);
}

.tenn-score .home-team-competitors, .tenn-score .away-team-competitors , .tenn-score .away-point-types, .tenn-score .home-point-types {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    flex-wrap: wrap;
}

.tenn-score .away-point-types, .tenn-score .home-point-types {
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: -2px;
}
.tenn-score .team-name {
  padding: 0.25em 0;
  font-size: 1.5em;
}

.tenn-score .scoreboard-points {
  font-size: 2em;
}
.tenn-score .scoreboard-games {
  font-size: 1.5em;
}
.tenn-score .scoreboard-sets {
  font-size: 1.25em;
}
.home-point-types .tennis-action-box, .away-point-types .tennis-action-box {
  width: calc(50% - 0.52ch);
}

.tenn-srv-green-circle {
    font-size: 1.25em;
    width: 2ch;
    height: 2ch;
    border-radius: 50%;
    background-color: var(--user-item-selected-bck-color);
    cursor: pointer;
}

.tenn-srv-green-circle.active:before, .tenn-srv-green-circle:hover:before {
  content: 'S';
  color: var(--light-homepage-color);
}

.tenn-srv-green-circle.active {
  background-color: var(--archive-bck-color);
}

.tenn-srv-green-circle:hover {
  background-color: var(--archive-bck-color);
}

.left-control .tenn-srv-green-circle {
    padding-left: 1ch;
}

.left-control .tenn-srv-green-circle::before {
    left: 0;
}

.right-control .tenn-srv-green-circle {
    padding-right: 1ch;
}

.right-control .tenn-srv-green-circle::before {
    right: 0;
}

.left-control, .middle-control, .right-control {
    width: 33.33%;
    display: flex;
    justify-content: center;
}
.tenn-score .middle-control {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.who-is-serving-section {
  display: flex;
  justify-content: space-between;
  width: 8ch;
}

.scoreboard-modal-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.scoreboard-controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5em;
}

.tenn-score .stream-player {
    width: calc(100% - max(63ch, 45%));
    height: calc(100% - 3.5em);
    min-width: 50ch;
}

.tenn-score .score-form {
    width: 45%;
    min-width: 63ch;
}

.override-row-headings {
    margin-top: 4em;
}

.override-row-headings .tennis-action-box {
    margin-bottom: 0.7em;
    margin-top: 0;
    cursor: initial;
}
@media screen and (min-width: 1050px) {

}

@media screen and (min-width: 485px) {

}
</style>