<template>
<TheAdminDashboard>
    <template v-slot:default>
        <div class="rly-score">
            <h1>Rally Score</h1>
            <div class="score-form">
                <div class="controls">
                    <button type="button" title="Delete" @click="deleteLastEvent" >Delete</button>
                </div>
                <div class="events">
                    <div class="events__headers">
                        <div v-for="(eventHeaderClass, eventHeader) in eventHeaders" :key="eventHeader" class="events__headers_header" :class="eventHeaderClass">
                            {{eventHeader}}
                        </div>
                    </div>
                    <div class="event-form_new">
                        <select v-model="chosenEntityId" class="entity">
                            <option disabled value="">Please select one</option>
                            <option v-for="entityContainer in getEntityContainers(containerId)" :key="entityContainer.entity.id" :value="entityContainer.entity.id">{{entityContainer.entity.name}}</option>
                        </select>
                        <select v-model="chosenPlayerId" class="player">
                            <option disabled value="">Please select one</option>
                            <template v-for="entityContainer in getEntityContainers(containerId)" :key="entityContainer.entity.id" >
                                <option v-for="player in entityContainer.entity.players" :key="player.id" :value="player.id">{{player.number}}</option>
                            </template>
                        </select>
                        <input v-model="newEventDescription" class="description"/>
                        <textarea v-model="newEventAdditionalData" placeholder="" class="additional-data"></textarea>
                        <button class="event-form_new__save-btn" type="button" title="Save" @click="saveEvent">Save</button>
                    </div>
                    <div class="events__list">
                        <div class="events__list__event" v-for="event in getEvents(containerId)" :key="event.id">
                            <div class="entity">{{event.entityId}}</div>
                            <div class="player">{{event.playerId}}</div>
                            <div class="description">{{event.description}}</div>
                            <div class="additional-data">{{event.additionalData}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
</TheAdminDashboard>

</template>
<script lang="ts">
import TheAdminDashboard from '@/components/main/TheAdminDashboard.vue' 
import { useStore, createNamespacedHelpers} from 'vuex'
import { onMounted, Ref, ref } from 'vue'
import { key } from '@/store'
import { Event } from '@/models/main/Event'

const { mapGetters } = createNamespacedHelpers('containerStore')

export default {
    name: 'RallyScore',
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters([
            'getEvents',
            'getEntityContainers'
        ])
    },
    setup() {
        const eventHeaders = {
            'Entity': 'entity',
            'Player': 'player',
            'Race': 'description',
            'Timestamp': 'additional-data'
        }

        const store = useStore(key);
        const containerId = ref(3);
        const chosenEntityId:Ref<number|null> = ref(null)
        const chosenPlayerId:Ref<number|undefined> = ref()
        const newEventDescription:Ref<string> = ref("")
        const newEventAdditionalData:Ref<string> = ref("")

        const getEvents = () => {
            store.dispatch('containerStore/getContainer', containerId.value)
        }

        const deleteLastEvent = () => {
            store.dispatch('containerStore/removeLastEvent', containerId.value)
        }

        const saveEvent = () => {
            const event:Event = {
                type: "Timestamp",
                startTimestamp: new Date(),
                endTimestamp: new Date(),
                description: newEventDescription.value,
                additionalData: newEventAdditionalData.value,
                containerId: containerId.value,
                entityId: chosenEntityId.value as number,
                playerId: chosenPlayerId.value
            }
            store.dispatch('containerStore/storeEvent', event)
        }

        onMounted(getEvents);
        return {
            eventHeaders, 
            containerId,
            chosenEntityId,
            chosenPlayerId,
            newEventDescription,
            newEventAdditionalData,
            deleteLastEvent,
            saveEvent,
        }
    },
    components: {
        TheAdminDashboard
    }
}
</script>

<style>

.events__headers {
  display: flex;
  align-items: center;
}

.events .entity, .events .player {
  width: 150px;
}

.events .description {
  width: 180px;
}

.events .additional-data {
  width: calc(100% - 560px);
}

.event-form_new {
  display: flex;
}

.event-form_new__save-btn {
    width: 80px;
}

.events__list__event {
  display: flex;
  align-content: center;
  margin: 10px 0;
  border-top: 2px solid black;
}

.events__list__event:last-child {
  border-bottom: 2px solid black;
}

.events .entity, .events .player, .events .description, .events .additional-data{
    text-align: left;
    padding: 10px;
    margin-bottom: 10px;
}
</style>