<template>
    <div class="organization-wrapper">
        <router-link :to="{ name: 'Organization Public', params: { id: organization.id }}">
            <div class="organization-badge">
                <div class="organization-image-wrapper">
                    <img :src="'/api/organization/' + organization.id + '/logo'" :alt="organization.name" />
                </div>
                <div class="organization-info-wrapper">
                    <div class="organization-name">{{organization.name}}</div>
                </div>
            </div>
        </router-link>
    </div>
</template>
<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { Organization } from '@/models/main/Organization';

 const OrganizationPublicBadgeItem = defineComponent({
    props: {
        organization: {
            type: Object as PropType<Organization>,
            required: true
        }
    },
 })

 export default OrganizationPublicBadgeItem;
</script>
<style>

</style>