<template>
    <div class="snooker-watch-wrapper"
        :class="{ 'fallback-fullscreen': isFallbackFullScreenMode, 'fullscreen': isFullScreenMode }">
        <div v-if="!livestream" class="loading-livestream-icon-div">
            <LoadingIcon />
        </div>
        <div class="live-central-panel">
            <LivePlayer v-if="livestream" @playerDurationChange="playerDurationChange"
                @fullscreenToggle="onFullScreenToggle" :livestream="livestream" :startPlayingAt="seekPlayerTo"
                :endPlayingAt="endVideoTime" :startFullScreen="isFullScreenMode">
                <div v-if="getCurrentContainer && getCurrentHomeEntity?.id && getCurrentAwayEntity?.id"
                    class="in-player-result-board snooker-scoreboard-wrapper">
                    <div class="snooker-home-player">
                        <div class="snooker-player-names snooker-home-player-name">
                            {{ getCurrentHomeEntity?.name }}
                        </div>
                        <div class="snooker-player-points">
                            {{ calculateEntityPoints(getCurrentHomeEntity.id, getCurrentContainer?.events) }}
                        </div>
                    </div>
                    <div class="snooker-frames">
                        <div>
                            {{ calculateEntityFrames(getCurrentHomeEntity.id, getCurrentContainer?.events) }}
                        </div>
                        <div>
                            {{ calculateEntityFrames(getCurrentAwayEntity.id, getCurrentContainer?.events) }}
                        </div>
                    </div>
                    <div class="snooker-away-player">
                        <div class="snooker-player-names snooker-away-player-name">
                            {{ getCurrentAwayEntity?.name }}
                        </div>
                        <div class="snooker-player-points">
                            {{ calculateEntityPoints(getCurrentAwayEntity.id, getCurrentContainer?.events) }}
                        </div>
                    </div>
                </div>
            </LivePlayer>
        </div>
        <div v-if="getCurrentContainer && getCurrentHomeEntity?.id && getCurrentAwayEntity?.id"
            class="live-right-panel hide-on-full">
            <div class="main-snooker-stats-wrapper">
                <div class="entity-stat">
                    <div class="home-entity-name">
                        {{ getCurrentHomeEntity?.name }}
                    </div>
                    <div class="away-entity-name">
                        {{ getCurrentAwayEntity?.name }}
                    </div>
                </div>
                <div class="entity-stat">
                    <div class="home-entity-stat">
                        {{ (calculateEntityFaults(getCurrentHomeEntity?.id, getCurrentContainer?.events)).Snoo_F4 }}
                    </div>
                    <div class="entity-stat-heading">
                        Fault 4
                    </div>
                    <div class="away-entity-stat">
                        {{ (calculateEntityFaults(getCurrentAwayEntity?.id, getCurrentContainer?.events)).Snoo_F4 }}
                    </div>
                </div>
                <div class="entity-stat">
                    <div class="home-entity-stat">
                        {{ (calculateEntityFaults(getCurrentHomeEntity?.id, getCurrentContainer?.events)).Snoo_F5 }}
                    </div>
                    <div class="entity-stat-heading">
                        Fault 5
                    </div>
                    <div class="away-entity-stat">
                        {{ (calculateEntityFaults(getCurrentAwayEntity?.id, getCurrentContainer?.events)).Snoo_F5 }}
                    </div>
                </div>
                <div class="entity-stat">
                    <div class="home-entity-stat">
                        {{ (calculateEntityFaults(getCurrentHomeEntity?.id, getCurrentContainer?.events)).Snoo_F6 }}
                    </div>
                    <div class="entity-stat-heading">
                        Fault 6
                    </div>
                    <div class="away-entity-stat">
                        {{ (calculateEntityFaults(getCurrentAwayEntity?.id, getCurrentContainer?.events)).Snoo_F6 }}
                    </div>
                </div>
                <div class="entity-stat">
                    <div class="home-entity-stat">
                        {{ (calculateEntityFaults(getCurrentHomeEntity?.id, getCurrentContainer?.events)).Snoo_F7 }}
                    </div>
                    <div class="entity-stat-heading">
                        Fault 7
                    </div>
                    <div class="away-entity-stat">
                        {{ (calculateEntityFaults(getCurrentAwayEntity?.id, getCurrentContainer?.events)).Snoo_F7 }}
                    </div>
                </div>
            </div>
            <div class="logos-wrapper">
                <div class="logo-wrapper">
                    <a :href="SPORTZONE_MAIN_PAGE_URL"><img src="/sportzone-logo.svg" style="width: 100%;"
                            alt="Sportzone"></a>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, Ref, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import LivePlayer from '@/components/main/LivePlayer.vue';
import LoadingIcon from '@/components/util/LoadingIcon.vue';
import { SportzoneAuth } from '@/components/util/SportzoneAuth';
import { SPORTZONE_MAIN_PAGE_URL } from '@/composables/common/Constants';
import { shouldSeekToValue } from '@/composables/common/Util';
import LiveDataModel from '@/composables/dataModels/LiveDataModel';
import useDataManagerHelpers from '@/composables/dataModels/UseDataManagerHelpers';
import useCurrentContainerComputeds from '@/composables/snooker/UseCurrentContainerComputeds';
import useInterpretSnookerEvents from '@/composables/snooker/UseInterpretSnookerEvents';
import { Event } from '@/models/main/Event';
import { key } from '@/store';
export default defineComponent({
    components: {
        LivePlayer,
        LoadingIcon,
    },
    setup() {
        const route = useRoute();
        const store = useStore(key);
        const apiUri = SportzoneAuth.apiUri;
        const livestream: Ref<LiveDataModel | null> = ref(null);
        const currentContainerId: Ref<number> = ref(0);
        const liveId = parseInt(route.params.id as string);
        const isFullScreenMode: Ref<boolean> = ref(false);
        const isFallbackFullScreenMode: Ref<boolean> = ref(false);
        const playerCurrentUnixTime: Ref<number> = ref(0);
        const advanceSecs = 8;
        let startVideoTime: number | undefined = parseInt(route.query?.start as string);
        let endVideoTime: number | undefined = parseInt(route.query?.end as string);
        let startReplayEventId: number | undefined = parseInt(route.query?.startEvent as string);
        let endReplayEventId: number | undefined = parseInt(route.query?.endEvent as string);
        const seekPlayerTo: Ref<number> = ref(startVideoTime !== undefined ? startVideoTime : 0);
        if (isNaN(startVideoTime)) {
            startVideoTime = undefined;
        }
        if (isNaN(endVideoTime)) {
            endVideoTime = undefined;
        }
        if (startVideoTime !== undefined || isNaN(startReplayEventId)) {
            startReplayEventId = undefined;
        }
        if (endVideoTime !== undefined || isNaN(endReplayEventId)) {
            endReplayEventId = undefined;
        }
        const {
            initDataManager,
        } = useDataManagerHelpers(store);
        const {
            getCurrentContainer,
            getCurrentHomeEntity,
            getCurrentAwayEntity,
        } = useCurrentContainerComputeds(store, currentContainerId);
        const {
            calculateEntityPoints,
            calculateEntityFrames,
            calculateEntityFaults,
        } = useInterpretSnookerEvents();
        const playerDurationChange = (currentDuration: number) => {
            let liveStarttime = 0;
            if (livestream.value?.streamActualStartTime) {
                liveStarttime = new Date(livestream.value.streamActualStartTime).getTime() / 1000;
            }
            playerCurrentUnixTime.value = currentDuration + liveStarttime - advanceSecs;
        };
        const onFullScreenToggle = (isFullScreen: boolean, isFallbackFullScreen: boolean) => {
            isFullScreenMode.value = isFullScreen;
            isFallbackFullScreenMode.value = isFallbackFullScreen;
        };
        const seekPlayer = (event: Event) => {
            if (livestream.value) {
                const shouldSeekTo = shouldSeekToValue(event, livestream.value, advanceSecs);
                if (shouldSeekTo) {
                    seekPlayerTo.value = shouldSeekTo;
                }
            }
        };
        initDataManager(liveId)
            .then((result) => {
                if (result?.livestreamDataModel) {
                    livestream.value = result.livestreamDataModel;
                }
                if (result?.containerDataModel !== undefined && result.containerDataModel.id !== undefined) {
                    currentContainerId.value = result.containerDataModel.id;
                }
            });
        return {
            store,
            apiUri,
            livestream,
            currentContainerId,
            isFullScreenMode,
            isFallbackFullScreenMode,
            endVideoTime,
            seekPlayerTo,
            getCurrentContainer,
            getCurrentHomeEntity,
            getCurrentAwayEntity,
            SPORTZONE_MAIN_PAGE_URL,
            calculateEntityPoints,
            calculateEntityFrames,
            calculateEntityFaults,
            playerDurationChange,
            onFullScreenToggle,
            seekPlayer,
        };
    },
});
</script>
<style>
.snooker-watch-wrapper {
    --vly-watch-min-height: 105vh;
    height: var(--vly-watch-min-height);
    color: var(--light-homepage-color);
    --player-def-width: 1584px;
    --player-def-height: 891px;
    overflow-y: scroll;
    scrollbar-width: none;
    background-color: var(--darker-bckgrnd-color);
}

.loading-livestream-icon-div {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-snooker-stats-wrapper {
    justify-content: flex-start;
    align-items: center;
    height: 53.25ch;
    width: 100%;
    flex-direction: column;
}

.snooker-watch-wrapper::after {
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
}

.snooker-watch-wrapper::-webkit-scrollbar {
    display: none;
}

.snooker-watch-wrapper .live-central-panel {
    width: 100%;
}

.snooker-watch-wrapper .stream-player {
    height: 100%;
}

.snooker-watch-wrapper .in-player-result-board {
    bottom: 4ch;
    font-size: 1em;
    top: auto;
    position: absolute;
    width: 100%;
    justify-content: center;
}

.snooker-watch-wrapper .main-scoreboard {
    display: flex;
    justify-content: center;
}

.snooker-watch-wrapper .live-right-panel {
    display: none;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.main-snooker-stats-wrapper .entity-stat {
    width: 100%;
    background-color: var(--deep-blue-bck-color);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4ch;
    font-size: 1.25em;
}

.main-snooker-stats-wrapper .entity-stat+.entity-stat {
    margin-top: 1.5ch;
}

.main-snooker-stats-wrapper .home-entity-name,
.main-snooker-stats-wrapper .away-entity-name {
    width: 50%;
    text-align: center;
}

.main-snooker-stats-wrapper .entity-stat-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 0.85em;
}

.main-snooker-stats-wrapper .home-entity-stat,
.main-snooker-stats-wrapper .entity-stat-heading,
.main-snooker-stats-wrapper .away-entity-stat {
    width: 33.33%;
}

.main-snooker-stats-wrapper .home-entity-stat,
.main-snooker-stats-wrapper .away-entity-stat {
    font-size: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fullscreen.fallback-fullscreen .in-player-result-board,
:fullscreen .in-player-result-board {
    display: flex;
}

@media screen and (min-height: 450px) {
    .snooker-watch-wrapper {
        --vly-watch-min-height: 100vh;
    }
}

@media screen and (min-width: 680px) {
    .snooker-watch-wrapper {
        --vly-watch-min-width: 100vw;
    }
}

@media screen and (min-width: 980px) {
    .snooker-watch-wrapper .live-central-panel {
        width: calc(100% - 35.5ch - 16px);
    }

    .snooker-watch-wrapper .in-player-result-board {
        font-size: 1.10em;
    }

    .snooker-watch-wrapper .main-scoreboard {
        font-size: 1.8em;
    }

    .snooker-watch-wrapper .live-right-panel {
        display: flex;
    }
}

@media screen and (min-width: 1280px) {
    .snooker-watch-wrapper .main-scoreboard-wrapper {
        width: 100%;
    }
}
</style>