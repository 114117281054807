<template>
    <nav class="user-nav">
        <div class="user-nav-side">
            <div class="sportzone-logo">
                <router-link :to="{ name: 'User Dashboard' }">
                    <img src="/sportzone-logo.svg" alt="Sportzone Logo" />
                    <img src="/sportzone-large-logo.jpg" alt="Sportzone Logo" v-show="false" />
                </router-link>
            </div>
            <div class="user-nav-menu-items">
                <div class="user-nav-menu-item">
                    <router-link :to="{ name: 'User Dashboard' }">
                        <div class="menu-item-icon">
                            <img src="/rounded-light-blue-triangle.svg" alt="Sportzone Logo" />
                        </div>
                        <div class="menu-item-title">
                            Dashboard
                        </div>
                    </router-link>
                </div>
                <div class="user-nav-menu-item">
                    <router-link :to="{ name: 'User Organizations' }">
                        <div class="menu-item-icon">
                            <img src="/rounded-light-blue-triangle.svg" alt="Sportzone Logo" />
                        </div>
                        <div class="menu-item-title">
                            Organizations
                        </div>
                    </router-link>
                </div>
                <div class="user-nav-menu-item">
                    <router-link :to="{ name: 'User Calendar' }">
                        <div class="menu-item-icon">
                            <img src="/rounded-light-blue-triangle.svg" alt="Sportzone Logo" />
                        </div>
                        <div class="menu-item-title">
                            Calendar
                        </div>
                    </router-link>
                </div>
                <div class="user-nav-menu-item">
                    <router-link :to="{ name: 'User Livestreams Dashboard' }">
                        <div class="menu-item-icon">
                            <img src="/rounded-light-blue-triangle.svg" alt="Sportzone Logo" />
                        </div>
                        <div class="menu-item-title">
                            Streams
                        </div>
                    </router-link>
                </div>
                <div class="user-nav-menu-item">
                    <router-link :to="{ name: 'User Livestream' }">
                        <div class="menu-item-icon">
                            <img src="/rounded-light-blue-triangle.svg" alt="Sportzone Logo" />
                        </div>
                        <div class="menu-item-title">
                            Gallery
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="user-nav-top">
            <div class="page-heading">{{pageHeading}}</div>
            <div class="user-nav-notifications-section">
                <div @click="toggleNotifications" class="notification-toggle-wrapper">
                    <!-- <img src="/notification-icon.svg" alt="Notifications" /> -->
                    <svg width="22" height="22" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M27.4118 25.3038L25.2643 21.7263C24.2743 20.075 23.7505 18.185 23.7505 16.2588V13.125C23.7505 8.30125 19.8243 4.375 15.0005 4.375C10.1768 4.375 6.25057 8.30125 6.25057 13.125V16.2588C6.25057 18.185 5.7268 20.075 4.7368 21.7263L2.58928 25.3038C2.47303 25.4962 2.47051 25.7375 2.58055 25.9325C2.69182 26.1288 2.90053 26.25 3.12553 26.25H26.8755C27.1005 26.25 27.3093 26.1288 27.4205 25.9325C27.5305 25.7375 27.5281 25.4962 27.4118 25.3038ZM4.22932 25L5.80807 22.3687C6.91555 20.5237 7.50055 18.4113 7.50055 16.2588V13.125C7.50055 8.98873 10.8643 5.62498 15.0005 5.62498C19.1368 5.62498 22.5005 8.98873 22.5005 13.125V16.2588C22.5005 18.4113 23.0855 20.5237 24.1918 22.3687L25.7718 25H4.22932Z" fill="currentColor"/>
                        <path d="M15 0C13.6213 0 12.5 1.12125 12.5 2.50002V5.00004C12.5 5.34498 12.78 5.625 13.125 5.625C13.47 5.625 13.75 5.34498 13.75 4.99998V2.50002C13.75 1.81002 14.31 1.25004 15 1.25004C15.69 1.25004 16.25 1.81002 16.25 2.50002V5.00004C16.25 5.34498 16.53 5.625 16.875 5.625C17.22 5.625 17.5001 5.34498 17.5001 4.99998V2.50002C17.5001 1.12125 16.3788 0 15 0Z" fill="currentColor"/>
                        <path d="M17.705 25.309C17.5288 25.0115 17.1475 24.914 16.8488 25.0852C16.55 25.2602 16.45 25.644 16.625 25.9414C16.7875 26.2177 16.8763 26.5489 16.8763 26.8752C16.8763 27.909 16.035 28.7502 15.0013 28.7502C13.9675 28.7502 13.1263 27.909 13.1263 26.8752C13.1263 26.5489 13.215 26.2177 13.3775 25.9414C13.5512 25.6427 13.4513 25.2602 13.1537 25.0852C12.8525 24.9139 12.4725 25.0114 12.2975 25.309C12.0212 25.7815 11.875 26.3227 11.875 26.8752C11.8751 28.599 13.2763 30.0002 15 30.0002C16.7238 30.0002 18.125 28.599 18.1275 26.8752C18.1275 26.3227 17.9813 25.7815 17.705 25.309Z" fill="currentColor"/>
                    </svg>
                </div>
                <div class="notifications-wrapper" :class="{ 'toggle-notifications': toggleValue }">
                    <div class="notification-item">
                        <div class="notification-item-text">
                            Welcome {{ user.firstName }} {{ user.lastName }}!
                        </div>
                    </div>
                    <div class="notification-item" v-for="notification in getNotifications" :key="notification.id" >
                        <div class="notification-item-text">
                            {{ notification.message }}
                        </div>
                        <div class="notification-item-link">
                            <a :href="notification.link">{{ notification.title }}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="user-profile-section" v-if="user && user.id">
                <input type="radio" id="dropdown-radio-hide" hidden name="user-profile-dropdown-radio"/>
                <input type="radio" id="user-profile-dropdown-radio" hidden name="user-profile-dropdown-radio"/>
                <div class="dropdown">
                    <label for="dropdown-radio-hide" class="dropdown-hide-btn"></label>
                    <router-link :to="{ name: 'Profile' }" class="dropdown-item">
                        My Profile
                    </router-link>
                    <router-link :to="{ name: 'User Preferences' }" class="dropdown-item">
                        My Preferences
                    </router-link>
                    <router-link :to="{ name: 'Organizations' }" class="dropdown-item">
                        Organize
                    </router-link>
                    <router-link :to="{ name: 'User Request'}" class="dropdown-item">
                        Make Request
                    </router-link>
                    <div class="dropdown-item">
                        <a @click.prevent="logoutUser()">Log Out</a>
                    </div>
                </div>
                <label for="user-profile-dropdown-radio">
                    <div class="user-section-icon">
                        <div class="user-section-icon__image">
                            <img :src="`/api/user/${user.id}/picture`" :alt="user.firstName" />
                        </div>
                    </div>
                    <div class="user-section-name">
                        {{ user.firstName }} {{ user.lastName }}
                    </div>
                </label>
                <!-- </router-link> -->
                <div class="user-section-link">

                </div>
            </div>
        </div>
    </nav>
</template>

<script lang="ts">
import { SportzoneRole, User } from '@/models/main/User'
import { defineComponent, onBeforeMount, PropType, ref, Ref } from 'vue'
import NavigationBarItem, { NavigationItem } from './NavigationBarItem.vue'
import { useMsal } from '@/composables/auth/UseMSAL'
import { EndSessionRequest } from '@azure/msal-browser'
import { createNamespacedHelpers, useStore } from 'vuex'
import { key } from '@/store'
const userNotificationStoreHelpers = createNamespacedHelpers('userNotificationStore')
const TheUserNavigationBar = defineComponent({
    data() {
        return {
            publicPath: process.env.BASE_URL,
            logoutNavItem: { title: "Log out", routerLinkName: "/.auth/logout", params: null, iconLink: null, allowedRoles: ["Admin", "Organizer", "Operator", "Player"]} as NavigationItem,
        }
    },
    props: {
        navigationItems: {
            type: Object as PropType<Array<NavigationItem>>,
            required: true
        },
        user: {
          type: Object as PropType<User>,
          required: true
        },
        pageHeading: {
            type: String,
        },
    },
    components: {
        NavigationBarItem
    },
    computed: {
        ...userNotificationStoreHelpers.mapGetters(['getNotifications'])
    },
    setup(props) {
        const defaultProfilePicRelativePath = 'default-profile-pic.svg';
        const { instance } = useMsal();
        const logoutUser = () => {
            let request: EndSessionRequest = {
                postLogoutRedirectUri: "/"
            }
            instance.logoutRedirect(request);
        }

        const preLoadImages = () => {
            const imagesToLoad = [ 
                '/rounded-light-blue-triangle.svg', 
                '/rounded-deep-blue-triangle.svg',
                '/sportzone_deep_blue_logo.svg' ]
            for(let i = 0; i < imagesToLoad.length; i++) {
                const img = new Image();
                img.src = imagesToLoad[i];
            }
        }

        onBeforeMount(preLoadImages);
        const navigationItemIsAllowedToUser = (allowedRoles: SportzoneRole[]) : boolean => {
            if(allowedRoles.length === 0) {
                return true // empty allowedRoles means everybody,
            }
            if(props.user && allowedRoles.some(r => props.user.userRoles.includes(r))) { // user has at least one of the allowedRoles
                return true
            }

            return false
        }

        const expandMenu = ref(false)
        const menuToggle = () => {
            expandMenu.value = !expandMenu.value
        }
        const store = useStore(key)
        const loadNotifications = async () => {
            await store.dispatch('userNotificationStore/listNotifications')
        }
        const toggleValue: Ref<boolean> = ref(false)
        const toggleNotifications = () => {
            if (toggleValue.value) {
                toggleValue.value = false
            } else {
                toggleValue.value = true
            }
        }
        loadNotifications()
        return {
            defaultProfilePicRelativePath,
            expandMenu,
        logoutUser,
        navigationItemIsAllowedToUser,
        menuToggle,
            store,
            toggleValue,
            toggleNotifications
        }
    }
})
export default TheUserNavigationBar
</script>

<style>
.user-nav {
  --nav-side-bg-color: var(--main-font-clr);
  --nav-top-bg-color: var(--bright-font-clr);
  --nav-top-color: var(--main-font-clr);
  --nav-side-color: var(--background-clr);
  --nav-el-hover-bg-color: var(--background-clr);
}

.subpages-wrapper .user-nav {
  --nav-el-hover-bg-color: var(--bright-font-clr);
}

.user-nav-side {
    position: fixed;
    width: var(--user-nav-side-width);
    left: calc((100% - var(--max-width)) / 2);
    background-color: var(--nav-side-bg-color);
    height: 100vh;
    z-index: 5;
    color: var(--nav-side-color);
}
.menu-item-icon img {
    max-width: 100%;
}
.menu-item-icon {
    width: 3em;
    display: none;
    justify-content: center;
    align-items: center;
}
.user-nav-menu-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 3em;
  padding-left: 1em;
  gap: 1em;
}
.user-nav-menu-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0.5em 1em;
    border-radius: var(--default-border-rad) 0 0 var(--default-border-rad);
    --edge-radius: 1.5em;
    --edge-shadow-offset: calc(var(--edge-radius) * 0.207 )
}
.user-nav-menu-item:hover, .user-nav-menu-item:has(.router-link-active) {
    background-color: var(--nav-el-hover-bg-color);
    color: var(--main-font-clr);
    position: relative;
}
.user-nav-menu-item a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    color: inherit;
}

.user-nav-menu-item:hover::before, .user-nav-menu-item:hover::after, 
.user-nav-menu-item:has(.router-link-active)::before, .user-nav-menu-item:has(.router-link-active)::after {
    content: '';
    width: var(--edge-radius);
    height: var(--edge-radius);
    position: absolute;
    right: 0;
}
.user-nav-menu-item::before {
    top: calc(var(--edge-radius) * -1);
    box-shadow: var(--edge-shadow-offset) var(--edge-shadow-offset) 0 var(--edge-shadow-offset) var(--nav-el-hover-bg-color);
    border-radius: 0 0 var(--default-border-rad) 0;
}
.user-nav-menu-item::after {
    bottom: calc(var(--edge-radius) * -1);
    box-shadow: var(--edge-shadow-offset) calc(var(--edge-shadow-offset) * -1) 0 var(--edge-shadow-offset) var(--nav-el-hover-bg-color);
    border-radius: 0 var(--default-border-rad) 0 0;
}

.menu-item-title {
    text-align: left;
    font-weight: 500;
    font-size: 1.333em;
}

.user-nav-top {
    --nav-height: 4.5rem;
    --user-profile-section-width: min(14rem, 22vw);
    position: fixed;
    left: calc((100% - var(--max-width)) / 2);
    width: calc(var(--max-width) - var(--user-nav-side-width));
    height: var(--nav-height);
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    background-color: var(--nav-top-bg-color);
    z-index: 4;
    padding: 0.25em 0;
    border-bottom-right-radius: var(--default-border-rad);
    padding-inline: 1.5rem;
    margin-left: var(--user-nav-side-width);
    border-left: 1px solid var(--background-clr);
    color: var(--nav-top-color);
}

.notifications-wrapper {
    display: none;
    position: absolute;
    top: 5rem;
    right: 0;
    width: calc(12em + 3em);
    background-color: var(--darker-font-clr);
    color: var(--bright-font-clr);
    padding: 2em 1.5em;
    flex-direction: column;
    gap: 0.5rem;
    border-radius: var(--default-border-rad);
}

.toggle-notifications {
    display: flex;
}

.notification-toggle-wrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0.25rem;
    justify-content: center;
}

.notification-toggle-wrapper:hover,.notification-toggle-wrapper:focus {
  background-color: var(--background-clr);
  border-radius: 100vw;
  color: var(--main-font-clr);
}

.notification-toggle-wrapper > img {
  max-height: 1.5em;
}

.user-profile-section {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    --name-height: var(--nav-contour-height);
    height: 100%;
    width: var(--user-profile-section-width);
}

.user-section-icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - var(--name-height));
}

.user-section-link {
    cursor: pointer;
}

.user-nav-notifications-section {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notification-item {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
}

.user-section-name {
    position: relative;
    display: none;
    justify-content: center;
    align-items: flex-end;
    height: var(--name-height);
    z-index: 2;
    font-weight: 500;
}

.dropdown {
    display: none;
    position: absolute;
    top: calc(var(--nav-height) + 0.5rem);
    right: 2em;
    width: 12em;
    flex-direction: column;
    background-color: var(--nav-side-bg-color, var(--main-font-clr));
    border-radius: var(--default-border-rad);
    padding-block: 1.5em;
    overflow: hidden;
    color: var(--nav-side-color, var(--bright-font-clr));
}

.user-profile-section > label {
  height: 100%;
}

.user-profile-section label {
  cursor: pointer;
}

.user-nav .user-profile-section > label {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  gap: 1em;
}

#user-profile-dropdown-radio:checked + .dropdown {
    display: flex;
}


.dropdown-item {
    padding: 0.5em 1.5em;
    color: inherit;
    display: flex;
    align-items: center;
}
.dropdown-item:hover {
    background-color: var(--bright-font-clr);
    color: var(--main-font-clr);
}

.dropdown-item a {
    color: inherit;
    cursor: pointer;
}

.dropdown-hide-btn {
    position: absolute;
    top: 0.25em;
    right: 0.25em;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(/transparent-cross.svg);
    background-size: contain;
    background-repeat: no-repeat;
}

.org-dashboard-wrapper .user-profile-section {
  font-size: 1.5em;
}

@media  screen and (min-width: 680px) {

}

@media  screen and (min-width: 875px) {
    .user-section-name {
        display: flex;
    }
}

@media  screen and (min-width: 1000px) {

}

</style>