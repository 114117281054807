<template>
    <ThePublicHomeLayout>
        <div class="badminton-score">
            <div class="container-title">{{getCurrentContainer?.title}}</div>
            <div class="score-form" :class="{'disabled': formIsDisabled}">
                <div class="break-controls">
                </div>
                <div class="match-controls">
                    <button @click="onReverseClick" class="btn delete-btn" type="button" title="Undo" :disabled="formIsDisabled">Undo</button>
                </div>
                <div class="match-scoreboard">
                    <div class="home-team">
                        <div class="main-scoreboard">
                            <div class="scoreboard-top">
                                <div class="team-name default-box">{{getCurrentHomeEntity?.name}}</div>
                                <ActionBox :isDisabled="formIsDisabled" class="point-box" @click="onHomePointBoxClick">{{getCurrentHomeEntityPoints}}</ActionBox>
                            </div>
                            <div class="scoreboard-bottom">
                                <ActionBox :isDisabled="formIsDisabled" @click="onGameBoxClick(getCurrentHomeEntity?.id)" style="float:left;">{{currentHomeEntityGamePoints}}</ActionBox>
                            </div>
                            <div class="home-point-types">
                                <PointTypesSet @click="onHomePointBoxClick" :isDisabled="formIsDisabled" />
                            </div>
                        </div>
                    </div>
                    <div class="splitter">:</div>
                    <div class="away-team">
                        <div class="main-scoreboard">
                            <div class="scoreboard-top">
                                <div class="team-name default-box">{{getCurrentAwayEntity?.name}}</div>
                                <ActionBox :isDisabled="formIsDisabled" class="point-box" @click="onAwayPointBoxClick">{{getCurrentAwayEntityPoints}}</ActionBox>
                            </div>
                            <div class="scoreboard-bottom">
                                <ActionBox :isDisabled="formIsDisabled" @click="onGameBoxClick(getCurrentAwayEntity?.id)" style="float:right;">{{currentAwayEntityGamePoints}}</ActionBox>
                            </div>
                            <div class="away-point-types">
                                <PointTypesSet @click="onAwayPointBoxClick" :isDisabled="formIsDisabled" />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ThePublicHomeLayout>
    </template>
    <script lang="ts">
    import ThePublicHomeLayout from '@/components/main/ThePublicHomeLayout.vue' 
    import ActionBox from '@/components/volleyball/ActionBox.vue'
    import { useStore, createNamespacedHelpers} from 'vuex'
    import { defineComponent, onMounted, ref, Ref } from 'vue'
    import { key } from '@/store'
    import { Container } from '@/models/main/Container'
    import { useRoute } from 'vue-router'
    import { Event, EventType } from '@/models/main/Event';
    import { BadmintonGameControlType } from '@/composables/badminton/BadmintonEventTypes'
    import useCurrentContainerComputeds  from '@/composables/badminton/UseCurrentContainerComputeds'
    import Modal from '@/components/main/Modal.vue';
    import { copyToClipboard } from '@/composables/common/Util';
    import PointTypesSet from '@/components/badminton/PointTypesSet.vue';
    const { mapGetters } = createNamespacedHelpers('containerStore')
    
    export default defineComponent({
        computed: {
            ...mapGetters([
                'getContainer',
                'getHomeEntity',
                'getAwayEntity',
            ]),
        },
        setup() {
            const formIsDisabled: Ref<boolean> = ref(false)
            const store = useStore(key);
            const route = useRoute()
            const matchId: Ref<number> = ref(parseInt(route.params.id as string));
            const match: Ref<Container | undefined> = ref()
            let accessKey: string | undefined = route.query?.accessKey as string;
            const shareLink: Ref<string | null> = ref(null);
            const infoPopUp: Ref<any> = ref(null);
            const getMatch = async() => {
                match.value = await store.dispatch('containerStore/getPublicContainer', matchId.value)
            }
            const onHomePointBoxClick = async (pointTypeValue: string) => {
                if(getCurrentHomeEntity.value?.id !== undefined) {
                    const addedOk = await addEvent("Point", getCurrentHomeEntity.value.id, pointTypeValue, undefined)
                }
            }
            const onAwayPointBoxClick = async (pointTypeValue: string) => {
                if(getCurrentAwayEntity.value?.id !== undefined) {
                    const addedOk = await addEvent("Point", getCurrentAwayEntity.value.id, pointTypeValue, undefined)
                }
            }
            const onReverseClick = async () => {
                if(match.value !== undefined) {
                    formIsDisabled.value = true
                    const result  = await store.dispatch('containerStore/removeLastEventAsGuest', {
                        containerId: match.value.id,
                        accessKey,
                    })
                    formIsDisabled.value = false
                    return result
                }
            }
            const onGameBoxClick = async (entityId: number) => {
                const gameFinished: BadmintonGameControlType = "Bdm_G"
                await addEvent("GameControl", entityId, gameFinished)
            }
    
            const { 
                calculateEntityPoints,
                calculateEntityGames,
                getCurrentContainer,
                getCurrentHomeEntity,
                getCurrentAwayEntity,
                getCurrentHomeEntityPoints,
                getCurrentAwayEntityPoints,
                currentHomeEntityGamePoints,
                currentAwayEntityGamePoints
            } = useCurrentContainerComputeds(store, matchId)
    
            const addEvent = async (type: EventType, entityId: number, description: string, playerId?: number): Promise<boolean> => {
                if(getCurrentHomeEntity.value && getCurrentHomeEntity.value && match.value !== undefined && match.value .id !== null) {
                    const currentTimeStamp = new Date()
                    const event: Event = {
                        type: type,
                        entityId: entityId,
                        playerId: playerId,
                        description: description,
                        containerId: match.value?.id,
                        startTimestamp: currentTimeStamp,
                        endTimestamp: currentTimeStamp
                    }
    
                    formIsDisabled.value = true
                    const result = await store.dispatch('containerStore/storeEventAsGuest', {
                        event,
                        accessKey,
                    });
                    formIsDisabled.value = false
                    return result
                }
    
                return false
            }
    
            onMounted(getMatch);
    
            const homeTimeouts: Ref<string[]> = ref([])
            const awayTimeouts: Ref<string[]> = ref([])
    
            return {
                matchId,
                match,
                homeTimeouts,
                awayTimeouts,
                calculateEntityPoints,
                calculateEntityGames,
                getCurrentContainer,
                getCurrentHomeEntity,
                getCurrentAwayEntity,
                getCurrentHomeEntityPoints,
                getCurrentAwayEntityPoints,
                currentHomeEntityGamePoints, 
                currentAwayEntityGamePoints,
                onHomePointBoxClick,
                onAwayPointBoxClick,
                onReverseClick,
                onGameBoxClick,
                formIsDisabled,
                accessKey,
                shareLink,
                infoPopUp,
                copyToClipboard,
            }
        },
        components: {
            ThePublicHomeLayout,
            ActionBox,
            Modal,
            PointTypesSet,
        }
    })
    </script>
    
    <style>
    /* TABLE TENNIS CUSTOM */

    </style>