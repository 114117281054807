<template>
    <TheOrganizerDashboard>
        <div class="livestream-panel-wrapper" v-if="livestream" >
            <div class="livestream-panel-video">
                <LivePlayer :livestream="livestream" :startPlayingAt="seekPlayerTo" />
            </div>
            <div class="livestream-panel-details">
                <div>
                    <div class="livestream-panel-info-text">Title</div>
                    <div class="livestream-panel-info-value">{{ livestream?.title }}</div>
                </div>
                <div>
                    <div class="livestream-panel-info-text">Sport</div>
                    <div class="livestream-panel-info-value">{{ livestream?.container?.sport }}</div>
                </div>
                <div>
                    <div class="livestream-panel-info-text">Starts at</div>
                    <div class="livestream-panel-info-value">{{ livestream?.startTime }}</div>
                </div>
                <div>
                    <div class="livestream-panel-info-text">Ends at</div>
                    <div class="livestream-panel-info-value">{{ livestream?.endTime }}</div>
                </div>
                <div>
                    <div class="livestream-panel-info-text">Status</div>
                    <div class="livestream-panel-info-value">{{ livestream?.status }}</div>
                </div>
                <div>
                    <div class="livestream-panel-info-text">Livestream ID</div>
                    <div class="livestream-panel-info-value">{{ livestream?.atStreamingPlatformId }}</div>
                </div>
            </div>
            <div class="livestream-panel-button">
                <div v-if="isStart !== null" @click="controlLivestream"
                    :class="[isStart ? 'manage-livestream-item__controls__start btn btn-inverse' : 'manage-livestream-item__controls__stop btn btn-inverse']"
                    :title="(isStart ? 'Start' : 'Stop')">
                    {{ isStart ? 'Start' : 'Stop' }}
                </div>
                <div class="manage-livestream-item__controls_watch">
                    <router-link class="watch-livestream-btn" :to="{ name: getSportWatchViewName(livestream.sport), params: { id: livestream.id },}">
                    {{livestream.status === "Completed" || livestream.status === "Stopping" ? "Watch" : (livestream.status === "Pending" ? "Soon" : "Live")}}
                </router-link>
                </div>
            </div>
        </div>
    </TheOrganizerDashboard>
</template>
<script lang="ts">
import TheOrganizerDashboard from '@/components/main/TheOrganizerDashboard.vue';
import LivePlayer from '@/components/main/LivePlayer.vue';
import { defineComponent, Ref, ref } from 'vue';
import { useStore } from 'vuex';
import { key } from '@/store';
import { useRoute, useRouter } from 'vue-router';
import Livestream from '@/models/main/Livestream';
import { getSportWatchViewName } from '@/composables/common/Util';
export default defineComponent({
    components: {
        TheOrganizerDashboard,
        LivePlayer,
    },
    setup() {
        const isStart: Ref<boolean | null> = ref(null);
        const store = useStore(key);
        const route = useRoute();
        const livestreamId = parseInt(route.params.id as string);
        const livestream: Ref<Livestream | null> = ref(null);
        const seekPlayerTo: Ref<number> = ref(0);
        const router = useRouter();
        const controlLivestream = () => {
            if (isStart.value) {
                store
                    .dispatch('livestreamStore/startLivestream', livestreamId)
                    .then(() => {
                        getCurrentLivestream();
                    });
            }
            else {
                store
                    .dispatch('livestreamStore/stopLivestream', livestreamId)
                    .then(() => {
                        getCurrentLivestream();
                    });
            }
        };
        const getCurrentLivestream = async () => {
            livestream.value = await store.dispatch('livestreamStore/getLivestream', livestreamId);
            if (livestream.value?.status === 'Starting' || livestream.value?.status === 'Streaming') {
                isStart.value = false;
            }
            else {
                isStart.value = true;
            }
        };
        getCurrentLivestream();
        return {
            isStart,
            livestreamId,
            livestream,
            seekPlayerTo,
            router,
            controlLivestream,
            getSportWatchViewName
        };
    },
});
</script>
<style>
.livestream-panel-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}
.livestream-panel-video {
    width: 100%;
    max-width: 720px;
}

.livestream-panel-wrapper .stream-player {
  width: 100%;
  aspect-ratio: 16 / 9;
}

.livestream-panel-details {
  width: 25ch;
  margin-left: 2ch;
}

.livestream-panel-info-text {
    margin-bottom: 0.3em;
}

.livestream-panel-info-value {
    margin-bottom: 2em;
}
.manage-livestream-item__controls_watch {
    display: flex;
}
</style>