<template>
  <div @click="$emit('click')" class="user-item badge-item" :class="{ selected : isSelected }" >
	<div class="badge-item-col user-item-id">
      <div class="badge-info-heading">ID</div>
      <div class="badge-info-value">
        {{ user.id }}
      </div>
    </div>
    <div class="badge-item-col user-item-name">
      <div class="badge-info-heading">Name</div>
      <div class="badge-info-value">
        {{ user.firstName }}{{ user.middleName ? ' ' + user.middleName : ''}} {{ user.lastName }}
      </div>
    </div>
    <div class="badge-item-col user-item-sex">
      <div class="badge-info-heading">Sex</div>
      <div class="badge-info-value">
        {{ user.sex }}
      </div>
    </div>
	<div class="badge-item-col user-item-nat">
      <div class="badge-info-heading">Nationality</div>
      <div class="badge-info-value">
        {{ user.nationality }}
      </div>
    </div>
	<div class="badge-item-col user-item-birthday">
      <div class="badge-info-heading">Birthday</div>
      <div class="badge-info-value" v-if="new Date(user.birthday ?? '0001-01-01') > new Date('0001-01-01')">
        {{ formatDate(user.birthday) }}
      </div>
	  <div class="badge-info-value" v-else>
        Unset
      </div>
    </div>
    <div class="badge-item-col user-item-email">
      <div class="badge-info-heading">Email</div>
      <div class="badge-info-value">
        {{ user.email }}
      </div>
    </div>
	<div class="badge-item-col user-item-edit">
      <button class="btn edit-btn" @click="$emit('edit')">Edit</button>
    </div>
	<div class="badge-item-col user-item-merge">
      <button class="btn btn-inverse" @click="$emit('merge')">Merge</button>
    </div>
	<div class="badge-item-col user-item-delete">
      <button class="btn delete-btn" @click="$emit('delete')">Delete</button>
    </div>
  </div>
</template>

<script lang="ts">
import { formatDate } from "@/composables/common/Util";
import { User } from "@/models/main/User";
import { defineComponent, PropType } from "vue";

const UserItem = defineComponent({
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
    isSelected: {
        type: Boolean,
        required: false,
    }
  },
  emits: ['click', 'edit', 'delete', 'merge'],
  setup() {
	return {
		formatDate
	}
  }
});

export default UserItem;
</script>

<style>

:root {
    --user-primary-info-padding: 0.2em;
    --user-secondary-info-padding: 0.325em;
    --user-item-color: #ececec;
    --user-item-bck-color: #297987;
    --user-item-selected-bck-color: #425659;
    --user-item-margin-bottom: 0.625em;
    --user-primary-info-font-size: 1.2em;
}


.user-item .user-item-name, .user-item .user-item-email {
  width: 18em;
}

.user-item.selected {
  background-color: var(--user-item-selected-bck-color);
}

.user-item > .user-item-sex, .user-item > .user-item-id {
  min-width: 5em;
}

.user-item .user-item-birthday {
  width: 14em;
}
</style>