import axios from "axios";
import { Method } from "axios";

export default class DefaultHttpClient {
    private _serverURI = ''

    get serverURI() {
        return this._serverURI;
    }

    set serverURI(serverURI) {
        this._serverURI = serverURI;
    }

    constructor(serverURI: string) {
        this.serverURI = serverURI;
    }

    _httpRequest(url: string, data: any, methodType: Method) {
        return axios.request({
            data: data,
            url: url,
            method: methodType,
            responseType: "json",

        })
        // return $.ajax({
        //     type: methodType,
        //     url: url,
        //     dataType: "json",
        //     contentType: "json",
        //     data: JSON.stringify(data)
        // });
    }

    send(url: string, data: any, httpMethod: Method) {
        if (!this.serverURI || !url) {
            console.error("There is no URL provided!");
            return false;
            //error
        }

        const requestUrl = this._serverURI + "/api/" + url;
        return this._httpRequest(requestUrl, data, httpMethod);
    }
}