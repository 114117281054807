<template>
    <ThePublicHomeLayout :title="container?.title">
        <h1>{{ container?.title }}</h1>
         <div class="container-views-container">
            <template v-for="containerRankingView in containerRankingViews">
                <router-link :to="{ name: 'Container Public View', params: { id: containerRankingView.id } }">
                    <div class="container-view-card">
                        <div class="container-view-card__title">{{ containerRankingView.title }}</div>
                        <div class="container-view-card__description">{{ containerRankingView.description }}</div>
                        <div class="container-view-card__type-logo">
                            <span>{{ containerRankingView.viewType }}</span>
                            <div class="elimination-example" v-if="containerRankingView.viewType == 'Elimination'">
                                <div class="elimination-example__r1">
                                    <div class="elimination-example__m1"></div>
                                    <div class="elimination-example__m2"></div>
                                </div>
                                <div class="elimination-example__r2">
                                    <div class="elimination-example__m1"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </router-link>
            </template>
         </div>
    </ThePublicHomeLayout>
</template>
<script setup lang="ts">
import TheUserDashboard from '@/components/main/TheUserDashboard.vue'
import ThePublicHomeLayout from '@/components/main/ThePublicHomeLayout.vue'
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { computed, ComputedRef, onMounted, ref, Ref } from 'vue'
import { key } from '@/store';
import { Container } from '@/models/main/Container';

const store = useStore(key);
const route = useRoute()

const containerId: Ref<number> = ref(parseInt(route.params.id as string));
const containerRankingViews: Ref<Array<any>> = ref([])
const container: ComputedRef<Container> = computed(() => {
    return store.getters['containerStore/getContainer'](containerId.value)
})

onMounted(() => {
    store.dispatch('containerStore/getPublicContainer', containerId.value)

    store.dispatch('containerStore/listContainerRankingViews', containerId.value)
    .then((response) => {
        containerRankingViews.value = response
        containerRankingViews.value.sort((a, b) => {
            return (a?.sequence ?? 0) - (b?.sequence ?? 0)
        })
    })
})

</script>
<style>
.container-views-container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: var(--background-clr);
    z-index: 1;
    gap: 2rem;
    padding: 2rem;
    flex-wrap: wrap;
    align-content: flex-start;
}


.container-view-card {
    display: grid;
    width: 20rem;
    aspect-ratio: 1;
    background-color: var(--bright-font-clr, #fff);
    border-radius: var(--default-border-rad);
    color: var(--main-font-clr);
    gap: 1rem;
    padding: 1rem;
    grid-template-rows: 4rem 3rem 1fr;
    transition: ease-in-out all 200ms;
    cursor: pointer;
}

.container-view-card:hover {
  transform: scale(1.1);
}

.container-view-card__title {
    font-size: 1.5rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    text-align: center;
}

.container-view-card__description {
    text-align: center;
}

.elimination-example {
    display: flex;
    gap: 1rem;
    max-width: 10rem;
    width: 100%;
    padding: 1rem;
    background-color: var(--background-clr);
    border-radius: var(--default-border-rad);
}

.elimination-example__m1, .elimination-example__m2 {
    width: 100%;
    height: 1rem;
    border-top: 2px solid var(--main-font-clr);
    border-bottom: 2px solid var(--main-font-clr);
}
.elimination-example__r1 {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    width: 50%;
}
.elimination-example__r2 {
    display: flex;
    align-items: center;
    width: 50%;
}
.container-view-card__type-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
}

</style>