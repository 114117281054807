import { Entity } from "@/models/main/Entity"
import Season from "@/models/main/Season"
import { computed, Ref, ref } from "vue"
import { key } from "@/store";
import { useStore } from "vuex";
import useEntityComposables from "@/composables/generic/UseEntityComposables";

export default function useSeasonComposables(organizationId: Ref<number | undefined>) {
    const store = useStore(key);
    const loadSeasons = async () => {
        if(organizationId.value) {
            await store.dispatch('organizationSeasonStore/fetchOrganizationSeasons', organizationId.value)
        }
    }

    const organizationSeasons = computed(() => {
        const seasons: Array<Season> | undefined = store.getters['organizationSeasonStore/getOrganizationSeasons'](organizationId.value)
        const seasonsMap = new Map<string, Season | null>()
        seasonsMap.set('All Time Season', null)
        if(seasons && seasons.length > 0) {
            seasons.forEach((season: Season) => {
              seasonsMap.set(season.name, season)
            })
        }
        return seasonsMap
    }) 

    const organizationSeasonNames = computed(() => {
        if(organizationSeasons.value) {
            return [...organizationSeasons.value.keys()]
        }

        return []
    })
    const seasonFilter: Ref<string> = ref('')
    const seasonFilterId = computed(() => {
        return seasonFilter.value ? parseInt(seasonFilter.value) : undefined
    })

    return {
        seasonFilterId,
        seasonFilter,
        organizationSeasonNames,
        organizationSeasons,
        loadSeasons,
    }
}