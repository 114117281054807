<template>
<TheOrganizerDashboard>
    <div class="tbl-tenn-score">
        <div class="container-title">{{getCurrentContainer?.title}}</div>
        <div class="score-form" :class="{'disabled': formIsDisabled}">
            <!-- <div class="break-controls">

            </div> -->
            <div class="match-controls">
                <button @click="onOpenMatch" v-show="getCurrentContainer?.status === CONTAINER_STATUSES[0]"
                            class="btn edit-btn" type="button" title="Start Match">Start</button>
                        <button @click="onReopenMatch" v-show="getCurrentContainer?.status === CONTAINER_STATUSES[2]"
                            class="btn edit-btn" type="button" title="Edit Match">Edit</button>
                        <button @click="onCompleteMatch(async () => {})" v-show="getCurrentContainer?.status === CONTAINER_STATUSES[1]" 
                            class="btn accept-btn" type="button" title="Complete Match">Complete</button>
                <button @click="onReverseClick" class="btn delete-btn" type="button" title="Undo" :disabled="formIsDisabled">Undo</button>
                <button @click="onShareLinkButtonClicked" class="btn btn-inverse" type="button" title="Share">Share</button>
                <Modal ref="infoPopUp">
                        <template v-slot:header>
                            <h1>Share</h1>
                        </template>
                        <template v-slot:body>
                            <div class="link-section">
                                {{ shareLink }}
                            </div>
                        </template>
                        <template v-slot:footer>
                            <div>
                                <button @click="infoPopUp.closeModal(), copyToClipboard(shareLink)" class="btn">Copy</button>
                                <button @click="infoPopUp.closeModal()" class="btn btn-inverse">Cancel</button>
                            </div>
                        </template>
                </Modal>
            </div>
            <div class="match-scoreboard">
                <div class="home-team" v-if="getCurrentHomeEntity?.id">
                    <div class="main-scoreboard">
                        <div class="scoreboard-top">
                            <div class="score-form__entity-color">
                                <input type="color" id="home-color" name="home-color" value="#ffffff" />
                                <label for="home-color" aria-hidden="true" v-show="false">Kit color</label>
                            </div>
                            <div class="score-form__entity-name">{{getCurrentHomeEntity?.name}}</div>
                            <ActionBox :isDisabled="formIsDisabled" class="point-box" @click="onHomePointBoxClick">{{getCurrentHomeEntityPoints}}</ActionBox>
                        </div>
                        <div class="scoreboard-bottom">
                            <ActionBox :isDisabled="formIsDisabled" @click="onGameBoxClick(getCurrentHomeEntity?.id)" style="float:left;">{{currentHomeEntityGamePoints}}</ActionBox>
                        </div>
                    </div>
                </div>
                <div class="away-team" v-if="getCurrentAwayEntity?.id">
                    <div class="main-scoreboard">
                        <div class="scoreboard-top">
                            <div class="score-form__entity-color">
                                <input type="color" id="away-color" name="away-color" value="#ffffff" />
                                <label for="away-color" aria-hidden="true" v-show="false">Kit color</label>
                            </div>
                            <div class="score-form__entity-name">{{getCurrentAwayEntity?.name}}</div>
                            <ActionBox :isDisabled="formIsDisabled" class="point-box" @click="onAwayPointBoxClick">{{getCurrentAwayEntityPoints}}</ActionBox>
                        </div>
                        <div class="scoreboard-bottom">
                            <ActionBox :isDisabled="formIsDisabled" @click="onGameBoxClick(getCurrentAwayEntity?.id)" style="float:right;">{{currentAwayEntityGamePoints}}</ActionBox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</TheOrganizerDashboard>
</template>
<script setup lang="ts">
import TheOrganizerDashboard from '@/components/main/TheOrganizerDashboard.vue' 
import ActionBox from '@/components/volleyball/ActionBox.vue'
import { useStore, createNamespacedHelpers} from 'vuex'
import { defineComponent, onMounted, ref, Ref } from 'vue'
import { key } from '@/store'
import { CONTAINER_STATUSES, Container } from '@/models/main/Container'
import { useRoute } from 'vue-router'
import { Event, EventType } from '@/models/main/Event';
import { TableTennisGameControlType } from '@/composables/tableTennis/TableTennisEventTypes'
import useTableTennisContainerComputeds  from '@/composables/tableTennis/UseCurrentContainerComputeds'
import Modal from '@/components/main/Modal.vue';
import { copyToClipboard, addAccessKeyQueryParam } from '@/composables/common/Util';

const store = useStore(key);
const route = useRoute()
const matchId: Ref<number> = ref(parseInt(route.params.id as string));
const match: Ref<Container | undefined> = ref()

const { 
    getCurrentContainer,
    getCurrentHomeEntity,
    getCurrentAwayEntity,
    formIsDisabled,
    onOpenMatch,
    onCompleteMatch,
    onReopenMatch,
    loadContainer,
    onShareLinkButtonClicked,
    accessKey,
    infoPopUp,
    shareLink,
    offlineDataDownloadLinkUri,
    onReverseClick,
    createEvent,
    addEvent,
    editEvent,
    onEventEdit,
    removeEvent,
    onHomePointBoxClick,
    onAwayPointBoxClick,
    toggleHomePlayerSelect,
    toggleAwayPlayerSelect,
    selectedHomePlayerId,
    selectedAwayPlayerId,
    selectedEventToEdit,
    advancedEditEnabled,
    calculateEntityPoints,
    calculateEntityGames,
    groupEventsByGames,
    getCurrentContainerEventsGroupedByGames,
    onMatchFinishedClick,
    onGameBoxClick,
    getCurrentHomeEntityPoints,
    getCurrentAwayEntityPoints,
    currentHomeEntityGamePoints,
    currentAwayEntityGamePoints,
} = useTableTennisContainerComputeds(matchId)

onMounted(loadContainer);

</script>
<style>
/* GENERIC */
.score-form__entity-name {
    font-weight: 500;
    min-width: 22ch;
    max-width: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1ch;
    font-size: 1.5rem;
}

/* TABLE TENNIS CUSTOM */
.tbl-tenn-score .away-team .scoreboard-bottom {
  justify-content: flex-end;
}

/* */

.tbl-tenn-score {
  width: 100%;
  background-color: var(--background-clr);
}

.tbl-tenn-score .container-title {
  font-size: 1.5em;
  font-weight: 500;
  text-align: center;
}
.away-team .scoreboard-top {
  flex-direction: row-reverse;
}

.match-controls {
    display: flex;
    justify-content: space-between;
}

.home-team, .away-team {
    display: flex;
    flex-direction: column;
    width: calc((100% - var(--splitter-width))/2);
}
.detail-points-board {
    display: flex;
    font-weight: 600;
}

.home-team-competitors, .away-team-competitors , .away-point-types, .home-point-types {
    display: flex;
    flex-direction: column;
}

.away-point-types, .home-point-types {
  width: var(--point-types-width);
  align-items: flex-end;
}


.home-point-types .vly-action-box, .home-team .vly-action-box {
  margin-right: 0;
}

.away-point-types .vly-action-box {
  margin-left: 0;
}

.home-team-competitors, .away-team-competitors {
        width: 4em;
}

.scoreboard-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.scoreboard-top {
    display: flex;
    margin-top: 0.3em;
    margin-bottom: 0.3em;
}

.left-control {
    text-align: left;
}

.right-control {
    text-align: right;
}

.match-controls {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 .75em;
    margin-bottom: .5em;
}

.match-control-btn {
  color: var(--watch-bck-color);
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5ch 1ch;
  border-radius: var(--default-border-rad);
}

.match-control-btn[disabled] {
  cursor: no-drop;
}

.break-controls {
  margin-bottom: 10px;
}

.score-form .vly-action-box > span {
  display: none;
}

.score-form .vly-action-box > .cmp-number {
  display: block;
}

@media screen and (min-width: 1050px) {
    .score-form {
        /* max-width: 55em; */
        --point-types-width: 10em;
    }

    .tbl-tenn-score {
        width: 1000px;
    }
}


@media screen and (min-width: 485px) {
    .score-form .vly-action-box > span {
        display: block;
    }

    .tbl-tenn-score {
        padding: 1em;
    }

    .home-team-competitors, .away-team-competitors {
        width: calc(100% - var(--point-types-width));
    }
}

</style>