import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActionBox = _resolveComponent("ActionBox")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pointTypes, (pointType, index) => {
    return (_openBlock(), _createBlock(_component_ActionBox, {
      isDisabled: _ctx.isDisabled,
      onClick: ($event: any) => (_ctx.$emit('click', index)),
      key: index
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(pointType), 1)
      ]),
      _: 2
    }, 1032, ["isDisabled", "onClick"]))
  }), 128))
}