import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserForm = _resolveComponent("UserForm")!
  const _component_TheUserDashboard = _resolveComponent("TheUserDashboard")!

  return (_openBlock(), _createBlock(_component_TheUserDashboard, {
    title: "Profile",
    onUserLoad: _ctx.onUserLoaded
  }, {
    default: _withCtx(() => [
      _createVNode(_component_UserForm, {
        id: _ctx.currentUser.id,
        "onUpdate:id": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentUser.id) = $event)),
        firstName: _ctx.currentUser.firstName,
        "onUpdate:firstName": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentUser.firstName) = $event)),
        middleName: _ctx.currentUser.middleName,
        "onUpdate:middleName": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.currentUser.middleName) = $event)),
        lastName: _ctx.currentUser.lastName,
        "onUpdate:lastName": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.currentUser.lastName) = $event)),
        sex: _ctx.currentUser.sex,
        "onUpdate:sex": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.currentUser.sex) = $event)),
        email: _ctx.currentUser.email,
        "onUpdate:email": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.currentUser.email) = $event)),
        birthday: _ctx.currentUser.birthday,
        "onUpdate:birthday": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.currentUser.birthday) = $event)),
        nationality: _ctx.currentUser.nationality,
        "onUpdate:nationality": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.currentUser.nationality) = $event)),
        onOnProfileChange: _ctx.onProfilePictureChange
      }, {
        default: _withCtx(() => [
          _createElementVNode("button", {
            class: "profile-button-save",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFormSubmit && _ctx.onFormSubmit(...args)))
          }, "Save")
        ]),
        _: 1
      }, 8, ["id", "firstName", "middleName", "lastName", "sex", "email", "birthday", "nationality", "onOnProfileChange"])
    ]),
    _: 1
  }, 8, ["onUserLoad"]))
}