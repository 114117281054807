<template>
    <div class="user-bar__user_section" v-if="user.id">
        <div class="user-bar__user_section__icon">
            <img :src="`${publicPath}${defaultProfilePicRelativePath}`" alt="Profile"/>
        </div>
        <div class="user-bar__user_section__name">
            <router-link  :to="{name: 'Profile'}">
                {{user.firstName}} {{user.lastName}}
            </router-link>
            
        </div>
        <div class="user-bar__user_section__link">
                <a @click.prevent="logoutUser()">Logout</a>
        </div>
    </div>
</template>

<script lang="ts">
import authModule from '@/main';
import { User } from '@/models/main/User'
import { defineComponent, PropType } from 'vue'
import { useRouter } from 'vue-router';

const TheUserBar = defineComponent({
    data() {
        return {
            defaultProfilePicRelativePath: "default-profile.png",
            publicPath: process.env.BASE_URL
        }
    },
    props: {
        user: {
            type: Object as PropType<User>,
            required: false
        }
    },
    setup() {
      const router = useRouter();
      const logoutUser = () => {
          authModule.logout()
          router.push({name: "Login"})
      }

      return {
        logoutUser
      }
    }
})

export default TheUserBar
</script>

<style>

.admin-section .sportzone-nav {
  position: fixed;
  width: 100%;
    z-index: 4;
}

.admin-section .sportzone-container {
    justify-content: space-between;
}

/* .user-bar {
    width: clamp(320px, 95vw - var(--nav-width) - var(--nav-main-section-margin), var(--max-central-section-width) - var(--nav-width) - var(--nav-main-section-margin));
    height: 100px;
    display: flex;
    justify-content: flex-end;
    background-color: var(--darker-bckgrnd-color);
    color: var(--white-shade-bck-clr);
    position: fixed;
    top: 0;
    left: var(--nav-width);
    z-index: 3;
} */

.user-bar__user_section {
    display: flex;
    align-items: flex-end;
    height: 100%;
    margin-right: calc(1rem + 20px);
    color: var(--white-shade-bck-clr);
    padding-bottom: 2em;
}

.user-bar__user_section__icon {
    width: 2.5rem;
    border-radius: 50%;
    overflow: hidden;
    height: 2.5rem;
    margin-right: 10px;
}

.user-bar__user_section__icon img {
    width: 100%;
}

.user-bar__user_section__name {
 margin-left:10px;
 color:inherit;
 position: relative;
 margin-right: 1rem;
}

.user-bar__user_section__name a {
    color: inherit;
    text-decoration: none;
}

.user-bar__user_section__name::after {
  content: '';
  background: var(--white-shade-bck-clr);
  height: 100%;
  width: 0.1rem;
  position: absolute;
  right: -0.5rem;
  top: 0;
}

.user-bar__user_section__link {
    cursor: pointer;
}

.user-bar__user_section__link a {
    color: var(--white-shade-bck-clr);
}

.user-bar__user_section__link:hover {
    text-decoration: underline;
}

@media  screen and (min-width: 875px) {
    .user-bar {
        left: auto;
    }
}

</style>