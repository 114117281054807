import { PaginatedItemsStore, PagedResponse, PaginatedItemsStoreGetters, PaginatedItemsStoreMutations } from '@/composables/common/Pagination';
import { Container, ContainerStatus, ContainerType } from '@/models/main/Container';
import { Sport } from '@/models/main/CommonTypes';
import { EntityContainerType, EntityContainer } from '@/models/main/EntityContainer';
import { Entity } from '@/models/main/Entity';
import GenericDictionary from '@/components/util/GenericDictionary';
import { Event } from '@/models/main/Event';
import { ActionContext, Module } from 'vuex';
import { IRootState } from '@/store';
import authModule from '@/main';
import { ContainerResult } from '@/models/main/ContainerResult'
import { EntityCollection } from '@/models/main/EntityCollection';
export interface ContainerStore extends PaginatedItemsStore<Container> {
    paginatedItems: PagedResponse<Container>,
    status?: ContainerStatus,
    type?: ContainerType,
    sport?: Sport,
    seasonId?: number,
};
const state: ContainerStore = {
    paginatedItems: { items: {}, pageSize: 15, currentPage: 1, nextPage: 1 },
};
const getters = {
    getEntityContainers: (state: ContainerStore) => (containerId: number) => {
        if (state.paginatedItems.items[containerId]) {
            return state.paginatedItems.items[containerId].entities;
        }
        else {
            console.error('Not found container!');
            return [];
        }
    },
    getContainer: (state: ContainerStore) => (containerId: number) => {
        if (state.paginatedItems.items[containerId]) {
            return state.paginatedItems.items[containerId];
        }
        else {
            return undefined;
        }
    },
    listContainers: (state: ContainerStore) => ({ sort = 'DESC', status, type, sport, organizationId, seasonId }:
        { sort: 'ASC' | 'DESC', status?: ContainerStatus, type?: ContainerType, sport?: Sport, organizationId?: number, seasonId?: number }) => {
        const filteredContainers: Array<Container> = [];
        for (const containerId in state.paginatedItems.items) {
            if (Object.prototype.hasOwnProperty.call(state.paginatedItems.items, containerId)) {
                const container: Container = state.paginatedItems.items[containerId] as Container;
                const statusIsOk = status === undefined || status === 'Status' || container.status === status; // if Status then get all
                const typeIsOk = type === undefined || type === 'Type' || container.type === type; // if Type then get all
                const sportIsOk = sport === undefined || sport === 'Sport' || container.sport === sport; // if Sport then get all
                const organizationIsOk = organizationId === undefined || container.organizationId === organizationId
                const seasonIsOk = seasonId === undefined || container.seasons?.find(s => s.id === seasonId)
                if (statusIsOk && typeIsOk && sportIsOk && organizationIsOk && seasonIsOk) {
                    filteredContainers.push(container);
                }
            }
        }
        const sortedLivestreams = filteredContainers.sort((a, b) => {
            if (a.startTime && b.startTime) {
                const aDate = new Date(a.startTime);
                const bDate = new Date(b.startTime);
                let aDateValue;
                let bDateValue;
                return isFinite(aDateValue = aDate.valueOf()) && isFinite(bDateValue = bDate.valueOf())
                    ? (sort === 'ASC' ? aDateValue - bDateValue : bDateValue - aDateValue)
                    : 0;
            }
            else {
                return 0;
            }
        });
        return sortedLivestreams;
    },
    getEntityByEntityContainerType: (state: ContainerStore, getters: any) => ({ containerId, type }: { containerId: number, type: EntityContainerType }): Entity | undefined => {
        const currentContainer: any = getters.getContainer(containerId);
        if (currentContainer !== undefined) {
            for (const i in currentContainer.entities) {
                if (currentContainer.entities[i].type === type) {
                    if (currentContainer.entities[i].entity !== null) {
                        const currentEntity = currentContainer.entities[i].entity as Entity;
                        return currentEntity;
                    }
                }
            }

            for (const i in currentContainer.entityCollectionFormers) { // fallback
                const entities = currentContainer.entityCollectionFormers[i].entities
                console.log(entities);
                const index = type === 'Home' ? 0 : 1;
                const currentEntity = entities[index] as Entity;
                return currentEntity;
            }
        }
        return undefined;
    },
    getHomeEntity: (state: ContainerStore, getters: any) => (containerId: number): Entity | undefined => {
        const params: { containerId: number, type: EntityContainerType } = { containerId, type: 'Home' };
        return getters.getEntityByEntityContainerType(params);
    },
    getAwayEntity: (state: ContainerStore, getters: any) => (containerId: number): Entity | undefined => {
        const params: { containerId: number, type: EntityContainerType } = { containerId, type: 'Away' };
        return getters.getEntityByEntityContainerType(params);
    },
    getStatusFilter(state: ContainerStore) {
        return state.status;
    },
    getTypeFilter(state: ContainerStore) {
        return state.type;
    },
    getSportFilter(state: ContainerStore) {
        return state.sport;
    },
    getSeasonIdFilter(state: ContainerStore) {
        return state.seasonId;
    },
    ...PaginatedItemsStoreGetters(),
};
const mutations = {
    setContainer(state: ContainerStore, container: Container) {
        if (container.id !== undefined) {
            state.paginatedItems.items[container.id] = container;
        }
    },
    setId(state: ContainerStore, payload: number) {
        state.paginatedItems.items[payload].id = payload;
    },
    setType(state: ContainerStore, { containerId, payload }: { containerId: number, payload: ContainerType }) {
        if (state.paginatedItems.items[containerId]) {
            state.paginatedItems.items[containerId].type = payload;
        }
        else {
            console.error('Tryed to set type to not existing container!');
        }
    },
    setStatus(state: ContainerStore, { containerId, payload }: { containerId: number, payload: ContainerStatus }) {
        if (state.paginatedItems.items[containerId]) {
            state.paginatedItems.items[containerId].status = payload;
        }
        else {
            console.error('Tryed to set status to not existing container!');
        }
    },
    setStartTime(state: ContainerStore, { containerId, payload }: { containerId: number, payload: string }) {
        if (state.paginatedItems.items[containerId]) {
            state.paginatedItems.items[containerId].startTime = payload;
        }
        else {
            console.error('Tryed to set start time to not existing container!');
        }
    },
    setEndTime(state: ContainerStore, { containerId, payload }: { containerId: number, payload: string }) {
        if (state.paginatedItems.items[containerId]) {
            state.paginatedItems.items[containerId].endTime = payload;
        }
        else {
            console.error('Tryed to set end time to not existing container!');
        }
    },
    setSport(state: ContainerStore, { containerId, payload }: { containerId: number, payload: Sport }) {
        if (state.paginatedItems.items[containerId]) {
            state.paginatedItems.items[containerId].sport = payload;
        }
        else {
            console.error('Tryed to set sport to not existing container!');
        }
    },
    setStatusFilter(state: ContainerStore, status: ContainerStatus) {
        state.status = status;
    },
    setTypeFilter(state: ContainerStore, type: ContainerType) {
        state.type = type;
    },
    setSportFilter(state: ContainerStore, sport: Sport) {
        state.sport = sport;
    },
    addEvent(state: ContainerStore, event: Event) {
        console.log(event)
        if (event.id !== undefined && event.containerId !== undefined) {
            if (state.paginatedItems.items[event.containerId]) {
                state.paginatedItems.items[event.containerId].events[event.id] = event;
            }
            else {
                console.error('Tryed to add event to not existing container!');
            }
        }
    },
    removeEvent(state: ContainerStore, { containerId, eventId }: { containerId: number, eventId: number }) {
        if (state.paginatedItems.items[containerId]) {
            for (const i in state.paginatedItems.items[containerId].events) {
                const event = state.paginatedItems.items[containerId].events[i];
                if (event.id === eventId) {
                    delete state.paginatedItems.items[containerId].events[i];
                    return;
                }
            }
            console.error('Could not find the event to delete');
        }
        else {
            console.error('Tryed to remove event from not existing container!');
        }
    },
    setEntityContainers(state: ContainerStore, { containerId, payload }: { containerId: number, payload: GenericDictionary<EntityContainer> }) {
        if (state.paginatedItems.items[containerId]) {
            state.paginatedItems.items[containerId].entities = payload;
        }
        else {
            console.error('Tryed to set entity containers to not existing container!');
        }
    },
    removeContainer(state: ContainerStore, containerId: number) {
        if (Object.prototype.hasOwnProperty.call(state.paginatedItems.items, containerId)) {
            delete state.paginatedItems.items[containerId];
        }
        else {
            console.error('Tryed to delete not existing container!');
        }
    },
    setSeasonIdFilter(state: ContainerStore, seasonId?: number) {
        state.seasonId = seasonId;
    },
    ...PaginatedItemsStoreMutations(),
};
const actions = {
    getContainer({ dispatch }: ActionContext<ContainerStore, IRootState>, containerId: number) {
        return authModule.get('/api/container/' + containerId)
            .then((response) => {
                const container: Container = response.data as unknown as Container;
                dispatch('setContainer', container);
                return container;
            })
            .catch((error) => {
                console.error(error);
            });
    },
    getPublicContainer({ dispatch }: ActionContext<ContainerStore, IRootState>, containerId: number) {
        return authModule.get('/api/container/public/' + containerId)
            .then((response) => {
                const container: Container = response.data as unknown as Container;
                dispatch('setContainer', container);
                return container;
            })
            .catch((error) => {
                console.error(error);
            });
    },
    removeLastEvent({ commit }: ActionContext<ContainerStore, IRootState>, containerId: number) {
        return authModule.delete('/api/event/' + containerId + '/delete')
            .then((response) => {
                commit('containerEventStore/removeEvent', response.data.id, { root: true });
            })
            .catch((error) => {
                console.error(error);
            });
    },
    removeLastEventAsGuest({ commit }: ActionContext<ContainerStore, IRootState>, { containerId, accessKey }: { containerId: number, accessKey: string }) {
        return authModule.post('/api/guest/event/' + containerId + '/delete', { 'containerOperateAccessKey': accessKey })
            .then((response) => {
                commit('containerEventStore/removeEvent', response.data.id, { root: true });
            })
            .catch((error) => {
                console.error(error);
            });
    },
    storeEvent({ commit }: ActionContext<ContainerStore, IRootState>, { event, accessKey }: { event: Event, accessKey?: string | undefined }) {
        return authModule
            .post('/api/event/', { ...event, 'containerOperateAccessKey': accessKey })
            .then((response) => {
                const createdEvent: Event = response.data as unknown as Event;
                if (createdEvent) {
                    commit('containerEventStore/addEvent', createdEvent, { root: true });
                    if (event.id !== undefined) {
                        commit('containerEventStore/removeEvent', event.id, { root: true });// remove the temp one
                    }
                    return true;
                }

                return false;
            })
            .catch((error) => {
                console.error(error);
            });
    },
    storeEventAsGuest({ commit }: ActionContext<ContainerStore, IRootState>, { event, accessKey }: { event: Event, accessKey?: string | undefined }) {
        return authModule
            .post('/api/guest/event/', { ...event, 'containerOperateAccessKey': accessKey })
            .then((response) => {
                const createdEvent: Event = response.data as unknown as Event;
                if (createdEvent) {
                    commit('containerEventStore/addEvent', createdEvent, { root: true });
                    if (event.id !== undefined) { // is negative or zero, thus its temp event
                        commit('containerEventStore/removeEvent', event.id, { root: true });// remove the temp one
                    }
                    return true;
                }

                return false;
            })
            .catch((error) => {
                console.error(error);
            });
    },
    fetchContainers({ commit, dispatch, getters }: ActionContext<ContainerStore, IRootState>,
        { status, type, sport, organizationId, seasonId }: { status: ContainerStatus | null, type: ContainerType | null, sport: Sport | null, organizationId: number | undefined, seasonId: number | undefined }) {
        let filtersChanged = false;
        let pageToRequest = getters['getNextPage'];
        const pageSize = getters['getPageSize'];
        const currentStatus = getters['getStatusFilter'];
        const currentType = getters['getTypeFilter'];
        const currentSport = getters['getSportFilter'];
        const currentSeasonId = getters['getSeasonIdFilter']
        if (currentStatus !== status) {
            commit('setStatusFilter', status);
            filtersChanged = true;
        }
        if (currentType !== type) {
            commit('setTypeFilter', type);
            filtersChanged = true;
        }
        if (currentSport !== sport) {
            commit('setSportFilter', sport);
            filtersChanged = true;
        }
        if(currentSeasonId !== seasonId) {
            commit('setSeasonIdFilter', seasonId);
            filtersChanged = true;
        }
        if (filtersChanged) {
            pageToRequest = 1;
        }
        if (!filtersChanged && getters['getNextPage'] === undefined && getters['getCurrentPage'] > 1) {
            return;
        }
        return authModule.get(`/api/container`, {
            params: {
                status: status,
                type: type == 'Type' ? undefined : type,
                sport: organizationId !== undefined ? undefined : sport, // no need if organizationId is present
                organizationId: organizationId,
                seasonId: seasonId,
                page: pageToRequest,
                pageSize: pageSize,
            },
        })
            .then((response) => {
                const pagedResponse: PagedResponse<Container> = response.data as unknown as PagedResponse<Container>;
                commit('setCurrentPage', pagedResponse.currentPage);
                commit('setPageSize', pagedResponse.pageSize);
                commit('setNextPage', pagedResponse.nextPage);
                commit('setPreviousPage', pagedResponse.previousPage);
                for (const containerIndex in pagedResponse.items) {
                    dispatch('setContainer', pagedResponse.items[containerIndex]);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    },
    createContainer({ dispatch }: ActionContext<ContainerStore, IRootState>,
        { container, homeEntityId, awayEntityId }: { container: Container, homeEntityId?: number, awayEntityId?: number }) {
        return authModule.put(`/api/container`, { homeEntityId, awayEntityId, ...container })
            .then((response) => {
                const container: Container = response.data as unknown as Container;
                dispatch('setContainer', container);
                return container;
            });
    },
    deleteContainer({ commit }: ActionContext<ContainerStore, IRootState>, containerId: number) {
        return authModule.delete(`/api/container/${containerId}`)
            .then((response) => {
                const deletedContainerId: number = response.data as unknown as number;
                commit('removeContainer', deletedContainerId);
            });
    },
    editContainer({ dispatch }: ActionContext<ContainerStore, IRootState>, { container, homeEntityId, awayEntityId }: { container: Container, homeEntityId?: number, awayEntityId?: number }) {
        return authModule.patch(`/api/container/${container.id}`, { homeEntityId, awayEntityId, ...container })
            .then((response) => {
                // const editedContainer: Container = response.data as unknown as Container;
                dispatch('setContainer', container);
                return container;
            });
    },
    setContainer({ commit }: ActionContext<ContainerStore, IRootState>, container: Container) {
        commit('setContainer', container);
        if (container.entities) {
            for (let c in container.entities) {
                if (container.entities[c].entity) {
                    commit('entityStore/addEntity', container.entities[c].entity, { root: true })
                }
            }

            for (let e in container.events) {
                if (container.events[e]) {
                    const eventToAdd = container.events[e]
                    eventToAdd.containerId = container.id
                    commit('containerEventStore/addEvent', eventToAdd, { root: true })
                }
            }
        }
    },
    createOperateAccess({ }: ActionContext<ContainerStore, IRootState>, matchId: number) {
        return authModule
            .post(`/api/container/${matchId}/operationAccess`, { "reason": "" })
            .then((response) => {
                const containerOperateAccessKey: string = response.data as unknown as string;
                return containerOperateAccessKey;
            });
    },
    saveVolleyballContainerResults({ }: ActionContext<ContainerStore, IRootState>, containerResults: any[]) {
        return authModule.post(`/api/container/volleyball/result`, containerResults)
            .then((response) => {
                const result = response.data
                return result
            });
    },
    saveVolleyballGamesResult({ }: ActionContext<ContainerStore, IRootState>, containerGamesResult: any[]) {
        return authModule.post(`/api/container/volleyball/game`, containerGamesResult)
            .then((response) => {
                const result = response.data
                return result
            });
    },
    saveVolleyballPlayersStats({ }: ActionContext<ContainerStore, IRootState>, containerPlayersStats: any[]) {
        return authModule.post(`/api/container/volleyball/player/stats`, containerPlayersStats)
            .then((response) => {
                const result = response.data
                return result
            });
    },
    createContainerRankingView({ }: ActionContext<ContainerStore, IRootState>, {containerId, title, type}: { containerId: number, title: string, type: string}) {
        return authModule
            .post(`/api/container/${containerId}/rankings`, { title, type })
            .then((response) => {
                const containerRankingView = response.data
                return containerRankingView
            })
    },
    getContainerRankingView({ }: ActionContext<ContainerStore, IRootState>, containerRankingViewId: number) {
        return authModule
            .get(`/api/container/rankingView/${containerRankingViewId}`)
            .then((response) => {
                const containerRankingView = response.data
                return containerRankingView
            })
    },
    listContainerRankingViews({ }: ActionContext<ContainerStore, IRootState>, containerId: number) {
        return authModule
            .get(`/api/container/${containerId}/rankingView`)
            .then((response) => {
                const containerRankingViews = response.data
                return containerRankingViews
            })
    },
    updateContainerResults({ }: ActionContext<ContainerStore, IRootState>, containerResults: ContainerResult[]) {
        return authModule
            .put('/api/container/result', containerResults)
            .then((response) => {
                const result = response.data
                return result
            })
    },
    saveContainerInitialResults({  }: ActionContext<ContainerStore, IRootState>, { containerId, containerResults }: { containerId: number, containerResults: any[]}) {
        return authModule.post(`/api/container/${containerId}/result/initial`, containerResults)
            .then((response) => {
                const result = response.data
                return result
            });
    },
    updateEntityCollections({ }: ActionContext<ContainerStore, IRootState>, entityCollections: EntityCollection[]) {
        return authModule
            .put('/api/container/entityCollection', entityCollections)
            .then((response) => {
                const result = response.data
                return result
            })
    },
    uploadLogo({  }: ActionContext<ContainerStore, IRootState>, { containerId, logo }: { containerId: number, logo: File }) {
        return authModule
            .post(`/api/container/${containerId}/logo`, logo, {
                headers: {
                    'Content-Type': logo.type,
                },
            })
    },
};
const containerStore: Module<ContainerStore, IRootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
export default containerStore;