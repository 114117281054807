<template>
    <div ref="watchViewWrapper" class="volleyball-watch-wrapper"
        :class="{ 'fallback-fullscreen': isFallbackFullScreenMode, 'fullscreen': isFullScreenMode }">
        <div v-if="!livestream" class="loading-livestream-icon-div">
            <LoadingIcon />
        </div>
        <div class="live-central-panel">
            <LivePlayer :startPlayingAt="seekPlayerTo" :endPlayingAt="endVideoTime" :livestream="livestream"
                @playerDurationChange="playerDurationChange" @fullscreenToggle="onFullScreenToggle" v-if="livestream"
                :shareClipLength="shareClipLength" @shareButtonClick="onShareClick">
                <div class="result-board_main" v-if="getCurrentHomeEntity && getCurrentAwayEntity">
                    <div class="result-board_main__home-team">
                        <div class="result-board_main__cell result-board_main__team-name">{{
            getCurrentHomeEntity?.name
        }}
                        </div>
                        <div class="result-board_main__cell result-board_main__games_cell ">
                            {{ currentHomeEntityGamePoints }}</div>
                        <div class="result-board_main__cell result-board_main__points_cell">
                            {{ getCurrentHomeEntityPoints }}</div>
                    </div>
                    <div class="result-board_main__away-team">
                        <div class="result-board_main__cell result-board_main__team-name">{{
            getCurrentAwayEntity?.name
        }}
                        </div>
                        <div class="result-board_main__cell result-board_main__games_cell">
                            {{ currentAwayEntityGamePoints }}</div>
                        <div class="result-board_main__cell result-board_main__points_cell">
                            {{ getCurrentAwayEntityPoints }}</div>
                    </div>
                </div>
                <div class="break-pane">
                    <div class="break-pane__ad">
                        <div class="break-pane__add__img_container">
                            <img src="/WASK.jpg" alt="ВАСК" />
                        </div>
                    </div>
                    <div class="break-pane__stats">Statistics</div>
                </div>
            </LivePlayer>
            <div class="main-scoreboard-wrapper hide-on-full" v-if="getCurrentHomeEntity && getCurrentAwayEntity">
                <div class="central-score-section">
                    <div class="home-stats-wrapper">
                        <template
                            v-for="homeEntityPointTypeStat in getCurrentContainerBasicStats?.homeEntity[0].entityStats"
                            :key="homeEntityPointTypeStat.pointType">
                            <div class="stat-row"
                                v-if="VOLLEYBALL_POINT_TITLE_PAIRS[homeEntityPointTypeStat.pointType]">
                                <div class="stat">
                                    <div class="stat-title">
                                        {{ VOLLEYBALL_POINT_TITLE_PAIRS[homeEntityPointTypeStat.pointType] }}</div>
                                    <div class="stat-icon">
                                        <img :src="getPointTypeImageSrc(homeEntityPointTypeStat.pointType)"
                                            :alt="VOLLEYBALL_POINT_TITLE_PAIRS[homeEntityPointTypeStat.pointType]" />
                                    </div>
                                </div>
                                <div class="stat-value game-stat-value">
                                    {{
            getCurrentContainerBasicStats?.homeEntity?.[currentGame]?.entityStats?.[homeEntityPointTypeStat.pointType]?.count
        }}
                                </div>
                                <div class="stat-value match-stat-value">{{ homeEntityPointTypeStat.count }}</div>
                            </div>
                        </template>
                    </div>
                    <div class="main-scoreboard">
                        <div class="home-scoreboard">
                            <div class="scoreboard-top-side">
                                <div class="team-name">{{ getCurrentHomeEntity?.name }}</div>
                                <div class="team-score">{{ getCurrentHomeEntityPoints }}</div>
                            </div>
                            <div class="scoreboard-bottom-side">
                                <div class="team-games-score">{{ currentHomeEntityGamePoints }}</div>
                                <div class="team-timeouts"></div>
                            </div>
                        </div>
                        <div class="main-splitter">:</div>
                        <div class="away-scoreboard">
                            <div class="scoreboard-top-side">
                                <div class="team-name">{{ getCurrentAwayEntity?.name }}</div>
                                <div class="team-score">{{ getCurrentAwayEntityPoints }}</div>
                            </div>
                            <div class="scoreboard-bottom-side">
                                <div class="team-games-score">{{ currentAwayEntityGamePoints }}</div>
                                <div class="team-timeouts"></div>
                            </div>
                        </div>
                    </div>
                    <div class="away-stats-wrapper">
                        <template
                            v-for="awayEntityPointTypeStat in getCurrentContainerBasicStats?.awayEntity[0].entityStats"
                            :key="awayEntityPointTypeStat.pointType">
                            <div class="stat-row"
                                v-if="VOLLEYBALL_POINT_TITLE_PAIRS[awayEntityPointTypeStat.pointType]">
                                <div class="stat">
                                    <div class="stat-title">
                                        {{ VOLLEYBALL_POINT_TITLE_PAIRS[awayEntityPointTypeStat.pointType] }}</div>
                                    <div class="stat-icon">
                                        <img :src="getPointTypeImageSrc(awayEntityPointTypeStat.pointType)"
                                            :alt="VOLLEYBALL_POINT_TITLE_PAIRS[awayEntityPointTypeStat.pointType]" />
                                    </div>
                                </div>
                                <div class="stat-value game-stat-value">
                                    {{
            getCurrentContainerBasicStats?.awayEntity?.[currentGame]?.entityStats?.[awayEntityPointTypeStat.pointType]?.count
        }}
                                </div>
                                <div class="stat-value match-stat-value">{{ awayEntityPointTypeStat.count }}</div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="interactable-info-section" :class="{ 'show-personal-stats': showPersonalStats }">
                    <div class="logos-wrapper">
                        <div class="logo-wrapper">
                            <picture v-if="containerIsOfIVL">
                                <source srcset="/ivl-white-logo.webp" type="image/webp" />
                                <img src="/ivl-white-logo.png" alt="IVL Logo">
                            </picture>
                            <img v-else src="/WASK.jpg" alt="ВАСК Logo">
                        </div>
                        <div class="logo-wrapper">
                            <a :href="sportzoneMainPage"><img src="/sportzone-logo.svg" style="width: 100%;"
                                    alt="Sportzone"></a>
                        </div>
                    </div>
                    <div class="personal-stat-panel">
                        <button @click="togglePinPersonalStats" class="personal-stat-pin-btn"
                            :class="{ 'active': personalStatsPinned }" :title="personalStatsPinned ? 'Unpin' : 'Pin'">
                            <PinIcon />
                        </button>
                        <div class="player-img-section">
                            <div class="player-img-wrapper">
                                <img :src="apiUri + '/api/player/' + selectedPersonalStatPlayer?.id + '/picture'"
                                    onerror="this.src='/default-profile.png';" alt="Player Img" />
                            </div>
                        </div>
                        <div class="player-info-section">
                            <div class="player-name">{{ selectedPersonalStatPlayer?.firstName }}
                                {{ selectedPersonalStatPlayer?.lastName }}</div>
                            <div class="player-secondary-info">
                                <div class="vly-player-details">
                                    <div class="player-detail">
                                        <div class="player-detail-description">Team</div>
                                        <div class="player-detail-value">{{ selectedPersonalStatPlayersEntity?.name }}
                                        </div>
                                    </div>
                                    <div class="player-detail">
                                        <div class="player-detail-description">Number</div>
                                        <div class="player-detail-value">{{ selectedPersonalStatPlayer?.number }}</div>
                                    </div>
                                    <div class="player-detail">
                                        <div class="player-detail-description">Nationality</div>
                                        <div class="player-detail-value">{{ selectedPersonalStatPlayer?.nationality }}
                                        </div>
                                    </div>
                                    <div class="player-detail">
                                        <div class="player-detail-description">Age</div>
                                        <div class="player-detail-value">
                                            {{ calculateAge(selectedPersonalStatPlayer?.birthday) }}</div>
                                    </div>
                                    <!-- <div class="player-detail">
                                    <div class="player-detail-description">Height</div>
                                    <div class="player-detail-value">199</div>
                                </div>
                                <div class="player-detail">
                                    <div class="player-detail-description">Position</div>
                                    <div class="player-detail-value">Captain</div>
                                </div> -->
                                </div>
                                <div class="player-stats">
                                    <template
                                        v-if="selectedPersonalStatPlayersEntity?.id === getCurrentHomeEntity?.id && selectedPersonalStatPlayer?.id"
                                        v-for="palyerPointTypeStat in getCurrentContainerBasicStats?.homeEntity[0].playersStats?.[selectedPersonalStatPlayer?.id]"
                                        :key="palyerPointTypeStat.pointType">
                                        <div class="player-stat"
                                            v-if="VOLLEYBALL_POINT_TITLE_PAIRS[palyerPointTypeStat.pointType]">
                                            <div class="player-stat-description">
                                                {{ VOLLEYBALL_POINT_TITLE_PAIRS[palyerPointTypeStat.pointType] }}</div>
                                            <div class="player-stat-value">{{ palyerPointTypeStat.count }}</div>
                                        </div>
                                    </template>
                                    <template
                                        v-if="selectedPersonalStatPlayersEntity?.id === getCurrentAwayEntity?.id && selectedPersonalStatPlayer?.id"
                                        v-for="palyerPointTypeStat in getCurrentContainerBasicStats?.awayEntity[0].playersStats?.[selectedPersonalStatPlayer?.id]"
                                        :key="palyerPointTypeStat.pointType">
                                        <div class="player-stat"
                                            v-if="VOLLEYBALL_POINT_TITLE_PAIRS[palyerPointTypeStat.pointType]">
                                            <div class="player-stat-description">
                                                {{ VOLLEYBALL_POINT_TITLE_PAIRS[palyerPointTypeStat.pointType] }}</div>
                                            <div class="player-stat-value">{{ palyerPointTypeStat.count }}</div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="test-controls">
            <button @click="toggleShow('break-pane__stats')" title="Show Stats">Toggle Stats</button>
            <button @click="toggleShow('break-pane__ad')" title="Show Ad">Toggle Ad</button>
        </div>
        <h2>{{ getCurrentContainer?.title }}</h2>
        <div class="live-right-panel hide-on-full">
            <div class="events-sec" v-if="getCurrentHomeEntity && getCurrentAwayEntity">
                <div class="info-section">
                    <div id="home-entity-name">{{ getCurrentHomeEntity?.name }}</div>
                    <div id="away-entity-name">{{ getCurrentAwayEntity?.name }}</div>
                    <div @click="onChoseGameClick(i)" class="game-num" v-for="i in getHighestNumGameInContainerEvents"
                        :key="i"
                        :class="{ 'current-game': i === currentGame, 'is-live': getCurrentContainerEventsGroupedByGames[i].isLive }">
                        {{ (i) }}</div>
                </div>
                <div class="game-wrapper" v-for="(gameArray, g) in getCurrentContainerEventsGroupedByGames" :key="g"
                    v-show="currentGame == g">
                    <div class="event-row" v-for="(eventRow, e) in gameArray.events" :key="e"
                        :class="{ 'is-live': eventRow.isLive }" :set="player = getEventPlayer(eventRow.event)"
                        :setEventEntity="eventEntity = getTheEntityThatTheEventBelongsTo(eventRow.event)">
                        <div class="score-box"
                            @click="onScoreBoxClick(eventRow.event, eventRow.homeTeamScore, eventRow.awayTeamScore, g)">
                            <div class="home-point-type vly-point-type">
                                <img v-if="eventEntity?.id === getCurrentHomeEntity?.id"
                                    :src="getPointTypeImageSrc(eventRow.event.description)" alt="Point" />
                            </div>
                            <div class="home-team-score">{{ eventRow.homeTeamScore }}</div>
                            <div class="away-team-score">{{ eventRow.awayTeamScore }}</div>
                            <div class="away-point-type vly-point-type">
                                <img v-if="eventEntity?.id === getCurrentAwayEntity?.id"
                                    :src="getPointTypeImageSrc(eventRow.event.description)" alt="Point" />
                            </div>
                        </div>
                        <div class="event-info-box"
                            @click="displayPersonsalStat(eventRow.event.entityId, eventRow.event.playerId)">
                            <div class="player-num">{{ player?.number }}</div>
                            <div class="player-name"
                                :class="{ 'highlight': selectedPersonalStatPlayer?.id === eventRow.event.playerId }">
                                <div class="player-firstName" v-if="player">{{ player?.firstName.substring(0, 1) }}
                                </div>
                                <div class="player-lastName">{{ player?.lastName }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal ref="infoPopUp">
            <template v-slot:header>
                <h1>Share</h1>
            </template>
            <template v-slot:body>
                <div class="link-section">
                    {{ shareLink }}
                </div>
            </template>
            <template v-slot:footer>
                <div>
                    <button class="btn" v-if="false">Download</button>
                    <button @click="infoPopUp.closeModal(), copyToClipboard(shareLink)" class="btn">Copy</button>
                    <button @click="infoPopUp.closeModal()" class="btn btn-inverse">Cancel</button>
                </div>
            </template>
        </Modal>
    </div>
</template>
<script lang="ts">
import { computed, defineComponent } from "@vue/runtime-core";
import LivePlayer from "@/components/main/LivePlayer.vue";
import Modal from '@/components/main/Modal.vue';
import PinIcon from "@/components/main/PinIcon.vue";
import DataManager from "@/components/util/DataManager"
import { onMounted, Ref, ref, watch } from "vue";
import { ContainerDataModel } from "@/composables/dataModels/ContainerDataModel";
import LiveDataModel from "@/composables/dataModels/LiveDataModel";
import DataModel from "@/models/main/DataModel";
import { useRoute } from "vue-router";
import useVolleyballContainerComputeds from "@/composables/volleyball/UseCurrentContainerComputeds";
import { useStore, createNamespacedHelpers } from "vuex";
import { key } from '@/store'
import { Event } from '@/models/main/Event';
import { Player } from "@/models/main/Player";
import { Entity } from "@/models/main/Entity";
import Tooltip from '@/components/main/Tooltip.vue';
import { TOOLTIP_POSITIONS } from '@/models/main/Tooltip';

import { VolleyballPointType, VOLLEYBALL_POINT_TITLE_PAIRS } from "@/composables/volleyball/VolleyballEventTypes";
import { SportzoneAuth } from "@/components/util/SportzoneAuth";
import { SPORT_TYPES } from "@/models/main/CommonTypes";
import LoadingIcon from '@/components/util/LoadingIcon.vue';

const { mapGetters } = createNamespacedHelpers('containerStore')

export default defineComponent({
    components: {
        LivePlayer,
        PinIcon,
        Modal,
        LoadingIcon,
        Tooltip,
    },
    computed: {
        ...mapGetters([
            'getContainer',
            'getHomeEntity',
            'getAwayEntity',
        ]),
    },
    setup() {
        const route = useRoute()
        const store = useStore(key)
        const onLiveModelChange = (live: LiveDataModel) => {
            if (live.containerId !== container.value?.id) {
                //should change container! unsubscribe previous subscribe to new one
            }
        };

        const chosenGame: Ref<number> = ref(0)
        const onChoseGameClick = (gameNum: number) => {
            chosenGame.value = gameNum
        }

        const containerIsOfIVL = computed(() => {
            if (getCurrentContainer?.value?.organization) {
                const title = getCurrentContainer.value.organization?.name ?? "";
                return title.startsWith("IVL");
            }

            return false;
        })

        const onContainerModelChange = (updatedContainer: ContainerDataModel) => {
            container.value = updatedContainer

            const testContainer: ContainerDataModel = new ContainerDataModel()
            testContainer.id = updatedContainer.id
            testContainer.title = updatedContainer.title
            testContainer.type = updatedContainer.type
            testContainer.status = updatedContainer.status
            testContainer.startTime = updatedContainer.startTime
            testContainer.endTime = updatedContainer.endTime
            testContainer.sport = updatedContainer.sport
            testContainer.events = updatedContainer.events,
                testContainer.livestreams = updatedContainer.livestreams
            testContainer.entities = updatedContainer.entities

            store.dispatch('containerStore/setContainer', testContainer)
        }

        const sportzoneMainPage = "https://sportzone.live"

        const container: Ref<ContainerDataModel | null> = ref(null)
        const currentContainerId: Ref<number> = ref(0)
        const livestream: Ref<LiveDataModel | null> = ref(null)
        const liveId = parseInt(route.params.id as string);
        let startReplayEventId: number | undefined = parseInt(route.query?.startEvent as string)
        let endReplayEventId: number | undefined = parseInt(route.query?.endEvent as string)
        let startVideoTime: number | undefined = parseInt(route.query?.start as string)
        let endVideoTime: number | undefined = parseInt(route.query?.end as string)
        const queryParamPlayerId: number | undefined = parseInt(route.query?.playerId as string)
        const queryParamEntityId: number | undefined = parseInt(route.query?.entityId as string)
        if (isNaN(startVideoTime)) {
            startVideoTime = undefined
        }
        if (isNaN(endVideoTime)) {
            endVideoTime = undefined
        }
        if (startVideoTime !== undefined || isNaN(startReplayEventId)) {
            startReplayEventId = undefined
        }
        if (endVideoTime !== undefined || isNaN(endReplayEventId)) {
            endReplayEventId = undefined
        }

        const isSpoiledModeOn: Ref<boolean> = ref(route.query.unspoiled !== "1")
        const onDataManagerInstanced = async () => {
            console.info("Data manager is ready.")
        };



        let dataManagerInstance: DataManager | null = null
        const initDataManager = async () => {
            dataManagerInstance = DataManager.instance(onDataManagerInstanced);
            if (dataManagerInstance !== null) {
                dataManagerInstance.modelUrl = "https://portal.sportzone.live/api/dataModel";


                const liveDataModelID = DataModel
                    .generateModelID(new LiveDataModel(), { "id": liveId }) //TODO this is bad
                if (liveDataModelID === false) {
                    console.error("Could not generate proper Model ID!")
                    return null;
                }

                await dataManagerInstance.subscribeToDataModel(
                    liveDataModelID,
                    onLiveModelChange,
                    false
                );
                const subscribedLiveDataModel = await dataManagerInstance.dataModels[liveDataModelID]
                livestream.value = subscribedLiveDataModel as LiveDataModel
                if (livestream.value?.containerId) {
                    const containerDataModelId = DataModel
                        .generateModelID(new ContainerDataModel(), { "id": livestream.value.containerId }) //TODO this is bad 
                    if (containerDataModelId === false) {
                        console.error("Could not generate proper Model ID!")
                        return null;
                    }

                    await dataManagerInstance.subscribeToDataModel(
                        containerDataModelId,
                        onContainerModelChange,
                        false
                    );
                    const subscribedContainerDataModel = await dataManagerInstance.dataModels[containerDataModelId]
                    container.value = subscribedContainerDataModel as ContainerDataModel
                    if (container.value.id !== undefined) {
                        //const copiedContainer = deepCopy(subscribedContainerDataModel)
                        await store.dispatch('containerStore/setContainer', subscribedContainerDataModel)
                        currentContainerId.value = container.value.id
                    }
                    if (queryParamEntityId && queryParamPlayerId) {
                        personalStatsPinned.value = true;
                        displayPersonsalStat(queryParamEntityId, queryParamPlayerId)
                    }
                } else {
                    //isFullScreenMode.value = true
                }
            } else {
                console.error("Data manager is not initalized")
            }
        };

        onMounted(initDataManager);

        const toggleShow = (targetClass: string) => {
            const target = document.querySelector('.' + targetClass)
            if (target !== null) {
                target.classList.toggle('show');
                //target.classList.contains('show')
                const pane = document.querySelector('.break-pane')
                if (pane !== null) {
                    pane.classList.toggle('show')
                }
            }

        }

        const playerCurrentUnixTime: Ref<number | undefined> = ref()

        const { calculateEntityPoints,
            calculateEntityGames,
            getCurrentContainerBasicStats,
            getCurrentContainerEventsGroupedByGames,
            getCurrentContainer,
            getCurrentHomeEntity,
            getCurrentAwayEntity,
            getCurrentHomeEntityPoints,
            getCurrentAwayEntityPoints,
            currentHomeEntityGamePoints,
            currentAwayEntityGamePoints,
        } = useVolleyballContainerComputeds(currentContainerId, playerCurrentUnixTime, isSpoiledModeOn)

        const getHighestNumGameInContainerEvents = computed(() => {
            let highestNum = 1
            for (const g in getCurrentContainerEventsGroupedByGames.value) {
                const game = parseInt(g)
                if (highestNum < game) {
                    highestNum = game
                }
            }

            return highestNum
        })

        const getPointTypeImageSrc = (pointType: VolleyballPointType | null): string => {
            switch (pointType) {
                case "Vly_A":
                    return "/vly-ace-white.svg"
                case "Vly_At":
                    return "/vly-attack-white-filled.svg"
                case "Vly_Bl":
                    return "/vly-block-white-filled.svg"
                case null: //undefined point for event entity
                    return "/vly-attack-white-filled.svg"
                case "Vly_Err":
                    return "/vly-opp-err.svg"
                case "Vly_SE":
                    return "/vly-srv-err.svg"
                case "Vly_F":
                    return "/vly-foul.svg"
                default:
                    return "/vly-attack-white-filled.svg"
            }
        }

        const getTheEntityThatTheEventBelongsTo = (event: Event): Entity | undefined => {
            const pointType = event.description as unknown as VolleyballPointType || null
            switch (pointType) {
                case "Vly_A":
                case "Vly_At":
                case "Vly_Bl":
                case null: //undefined point for event entity
                    if (getCurrentHomeEntity.value?.id == event.entityId) {
                        return getCurrentHomeEntity.value
                    } else {
                        return getCurrentAwayEntity.value
                    }
                case "Vly_Err":
                case "Vly_SE":
                case "Vly_F":
                    if (getCurrentHomeEntity.value?.id == event.entityId) {
                        return getCurrentAwayEntity.value
                    } else {
                        return getCurrentHomeEntity.value
                    }
            }
        }

        const getEventPlayer = (event: Event): Player | undefined => {
            if (event.playerId === undefined || event.playerId === null) {
                return undefined
            }

            const eventEntityId = event.entityId
            const eventPlayerId = event.playerId
            return getPlayer(eventEntityId, eventPlayerId)
        }

        const getPlayer = (entityId: number | undefined, playerId: number | undefined) => {
            if (playerId === undefined) {
                return playerId
            }
            if (entityId !== undefined) {
                let currentEntity: Entity | undefined
                switch (entityId) {
                    case getCurrentHomeEntity.value?.id:
                        currentEntity = getCurrentHomeEntity.value
                        break;
                    case getCurrentAwayEntity.value?.id:
                        currentEntity = getCurrentAwayEntity.value
                        break;
                }


                for (const i in currentEntity?.players) {
                    const currentPlayer = currentEntity?.players[i];
                    if (currentPlayer?.id === playerId) {
                        return currentEntity?.players[i]
                    }
                }
            }

            return undefined
        }

        const currentGame = computed(() => {
            if (chosenGame.value !== 0) {
                if (chosenGame.value === getHighestNumGameInContainerEvents.value) { //reset when selected highest game
                    chosenGame.value = 0
                    return getHighestNumGameInContainerEvents.value
                }

                return chosenGame.value
            }

            return getHighestNumGameInContainerEvents.value
        })
        const isFullScreenMode: Ref<boolean> = ref(false)
        const isFallbackFullScreenMode: Ref<boolean> = ref(false)
        const onFullScreenToggle = (isFullScreen: boolean, isFallbackFullScreen: boolean) => {
            isFullScreenMode.value = isFullScreen
            isFallbackFullScreenMode.value = isFallbackFullScreen
        }

        const selectedPersonalStatPlayer: Ref<Player | undefined> = ref()
        const selectedPersonalStatPlayersEntity: Ref<Entity | undefined> = ref()
        const showPersonalStats: Ref<boolean> = ref(false)
        let timeout: any | null = null
        const displayPersonsalStat = (entityId: number | undefined, playerId: number | undefined) => {
            if (getCurrentHomeEntity.value?.id === entityId) {
                selectedPersonalStatPlayersEntity.value = getCurrentHomeEntity.value
            }
            if (getCurrentAwayEntity.value?.id === entityId) {
                selectedPersonalStatPlayersEntity.value = getCurrentAwayEntity.value
            }

            selectedPersonalStatPlayer.value = getPlayer(entityId, playerId)
            showPersonalStats.value = true
            if (timeout !== null) {
                clearTimeout(timeout)
                timeout = null
            }
            if (!personalStatsPinned.value) {
                timeout = setTimeout(() => {
                    showPersonalStats.value = false
                    selectedPersonalStatPlayer.value = undefined
                    timeout = null
                }, 20000)
            }
        }

        const personalStatsPinned: Ref<boolean> = ref(false)
        const togglePinPersonalStats = () => {
            personalStatsPinned.value = !personalStatsPinned.value
            if (personalStatsPinned.value && timeout !== null) {
                clearTimeout(timeout)
                timeout = null
            } else if (!personalStatsPinned.value && timeout === null) {
                timeout = setTimeout(() => {
                    showPersonalStats.value = false
                    selectedPersonalStatPlayer.value = undefined //FIXME Copied from top should not be copied
                    timeout = null
                }, 20000)
            }
        }

        const calculateAge = (birthday: Date | undefined): string => {
            if (!birthday) {
                return "-"
            }

            const today = new Date();
            const birthDate = new Date(birthday);
            let age = today.getFullYear() - birthDate.getFullYear();
            const m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }

            return age.toString();
        }

        const seekPlayerTo: Ref<number> = ref(startVideoTime !== undefined ? startVideoTime : 0)
        const advanceSecs = 8

        const seekPlayer = (event: Event) => {
            if (event.startTimestamp && livestream.value?.streamActualStartTime) {
                const eventStartTime = new Date(event.startTimestamp).getTime() / 1000
                const liveStarttime = new Date(livestream.value.streamActualStartTime).getTime() / 1000

                let shouldSeekTo = eventStartTime - liveStarttime - advanceSecs;
                if (shouldSeekTo < 0) {
                    shouldSeekTo = 0
                }
                seekPlayerTo.value = shouldSeekTo;
            }
        }

        const startReplayEventRef: Ref<Event | null> = ref(null)
        const endReplayEventRef: Ref<Event | null> = ref(null)
        watch(getCurrentContainerEventsGroupedByGames, async (newContainerEventsGroupedByGames) => {
            if ((startReplayEventId === null && endReplayEventId === null) || (startReplayEventRef.value !== null && endReplayEventRef.value !== null)) {
                return;
            }

            for (const g in newContainerEventsGroupedByGames) {
                const events = newContainerEventsGroupedByGames[g].events
                for (const e in events) {
                    const eventRow = events[e]
                    if (eventRow.event.id === startReplayEventId) {
                        chosenGame.value = parseInt(g);
                        startReplayEventRef.value = eventRow.event
                        seekPlayer(startReplayEventRef.value)
                    }
                    if (eventRow.event.id === endReplayEventId) {
                        endReplayEventRef.value = eventRow.event
                    }
                }
            }
        })

        const onScoreBoxClick = (event: Event, homeTeamscore: number, awayTeamScore: number, eventGame: number) => {
            seekPlayer(event)
        }

        const apiUri = SportzoneAuth.apiUri

        const watchViewWrapper: Ref<any> = ref()
        const isFallbackFullScreen: Ref<boolean> = ref(false)
        const goWatchViewFullscreen = () => {
            const currentDoc: any = document
            if (currentDoc.fullscreenElement === undefined &&
                currentDoc.webkitFullscreenElement === undefined &&
                currentDoc.mozFullScreenElement === undefined &&
                currentDoc.msFullscreenElement === undefined) { //fallback for not supported devices
                isFallbackFullScreen.value = true
            }

            if (
                currentDoc.fullscreenElement ||
                currentDoc.webkitFullscreenElement ||
                currentDoc.mozFullScreenElement ||
                currentDoc.msFullscreenElement
            ) {
                if (currentDoc.exitFullscreen) {
                    currentDoc.exitFullscreen();
                } else if (currentDoc.mozCancelFullScreen) {
                    currentDoc.mozCancelFullScreen();
                } else if (currentDoc.webkitExitFullscreen) {
                    currentDoc.webkitExitFullscreen();
                } else if (currentDoc.msExitFullscreen) {
                    currentDoc.msExitFullscreen();
                }
            } else {
                if (watchViewWrapper.value.requestFullscreen) {
                    watchViewWrapper.value.requestFullscreen();
                } else if (watchViewWrapper.value.mozRequestFullScreen) {
                    watchViewWrapper.value.mozRequestFullScreen();
                } else if (watchViewWrapper.value.webkitRequestFullscreen) {
                    watchViewWrapper.value.webkitRequestFullscreen((Element as any).ALLOW_KEYBOARD_INPUT);
                } else if (watchViewWrapper.value.msRequestFullscreen) {
                    watchViewWrapper.value.msRequestFullscreen();
                }
            }
        }


        const playerDurationChange = (currentDuration: number) => {
            let liveStarttime = 0
            if (livestream.value?.streamActualStartTime) {
                liveStarttime = new Date(livestream.value.streamActualStartTime).getTime() / 1000
            }
            if (startShareClipTime.value !== null) {
                shareClipLength.value = currentDuration - (startShareClipTime.value as number);
            }

            if (livestream.value?.status === "Completed") {
                playerCurrentUnixTime.value = currentDuration + liveStarttime - advanceSecs
            }
        }

        const startShareClipTime: Ref<number | null> = ref(null);
        const endShareClipTime: Ref<number | null> = ref(null);
        const shareLink: Ref<string | null> = ref(null);
        const shareClipLength: Ref<number> = ref(0);
        const infoPopUp: Ref<any> = ref(null);
        const onShareClick = (currentPlayerTime: number) => {
            if (livestream.value !== null) {
                if (startShareClipTime.value === null) {
                    startShareClipTime.value = currentPlayerTime
                }
                else {
                    endShareClipTime.value = currentPlayerTime
                    shareLink.value = createShareLink(livestream.value, startShareClipTime.value, endShareClipTime.value);
                    infoPopUp.value.openModal();
                    startShareClipTime.value = null;
                    endShareClipTime.value = null;
                    shareClipLength.value = 0;
                }
            }

        }

        const createShareLink = (livestream: LiveDataModel, startTime: number, endTime: number | null) => {
            let playerEntityParams = '';
            if (selectedPersonalStatPlayer.value) {
                playerEntityParams += '&playerId=' + selectedPersonalStatPlayer.value.id;
                playerEntityParams += '&entityId=' + selectedPersonalStatPlayersEntity.value?.id;
            }
            switch (livestream.container?.sport) {
                case SPORT_TYPES[0]:
                    return window.location.protocol + '//' + window.location.host + '/volleyball/live/' + livestream.id + '?start=' + startTime + '&end=' + endTime + playerEntityParams;
                case SPORT_TYPES[1]:
                    return window.location.protocol + '//' + window.location.host + '/bowling/live/' + livestream.id + '?start=' + startTime + '&end=' + endTime + playerEntityParams;
                case SPORT_TYPES[2]:
                    return window.location.protocol + '//' + window.location.host + '/table-tennis/live/' + livestream.id + '?start=' + startTime + '&end=' + endTime + playerEntityParams;
                default:
                    return window.location.protocol + '//' + window.location.host + '/volleyball/live/' + livestream.id + '?start=' + startTime + '&end=' + endTime + playerEntityParams;
            }
        };

        const copyToClipboard = (link: string | null) => {
            if (link) {
                navigator.clipboard.writeText(link);
            }
        };

        return {
            store,
            livestream,
            container,
            currentContainerId,
            currentGame,
            isFullScreenMode,
            isFallbackFullScreenMode,
            watchViewWrapper,
            shareLink,
            shareClipLength,
            infoPopUp,
            copyToClipboard,
            onShareClick,
            goWatchViewFullscreen,
            playerDurationChange,
            onFullScreenToggle,
            toggleShow,
            calculateEntityPoints,
            calculateEntityGames,
            getEventPlayer,
            getPlayer,
            getPointTypeImageSrc,
            getTheEntityThatTheEventBelongsTo,
            displayPersonsalStat,
            calculateAge,
            onChoseGameClick,
            togglePinPersonalStats,
            onScoreBoxClick,
            containerIsOfIVL,
            endVideoTime,
            seekPlayerTo,
            personalStatsPinned,
            showPersonalStats,
            selectedPersonalStatPlayersEntity,
            selectedPersonalStatPlayer,
            getCurrentContainerBasicStats,
            getCurrentContainerEventsGroupedByGames,
            getHighestNumGameInContainerEvents,
            getCurrentContainer,
            getCurrentHomeEntity,
            getCurrentAwayEntity,
            getCurrentHomeEntityPoints,
            getCurrentAwayEntityPoints,
            currentHomeEntityGamePoints,
            currentAwayEntityGamePoints,
            VOLLEYBALL_POINT_TITLE_PAIRS,
            apiUri,
            sportzoneMainPage,
            TOOLTIP_POSITIONS,
        }
    }
})
</script>
<style>
.volleyball-watch-wrapper {
    --vly-watch-min-width: 640px;
    --vly-watch-min-height: 100dvh;
    --vly-personal-stat-panel-width: 52ch;
    --vly-bottom-results-panel-height: 18ch;
    --vly-player-height: 100%;
    width: var(--vly-watch-min-width);
    height: var(--vly-watch-min-height);
    color: var(--light-homepage-color);
    --player-def-width: 1584px;
    --player-def-height: 891px;
    overflow-y: scroll;
    scrollbar-width: none;
    background-color: var(--darker-bckgrnd-color);
    display: grid;
    grid-template-columns: 1fr 1fr 36ch;
    grid-template-rows: 1fr 1fr 18ch;
    grid-template-areas:
        "central central central"
        "central central central"
        "central central central"
}



.volleyball-watch-wrapper::-webkit-scrollbar {
    display: none;
}



.volleyball-watch-wrapper::after {
    /* background-image: url(/live-background.svg); */
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
}

.live-central-panel {
    grid-area: central;
    /* width: calc(100% - 35.5ch - 16px);
    float: left;
    height: var(--vly-watch-min-height); */
}

.central-score-section {
    display: flex;
    justify-content: center;
    width: auto;
    align-items: center;
    gap: 2ch;
    height: 100%;
    padding-inline: 1ch;
}

.live-right-panel {
    width: calc(35.5ch + 15px);
    float: left;
    height: 100%;
    display: none;
    grid-area: events;
}

/* .volleyball-watch-wrapper.fullscreen  .live-right-panel {
  display: none;
} */


.stream-player,
.events-wrapper,
.test-controls {
    float: left;
}

.test-controls {
    display: none;
}

.volleyball-watch-wrapper .stream-player {
    height: var(--vly-player-height);
    width: 100%;
}

.volleyball-watch-wrapper.fullscreen.fallback-fullscreen .stream-player {
    width: var(--vly-watch-min-width);
    height: var(--vly-watch-min-height);
}

h2 {
    position: relative;
    z-index: 2;
    display: none;
}

/* Personal Stats */

.interactable-info-section,
.personal-stat-panel,
.player-info-section,
.player-secondary-info {
    height: 100%;
    display: flex;
    align-items: center;
}

.player-secondary-info {
    width: 100%;
    font-size: .9em;
    flex-direction: column;
    padding-left: 1ch;
}

.player-img-section {
    width: var(--player-img-section-width);
    max-height: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.player-img-wrapper,
.player-img-wrapper img {
    width: 100%;
}

.player-img-wrapper img {
    height: 100%;
}

.player-img-wrapper {
    margin-top: 0.3em;
    display: flex;
    overflow: hidden;
}

.player-info-section {
    flex-direction: column;
    width: calc(100% - var(--player-img-section-width));
}


.interactable-info-section {
    width: var(--vly-personal-stat-panel-width);
    /* TODO */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 0;
    padding-left: 0.5ch;
    /* min-width: 335px; */
    max-width: 52ch;
}

.personal-stat-panel {
    width: 100%;
    display: none;
    --player-stat-width: 15.5ch;
    --player-img-section-width: 5em;
    overflow: hidden;
    background: #003462;
    padding: 0.5ch;
    border-radius: var(--default-border-rad);
    position: relative;
    margin-top: 1ch;
}

.show-personal-stats .personal-stat-panel {
    display: flex;
}

.show-personal-stats .logos-wrapper {
    display: none;
}

.vly-player-details,
.player-stats {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.personal-stat-panel .player-name {
    height: 2em;
    font-size: 1em;
    width: 100%;
    margin-left: 0;
    padding-left: .6ch;
    font-weight: 600;
    margin-bottom: 1ch;
    margin-left: calc(-1 * var(--player-img-section-width));
}


.vly-player-details {
    width: 100%;
    --detail-description-width: 9ch;
}

.player-stats {
    width: 100%;
    margin-right: .5ch;
}

.player-detail,
.player-stat {
    display: flex;
    padding-top: 0.2ch;
    padding-bottom: 0.2ch;
    width: 100%;
    align-items: center;
    line-height: 1.1em;
}

.player-stat {
    justify-content: flex-start;
    display: none;
}

.player-stat:nth-child(-n+4) {
    display: flex;
}

.player-detail:first-child,
.player-stat:first-child {
    padding-top: 0;
}

.player-detail-description,
.player-detail-value {
    width: 50%;
    text-align: left;
    overflow: hidden;
    line-height: 1.1em;
}

.player-detail-description {
    width: var(--detail-description-width);
}

.player-detail-value {
    width: calc(100% - var(--detail-description-width));
}


.player-stat-description {
    width: calc(100% - 5ch);
    text-align: left;
}

.player-stat-value {
    text-align: right;
    width: 3ch;
}


.player-detail-value,
.player-stat-value {
    padding-left: 1ch;
}

.player-stat-value {
    text-align: right;
}

.personal-stat-pin-btn {
    width: 2.5ch;
    height: 2.5ch;
    position: absolute;
    top: 0.5ch;
    right: 1ch;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

/*Stats End */

.info-section {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    font-size: 0.8rem;
    margin-left: 0.5em;
}

.volleyball-watch-wrapper .info-section>div {
    border: 1px solid var(--light-homepage-color);
    border-radius: var(--default-border-rad);
    width: 6em;
    overflow: hidden;
    word-wrap: break-word;
    max-height: 3ch;
    line-height: 2.75ch;
    padding: 0;
}

.info-section .game-num {
    margin-left: 1ch;
    width: 3ch;
    cursor: pointer;
}

.game-num.is-live::before {
    content: 'L';
    font-size: 0.85em;
    font-weight: 600;
    color: var(--archive-bck-color);
}

.game-num.current-game.is-live::before {
    color: var(--light-homepage-color);
}

.info-section .game-num.current-game {
    background-color: var(--archive-bck-color);
}

#home-entity-name,
#away-entity-name {
    margin-right: 1.8ch;
}

.result-board_main {
    position: absolute;
    top: 7px;
    left: 60px;
    z-index: 9999;
    background-color: var(--deep-blue-bck-color);
    color: white;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    font-size: 1.25em;
}

.result-board_main::before {
    content: '';
    position: absolute;
    top: 0;
    right: -30px;
    bottom: 0;
    left: -50px;
    background: transparent;
}

/* .volleyball-watch-wrapper.fullscreen  .result-board_main {
  display: flex;
} */

.fullscreen.fallback-fullscreen .result-board_main,
:fullscreen .result-board_main {
    display: flex;
}

.result-board_main__home-team,
.result-board_main__away-team {
    display: flex;
    border: 1px solid #cecccc;
    width: auto;
}

.result-board_main__points_cell {
    background-color: var(--archive-bck-color);
    color: var(--light-homepage-color);
    width: 2.5em;
}

.result-board_main__team-name {
    width: calc(100% - 4.5em);
    text-align: left;
    padding-left: 1ch;
    padding-right: 1ch;
    background-color: var(--darker-bckgrnd-color);
}

.result-board_main__games_cell {
    background-color: var(--deep-blue-bck-color);
    width: 2em;
}

.break-pane {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: calc(100% - 10%);
    background-color: rgba(0, 0, 0, 0.80);
    color: white;
    margin-left: 25%;
    margin-top: 30px;
}

.break-pane__ad,
.break-pane__stats {
    width: 100%;
    height: 100%;
}

.break-pane,
.break-pane__ad,
.break-pane__stats {
    display: none;
}



.break-pane.show,
.break-pane__ad.show,
.break-pane__stats.show {
    display: block;
}

.break-pane__add__img_container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

/* EVENTS SECTION */

.events-sec {
    display: flex;
    width: 100%;
    float: left;
    margin-top: 0.8rem;
    flex-direction: column;
    height: calc(100% - .8rem);
}

.game-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    padding-left: 0.5em;
    font-size: 1rem;
    margin-top: .2rem;
    line-height: 1.25rem;
    max-height: calc(var(--vly-player-height) - 1.6rem);
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: var(--archive-bck-color) var(--deep-blue-bck-color);
}




.event-row {
    display: flex;
    width: 100%;
    border-radius: var(--default-border-rad);
    align-items: center;
    padding-top: 0.1ch;
    padding-bottom: 0.1ch;
    position: relative;
}

.event-row.is-live::before {
    content: '';
    background-color: var(--archive-bck-color);
    width: 5px;
    height: 100%;
    position: absolute;
}

.event-row:nth-child(even) {
    background: var(--deep-blue-bck-color);
}

.score-box {
    display: flex;
    margin-right: 0;
    width: 18ch;
    cursor: pointer;
}


.score-box .vly-point-type {
    width: 2ch;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.5ch;
    margin-left: 1.5ch;
}

.vly-point-type img {
    width: 100%;
}

.events-sec .away-team-score {
    width: 2ch;
    text-align: left;
    margin-left: 2ch;
    margin-right: 1ch;
}

.events-sec .home-team-score {
    width: 2ch;
    text-align: right;
    position: relative;
    margin-left: 1ch;
}


.away-point-type.vly-point-type {
    margin-left: 1.5ch;
}

.home-team-score::after {
    content: ':';
    position: absolute;
    left: 2ch;
    width: 2ch;
    text-align: center;
}

.event-info-box {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 17ch;
}

.player-name {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 0.5ch;
    margin-left: 0.5ch;
}

.player-name.highlight {
    background-color: var(--archive-bck-color);
}

.player-num {
    width: 2.5ch;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.player-firstName {
    text-transform: lowercase;
    width: 2ch;
    overflow: hidden;
    text-transform: capitalize;
    margin-right: 0.5ch;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.player-firstName::after {
    content: '.';
    font-size: 1.25rem;
    margin-right: 0.4rem;
    margin-bottom: 0.2ch;
}

.player-lastName {
    display: flex;
    align-items: flex-end;
    width: 11ch;
    overflow: hidden;
}


/* SCOREBOARD */


.main-scoreboard-wrapper {
    --logos-wrapper-width: 62ch;
    --logos-wrapper-width: 40ch;
    display: none;
    float: left;
    width: 100vw;
    margin-top: 0.5ch;
    justify-content: space-between;
    align-items: center;
    height: calc(var(--vly-bottom-results-panel-height) - 0.5ch);
}

.volleyball-watch-wrapper .main-scoreboard-wrapper {
    --team-scoreboard-width: 6rem;
}


.volleyball-watch-wrapper .main-scoreboard {
    display: flex;
    justify-content: center;
    width: calc(100% - (var(--team-scoreboard-width) * 2) - (2ch * 2));
    height: 100%;
    gap: 0.5em;
}



.volleyball-watch-wrapper .team-name {
    color: inherit;
    font-size: 1.1em;
    overflow: hidden;
    width: calc(100% - 2.5ch);
}






.away-scoreboard,
.home-scoreboard {
    width: calc(50% - 1em);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.main-splitter {
    font-size: 1.25em;
    height: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0.5em;
}


.scoreboard-top-side {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: calc(100% - 5.5em);
}

.team-score {
    border: 1px solid var(--light-homepage-color);
    ;
    width: 1.5em;
    height: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--default-border-rad);
    font-size: 1.25em;
    background: var(--archive-bck-color);
}



.away-scoreboard .team-name {
    justify-content: flex-end;
}

.team-games-score {
    width: 1.5em;
    height: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    background-color: var(--deep-blue-bck-color);
    border-radius: var(--default-border-rad);
}

.away-scoreboard .scoreboard-top-side {
    flex-direction: row-reverse;
}

.scoreboard-bottom-side {
    display: flex;
    justify-content: flex-end;
    margin-top: 2ch;
}

.away-scoreboard .scoreboard-bottom-side {
    flex-direction: row-reverse;
}

/* SCOREBOARD STATS */

.home-stats-wrapper,
.away-stats-wrapper {
    width: var(--team-scoreboard-width);
    font-size: 1em;
    height: 100%;
}


.home-stats-wrapper .stat,
.away-stats-wrapper .stat {
    margin-right: 0.5ch;
}

.stat-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0.5ch;
}

.home-stats-wrapper .stat-row {
    justify-content: flex-end;
}

.stat {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0.5ch;
    padding-right: 0.5ch;
    border-radius: var(--default-border-rad);
}

.stat,
.stat-value {
    background-color: var(--deep-blue-bck-color);
}

.stat-icon {
    width: 2ch;
    height: 2ch;
}

.stat-title {
    display: none;
    justify-content: flex-start;
    align-items: center;
    width: 11ch;
}

.stat-value {
    width: 3ch;
    text-align: center;
    border-radius: var(--default-border-rad);
    padding-right: 0.5ch;
    padding-left: 0.5ch;
}

.stat-value.game-stat-value:empty:before {
    content: '0';
}

.stat-value.game-stat-value {
    background-color: var(--archive-bck-color);
    margin-right: 0.5ch;
}

/* LOGOS */

.logos-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}

.logo-wrapper {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    height: 150px;
    width: 150px;
}

.logo-wrapper img {
    width: 100%;
}

/* SHARE MODAL CSS */
.volleyball-watch-wrapper .modal__dialog {
    background-color: var(--darker-bckgrnd-color);
}

.volleyball-watch-wrapper .modal__body {
    min-height: auto;
}

.volleyball-watch-wrapper .modal__footer .btn {
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    font-size: 1em;
}

.volleyball-watch-wrapper .link-section {
    padding: 1em;
    background-color: var(--light-bckgrnd-color);
    border-radius: 5px;
}

/*END */

@media screen and (min-height: 450px) {
    .volleyball-watch-wrapper {
        --vly-watch-min-height: 100vh;
    }
}

@media screen and (min-width: 640px) {
    .volleyball-watch-wrapper {
        --vly-watch-min-width: 100vw;
    }
}

@media screen and (min-width: 999px) {

    /* Mobile View START */
    .main-scoreboard-wrapper {
        display: flex;
    }

    .live-right-panel {
        display: block;
    }

    .volleyball-watch-wrapper {
        --vly-player-height: calc(100% - var(--vly-bottom-results-panel-height));
        grid-template-areas:
        "central central events"
        "central central events"
        "central central events"
    }

    /* Mobile View END */
    .central-score-section {
        width: calc(100% - var(--vly-personal-stat-panel-width));
    }

    .personal-stat-panel .player-name {
        margin-left: 0;
    }

    .player-stats {
        width: var(--player-stat-width);
    }

    .player-secondary-info {
        font-size: 1em;
        flex-direction: row;
    }

    .vly-player-details {
        padding-right: 1ch;
    }


    .player-stat {
        justify-content: flex-end;
    }
}



@media screen and (min-width: 1100px) {
    .stat {
        padding-right: 0.75ch;
        padding-left: 0.75ch;
    }

    .team-score {
        font-size: 1.5em;
        width: 1.5em;
        height: 1.5em;
    }

    .team-games-score {
        font-size: 1.5em;
    }

    .main-splitter {
        font-size: 1.5em;
        height: 1.5em;
    }
}

@media screen and (min-width: 1200px) {

    .stat-title {
        display: flex;
    }

    .personal-stat-panel {
        --player-img-section-width: 6em;
        margin-top: 0;
    }

    .interactable-info-section {
        /* TODO */
        padding-left: 1ch;
    }

    .volleyball-watch-wrapper .main-scoreboard-wrapper {
        --team-scoreboard-width: 12rem;
    }

}

@media screen and (min-width: 1480px) {

    .volleyball-watch-wrapper {
        --vly-bottom-results-panel-height: 23ch;
    }

    .volleyball-watch-wrapper .main-scoreboard-wrapper {
        --team-scoreboard-width: 15rem;
        padding-bottom: 0.5ch;
    }

    .team-score {
        font-size: 2em;
        width: 1.5em;
        height: 1.5em;
    }

    .team-games-score {
        font-size: 2em;
    }

    .main-splitter {
        font-size: 2em;
        height: 1.5em;
    }

    .volleyball-watch-wrapper .team-name {
        font-size: 1.5em;
        width: calc(100% - 1.5em);
    }

    .home-stats-wrapper,
    .away-stats-wrapper {
        font-size: 1.25em;
    }

    .logo-wrapper {
        height: 180px;
        width: 180px;
    }

    .personal-stat-panel .player-name {
        font-size: 1.5em;
    }

    .personal-stat-panel {
        --player-stat-width: 12ch;
        --player-img-section-width: 7em;
    }

    .player-stats {
        width: var(--player-stat-width);
    }

    .vly-player-details {
        width: calc(100% - var(--player-stat-width));
    }
}

@media screen and (min-width: 1800px) {
    .personal-stat-panel .player-name {
        padding-left: 0.9ch;
    }

    .player-secondary-info {
        padding-left: 1.5ch;
    }

    .game-wrapper {
        max-height: calc(var(--vly-watch-min-height) - 2.5em);
    }

    .main-scoreboard-wrapper {
        width: 100%;
        --logos-wrapper-width: 62ch;
    }
}
</style>