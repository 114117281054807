import DataModelMapper from "@/components/util/DataModelMapper";
import GenericDictionary from "@/components/util/GenericDictionary";
import { Sport } from "@/models/main/CommonTypes";
import DataModel from "@/models/main/DataModel";
import { Entity, EntityType } from "@/models/main/Entity";
import PlayerDataModel from "./PlayerDataModel";

export default class EntityDataModel extends DataModel implements Entity {
    public static readonly MODEL_NAME = "En"
    private _id?: number
    private _name?: string
    private _type?: EntityType
    private _sport?: Sport
    private _additionalData?: string
    private _players: GenericDictionary<PlayerDataModel> = {};

    //#region Fields Keys
    static get IDKey() {
        return "id";
    }

    static get nameKey() {
        return "name";
    }

    static get typeKey() {
        return "type";
    }

    static get sportKey() {
        return "sport";
    }

    static get additionalDataKey() {
        return "additionalData";
    }

    static get playersKey() {
        return "players";
    }

    //#endregion

    // #region accessors/mutations 
    get id() {
        return this._id;
    }

    set id(id) {
        this._id = id;
    }

    get name() {
        return this._name;
    }

    set name(name) {
        this._name = name;
    }

    get type() {
        return this._type;
    }

    set type(type) {
        this._type = type;
    }

    get sport() {
        return this._sport;
    }

    set sport(sport) {
        this._sport = sport;
    }

    get additionalData() {
        return this._additionalData;
    }

    set additionalData(additionalData) {
        this._additionalData = additionalData;
    }

    get players() {
        return this._players;
    }

    set players(players) {
        this._players = players;
    }

    //#endregion

    constructor(modelID:string, modelData: any) {
        super();
        this.parseMinifiedData(modelData);
        if(this.id !== undefined) {
            const modelId = DataModel.generateModelID(this, {"id": this.id});
            if(modelId !== false) {
                this.modelID = modelId
            }
        }
    }

    // #region FieldMappings Getters/Setters

    dataModelName() {
        return EntityDataModel.MODEL_NAME
    }

    getKeyByMinifiedKey(minKey: string) {
        switch (minKey) {
            case "1":
                return EntityDataModel.IDKey;
            case "2":
                return EntityDataModel.nameKey;
            case "3":
                return EntityDataModel.typeKey;
            case "4":
                return EntityDataModel.sportKey;
            case "5":
                return EntityDataModel.additionalDataKey;
            case "6":
                return EntityDataModel.playersKey;
            default: 
                return ""
        }
    }

    getProperty(propertyKey: string) {
        switch (propertyKey) {
            case EntityDataModel.IDKey:
                return this.id;
            case EntityDataModel.nameKey:
                return this.name;
            case EntityDataModel.typeKey:
                return this.type;
            case EntityDataModel.sportKey:
                return this.sport;
            case EntityDataModel.additionalDataKey:
                return this.additionalData;
            case EntityDataModel.playersKey:
                return this.players;
        }
    }

    setProperty(propertyKey: string, value: any) {
        let players = null;
        switch (propertyKey) {
            case EntityDataModel.IDKey:
                this.id = value;
                break;
            case EntityDataModel.nameKey:
                this.name = value;
                break;
            case EntityDataModel.typeKey:
                this.type = value;
                break;
            case EntityDataModel.sportKey:
                this.sport = value;
                break;
            case EntityDataModel.additionalDataKey:
                this.additionalData = value;
                break;
            case EntityDataModel.playersKey:
                players = value;
                for (const playerKey in players) {
                    if (Object.prototype.hasOwnProperty.call(players, playerKey)) {
                        const player = players[playerKey];
                        let playerModel = null;
                        if (player instanceof DataModel) {
                            playerModel = player;
                        } else if(player !== null) {
                            playerModel = DataModelMapper.createFromObject(player);
                        }
                        if (playerModel instanceof PlayerDataModel) {
                            this.players[playerKey] = playerModel;
                        } else if (player === null) {
                            delete this.players[playerKey];
                        }
                    }
                }
                break;
        }
    }

    //#endregion
}