<template>
    <TheOrganizerDashboard :onUserLoad="onUserLoaded">
        <template #heading>
            <router-link :to="{ name: 'Organizations' }">
                <div class="page-heading">
                    Organizations
                </div>
            </router-link>
            <router-link :to="{ name: 'Organization Menu', params: { id: organization?.id } }">
                <div class="page-heading">
                    {{ organization?.name }}
                </div>
            </router-link>
            <router-link :to="{ name: 'Sub Organizations', params: { id: organization?.id } }">
                <div class="page-heading">
                    Sub Organizations
                </div>
            </router-link>
        </template>
        <template #default>
            <div class="organizations-wrapper" v-if="organization">
                <div class="sport-organizations">                           
                    <OrganizationBadgeItem class="organization-wrapper" v-for="subOrganization in organization.subOrganizations" :organization="subOrganization" />
                    <div class="organization-add-wrapper" @click="createOrganizationPrompt(organization.sport)">
                        <div class="organization-add-badge">
                            <img src="/plus-add-sign-green.svg" :alt="'Create ' + organization.sport + ' Organization'" />
                        </div>
                    </div>
                </div>
                <StepBasedModal ref="organizationPopup" @onApplyClick="onFormSubmit" :start-step="organizationProcessStep"
            :step-validators="stepValidators" :action-process-total-steps="organizationProcessTotalSteps">
                <template #header>
                    <h1>Create Organization</h1>
                </template>
                <template #popup-step-1>
                    <div class="popup-step">
                            <div class="organization-details">
                                <div class="spz-text-input-group">
                                    <label for="organization_name">Name</label>
                                    <input id="organization_name" v-model="newOrganization.name" type="text" placeholder="Name" required />
                                    <div v-show="!validName" class="spz-form-err-msg">Please provide a Name.</div>
                                </div>
                                <div class="spz-text-input-group">
                                    <label for="dp-organization_established_on">Established on</label>
                                    <VueDatePicker uid="organization_established_on" v-model="newOrganization.establishedOn" time-picker-inline utc="true" />
                                </div>
                            </div>
                        </div>
                </template>
                <template #action-btn >
                    <button class="btn add-btn">Create</button>
                </template>
            </StepBasedModal>
            </div>
        </template>
    </TheOrganizerDashboard>
  </template>
  <script lang="ts">
  import { Ref, defineComponent, ref} from "vue";
import { createNamespacedHelpers, useStore } from "vuex";
import { key } from "@/store";
import TheOrganizerDashboard from "@/components/main/TheOrganizerDashboard.vue";
import BowlingIcon from '@/components/bowling/BowlingIcon.vue'
import VolleyballIcon from '@/components/volleyball/VolleyballIcon.vue'
import TableTennisIcon from '@/components/tableTennis/TableTennisIcon.vue'
import BadmintonIcon from "@/components/badminton/BadmintonIcon.vue"
import TennisIcon from '@/components/tennis/TennisIcon.vue'
import StepBasedModal from '@/components/main/StepBasedModal.vue';
import SportzoneSelect from '@/components/util/SportzoneSelect.vue';
import { useRoute, useRouter } from "vue-router";
import { User } from "@/models/main/User";
import { SPORT_TYPES, Sport } from "@/models/main/CommonTypes";
import { ORGANIZATION_PRIVACIES, Organization } from "@/models/main/Organization";
import moment from "moment";
import { getUserLastSelectedSport } from "@/composables/common/Util";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import OrganizationBadgeItem from "@/components/main/OrganizationBadgeItem.vue";

const organizationStoreHelpers = createNamespacedHelpers('organizationStore');

export default defineComponent({
    components: {
        TheOrganizerDashboard,
        VolleyballIcon,
        TableTennisIcon,
        TennisIcon,
        BadmintonIcon,
        BowlingIcon,
        StepBasedModal,
        VueDatePicker,
        SportzoneSelect,
        OrganizationBadgeItem,
    },
    computed: {
        ...organizationStoreHelpers.mapGetters(
            ['getOrganizationsGroupedBySport'],
        ),
    },
    setup() {
        const route = useRoute();
        const store = useStore(key);
        const organizationId: Ref<number> = ref(parseInt(route.params.id as string));
        const organization: Ref<Organization | undefined> = ref();
        const organizationPopup: Ref<any> = ref(null);
        const initialSportValue = getUserLastSelectedSport() ?? SPORT_TYPES[0]
        const defaultOrganization: Organization = {
            id: 0,
            owner: undefined,
            ownerId: undefined,
            parentOrganization: organization.value,
            name: '',
            dateCreated: moment().toDate(),
            establishedOn: moment().toDate(),
            allowApplication: true,
            autoAcceptApplication: true,
            isSystemGenerated: false,
            sport: initialSportValue,
            subOrganizations: {},
            language: "English",
            acceptSubOrganizationMembers: true,
            subOrganizationsCanAccessMembers: true,
            privacy: ORGANIZATION_PRIVACIES[0]
        };
        const newOrganization: Ref<Organization> = ref(defaultOrganization);
        const validName: Ref<boolean> = ref(true);
        const validEstablishedDate: Ref<boolean> = ref(true)
        const mainDetailsValidator = () => {
            validName.value = newOrganization.value.name !== undefined && newOrganization.value.name?.length > 0
            validEstablishedDate.value = newOrganization.value.establishedOn !== undefined
            return validName.value && validEstablishedDate.value
        }
        const stepValidators: Array<Function> = [mainDetailsValidator, ()=>{ return true}]
        const organizationProcessStep: Ref<number> = ref(1);
        const organizationProcessTotalSteps = ref(1);
    

        const onUserLoaded = (user: User) => {
        }

        const loadOrganizations = async () => {
            //showLoadingIcon.value = true;
            organization.value = await store.dispatch('organizationStore/getOrganization', organizationId.value);
            //await store.dispatch('organizationStore/getOrganization', organizationId.value);

            const organizationsGroupedBySport = store.getters['organizationStore/getOrganizationsGroupedBySport']();
            //showLoadingIcon.value = false;
        };

        const createOrganizationPrompt = (sport: Sport) => {
            newOrganization.value.sport = sport
            newOrganization.value.parentOrganizationId = organization.value?.id;
            organizationPopup.value.openModal()
        }
        loadOrganizations();

        const onFormSubmit = async () => {
            const createdOrganization = await store.dispatch('organizationStore/createOrganization', newOrganization.value);
            console.log(createdOrganization);
        };

        return {
            createOrganizationPrompt,
            onUserLoaded,
            onFormSubmit,
            SPORT_TYPES,
            newOrganization,
            stepValidators,
            validName,
            organizationProcessStep,
            organizationProcessTotalSteps,
            organizationPopup,
            organization
            
        }
    }
})
</script>
<style>

</style>