import DataModel from "@/models/main/DataModel";

export default class PhaseDetailsDataModel extends DataModel {

    public static readonly MODEL_NAME: string = "PhaseDetails"

    private _id?: number;
    private _name?: string;
    private _maxSlots?: number;
    private _status?: string;
    private _sequence?: number;
    private _stageId?: number;
    private _stageName?: string;
    private _stageType?: string;
    private _isMaxSeriesOnly?: number;
    private _maxSeriesGames?: number;
    private _percentagePointsFromScratch?: any;
    private _positionPointsDefinition?: any;
    private _scorePointsDefinition?: any;
    private _tiebreak1?: any;
    private _tiebreak2?: any;
    private _tiebreak3?: any;
    private _eventID?: number;
    private _eventName?: string;
    private _tournamentID?: number;
    private _tournamentName?: string;
    private _tournamentLanguage?: any;
    private _entities?: any;
    private _drops?: any;
    private _series?: any;

    // #region Fields Keys
    static get IDKey() {
        return "id";
    }

    static get nameKey() {
        return "phase_name";
    }

    static get maxSlotsKey() {
        return "max_slots";
    }

    static get statusKey() {
        return "status";
    }

    static get sequenceKey() {
        return "sequence";
    }

    static get stageIDKey() {
        return "stage_id";
    }

    static get stageTypeKey() {
        return "stage_type";
    }

    static get stageNameKey() {
        return "stage_name";
    }

    static get isMaxSeriesOnlyKey() {
        return "max_series_only";
    }

    static get maxSeriesGamesKey() {
        return "max_series_games";
    }

    static get percentagePointsFromScratchKey() {
        return "percentage_points_from_scratch";
    }

    static get positionPointsDefinitionKey() {
        return "position_points_definition";
    }

    static get scorePointsDefinitionKey() {
        return "score_points_definition";
    }

    static get tiebreak1Key() {
        return "tiebreak_1";
    }

    static get tiebreak2Key() {
        return "tiebreak_2";
    }

    static get tiebreak3Key() {
        return "tiebreak_3";
    }

    static get eventIDKey() {
        return "tournament_group_id";
    }

    static get eventNameKey() {
        return "tournament_group_name";
    }

    static get tournamentIDKey() {
        return "tournament_id";
    }

    static get tournamentNameKey() {
        return "tournament_name";
    }

    static get tournamentLanguageKey() {
        return "tournament_language";
    }

    static get entitiesKey() {
        return "phase_entities";
    }

    static get dropsKey() {
        return "phase_drops";
    }

    static get seriesKey() {
        return "series";
    }

    // #endregion

    // #region accessors/mutations 
    get ID() {
        return this._id;
    }

    set ID(ID) {
        this._id = ID;
    }

    get name() {
        return this._name;
    }

    set name(name) {
        this._name = name;
    }

    get maxSlots() {
        return this._maxSlots;
    }

    set maxSlots(maxSlots) {
        this._maxSlots = maxSlots;
    }

    get status() {
        return this._status;
    }

    set status(status) {
        this._status = status;
    }

    get sequence() {
        return this._sequence;
    }

    set sequence(sequence) {
        this._sequence = sequence;
    }

    get stageID() {
        return this._stageId;
    }

    set stageID(stageID) {
        this._stageId = stageID;
    }

    get stageName() {
        return this._stageName;
    }

    set stageName(stageName) {
        this._stageName = stageName;
    }

    get stageType() {
        return this._stageType;
    }

    set stageType(stageType) {
        this._stageType = stageType;
    }

    get isMaxSeriesOnly() {
        return this._isMaxSeriesOnly;
    }

    set isMaxSeriesOnly(isMaxSeriesOnly) {
        this._isMaxSeriesOnly = isMaxSeriesOnly;
    }

    get maxSeriesGames() {
        return this._maxSeriesGames;
    }

    set maxSeriesGames(maxSeriesGames) {
        this._maxSeriesGames = maxSeriesGames;
    }

    get percentagePointsFromScratch() {
        return this._percentagePointsFromScratch;
    }

    set percentagePointsFromScratch(percentagePointsFromScratch) {
        this._percentagePointsFromScratch = percentagePointsFromScratch;
    }

    get positionPointsDefinition() {
        return this._positionPointsDefinition;
    }

    set positionPointsDefinition(positionPointsDefinition) {
        this._positionPointsDefinition = positionPointsDefinition;
    }

    get scorePointsDefinition() {
        return this._scorePointsDefinition;
    }

    set scorePointsDefinition(scorePointsDefinition) {
        this._scorePointsDefinition = scorePointsDefinition;
    }

    get tiebreak1() {
        return this._tiebreak1;
    }

    set tiebreak1(tiebreak1) {
        this._tiebreak1 = tiebreak1;
    }

    get tiebreak2() {
        return this._tiebreak2;
    }

    set tiebreak2(tiebreak2) {
        this._tiebreak2 = tiebreak2;
    }

    get tiebreak3() {
        return this._tiebreak3;
    }

    set tiebreak3(tiebreak3) {
        this._tiebreak3 = tiebreak3;
    }

    get eventID() {
        return this._eventID;
    }

    set eventID(eventID) {
        this._eventID = eventID;
    }

    get eventName() {
        return this._eventName;
    }

    set eventName(eventName) {
        this._eventName = eventName;
    }

    get tournamentID() {
        return this._tournamentID;
    }

    set tournamentID(tournamentID) {
        this._tournamentID = tournamentID;
    }

    get tournamentName() {
        return this._tournamentName;
    }

    set tournamentName(tournamentName) {
        this._tournamentName = tournamentName;
    }

    get tournamentLanguage() {
        return this._tournamentLanguage;
    }

    set tournamentLanguage(tournamentLanguage) {
        this._tournamentLanguage = tournamentLanguage;
    }

    get entities() {
        return this._entities;
    }

    set entities(entities) {
        this._entities = entities;
    }

    get drops() {
        return this._drops;
    }

    set drops(drops) {
        this._drops = drops;
    }

    /**
     *
     * @returns []
     */
    get series() {
        return this._series;
    }

    set series(series) {
        this._series = series;
    }

    // #endregion

    constructor(modelID?: string, modelData?: any) {
        super();
        this.series = {};
        this.parseMinifiedData(modelData);
        if(this.ID !== undefined) {
            const modelId = DataModel.generateModelID(this, {"ID": this.ID});
            if(modelId !== false) {
                this.modelID = modelId
            }
        }
    }

    // #region FieldMappings Getters/Setters
    dataModelName() {
        return PhaseDetailsDataModel.MODEL_NAME
    }

    getKeyByMinifiedKey(minKey: string) {
        switch (minKey) {
            case "0":
                return PhaseDetailsDataModel.IDKey;
            case "1":
                return PhaseDetailsDataModel.nameKey;
            case "2":
                return PhaseDetailsDataModel.maxSlotsKey;
            case "3":
                return PhaseDetailsDataModel.statusKey;
            case "4":
                return PhaseDetailsDataModel.sequenceKey;
            case "5":
                return PhaseDetailsDataModel.stageIDKey;
            case "6":
                return PhaseDetailsDataModel.stageNameKey; //FIXME
            case "7":
                return PhaseDetailsDataModel.stageTypeKey;
            case "8":
                return PhaseDetailsDataModel.isMaxSeriesOnlyKey;
            case "9":
                return PhaseDetailsDataModel.maxSeriesGamesKey;
            case "10":
                return PhaseDetailsDataModel.percentagePointsFromScratchKey;
            case "11":
                return PhaseDetailsDataModel.positionPointsDefinitionKey;
            case "12":
                return PhaseDetailsDataModel.scorePointsDefinitionKey;
            case "13":
                return PhaseDetailsDataModel.tiebreak1Key;
            case "14":
                return PhaseDetailsDataModel.tiebreak2Key;
            case "15":
                return PhaseDetailsDataModel.tiebreak3Key;
            case "16":
                return PhaseDetailsDataModel.eventIDKey;
            case "17":
                return PhaseDetailsDataModel.eventNameKey;
            case "18":
                return PhaseDetailsDataModel.tournamentIDKey;
            case "19":
                return PhaseDetailsDataModel.tournamentNameKey;
            case "20":
                return PhaseDetailsDataModel.tournamentLanguageKey;
            case "21":
                return PhaseDetailsDataModel.entitiesKey;
            case "22":
                return PhaseDetailsDataModel.dropsKey;
            case "23":
                return PhaseDetailsDataModel.seriesKey;
            default:
                return ""
        }
    }

    setProperty(propertyKey: string, value: any) {
        switch (propertyKey) {
            case PhaseDetailsDataModel.IDKey:
                this.ID = parseInt(value);
                break;
            case PhaseDetailsDataModel.nameKey:
                this.name = value;
                break;
            case PhaseDetailsDataModel.maxSlotsKey:
                this.maxSlots = parseInt(value);
                break;
            case PhaseDetailsDataModel.statusKey:
                this.status = value;
                break;
            case PhaseDetailsDataModel.sequenceKey:
                this.sequence = parseInt(value);
                break;
            case PhaseDetailsDataModel.stageIDKey:
                this.stageID = parseInt(value);
                break;
            case PhaseDetailsDataModel.stageNameKey:
                this.stageName = value;
                break;
            case PhaseDetailsDataModel.stageTypeKey:
                this.stageType = value;
                break;
            case PhaseDetailsDataModel.isMaxSeriesOnlyKey:
                this.isMaxSeriesOnly = parseInt(value);
                break;
            case PhaseDetailsDataModel.maxSeriesGamesKey:
                this.maxSeriesGames = parseInt(value);
                break;
            case PhaseDetailsDataModel.percentagePointsFromScratchKey:
                this.percentagePointsFromScratch = parseInt(value);
                break;
            case PhaseDetailsDataModel.positionPointsDefinitionKey:
                this.positionPointsDefinition = value;
                break;
            case PhaseDetailsDataModel.scorePointsDefinitionKey:
                this.scorePointsDefinition = value;
                break;
            case PhaseDetailsDataModel.tiebreak1Key:
                this.tiebreak1 = value;
                break;
            case PhaseDetailsDataModel.tiebreak2Key:
                this.tiebreak2 = value;
                break;
            case PhaseDetailsDataModel.tiebreak3Key:
                this.tiebreak3 = value;
                break;
            case PhaseDetailsDataModel.eventIDKey:
                this.eventID = parseInt(value);
                break;
            case PhaseDetailsDataModel.eventNameKey:
                this.eventName = value;
                break;
            case PhaseDetailsDataModel.tournamentIDKey:
                this.tournamentID = parseInt(value);
                break;
            case PhaseDetailsDataModel.tournamentNameKey:
                this.tournamentName = value;
                break;
            case PhaseDetailsDataModel.tournamentLanguageKey:
                this.tournamentLanguage = value;
                break;
            case PhaseDetailsDataModel.entitiesKey:
                this.entities = value;
                break;
            case PhaseDetailsDataModel.dropsKey:
                this.drops = value;
                break;
            case PhaseDetailsDataModel.seriesKey:
                this.series = value;
                break;
        }
    }

    getProperty(propertyKey: string) {
        switch (propertyKey) {
            case PhaseDetailsDataModel.IDKey:
                return this.ID;
            case PhaseDetailsDataModel.nameKey:
                return this.name;
            case PhaseDetailsDataModel.maxSlotsKey:
                return this.maxSlots;
            case PhaseDetailsDataModel.statusKey:
                return this.status;
            case PhaseDetailsDataModel.sequenceKey:
                return this.sequence;
            case PhaseDetailsDataModel.stageIDKey:
                return this.stageID;
            case PhaseDetailsDataModel.stageNameKey:
                return this.stageName;
            case PhaseDetailsDataModel.stageTypeKey:
                return this.stageType;
            case PhaseDetailsDataModel.isMaxSeriesOnlyKey:
                return this.isMaxSeriesOnly;
            case PhaseDetailsDataModel.maxSeriesGamesKey:
                return this.maxSeriesGames;
            case PhaseDetailsDataModel.percentagePointsFromScratchKey:
                return this.percentagePointsFromScratch;
            case PhaseDetailsDataModel.positionPointsDefinitionKey:
                return this.positionPointsDefinition;
            case PhaseDetailsDataModel.scorePointsDefinitionKey:
                return this.scorePointsDefinition;
            case PhaseDetailsDataModel.tiebreak1Key:
                return this.tiebreak1;
            case PhaseDetailsDataModel.tiebreak2Key:
                return this.tiebreak2;
            case PhaseDetailsDataModel.tiebreak3Key:
                return this.tiebreak3;
            case PhaseDetailsDataModel.eventIDKey:
                return this.eventID;
            case PhaseDetailsDataModel.eventNameKey:
                return this.eventName;
            case PhaseDetailsDataModel.tournamentIDKey:
                return this.tournamentID;
            case PhaseDetailsDataModel.tournamentNameKey:
                return this.tournamentName;
            case PhaseDetailsDataModel.tournamentLanguageKey:
                return this.tournamentLanguage;
            case PhaseDetailsDataModel.entitiesKey:
                return this.entities;
            case PhaseDetailsDataModel.dropsKey:
                return this.drops;
            case PhaseDetailsDataModel.seriesKey:
                return this.series;
        }
    }

    // #endregion
}