<template>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <rect class="pin-icon-back" width="16" height="16"/>
    <polygon class="pin-icon" points="3.96 12.04 0 16 2.56 16 5.25 13.32 3.96 12.04"/>
    <path class="pin-icon" d="M15.73,6.32,10.94,1.54A.91.91,0,0,0,9.66,2.82l.23.23-4,4A1.84,1.84,0,0,0,3.32,9.64L7.65,14a1.84,1.84,0,0,0,2.6-2.6h0l4-4,.22.22a.91.91,0,0,0,1.28-1.28Z"/>
</svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({

})
</script>

<style>
.pin-icon-back {
    fill: none;
}
.pin-icon {
    fill: #fff;
}

.active .pin-icon {
  fill: var(--archive-bck-color);
}
</style>