<template>
    <div class="results-container">
        <div class="player-panel" v-for="(player, playerIndex) in displayPlayers" :key="playerIndex">
            <template v-if="player.playerIsInCameraLanes">
                <div class="player-details">
                    <div class="player-info">
                        <div class="bowling-player-name">{{player.name}}</div>
                        <div style="display: flex;">
                            <div class="player-country">{{player.country}}</div>
                            <div class="lane">Lanes 
                                {{parseInt(player.games[player.games.length - 1].game.lane) % 2 === 0 ? player.games[player.games.length - 1].game.lane - 1 : player.games[player.games.length - 1].game.lane}}-{{
                                    parseInt(player.games[player.games.length - 1].game.lane) % 2 !== 0 ? parseInt(player.games[player.games.length - 1].game.lane) + 1 : player.games[player.games.length - 1].game.lane }}</div>
                        </div>
                        <div class="player-games">
                            <div class="game" v-for="displayGame in player.games" :key="displayGame.game.ID">
                                <div class="game-num"><span>G</span> {{displayGame.game.sequence}}</div>
                                <div class="game-score" :class="{'predicted' : displayGame.game.isPredicted}">
                                    {{displayGame.game.score}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="frames-panel">
                    <div class="ranking-details">
                        <div :class="{'predicted' : player.rankingInfo.isPredicted}" style="width: calc(18% - 6px)">
                            <span style="color:#fff;">&Sigma; </span> 
                            {{player.rankingInfo.currentSeriesScore}}
                        </div>
                        <div style="width: calc(26% - 6px);">
                            <span>&Delta;Best </span> 
                            <span :class="{'positive-diff' : player.rankingInfo.bestSeriesScore > 0 && player.rankingInfo.currentSeriesScore > player.rankingInfo.bestSeriesScore}" 
                                style="font-weight: bold;font-size: 14px;">
                                {{player.rankingInfo.bestSeriesScore > 0 && player.rankingInfo.currentSeriesScore > player.rankingInfo.bestSeriesScore ? '+' : ''}}{{player.rankingInfo.bestSeriesScore > 0 ? player.rankingInfo.currentSeriesScore - player.rankingInfo.bestSeriesScore : '-'}}
                            </span>
                        </div>
                        <div :class="{'predicted' : player.rankingInfo.isPredicted}" style="width: calc(22% - 6px);">
                            <span style="color:#fff;">Avg </span> 
                            {{player.rankingInfo.currentAvg.toFixed(1)}}
                        </div>
                        <div style="width: calc(21% - 6px);">
                            <span>&Delta; </span> 
                            <span :class="{'positive-diff' : player.rankingInfo.rankingResult > (localPhaseRanking?.cut.result ?? 0) }" 
                                style="font-weight: bold;font-size: 14px;">
                                {{player.rankingInfo.rankingResult >= (localPhaseRanking?.cut.result ?? 0) ? '+' : ''}}{{player.rankingInfo.rankingResult  - (localPhaseRanking?.cut.result ?? 0)}}
                            </span>
                        </div>
                        <div style="width: calc(13 - 6px);">
                            <BIconStarFill style="font-size: .9em;" />
                            <span :class="{'qualifies' : player.rankingInfo.qualifies}" style="font-weight: bold;font-size: 14px;">
                                {{playerIndex + 1}}
                            </span>
                        </div>
                    </div>
                    <div class="frame-nums">
                        <div class="frame-num">1</div><div class="frame-num">2</div><div class="frame-num">3</div><div class="frame-num">4</div><div class="frame-num">5</div><div class="frame-num">6</div><div class="frame-num">7</div><div class="frame-num">8</div><div class="frame-num">9</div><div class="frame-num">10</div>
                        <div class="total-col">&nbsp;&nbsp;Total {{player?.handicap> 0 ? '+' + player?.handicap : ''}}</div>
                    </div>
                    <div class="frames-row">
                        <template v-for="(displayGame, displayGameIndex) in player.games" :key="displayGameIndex">
                            <template v-if="displayGame.gameIsActiveOrIsLastOne && displayGame.game.frames">
                                <div class="frame-cell" v-for="frameIndex in 10" :key="frameIndex" :setFrame="frame = displayGame.game.frames[frameIndex - 1]">
                                    <div :class="{'split' : frame.isSplit === 1}" class="frame" >
                                        <span :data-time="frame.firstRollTime">{{getFirstRollVisualisation(frame)}}</span>
                                        <span :data-time="frame.secondRollTime">{{getSecondRollVisualisation(frame)}}</span>
                                    </div>
                                    <div class="frame-score">{{frame.scoreAtFrame}}
                                    </div>
                                </div>
                            </template> 
                        </template>
                        <!-- <template >
                            <div class="frame-cell" v-for="frameIndex in 10" :key="frameIndex">
                                <div class="frame" >
                                    <span></span>
                                    <span></span>
                                </div>
                                <div class="frame-score"></div>
                            </div>
                        </template> -->
                        <div :class="{'predicted' : player.games[player.games.length - 1].game?.isPredicted}" class="total-score">{{player.games[player.games.length - 1].game.score}}</div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<script lang="ts">
import useParsePhaseRankingForRender from "@/composables/bowling/UseParsePhaseRankingForRender";
import PhaseRankingModel from "@/composables/dataModels/bowling/PhaseRankingDataModel";
import { defineComponent, onMounted, PropType, ref, Ref, watch } from "vue";
import { BIconStarFill } from 'bootstrap-icons-vue'
import FrameDataModel from "@/composables/dataModels/bowling/FrameDataModel";

export default defineComponent ({
    components: {
        BIconStarFill
    }, 
    props: {
        phaseRanking: {
            type: Object as PropType<PhaseRankingModel> | null,
            required: false,
        },
        squadId: {
            type: Number,
            required: true
        },
        fromLane: {
            type: Number,
            required: false
        },
        toLane: {
            type: Number,
            required: false
        }
    },
    setup(props) {
        const localPhaseRanking: Ref<PhaseRankingModel | undefined> = ref()
        const localSquadId: Ref<number> = ref(0)
        const localFromLane: Ref<number> = ref(0)
        const localToLane: Ref<number> = ref(100)
        onMounted(() => {
            if(props.phaseRanking) {
                localPhaseRanking.value = props.phaseRanking
            }
            if(props.squadId) {
                localSquadId.value = props.squadId
            }

            if(props.fromLane) {
                localFromLane.value = props.fromLane
            }
            if(props.toLane) {
                localToLane.value = props.toLane
            }

            console.log(displayPlayers.value)
        })
        watch(props, (newProps) => {
            if(newProps.phaseRanking) {
                localPhaseRanking.value = newProps.phaseRanking
            }
            if(newProps.squadId) {
                localSquadId.value = newProps.squadId
            }
            if(newProps.fromLane) {
                localFromLane.value = newProps.fromLane
            }
            if(newProps.toLane) {
                localToLane.value = newProps.toLane
            }
            console.log(displayPlayers.value)
        })

        const getFirstRollVisualisation = (frame: FrameDataModel) => {
            return frame.firstRollScore === -1 ? "" : 
                    (frame.firstRollScore === 10 ? "X" : 
                        (frame.firstRollScore === 0 ? "-" : 
                            ((frame.firstRollScore ?? 99) > 9 ? '?' : frame.firstRollScore)));
        }

        const getSecondRollVisualisation = (frame: FrameDataModel) => {
            
            if(frame.secondRollScore === undefined || frame.secondRollScore === -1 || frame.firstRollScore === 10) {
                console.log(frame)
                return '';
            } else if(frame.secondRollScore + (frame.firstRollScore ?? -1) === 10) {
                return "/";
            } else if((frame.frameNumber ?? 0) <= 9 && frame.secondRollScore === 0) {
                console.log(frame)
                return "-";
            } else if ((frame.frameNumber ?? 0) > 9 && frame.secondRollScore === 0) {
                return '';
            } else {
                return frame.secondRollScore > 10 || frame.secondRollScore < 0 ? '?' : frame.secondRollScore;
            }
        }

        const {
            phaseCutAverage,
            displayPlayers
        } = useParsePhaseRankingForRender(localPhaseRanking, undefined, undefined, localSquadId, localFromLane, localToLane)    
        return {
            phaseCutAverage,
            displayPlayers,
            localPhaseRanking,
            getFirstRollVisualisation,
            getSecondRollVisualisation
        }
    }
})
</script>

<style>
    .frames-panel {
        width: 450px;
        font-size: 13px;
        background-color: #4e4e4e;
    }

    .frames-panel > div {
        float: left;
    }

    .total-col, .total-score {
        width: calc(12% + 10px);
        border-top: 1px solid #bfbfbf;
        border-right: 1px solid #bfbfbf;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
    }

    .total-col {
        font-size: 11px;
    }

    .frame-cell, .total-score {
        border-bottom: 1px solid #bfbfbf;
    }

    .frame-nums, .total-score, .total-col, .frame-score {
        background-color: #5f5f5f;
    }


    .frame-num, .frame-cell {
        width: calc(8.8% - 1px);
        float: left;
        border-top: 1px solid #bfbfbf;
        border-right: 1px solid #bfbfbf;
        text-align: center;
        overflow: hidden;
    }

    .frames-row, .frame-nums {
        width: 100%;
        display: flex;
        border-left: 1px solid #bfbfbf;
    }

    .frame-nums {
        height: 20%;
    }

    .frames-row {
        height: 50%;
    }

    .frame-num {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 11px;
        font-weight: bold;
    }

    .frame-score,.frame {
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .player-details {
        width: 185px;
        border-top: 1px solid #bfbfbf;
        border-bottom: 1px solid #bfbfbf;
        border-left: 1px solid #bfbfbf;
        background-color: #4e4e4e;
    }

    .player-panel, .player-details {
        display: flex;
        text-align: left;
    }

    .player-panel {
        margin: 3px 0;
    }

    .player-pic > img {
        width: 4em;
    }

    .player-pic {
        height: 5em;
        overflow: hidden;
    }

    .player-info {
        padding-left: 4px;
        max-width: 100%;
    }

    .bowling-player-name {
        font-size: 16px;
        font-weight: bold;
        overflow: hidden;
        text-overflow: clip;
        white-space: nowrap;
    }

    .player-games {
        display: flex;
        justify-content: left;
        align-items: center;
        margin-left: -5px;
        min-width: 186px;
    }

    .player-games > .game {
        border-right: 1px solid #bfbfbf;
        padding: 0 3px;
    }

    .player-games > .game:last-child {
        border-right: none;
    }

    .game-num {
        font-size: 13px;
        font-weight: bold;
    }

    .game-num > span {
        font-size: 11px;
        color: #cecece;
    }

    .player-country {
        font-size: 11px;
        width: calc(100% - 68px);
    }

    .ranking-details {
        height: 30%;
        width: 100%;
        border-left: 1px solid #bfbfbf;
        border-top: 1px solid #bfbfbf;
        border-right: 1px solid #bfbfbf;
        font-weight: bold;
    }

    .game-score {
        font-size: 14px;
    }

    .ranking-details > div {
        float: left;
        padding: 0 3px;
    }

    .ranking-details > div > span {
        font-size: 14px;
        font-weight: normal;
    }

    .heading {
        height: 250px;
        padding: 8px;
    }

    .heading > div {
        padding-left: 20px;
        width: calc(33.3333% - 30px);
        margin-left: 10px;
    }

    .qualifies, .positive-diff {
        color: #55CF60;
    }

    .predicted {
        color: #3ABCDF;
    }

    .frame.split > span:first-child {
        background-color: #C01A16;
        border-radius: 50%;
    }

    .frame span {
        padding: 0 2px;
        cursor: pointer;
    }

    .results-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        float: left;
    }

    .lane {
        font-size: 11px;
        width: 68px;
        text-align: right;
        padding-right: 4px;
    }
</style>