import authModule from '@/main';
import { IdentityProvider, ProviderUser } from '@/models/main/ProviderUser';
import { IRootState } from '@/store';
import { Module } from 'vuex'

const state: ProviderUser = {
    userId: "",
    identityProvider: null,
    userDetails: ""
}

const getters = {
    getProviderUser(state: any) {
        return state;
    },
}

const mutations = {
    setUserId(state: ProviderUser, payload: string) {
        state.userId = payload
    },

    setIdentityProvider(state: ProviderUser, payload: IdentityProvider) {
        state.identityProvider = payload
    },

    setUserDetails(state: ProviderUser, payload: string) {
        state.userDetails = payload
    },
}

const actions = {
}

const providerUserStore: Module<ProviderUser, IRootState> = 
{
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}

export default providerUserStore;