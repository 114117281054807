
export const USER_REQUEST_TYPES = ['Other', 'Merge']
export type UserRequestType = typeof USER_REQUEST_TYPES[number]
export interface UserRequest {
    id: number,
    userId: number,
    title: string,
    contact: string,
    description: string,
    type: UserRequestType
}