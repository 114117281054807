import GenericDictionary from "@/components/util/GenericDictionary"
import { Commit } from "vuex"

export interface PagedResponse<T> {
    items: GenericDictionary<T>
    currentPage?: number,
    pageSize?: number,
    totalPages?: number,
    totalCount?: number,
    nextPage?: number,
    previousPage?: number
}

export interface PaginatedItemsStore<T> {
    paginatedItems: PagedResponse<T>
}

export function PaginatedItemsStoreMutations<T>() {
    const setCurrentPage = (state: PaginatedItemsStore<T>, currentPage: number) => {
        state.paginatedItems.currentPage = currentPage
    }

    const setPageSize = (state: PaginatedItemsStore<T>, pageSize: number) => {
        state.paginatedItems.pageSize = pageSize
    }

    const setNextPage = (state: PaginatedItemsStore<T>, nextPage: number | null) => {
        state.paginatedItems.nextPage = nextPage === null ? undefined : nextPage
    }

    const setPreviousPage = (state: PaginatedItemsStore<T>, previousPage: number | null) => {
        state.paginatedItems.previousPage = previousPage === null ? undefined : previousPage
    }

    const setTotalCount = (state: PaginatedItemsStore<T>, totalCount: number | null) => {
        state.paginatedItems.totalCount = totalCount === null ? undefined : totalCount
    }

    return { setCurrentPage, setPageSize, setNextPage, setPreviousPage, setTotalCount }
}

export function PaginatedItemsStoreGetters<T>() {
    const getCurrentPage = (state: PaginatedItemsStore<T>) => {
        return state.paginatedItems.currentPage ?? 1
    }
    const getPageSize = (state: PaginatedItemsStore<T>) => {
        return state.paginatedItems.pageSize ?? 0
    }

    const getNextPage = (state: PaginatedItemsStore<T>) => {
        return state.paginatedItems.nextPage
    }

    const getPreviousPage = (state: PaginatedItemsStore<T>) => {
        return state.paginatedItems.previousPage
    }

    const getTotalCount = (state: PaginatedItemsStore<T>) => {
        return state.paginatedItems.totalCount ?? 0
    }

    return {
        getCurrentPage,
        getPageSize,
        getNextPage,
        getPreviousPage,
        getTotalCount
    }
}

export function prepForPagedCall(getters: any, filtersChanged: boolean = false) {
    let pageToRequest: number = getters['getNextPage'];
    const pageSize: number = getters['getPageSize'];
    const nextPage: number = getters['getNextPage']
    const currentPage: number = getters['getCurrentPage']
    if (filtersChanged) {
        pageToRequest = 1;
    }
    const isFirstRequest = nextPage === 1 && currentPage === 1
    const canMakeNextPageCall = filtersChanged || isFirstRequest || nextPage > currentPage
    return { pageToRequest, pageSize, canMakeNextPageCall }
}

export function commitPagedResponseDetails<T>(commit: Commit, pagedResponse: PagedResponse<T>) {
    commit('setCurrentPage', pagedResponse.currentPage);
    commit('setPageSize', pagedResponse.pageSize);
    commit('setNextPage', pagedResponse.nextPage);
    commit('setPreviousPage', pagedResponse.previousPage);
    commit('setTotalCount', pagedResponse.totalCount)
}