import { IRootState } from '@/store';
import { Store } from 'vuex';
import { Container } from '@/models/main/Container';
import { computed, Ref } from '@vue/runtime-core';
import { Entity } from '@/models/main/Entity';
import useInterpretTennisEvents from './UseInterpretTennisEvents';
import Livestream from '@/models/main/Livestream';

export default function useCurrentContainerComputeds(store: Store<IRootState>, currentContainerId: Ref<number>, liveStream?: Ref<Livestream | null>, playerCurrentUnixTime?: Ref<number>, isSpoiledModeOn?: Ref<boolean>) {
    const getCurrentContainer = computed((): Container | undefined => {
        return store.getters['containerStore/getContainer'](currentContainerId.value)
    })

    const getCurrentHomeEntity = computed((): Entity | undefined => {
        return store.getters['containerStore/getHomeEntity'](currentContainerId.value)
    })

    const getCurrentAwayEntity = computed((): Entity | undefined => {
        return store.getters['containerStore/getAwayEntity'](currentContainerId.value)
    })

    const { countBasicStats } = useInterpretTennisEvents()

    // const getCurrentContainerEventsGroupedByGames = computed(() => {
    //     const homeEntityId = getCurrentHomeEntity.value?.id
    //     const awayEntityId = getCurrentAwayEntity.value?.id
    //     const currentContainer = getCurrentContainer.value
    //     if(homeEntityId !== undefined && awayEntityId !== undefined && currentContainer !== undefined) {
    //         return groupEventsByGames(homeEntityId, awayEntityId, currentContainer.events, liveStream?.value?.status === "Stopping" || liveStream?.value?.status === "Completed" ? playerCurrentUnixTime?.value : undefined, isSpoiledModeOn?.value ? undefined : playerCurrentUnixTime?.value)
    //     }

    //     return {}
    // })

    const getCurrentMatchStats = computed(() => {
        const homeEntityId = getCurrentHomeEntity.value?.id
        const awayEntityId = getCurrentAwayEntity.value?.id
        const currentContainer = getCurrentContainer.value
        if(homeEntityId !== undefined && awayEntityId !== undefined && currentContainer !== undefined) {
            return countBasicStats(homeEntityId, awayEntityId, currentContainer.events, liveStream?.value?.status === "Stopping" || liveStream?.value?.status === "Completed" ? playerCurrentUnixTime?.value : undefined)
        }

        return undefined
    })

    const getCurrentMatchStatsSpoiled = computed(() => {
        const homeEntityId = getCurrentHomeEntity.value?.id
        const awayEntityId = getCurrentAwayEntity.value?.id
        const currentContainer = getCurrentContainer.value
        if(homeEntityId !== undefined && awayEntityId !== undefined && currentContainer !== undefined) {
            return countBasicStats(homeEntityId, awayEntityId, currentContainer.events)
        }

        return undefined
    })

    const getCurrentHomeEntityPoints = computed(() => {
        const matchScores = getCurrentMatchStats.value?.matchScores
        const homeEntityId = getCurrentHomeEntity.value?.id
        if(homeEntityId !== undefined && matchScores !== undefined) {
            return matchScores.pointsScore.entities[homeEntityId]?.score ?? 0
        }

        return 0
    })

    const getCurrentAwayEntityPoints = computed(() => {
        const matchScores = getCurrentMatchStats.value?.matchScores
        const awayEntityId = getCurrentAwayEntity.value?.id
        if(awayEntityId !== undefined && matchScores !== undefined) {
            return matchScores.pointsScore.entities[awayEntityId]?.score ?? 0
        }

        return 0
    })

    const currentHomeEntityGamePoints = computed((): number => {
        const matchScores = getCurrentMatchStats.value?.matchScores
        const homeEntityId = getCurrentHomeEntity.value?.id
        if(homeEntityId !== undefined && matchScores !== undefined) {
            return matchScores.gameScore.entities[homeEntityId]?.score ?? 0
        }

        return 0
    })

    const currentAwayEntityGamePoints = computed((): number => {
        const matchScores = getCurrentMatchStats.value?.matchScores
        const awayEntityId = getCurrentAwayEntity.value?.id
        if(awayEntityId !== undefined && matchScores !== undefined) {
            return matchScores.gameScore.entities[awayEntityId]?.score ?? 0
        }
        
        return 0
    })

    const currentHomeEntitySetPoints = computed((): number => {
        const matchScores = getCurrentMatchStats.value?.matchScores
        const homeEntityId = getCurrentHomeEntity.value?.id
        if(homeEntityId !== undefined && matchScores !== undefined) {
            return matchScores.setScore.entities[homeEntityId]?.score ?? 0
        }

        return 0
    })

    const currentAwayEntitySetPoints = computed((): number => {
        const matchScores = getCurrentMatchStats.value?.matchScores
        const awayEntityId = getCurrentAwayEntity.value?.id
        if(awayEntityId !== undefined && matchScores !== undefined) {
            return matchScores.setScore.entities[awayEntityId]?.score ?? 0
        }
        
        return 0
    })

    return {
        getCurrentMatchStats,
        getCurrentMatchStatsSpoiled,
        getCurrentContainer,
        getCurrentHomeEntity,
        getCurrentAwayEntity,
        getCurrentHomeEntityPoints,
        getCurrentAwayEntityPoints,
        currentHomeEntityGamePoints,
        currentAwayEntityGamePoints,
        currentHomeEntitySetPoints,
        currentAwayEntitySetPoints
    }
}