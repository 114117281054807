<template>
  <div @click="$emit('click')" class="entity-item badge-item" :class="{ selected: isSelected }">
    <div class="entity-item__logo badge-item-col">
        <img decoding="async" loading="lazy" :src="getEntityLogoSrc(entity)" @error="setDefaultEntityLogo" :alt="entity.name" width="68" height="68"/>
    </div>
    <div class="entity-item__name badge-item-col">
        {{ entity.name }}
    </div>
    <div class="entity-item__season badge-item-col">
        {{ entity.seasons && entity.seasons.length > 0 ? entity.seasons[entity.seasons.length - 1].name : 'All Time'}}
    </div>
    <div v-if="isEditable" class="entity-item__actions badge-item-col">
      <div class="dropdown-action-box" tabindex="0">
        <div class="dropdown-action-box__title">Actions</div>
        <div class="dropdown-action-box__actions">
          <button @click="$emit('edit')" class="droopdown-action-box__action" title="Edit Team">Edit</button>
          <button @click="$emit('delete')" class="droopdown-action-box__action" title="Delete Team">Delete</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Entity } from '@/models/main/Entity';
import { setDefaultEntityLogo, getEntityLogoSrc } from '@/composables/common/Util';

const EntityItem = defineComponent({
  props: {
    entity: {
      type: Object as PropType<Entity>,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: false,
    },
    isEditable: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['click', 'edit', 'delete'],
  setup() {
    return { getEntityLogoSrc, setDefaultEntityLogo}
  }
});
export default EntityItem;
</script>
<style>
:root {
  --entity-item-padding: 0.325em;
  --entity-item-bck-color: #76b399;
  --entity-item-margin-bottom: 0.2em;
  --entity-item-size: 1em;
}

.entity-item {
  padding: 0.5em 1em;
  background-color: var(--bright-font-clr);
  margin-bottom: var(--entity-item-margin-bottom);
  font-size: var(--entity-item-size);
  cursor: pointer;
  border-radius: var(--default-border-rad);
  display: flex;
  font-weight: 600;
  height: 5em;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
}

.entity-item__logo.badge-item-col {
    height: calc(var(--badge-item-height) - 1em);
    align-self: flex-start;
    align-items: center;
    width: var(--badge-item-height);
}
.entity-item__logo > img {
  height: 100%;
  border-radius: var(--default-border-rad);
  object-fit: contain;
}
.entity-item__name {
  height: 100%;
  align-self: flex-start;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc((100% - var(--badge-item-height) - 8em) * 0.5);
  padding-left: 1em;
}
.entity-item__actions {
  height: 100%;
  align-self: flex-end;
}

.entity-item.selected {
  background-color: var(--select-background-color);
}

.entity-item.selected .entity-item__actions {
  color: var(--main-font-clr);
}

.entity-item__season.badge-item-col {
  width: 30%;
}
</style>