<template>
<div class="bwl-small-frames-wrapper">
    <div class="small-frames-title">Other Lanes</div>
    <!-- <div class="powered-by-bowlzone"><img src="../../public/image/Bowlzone_logo_powered_white.png" alt="Powered By Bowlzone"></div> -->
    <div class="frames-container">
        <template v-for="(player, playerIndex) in displayPlayers" :key="playerIndex">
            <div class="small-player-panel player-panel" v-if="!player.playerIsInCameraLanes">
                <div class="lane-num">{{player.games[player.games.length - 1].game.lane}}</div>
                <div class="small-player-name">{{player.name}}</div>
                <div class="small-frames-panel">
                    <div class="small-ranking-details">		
                        <div class="panel-ranking-detail-sigma-wrapper" :class="{'predicted' : player.rankingInfo.isPredicted}">
                            <div class="panel-symbol" style="font-weight: normal;color:#fff;">&Sigma; </div> 
                            <div class="panel-ranking-detail" style="width: 36px;">{{player.rankingInfo.currentSeriesScore}}</div>
                        </div>
                        <div class="panel-ranking-detail-delta-wrapper">
                            <div class="panel-symbol">&Delta; </div> 
                            <div class="panel-ranking-detail" :class="{'positive-diff' : player.rankingInfo.rankingResult > (localPhaseRanking?.cut.result ?? 0) }" style="font-weight: bold;font-size: 14px; width: 36px">
                        {{player.rankingInfo.rankingResult >= (localPhaseRanking?.cut.result ?? 0) ? '+' : ''}}{{player.rankingInfo.rankingResult  - (localPhaseRanking?.cut.result ?? 0)}}</div>
                        </div>
                        <div class="panel-ranking-detail-position-wrapper" >
                            <BIconStarFill class="panel-symbol"></BIconStarFill>
                            <div class="panel-ranking-detail" :class="{'qualifies' : player.rankingInfo.qualifies}">
                                {{player.position}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</div>
</template>
<script lang="ts">
import useParsePhaseRankingForRender from "@/composables/bowling/UseParsePhaseRankingForRender";
import PhaseRankingModel from "@/composables/dataModels/bowling/PhaseRankingDataModel";
import { defineComponent, onMounted, PropType, ref, Ref, watch } from "vue";
import { BIconStarFill } from 'bootstrap-icons-vue'

export default defineComponent ({
    components: {
        BIconStarFill
    }, 
    props: {
        phaseRanking: {
            type: Object as PropType<PhaseRankingModel> | null,
            required: false,
        },
        squadId: {
            type: Number,
            required: true
        },
        fromLane: {
            type: Number,
            required: false
        },
        toLane: {
            type: Number,
            required: false
        }
    },
    setup(props) {
        const localPhaseRanking: Ref<PhaseRankingModel | undefined> = ref()
        const localSquadId: Ref<number> = ref(0)
        const localFromLane: Ref<number> = ref(0)
        const localToLane: Ref<number> = ref(100)
        onMounted(() => {
            if(props.phaseRanking) {
                localPhaseRanking.value = props.phaseRanking
            }
            if(props.squadId) {
                localSquadId.value = props.squadId
            }

            if(props.fromLane) {
                localFromLane.value = props.fromLane
            }
            if(props.toLane) {
                localToLane.value = props.toLane
            }
        })
        watch(props, (newProps) => {
            if(newProps.phaseRanking) {
                localPhaseRanking.value = newProps.phaseRanking
            }
            if(newProps.squadId) {
                localSquadId.value = newProps.squadId
            }
            if(newProps.fromLane) {
                localFromLane.value = newProps.fromLane
            }
            if(newProps.toLane) {
                localToLane.value = newProps.toLane
            }
        })

        const {
            phaseCutAverage,
            displayPlayers
        } = useParsePhaseRankingForRender(localPhaseRanking, undefined, undefined, localSquadId, localFromLane, localToLane)   
        return {
            phaseCutAverage,
            displayPlayers,
            localPhaseRanking
        }
    }
})
</script>

<style>
    .small-ranking-details .panel-ranking-detail-delta-wrapper, .small-ranking-details .panel-ranking-detail-sigma-wrapper {
        width: 60px;
    }
    .small-ranking-details .panel-ranking-detail-position-wrapper {
        width: 3em;
    }

    .small-ranking-details .panel-symbol {
        font-size: .8em;
    }

    .small-player-panel .lane-num {
        padding-right: 3px;
        width: 15px;
        text-align: right;
        background-color: #3b3b3b;
        font-size: 0.75em;
    }

    .small-ranking-details {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid var(--bwl-small-player-panel-border-clr);
        font-weight: bold;
        border-right: 1px solid var(--bwl-small-player-panel-border-clr);
    }

    .small-player-panel {
        display: flex;
        width: calc(100% - 1px);
        height: 21px;
        margin: 0;
        border-right: 1px solid var(--bwl-small-player-panel-border-clr);
        --bwl-small-player-panel-bck-clr: #4e4e4e;
        --bwl-small-player-panel-border-clr: #bfbfbf;
    }

    

    .small-player-panel > div {
        background-color: var(--bwl-small-player-panel-bck-clr)
    }

    .small-frames-panel {
        width: 154px;
        font-size: 14px;
        border-top: 1px solid #bfbfbf;
    }
    .small-player-name {
        font-size: 13px;
        font-weight: bold;
        overflow: hidden;
        text-overflow: clip;
        white-space: nowrap;
        width: 145px;
        padding-left: 3px;
        border-top: 1px solid #bfbfbf;
        border-left: 1px solid #bfbfbf;
        text-align: left;
    }

    .small-player-panel.player-panel:nth-child(16) > .small-player-name, .small-player-panel:nth-child(32) > .small-player-name, .small-player-panel:last-child > .small-player-name {
        border-bottom: 1px solid #bfbfbf;
    }

    .small-player-panel.player-panel:nth-child(16) > .small-frames-panel, .small-player-panel:nth-child(32) > .small-frames-panel, .small-player-panel:last-child > .small-frames-panel {
        border-bottom: 1px solid #bfbfbf;
    }

    .small-player-panel.player-panel:nth-child(16) > .small-player-name, .small-player-panel:nth-child(32) > .small-player-name, .small-player-panel:last-child > .small-player-name {
        border-bottom: 1px solid #bfbfbf;
    }

    .small-player-panel.player-panel:nth-child(16) > .small-frames-panel, .small-player-panel:nth-child(32) > .small-frames-panel, .small-player-panel:last-child > .small-frames-panel {
        border-bottom: 1px solid #bfbfbf;
    }



    .empty-small-player-panel {
        width: 100%;
        height: 21px;
        display: flex;
    }

    .empty-small-player-panel:last-child .empty-frames-panel {
        border-bottom: 1px solid #bfbfbf;
    }

    .empty-frames-panel {
        height: 21px;
        border-top: 1px solid #bfbfbf;
        border-right: 1px solid #bfbfbf;
        border-left: 1px solid #bfbfbf;
    }

    .bwl-small-frames-wrapper .frames-container {
        display: flex;
        float: left;
        margin-left: 6px;
        margin-top: 8px;
        width: 626px;
        flex-direction: column;
        flex-wrap: wrap;
        overflow: hidden;
    }

    .small-ranking-details > div {
        display:flex;
        align-items: center;
    }

    .panel-ranking-detail {
        text-align: right;
    }

    .small-ranking-details > div {
        padding: 0 3px;
    }


    .empty-small-player-panel:last-child .empty-frames-panel {
        border-bottom: 1px solid #bfbfbf;
    }

    .empty-frames-panel {
        height: 21px;
        border-top: 1px solid #bfbfbf;
        border-right: 1px solid #bfbfbf;
        border-left: 1px solid #bfbfbf;
        width: calc(100% - 22px);
    }

    #right-squad-players {
        width: 307px;
    }

    #left-squad-players{
        width: 351px;
    }

    #right-squad-players .lane-num {
        display: none;
    }

    #right-squad-players .empty-frames-panel {
        width: 100%;
    }

    .small-frames-title {
        height: 24px;
        text-align: left;
        margin-left: 12px;
        font-size: 16px;
        font-weight: bolder;
        width: 100%;
        margin-top: 25px;
    }

    .powered-by-bowlzone {
        float: right;
        padding-top: 8px;
        margin-right: 5px;
    }

    .powered-by-bowlzone > img {
        max-width: 100%;
    }
</style>