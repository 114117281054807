import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "user-bar__user_section"
}
const _hoisted_2 = { class: "user-bar__user_section__icon" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "user-bar__user_section__name" }
const _hoisted_5 = { class: "user-bar__user_section__link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.user.id)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: `${_ctx.publicPath}${_ctx.defaultProfilePicRelativePath}`,
            alt: "Profile"
          }, null, 8, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, { to: {name: 'Profile'} }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.user.firstName) + " " + _toDisplayString(_ctx.user.lastName), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("a", {
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.logoutUser()), ["prevent"]))
          }, "Logout")
        ])
      ]))
    : _createCommentVNode("", true)
}