<template>
    <Teleport to="nav">
        <Modal ref="addEntityPopup">
            <template v-slot:header>
                <h2>Add {{ $c('entity', sportFilter) }}</h2>
            </template>
            <template v-slot:body>
                <div class="spz-text-input-group">
                    <label for="search-team" v-show="false" sr-only>Search {{ $c('entity', sportFilter) }}:</label>
                    <input @input="onEntitySearchInput" :value="entitySearchTerm" id="search-team" type="text" placeholder="Search">
                </div>
                <div class="team-type">
                    <div class="side-description">{{ $c('entity', sportFilter) }}</div>
                </div>
                <div class="selected-teams">
                    <div class="selected-away-team">
                        <EntityItem @click="selectedEntityId = 0" v-if="selectedEntityId !== 0"
                            :entity="getEntityById(selectedEntityId)" />
                        <div class="already-selected" v-else>Please select {{ $c('entity', sportFilter) }}</div>
                    </div>
                </div>
                <div v-infinite-scroll="[loadMoreEntities, { interval: 400, canLoadMore: () => { return !showLoadingIcon && thereIsNextEntitiesPage } }]" class="teams-container">
                    <div class="away-entities" :class="{ 'entities-hide': selectedEntityId !== 0 }">
                        <EntityItem @click="selectedEntityId = entity.id"
                            :isSelected="entity.id === selectedEntityId"
                            v-for="entity in getEntities(entitySearchTerm, organizationId)" :key="entity.id" :entity="entity"> {{
                                entity.name }}
                        </EntityItem>
                    </div>
                </div>
                <LoadingIcon v-show="showLoadingIcon" />
            </template>
            <template v-slot:footer>
                <button @click="addEntityPopup.closeModal(), $emit('onChoose')" class="btn add-btn">
                Confirm
                </button>
                <button @click="addEntityPopup.closeModal()" class="btn btn-inverse">Cancel</button>
            </template>
        </Modal>
    </Teleport>
</template>
<script setup lang="ts">
import { Ref, ref } from 'vue'
import Modal from '@/components/main/Modal.vue'
import LoadingIcon from '@/components/util/LoadingIcon.vue';
import EntityItem from '@/components/main/EntityItem.vue';
import useEntityComposables from '@/composables/generic/UseEntityComposables'
import { vInfiniteScroll } from '@vueuse/components'
import { useDebounceFn } from '@vueuse/core'

const showLoadingIcon: Ref<boolean> = ref(true);
const seasonId: Ref<number | undefined> = ref()
const props = defineProps({
  organizationId: Number,
})

const localOrganizationId: Ref<number | undefined> = ref(props.organizationId);
const { 
    entitySearchTerm,
    sportFilter,
    thereIsNextEntitiesPage,
    getEntities,
    getEntityById,
    loadEntities,
    onEntitySearchInput 
} = useEntityComposables(localOrganizationId, seasonId, showLoadingIcon)

defineEmits(['onChoose'])



const loadMoreEntities = async() => {
    console.log("Load more")
    showLoadingIcon.value = true
    await loadEntities(props.organizationId)
    showLoadingIcon.value = false
}
const selectedEntityId = defineModel<number>({ required: true, default : 0 })


const addEntityPopup: Ref<any> = ref()
//sportFilter.value = "TableTennis"; //TODO: Fix this, set to TableTennis only
// const onEntitySearchInput = useDebounceFn(async (event: any) => {
//     entitySearchTerm.value = event.target.value;
//     if (entitySearchTerm.value !== undefined && (entitySearchTerm.value.length >= 3 || entitySearchTerm.value.length === 0)) {
//         showLoadingIcon.value = true
//         await loadEntities(props.organizationId)        
//         showLoadingIcon.value = false   
//     }
// }, 400, { maxWait: 4000 })

const open = () => {
    // loadEntities(props.organizationId)
    showLoadingIcon.value = false
    addEntityPopup.value.openModal()
}
defineExpose({ open })
</script>
<style>
</style>