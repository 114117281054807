import { PagedResponse } from '@/composables/common/Pagination'
import authModule from '@/main'
import { OrganizationMember } from '@/models/main/OrganizationMember'
import { useDebounceFn } from '@vueuse/core'
import { defineStore, acceptHMRUpdate } from 'pinia'
import { computed, Ref, ref, watch } from 'vue'

export const useOrganizationMembersStoreStore = defineStore('OrganizationMembersStore', () => {
    const items: Ref<Map<number, OrganizationMember>> = ref(new Map<number, OrganizationMember>())
    const currentPage: Ref<number | undefined> = ref(1)
    const pageSize: Ref<number | undefined> = ref(15)
    const totalPages: Ref<number | undefined> = ref()
    const totalCount: Ref<number | undefined> = ref()
    const nextPage: Ref<number | undefined> = ref(1)
    const previousPage: Ref<number | undefined> = ref()
    const showLoadingIcon: Ref<boolean> = ref(false)
    const organizationId: Ref<number | undefined> = ref()

    const searchTerm: Ref<string | undefined> = ref()
    const nationality: Ref<string> = ref('Nationality')

    watch(organizationId, () => {
        setNextPage(1)
        fetchOrganizationMembers()
    })

    watch(nationality, (newNationalityVal: string) => {
        setNextPage(1)
        fetchOrganizationMembers()
    })

    const onMemberSearchInput = useDebounceFn(
        async () => {
            setNextPage(1); // reset paging
            await fetchOrganizationMembers();
        },
        400,
        { maxWait: 4000 }
    );

    watch(searchTerm, async (newSearchVal?: string) => {
        if (newSearchVal !== undefined &&
            (newSearchVal.length >= 3 || newSearchVal.length === 0)) {
            await onMemberSearchInput()
        }
    })

    const getOrganizationMembers = computed(() => {
        let filteredOrganizationMembers: Array<OrganizationMember> = [];
        for (const organizationMember of items.value.values()) {
            let searchTermIsOk = false
            const nationalityIsOk = nationality.value === undefined || nationality.value === 'Nationality' || organizationMember.nationality === nationality.value
            const organizationIsOk = organizationId.value === undefined || organizationMember.organizationId === organizationId.value
            if (searchTerm.value && searchTerm.value.length > 2) {
                const searchTermLowered = searchTerm.value.toLowerCase();
                const matchesFirstName = organizationMember.firstName.toLowerCase().includes(searchTermLowered)
                const matchesMiddleName = organizationMember.middleName?.toLowerCase().includes(searchTermLowered)
                const matchesLastName = organizationMember.lastName.toLowerCase().includes(searchTermLowered)
                searchTermIsOk = matchesFirstName || matchesMiddleName || matchesLastName;
            } else {
                searchTermIsOk = true
            }
            if (searchTermIsOk && nationalityIsOk && organizationIsOk) {
                filteredOrganizationMembers.push(organizationMember);
            }
        }
        filteredOrganizationMembers = filteredOrganizationMembers.sort((a, b) => {
            const firstNameCompare = a.firstName.localeCompare(b.firstName)
            return firstNameCompare === 0 ? a.lastName.localeCompare(b.lastName) : firstNameCompare;
        });
        return filteredOrganizationMembers;
    })

    const setSearchTerm = (searchValue: string) => {
        searchTerm.value = searchValue
    }

    const setOrganizationId = (val: number) => {
        organizationId.value = val
    }

    const setCurrentPage = (val: number) => {
        currentPage.value = val
    }

    const setNextPage = (val: number) => {
        nextPage.value = val
    }

    const thereIsNextMembersPage = computed(() => {
        return nextPage.value !== undefined
    });

    const getOrganizationMember = (organizationMemberId: number) => {
        return items.value.get(organizationMemberId)
    }
    const setOrganizationMember = (organizationMember: OrganizationMember) => {
        items.value.set(organizationMember.id, organizationMember)
    }

    const createOrganizationMember = (organizationMember: OrganizationMember) => {
        return authModule.post(`/api/organization/${organizationMember.organizationId}/member`, organizationMember)
            .then((response) => {
                const organizationMember: OrganizationMember = response.data as unknown as OrganizationMember;
                setOrganizationMember(organizationMember);
                return organizationMember;
            });
    }
    const editOrganizationMember = (organizationMember: OrganizationMember) => {
        return authModule.patch(`/api/organization/${organizationMember.organizationId}/member/${organizationMember.id}`, organizationMember)
            .then((response) => {
                const organizationMember: OrganizationMember = response.data as unknown as OrganizationMember;
                setOrganizationMember(organizationMember);
                return organizationMember;
            });
    }
    const deleteOrganizationMember = (organizationMember: OrganizationMember): Promise<void> => {
        return authModule.delete(`/api/organization/${organizationMember.organizationId}/member/${organizationMember.id}`)
            .then((response) => {
                const deletedOrganizationMemberId: number = response.data as unknown as number;
                items.value.delete(deletedOrganizationMemberId)
            });
    }
    const fetchOrganizationMembers = () => {
        showLoadingIcon.value = true
        if(!nextPage.value) {
            showLoadingIcon.value = false
            return
        }

        return authModule.get(`/api/organization/${organizationId.value}/member`, {
            params: {
                searchTerm: searchTerm.value !== undefined && searchTerm.value !== '' ? searchTerm.value : null,
                nationality: nationality.value !== undefined && nationality.value !== '' && nationality.value !== 'Nationality' ? nationality.value : null,
                page: nextPage.value,
                pageSize: pageSize.value,
            },
        })
            .then((response) => {
                const pagedResponse: PagedResponse<OrganizationMember> = response.data as unknown as PagedResponse<OrganizationMember>;
                currentPage.value = pagedResponse.currentPage
                pageSize.value = pagedResponse.pageSize
                nextPage.value = pagedResponse.nextPage
                previousPage.value = pagedResponse.previousPage

                for (const containerIndex in pagedResponse.items) {
                    setOrganizationMember(pagedResponse.items[containerIndex]);
                }
                return true
            })
            .catch((error) => {
                console.error(error);
                return false
            })
            .finally(() => {
                showLoadingIcon.value = false
            })
    }
    const uploadPicture = ({ memberId, memberPicture }: { memberId: number, memberPicture: File }) => {
        return authModule
            .post(`/api/member/${memberId}/picture`, memberPicture, {
                headers: {
                    'Content-Type': memberPicture.type,
                },
            });
    }
    const mergeOrganizationMembers = ({ organizationId, survivorMemberId, victimMemberIds }: { organizationId: number, survivorMemberId: number, victimMemberIds: number[] }) => {
        return authModule
            .post(`/api/organization/${organizationId}/member/${survivorMemberId}/merge`, victimMemberIds);
    }

    return {
        showLoadingIcon,
        items,
        currentPage,
        pageSize,
        totalPages,
        totalCount,
        nextPage,
        previousPage,
        thereIsNextMembersPage,
        searchTerm,
        nationality,
        getOrganizationMembers,
        getOrganizationMember,
        fetchOrganizationMembers,
        uploadPicture,
        deleteOrganizationMember,
        editOrganizationMember,
        createOrganizationMember,
        mergeOrganizationMembers,
        setSearchTerm,
        setCurrentPage,
        setOrganizationId,
    }
})

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept(acceptHMRUpdate(useOrganizationMembersStoreStore, import.meta.webpackHot))
}
