import DataModelMapper from "@/components/util/DataModelMapper";
import DataModel from "@/models/main/DataModel";
import PhaseGameDataModel from "./PhaseGameDataModel";

export default class PhasePlayerDataModel extends DataModel {
    public static readonly MODEL_NAME: string = "PhasePlayer"

    private _id?: number;
    private _firstName?: string;
    private _middleName?: string;
    private _lastName?: string;
    private _country?: string;
    private _games?: Array<PhaseGameDataModel>;

    // #region Fields Keys
    static get IDKey() {
        return "player_id";
    }

    static get firstNameKey() {
        return "first_name";
    }

    static get middleNameKey() {
        return "middle_name";
    }

    static get lastNameKey() {
        return "last_name";
    }

    static get countryKey() {
        return "country";
    }

    static get gamesKey() {
        return "games";
    }

    // #endregion

    // #region accessors/mutations 

    get ID() {
        return this._id;
    }

    set ID(ID) {
        this._id = ID;
    }

    get firstName() {
        return this._firstName;
    }

    set firstName(firstName) {
        this._firstName = firstName;
    }

    get middleName() {
        return this._middleName;
    }

    set middleName(middleName) {
        this._middleName = middleName;
    }

    get lastName() {
        return this._lastName;
    }

    set lastName(lastName) {
        this._lastName = lastName;
    }

    get country() {
        return this._country;
    }

    set country(country) {
        this._country = country;
    }

    /**
     *
     * @returns {[PhaseGame]}
     */
    get games() {
        return this._games;
    }

    set games(games) {
        this._games = games;
    }
    //#endregion

    constructor(modelID?: string, modelData?: any) {
        super();
        this.games = [];
        this.parseMinifiedData(modelData);
        if(this.ID !== undefined) {
            const modelId = DataModel.generateModelID(this, {"id": this.ID});
            if(modelId !== false) {
                this.modelID = modelId
            }
        } else if(modelID !== undefined){
            this.modelID = modelID;
        }

    }

    // #region FieldMappings Getters/Setters
    dataModelName() {
        return PhasePlayerDataModel.MODEL_NAME
    }

    getKeyByMinifiedKey(minKey: string) {
        switch (minKey) {
            case "0":
                return PhasePlayerDataModel.IDKey;
            case "1":
                return PhasePlayerDataModel.firstNameKey;
            case "2":
                return PhasePlayerDataModel.middleNameKey;
            case "3":
                return PhasePlayerDataModel.lastNameKey;
            case "4":
                return PhasePlayerDataModel.countryKey;
            case "5":
                return PhasePlayerDataModel.gamesKey;
            default:
                return ""
        }
    }

    setProperty(propertyKey: string, value: any) {
        let games
        switch (propertyKey) {
            case PhasePlayerDataModel.IDKey:
                this.ID = parseInt(value);
                break;
            case PhasePlayerDataModel.firstNameKey:
                this.firstName = value;
                break;
            case PhasePlayerDataModel.middleNameKey:
                this.middleName = value;
                break;
            case PhasePlayerDataModel.lastNameKey:
                this.lastName = value;
                break;
            case PhasePlayerDataModel.countryKey:
                this.country = value;
                break;
            case PhasePlayerDataModel.gamesKey:
                games = value;
                for(const gameIndex in games) {
                    if(Object.prototype.hasOwnProperty.call(games, gameIndex)) {
                        let gameModel = null;
                        if(games[gameIndex] instanceof DataModel) {
                            gameModel = games[gameIndex]
                        } else {
                            gameModel = DataModelMapper.createFromObject(games[gameIndex]);
                        }
                        if(this.games === undefined) {
                            this.games = []
                        }
                        if(gameModel instanceof PhaseGameDataModel && gameModel.sequence !== undefined) {
                            this.games[gameModel.sequence - 1] = gameModel;
                        }
                    }
                }
                break;
        }
    }

    getProperty(propertyKey: string) {
        switch (propertyKey) {
            case PhasePlayerDataModel.IDKey:
                return this.ID;
            case PhasePlayerDataModel.firstNameKey:
                return this.firstName;
            case PhasePlayerDataModel.middleNameKey:
                return this.middleName;
            case PhasePlayerDataModel.lastNameKey:
                return this.lastName;
            case PhasePlayerDataModel.countryKey:
                return this.country;
            case PhasePlayerDataModel.gamesKey:
                return this.games;
        }
    }
    //#endregion
}