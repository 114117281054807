<template>
    <div @click="isDisabled ? null : $emit('click')" class='badminton-action-box' :class="{ selected: isSelected, disabled: isDisabled }" >
        <slot></slot>
    </div>
</template>
<script lang="ts">

import { defineComponent } from "vue";

export default defineComponent({
  props: {
    isSelected: {
        type: Boolean,
        required: false
    },
    isDisabled: {
        type: Boolean,
        required: false
    }
  },
  emits: ['click'],
})
</script>
<style>

.badminton-action-box {
    --hover-bck-color: #f1f1f1;
    cursor: pointer;
    color: var(--darker-font-clr);
    display: flex;
    padding: 0.5ch 1ch;
    border: 2px solid #ccc;
    border-radius: var(--default-border-rad);
    min-height: 4.5ch;
    min-width: 18ch;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0.25ch;
    font-size: 1.25em;
    font-weight: 500;
}

.badminton-action-box:hover, .badminton-action-box.disabled{
    background-color: var(--hover-bck-color);
}

.badminton-action-box.disabled {
    cursor: no-drop;
}

.badminton-action-box.selected {
    background-color: #414141;
    color: #DADADA;
}
</style>