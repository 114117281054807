<template>
  <TheOrganizerDashboard title="User Management" class="usr-management">
	<div class="users-container-heading">
		<div class="users-filters">
			<div class="spz-text-input-group narrow">
				<label for="search-term">Search:</label>
				<input @input="onSearchTermInput" id="search-term" type="text" placeholder="Search">
			</div>
			<SportzoneSelect @selectOptionChange="onSelectNationality" id="nationality-filter"
				v-model:selectedOption="nationalityFilter"
				:options="['Nationality', ...nationalitiesComputed]">
			</SportzoneSelect>
		</div>
		<div class="users-actions">
			<button  @click="onCreateBtnClicked(), $refs.userPopup.openModal()" class="btn add-usr-btn" title="Create User">
				User
			</button>
		</div>
	</div>

    <div class="users-container" @scroll="onUsersWrapperScroll">
        <UserItem 
		v-for="user in getUsers(userSearchTerm, nationalityFilter)" :key="user.id" 
		:user="user"
		@edit="onEditBtnClicked(user), $refs.userPopup.openModal()"
		@delete="deleteUser(user.id)"
		@merge="onMergeBtnClicked(user), $refs.mergePopup.openModal()"
        :isSelected="selectedUserId === user.id">
        </UserItem>
    </div>
    <Modal ref="userPopup">
      <template v-slot:header>
        <h1>Create User</h1>
      </template>
      <template v-slot:body>
        <UserForm
          @onFormSubmit="onFormSubmit"
          v-model:id="newUser.id"
          v-model:firstName="newUser.firstName"
          v-model:middleName="newUser.middleName"
          v-model:lastName="newUser.lastName"
          v-model:sex="newUser.sex"
          v-model:email="newUser.email"
          v-model:birthday="newUser.birthday"
          v-model:nationality="newUser.nationality"
        ></UserForm>
      </template>
      <template v-slot:footer>
        <div>
          <button class="btn" @click="$refs.userPopup.closeModal(), onFormSubmit()">
            Save
          </button>
          <button class="btn" @click="$refs.userPopup.closeModal()">Cancel</button>
        </div>
      </template>
    </Modal>
	<StepBasedModal ref="mergePopup" @onApplyClick="onMergeFormSubmit" :start-step="mergeProcessStep"
		:action-process-total-steps="mergeProcessTotalSteps" :step-validators="stepValidators">
		<template #header>
			<h1>Merge {{ newUser.firstName }}{{ newUser.middleName ? ' ' + newUser.middleName : '' }} {{ newUser.lastName }}</h1>
		</template>
		<template #popup-step-1>
			<div class="popup-step">
				<div class="users-filters">
					<div class="spz-text-input-group narrow">
						<label for="search-term">Search:</label>
						<input @input="onSearchTermInput" id="search-term" :value="userSearchTerm" type="text" placeholder="Search">
					</div>
					<SportzoneSelect @selectOptionChange="onSelectNationality" id="nationality-filter"
						v-model:selectedOption="nationalityFilter"
						:options="['Nationality', ...nationalitiesComputed]">
					</SportzoneSelect>
				</div>
				<div class="users-wrapper" @scroll="onUsersWrapperScroll">
					<template v-for="user in getUsers(userSearchTerm, nationalityFilter)">
						<div @click="toggleUserItemSelect(user)" v-show="user.id != newUser.id"
							class="spz-user-item small-item badge-item selectable-item"
							:class="{ 'selected-item': selectedUser?.id == user.id }">
							<div class="badge-item-col spz-user-item-id">
								<div class="badge-info-heading">
									ID
								</div>
								<div class="badge-info-value">
									{{ user.id }}
								</div>
							</div>
							<div class="badge-item-col spz-user-item-name">
								<div class="badge-info-heading">
									Name
								</div>
								<div class="badge-info-value">
									{{ user.firstName }} {{ user.lastName }}
								</div>
							</div>
							<div class="badge-item-col spz-user-item-email">
								<div class="badge-info-heading">
									Email
								</div>
								<div class="badge-info-value">
									{{ user.email }}
								</div>
							</div>
							<div class="badge-item-col spz-user-item-nat">
								<div class="badge-info-heading">
									Nationality
								</div>
								<div class="badge-info-value">
									{{ user.nationality }}
								</div>
							</div>
							<div class="badge-item-col spz-user-item-sex">
								<div class="badge-info-heading">
									Sex
								</div>
								<div class="badge-info-value">
									{{ user.sex }}
								</div>
							</div>

						</div>
					</template>
				</div>
			</div>
		</template>
		<template #popup-step-2>
			<div class="popup-step">
				<div class="keep-user-wrapper">
					<div class="badge-info-heading">Keep User</div>
					<div class="user-preview-badge">
						<div class="user-preview-badge-img-wrapper">
							<object :data="'/api/user/' + newUser.id + '/picture'" >
								<img src="/default-profile-pic.svg" alt="Default Profile Picture" />
							</object>
						</div>
						<div class="user-preview-badge-info-wrapper">
							<div class="badge-item-col">
								<div class="badge-info-heading">
									ID
								</div>
								<div class="badge-info-value">
									{{ newUser.id }}
								</div>
							</div>
							<div class="badge-item-col">
								<div class="badge-info-heading">
									Name
								</div>
								<div class="badge-info-value">
									{{ newUser.firstName }}{{ newUser.middleName ? ' ' + newUser.middleName : '' }} {{ newUser.lastName }}
								</div>
							</div>
							<div class="badge-item-col">
								<div class="badge-info-heading">
									Email
								</div>
								<div class="badge-info-value">
									{{ newUser.email }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="remove-user-wrapper">
					<div class="badge-info-heading">Remove User</div>
					<div class="user-preview-badge" v-if="selectedUser">
						<div class="user-preview-badge-img-wrapper">
							<object :data="'/api/user/' + selectedUser.id + '/picture'" >
								<img src="/default-profile-pic.svg" alt="Default Profile Picture" />
							</object>
						</div>
						<div class="user-preview-badge-info-wrapper">
							<div class="badge-item-col">
								<div class="badge-info-heading">
									ID
								</div>
								<div class="badge-info-value">
									{{ selectedUser.id }}
								</div>
							</div>
							<div class="badge-item-col">
								<div class="badge-info-heading">
									Name
								</div>
								<div class="badge-info-value">
									{{ selectedUser.firstName }}{{ selectedUser.middleName ? ' ' + selectedUser.middleName : '' }} {{ selectedUser.lastName }}
								</div>
							</div>
							<div class="badge-item-col">
								<div class="badge-info-heading">
									Email
								</div>
								<div class="badge-info-value">
									{{ selectedUser.email }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template #action-btn>
			<button class="btn delete-btn">Merge</button>
		</template>
	</StepBasedModal>
  </TheOrganizerDashboard>
</template>
<script lang="ts">
import { computed, defineComponent, Ref, ref } from "vue";
import { createNamespacedHelpers, useStore } from "vuex";
import { key } from "@/store";
import Modal from "@/components/main/Modal.vue";
import StepBasedModal from "@/components/main/StepBasedModal.vue";
import UserItem from "@/components/main/UserItem.vue";
import TheOrganizerDashboard from "@/components/main/TheOrganizerDashboard.vue";
import UserForm from "@/components/main/UserForm.vue";
import { useRouter } from "vue-router";
import { User } from "@/models/main/User";
import { DefaultUser } from "@/store/modules/main/UserStore";
import { SPORT_TYPES } from "@/models/main/CommonTypes";
import SportzoneSelect from '@/components/util/SportzoneSelect.vue';
const nationalities = require('i18n-nationality');
nationalities.registerLocale(require('i18n-nationality/langs/en.json'));

const userStoreHelpers = createNamespacedHelpers('userStore')

export default defineComponent({
    components: {
      TheOrganizerDashboard,
        UserForm,
        Modal,
        UserItem,
		SportzoneSelect,
		StepBasedModal,
    },
    computed: {
        ...userStoreHelpers.mapGetters(
            ['getUsers']
        )
    },
    setup() {
        const router = useRouter();
        const store = useStore(key);
        const onFormSubmit = async () => {
			if(newUser.value.id > 0) {
				await store.dispatch("userStore/editUser", newUser.value)
			} else {
				await store.dispatch("userStore/createUser", newUser.value)
			}
        };

        const deleteUser = async (userId: number) => {
            if(userId !== 0) {
                await store.dispatch("userStore/deleteUser", userId)
            } else {
                console.warn("Select User!")
            }
        }
        
        const loadUsers = async (searchTerm?: string, nationality?: string) => {
            await store.dispatch('userStore/listUsers', {
                searchTerm,
                nationality
            });
        };

        loadUsers()

		const mergePopup: Ref<any> = ref()
		const userPopup: Ref<any> = ref()
        const defaultUser: User = new DefaultUser();
        const newUser: any = ref(defaultUser);
		const onEditBtnClicked = (user: User) => {
			newUser.value = user
		}
		const onCreateBtnClicked = () => {
			newUser.value = defaultUser
		}

		const onMergeBtnClicked = (user: User) => {
			newUser.value = user
		}

		const selectedUser: Ref<User | undefined> = ref()
		const mergeProcessStep: Ref<number> = ref(1);
        const mergeProcessTotalSteps = ref(2);
        const stepValidators: Array<Function> = [
            () => {
                return true;
            },
            () => {
                return true;
            }
        ];

		const onMergeFormSubmit = async () => {
            console.info("MERGE USER", newUser.value, selectedUser.value);
			if(selectedUser.value) {
				await store.dispatch("userStore/deleteUser", selectedUser.value.id)
			}
        };
		
        const selectedUserId: Ref<number> = ref(0)
		const userSearchTerm: Ref<string | undefined> = ref();
        const nationalityFilter: Ref<string> = ref('Nationality');
		const onSearchTermInput = async (event: any) => {
            userSearchTerm.value = event.target.value;
            if (userSearchTerm.value !== undefined && (userSearchTerm.value.length >= 3 || userSearchTerm.value.length === 0)) {
                selectedUser.value = undefined;
                loadUsers(userSearchTerm.value, nationalityFilter.value);
            }
        };
        const onSelectNationality = () => {
            selectedUser.value = undefined;
            loadUsers(userSearchTerm.value, nationalityFilter.value);
        };
        const toggleUserItemSelect = (user: User) => {
            if (user && user.id) {
                if (selectedUser.value && selectedUser.value.id === user.id) {
                    selectedUser.value = undefined
                    return;
                }
                selectedUser.value = user;
            }
        };
        const nationalitiesComputed = computed(() => {
            const list = nationalities.getNames('en');
            const result = Object
                .keys(list)
                .map((key) => (list[key]));
            return result;
        });

        let scrollTimeout: any;
        let loadInProcess: Ref<boolean> = ref(false);
        const onUsersWrapperScroll = (event: any) => {
            const totalScrollHeight = event.target.scrollHeight;
            const scrollTop = event.target.scrollTop;
            const offsetHeight = event.target.offsetHeight;
            const margin = 20;
            let bottomOfWindow = totalScrollHeight - scrollTop - offsetHeight < margin;
            if (bottomOfWindow) {
                if (scrollTimeout) {
                    clearTimeout(scrollTimeout);
                }
                scrollTimeout = setTimeout(onScrollStopped, 200);
            }
        };
        const onScrollStopped = () => {
            if (!loadInProcess.value) {
                loadInProcess.value = true;
                loadUsers(userSearchTerm.value, nationalityFilter.value)
                    .then(() => {
                        loadInProcess.value = false;
                    });
            }
        };
        return {
            store,
            router,
            newUser,
            selectedUserId,
			nationalitiesComputed,
			nationalityFilter,
			userSearchTerm,
			userPopup,
			mergePopup,
			onMergeFormSubmit,
			mergeProcessStep,
			stepValidators,
			mergeProcessTotalSteps,
			selectedUser,
			onMergeBtnClicked,
			onCreateBtnClicked,
			onEditBtnClicked,
            onFormSubmit,
            deleteUser,
			onSelectNationality,
			onUsersWrapperScroll,
			toggleUserItemSelect,
			onSearchTermInput,
			SPORT_TYPES,
        };
    },
});
</script>

<style>
.users-container {
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	scrollbar-width: thin;
}

.usr-management .users-filters .spz-text-input-group {

	max-width: 20em;
	margin-right: 1em;
}
.usr-management .users-filters .spz-select {
	margin-right: 1em;
}

.usr-management .users-filters {
    width: 50%;
}

.users-container-heading {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.users-actions {
	width: 50%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.user-preview-badge {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1em;
	margin-bottom: 1em;
	background-color: var(--bright-font-clr);
	border-radius: var(--default-border-rad);
}
.user-preview-badge-img-wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
}

.user-preview-badge-img-wrapper, .user-preview-badge-info-wrapper {
	width: 50%;
}
.user-preview-badge-img-wrapper object, .user-preview-badge-img-wrapper img {
	max-width: min(8em, 100%);
	max-height: min(8em, 100%);
	height: 100%;
	width: 100%;
}
</style>
