import { ActionContext, Module } from 'vuex'
import GenericDictionary from '@/components/util/GenericDictionary'
import { PagedResponse, PaginatedItemsStoreGetters, PaginatedItemsStoreMutations } from '@/composables/common/Pagination'
import { UserNotification } from '@/models/main/UserNotification'
import authModule from '@/main'
import { IRootState } from '@/store'
export interface UserNotificationStore {
    paginatedItems: PagedResponse<UserNotification>,
    notifications: GenericDictionary<UserNotification>
}
const state: UserNotificationStore = {
    paginatedItems: {
        items: {},
        pageSize: 30,
        currentPage: 1
    },
    notifications: {}
}
const getters = {
    getNotification(state: UserNotificationStore, notificationId: number) {
        return state.notifications[notificationId]
    },
    getNotifications(state: UserNotificationStore) {
        const notifications: Array<UserNotification> = []
        for (const notificationId in state.notifications) {
            if (Object.prototype.hasOwnProperty.call(state.notifications, notificationId)) {
                const notification = state.notifications[notificationId]
                notifications.push(notification)
            }
        }
        return notifications
    },
    ...PaginatedItemsStoreGetters()
}
const mutations = {
    addNotification(state: UserNotificationStore, notification: UserNotification) {
        if (notification.id !== undefined) {
            state.notifications[notification.id] = notification
        }
    },
    removeNotification(state: UserNotificationStore, deletedNotificationId: number) {
        if (Object.prototype.hasOwnProperty.call(state.notifications, deletedNotificationId)) {
            delete state.notifications[deletedNotificationId]
        }
    },
    ...PaginatedItemsStoreMutations()
}
const actions = {
    getNotification({ commit }: ActionContext<UserNotificationStore, IRootState>, notificationId: number) {
        return authModule
            .get(`/api/user/notification/${notificationId}`)
            .then((response) => {
                const notification: UserNotification = response.data as unknown as UserNotification
                commit('addNotification', notification)
            })
    },
    listNotifications({ commit, getters }: ActionContext<UserNotificationStore, IRootState>) {
        const pageToRequest = getters['getNextPage']
        const pageSize = getters['getPageSize']
        if (getters['getNextPage'] === 0 && getters['getCurrentPage'] > 0) {
            return
        }
        return authModule
            .get(`/api/user/notification?page=${pageToRequest}&pageSize=${pageSize}`)
            .then((response) => {
                const pagedResponse: PagedResponse<UserNotification> = response.data as unknown as PagedResponse<UserNotification>
                commit('setCurrentPage', pagedResponse.currentPage)
                commit('setPageSize', pagedResponse.pageSize)
                commit('setNextPage', pagedResponse.nextPage)
                commit('setPreviousPage', pagedResponse.previousPage)
                for (const notificationIndex in pagedResponse.items) {
                    commit('addNotification', pagedResponse.items[notificationIndex])
                }
            })
    },
    createNotification({ commit }: ActionContext<UserNotificationStore, IRootState>, notification: UserNotification) {
        return authModule
            .post(`/api/user/notification`, notification)
            .then((response) => {
                const createdNotification: UserNotification = response.data as unknown as UserNotification
                commit('addNotification', createdNotification)
            })
    },
    editNotification({ commit }: ActionContext<UserNotificationStore, IRootState>, notification: UserNotification) {
        return authModule
            .patch(`/api/user/notification/${notification.id}`, notification)
            .then((response) => {
                const editedNotification: UserNotification = response.data as unknown as UserNotification
                commit('addNotification', editedNotification)
            })
    },
    deleteNotification({ commit }: ActionContext<UserNotificationStore, IRootState>, notificationId: number) {
        return authModule
            .delete(`/api/user/notification/${notificationId}`)
            .then((response) => {
                const deletedNotificationId: number = response.data as number
                commit('removeNotification', deletedNotificationId)
            })
    }
}
const userNotificationStore: Module<UserNotificationStore, IRootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
export default userNotificationStore