import { Organization } from "./Organization"
import { User } from "./User"

export const ORGANIZATION_FOLLOW_TYPES = ["Default"]
export type OrganizationFollowType = typeof ORGANIZATION_FOLLOW_TYPES[number]

export interface OrganizationFollow {
    organizationId: number,
    organization?: Organization,
    userId: number,
    user?: User,
    type: OrganizationFollowType
}