<template>
    <TheOrganizerDashboard>
        <template #heading v-if="organization">
            <router-link :to="{ name: 'Organizations' }">
                <div class="page-heading">
                    Organizations
                </div>
            </router-link>
            <router-link :to="{ name: 'Organization Menu', params: { id: organization?.id } }">
                <div class="page-heading">
                    {{ organization?.name }}
                </div>
            </router-link>
            <router-link :to="{ name: 'Organization Team Management', params: { id: organization?.id } }">
                <div class="page-heading">
                    {{ $c('entity', currentSport) }}s
                </div>
            </router-link>
        </template>
        <div class="actions-wrapper">
            <div class="entity-actions-wrapper">
                <!-- <SportzoneSelect @selectOptionChange="onSelectOptionChange" id="new-match-sport"
          :options="['Sport', ...SPORT_TYPES]" v-model:selectedOption="currentSport">
        </SportzoneSelect> -->
                <div class="spz-text-input-group narrow">
                    <label for="search-team" v-if="false">Search Team:</label>
                    <input @input="filterTeams" id="search-team" type="search" placeholder="Search">
                </div>
                <div class="spz-select-group narrow">
                    <label for="season-filter">Season:</label>
                    <SportzoneSelect @selectOptionChange="onSeasonFilterChange" id="season-filter"
                        v-model:selectedOption="seasonFilter" :options="[...organizationSeasonNames]" />
                </div>
                <button @click="entityPopup.openModal(), onCreateEntityButtonClicked()" class="btn add-btn"
                    :title="'Create' + $c('entity', currentSport)">{{ $c('entity', currentSport) }}</button>
            </div>

            <div class="player-actions-wrapper">
                <button @click="playerPopup.openModal(), onCreatePlayerButtonClicked()" v-show="currentEntityId !== 0"
                    class="btn add-btn" title="Add Player">Player</button>
            </div>
        </div>
        <div class="entity-management-section">
            <div @scroll="onEntitiesWrapperScroll" class="entities-container">
                <EntityItem @click="onEntityItemClick(entity.id)"
                    @edit="entityPopup.openModal(), onEditEntityButtonClicked(entity)"
                    @delete="openConfirmDeleteEntityDialog(entity)" :isSelected="entity.id === currentEntityId"
                    :isEditable="true"
                    v-for="entity in getEntities(currentSport, searchedTeam, undefined, organizationId, seasonFilterId)"
                    :key="entity.id" :entity="entity">
                    {{ entity.name }}
                </EntityItem>
            </div>
            <div class="players-container">
                <PlayerItem @click="selectedPlayerId = player.id"
                    @edit="playerPopup.openModal(), onEditPlayerButtonClicked(player)"
                    @delete="openConfirmDeletePlayerDialog(player)"
                    v-for="player in getEntity({ entityId: currentEntityId })?.players" :key="player.id"
                    :player="player">
                </PlayerItem>
            </div>
        </div>
        <Modal ref="entityPopup">
            <template v-slot:header>
                <h1>{{ isCreate ? 'Create ' + $c('entity', currentSport) : 'Edit ' + $c('entity', currentSport) }}</h1>
            </template>
            <template v-slot:body>
                <div class="spz-img-input-group entity-logo-input">
                    <label for="entity-logo">
                        <img :src="currentEntityLogoUrl" @error="setDefaultEntityLogo" :alt="dialogEntity.name"
                            width="68" height="68" />
                    </label>
                    <input v-show="false" type="file" id="entity-logo" @change="onLogoChange"
                        accept="image/jpeg, image/png, image/webp, image/avif" />
                </div>
                <div class="spz-text-input-group">
                    <label for="team_name">Name</label>
                    <input v-model="dialogEntity.name" type="text" id="team_name" placeholder="Name" />
                </div>
                <div class="spz-select-group">
                    <label for="new-entity-sport">Sport</label>
                    <select id="new-entity-sport" v-model="dialogEntity.sport">
                        <option v-for="sport in SPORT_TYPES" :key="sport" :value="sport">{{ sport }}</option>
                    </select>
                </div>
                <div class="spz-select-group">
                    <label for="new-entity-type">Type</label>
                    <select id="new-entity-type" v-model="dialogEntity.type">
                        <option v-for="entityType in ENTITY_TYPES" :key="entityType" :value="entityType">
                            {{ entityType }}
                        </option>
                    </select>
                </div>
                <div class="spz-select-group combobox-style-select">
                    <label for="new-entity-season">Season</label>
                    <select id="new-entity-season" v-model="selectedSeasonIdToAdd">
                        <option :value="null">All Time</option>
                        <option v-for="season in nonAssignedEntitySeasons" :key="season?.id ?? 0" :value="season?.id">{{
            season?.name }}</option>
                    </select>
                    <button @click="onSeasonAddClick" class="btn btn-inverse add-season-btn" role="button"
                        title="Add Season">+ Season</button>
                </div>
                <div class="entity-assigned-seasons">
                    <div class="assigned-season" v-for="season in dialogEntity.seasons">
                        <div class="assigned-season__name">
                            {{ season?.name }}
                        </div>
                        <div class="assinged-season__actions">
                            <button @click="onSeasonDeleteClick(season.id)" class="remove-season-btn" role="button"
                                title="Remove Season">X</button>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div>
                    <button @click="entityPopup.closeModal(), onFormSubmitEntity()" class="btn"
                        :class="{ 'add-btn': isCreate, 'edit-btn': !isCreate }">
                        {{ isCreate ? 'Create' : 'Edit' }}</button>
                    <button @click="entityPopup.closeModal()" class="btn btn-inverse">Cancel</button>
                </div>
            </template>
        </Modal>
        <Modal ref="playerPopup">
            <template v-slot:header>
                <h1>{{ isCreate ? 'Create Player' : 'Edit Player' }}</h1>
            </template>
            <template v-slot:body>
                <div class="spz-img-input-group narrow player-picture-input">
                    <label for="player-picture">
                        <img :src="currentPlayerPictureUrl" @error="setDefaultPlayerPicture" :alt="newPlayer.firstName"
                            width="68" height="68" />
                    </label>
                    <input v-show="false" type="file" id="player-picture" @change="onPlayerPictureChange"
                        accept="image/jpeg, image/png, image/webp, image/avif" />
                </div>
                <div class="spz-text-input-group connected-member-section">
                    <span class="member-player-connection" :class="{ 'connected': newPlayer.membership }">
                        {{ newPlayer.membership ? 'Connected to member:' : 'Not connected' }}
                    </span>
                    <OrganizationMemberItem v-if="selectedMember" :member="selectedMember" :editable="false"
                        @click="onMemberSelect(selectedMember)" :is-selected="true" />
                </div>
                <div class="spz-text-input-group">
                    <label for="player_number">Player Number</label>
                    <input v-model="newPlayer.number" type="text" id="player_number" placeholder="Example: 7"
                        required />
                </div>
                <div class="spz-text-input-group narrow">
                    <label for="search-term" v-if="false">Search: </label>
                    <input v-model="organizationMemberStore.searchTerm" id="search-term" type="search"
                        placeholder="Search">
                </div>
                <div class="spz-select-group narrow">
                    <SportzoneSelect id="nationality-filter"
                        v-model:selectedOption="organizationMemberStore.nationality"
                        :options="['Nationality', ...nationalitiesComputed.map(a => a.value)]" />
                </div>
                <div class="members-wrapper"
                    v-infinite-scroll="[organizationMemberStore.fetchOrganizationMembers, { interval: 400, canLoadMore: () => { return !organizationMemberStore.showLoadingIcon && organizationMemberStore.thereIsNextMembersPage } }]">
                    <template v-for="member in organizationMemberStore.getOrganizationMembers" :key="member.id">
                        <OrganizationMemberItem v-if="selectedMember?.id !== member.id" :member="member"
                            :editable="false" @click="onMemberSelect(member)" />
                    </template>
                    <LoadingIcon v-show="organizationMemberStore.showLoadingIcon" />
                </div>
                <div class="spz-text-input-group" v-show="showPlayerForm">
                    <label for="first_name">First Name</label>
                    <input v-model.lazy="newPlayer.firstName" type="text" id="first_name" required />
                </div>
                <div class="spz-text-input-group" v-show="showPlayerForm">
                    <label for="middle_name">Middle Name</label>
                    <input v-model.lazy="newPlayer.middleName" type="text" id="middle_name" />
                </div>
                <div class="spz-text-input-group" v-show="showPlayerForm">
                    <label for="last_name">Last Name</label>
                    <input v-model.lazy="newPlayer.lastName" type="text" id="last_name" />
                </div>
                <div class="spz-text-input-group" v-show="showPlayerForm">
                    <label for="birthday">Birthday</label>
                    <input v-model.lazy="newPlayer.birthday" type="text" id="birthday" />
                </div>
                <div class="spz-multi-toggle-group" v-show="showPlayerForm">
                    <div class="spz-multi-toggle-name">Sex</div>
                    <div class="spz-multi-toggle_options">
                        <div class="spz-multi-toggle_option">
                            <input type="radio" id="sex-female" value="Female" name="sex" v-model="newPlayer.sex"
                                required />
                            <label for="sex-female">Female</label>
                        </div>
                        <div class="spz-multi-toggle_option">
                            <input type="radio" id="sex-male" value="Male" name="sex" v-model="newPlayer.sex"
                                required />
                            <label for="sex-male">Male</label>
                        </div>
                        <div class="spz-multi-toggle_option">
                            <input type="radio" id="sex-other" value="Other" name="sex" v-model="newPlayer.sex"
                                required />
                            <label for="sex-other">Other</label>
                        </div>
                    </div>
                </div>
                <div class="spz-select-group" v-show="showPlayerForm">
                    <label for="nationality">Nationality</label>
                    <select id="nationality" v-model="newPlayer.nationality">
                        <option disabled value="">Please select one</option>
                        <option v-for="nationality in nationalitiesComputed" :value="nationality.value">
                            {{ nationality.label }}
                        </option>
                    </select>
                </div>
                <div class="spz-text-input-group" v-show="showPlayerForm">
                    <label for="height">Height</label>
                    <input v-model.lazy="newPlayer.height" id="height" type="text" placeholder="Height" />
                </div>
                <div class="spz-text-input-group" v-show="showPlayerForm">
                    <label for="weight">Weight</label>
                    <input v-model.lazy="newPlayer.weight" id="weight" type="text" placeholder="Weight" />
                </div>
                <div class="spz-multi-toggle-group">
                    <div class="spz-multi-toggle_options">
                        <div class="spz-multi-toggle_option">
                            <input type="radio" id="active" value="true" name="active" v-model="newPlayer.isActive" />
                            <label for="active">Active</label>
                        </div>
                        <div class="spz-multi-toggle_option">
                            <input type="radio" id="inactive" value="false" name="active"
                                v-model="newPlayer.isActive" />
                            <label for="inactive">Inactive</label>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div>
                    <button @click="playerPopup.closeModal(), onFormSubmitPlayer()" class="btn"
                        :class="{ 'add-btn': isCreate, 'edit-btn': !isCreate }">
                        {{ isCreate ? 'Create' : 'Edit' }}</button>
                    <button @click="playerPopup.closeModal()" class="btn btn-inverse">Cancel</button>
                </div>
            </template>
        </Modal>
        <SportzoneDialog ref="confirmEntityDialog" @close="showConfirmEntityDialog = false"
            :show="showConfirmEntityDialog" class="mini">
            <template #header>
                Delete {{ $c('entity', currentSport) }}
            </template>
            <template v-slot:body>
                <div class="confirm-dialog-message">
                    {{ $c('entity', currentSport) }}: <strong>{{ dialogEntity?.name }}</strong> will be deleted. Do you
                    confirm this action?
                </div>
            </template>
            <template v-slot:footer>
                <button @click="$refs.confirmEntityDialog.closeModal(), confirmEntityDialogCallback()"
                    class="btn delete-btn">
                    Confirm
                </button>
                <button @click="$refs.confirmEntityDialog.closeModal()" class="btn btn-inverse">Cancel</button>
            </template>
        </SportzoneDialog>
        <SportzoneDialog ref="confirmPlayerDialog" @close="showConfirmPlayerDialog = false"
            :show="showConfirmPlayerDialog" class="mini">
            <template #header>
                Delete player
            </template>
            <template v-slot:body>
                <div class="confirm-dialog-message">
                    Player: <strong>{{ dialogPlayer }}</strong> will be deleted. Do you confirm this action?
                </div>
            </template>
            <template v-slot:footer>
                <button @click="$refs.confirmPlayerDialog.closeModal(), confirmPlayerDialogCallback()"
                    class="btn delete-btn">
                    Confirm
                </button>
                <button @click="$refs.confirmPlayerDialog.closeModal()" class="btn btn-inverse">Cancel</button>
            </template>
        </SportzoneDialog>
    </TheOrganizerDashboard>
</template>
<script setup lang="ts">
import TheOrganizerDashboard from '@/components/main/TheOrganizerDashboard.vue';
import SportzoneSelect from '@/components/util/SportzoneSelect.vue';
import { SPORT_TYPES, Sport } from '@/models/main/CommonTypes';
import EntityItem from '@/components/main/EntityItem.vue';
import PlayerItem from '@/components/main/PlayerItem.vue';
import Season from "@/models/main/Season";
import Modal from '@/components/main/Modal.vue';
import { ref, Ref, computed, onMounted, ComputedRef } from 'vue';
import { ENTITY_TYPES, Entity, EntityType } from '@/models/main/Entity';
import SportzoneDialog from '@/components/util/SportzoneDialog.vue';
import { useStore } from 'vuex';
import { Player } from '@/models/main/Player';
import { useRoute, useRouter } from 'vue-router';
import { key } from '@/store';
import { setDefaultPlayerPicture, setDefaultEntityLogo, getEntityLogoSrc, getUserLastSelectedSport, getUserLastVisitedOrganizationId, setUserLastSelectedSport, getPlayerPictureSrc } from '@/composables/common/Util';
import { Organization } from '@/models/main/Organization';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import useSeasonComposables from '@/composables/generic/UseSeasonComposables';
import OrganizationMemberItem from '@/components/main/OrganizationMemberItem.vue';
import { OrganizationMember } from '@/models/main/OrganizationMember';
import { useOrganizationMembersStoreStore } from '@/stores/OrganizationMembersStore'
import { vInfiniteScroll } from '@vueuse/components'
import LoadingIcon from '@/components/util/LoadingIcon.vue'

const nationalities = require('i18n-nationality')
nationalities.registerLocale(require('i18n-nationality/langs/en.json'))

const store = useStore(key);
const route = useRoute();
const $toast = useToast();
const organizationMemberStore = useOrganizationMembersStoreStore()
const routeOrgId: string | undefined = route.params.id ? route.params.id as string : undefined;
const lastVisitedOrgId = getUserLastVisitedOrganizationId()
const organizationId: Ref<number | undefined> = ref(routeOrgId ? parseInt(routeOrgId) : lastVisitedOrgId);
if(organizationId.value)
    organizationMemberStore.setOrganizationId(organizationId.value)
const organization: Ref<Organization | undefined> = ref();
const initialSportValue = getUserLastSelectedSport() ?? SPORT_TYPES[0]
const currentSport: Ref<Sport> = ref(initialSportValue);
let scrollTimeout: any;
let loadInProcess: Ref<boolean> = ref(false);
const currentEntityId: Ref<number> = ref(0);
const entityTypeFilter: Ref<EntityType | undefined> = ref(ENTITY_TYPES[3]) // TEAM
const myEntity: Entity = {
    id: 0,
    name: '',
    sport: initialSportValue,
    //   type: ENTITY_TYPES[3],
    additionalData: '',
    players: {},
    organizationId: organizationId.value ?? undefined
};

const nationalitiesComputed = computed(() => {
    const list = nationalities.getNames('en')
    return Object.keys(list).map((key) => ({ value: list[key], label: list[key] }))
})
const dialogEntity: Ref<Entity> = ref(myEntity);
const dialogPlayer: Ref<string> = ref('');
const showConfirmEntityDialog = ref(false);
const confirmEntityDialogCallback: Ref<Function> = ref(() => { });
const showConfirmPlayerDialog = ref(false);
const confirmPlayerDialogCallback: Ref<Function> = ref(() => { });
const newPlayer: Ref<Player> = ref({ sport: currentSport.value });
const playerPopup: Ref<any> = ref(null);
const entityPopup: Ref<any> = ref(null);
const selectedPlayerId: Ref<number> = ref(0);
const router = useRouter();
const isCreate: Ref<boolean> = ref(true);
const showPlayerForm: Ref<boolean> = ref(true);
let searchedTeam: Ref<string | undefined> = ref()
const onSelectOptionChange = () => {
    loadEntities();
};

const getEntities = store.getters['entityStore/getEntities']
const loadEntities = async () => {
    setUserLastSelectedSport(currentSport.value)
    myEntity.sport = currentSport.value
    await store.dispatch('entityStore/getEntities', {
        sport: currentSport.value,
        searchedTeam: searchedTeam.value,
        // type: entityTypeFilter.value,
        organizationId: organizationId.value,
        seasonId: seasonFilterId.value
    });
};
const onEntitiesWrapperScroll = (event: any) => {
    const totalScrollHeight = event.target.scrollHeight;
    const scrollTop = event.target.scrollTop;
    const offsetHeight = event.target.offsetHeight;
    const margin = 20;
    let bottomOfWindow = totalScrollHeight - scrollTop - offsetHeight < margin;
    if (bottomOfWindow) {
        if (scrollTimeout) {
            clearTimeout(scrollTimeout);
        }
        scrollTimeout = setTimeout(onScrollStopped, 200);
    }
};
const onScrollStopped = () => {
    if (!loadInProcess.value) {
        loadInProcess.value = true;
        loadEntities();
        loadInProcess.value = false;
    }
};
const onEntityItemClick = (entityId: number) => {
    currentEntityId.value = entityId;
};
const openConfirmDeleteEntityDialog = (entity: any) => {
    dialogEntity.value = getEntity({ entityId: entity.id });
    showConfirmEntityDialog.value = true;
    confirmEntityDialogCallback.value = async () => {
        await store.dispatch('entityStore/deleteEntity', entity.id);
        currentEntityId.value = 0;
        $toast.success('Entity deleted');
    };
};
const getEntity = store.getters['entityStore/getEntity']

const openConfirmDeletePlayerDialog = (player: any) => {
    dialogPlayer.value = `${player.firstName} ${player.lastName}`;
    showConfirmPlayerDialog.value = true;
    confirmPlayerDialogCallback.value = async () => {
        await store.dispatch('playerStore/deletePlayer', {
            playerId: player.id,
            entityId: currentEntityId.value,
        });
        $toast.success('Player deleted');
    };
};
const onFormSubmitEntity = async () => {
    if (isCreate.value) {
        if (dialogEntity.value.organizationId === undefined) {
            dialogEntity.value.organizationId = organizationId.value ?? undefined
        }
        if (dialogEntity.value.organizationId) {
            const createdEntity: Entity = await store.dispatch('entityStore/createEntity', dialogEntity.value);
            dialogEntity.value.id = createdEntity.id
            $toast.success('Created');
        }
    }
    else {
        await store.dispatch('entityStore/editEntity', dialogEntity.value);
        $toast.success('Edited');
    }

    if (entityLogoToUpload.value) {
        store.dispatch('entityStore/uploadLogo', { entityId: dialogEntity.value.id, entityLogo: entityLogoToUpload.value })
    }
};
const onFormSubmitPlayer = async () => {
    if (isCreate.value) {
        if (newPlayer.value && newPlayer.value?.number !== null && currentEntityId.value !== 0) {
            newPlayer.value.membershipId = selectedMember.value?.id
            newPlayer.value.entityId = currentEntityId.value;
            const createdPlayer = await store.dispatch('playerStore/createPlayer', newPlayer.value);
            newPlayer.value.id = createdPlayer.id;
            $toast.success('Created');
            if (playerPictureToUpload.value) {
                store.dispatch('playerStore/uploadPicture', { playerId: createdPlayer.id, playerPicture: playerPictureToUpload.value })
            }
        }
    }
    else {
        newPlayer.value.membershipId = selectedMember.value?.id
        await store.dispatch('entityStore/editPlayer', {
            entityId: currentEntityId.value,
            player: newPlayer.value,
        });
        if (playerPictureToUpload.value) {
            store.dispatch('playerStore/uploadPicture', { playerId: newPlayer.value.id, playerPicture: playerPictureToUpload.value })
        }
        $toast.success('Edited');
    }


};
const onCreateEntityButtonClicked = () => {
    isCreate.value = true;
    dialogEntity.value.id = 0;
    dialogEntity.value.name = '';
    //dialogEntity.value.sport = initialSportValue;
    // dialogEntity.value.type = 'Team';
    dialogEntity.value.seasons = []
    dialogEntity.value.additionalData = '';
    dialogEntity.value.players = {};
    currentEntityLogoUrl.value = getEntityLogoSrc(dialogEntity.value)
};
const onCreatePlayerButtonClicked = () => {
    isCreate.value = true;
    newPlayer.value.id = 0;
    newPlayer.value.number = undefined;
    newPlayer.value.firstName = '';
    newPlayer.value.middleName = '';
    newPlayer.value.lastName = '';
    newPlayer.value.birthday = undefined;
    // newPlayer.value.sex = undefined;
    newPlayer.value.nationality = 'Bulgarian';
    newPlayer.value.height = '';
    newPlayer.value.weight = '';
    newPlayer.value.isActive = true;
    newPlayer.value.membership = undefined
    selectedMember.value = undefined
    currentPlayerPictureUrl.value = getPlayerPictureSrc(newPlayer.value)
    organizationMemberStore.fetchOrganizationMembers()
};
const onEditEntityButtonClicked = (entity: any) => {
    isCreate.value = false;
    dialogEntity.value.id = entity.id;
    dialogEntity.value.name = entity.name;
    dialogEntity.value.sport = entity.sport;
    dialogEntity.value.type = entity.type;
    currentEntityLogoUrl.value = getEntityLogoSrc(dialogEntity.value)
    dialogEntity.value.seasons = entity.seasons
};
const onEditPlayerButtonClicked = (player: any) => {
    isCreate.value = false;
    newPlayer.value.id = player.id;
    newPlayer.value.number = player.number;
    newPlayer.value.firstName = player.firstName;
    newPlayer.value.middleName = player.middleName;
    newPlayer.value.lastName = player.lastName;
    newPlayer.value.birthday = player.birthday;
    newPlayer.value.sex = player.sex;
    newPlayer.value.nationality = player.nationality;
    newPlayer.value.height = player.height;
    newPlayer.value.weight = player.weight;
    newPlayer.value.isActive = player.isActive;
    newPlayer.value.membership = player.membership
    selectedMember.value = player.membership
    if (selectedMember.value) {
        showPlayerForm.value = false
    }
    currentPlayerPictureUrl.value = getPlayerPictureSrc(newPlayer.value)
    organizationMemberStore.fetchOrganizationMembers()
}
const filterTeams = async (event: any) => {
    searchedTeam.value = event.target.value;
    if (searchedTeam.value !== undefined && (searchedTeam.value.length >= 3 || searchedTeam.value.length === 0)) {
        await store.dispatch('entityStore/getEntities',
            {
                sport: currentSport.value,
                searchedTeam: searchedTeam.value,
                //   type: entityTypeFilter.value,
                seasonId: seasonFilterId.value,
                organizationId: organizationId.value
            }
        );
    }
};
const loadOrganization = async () => {
    if (organizationId.value === null) {
        const organizations = await store.dispatch('organizationStore/fetchOrganizations', { sport: currentSport.value })
        if (organizations && organizations.items.length > 0 && organizations.items[0]) {
            organization.value = organizations.items[0]
            organizationId.value = organizations.items[0].id
        }
    } else {
        organization.value = await store.dispatch('organizationStore/getOrganization', organizationId.value)
    }
    if (organization.value) {
        currentSport.value = organization.value.sport
        setUserLastSelectedSport(currentSport.value);
    }
}

const currentEntityLogoUrl: Ref<any> = ref(getEntityLogoSrc(dialogEntity.value))
const entityLogoToUpload: Ref<File | undefined> = ref()
const onLogoChange = (e: Event) => {
    if (e.target) {
        const inputElement = e.target as HTMLInputElement
        if (inputElement.files && inputElement.files.length > 0) {
            const imageFile = inputElement.files[0];
            const tempImageURL = URL.createObjectURL(imageFile)
            currentEntityLogoUrl.value = tempImageURL
            entityLogoToUpload.value = imageFile
        }
    }
}
const currentPlayerPictureUrl: Ref<any> = ref(getPlayerPictureSrc(newPlayer.value))
const playerPictureToUpload: Ref<File | undefined> = ref()
const onPlayerPictureChange = (e: Event) => {
    if (e.target) {
        const inputElement = e.target as HTMLInputElement
        if (inputElement.files && inputElement.files.length > 0) {
            const imageFile = inputElement.files[0];
            const tempImageURL = URL.createObjectURL(imageFile)
            currentPlayerPictureUrl.value = tempImageURL
            playerPictureToUpload.value = imageFile
        }
    }
}

const {
    seasonFilterId,
    seasonFilter,
    organizationSeasonNames,
    organizationSeasons,
    loadSeasons,
} = useSeasonComposables(organizationId);

// const { loadEntities } = useEntityComposables()
const onSeasonFilterChange = async () => {
    if (organizationSeasons.value) {
        loadEntities()
    }
}

const selectedSeasonIdToAdd: Ref<number | null> = ref(null)
const nonAssignedEntitySeasons = computed(() => {
    const nonAssignedEntitySeasons: Season[] = []
    if (organizationSeasons.value) {
        for (const season of organizationSeasons.value?.values()) {
            if (season !== null && dialogEntity.value?.seasons?.find(s => s.id === season?.id) === undefined) { // not found => not assigned
                nonAssignedEntitySeasons.push(season)
            }
        }
    }

    return nonAssignedEntitySeasons;
})

const onSeasonAddClick = () => {
    if (selectedSeasonIdToAdd.value) {
        const seasonToAdd = nonAssignedEntitySeasons.value.find(s => s.id === selectedSeasonIdToAdd.value)
        if (seasonToAdd) {
            if (!dialogEntity.value.seasons) {
                dialogEntity.value.seasons = []
            }

            dialogEntity.value.seasons?.push(seasonToAdd)
            selectedSeasonIdToAdd.value = null
        }
    }
}

const onSeasonDeleteClick = (seasonId: number) => {
    if (dialogEntity.value.seasons) {
        const i = dialogEntity.value.seasons.findIndex(s => s.id === seasonId) ?? -1;
        if (i >= 0) {
            dialogEntity.value.seasons.splice(i, 1);
        }
    }
}


const selectedMember: Ref<OrganizationMember | undefined> = ref()
const onMemberSelect = (member: OrganizationMember) => {
    selectedMember.value = member
    newPlayer.value.firstName = member.firstName
    newPlayer.value.middleName = member.middleName
    newPlayer.value.lastName = member.lastName
    newPlayer.value.birthday = member.birthday
    newPlayer.value.sex = member.sex
    newPlayer.value.membership = member
    newPlayer.value.membershipId = member.id
    if (selectedMember.value) {
        showPlayerForm.value = false
    }
}

onMounted(async () => {
    Promise.all([loadEntities(), loadOrganization(), loadSeasons()]);
})
</script>
<style>
.actions-wrapper {
    display: flex;
    width: 100%;
}

.entity-actions-wrapper {
    display: flex;
    width: 40.4%;
    align-self: baseline;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 1rem;
}

.entity-actions-wrapper>.spz-select-group {
    width: fit-content;
}

.entity-actions-wrapper .btn {
    margin-left: auto;
}

.player-actions-wrapper {
    width: 60%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.entity-management-section {
    display: flex;
    margin-top: 1em;
    width: 100%;
    max-height: 100%;
    overflow-y: hidden;
}

.entity-management-section .add-btn {
    position: absolute;
    bottom: -2em;
    right: 0;
    z-index: 6;
}

.entities-container {
    display: flex;
    flex-direction: column;
    width: 40%;
    max-height: 100%;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: var(--main-font-clr) var(--bright-font-clr);
    padding-right: 1ch;
    min-height: 10em;
}


.players-container {
    padding-left: 1em;
    width: 60%;
    max-height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    --scrollbar-width: 0;
}

.entity-actions-wrapper>* {
    margin-bottom: 0;
}


.player-item .primary-info {
    display: flex;
    width: 100%;
}

.player-item .secondary-info {
    display: flex;
}

.player-item .nationality,
.player-item .sex {
    margin-right: 0.5em;
}

.player-item .last-name {
    margin-left: 0.5em;
}

.spz-img-input-group.entity-logo-input,
.spz-img-input-group.player-picture-input {
    height: 8rem;
    width: fit-content;
}

/* Assigned seasons styles */
.entity-assigned-seasons {
    display: flex;
    gap: 0.75rem;
    flex-direction: column;
    background-color: var(--bright-font-clr);
    padding: 1rem;
    width: 100%;
}

.assigned-season {
    background-color: var(--main-font-clr);
    padding: 0.5rem;
    border-radius: var(--default-border-rad);
    color: var(--bright-font-clr);
}

.assigned-season {
    display: flex;
}

.assinged-season__actions {
    margin-inline-start: auto;
}

.remove-season-btn {
    font-weight: bold;
    color: var(--btn-error-background-color);
    background-color: transparent;
    border: none;
    font-size: 1.125rem;
    cursor: pointer;
}

.remove-season-btn:hover {
    color: var(--background-clr);
}

.add-season-btn {
    margin-inline-start: auto;
}

.add-season-btn {
    position: absolute;
    right: -12ch;
    bottom: 0;
}

.combobox-style-select {
    position: relative;
    width: calc(100% - 12ch);
}

.member-player-connection {
    position: relative;
    padding-left: 0.5rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
}

.member-player-connection::before {
    content: '';
    width: 1rem;
    aspect-ratio: 1;
    background-color: var(--select-border);
    position: absolute;
    left: -1rem;
    border-radius: 100vw;
}

.member-player-connection.connected::before {
    background-color: var(--archive-bck-color);
}

.members-wrapper {
    margin-block-end: 1.5rem;
    display: flex;
    overflow-y: auto;
    height: -moz-max-content;
    height: max-content;
    flex-wrap: wrap;
    width: 100%;
    max-height: 23rem;
}

.members-wrapper .organization-member-list-badge {
    cursor: pointer;
}

.connected-member-section {
    width: calc(100% - 10rem);
}
</style>