<template>
    <!-- <button v-if="!isEditMode" @click="isEditMode = !isEditMode" class="btn edit-btn" type="button" title="Edit">Edit</button> -->
    <button @click="saveContainerResultFormerResultChanges" class="btn edit-btn" type="button" title="Save">{{ saveInProcess ? 'Saving' : 'Save'}}</button>
    <button v-show="isEditMode" class="btn btn-inverse" @click="entitySelectDialog.open(), selectedEntityCollectionFormerId = mainContainerResultFormer?.entityCollectionFormerId">+ Add</button>
    <div class="container-result-wrapper">
        <div class="container-result-row position-row">
            <div class="container-result-box">Position</div>
            <div v-if="(entityIdPositionKVPair?.length ?? 0) > 0" v-for="entity in entityIdPositionKVPair" class="container-result-box">
                <template v-for="entityContainerResult in [ getEntityContainerResult(entity.id ?? 0, mainContainerResultFormer)]">
                    <input v-show="isEditMode" class="container-result-cell" type="text" :name="'cr-' + entityContainerResult?.id"  
                        @change="updateContainerResultPosition($event, mainContainerResultFormer, entityContainerResult, entity)" 
                        :value="entityContainerResult?.position ?? 0" />
                    <span v-show="!isEditMode">{{ entityContainerResult?.position }}</span>
                </template>
            </div>
            <template v-else v-for="entityCollectionFormer in entityCollectionFormersMap.values()">             
                <div class="container-result-box" v-for="entity in entityCollectionFormer.entities">
                    <input v-show="isEditMode" class="container-result-cell" type="text" :name="'cr-' + 0"  
                                                    @change="updateContainerResultPosition($event, mainContainerResultFormer, undefined, entity)" 
                                                    :value="0" />
                    <span v-show="!isEditMode">{{ 0 }}</span>
                </div>
            </template>
        </div>
        <div class="container-result-row team-row visible-column">
            <div class="container-result-box">Teams</div>
            <template v-if="(entityIdPositionKVPair?.length ?? 0) > 0">
                <div v-for="entity in entityIdPositionKVPair" class="container-result-box">
                    <div>{{ entity?.name }}</div>
                </div>
            </template>
            <template v-else v-for="entityCollectionFormer in entityCollectionFormersMap.values()">             
                <div class="container-result-box" v-for="entity in entityCollectionFormer.entities">
                    {{ entity?.name }}
                </div>
            </template>
        </div>
        <!-- <div v-for="(entity, index) in entityIdPositionKVPair" class="container-result-row team-row">
            <div class="container-result-box">{{ entity?.name }}</div>
            <template v-if="true" v-for="(innerEntity, innerIndex) in entityIdPositionKVPair">
                <div v-if="innerIndex == index" class="container-result-box match-result-wrapper empty-box"></div>
                <div v-else class="container-result-box match-result-wrapper">
                    <div class="normal-result-box">
                        <div class="game-result-wrapper" v-for="commonContainerResultFormer in 
                                getCommonContainerResultFormersOfEntities(entity?.id ?? 0, innerEntity?.id ?? 0)">
                            <div class="game-number">S {{ commonContainerResultFormer?.container?.sequence }}</div>
                            <div class="game-point"
                                v-for="current in [{ entityResult: getEntityContainerResult(innerEntity?.id ?? 0, commonContainerResultFormer) }]"
                                :class="{ 'result-pos-1': current.entityResult?.position == 1 }">
                                <input v-show="isEditMode" :name="'cr-' + current.entityResult?.id"
                                    :value="current.entityResult?.result" type="text"
                                    @change="updateContainerResult($event, current, commonContainerResultFormer)" />
                                <span v-show="!isEditMode">{{ current.entityResult?.result }}</span>
                            </div>
                            <div class="game-point"
                                v-for="current in [{ entityResult: getEntityContainerResult(entity?.id ?? 0, commonContainerResultFormer) }]"
                                :class="{ 'result-pos-1': current.entityResult?.position == 1 }">
                                <input v-show="isEditMode" :name="'cr-' + current.entityResult?.id"
                                    :value="current.entityResult?.result" type="text"
                                    @change="updateContainerResult($event, current, commonContainerResultFormer)" />
                                <span v-show="!isEditMode">{{ current.entityResult?.result }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div> -->

        <!-- <div class="container-result-row point-row"
            v-for="additionalResultFormerId in additionalResultFormerIdsAsColumn">
            <div class="container-result-box">{{ resultFormersDisplayNames.get(additionalResultFormerId) ?? "Points" }}
            </div>
            <template v-for="entity in entityIdPositionKVPair">
                <div v-for="containerResultFormer in [additionalResultFormers.get(additionalResultFormerId)]"
                    class="container-result-box">
                    <div>{{ getEntityContainerResult(entity?.id ?? 0, containerResultFormer)?.result }}</div>
                </div>
            </template>
        </div> -->
        <div class="container-result-row point-row">
            <div class="container-result-box">{{ "Points" }}</div>
            <div v-if="(mainContainerResultFormer?.containerResults?.length ?? 0) > 0" v-for="containerResult in mainContainerResultFormer?.containerResults" class="container-result-box">
                <div>{{  }}

                    <input v-show="isEditMode" class="container-result-cell" type="text" :name="'cr-' + 0"  
                                                    @change="updateContainerResult($event, mainContainerResultFormer, containerResult.result, containerResult.result.entities[0])" 
                                                    :value="containerResult.result" />
                    <span v-show="!isEditMode">{{ containerResult.result }}</span>
                </div>
            </div>
            <template v-else v-for="entityCollectionFormer in entityCollectionFormersMap.values()">             
                <div class="container-result-box" v-for="entity in entityCollectionFormer.entities">
                    <input v-show="isEditMode" class="container-result-cell" type="text" :name="'cr-' + 0"  
                                                    @change="updateContainerResult($event, mainContainerResultFormer, undefined, entity)" 
                                                    :value="0" />
                    <span v-show="!isEditMode">{{ 0 }}</span>
                </div>
            </template>
        </div>
    </div>
    <Modal ref="infoPopup" class="match-info-modal">
        <template v-slot:header>
            <h3>Match Info</h3>
        </template>
        <template v-slot:body>
            <TableTennisMatchOverview :containerInfo="containerInfo" v-if="sport == 'TableTennis'" />
            <TennisMatchOverview :containerInfo="containerInfo" v-if="sport == 'Tennis'" />
            <VolleyballMatchOverview :containerInfo="containerInfo" v-if="sport == 'Volleyball'" />
        </template>
        <template v-slot:footer>
            <div>
                <button @click="infoPopup.closeModal()" class="btn btn-inverse">Close</button>
            </div>
        </template>
    </Modal>
    <EntitySelectDialog v-if="mainContainerResultFormer?.container?.organizationId" ref="entitySelectDialog" :organizationId="mainContainerResultFormer.container.organizationId" v-model="selectedEntityId" @onChoose="addEntityToCollectionFormer()" />
</template>
<script setup lang="ts">
import { onMounted, Ref, ref } from 'vue'
import Modal from '@/components/main/Modal.vue'
import TableTennisMatchOverview from '@/components/tableTennis/TableTennisMatchOverview.vue'
import TennisMatchOverview from '@/components/tennis/TennisMatchOverview.vue'
import VolleyballMatchOverview from '@/components/volleyball/VolleyballMatchOverview.vue'
import { useStore } from 'vuex'
import { key } from '@/store'
import { Entity } from '@/models/main/Entity'
import { useRoute } from "vue-router";
import useContainerRankingViewComposables from '@/composables/generic/UseContainerRankingViewComposables'
import EntitySelectDialog from '@/components/main/entity/EntitySelectDialog.vue'

const store = useStore(key);
const route = useRoute()
const containerRankingViewId: Ref<number> = ref(parseInt(route.params.id as string));
const sport: Ref<string> = ref('TableTennis')
const infoPopup: Ref<any> = ref(null)
const containerInfo: Ref<any> = ref(null)

/* Refactoring Section */
const entitySelectDialog: Ref<any> = ref()
const selectedEntityId: Ref<number> = ref(0);
const {
    saveInProcess,
    entityIdPositionKVPair,
    entityCollectionFormersMap,
    mainContainerResultFormer,
    selectedEntityCollectionFormerId,
    isEditMode,
    groupedByContainer,
    containersMap,
    entityCollectionsGoupedByRoundAndContainer,
    saveContainerResultFormerResultChanges,
    findEntityResult,
    updateContainerResult,
    updateContainerResultPosition,
    addEntityToCollectionFormer,
    loadContainerRankingView, 

} = useContainerRankingViewComposables(containerRankingViewId, selectedEntityId)
onMounted(loadContainerRankingView)

/* END */
const toggleModal = (containerResultFormersOfEntitiesArray: Array<any>) => {
    infoPopup.value.openModal()
    const containerResultFormersOfEntities = containerResultFormersOfEntitiesArray[0]
    containerInfo.value = containerResultFormersOfEntities
    sport.value = containerInfo.value.container.sport
}

// let mainContainerResultFormer: Ref<any> = ref(null)
// const entitiesContainerResultFormers = new Map<number, Map<number, any>>()
// const sortedEntityContainerResultFormers = ref(new Map<number, Map<number, any>>());
// const entityIdPositionKVPair = ref(new Array<Entity>());
// const additionalResultFormers = ref(new Map<number, any>())
// const getCommonContainerResultFormersOfEntities = (entityId: number, opponentEntityId: number) => {
//     const commonContainerResultFormers: Array<any> = [];
//     const mainEntityContainerResultFormers = sortedEntityContainerResultFormers.value.get(entityId)
//     if (mainEntityContainerResultFormers) {
//         for (const iterator of mainEntityContainerResultFormers) {
//             const containerResultFormer = iterator[1]
//             const containerResults: Array<any> = containerResultFormer.containerResults;
//             for (let i = 0; i < containerResults.length; i++) {
//                 const containerResult = containerResults[i];
//                 const containerResultEntity = containerResult.entity;
//                 if (containerResultEntity.id === opponentEntityId) {
//                     commonContainerResultFormers.push(containerResultFormer);
//                 }
//             }
//         }
//     }

//     commonContainerResultFormers.sort((a, b) => {
//         return (a?.container?.sequence ?? 0) - (b?.container?.sequence ?? 0)
//     })
//     return commonContainerResultFormers;
// }
const getEntityContainerResult = (entityId: number, containerResultFormer: any) => {
    if (!containerResultFormer) {
        return;
    }

    const containerResults: Array<any> = containerResultFormer.containerResults;
    for (let i = 0; i < containerResults.length; i++) {
        const containerResult = containerResults[i];
        const containerResultEntity = containerResult.entity;
        if (containerResultEntity.id === entityId) // found
        {
            return containerResult;
        }
    }
}
// let additionalResultFormerIdsAsColumn: number[]
// const resultFormersDisplayNames = new Map<number, string>()
// resultFormersDisplayNames.set(1466, "G Wins")
// resultFormersDisplayNames.set(1467, "G Loses")
// //resultFormersDisplayNames.set(1465, "Loses")
// resultFormersDisplayNames.set(1464, "Wins")
//additionalResultFormerIdsAsColumn = [...resultFormersDisplayNames.keys()]
// store.dispatch('containerStore/getContainerRankingView', containerRankingViewId.value)
//     .then((containerRankingView) => {
//         console.log(containerRankingView)
//         const viewSchemaParsed = JSON.parse(containerRankingView.viewSchema)

//         const mainContainerResultFormerId = viewSchemaParsed.mainContainerResultFormerId //559 // 486
//         const displayChildResultFormerId = viewSchemaParsed.displayChildResultFormerId;
//         const containerResultFormers: Array<any> = containerRankingView.containerResultFormers;
//         for (let i = 0; i < containerResultFormers.length; i++) {
//             const containerResultFormer = containerResultFormers[i];
//             if (additionalResultFormerIdsAsColumn.includes(containerResultFormer.id)) {
//                 additionalResultFormers.value.set(containerResultFormer.id, containerResultFormer);
//             }
//             if (containerResultFormer.id == mainContainerResultFormerId) {
//                 mainContainerResultFormer.value = containerResultFormer;
//                 continue; // skipping mainContainerResultFormer, won't store it in grouped Map for now
//             }

//             const resultFormer = containerResultFormer.resultFormer;
//             if (resultFormer.id == displayChildResultFormerId) {
//                 const containerResults: Array<any> = containerResultFormer.containerResults;
//                 for (let k = 0; k < containerResults.length; k++) {
//                     const containerResult = containerResults[k];
//                     const entity = containerResult.entity;
//                     if (!entitiesContainerResultFormers.has(entity.id)) {
//                         entitiesContainerResultFormers.set(entity.id, new Map<number, any>());
//                     }
//                     const entityContainerResultFormersGrouped = entitiesContainerResultFormers.get(entity.id);
//                     if (entityContainerResultFormersGrouped) {
//                         entityContainerResultFormersGrouped.set(containerResultFormer.id, containerResultFormer);
//                     }
//                 }
//             }
//         }

//         const mainContainerResultFormerContainerResults: Array<any> = mainContainerResultFormer.value.containerResults;
//         mainContainerResultFormerContainerResults.sort((a, b) => {
//             return a.position - b.position
//         })
//         for (let id = 0; id < mainContainerResultFormerContainerResults.length; id++) {
//             const containerResult = mainContainerResultFormerContainerResults[id];
//             const entity = containerResult.entity;
//             const groupedEntityContainerResultFormers = entitiesContainerResultFormers.get(entity.id);
//             if (groupedEntityContainerResultFormers) {
//                 entityIdPositionKVPair.value.push(entity)
//                 sortedEntityContainerResultFormers.value.set(entity.id, groupedEntityContainerResultFormers)
//             }
//         }
//         console.log(entityIdPositionKVPair.value);
//         console.log(sortedEntityContainerResultFormers.value);
//     })
</script>
<style>
.container-result-wrapper {
    --position-col-width: 12ch;
    --cell-default-padding: 0.3em;
    --cell-border: 1px solid black;
    display: flex;
    width: 100%;
    height: 100%;
    text-align: center;
    border: var(--cell-border);
    overflow: auto;
    text-transform: uppercase;
}

.container-result-row {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.position-row {
    min-width: var(--position-col-width);
    position: sticky;
    left: 0;
    z-index: 1;
}

.container-result-box {
    --result-line-height: 1.2;
    --column-count: 5;
    --row-count: 3;
    --game-score-width: 2.5ch;
    display: flex;
    min-width: calc((var(--column-count) * var(--game-score-width)) + (var(--cell-default-padding) * 2));
    height: 100%;
    border: var(--cell-border);
    width: 100%;
    background-color: var(--background-clr);
    padding: var(--cell-default-padding);
    min-height: calc((var(--result-line-height) * var(--row-count) * 1em) + (var(--cell-default-padding) * 2));
    justify-content: center;
    align-items: center;
}

div.container-result-box:nth-child(1) {
    position: sticky;
    top: 0;
    z-index: 0;
}

.visible-column {
    position: sticky;
    left: var(--position-col-width);
    z-index: 1;
}

.match-result-wrapper:not(.empty-box):hover {
    background-color: var(--sky-blue-clr);
    cursor: pointer;
}

.match-info-modal .modal__dialog {
    top: 8rem;
}

.empty-box {
    background-color: #abb8c3;
}

div.container-result-row:nth-child(1),
div.container-result-row:nth-child(2),
div.container-result-row:last-child {
    width: max-content;
}

.point-row .container-result-box div {
    font-size: 1.5em;
}

.team-row,
.point-row {
    width: 100%;
}

.modal_player_name_wrapper {
    width: 50%;
}

.modal-entity-img-wrapper {
    width: 9.5em;
    height: 5em;
}

.modal-stat-wrapper {
    justify-content: space-between;
    height: 2.5em;
}

.modal_player_info_wrapper,
.modal_player_name_wrapper {
    justify-content: space-evenly;
}

.modal_player_info_wrapper,
.modal_player_name_wrapper,
.modal-entity-img-wrapper,
.modal-stat-wrapper {
    display: flex;
}

.modal_player_name_wrapper,
.modal-stat-wrapper {
    align-items: center;
}

.volleyball-player-wrapper {
    width: 25%;
}

.volleyball-player-box {
    height: 3em;
    align-content: center;
    align-items: center;
}

.volleyball-player-img-wrapper {
    width: 2.5em;
    height: 2.5em;
}

.volleyball-player-info-wrapper,
.volleyball-player-box {
    justify-content: space-evenly;
}

.volleyball-player-info-wrapper,
.volleyball-player-box,
.volleyball-player-img-wrapper {
    display: flex;
}

.volleyball-stat-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1em;
}

.volleyball-stat-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
}

.volleyball-stat-box__name,
.volleyball-stat-box__value {
    width: 50%;
    height: 5em;
    align-content: center;
}

.game-point input {
    max-width: 3ch;
}

@media (width < 80em) {
    .container-result-box {
        --column-count: 3;
        --row-count: 5;
        padding: 0.1em;
    }
}
</style>