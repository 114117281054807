<template>
    <transition name="fade">
      <div class="sportzone__dialog__wrapper" v-if="show">
        <div class="sportzone__dialog__backdrop" @click="closeModal()" />
        <div class="sportzone__dialog">
          <div class="sportzone__dialog__header">
            <slot name="header" />
          </div>
          <div class="sportzone__dialog__body">
            <slot name="body" />
          </div>
          <div class="sportzone__dialog__footer">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </transition>
  </template>
  
  <script lang="ts">
  import { defineComponent } from 'vue'
  export default defineComponent({
    name: "SportzoneDialog",
    props: {
        show: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            localShow: this.show
        }
    },
    emits: ['close'],
    methods: {
      closeModal() {
        this.$emit('close')
      }
    }
  });
  </script>
  
  
  <style>
  .sportzone__dialog__wrapper {
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
  }
  
  .sportzone__dialog__backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  
  .sportzone__dialog {
    background-color: var(--background-clr);
    position: fixed;
    width: 600px;
    margin: 25px auto;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    z-index: 2;
    top: 4rem;
    left: calc((100% - 600px) / 2);
  }

.sportzone__dialog__wrapper.mini .sportzone__dialog__body {
    min-height: 10em;
}
  
  @media screen and (max-width: 992px) {
    .sportzone__dialog {
      width: 90%;
    }
  }
  
  .sportzone__dialog__header {
    padding: 20px 20px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    color: var(--main-font-clr);
    font-weight: bold;
    font-size: 1.25em;
  }
  
  .sportzone__dialog__body {
    padding: 10px 20px 10px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-height: 70vh;
    min-height: 480px;
  }
  
  .sportzone__dialog__footer {
    padding: 10px 20px 20px;
    display: flex;
    justify-content: flex-end;
  }
  
  
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s;
  }
  
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  </style>