<template>
    <div @click="$emit('click')" class="yt-broadcast-item" :class="{ selected: isSelected }">
      <div class="yt-broadcast-item__thumbnail"><img :src="ytLiveBroadcast.snippet.thumbnails.default.url" /></div>
      <div class="yt-broadcast-item__info">
        <div class="yt-broadcast-item__info_primary">
            <div class="container-title">{{ytLiveBroadcast.snippet.title}}</div>
        </div>
        <div class="yt-broadcast-item__info_secondary">
            <div class="yt-broadcast-item__start">{{formatDate(ytLiveBroadcast.snippet.scheduledStartTime, 'MMMM Do, YYYY HH:mm')}}</div>
            <div class="yt-broadcast-item__description">
              {{ ytLiveBroadcast.snippet.description }}
            </div>
        </div>
      </div>
    </div>
</template>

<script lang="ts">
import { key } from "@/store";
import { defineComponent, PropType } from "vue";
import { useStore } from "vuex";
import { formatDate } from "@/composables/common/Util";
import YTLiveBroadcast from "@/models/main/YoutubeLiveBroadcast";

const YoutubeLiveBroadcastItem = defineComponent({
  props: {
    ytLiveBroadcast: {
      type: Object as PropType<YTLiveBroadcast>,
      required: true,
    },
    isSelected: {
        type: Boolean,
        required: false
    }
  },
  emits: ['click'],
  setup() {
    const store = useStore(key);

    return {
      formatDate
    }
  },
});

export default YoutubeLiveBroadcastItem;
</script>

<style>

:root {

} 
.yt-broadcast-item {
  background-color: var(--bright-font-clr);
  padding: var(--badge-item-padding);
  display: flex;
  align-items: center;
  border-radius: var(--default-border-rad);
  cursor: pointer;
}

.yt-broadcast-item__thumbnail {
  margin-right: 0.5em;
}

.yt-broadcast-item.selected {
  background-color: var(--contour-light-color);
}

.yt-broadcast-item__info_primary {
  font-weight: 600;
}

</style>