<template>
    <TheUserDashboard :onUserLoad="onUserLoaded" title="Request">
        <div class="user-request-wrapper">
            <div class="user-request-form">
                <div class="spz-select-group">
                    <label for="request-type">Type</label>
                    <SportzoneSelect id="request-type"
                        v-model:selectedOption="userRequest.type"
                        :options="USER_REQUEST_TYPES">
                    </SportzoneSelect>
                </div>
                <div class="spz-text-input-group">
                    <label for="request-title">Title</label>
                    <input id="request-title" type="text" placeholder="Title" v-model="userRequest.title">
                </div>
                <div class="spz-text-input-group">
                    <label for="request-description">Description</label>
                    <textarea id="request-description" placeholder="Description" cols="30" rows="10" v-model="userRequest.description"></textarea>
                </div>
                <div class="spz-text-input-group">
                    <label for="request-contact">Contact</label>
                    <input id="request-contact" type="text" placeholder="Contact" v-model="userRequest.contact">
                </div>
                <div @click="onSendUserRequestData" class="btn user-request-button-send">
                    Send
                </div>
            </div>
        </div>
    </TheUserDashboard>
</template>
<script lang='ts'>
import { Ref, defineComponent, ref } from 'vue'
import TheUserDashboard from '@/components/main/TheUserDashboard.vue'
import { USER_REQUEST_TYPES, UserRequest, UserRequestType } from '@/models/main/UserRequest'
import { User } from '@/models/main/User'
import SportzoneSelect from '@/components/util/SportzoneSelect.vue';

export default defineComponent({
    components: {
        TheUserDashboard,
        SportzoneSelect
    },
    setup() {
        const defaultUserRequest: UserRequest = {
            id: 0,
            contact: '',
            description: '',
            title: '',
            userId: 0,
            type: USER_REQUEST_TYPES[1]
        }
        const userRequest: Ref<UserRequest> = ref(defaultUserRequest)
        const onUserLoaded = (user: User) => {
            if(user) {
                userRequest.value.userId = user.id ?? 0
                userRequest.value.contact = user.email ?? ''
            }
        }

        const onSendUserRequestData = () => {
            console.log('send user request')
        }
        return {
            USER_REQUEST_TYPES,
            onSendUserRequestData,
            onUserLoaded,
            userRequest
        }
    }
})
</script>
<style>
.user-request-wrapper {
    width: min(28rem, 95vw);
    margin: auto;
}

.user-request-form {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.user-request-button-send {
    align-self: flex-end;
}
</style>