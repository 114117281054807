import GenericDictionary from "@/components/util/GenericDictionary";
import authModule from "@/main";
import { Player } from "@/models/main/Player";
import { User } from "@/models/main/User";
import { IRootState } from "@/store";
import { ActionContext, Module } from "vuex";

const state: UserStatStore = {
    players: {}
};
export interface UserStatStore {
    players: GenericDictionary<Player>
};

const getters = {
    getUserStats: (state: UserStatStore) => {
        return state.players
    },
}

const mutations = {
    addUserPlayerStats(state: UserStatStore, userPlayerStats: GenericDictionary<Player>) {
        state.players = userPlayerStats
    },
}

const actions = {
    fetchUserStats({ commit }: ActionContext<UserStatStore, IRootState>, userId: number) {
        return authModule
            .get(`/api/user/${userId}/stat`)
            .then((response) => {
                const userStats: User = response.data as unknown as User;
                commit('addUserPlayerStats', userStats.players);
                return userStats
            });
    },
}

const userStatStore: Module<UserStatStore, IRootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
export default userStatStore;