<template>
    <h3>{{ containerInfo.container.sport }}</h3>
    <div>
        <div>
            <h3>Team Info</h3>
            <div class="modal_player_info_wrapper">
                <div class="modal_player_name_wrapper">
                    <div class="modal-entity-img-wrapper">
                        <img :src="containerInfo.containerResults[0].entity.logoLink">
                    </div>
                    <div>{{ containerInfo.containerResults[0].entity.name }}</div>
                </div>
                <div class="modal_player_name_wrapper">
                    <div>{{ containerInfo.containerResults[1].entity.name }}</div>
                    <div class="modal-entity-img-wrapper">
                        <img :src="containerInfo.containerResults[1].entity.logoLink">
                    </div>
                </div>
            </div>
        </div>
        <div>
            <h3>Player Info</h3>
            <div class="volleyball-player-info-wrapper">
                <div class="volleyball-player-wrapper">
                    <div v-for="playerIndex of homeEntityPlayers" @click="toggleModal(playerIndex)"
                        class="volleyball-player-box">
                        <img :src="playerIndex.pictureLink" class="volleyball-player-img-wrapper">
                        <div>{{ playerIndex.firstName }} {{ playerIndex.lastName }}</div>
                    </div>
                </div>
                <div class="volleyball-player-wrapper">
                    <div v-for="playerIndex of awayEntityPlayers" @click="toggleModal(playerIndex)"
                        class="volleyball-player-box">
                        <div>{{ playerIndex.firstName }} {{ playerIndex.lastName }}</div>
                        <img :src="playerIndex.pictureLink" class="volleyball-player-img-wrapper">
                    </div>
                </div>
            </div>
        </div>
        <Modal ref="playerInfoPopup" class="match-info-modal">
            <template v-slot:header>
                <h1>Player Info</h1>
            </template>
            <template v-slot:body>
                <h3>{{ player.firstName }} {{ player.lastName }}</h3>
                <div class="volleyball-stat-container">
                    <div v-for="point in pointTypes" class="volleyball-stat-wrapper">
                        <div class="volleyball-stat-box__name">{{ point }}</div>
                        <div class="volleyball-stat-box__value">{{ player.points[point] }}</div>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div>
                    <button @click="playerInfoPopup.closeModal()" class="btn btn-inverse">Back</button>
                </div>
            </template>
        </Modal>
    </div>
</template>
<script setup lang="ts">
import { Ref, ref } from 'vue'
import Modal from '@/components/main/Modal.vue'
const props = defineProps(['containerInfo'])
const containerInfo: Ref<any> = ref(props.containerInfo)
const homeEntityPlayers = containerInfo.value.containerResults[0].entity.players
const awayEntityPlayers = containerInfo.value.containerResults[1].entity.players
const playerInfoPopup: Ref<any> = ref(null)
const player: Ref<any> = ref(null)
const pointTypes = Object.keys(containerInfo.value.containerResults[0].entity.players[0].points)
const toggleModal = (playerData: number) => {
    playerInfoPopup.value.openModal()
    player.value = playerData
}
</script>
<style></style>