import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "sportzone__dialog__wrapper"
}
const _hoisted_2 = { class: "sportzone__dialog" }
const _hoisted_3 = { class: "sportzone__dialog__header" }
const _hoisted_4 = { class: "sportzone__dialog__body" }
const _hoisted_5 = { class: "sportzone__dialog__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.show)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "sportzone__dialog__backdrop",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
            }),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "header")
              ]),
              _createElementVNode("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "body")
              ]),
              _createElementVNode("div", _hoisted_5, [
                _renderSlot(_ctx.$slots, "footer")
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}