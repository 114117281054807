import { PagedResponse, PaginatedItemsStoreGetters, PaginatedItemsStoreMutations } from '@/composables/common/Pagination'
import { Phase, PhaseStatusType } from '@/models/main/Phase'
import { ActionContext, Module } from 'vuex'
import { IRootState } from '@/store'
import authModule from '@/main'
export interface PhaseStore {
    paginatedItems: PagedResponse<Phase>,
    status?: PhaseStatusType
}
const state: PhaseStore = {
    paginatedItems: {
        items: {},
        pageSize: 15,
        currentPage: 1
    }
}
const getters = {
    getPhase(state: PhaseStore, phaseId: number) {
        return state.paginatedItems.items[phaseId]
    },
    listPhases(state: PhaseStore, status?: PhaseStatusType) {
        const filteredPhases: Array<Phase> = []
        for (const phaseId in state.paginatedItems.items) {
            if (Object.prototype.hasOwnProperty.call(state.paginatedItems.items, phaseId)) {
                const phase: Phase = state.paginatedItems.items[phaseId] as Phase
                const statusIsOk = phase.status === status
                if (statusIsOk) {
                    filteredPhases.push(phase)
                }
            }
        }
        const sortedPhases = filteredPhases.sort()
        return sortedPhases
    },
    getPhaseStatus(state: PhaseStore) {
        return state.status
    },
    ...PaginatedItemsStoreGetters()
}
const mutations = {
    setPhase(state: PhaseStore, phase: Phase) {
        if (phase.id !== undefined) {
            state.paginatedItems.items[phase.id] = phase
        }
    },
    removePhase(state: PhaseStore, deletedPhaseId: number) {
        if (Object.prototype.hasOwnProperty.call(state.paginatedItems.items, deletedPhaseId)) {
            delete state.paginatedItems.items[deletedPhaseId]
        }
    },
    setPhaseStatus(state: PhaseStore, status: PhaseStatusType) {
        state.status = status
    },
    ...PaginatedItemsStoreMutations()
}
const actions = {
    getPhase({ commit }: ActionContext<PhaseStore, IRootState>, phaseId: number) {
        return authModule
            .get(`/api/phase/${phaseId}`)
            .then((response) => {
                const phase: Phase = response.data as unknown as Phase
                commit('setPhase', phase)
                return phase
            })
    },
    fetchPhases({ getters, commit }: ActionContext<PhaseStore, IRootState>, status: PhaseStatusType) {
        let filtersChanged = false
        let pageToRequest = getters['getNextPage']
        const pageSize = getters['getPageSize']
        const currentStatus = getters['getPhaseStatus']
        if (currentStatus !== status) {
            commit('setPhaseStatus', status)
            filtersChanged = true
        }
        if (filtersChanged) {
            pageToRequest = 1
        }
        if (!filtersChanged && getters['getNextPage'] === 1 && getters['getCurrentPage'] > 1) {
            return
        }
        return authModule
            .get('/api/phase', {
                params: {
                    status: status,
                    page: pageToRequest,
                    pageSize: pageSize
                }
            })
            .then((response) => {
                const pagedResponse: PagedResponse<Phase> = response.data as unknown as PagedResponse<Phase>
                commit('setCurrentPage', pagedResponse.currentPage)
                commit('setPageSize', pagedResponse.pageSize)
                commit('setNextPage', pagedResponse.nextPage)
                commit('setPreviousPage', pagedResponse.previousPage)
                for (const phaseIndex in pagedResponse.items) {
                    commit('setPhase', pagedResponse.items[phaseIndex])
                }
            })
    },
    createPhase({ commit }: ActionContext<PhaseStore, IRootState>, phase: Phase) {
        return authModule
            .post('/api/phase', phase)
            .then((response) => {
                const createdPhase: Phase = response.data as unknown as Phase
                commit('setPhase', createdPhase)
                return createdPhase
            })
    },
    editPhase({ commit }: ActionContext<PhaseStore, IRootState>, phase: Phase) {
        return authModule
            .patch(`/api/phase/${phase.id}`, phase)
            .then((response) => {
                const editedPhase: Phase = response.data as unknown as Phase
                commit('setPhase', editedPhase)
                return editedPhase
            })
    },
    deletePhase({ commit }: ActionContext<PhaseStore, IRootState>, phaseId: number) {
        return authModule
            .delete(`/api/phase/${phaseId}`)
            .then((response) => {
                const deletedPhaseId: number = response.data as number
                commit('removePhase', deletedPhaseId)
                return deletedPhaseId
            })
    }
}
const phaseStore: Module<PhaseStore, IRootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
export default phaseStore