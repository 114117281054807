<template>
    <TheUserDashboard :onUserLoad="onUserLoaded">
        <UserPreferences v-if="currentUser" :user="currentUser" />
  </TheUserDashboard>
</template>
<script lang="ts">
import { Ref, defineComponent, ref } from "vue";
import { createNamespacedHelpers, useStore } from "vuex";
import { key } from "@/store";
import TheUserDashboard from "@/components/main/TheUserDashboard.vue";
import { useRouter } from "vue-router";
import { User } from "@/models/main/User";
import Tooltip from '@/components/main/Tooltip.vue';
import UserPreferences from "@/components/user/UserPreferences.vue";
const userStoreHelpers = createNamespacedHelpers("userStore");

export default defineComponent({
    components: {
    TheUserDashboard,
    UserPreferences,
    Tooltip
    },
    ...userStoreHelpers.mapGetters(["getUser"]),
  setup() {
    const router = useRouter();
    const store = useStore(key);
    const currentUser: Ref<User | undefined> = ref();
    const onUserLoaded = (user: User) => {
        currentUser.value = user
    }

    return {
      store,
      router,
      currentUser,
      onUserLoaded,
    };
  },
})

</script>

<style>

</style>