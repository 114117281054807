import { Container } from "@/models/main/Container";
import { ContainerResult } from "@/models/main/ContainerResult";
import { Entity } from "@/models/main/Entity";
import { EntityCollection } from "@/models/main/EntityCollection";
import { key } from "@/store";
import { ref, Ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import {useToast} from 'vue-toast-notification';



export default function useContainerRankingViewComposables(containerRankingViewId: Ref<number>, selectedEntityId: Ref<number>, autoScroll: number = 0) {
    const store = useStore(key);
    const router = useRouter();
    const $toast = useToast();
    const editedContainerResultsMap: Map<number, ContainerResult> = new Map<number, ContainerResult>()
    const containerResultsToAddGrouped: Map<number, Map<number, ContainerResult>> = new Map<number, Map<number, ContainerResult>>()
    const saveInProcess = ref(false);
    const updateContainerResult = ($event: Event, containerResultFormer: any, containerResult?: ContainerResult,  entity?: Entity) => {
        const inputEl = $event.target as HTMLInputElement;
        const newResult = parseInt(inputEl.value.trim());
    
        if(containerResult) {
            containerResult.result = newResult
            containerResult.containerId = containerResultFormer.container.id
            containerResult.resultFormerId = containerResultFormer.resultFormer.id
            containerResult.containerResultFormerId = containerResultFormer.id
            editedContainerResultsMap.set(containerResult.id, containerResult)
        } else if(entity && entity.id) {
            const containerId = containerResultFormer.container.id
            const containerResultFormerId = containerResultFormer.id
            const entityId = entity.id
            containerResult = {
                id: 0,
                containerResultFormerId: containerResultFormerId,
                containerId: containerId,
                entityId: entityId,
                result: newResult,
                position: 1,
                dataType: "Point",
                type: "Initial",
                resultFormerId: containerResultFormer.resultFormer.id
            }
    
            if(!containerResultsToAddGrouped.has(containerResultFormerId)) {
                containerResultsToAddGrouped.set(containerResultFormerId, new Map<number, ContainerResult>())
            }
    
            const containerResultFormerResultsToAddByEntity = containerResultsToAddGrouped.get(containerResultFormerId) as Map<number, ContainerResult>
            containerResultFormerResultsToAddByEntity.set(entityId, containerResult)
        }
    
        if(containerResult) {
            console.log(containerResultsToAddGrouped)
            console.log(editedContainerResultsMap)
        }
    }

    const updateContainerResultPosition = ($event: Event, containerResultFormer: any, containerResult?: ContainerResult,  entity?: Entity) => {
        const inputEl = $event.target as HTMLInputElement;
        const newPosition = parseInt(inputEl.value.trim());
        console.log(containerResult);
        if(containerResult) {
            containerResult.position = newPosition
            containerResult.containerId = containerResultFormer.container.id
            containerResult.resultFormerId = containerResultFormer.resultFormer.id
            containerResult.containerResultFormerId = containerResultFormer.id
            editedContainerResultsMap.set(containerResult.id, containerResult)
        } else if(entity && entity.id) {
            const containerId = containerResultFormer.container.id
            const containerResultFormerId = containerResultFormer.id
            const entityId = entity.id
            containerResult = {
                id: 0,
                containerResultFormerId: containerResultFormerId,
                containerId: containerId,
                entityId: entityId,
                result: 0,
                position: newPosition,
                dataType: "Point",
                type: "Initial",
                resultFormerId: containerResultFormer.resultFormer.id
            }
    
            if(!containerResultsToAddGrouped.has(containerResultFormerId)) {
                containerResultsToAddGrouped.set(containerResultFormerId, new Map<number, ContainerResult>())
            }
    
            const containerResultFormerResultsToAddByEntity = containerResultsToAddGrouped.get(containerResultFormerId) as Map<number, ContainerResult>
            containerResultFormerResultsToAddByEntity.set(entityId, containerResult)
        }
    
        if(containerResult) {
            console.log(containerResultsToAddGrouped)
            console.log(editedContainerResultsMap)
        }
    }

    const findEntityResult = (entityId: number, containerResults: ContainerResult[]) => {
        for(let i = 0; i < containerResults.length; i++) {
            if(containerResults[i].entity?.id == entityId) {
                return containerResults[i]
            }
        }
    }
    const selectedEntityCollectionFormerId: Ref<number> = ref(0)
 
    const addEntityToCollectionFormer = async () => {
        if(selectedEntityCollectionFormerId.value !== 0 && selectedEntityId.value !== 0) {
                const newEntityCollection: EntityCollection = {
                entityCollectionFormerId: selectedEntityCollectionFormerId.value,
                entityId: selectedEntityId.value
            }
    
            await store.dispatch('containerStore/updateEntityCollections', [newEntityCollection])
            $toast.success('Success');
        }
    }

    const entityIdPositionKVPair: Ref<Array<Entity>> = ref(new Array<Entity>());
    const entitiesContainerResultFormers = new Map<number, Map<number, any>>()
    const sortedEntityContainerResultFormers = ref(new Map<number, Map<number, any>>());
    const mainContainerResultFormer: Ref<any> = ref(null)
    const additionalResultFormers = ref(new Map<number, any>())
    const entityCollectionFormersMap: Ref<Map<number, any>> = ref(new Map<number, any>())
    const containersMap: Ref<Map<number, any>> = ref(new Map<number, any>)
    const entityCollectionsGoupedByRoundAndContainer: Ref<Map<number, Map<number, Array<any>>>> = ref(new Map<number, Map<number, Array<any>>>())
    let additionalResultFormerIdsAsColumn: number[]
    const resultFormersDisplayNames = new Map<number, string>()
    const initialResultFormers: Ref<Array<any>> = ref([])
    additionalResultFormerIdsAsColumn = [ ...resultFormersDisplayNames.keys()]
    const groupedByContainer: Ref<Map<number, Array<any>>> = ref(new Map<number, Array<any>>())
    const loadContainerRankingView = () => {
        store.dispatch('containerStore/getContainerRankingView', containerRankingViewId.value)
        .then((containerRankingView) => {
            const viewType = containerRankingView.viewType;
            const isElimination = viewType === 'Elimination';
            const isStepladder = viewType === 'Stepladder';
            const isStandard = viewType === 'Standard';
            const viewSchemaParsed = JSON.parse(containerRankingView.viewSchema)
            const mainContainerResultFormerId = viewSchemaParsed.mainContainerResultFormerId //559 // 486
            const displayChildResultFormerId = viewSchemaParsed.displayChildResultFormerId;
            const containerResultFormers: Array<any> = containerRankingView.containerResultFormers;
            const entityCollectionFormers: Array<any> = containerRankingView.entityCollectionFormers;
            for (let i = 0; i < entityCollectionFormers.length; i++) {
                const entityCollectionFormer = entityCollectionFormers[i];
                entityCollectionFormersMap.value.set(entityCollectionFormer.id, entityCollectionFormer)
            }

            for (let i = 0; i < containerResultFormers.length; i++) {
                const containerResultFormer = containerResultFormers[i];
                const currentContainer: Container = containerResultFormer.container;
                const containerType: string = currentContainer.type as string;
                const isMatch = containerType.includes("Match")
                const parentContainerId = currentContainer.parentId
                if(!containersMap.value.has(currentContainer.id ?? 0)) {
                    containersMap.value.set(currentContainer.id ?? 0, currentContainer)
                }
                const isMatchInMatchBasedView = (isElimination || isStepladder) && isMatch
                const isMainResultFormerInStandardView = isStandard && containerResultFormer.id === mainContainerResultFormerId
                if(isMatchInMatchBasedView && parentContainerId) {
                    if(!entityCollectionsGoupedByRoundAndContainer.value.has(parentContainerId)) {
                        entityCollectionsGoupedByRoundAndContainer.value.set(parentContainerId, new Map<number, Array<any>>())
                    }

                    const efGroupedByContainer: Map<number, Array<any>> = entityCollectionsGoupedByRoundAndContainer.value.get(parentContainerId) as Map<number, Array<any>>
                    if(!efGroupedByContainer.has(currentContainer.id ?? 0)) {
                        efGroupedByContainer.set(currentContainer.id ?? 0, [])
                    }
    
                    const currentEntityCollections: any[] = efGroupedByContainer.get(currentContainer.id ?? 0) as any[]
                    const currentEntityCollectionFormer = entityCollectionFormersMap.value.get(containerResultFormer.entityCollectionFormerId)
                        currentEntityCollections.push(currentEntityCollectionFormer)
                }
    
                if(additionalResultFormerIdsAsColumn.includes(containerResultFormer.id)) {
                    additionalResultFormers.value.set(containerResultFormer.id, containerResultFormer);
                }

                if (mainContainerResultFormerId === undefined || containerResultFormer.id == mainContainerResultFormerId) {
                    mainContainerResultFormer.value = containerResultFormer;
                    if(isMatchInMatchBasedView) {
                        continue; // skipping mainContainerResultFormer, won't store it in grouped Map for now
                    }
                }
    
                const resultFormer = containerResultFormer.resultFormer;
                if (resultFormer.id == displayChildResultFormerId) { 
                    initialResultFormers.value.push(containerResultFormer)
                    const containerResults: Array<any> = containerResultFormer.containerResults;
                    for (let k = 0; k < containerResults.length; k++) {
                        const containerResult = containerResults[k];
                        const entity = containerResult.entity;
                        if (!entitiesContainerResultFormers.has(entity.id)) {
                            entitiesContainerResultFormers.set(entity.id, new Map<number, any>());
                        }
                        const entityContainerResultFormersGrouped = entitiesContainerResultFormers.get(entity.id);
                        if (entityContainerResultFormersGrouped) {
                            entityContainerResultFormersGrouped.set(containerResultFormer.id, containerResultFormer);
                        }
                    }
                }
            }

            const mainContainerResultFormerContainerResults: Array<any> = mainContainerResultFormer.value?.containerResults ?? [];
            mainContainerResultFormerContainerResults.sort((a, b) => {
                return a.position - b.position
            })
            for (let id = 0; id < mainContainerResultFormerContainerResults.length; id++) {
                const containerResult = mainContainerResultFormerContainerResults[id];
                const entity = containerResult.entity;
                const groupedEntityContainerResultFormers = entitiesContainerResultFormers.get(entity.id);
                if (groupedEntityContainerResultFormers) {
                    entityIdPositionKVPair.value.push(entity)
                    sortedEntityContainerResultFormers.value.set(entity.id, groupedEntityContainerResultFormers)
                }
            }
            console.log(entityIdPositionKVPair.value);
            console.log(entitiesContainerResultFormers);
            console.log('Initials: ', initialResultFormers.value)
            initialResultFormers.value.sort((a, b) => {
                return a.container.parent.sequence - b.container.parent.sequence
            })
    
            for (let i = 0; i < initialResultFormers.value.length; i++) {
                const containerResultFormer = initialResultFormers.value[i]
                const container = containerResultFormer.container;
                if(!groupedByContainer.value.has(container.parentId)) 
                {
                    groupedByContainer.value.set(container.parentId, []);
                }
    
                const containerInitialResultFormers: Array<any> = groupedByContainer.value.get(container.parentId) as Array<any>;
                containerInitialResultFormers.push(containerResultFormer);
            }
    
            console.log(initialResultFormers.value);
            console.log(groupedByContainer.value);
            console.log("ECF: ", entityCollectionsGoupedByRoundAndContainer.value)
            console.log("EC: ", entityCollectionFormersMap.value)
            if(autoScroll == 1) {
                setTimeout(() => { router.go(0)}, 119 * 1000)
            }
        })
    }
    const isEditMode: Ref<boolean> = ref(true)
    const saveContainerResultFormerResultChanges = async () => {
        saveInProcess.value = true
        //isEditMode.value = false
        const containerResultsArray = [ ...editedContainerResultsMap.values()]
        containerResultsToAddGrouped.forEach((containerResultFormerResultsGroupedByEntity) => {
            containerResultFormerResultsGroupedByEntity.forEach((containerResultToAdd) => {
                containerResultsArray.push(containerResultToAdd)
            })
        })
    

        await store.dispatch('containerStore/updateContainerResults', containerResultsArray)
        saveInProcess.value = false
        $toast.success('Saved');
        editedContainerResultsMap.clear()
        containerResultsToAddGrouped.clear()
    }

    return {
        saveInProcess,
        entityIdPositionKVPair,
        entityCollectionFormersMap,
        mainContainerResultFormer,
        isEditMode,
        containerResultsToAddGrouped,
        editedContainerResultsMap,
        groupedByContainer,
        containersMap,
        entityCollectionsGoupedByRoundAndContainer,
        selectedEntityCollectionFormerId,
        saveContainerResultFormerResultChanges,
        updateContainerResult,
        updateContainerResultPosition,
        findEntityResult,
        addEntityToCollectionFormer,
        loadContainerRankingView
    }
}