import { PagedResponse, PaginatedItemsStoreGetters, PaginatedItemsStoreMutations, commitPagedResponseDetails, prepForPagedCall } from "@/composables/common/Pagination";
import authModule from "@/main";
import { OrganizationInvitation } from "@/models/main/OrganizationInvitation";
import { IRootState } from "@/store";
import { ActionContext, Module } from "vuex";

const state: OrganizationInvitationStore = {
    paginatedItems: {
        items: {},
        pageSize: 30,
        currentPage: 1,
    }
};
export interface OrganizationInvitationStore {
    paginatedItems: PagedResponse<OrganizationInvitation>,
    searchedTeam?: string,
};

const getters = {
    getOrganizationInvitations: (state: OrganizationInvitationStore) => (searchTerm?: string) => {
        let filteredOrganizationInvitations: Array<OrganizationInvitation> = [];
        const lowerCaseSearchTerm = searchTerm?.toLowerCase();
        for (const organizationInvitationId in state.paginatedItems.items) {
            if (Object.prototype.hasOwnProperty.call(state.paginatedItems.items, organizationInvitationId)) {
                const organizationInvitation: OrganizationInvitation = state.paginatedItems.items[organizationInvitationId] as OrganizationInvitation;
                let inviteeName = organizationInvitation.inviteeFirstName + ' ' + organizationInvitation.inviteeLastName;
                if(organizationInvitation.user) {
                    const invitedUser = organizationInvitation.user;
                    inviteeName = invitedUser.firstName + (invitedUser.middleName ? ' ' + invitedUser.middleName + ' ' : ' ') + invitedUser.lastName;
                }
                const inviteeNameLowerCased = inviteeName.toLowerCase();
                const searchedTeamIsOk = lowerCaseSearchTerm === undefined || inviteeNameLowerCased.startsWith(lowerCaseSearchTerm);
                if (searchedTeamIsOk) {
                    filteredOrganizationInvitations.push(organizationInvitation);
                }
            }
        }
        filteredOrganizationInvitations = filteredOrganizationInvitations.sort((a, b) => {
            let aName = a.inviteeFirstName + ' ' + a.inviteeLastName;
            if(a.user) {
                const aUser = a.user;
                aName = aUser.firstName + (aUser.middleName ? ' ' + aUser.middleName + ' ' : ' ') + aUser.lastName;
            }
            let bName = b.inviteeFirstName + ' ' + b.inviteeLastName;
            if(b.user) {
                const bUser = b.user;
                bName = bUser.firstName + (bUser.middleName ? ' ' + bUser.middleName + ' ' : ' ') + bUser.lastName;
            }
            return (aName).localeCompare(bName);
        });
        return filteredOrganizationInvitations;
    },
    getOrganizationInvitation: (state: OrganizationInvitationStore) => (organizationInvitationId: number) => {
        return state.paginatedItems.items[organizationInvitationId];
    },
    ...PaginatedItemsStoreGetters(),
}

const mutations = {
    addOrganizationInvitation(state: OrganizationInvitationStore, organizationInvitation: OrganizationInvitation) {
        state.paginatedItems.items[organizationInvitation.id] = organizationInvitation;
    },
    editOrganizationInvitation(state: OrganizationInvitationStore, organizationInvitation: OrganizationInvitation) {
        state.paginatedItems.items[organizationInvitation.id] = organizationInvitation;   
    },
    removeOrganizationInvitation(state: OrganizationInvitationStore, organizationInvitationId: number) {
        if (Object.prototype.hasOwnProperty.call(state.paginatedItems.items, organizationInvitationId)) {
            delete state.paginatedItems.items[organizationInvitationId];
        }
    },
    ...PaginatedItemsStoreMutations(),
}

const actions = {
    addOrganizationInvitation({ commit }: ActionContext<OrganizationInvitationStore, IRootState>, organizationInvitation: OrganizationInvitation) {
        commit('addOrganizationInvitation', organizationInvitation);
    },
    createOrganizationInvitation({ dispatch }: ActionContext<OrganizationInvitationStore, IRootState>, organizationInvitation: OrganizationInvitation) {
        return authModule.post(`/api/organization/${organizationInvitation.organizationId}/invite`, organizationInvitation)
            .then((response) => {
                const organizationInvitation: OrganizationInvitation = response.data as unknown as OrganizationInvitation;
                dispatch('addOrganizationInvitation', organizationInvitation);
                return organizationInvitation;
            });
    },
    editOrganizationInvitation({ commit }: ActionContext<OrganizationInvitationStore, IRootState>, organizationInvitation: OrganizationInvitation) {
        return authModule.patch(`/api/organization/${organizationInvitation.organization?.id}/invite/${organizationInvitation.id}`, organizationInvitation)
            .then((response) => {
                const organizationInvitationEdited: OrganizationInvitation = response.data as unknown as OrganizationInvitation;
                commit('editOrganizationInvitation', organizationInvitation);
                return organizationInvitation;
            });
    },
    deleteOrganizationInvitation({ commit }: ActionContext<OrganizationInvitationStore, IRootState>, organizationInvitation: OrganizationInvitation) {
        return authModule.delete(`/api/organization/${organizationInvitation.organization?.id}/invite/${organizationInvitation.id}`)
            .then((response) => {
                const deletedOrganizationInvitationId: number = response.data as unknown as number;
                commit('removeOrganizationInvitation', deletedOrganizationInvitationId);
            });
    },
    fetchOrganizationInvitations({ commit, dispatch, getters }: ActionContext<OrganizationInvitationStore, IRootState>, organizationId: number ) {
        const { pageToRequest, pageSize, canMakeNextPageCall } = prepForPagedCall(getters)
        if(canMakeNextPageCall) {
            return authModule.get(`/api/organization/${organizationId}/invite`, {
                params: {
                    page: pageToRequest,
                    pageSize: pageSize,
                },
            })
            .then((response) => {
                const pagedResponse: PagedResponse<OrganizationInvitation> = response.data as unknown as PagedResponse<OrganizationInvitation>;
                commitPagedResponseDetails(commit, pagedResponse);
                for (const containerIndex in pagedResponse.items) {
                    dispatch('addOrganizationInvitation', pagedResponse.items[containerIndex]);
                }
            })
            .catch((error) => {
                console.error(error);
            });
        }
    },
    fetchUserOrganizationInvitations({ commit, dispatch, getters }: ActionContext<OrganizationInvitationStore, IRootState>) {
        const { pageToRequest, pageSize, canMakeNextPageCall } = prepForPagedCall(getters)
        if(canMakeNextPageCall) {
            return authModule.get(`/api/user/organization/invite`, {
                params: {
                    page: pageToRequest,
                    pageSize: pageSize,
                },
            })
            .then((response) => {
                const pagedResponse: PagedResponse<OrganizationInvitation> = response.data as unknown as PagedResponse<OrganizationInvitation>;
                commitPagedResponseDetails(commit, pagedResponse);
                for (const containerIndex in pagedResponse.items) {
                    dispatch('addOrganizationInvitation', pagedResponse.items[containerIndex]);
                }
            })
            .catch((error) => {
                console.error(error);
            });
        }
    },
    getOrganizationInvitation({ commit }: ActionContext<OrganizationInvitationStore, IRootState>, organizationInvitation: OrganizationInvitation) {
        return authModule
            .get(`/api/organization/${organizationInvitation.organization?.id}/invitation/${organizationInvitation.id}`)
            .then((response) => {
                const organizationInvitation: OrganizationInvitation = response.data as unknown as OrganizationInvitation;
                commit('addOrganizationInvitation', organizationInvitation);
            });
    },
    acceptUserInvitation({ commit }: ActionContext<OrganizationInvitationStore, IRootState>, organizationInvitation: OrganizationInvitation) {
        return authModule.post(`/api/user/invitation/${organizationInvitation.id}/accept`)
            .then((response) => {
                const organizationInvitationEdited: OrganizationInvitation = response.data as unknown as OrganizationInvitation;
                commit('editOrganizationInvitation', organizationInvitationEdited);
                return organizationInvitationEdited;
            });
    },
}

const organizationInvitationStore: Module<OrganizationInvitationStore, IRootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
export default organizationInvitationStore;