<template>
    <div class="loading-icon"></div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({

})
</script>
<style>
:root {
    --loading-icon-moving-color: var(--deep-blue-bck-color);
    --loading-icon-back-color: var(--light-homepage-color);
}

.loading-icon {
    border: 0.5em solid var(--loading-icon-back-color); 
    border-top: 0.5em solid var(--loading-icon-moving-color);
    border-bottom: 0.5em solid var(--loading-icon-moving-color); 
    border-radius: 50%;
    width: 3em;
    height: 3em;
    animation: spin 2s linear infinite;
    margin: 0.5em auto 0 auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>