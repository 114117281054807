import DataModelMapper from "@/components/util/DataModelMapper";
import DataModel from "@/models/main/DataModel";
import BowlingCameraDataModel from "./BowlingCameraDataModel";

export default class LivestreamDataModel extends DataModel {
    public static readonly MODEL_NAME: string = "Livestream"

    private _id?: number;
    private _name?: string;
    private _streamID?: string;
    private _squadID?: number;
    private _squadName?: string;
    private _squadLanes?: string;
    private _squadStatus?: string;
    private _squadType?: string;
    private _squadEventID?: number;
    private _squadDateStarted?: Date;
    private _camera?: BowlingCameraDataModel;
    private _phases?: any;

    // #region Fields Keys
    static get IDKey() {
        return "id";
    }

    static get nameKey() {
        return "name";
    }

    static get streamIDKey() {
        return "stream_id";
    }

    static get squadIDKey() {
        return "squad_id";
    }

    static get squadNameKey() {
        return "squad_name";
    }

    static get squadLanesKey() {
        return "lanes";
    }

    static get squadStatusKey() {
        return "status";
    }

    static get squadTypeKey() {
        return "squad_type";
    }

    static get eventIDKey() {
        return "tournament_group_id";
    }

    static get squadDateStartedKey() {
        return "date_started";
    }

    static get phasesKey() {
        return "phases";
    }

    static get phaseIDKey() {
        return "phase_id";
    }

    static get phaseNameKey() {
        return "phase_name";
    }

    static get cameraKey() {
        return "camera";
    }

    // #endregion

    // #region accessors/mutations 

    get ID() {
        return this._id;
    }

    set ID(ID) {
        this._id = ID;
    }

    get name() {
        return this._name;
    }

    set name(name) {
        this._name = name;
    }

    get streamID() {
        return this._streamID;
    }

    set streamID(streamID) {
        this._streamID = streamID;
    }

    get squadID() {
        return this._squadID;
    }

    set squadID(squadID) {
        this._squadID = squadID;
    }

    get squadName() {
        return this._squadName;
    }

    set squadName(squadName) {
        this._squadName = squadName;
    }

    get squadLanes() {
        return this._squadLanes;
    }

    set squadLanes(squadLanes) {
        this._squadLanes = squadLanes;
    }

    get squadStatus() {
        return this._squadStatus;
    }

    set squadStatus(squadStatus) {
        this._squadStatus = squadStatus;
    }

    get squadType() {
        return this._squadType;
    }

    set squadType(squadType) {
        this._squadType = squadType;
    }

    get squadEventID() {
        return this._squadEventID;
    }

    set squadEventID(eventID) {
        this._squadEventID = eventID;
    }

    get squadDateStarted() {
        return this._squadDateStarted;
    }

    set squadDateStarted(dateStarted) {
        this._squadDateStarted = dateStarted;
    }

    /**
     *
     * @returns {Camera}
     */
    get camera() {
        return this._camera;
    }

    /**
     *
     * @param camera {BowlingCameraDataModel}
     */
    set camera(camera) {
        if(camera instanceof BowlingCameraDataModel) {
            this._camera = camera;
        } else {
            console.error("Camera is not an instance of Camera!");
        }
    }

    get phases() {
        return this._phases;
    }

    set phases(phases) {
        this._phases = phases;
    }
    // #endregion

    constructor(modelID?: string, modelData?: any) {
        super();
        this.parseMinifiedData(modelData);
        if(this.ID !== undefined) {
            const modelId = DataModel.generateModelID(this, {"id": this.ID});
            if(modelId !== false) {
                this.modelID = modelId
            }
        } else if(modelID !== undefined){
            this.modelID = modelID;
        }
    }

    // #region FieldMappings Getters/Setters
    dataModelName() {
        return LivestreamDataModel.MODEL_NAME
    }

    getKeyByMinifiedKey(minKey: string) {
        switch (minKey) {
            case "0":
                return LivestreamDataModel.IDKey;
            case "1":
                return LivestreamDataModel.nameKey;
            case "2":
                return LivestreamDataModel.streamIDKey;
            case "3":
                return LivestreamDataModel.squadIDKey;
            case "4":
                return LivestreamDataModel.squadNameKey;
            case "5":
                return LivestreamDataModel.squadLanesKey;
            case "6":
                return LivestreamDataModel.squadStatusKey;
            case "7":
                return LivestreamDataModel.squadTypeKey;
            case "8":
                return LivestreamDataModel.eventIDKey;
            case "9":
                return LivestreamDataModel.squadDateStartedKey;
            case "10":
                return LivestreamDataModel.phasesKey;
            case "11":
                return LivestreamDataModel.cameraKey;
            default: 
                return ""
        }
    }

    getProperty(propertyKey: string) {
        switch (propertyKey) {
            case LivestreamDataModel.IDKey:
                return this.ID;
            case LivestreamDataModel.nameKey:
                return this.name;
            case LivestreamDataModel.streamIDKey:
                return this.streamID;
            case LivestreamDataModel.squadIDKey:
                return this.squadID;
            case LivestreamDataModel.squadNameKey:
                return this.squadName;
            case LivestreamDataModel.squadLanesKey:
                return this.squadLanes;
            case LivestreamDataModel.squadStatusKey:
                return this.squadStatus;
            case LivestreamDataModel.squadTypeKey:
                return this.squadType;
            case LivestreamDataModel.eventIDKey:
                return this.squadEventID;
            case LivestreamDataModel.squadDateStartedKey:
                return this.squadDateStarted;
            case LivestreamDataModel.phasesKey:
                return this.phases;
            case LivestreamDataModel.cameraKey:
                return this.camera;
        }
    }

    setProperty(propertyKey: string, value: any) {
        let phases
        let cameraModel
        switch (propertyKey) {
            case LivestreamDataModel.IDKey:
                this.ID = parseInt(value);
                break;
            case LivestreamDataModel.nameKey:
                this.name = value;
                break;
            case LivestreamDataModel.streamIDKey:
                this.streamID = value;
                break;
            case LivestreamDataModel.squadIDKey:
                this.squadID = parseInt(value);
                break;
            case LivestreamDataModel.squadNameKey:
                this.squadName = value;
                break;
            case LivestreamDataModel.squadLanesKey:
                this.squadLanes = value;
                break;
            case LivestreamDataModel.squadStatusKey:
                this.squadStatus = value;
                break;
            case LivestreamDataModel.squadTypeKey:
                this.squadType = value;
                break;
            case LivestreamDataModel.eventIDKey:
                this.squadEventID = parseInt(value);
                break;
            case LivestreamDataModel.squadDateStartedKey:
                this.squadDateStarted = value;
                break;
            case LivestreamDataModel.phasesKey:
                phases = [];
                for(const phaseIndex in value) {
                    if(Object.prototype.hasOwnProperty.call(value, phaseIndex)) {
                        phases.push(value[phaseIndex]);
                    }
                }

                this.phases = phases; // TODO: MAY NOT WORK
                break;
            case LivestreamDataModel.cameraKey:
                cameraModel = null;
                if(value instanceof DataModel) {
                    cameraModel = value;
                } else {
                    cameraModel = DataModelMapper.createFromObject(value);
                }
                if(cameraModel instanceof BowlingCameraDataModel) {
                    this.camera = cameraModel;
                }
                break;
        }
    }

    //#endregion
}