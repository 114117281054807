<template>
    <h3>{{ containerInfo.container.sport }}</h3>
    <div>
        <div>
            <h3>Player Info</h3>
            <div class="modal_player_info_wrapper">
                <div class="modal_player_name_wrapper">
                    <div class="modal-entity-img-wrapper">
                        <img :src="containerInfo.containerResults[0].entity.logoLink">
                    </div>
                    <div>{{ containerInfo.containerResults[0].entity.name }}</div>
                </div>
                <div class="modal_player_name_wrapper">
                    <div>{{ containerInfo.containerResults[1].entity.name }}</div>
                    <div class="modal-entity-img-wrapper">
                        <img :src="containerInfo.containerResults[1].entity.logoLink">
                    </div>
                </div>
            </div>
        </div>
        <div>
            <h3>Player Stats</h3>
            <div v-for="point in pointTypes" class="modal-stat-wrapper">
                <div>{{ containerInfo.containerResults[0].entity.players[0].points[point] }}</div>
                <div>
                    <div>{{ point }}</div>
                </div>
                <div>{{ containerInfo.containerResults[1].entity.players[0].points[point] }}</div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { Ref, ref } from 'vue'
const props = defineProps(['containerInfo'])
const containerInfo: Ref<any> = ref(props.containerInfo)
const pointTypes = Object.keys(containerInfo.value.containerResults[0].entity.players[0].points)
</script>
<style></style>