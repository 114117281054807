import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { key } from "@/store";
import { Ref, ref } from "vue";
import useVolleyballContainerComputeds from "./UseCurrentContainerComputeds";
import { VolleyballGameControlType } from "./VolleyballEventTypes";
import useCurrentContainerComputeds from "../generic/UseCurrentContainerComputeds";

export default function useVolleyballContainerActions(matchId?: Ref<number>) {
    const store = useStore(key);
    const route = useRoute()
    if (matchId === undefined) {
        matchId = ref(parseInt(route.params.id as string));
    }
    const {
        formIsDisabled,
        onOpenMatch,
        onCompleteMatch,
        onReopenMatch,
        loadContainer,
        onShareLinkButtonClicked,
        accessKey,
        infoPopUp,
        shareLink,
        offlineDataDownloadLinkUri,
        onReverseClick,
        createEvent,
        addEvent,
        editEvent,
        onEventEdit,
        removeEvent,
        onHomePointBoxClick,
        onAwayPointBoxClick,
        toggleHomePlayerSelect,
        toggleAwayPlayerSelect,
        selectedHomePlayerId,
        selectedAwayPlayerId,
        selectedEventToEdit,
        advancedEditEnabled,
        playerCurrentUnixTime
    } = useCurrentContainerComputeds(matchId)

    const {
        calculateEntityPoints,
        calculateEntityGames,
        getCurrentContainer,
        getCurrentHomeEntity,
        getCurrentAwayEntity,
        getCurrentHomeEntityPoints,
        getCurrentAwayEntityPoints,
        currentHomeEntityGamePoints,
        currentAwayEntityGamePoints,
        getCurrentHomePlayersOrdered,
        getCurrentAwayPlayersOrdered,
        getCurrentContainerBasicStats,
        getCurrentContainerEventsGroupedByGames,
        getCurrentContainerEvents
    } = useVolleyballContainerComputeds(matchId, playerCurrentUnixTime)

    const homeTimeouts: Ref<string[]> = ref([])
    const awayTimeouts: Ref<string[]> = ref([])
    const onMatchFinishedClick = async (entityId: number) => {
        if(matchId && matchId.value !== undefined) {
            const matchFinishedEventValue: VolleyballGameControlType = "Vly_M"
            const event = createEvent(matchId.value, "GameControl", entityId, matchFinishedEventValue,
                undefined,
                selectedEventToEdit.value ? selectedEventToEdit.value : undefined)
            if(selectedEventToEdit.value?.id) { // edit
                await editEvent(event)
            } else {
                await addEvent(event)
            }
        }
    }

    const onGameBoxClick = async (entityId: number) => {
        if(matchId && matchId.value !== undefined) {
            const gameFinished: VolleyballGameControlType = "Vly_G"
            const event = createEvent(matchId.value, "GameControl", entityId, gameFinished,
                undefined,
                selectedEventToEdit.value ? selectedEventToEdit.value : undefined)
            if(selectedEventToEdit.value?.id) { // edit
                await editEvent(event)
            } else {
                await addEvent(event)
            }
        }
    }
    const onTimeoutClick = async (entityId: number) => {
        if(matchId && matchId.value !== undefined) {
            const timeout: VolleyballGameControlType = "Vly_T"
            const event = createEvent(matchId.value, "GameControl", entityId, timeout,
                undefined,
                selectedEventToEdit.value ? selectedEventToEdit.value : undefined)
            if(selectedEventToEdit.value?.id) { // edit
                await editEvent(event)
            } else {
                await addEvent(event)
            }
        }
    }

    const onVolleyballCompleteMatch = async () => {
        onCompleteMatch(saveStats);
    };

    const saveStats = () => {
        const containerResults = [
            {
                containerId: getCurrentContainer.value?.id,
                containerType: "VolleyballMatch",
                containerSequence: 1,
                dataType: "Point",
                entityId: getCurrentHomeEntity.value?.id,
                result: currentHomeEntityGamePoints.value,
                childContainerResults: new Array<any>()
            },
            {
                containerId: getCurrentContainer.value?.id,
                containerType: "VolleyballMatch",
                containerSequence: 1,
                dataType: "Point",
                entityId: getCurrentAwayEntity.value?.id,
                result: currentAwayEntityGamePoints.value,
                childContainerResults: new Array<any>()
            }
        ]

        const containerGamesResults = []
        const containerPlayersStats = []
        const gamesCount = Object.keys(getCurrentContainerEventsGroupedByGames.value).length
        for (let g = 1; g <= gamesCount; g++) {
            const eventsInCurrentGame = getCurrentContainerEventsGroupedByGames.value[g].events
            let homeTeamScore = 0
            let awayTeamScore = 0
            for (let eventId in eventsInCurrentGame) {
                const event = eventsInCurrentGame[eventId]
                homeTeamScore = event.homeTeamScore
                awayTeamScore = event.awayTeamScore
            }

            const homeEntityGameResult = {
                containerId: getCurrentContainer.value?.id,
                containerType: "VolleyballGame",
                containerSequence: g,
                dataType: "Point",
                entityId: getCurrentHomeEntity.value?.id,
                // gameNumber: g,
                result: homeTeamScore,
                // timeouts: 0
            }
            const awayEntityGameResult = {
                containerId: getCurrentContainer.value?.id,
                containerType: "VolleyballGame",
                containerSequence: g,
                dataType: "Point",
                entityId: getCurrentAwayEntity.value?.id,
                // gameNumber: g,
                result: awayTeamScore,
                // timeouts: 0
            }

            containerResults[0].childContainerResults.push(homeEntityGameResult)
            containerResults[1].childContainerResults.push(awayEntityGameResult)
            // containerGamesResults.push(homeEntityGameResult)
            // containerGamesResults.push(awayEntityGameResult)

            if(getCurrentContainerBasicStats.value?.homeEntity[g]) {
                const homeEntityCurrentGamePlayerStats = getCurrentContainerBasicStats.value?.homeEntity[g].playersStats
                for (let playerId in homeEntityCurrentGamePlayerStats) {
                    const playerStats = homeEntityCurrentGamePlayerStats[playerId]
                    for (let statType in playerStats) {
                        if (playerStats[statType].count > 0) {
                            const containerPlayerStat = {
                                containerId: getCurrentContainer.value?.id,
                                entityId: getCurrentHomeEntity.value?.id,
                                gameNumber: g,
                                playerId: playerId,
                                statType: statType,
                                statCount: playerStats[statType].count
                            }
    
                            containerPlayersStats.push(containerPlayerStat)
                        }
                    }
                }
            }

            if(getCurrentContainerBasicStats.value?.awayEntity[g]) {
                const awayEntityCurrentGamePlayerStats = getCurrentContainerBasicStats.value?.awayEntity[g].playersStats
                for (let playerId in awayEntityCurrentGamePlayerStats) {
                    const playerStats = awayEntityCurrentGamePlayerStats[playerId]
                    for (let statType in playerStats) {
                        if (playerStats[statType].count > 0) {
                            const containerPlayerStat = {
                                containerId: getCurrentContainer.value?.id,
                                entityId: getCurrentAwayEntity.value?.id,
                                gameNumber: g,
                                playerId: playerId,
                                statType: statType,
                                statCount: playerStats[statType].count
                            }
    
                            containerPlayersStats.push(containerPlayerStat)
                        }
                    }
                }
            }
        }

        const promises = []
       // promises.push(store.dispatch('containerStore/saveVolleyballContainerResults', containerResults))
        promises.push(async () => { 
            try {
                    await store.dispatch('containerStore/saveContainerInitialResults', { containerId: getCurrentContainer.value?.id, containerResults})
                } finally {
                    return true
                }
            }
        )

        //promises.push(store.dispatch('containerStore/saveVolleyballGamesResult', containerGamesResults))
        if (containerPlayersStats.length > 0) {
           promises.push(store.dispatch('containerStore/saveVolleyballPlayersStats', containerPlayersStats))
        }

        return Promise.all(promises)

    }

    return {
        loadContainer,
        onEventEdit,
        selectedEventToEdit,
        toggleHomePlayerSelect,
        toggleAwayPlayerSelect,
        onHomePointBoxClick,
        onAwayPointBoxClick,
        onMatchFinishedClick,
        onReverseClick,
        onGameBoxClick,
        onTimeoutClick,
        onOpenMatch,
        onCompleteMatch: onVolleyballCompleteMatch,
        onReopenMatch,
        onShareLinkButtonClicked,
        calculateEntityPoints,
        calculateEntityGames,
        removeEvent,
        homeTimeouts,
        awayTimeouts,
        offlineDataDownloadLinkUri,
        infoPopUp,
        selectedHomePlayerId,
        selectedAwayPlayerId,
        getCurrentHomeEntityPoints,
        getCurrentAwayEntityPoints,
        getCurrentHomePlayersOrdered,
        getCurrentAwayPlayersOrdered,
        getCurrentContainer,
        getCurrentHomeEntity,
        getCurrentAwayEntity,
        currentHomeEntityGamePoints,
        currentAwayEntityGamePoints,
        getCurrentContainerBasicStats,
        getCurrentContainerEvents,
        formIsDisabled,
        accessKey,
        shareLink,
        advancedEditEnabled,
        playerCurrentUnixTime
    }
}