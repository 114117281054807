import { Camera } from '@/models/main/Camera';
import Station from '@/models/main/Station';
import { IRootState } from '@/store';
import { ActionContext, Module } from 'vuex'

const getters = {
    getStation(state: any) {
        return state
    },

    getCameras(state: any) {
        return state.cameras
    }
}

const mutations = {
    setId(state: Station, payload: string) {
        state.id = payload
    },

    setName(state: Station, payload: string) {
        state.name = payload
    },

    setUpdatedDate(state: Station, payload: Date) {
        state.updatedDate = payload
    },

    setCameras(state: Station, payload: Array<Camera>) {
        state.cameras = payload
    },

    addCamera(state: Station, payload: Camera) {
        state.cameras.push(payload);
    },
    
    removeCamera(state: Station, cameraId: number) {
        const filteredCameras = state.cameras.filter((el) => {
            return el.id !== cameraId
        })

        if(filteredCameras.length !== state.cameras.length - 1) {
            console.error("Could not remove camera with id: " + cameraId);
            return;
        }

        state.cameras = filteredCameras
    }
}

const actions = {

}

const state: Station = {
    id: null,
    name: null,
    updatedDate: null,
    cameras: []
}

const stationStore: Module<Station, IRootState> =
{
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}

export default stationStore;
