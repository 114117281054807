import { App } from "vue"

export default {
    install: (app: App<Element>, options: any) => {
      // inject a globally available $translate() method
      app.config.globalProperties.$c = (key: string, sport: string) => {
        // retrieve a nested property in `options`
        // using `key` as the path
        return key.split('.').reduce((option: any, i: string) => {
          if (option) {
            if(option[i][sport]) {
                return option[i][sport]
            } else if (option[i]["generic"]) {
                return option[i]["generic"]
            }
            
            return 
          } 
        }, options)
      }

      app.provide('contextualizer', options)
    }
  }