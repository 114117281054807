<template>
    <TheOrganizerDashboard :onUserLoad="onUserLoaded" >
        <template #heading>
            <router-link :to="{ name: 'Organizations' }">
                <div class="page-heading">
                    Organizations
                </div>
            </router-link>
            <router-link :to="{ name: 'Organization Menu', params: { id: organization?.id } }">
                <div class="page-heading">
                    {{ organization?.name }}
                </div>
            </router-link>
            <router-link :to="{ name: 'Organization Roles', params: { id: organization?.id } }">
                <div class="page-heading">
                    Roles
                </div>
            </router-link>
        </template>
        <template #default>
            <div v-if="organization" class="organization-roles-wrapper">
                <button @click="loadUsers(), addRolePopup.openModal()" class="btn add-usr-btn add-role-btn">
                    Add Role
                </button>
                <div class="organization-manager-list-badge badge-item">
                    <div class="organization-manager-image badge-item-col">
                        <object :data="'/api/user/'+ organization.owner?.id + '/picture'">
                            <img src="/default-profile-pic.svg" alt="Profile" />
                        </object>
                    </div>
                    <div class="organization-manager-name badge-item-col">
                        <div class="badge-info-heading">
                            Name
                        </div>
                        <div class="badge-info-value">
                            {{ organization.owner?.firstName }} {{ organization?.owner?.lastName }}
                        </div>
                    </div>
                    <div class="organization-manager-enrolled-date badge-item-col">
                        <div class="badge-info-heading">
                            Assigned On
                        </div>
                        <div class="badge-info-value">
                            {{ formatStringDate(organization.dateCreated.toString()) }}
                        </div>
                    </div>
                    <div class="organization-manager-role badge-item-col">
                        <div class="badge-info-heading">
                            Role
                        </div>
                        <div class="badge-info-value">
                            Owner
                        </div>
                    </div>
                </div>
                <div class="organization-manager-list-badge badge-item"  v-for="organizationManager in getOrganizationManagers()">
                    <div class="organization-manager-image badge-item-col">
                    </div>
                    <div class="organization-manager-name badge-item-col">
                        <div class="badge-info-heading">
                            Name
                        </div>
                        <div class="badge-info-value">
                            {{ organizationManager.user?.firstName }} {{ organizationManager.user?.lastName }}
                        </div>
                    </div>
                    <div class="organization-manager-enrolled-date badge-item-col">
                        <div class="badge-info-heading">
                            Assigned On
                        </div>
                        <div class="badge-info-value">
                            {{ formatStringDate(organizationManager.assignedOn) }}
                        </div>
                    </div>
                    <div class="organization-manager-role badge-item-col">
                        <div class="badge-info-heading">
                            Role
                        </div>
                        <div class="badge-info-value">
                            {{ organizationManager.role }}
                        </div>
                    </div>
                    <!-- <div class="organization-member-edit-control">
                        <button class="btn edit-btn">Edit</button>
                    </div> -->
                    <div class="organization-manager-delete-control badge-item-col">
                        <button class="btn delete-btn">Remove</button>
                    </div>
                </div>
            </div>
            <StepBasedModal ref="addRolePopup" @onApplyClick="onAddRoleFormSubmit" :start-step="addRoleProcessStep"
                :action-process-total-steps="addRoleProcessTotalSteps" :step-validators="stepValidators">
                <template #header>
                    <h1>Invite</h1>
                </template>
                <template #popup-step-1>
                    <div class="popup-step">
                        <div class="users-filters">
                            <div class="spz-text-input-group narrow">
                                <label for="search-term">Search:</label>
                                <input @input="onSearchTermInput" id="search-term" type="text" placeholder="Search">
                            </div>
                            <SportzoneSelect @selectOptionChange="onSelectNationality" id="nationality-filter"
                                v-model:selectedOption="nationalityFilter"
                                :options="['Nationality', ...nationalitiesComputed]">
                            </SportzoneSelect>
                        </div>
                        <div class="users-wrapper" @scroll="onUsersWrapperScroll">
                            <div @click="toggleUserItemSelect(user)"
                                v-for="user in getUsers(userSearchTerm, nationalityFilter)"
                                class="spz-user-item small-item badge-item selectable-item"
                                :class="{ 'selected-item': selectedUsers[user.id] }">
                                <div class="badge-item-col spz-user-item-name">
                                    <div class="badge-info-heading">
                                        Name
                                    </div>
                                    <div class="badge-info-value">
                                        {{ user.firstName }} {{ user.lastName }}
                                    </div>
                                </div>
                                <div class="badge-item-col spz-user-item-nat">
                                    <div class="badge-info-heading">
                                        Nationality
                                    </div>
                                    <div class="badge-info-value">
                                        {{ user.nationality }}
                                    </div>
                                </div>
                                <div class="badge-item-col spz-user-item-email">
                                    <div class="badge-info-heading">
                                        Sex
                                    </div>
                                    <div class="badge-info-value">
                                        {{ user.sex }}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </template>
                <template #popup-step-2>
                    <div class="popup-step">
                    </div>
                </template>
                <template #action-btn>
                    <button class="btn add-usr-btn">Add Role</button>
                </template>
            </StepBasedModal>
        </template>
    </TheOrganizerDashboard>
</template>
<script lang="ts">
import { createNamespacedHelpers, useStore } from 'vuex';
import { computed, defineComponent, Ref, ref } from 'vue';
import TheOrganizerDashboard from '@/components/main/TheOrganizerDashboard.vue';
import { Organization } from '@/models/main/Organization';
import { key } from '@/store';
import { useRoute } from 'vue-router';
import { User } from '@/models/main/User';
import { formatStringDate } from '@/composables/common/Util';
import '@vuepic/vue-datepicker/dist/main.css';
import GenericDictionary from '@/components/util/GenericDictionary';
import StepBasedModal from '@/components/main/StepBasedModal.vue';
import SportzoneSelect from '@/components/util/SportzoneSelect.vue';

const nationalities = require('i18n-nationality');
nationalities.registerLocale(require('i18n-nationality/langs/en.json'));

const organizationManagerStoreHelpers = createNamespacedHelpers('organizationManagerStore');
const userStoreHelpers = createNamespacedHelpers('userStore');
export default defineComponent({
    components: {
        TheOrganizerDashboard,
        StepBasedModal,
        SportzoneSelect
    },
    computed: {
        ...organizationManagerStoreHelpers.mapGetters(
            ['getOrganizationManagers'],
        ),
        ...userStoreHelpers.mapGetters(
            ['getUsers']
        )
    },
    setup() {
        const organization: Ref<Organization | undefined> = ref();
        const store = useStore(key);
        const route = useRoute();
        const organizationId: Ref<number> = ref(parseInt(route.params.id as string));

        const selectedUsers: Ref<GenericDictionary<User>> = ref({});
        const userSearchTerm: Ref<string | undefined> = ref();
        const nationalityFilter: Ref<string> = ref('Nationality');
        const addRolePopup: Ref<any> = ref(null);
        const addRoleProcessStep: Ref<number> = ref(1);
        const addRoleProcessTotalSteps = ref(2);
        const stepValidators: Array<Function> = [
            () => {
                return true;
            },
            () => {
                return true;
            }
        ];

        const onUserLoaded = (user: User) => {
        };
        const loadOrganizationMembers = async () => {
            organization.value = await store.dispatch('organizationStore/getOrganization', organizationId.value);
            const managers = await store.dispatch('organizationManagerStore/fetchOrganizationManagers', organizationId.value);
        };

        const onSearchTermInput = async (event: any) => {
            userSearchTerm.value = event.target.value;
            if (userSearchTerm.value !== undefined && (userSearchTerm.value.length >= 3 || userSearchTerm.value.length === 0)) {
                selectedUsers.value = {};
                loadUsers(userSearchTerm.value, nationalityFilter.value);
            }
        };
        const onSelectNationality = () => {
            selectedUsers.value = {};
            loadUsers(userSearchTerm.value, nationalityFilter.value);
        };
        const toggleUserItemSelect = (user: User) => {
            if (user && user.id) {
                if (selectedUsers.value[user.id]) {
                    delete selectedUsers.value[user.id];
                    return;
                }
                selectedUsers.value[user.id] = user;
            }
        };
        const nationalitiesComputed = computed(() => {
            const list = nationalities.getNames('en');
            const result = Object
                .keys(list)
                .map((key) => (list[key]));
            return result;
        });
        
        const loadUsers = async (searchTerm?: string, nationality?: string) => {
            await store.dispatch('userStore/listUsers', {
                searchTerm,
                nationality
            });
        };

        let scrollTimeout: any;
        let loadInProcess: Ref<boolean> = ref(false);
        const onUsersWrapperScroll = (event: any) => {
            const totalScrollHeight = event.target.scrollHeight;
            const scrollTop = event.target.scrollTop;
            const offsetHeight = event.target.offsetHeight;
            const margin = 20;
            let bottomOfWindow = totalScrollHeight - scrollTop - offsetHeight < margin;
            if (bottomOfWindow) {
                if (scrollTimeout) {
                    clearTimeout(scrollTimeout);
                }
                scrollTimeout = setTimeout(onScrollStopped, 200);
            }
        };
        const onScrollStopped = () => {
            if (!loadInProcess.value) {
                loadInProcess.value = true;
                loadUsers(userSearchTerm.value, nationalityFilter.value)
                    .then(() => {
                        loadInProcess.value = false;
                    });
            }
        };

        const onAddRoleFormSubmit = async () => {
            console.info("ADD")
        }

        loadOrganizationMembers();
        return {
            organization,
            organizationId,
            addRolePopup,
            nationalitiesComputed,
            addRoleProcessStep,
            addRoleProcessTotalSteps,
            stepValidators,
            userSearchTerm,
            nationalityFilter,
            selectedUsers,
            onAddRoleFormSubmit,
            loadUsers,
            onSelectNationality,
            onSearchTermInput,
            toggleUserItemSelect,
            onUsersWrapperScroll,
            onUserLoaded,
            formatStringDate
        };
    }
});
</script>
<style>
.organization-roles-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.organization-manager-list-badge.badge-item {
  max-width: 50em;
}

.organization-manager-image {
    max-width: 8em;
    max-height: 5em;
    height: 100%;
}

.organization-manager-image img, .organization-manager-image object{
  max-width: 100%;
  max-height: 100%;
}
</style>