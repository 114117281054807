<template>
  <div @click="$emit('click')" class="manage-livestream-item badge-item" :class="{ selected: isSelected }">
    <div class="manage-livestream-item__title badge-item-col">
        <div class="badge-info-heading">Title</div>
        <div class="badge-info-value">{{ livestream.title }}</div>
    </div>
    <div class="manage-livestream-item__start-time badge-item-col">
        <div class="badge-info-heading">Start</div>
        <div class="badge-info-value">{{ formatStringDate(livestream.startTime) }}</div>
    </div>
    <div class="manage-livestream-item__status badge-item-col badge-status" :class="livestream.status">
        {{ livestream.status }}
    </div>
    <div class="manage-livestream-item__controls badge-item-col">
      <router-link :to="{ name: 'Livestream Panel', params: { id: livestream.id }, }">
        <div class="btn manage-btn">
          Manage
        </div>
      </router-link>
      <div class="dropdown-action-box" tabindex="0">
        <div class="dropdown-action-box__title">
          Actions
        </div>
        <div class="dropdown-action-box__actions">
          <button @click="$emit('edit')" class="droopdown-action-box__action" type="button" role="button">Edit</button>
          <button @click="$emit('delete')" class="droopdown-action-box__action" role="button">Delete</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Livestream from '@/models/main/Livestream';
import { formatStringDate } from '@/composables/common/Util';
const ManageLivestreamItem = defineComponent({
  props: {
    isSelected: {
      type: Boolean,
      required: false,
    },
    livestream: {
      type: Object as PropType<Livestream>,
      required: true,
    },
  },
  emits: ['click', 'edit', 'delete'],
  setup() {
    return { formatStringDate }
  }
});
export default ManageLivestreamItem;
</script>
<style>
.manage-livestream-item {
  font-weight: 600;
}

.manage-livestream-item__title.badge-item-col {
    width: calc(100% - 48em);
}
.manage-livestream-item__controls.badge-item-col {
    flex-direction: row;
    align-items: center;
}
</style>