<template>
    <div class="watch-livestream-item">
        <div class="watch-livestream__left-section">
            <div class="start-word"> {{livestream.status === "Completed" || livestream.status === "Stopping" ? "STREAMED" : "START"}}</div>
            <div class="start-hour">{{getDateHours(livestream.startTime)}}:{{getDateMinutes(livestream.startTime)}} (EET)</div>
        </div>
        <div class="watch-livestream__icon-section">
            <TableTennisIcon class="tbltenn-icon-wrapper" v-if="livestream.sport === 'TableTennis'" />
            <TennisIcon class="tenn-icon-wrapper" v-else-if="livestream.sport === 'Tennis'" />
            <BadmintonIcon class="badminton-icon-wrapper" v-else-if="livestream.sport === 'Badminton'" />
            <img v-else :src="livestream.sport === 'Bowling' ? '/bowling.png' : '/volleyball.png'" alt="Sport Icon"/>
        </div>
        <div class="watch-livestream__mid-section">

            <div class="watch-livestream__primary-info">
                <div class="primary-info__item">{{livestream.title}}</div>
            </div>
            <div class="watch-livestream__secondary-info">
                <div class="secondary-info__item">{{getFromattedDate(livestream.startTime)}}</div>
            </div>
        </div>
        <div class="watch-livestream__right-section">
            <router-link class="watch-livestream-btn" :to="{ name: getSportWatchViewName(livestream.sport), params: { id: livestream.id },}">
                    {{livestream.status === "Completed" || livestream.status === "Stopping" ? "Watch" : (livestream.status === "Pending" ? "Soon" : "Live")}}
                </router-link>
        </div>
    </div>
</template>

<script lang="ts">
import Livestream from "@/models/main/Livestream";
import { defineComponent, PropType } from "vue";
import TableTennisIcon from "@/components/tableTennis/TableTennisIcon.vue"
import TennisIcon from "@/components/tennis/TennisIcon.vue"
import BadmintonIcon from "@/components/badminton/BadmintonIcon.vue"
import { getSportWatchViewName } from "@/composables/common/Util";

const WatchLivestreamItem = defineComponent({
  props: {
    livestream: {
      type: Object as PropType<Livestream>,
      required: true,
    }
  },
  components: {
      TableTennisIcon,
      TennisIcon,
      BadmintonIcon
  },
  setup() {
      const getDateHours = (date: string) => {
          const hours = new Date(date).getHours()
          return hours < 10 ? "0" + hours : hours
      }

      const getDateMinutes = (date: string) => {
          const mins = new Date(date).getMinutes() 
          return mins < 10 ? "0" + mins : mins
      }

      const getFromattedDate = (date: string ) => {
          const dateObj = new Date(date)
          const year = dateObj.toLocaleDateString('en', { year: "numeric"})
          const month = dateObj.toLocaleDateString('en', { month: "long"})
          const day = dateObj.toLocaleDateString('en', { day: "2-digit"})
          return `${day} ${month} ${year}`
      }

      return {
          getDateHours,
          getDateMinutes,
          getFromattedDate,
          getSportWatchViewName
      }
  },
});

export default WatchLivestreamItem;
</script>

<style>

:root {
    --dark-livestream-color: black;
}

.watch-livestream-item {
    --link-bck-color:#a6bded;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    flex-wrap:wrap;
    justify-content:flex-start;
    color:var(--player-item-color);
    background-color:var(--user-item-bck-color);
    margin-bottom:1em;
    width:100%;
    padding-bottom:1em;
    background-color:var(--light-homepage-color);
    border-radius: var(--default-border-rad);
    overflow: hidden;
}

.watch-livestream-item.compact {
  font-size: 0.77em;
}

.watch-livestream__left-section {
    display:flex;
    justify-content:center;
    padding:1em 0.5em;
    align-items:center;
    width:100%;
    background-color:var(--watch-bck-color)
}

.watch-livestream__icon-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
}


.watch-livestream__mid-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--light-homepage-color);
  color: var(--dark-livestream-color);
  font-size: 1.125em;
  line-height: 1.8em;
}

.watch-livestream__primary-info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.watch-livestream__secondary-info {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 0.9em;
  padding-bottom: 1em;
}

.start-word {
  margin-right: 1rem;
}


.watch-livestream-item .primary-info__item {
  display: flex;
  margin-bottom: var(--user-item-margin-bottom);
  text-align: center;
  overflow: hidden;
  height: 100%;
}

.watch-livestream-item .secondary-info__item {
    display: flex;
}

.watch-livestream__right-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--light-homepage-color);
    flex-direction: column;
}

.watch-livestream-btn {
    font-size: 1.17em;
    background-color: var(--watch-bck-color);
    border-radius: var(--default-border-rad);
    position: relative;
    width: 13ch;
    padding: 0.325em 0;
    text-align: center;
}

.watch-livestream-btn:first-child {
  margin-bottom: 0.5em;
}

.watch-livestream-btn::after {
    content: '';
    display: block;
    position: absolute;
    background-image: url(/play-btn.svg);
    top: 0.4em;
    height: 1.17em;
    width: 1.17em;
    right: 0.3em;
    background-size: contain;
    background-repeat: no-repeat;
}



@media screen and (min-width: 768px) {
    .watch-livestream-item {
        flex-direction:row;
        --left-section-width:7em;
        --right-section-width:15ch;
        --primary-info-height:50%;
        padding:0;
        height:7em;
    }

    

    .watch-livestream__left-section {
        width: var(--left-section-width);
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    

    .start-word {
        margin-right:0;
        margin-bottom: .8em;
    }

    .start-hour {
        text-align: center;
    }

    .watch-livestream__icon-section {
        height: 100%;
        width: var(--left-section-width);
        padding: 0;
    }

    .watch-livestream__mid-section {
        width:calc(100% - var(--left-section-width) - var(--left-section-width) - var(--right-section-width)); /* Left section, icon, right sections */
        height:100%;
        padding-left:1em;
        padding-top: 1em;
        padding-bottom: 1em;
        justify-content: center;
        align-items: center;
    }


    .watch-livestream__primary-info {
        justify-content:flex-start;
        align-items:flex-end;
    }

    .watch-livestream-item .primary-info__item {
        margin-bottom:0;
        text-align:left;
        align-items:center;
        flex-wrap:wrap;
        padding:0;
        padding-bottom:0
    }



    .watch-livestream__secondary-info {
        justify-content:flex-start;
        align-items:flex-start;
        padding-bottom: 0;
    }

    .watch-livestream__right-section {
        width: var(--right-section-width);
        height: 100%;
        margin-left: 1em;
        align-items: flex-start;
    }
}

.tbltenn-icon-wrapper, .tenn-icon-wrapper, .badminton-icon-wrapper {
    max-width: 65px;
    max-height: 65px;
}

.tbltenn-icon-wrapper .tbltenn-icon {
    fill: var(--darker-bckgrnd-color);
}
</style>