export const SPORT_BROWSER_STORAGE_KEY = 'SPZ_USER_SPORT'
export const ORGANIZATION_BROWSER_STORAGE_KEY = 'SPZ_USER_ORG'
export const SPORTZONE_MAIN_PAGE_URL = 'https://sportzone.live'
export const SPORTZONE_USER_LAST_ROLE_STORAGE_KEY = 'SPZ_LAST_ROLE'
export const SPORTZONE_USER_OPPONENT_SPLITTER_KEY = 'SPZ_OPP_SPLIT'
export const TERMS_IN_SPORT_CONTEXT = {
    'entity': {
        'Sport' : 'Member',
        'generic' : 'Member',
        'Volleyball': 'Team',
        'Tennis': 'Player',
        'TableTennis' : 'Player',
    }
}

