<template>
    <TheOrganizerDashboard title="Livestreams">
        <div class="manage-livestream-controls">
            <button @click="$refs.livestreamPopup.openModal(), prepareLivestreamModal()"
                class="manage-livestream-controls__create-livestream-btn btn add-btn"
                title="Create Livestream">Livestream</button>
            <div class="filter-group">
                <SportzoneSelect id="livestream-status" :options="['Status', ...LIVESTREAM_STATUSES]"
                    v-model:selectedOption="statusFilter"></SportzoneSelect>
            </div>
        </div>
        <div @scroll="onLivestreamsListWrapperScroll" class="livestreams-list">
            <ManageLivestreamItem @click="selectLivestream(livestream.id)" @deleteClick="deleteLivestream(livestream.id)"
                v-for="livestream in listLivestreams(statusFilter)" :key="livestream.id" :livestream="livestream"
                @delete="openConfirmDeleteLivestreamDialog(livestream.id)"
                @edit="onEditButtonClicked(livestream, livestream.id)"
                :class="{ selected: livestream.id === selectedLivestream }">
            </ManageLivestreamItem>
            <LoadingIcon v-show="showLoadingIcon" />
        </div>
        <Modal ref="livestreamPopup">
            <template v-slot:header>
                <h1>{{ isCreate ? 'Create Livestream' : 'Edit Livestream' }}</h1>
            </template>
            <template v-slot:body>
                <div class="popup__input-group spz-select-group">
                    <label for="new-stream-quality">Sport</label>
                    <select id="new-stream-quality" v-model="newLivestream.sport">
                        <option disabled value="">Please select one</option>
                        <option v-for="sport in SPORT_TYPES" :key="sport" :value="sport">{{ sport }}</option>
                    </select>
                </div>
                <div class="popup__input-group spz-select-group">
                    <label for="new-stream-channel">Channel</label>
                    <select id="new-stream-channel" v-model="newLivestream.channelId">
                        <option disabled value="">Please select one</option>
                        <option v-for="channel in getUserChannels" :key="channel.id" :value="channel.id">
                            {{ channel.name }}
                        </option>
                    </select>
                </div>
                <div class="popup__input-group spz-checkbox-group">
                    <input id="useYoutubeStream" v-model="useYoutubeStream" type="checkbox" />
                    <label for="useYoutubeStream">Use YouTube Stream</label>
                </div>
                <div class="livestream-form">
                    <div class="live-broadcasts" v-if="useYoutubeStream">
                        <YoutubeLiveBroadcastItem @click="onYoutubeBroadcastClick(livebroadcast)"
                            v-for="livebroadcast in youtubeLiveBroadcasts" :key="livebroadcast.id"
                            :yt-live-broadcast="livebroadcast"
                            :is-selected="selectedYTLiveBroadcast?.id === livebroadcast.id" />
                    </div>
                    <div class="popup__input-group spz-text-input-group" v-show="!newLivestream.isAutomatic && !useYoutubeStream">
                        <label for="new-stream-platform-id">Livestream ID</label>
                        <input id="new-stream-platform-id" v-model="newLivestream.atStreamingPlatformId" />
                    </div>
                    <div class="popup__input-group spz-text-input-group">
                        <label for="new-stream-title">Title</label>
                        <input id="new-stream-title" v-model="newLivestream.title" placeholder="Title" />
                    </div>
                    <div class="popup__input-group narrow spz-text-input-group">
                        <label for="dp-new-stream-start-time">Starts at</label>
                        <VueDatePicker uid="new-stream-start-time" v-model="newLivestream.startTime" time-picker-inline utc="true" />
                    </div>
                    <div class="popup__input-group narrow spz-text-input-group">
                        <label for="dp-new-stream-end-time">Ends at</label>
                        <VueDatePicker uid="new-stream-end-time" v-model="newLivestream.endTime" time-picker-inline utc="true" />
                    </div>
                    <div class="popup__input-group spz-select-group">
                        <label for="new-stream-privacy">Privacy</label>
                        <select id="new-stream-privacy" v-model="newLivestream.privacy">
                            <option v-for="privacy in LIVESTREAM_PRIVACIES" :key="privacy" :value="privacy">{{ privacy }}
                            </option>
                        </select>
                    </div>
                    <div class="popup__input-group spz-select-group">
                        <label for="broadcastTool">Broadcast Through</label>
                        <select id="broadcastTool" v-model="newLivestream.broadcastTool">
                            <option v-for="tool in LIVESTREAM_BROADCAST_TOOLS" :key="tool" :value="tool">{{ tool }}
                            </option>
                        </select>
                    </div>
                    <div class="popup__input-group spz-select-group" v-if="newLivestream.broadcastTool === LIVESTREAM_BROADCAST_TOOLS[0]">
                        <label for="new-stream-station">Station</label>
                        <select id="new-stream-station" v-model="selectedCameraStation">
                            <option disabled value="">Please select one</option>
                            <option v-for="station in getUserStations" :key="station.id" :value="station.id">
                                {{ station.name }}
                            </option>
                        </select>
                    </div>
                    <div class="popup__input-group spz-select-group" v-if="newLivestream.broadcastTool === LIVESTREAM_BROADCAST_TOOLS[0]">
                        <label for="new-stream-camera">Camera</label>
                        <select id="new-stream-camera" v-model="newLivestream.cameraId">
                            <option disabled value="">Please select one</option>
                            <option v-for="camera in getUserCameras" :key="camera.id" :value="camera.id">
                                {{ camera.name }}
                            </option>
                        </select>
                    </div>
                    <div class="popup__input-group spz-checkbox-group" v-if="newLivestream.broadcastTool === LIVESTREAM_BROADCAST_TOOLS[0]">
                        <input id="scheduleStart" v-model="scheduleStart" type="checkbox" />
                        <label for="scheduleStart">Schedule Start</label>
                    </div>
                    <div class="popup__input-group spz-select-group" v-if="false">
                        <label for="new-stream-quality">Quality</label>
                        <select id="new-stream-quality" v-model="newLivestream.quality">
                            <option disabled value="">Please select one</option>
                            <option v-for="quality in STREAM_QUALITIES" :key="quality" :value="quality">{{ quality }}
                            </option>
                        </select>
                    </div>
                    <div v-show="isCreate" class="popup__input-group spz-select-group">
                        <label for="new-stream-container">Match</label>
                        <select id="new-stream-container" v-model="newLivestream.containerId">
                            <option disabled value="">Please select one</option>
                            <option v-for="container in listContainers({ status: 'NotStarted' })" :key="container.id"
                                :value="container.id">
                                {{ container.title }}
                            </option>
                        </select>
                    </div>
                    <div class="popup__input-group spz-checkbox-group" v-if="false">
                        <label for="isAutomatic">
                            <input id="isAutomatic" v-model="newLivestream.isAutomatic" type="checkbox" />
                            Automatic
                        </label>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div>
                    <button @click="onFormSubmit()" class="btn add-btn">{{ isCreate ?
                        'Create' : 'Edit' }}</button>
                    <button @click="$refs.livestreamPopup.closeModal()" class="btn btn-inverse">Cancel</button>
                </div>
            </template>
        </Modal>
        <SportzoneDialog ref="confirmLivestreamDialog" @close="showConfirmLivestreamDialog = false" class="mini"
            :show="showConfirmLivestreamDialog">
            <template v-slot:body>
                Will delete Livestream, do you confirm?
            </template>
            <template v-slot:footer>
                <button @click="$refs.confirmLivestreamDialog.closeModal(), confirmLivestreamDialogCallback()"
                    class="btn delete-btn">
                    Confirm
                </button>
                <button @click="$refs.confirmLivestreamDialog.closeModal()" class="btn btn-inverse">Cancel</button>
            </template>
        </SportzoneDialog>
    </TheOrganizerDashboard>
</template>
<script lang="ts">
import TheOrganizerDashboard from '@/components/main/TheOrganizerDashboard.vue';
import SportzoneSelect from '@/components/util/SportzoneSelect.vue';
import ManageLivestreamItem from '@/components/main/ManageLivestreamItem.vue';
import LoadingIcon from '@/components/util/LoadingIcon.vue';
import Modal from '@/components/main/Modal.vue';
import { ref, defineComponent, Ref, onMounted } from 'vue';
import YoutubeLiveBroadcastItem from '@/components/main/YoutubeLiveBroadcastItem.vue';
import { STREAM_QUALITIES, SPORT_TYPES } from '@/models/main/CommonTypes';
import SportzoneDialog from '@/components/util/SportzoneDialog.vue';
import { createNamespacedHelpers, useStore } from 'vuex';
import { key } from '@/store';
import moment from 'moment';
import YTLiveBroadcast from '@/models/main/YoutubeLiveBroadcast';
import Channel from '@/models/main/Channel';
import Livestream, { LIVESTREAM_STATUSES, SaveLivestream, LivestreamStatus, LIVESTREAM_PRIVACIES, LIVESTREAM_BROADCAST_TOOLS } from '@/models/main/Livestream';
import { formatDate, getUserLastSelectedSport, setUserLastSelectedSport } from '@/composables/common/Util';
import { ContainerStatus } from '@/models/main/Container';
import Station from '@/models/main/Station';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const { mapGetters } = createNamespacedHelpers('stationStore');
const userStoreHelpers = createNamespacedHelpers('userStore');
const containerStoreHelpers = createNamespacedHelpers('containerStore');
const livestreamStoreHelpers = createNamespacedHelpers('livestreamStore')
export default defineComponent({
    components: {
        TheOrganizerDashboard,
        SportzoneSelect,
        ManageLivestreamItem,
        LoadingIcon,
        Modal,
        YoutubeLiveBroadcastItem,
        SportzoneDialog,
        VueDatePicker
    },
    computed: {
        ...mapGetters([
            'getStation',
        ]),
        ...livestreamStoreHelpers.mapGetters(['getLivestreams']),
        ...userStoreHelpers.mapGetters([
            'getUserChannels',
            'getUserCameras',
            'getUserStations',
            'getId',
            'getUserLivestreams',
        ]),
        ...containerStoreHelpers.mapGetters([
            'listContainers',
        ]),
    },
    setup() {
        const store = useStore(key);
        const dateTimeFormat = 'YYYY-MM-DD HH:mm';
        const initialSportValue = getUserLastSelectedSport() ?? SPORT_TYPES[0]
        const livestream: SaveLivestream = {
            title: null,
            startTime: moment().format(dateTimeFormat),
            endTime: moment().format(dateTimeFormat),
            quality: STREAM_QUALITIES[0],
            status: LIVESTREAM_STATUSES[0],
            atStreamingPlatformId: null,
            containerId: 0,
            cameraId: null,
            container: null,
            channelId: null,
            digestEndpoint: null,
            isAutomatic: false,
            sport: initialSportValue,
            privacy: LIVESTREAM_PRIVACIES[0],
            broadcastTool: LIVESTREAM_BROADCAST_TOOLS[1],
        };
        const newLivestream: Ref<SaveLivestream> = ref(livestream);
        const youtubeLiveBroadcasts: Ref<YTLiveBroadcast[]> = ref([]);
        const selectedLivestream: Ref<string | null> = ref(null);
        const showLoadingIcon: Ref<boolean> = ref(true);
        const showConfirmLivestreamDialog = ref(false);
        const confirmLivestreamDialogCallback: Ref<Function> = ref(() => { });
        const selectedYTLiveBroadcast: Ref<YTLiveBroadcast | undefined> = ref();
        const scheduleStart: Ref<boolean> = ref(false);
        const statusFilter: Ref<LivestreamStatus | 'Status'> = ref('Status');
        const isCreate: Ref<boolean> = ref(true);
        const useYoutubeStream: Ref<boolean> = ref(true)
        const selectedCameraStation: Ref<Station | undefined> = ref()
        let scrollTimeout: any;
        const loadInProcess: Ref<boolean> = ref(false);
        const livestreamPopup: Ref<any> = ref(null);
        let selectedLivestreamId: number;
        const prepareLivestreamModal = () => {
            isCreate.value = true;
            const userChannels: Channel[] = store.getters['userStore/getUserChannels'];
            if (userChannels.length >= 1) {
                newLivestream.value.channelId = userChannels[0].id;
                store
                    .dispatch('channelStore/getYoutubeBroadcasts', userChannels[0].id)
                    .then((broadcasts) => {
                        youtubeLiveBroadcasts.value = broadcasts;
                    });
            }
            // const userCameras: Camera[] = store.getters['userStore/getUserCameras'];
            // if (userCameras.length >= 1) {
            //     newLivestream.value.cameraId = userCameras[userCameras.length - 1].id ?? null;
            // }
            newLivestream.value.atStreamingPlatformId = null;
            newLivestream.value.title = null;
            newLivestream.value.startTime = moment().format(dateTimeFormat);
            newLivestream.value.endTime = moment().format(dateTimeFormat);
            newLivestream.value.sport = initialSportValue
        };
        const selectLivestream = (livestreamId: string) => {
            selectedLivestream.value = livestreamId;
        };
        const fetchUserChannels = () => {
            showLoadingIcon.value = true;
            store.dispatch('userStore/getUserChannels', store.getters['userStore/getId']);
            showLoadingIcon.value = false;
        };
        const fetchUserLivestreams = () => {
            showLoadingIcon.value = true;
            store.dispatch('livestreamStore/getLivestreams', {status: statusFilter.value !== "Status" ? statusFilter.value : undefined});
            showLoadingIcon.value = false;
        };
        const deleteLivestream = (livestreamId: number) => {
            store
                .dispatch('livestreamStore/deleteLivestream', livestreamId)
        };
        const listLivestreams = (status: LivestreamStatus | 'Status'): Array<Livestream> => {
            const livestreams: Array<Livestream> = store.getters['livestreamStore/getLivestreams']({statuses: statusFilter.value !== "Status" ? [statusFilter.value] : undefined });
            if (status !== 'Status') {
                const result = livestreams.filter(l => l.status === status);
                return result;
            }
            return livestreams;
        };
        const openConfirmDeleteLivestreamDialog = (livestreamId: number) => {
            showConfirmLivestreamDialog.value = true;
            confirmLivestreamDialogCallback.value = async () => {
                store
                    .dispatch('livestreamStore/deleteLivestream', livestreamId)
            };
        };
        const onYoutubeBroadcastClick = (livebroadcast: YTLiveBroadcast) => {
            selectedYTLiveBroadcast.value = livebroadcast;
            newLivestream.value.title = livebroadcast.snippet.title;
            newLivestream.value.startTime = formatDate(livebroadcast.snippet.scheduledStartTime, dateTimeFormat);
            newLivestream.value.endTime = formatDate(livebroadcast.snippet.scheduledEndTime, dateTimeFormat);
            newLivestream.value.atStreamingPlatformId = livebroadcast.id;
        };
        const onFormSubmit = async () => {
            livestreamPopup.value.closeModal();
            if (newLivestream.value.sport) {
                setUserLastSelectedSport(newLivestream.value.sport);
            }
            if (isCreate.value) {
                await store.dispatch('livestreamStore/saveLivestream', {
                    livestream: newLivestream.value,
                    scheduleStart: scheduleStart.value,
                });
            }
            else {
                await store.dispatch('livestreamStore/editLivestream', {
                    livestream: newLivestream.value,
                    livestreamId: selectedLivestreamId,
                });
            }
        };
        const onEditButtonClicked = (livestream: any, livestreamId: number) => {
            livestreamPopup.value.openModal();
            selectedLivestreamId = livestreamId;
            isCreate.value = false;
            const userChannels: Channel[] = store.getters['userStore/getUserChannels'];
            if (userChannels.length >= 1) {
                newLivestream.value.channelId = userChannels[0].id;
                store
                    .dispatch('channelStore/getYoutubeBroadcasts', userChannels[0].id)
                    .then((broadcasts) => {
                        youtubeLiveBroadcasts.value = broadcasts;
                    });
            }
            newLivestream.value.atStreamingPlatformId = livestream.atStreamingPlatformId;
            newLivestream.value.title = livestream.title;
            newLivestream.value.startTime = livestream.startTime;
            newLivestream.value.endTime = livestream.endTime;
            newLivestream.value.sport = livestream.sport;
            setUserLastSelectedSport(livestream.sport)
        };
        const fetchNotStartedContainers = () => {
            const status: ContainerStatus = 'NotStarted';
            store.dispatch('containerStore/fetchContainers', { status });
        };
        const fetchUserStations = () => {
            store.dispatch('userStore/getUserStations', store.getters['userStore/getId']);
        };
        const dateTimeFormater = (value: any) => {
            return moment(value).format('YYYY-MM-DD HH:mm');
        };
        const onLivestreamsListWrapperScroll = (event: any) => {
            const scrollHeight = event.target.scrollHeight;
            const scrollTop = event.target.scrollTop;
            const offsetHeight = event.target.offsetHeight;
            const margin = 20;
            let bottomOfWindow = scrollHeight - scrollTop - offsetHeight < margin;
            if (bottomOfWindow) {
                if (scrollTimeout) {
                    clearTimeout(scrollTimeout);
                }
                scrollTimeout = setTimeout(onScrollStopped, 200);
            }
        };
        const onScrollStopped = () => {
            if (!loadInProcess.value) {
                loadInProcess.value = true;
                showLoadingIcon.value = false;
                fetchUserLivestreams();
                loadInProcess.value = false;
                showLoadingIcon.value = true;
            }
        };
        onMounted(fetchNotStartedContainers);
        onMounted(fetchUserStations);
        onMounted(fetchUserChannels);
        onMounted(fetchUserLivestreams);
        return {
            store,
            STREAM_QUALITIES,
            LIVESTREAM_STATUSES,
            LIVESTREAM_BROADCAST_TOOLS,
            LIVESTREAM_PRIVACIES,
            SPORT_TYPES,
            newLivestream,
            youtubeLiveBroadcasts,
            selectedLivestream,
            showLoadingIcon,
            showConfirmLivestreamDialog,
            confirmLivestreamDialogCallback,
            selectedYTLiveBroadcast,
            scheduleStart,
            statusFilter,
            isCreate,
            useYoutubeStream,
            selectedCameraStation,
            livestreamPopup,
            prepareLivestreamModal,
            selectLivestream,
            deleteLivestream,
            listLivestreams,
            openConfirmDeleteLivestreamDialog,
            onYoutubeBroadcastClick,
            onFormSubmit,
            dateTimeFormater,
            onEditButtonClicked,
            onLivestreamsListWrapperScroll,
        };
    },
});
</script>
<style>
.manage-livestream-controls {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
}

.filter-group label {
    color: var(--main-font-clr);
}

.livestreams-list {
    margin-top: 1em;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: var(--main-font-clr) var(--bright-font-clr);
}

.popup__input-group {
    display: flex;
    margin-top: 0.5rem;
    width: 100%;
}

.popup__input-group>label {
    text-align: left;
    line-height: 1.5rem;
}

.popup__input-group>input[type="checkbox"] {
    width: 1.5rem;
    height: 1.5rem;
}

.livestream-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.live-broadcasts {
  width: 100%;
}
</style>