<template>
<TheOrganizerDashboard>
    <div class="badminton-score">
        <div class="container-title">{{getCurrentContainer?.title}}</div>
        <div class="score-form" :class="{'disabled': formIsDisabled}">
            <div class="break-controls">
                <button class="btn" type="button" title="Stats" @click="showStats">Show Stats</button>
                <button @click="onShareLinkButtonClicked" class="btn btn-inverse" type="button" title="Share">Share</button>
            </div>
            <div class="match-controls">
                <button @click="onReverseClick" class="btn delete-btn" type="button" title="Undo" :disabled="formIsDisabled">Undo</button>
                <Modal ref="infoPopUp">
                        <template v-slot:header>
                            <h1>Share</h1>
                        </template>
                        <template v-slot:body>
                            <div class="link-section">
                                {{ shareLink }}
                            </div>
                        </template>
                        <template v-slot:footer>
                            <div>
                                <button @click="infoPopUp.closeModal(), copyToClipboard(shareLink)" class="btn">Copy</button>
                                <button @click="infoPopUp.closeModal()" class="btn btn-inverse">Cancel</button>
                            </div>
                        </template>
                </Modal>
                <Modal ref="statsPopup" class="modal-large">
                        <template v-slot:header>
                            <h1>Match Stats</h1>
                        </template>
                        <template v-slot:body>
                            <div class="entity-stats-table-wrapper">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Team</th>
                                            <th>{{ BADMINTON_POINT_TITLE_PAIRS[BADMINTON_SMASH] }}</th>
                                            <th>{{ BADMINTON_POINT_TITLE_PAIRS[BADMINTON_COEAR] }}</th>
                                            <th>{{ BADMINTON_POINT_TITLE_PAIRS[BADMINTON_DROP] }}</th>
                                            <th>{{ BADMINTON_POINT_TITLE_PAIRS[BADMINTON_SHORT] }}</th>
                                            <th>{{ BADMINTON_POINT_TITLE_PAIRS[BADMINTON_SMASH_NET]}}</th>
                                            <th>{{ BADMINTON_POINT_TITLE_PAIRS[BADMINTON_DRIVE]}}</th>
                                            <th>{{ BADMINTON_POINT_TITLE_PAIRS[BADMINTON_OTHER]}}</th>
                                            <th>{{ BADMINTON_POINT_TITLE_PAIRS[BADMINTON_FAULT]}}</th>
                                            <th>{{ BADMINTON_POINT_TITLE_PAIRS[BADMINTON_SRV_FAULT]}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="getCurrentHomeEntity">
                                            <td>
                                                {{ getCurrentHomeEntity.name }}
                                            </td>
                                            <td>{{ getCurrentContainerBasicStats?.homeEntity[0].entityStats[BADMINTON_SMASH]?.count ?? 0}}</td>
                                            <td>{{ getCurrentContainerBasicStats?.homeEntity[0].entityStats[BADMINTON_COEAR]?.count ?? 0}}</td>
                                            <td>{{ getCurrentContainerBasicStats?.homeEntity[0].entityStats[BADMINTON_DROP]?.count ?? 0}}</td>
                                            <td>{{ getCurrentContainerBasicStats?.homeEntity[0].entityStats[BADMINTON_SHORT]?.count ?? 0}}</td>
                                            <td>{{ getCurrentContainerBasicStats?.homeEntity[0].entityStats[BADMINTON_SMASH_NET]?.count ?? 0}}</td>
                                            <td>{{ getCurrentContainerBasicStats?.homeEntity[0].entityStats[BADMINTON_DRIVE]?.count ?? 0}}</td>
                                            <td>{{ getCurrentContainerBasicStats?.homeEntity[0].entityStats[BADMINTON_OTHER]?.count ?? 0}}</td>
                                            <td>{{ getCurrentContainerBasicStats?.homeEntity[0].entityStats[BADMINTON_FAULT]?.count ?? 0}}</td>
                                            <td>{{ getCurrentContainerBasicStats?.homeEntity[0].entityStats[BADMINTON_SRV_FAULT]?.count ?? 0}}</td>
                                        </tr>
                                        <tr v-if="getCurrentAwayEntity">
                                            <td>
                                                {{ getCurrentAwayEntity.name }}
                                            </td>
                                            <td>{{ getCurrentContainerBasicStats?.awayEntity[0].entityStats[BADMINTON_SMASH]?.count ?? 0}}</td>
                                            <td>{{ getCurrentContainerBasicStats?.awayEntity[0].entityStats[BADMINTON_COEAR]?.count ?? 0}}</td>
                                            <td>{{ getCurrentContainerBasicStats?.awayEntity[0].entityStats[BADMINTON_DROP]?.count ?? 0}}</td>
                                            <td>{{ getCurrentContainerBasicStats?.awayEntity[0].entityStats[BADMINTON_SHORT]?.count ?? 0}}</td>
                                            <td>{{ getCurrentContainerBasicStats?.awayEntity[0].entityStats[BADMINTON_SMASH_NET]?.count ?? 0}}</td>
                                            <td>{{ getCurrentContainerBasicStats?.awayEntity[0].entityStats[BADMINTON_DRIVE]?.count ?? 0}}</td>
                                            <td>{{ getCurrentContainerBasicStats?.awayEntity[0].entityStats[BADMINTON_OTHER]?.count ?? 0}}</td>
                                            <td>{{ getCurrentContainerBasicStats?.awayEntity[0].entityStats[BADMINTON_FAULT]?.count ?? 0}}</td>
                                            <td>{{ getCurrentContainerBasicStats?.awayEntity[0].entityStats[BADMINTON_SRV_FAULT]?.count ?? 0}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </template>
                        <template v-slot:footer>
                            <div>
                                <button @click="statsPopup.closeModal()" class="btn btn-inverse">Close</button>
                            </div>
                        </template>
                    </Modal>
            </div>
            <div class="match-scoreboard">
                <div class="home-team">
                    <div class="main-scoreboard">
                        <div class="scoreboard-top">
                            <div class="team-name default-box">{{getCurrentHomeEntity?.name}}</div>
                            <ActionBox :isDisabled="formIsDisabled" class="point-box" @click="onHomePointBoxClick">{{getCurrentHomeEntityPoints}}</ActionBox>
                        </div>
                        <div class="scoreboard-bottom">
                            <ActionBox :isDisabled="formIsDisabled" @click="onGameBoxClick(getCurrentHomeEntity?.id)" style="float:left;">{{currentHomeEntityGamePoints}}</ActionBox>
                        </div>
                        <div class="home-point-types">
                                <PointTypesSet @click="onHomePointBoxClick" :isDisabled="formIsDisabled" />
                        </div>
                    </div>
                </div>
                <div class="splitter">:</div>
                <div class="away-team">
                    <div class="main-scoreboard">
                        <div class="scoreboard-top">
                            <div class="team-name default-box">{{getCurrentAwayEntity?.name}}</div>
                            <ActionBox :isDisabled="formIsDisabled" class="point-box" @click="onAwayPointBoxClick">{{getCurrentAwayEntityPoints}}</ActionBox>
                        </div>
                        <div class="scoreboard-bottom">
                            <ActionBox :isDisabled="formIsDisabled" @click="onGameBoxClick(getCurrentAwayEntity?.id)" style="float:right;">{{currentAwayEntityGamePoints}}</ActionBox>
                        </div>
                        <div class="away-point-types">
                                <PointTypesSet @click="onAwayPointBoxClick" :isDisabled="formIsDisabled" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</TheOrganizerDashboard>
</template>
<script lang="ts">
import TheOrganizerDashboard from '@/components/main/TheOrganizerDashboard.vue' 
import ActionBox from '@/components/volleyball/ActionBox.vue'
import { useStore, createNamespacedHelpers} from 'vuex'
import { defineComponent, onMounted, ref, Ref } from 'vue'
import { key } from '@/store'
import { Container } from '@/models/main/Container'
import { useRoute } from 'vue-router'
import { Event, EventType } from '@/models/main/Event';
import { BADMINTON_COEAR, BADMINTON_DRIVE, BADMINTON_DROP, BADMINTON_FAULT, BADMINTON_OTHER, BADMINTON_POINT_TITLE_PAIRS, BADMINTON_SHORT, BADMINTON_SMASH, BADMINTON_SMASH_NET, BADMINTON_SRV_FAULT, BadmintonGameControlType, BadmintonPointType } from '@/composables/badminton/BadmintonEventTypes'
import useCurrentContainerComputeds  from '@/composables/badminton/UseCurrentContainerComputeds'
import Modal from '@/components/main/Modal.vue';
import { copyToClipboard, addAccessKeyQueryParam } from '@/composables/common/Util';
import PointTypesSet from '@/components/badminton/PointTypesSet.vue';

const { mapGetters } = createNamespacedHelpers('containerStore')

export default defineComponent({
    computed: {
        ...mapGetters([
            'getContainer',
            'getHomeEntity',
            'getAwayEntity',
        ]),
    },
    setup() {
        const formIsDisabled: Ref<boolean> = ref(false)
        const store = useStore(key);
        const route = useRoute()
        const matchId: Ref<number> = ref(parseInt(route.params.id as string));
        const match: Ref<Container | undefined> = ref()
        let accessKey: string | undefined = route.query?.accessKey as string;
        const shareLink: Ref<string | null> = ref(null);
        const infoPopUp: Ref<any> = ref(null);
        const statsPopup: Ref<any> = ref(null);
        const getMatch = async() => {
            match.value = await store.dispatch('containerStore/getContainer', matchId.value)
        }
        const toggleHomePlayerSelect = (playerId: number) => {
            if(selectedHomePlayerId.value === playerId) {
                selectedHomePlayerId.value = 0
            } else {
                selectedHomePlayerId.value = playerId
            }
        }
        const toggleAwayPlayerSelect = (playerId: number) => {
            if(selectedAwayPlayerId.value === playerId) {
                selectedAwayPlayerId.value = 0
            } else {
                selectedAwayPlayerId.value = playerId
            }
        }
        const onHomePointBoxClick = async (pointType: BadmintonPointType) => {
            if(getCurrentHomeEntity.value?.id !== undefined) {
                const addedOk = await addEvent("Point", getCurrentHomeEntity.value.id, pointType)
            }
        };
        const onAwayPointBoxClick = async (pointType: BadmintonPointType) => {
            if(getCurrentAwayEntity.value?.id !== undefined) {
                const addedOk = await addEvent("Point", getCurrentAwayEntity.value.id, pointType)
            }
        };
        const onMatchFinishedClick = async (entityId: number) => {
            const matchFinishedEventValue: BadmintonGameControlType = "Bdm_M"
            await addEvent("GameControl", entityId, matchFinishedEventValue)
        }
        const onReverseClick = async () => {
            if(match.value !== undefined) {
                formIsDisabled.value = true
                const result  = await store.dispatch('containerStore/removeLastEvent', match.value.id)
                formIsDisabled.value = false
                return result
            }
        }
        const onGameBoxClick = async (entityId: number) => {
            const gameFinished: BadmintonGameControlType = "Bdm_G"
            await addEvent("GameControl", entityId, gameFinished)
        }

        const showStats = () => {
            statsPopup.value.openModal();
        }

        const { 
            calculateEntityPoints,
            calculateEntityGames,
            getCurrentContainerBasicStats,
            getCurrentContainer,
            getCurrentHomeEntity,
            getCurrentAwayEntity,
            getCurrentHomeEntityPoints,
            getCurrentAwayEntityPoints,
            currentHomeEntityGamePoints,
            currentAwayEntityGamePoints,
        } = useCurrentContainerComputeds(store, matchId)

        const addEvent = async (type: EventType, entityId: number, description: string, playerId?: number): Promise<boolean> => {
            if(getCurrentHomeEntity.value && getCurrentHomeEntity.value && match.value !== undefined && match.value .id !== null) {
                const currentTimeStamp = new Date()
                const event: Event = {
                    type: type,
                    entityId: entityId,
                    playerId: playerId,
                    description: description,
                    containerId: match.value?.id,
                    startTimestamp: currentTimeStamp,
                    endTimestamp: currentTimeStamp
                }

                formIsDisabled.value = true
                const result = await store.dispatch('containerStore/storeEvent', {
                    event,
                    accessKey,
                });
                formIsDisabled.value = false
                return result
            }

            return false
        }

        onMounted(getMatch);

        const homeTimeouts: Ref<string[]> = ref([])
        const awayTimeouts: Ref<string[]> = ref([])

        const selectedHomePlayerId: Ref<number> = ref(0)
        const selectedAwayPlayerId: Ref<number> = ref(0)
        const onShareLinkButtonClicked = async () => {
            accessKey = await store.dispatch('containerStore/createOperateAccess', matchId.value);
            if (accessKey !== undefined) {
                shareLink.value = addAccessKeyQueryParam(route.query, accessKey);
            }
            infoPopUp.value.openModal();
        };
        return {
            matchId,
            match,
            homeTimeouts,
            awayTimeouts,
            selectedHomePlayerId,
            selectedAwayPlayerId,
            calculateEntityPoints,
            calculateEntityGames,
            getCurrentContainer,
            getCurrentHomeEntity,
            getCurrentAwayEntity,
            getCurrentHomeEntityPoints,
            getCurrentAwayEntityPoints,
            currentHomeEntityGamePoints, 
            currentAwayEntityGamePoints,
            toggleHomePlayerSelect,
            toggleAwayPlayerSelect,
            onHomePointBoxClick,
            onAwayPointBoxClick,
            onMatchFinishedClick,
            onReverseClick,
            onGameBoxClick,
            formIsDisabled,
            accessKey,
            shareLink,
            infoPopUp,
            onShareLinkButtonClicked,
            copyToClipboard,
            BADMINTON_POINT_TITLE_PAIRS,
            getCurrentContainerBasicStats,
            showStats,
            statsPopup,
            BADMINTON_SMASH,
            BADMINTON_COEAR,
            BADMINTON_DROP,
            BADMINTON_SHORT,
            BADMINTON_SMASH_NET,
            BADMINTON_DRIVE,
            BADMINTON_OTHER,
            BADMINTON_FAULT,
            BADMINTON_SRV_FAULT,
        }
    },
    components: {
        TheOrganizerDashboard,
        ActionBox,
        Modal,
        PointTypesSet,
    }
})
</script>

<style>
.badminton-score {
    width: 100%;
    max-width: 1000px;
    z-index: 1;
}

.badminton-score .score-form {
    --point-types-width: 100%;
}

.badminton-score .container-title {
  font-size: 1.5em;
  font-weight: 500;
  text-align: center;
}

.badminton-score .home-point-types {
    align-items: flex-start;
}

.badminton-score .away-point-types {
    align-items: flex-end;
}


</style>