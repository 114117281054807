<template>
  <div @click="$emit('click')" class="container-item badge-item" :class="{ selected: isSelected }">
    <div class="container-item__title badge-item-col">
        <div class="badge-info-heading">Title</div>
        <div class="badge-info-value">{{ container.title }}</div>
    </div>
    <div class="container-item__stream badge-item-col">
        <router-link v-if="container.livestreams[0]" class="container-item__stream-watch-link btn btn-inverse" :to="{ name: getSportWatchViewName(container.livestreams[0].sport), params: { id: container.livestreams[0].id },}" 
        target="_blank">
            {{container.livestreams[0].status === "Completed" || container.livestreams[0].status === "Stopping" ? "Watch" : (container.livestreams[0].status === "Pending" ? "Soon" : "Live")}}
        </router-link>
    </div>
    <div class="container-item__start-time badge-item-col">
        <div class="badge-info-heading">Start</div>
        <div class="badge-info-value">
          {{ formatStringDate(container.startTime) }}
        </div>
    </div>
    <div class="container-item__home-entity badge-item-col" v-if="getHomeEntity && false">
        <div class="badge-info-heading">Home</div>
        <div class="badge-info-value">{{ getHomeEntity.name }}</div>
    </div>
    <div class="container-item__away-entity badge-item-col" v-if="getAwayEntity && false">
        <div class="badge-info-heading">Away</div>
        <div class="badge-info-value">{{ getAwayEntity.name }}</div>
    </div>
    <div class="container-item__status badge-item-col badge-status" :class="container.status">
        {{ container.status === "NotStarted" ? "Pending" : container.status }}
    </div>
    <div v-if="container.type !== 'Tournament'" class="container-item__actions badge-item-col">
      <router-link v-if="container.sport === 'Snooker'" class="btn edit-btn"
        :to="{ name: 'Snooker Score', params: { id: container.id } }">Score</router-link>
      <router-link v-if="container.sport === 'TableTennis'" class="btn edit-btn"
        :to="{ name: 'Table Tennis Score', params: { id: container.id } }">Score</router-link>
      <router-link v-if="container.sport === 'Tennis'" class="btn edit-btn"
        :to="{ name: 'Tennis Score', params: { id: container.id } }">Score</router-link>
      <router-link v-if="container.sport === 'Volleyball'" class="btn edit-btn"
        :to="{ name: 'Volleyball Score', params: { id: container.id } }">Score</router-link>
        <router-link v-if="container.sport === 'Badminton'" class="btn edit-btn"
        :to="{ name: 'Badminton Score', params: { id: container.id } }">Score</router-link>
      <div class="dropdown-action-box" tabindex="0">
        <div class="dropdown-action-box__title">
          Actions
        </div>
        <div class="dropdown-action-box__actions">
          <button @click="$emit('edit')" class="droopdown-action-box__action">Edit</button>
          <button @click="$emit('delete')" class="droopdown-action-box__action" role="button">Delete</button>
        </div>
      </div>
    </div>
    <div v-else>
        <router-link class="btn edit-btn"
        :to="{ name: 'Container Menu', params: { organizationId: container.organizationId, id: container.id } }">Manage</router-link>
    </div>
  </div>
</template>
<script lang="ts">
import { formatStringDate, getSportWatchViewName } from '@/composables/common/Util';
import { computed, defineComponent, PropType } from 'vue';
import { Container } from '@/models/main/Container';
import { useStore } from 'vuex';
import { key } from '@/store';
const ContainerItem = defineComponent({
  emits: [
    'click',
    'edit',
    'delete',
  ],
  props: {
    isSelected: {
      type: Boolean,
      required: false,
    },
    container: {
      type: Object as PropType<Container>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore(key);
    const deleteContainer = async (containerId?: number) => {
      if (containerId) {
        await store.dispatch('containerStore/deleteContainer', containerId);
      }
    };

    const getHomeEntity = computed(() => {
        var entities = props.container.entities
        if(entities) {
            var entity1 = entities[0]?.entity
            if(entity1?.type === "Home") {
                return entity1;
            } else {
                return entities[1]?.entity
            }
        }
    })
    const getAwayEntity = computed(() => {
        var entities = props.container.entities
        if(entities) {
            var entity1 = entities[0]?.entity
            if(entity1?.type === "Away") {
                return entity1;
            } else {
                return entities[1]?.entity
            }
        }
    })
    return {
      deleteContainer,
      formatStringDate,
      getSportWatchViewName,
      getHomeEntity,
      getAwayEntity,
    };
  },
});
export default ContainerItem;
</script>
<style>
.container-item.badge-item {
    --entity-name-width: 12em;
    --actions-width: 15em;
    --start-time-width: 15em;
    flex-direction: row;
    margin: var(--badge-item-margin);
    color: var(--darker-font-clr);
    font-weight: 600;
    min-width: 50ch;
    justify-content: flex-start;
    --entity-name-width: 0em;
}

.container-item__title.badge-item-col {
    width: calc(100% - var(--actions-width) - var(--start-time-width) - var(--entity-name-width) - 11em);
}

.container-item__home-entity.badge-item-col, .container-item__away-entity.badge-item-col {
    width: var(--entity-name-width);
}

.container-item__start-time.badge-item-col {
  width: var(--start-time-width);
}

.container-item__actions.badge-item-col {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: var(--actions-width);
}
.container-item__actions a {
  text-decoration: none;
  margin-right: 1em;
}

.container-item__stream.badge-item-col {
  min-width: 7em;
}

.container-item__stream-watch-link::before {
    content: '';
    position: absolute;
    top: calc((100% - 1em) / 2);
    left: 0.5em;
    width: 1em;
    height: 1em;
    background: var(--light-blue-triangle-background);
    background-position-x: 0;
}
.container-item__stream-watch-link {
    position: relative;
    padding-left: 1.8em;
    line-height: 1.1;
    height: 2em;
}
.container-item__stream-watch-link.btn.btn-inverse:hover, .container-item__stream-watch-link.btn.btn-inverse:focus {
  color: var(--bright-font-clr);
  background-color: var(--btn-darker-background-color);
}


.dropdown-action-box {
  border: 1px solid transparent;
  border-radius: var(--default-border-rad);
  display: flex;
  position: relative;
  cursor: pointer;
  padding: 0.5em;
  padding-right: 2ch;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

.dropdown-action-box::after {
  content: '';
  position: absolute;
  width: 0.8em;
  height: 0.5em;
  background-color: var(--main-font-clr);
  -webkit-clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  right: 0.25ch;
  top: 0.7em;
}

.dropdown-action-box__title {
  text-transform: uppercase;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

.dropdown-action-box__actions {
  position: absolute;
  top: 2.5em;
  background-color: var(--new-background-color);
  border: 1px solid var(--select-border);
  border-radius: var(--default-border-rad);
  text-transform: uppercase;
  display: none;
  flex-direction: column;
  cursor: pointer;
  z-index: 5;
  width: 100%;
  left: 0;
}

.droopdown-action-box__action {
  padding: 0.5em 0.25em;
  text-align: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  border: none;
  cursor: pointer;
  text-transform: inherit;
}

.droopdown-action-box__action:hover {
  background-color: var(--select-border);
}

.dropdown-action-box:hover,
.dropdown-action-box:focus-within {
  background-color: var(--new-background-color);
  border: 1px solid var(--select-border);
}

.dropdown-action-box:focus-within .dropdown-action-box__actions,
.dropdown-action-box__actions:active {
  display: flex;
}
</style>