<template>
    <div class="organization-application-item badge-item">
        <div class="organization-application-name badge-item-col">
            <div class="badge-info-heading">Name</div>
            <div class="badge-info-value">
                {{ application.user?.firstName }} {{ application.user?.middleName ? application.user.middleName + ' ' : '' }}{{ application.user?.lastName }}
            </div>
        </div>
        <div class="organization-application-email badge-item-col">
            <div class="badge-info-heading">Email</div>
            <div class="badge-info-value" >
                {{ application.user?.email }}
            </div>
        </div>
        <div class="organization-application-date badge-item-col">
            <div class="badge-info-heading">Applied on</div>
            <div class="badge-info-value">{{ formatStringDate(application.dateApplied) }}</div>
        </div>
        <div class="organization-application-status badge-item-col badge-status" :class="application.status">{{ application.status }}</div>
        <div class="organization-application-edit-control badge-item-col">
            <button class="btn accept-btn" @click="$emit('accept')" v-show="application.status == APPLICATION_STATUSES[0]">Accept</button>
        </div>
        <div class="organization-application-delete-control badge-item-col">
            <button class="btn delete-btn" @click="$emit('reject')" v-if="application.status == APPLICATION_STATUSES[0]">Reject</button>
            <button class="btn delete-btn" @click="$emit('delete')" v-else >Delete</button>
        </div>
    </div>
</template>
<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { formatStringDate } from '@/composables/common/Util';
import { APPLICATION_STATUSES, OrganizationApplication } from '@/models/main/OrganizationApplication';

 const OrganizationApplicationItem = defineComponent({
    props: {
        application: {
            type: Object as PropType<OrganizationApplication>,
            required: true
        }
    },
    emits: ['accept', 'reject', 'delete'],
    setup(props) {

        return {
            APPLICATION_STATUSES,
            formatStringDate
        }
    }
 })

 export default OrganizationApplicationItem;
</script>
<style>
.organization-application-email.badge-item-col, .organization-application-name.badge-item-col  {
    width: 18em;
}

.organization-application-date.badge-item-col {
    width: 16em;
}

.organization-application-edit-control.badge-item-col, .organization-application-delete-control.badge-item-col {
    width: 8em;
}
</style>