<template>
    <TheOrganizerDashboard>
        <h1>Service Unavailable</h1>
    </TheOrganizerDashboard>
</template>
<script lang="ts">
import TheOrganizerDashboard from '@/components/main/TheOrganizerDashboard.vue';
import { defineComponent } from 'vue';
export default defineComponent({
    components: {
        TheOrganizerDashboard,
    },
});
</script>