<template>
    <TheUserDashboard ref="mainWrapper" v-if="authModule.isAuthenticated" @scroll="onMainWrapperScroll" 
    :class="{ 'bwl': sportFilter === 'Bowling', 'tbltenn': sportFilter === 'TableTennis', 'tenn': sportFilter === 'Tennis', 'vly': sportFilter === 'Volleyball',
        'archive': activeSubPageName.includes('archive'), 'live' : activeSubPageName == 'live'
     }">
        <div class="multiple-subpage-layout-wrapper">
            <ul class="user-content-submenu"> 
                <li class="user-content-submenu__item" :class="{'active' : activeSubPageName == 'live'}" @click="onSubPageMenuItemClick('live')">
                    Live <span>1</span>
                </li>
                <li class="user-content-submenu__item" :class="{'active' : activeSubPageName == 'archive'}" @click="onSubPageMenuItemClick('archive'), onSportFilterClick('Volleyball')">Volleyball <span>&infin;</span></li>
                <li class="user-content-submenu__item" :class="{'active' : activeSubPageName == 'archive-bowling'}" @click="onSubPageMenuItemClick('archive-bowling'), onSportFilterClick('Bowling')">Bowling <span>&infin;</span></li>
                <li class="user-content-submenu__item" :class="{'active' : activeSubPageName == 'archive-badminton'}" @click="onSubPageMenuItemClick('archive-badminton'), onSportFilterClick('Badminton')">Badminton <span>&infin;</span></li>
                <li class="user-content-submenu__item" :class="{'active' : activeSubPageName == 'archive-table-tennis'}" @click="onSubPageMenuItemClick('archive-table-tennis'), onSportFilterClick('TableTennis')">Table Tennis <span>76</span></li>
                <li class="user-content-submenu__item" :class="{'active' : activeSubPageName == 'archive-tennis'}" @click="onSubPageMenuItemClick('archive-tennis'), onSportFilterClick('Tennis')">Tennis <span>&infin;</span></li>
            </ul>
            <div class="user-content-submenu__content-wrapper">
                <div class="watch-livestreams-list subpage-item" :class="{'active' : activeSubPageName == 'live'}">
                    <WatchLivestreamItem v-for="livestream in getLivestreams({ sort: 'ASC', statuses: liveStatuses })" :key="livestream.id" :livestream="livestream" />
                    <h1>No livestreams at the moment</h1>
                    <LoadingIcon v-show="showLoadingIcon" />
                </div>
                <div class="watch-livestreams-list subpage-item" :class="{'active' : activeSubPageName.includes('archive')}" @scroll="onMainWrapperScroll">
                    <WatchLivestreamItem v-for="livestream in getLivestreams({statuses: completedStatuses, sport: sportFilter})" :key="livestream.id" :livestream="livestream" />
                    <LoadingIcon v-show="showLoadingIcon"/>
                </div>
            </div>
        </div>
</TheUserDashboard>
</template>
<script lang="ts">
import TheUserDashboard from '@/components/main/TheUserDashboard.vue'
import BowlingIcon from '@/components/bowling/BowlingIcon.vue'
import VolleyballIcon from '@/components/volleyball/VolleyballIcon.vue'
import TableTennisIcon from '@/components/tableTennis/TableTennisIcon.vue'
import BadmintonIcon from "@/components/badminton/BadmintonIcon.vue"
import TennisIcon from '@/components/tennis/TennisIcon.vue'
import LoadingIcon from '@/components/util/LoadingIcon.vue'
import { defineComponent, ref, Ref } from 'vue'
import { useStore, createNamespacedHelpers} from 'vuex'
import { key } from '@/store'
import authModule from '@/main';
import WatchLivestreamItem from '@/components/main/WatchLivestreamItem.vue';
import ThePublicHomeLayout from '@/components/main/ThePublicHomeLayout.vue';
import { Sport } from '@/models/main/CommonTypes';
import { LIVESTREAM_STATUSES, LivestreamStatus } from '@/models/main/Livestream'
import { getUserLastSelectedSport, setUserLastSelectedSport } from '@/composables/common/Util'

const livestreamStoreHelpers = createNamespacedHelpers('livestreamPublicStore')
const userStoreHelpers = createNamespacedHelpers('userStore')

export default defineComponent({
    components: {
        TheUserDashboard,
        WatchLivestreamItem,
        ThePublicHomeLayout,
        BowlingIcon,
        VolleyballIcon,
        LoadingIcon,
        TableTennisIcon,
        TennisIcon,
        BadmintonIcon,
    },
    computed: {
        ...livestreamStoreHelpers.mapGetters([
            'getLivestreams',
        ]),
        ...userStoreHelpers.mapGetters([
            'getId',
            'getUser',
        ])
    },
    setup() {
        const initialSportValue = getUserLastSelectedSport() ?? "Volleyball"
        const sportFilter: Ref<Sport | undefined> = ref(initialSportValue)
        const store = useStore(key)
        const completedStatuses: LivestreamStatus[] = [LIVESTREAM_STATUSES[3], LIVESTREAM_STATUSES[4]]
        const liveStatuses: LivestreamStatus[] = [LIVESTREAM_STATUSES[0], LIVESTREAM_STATUSES[1], LIVESTREAM_STATUSES[2]]
        const showLoadingIcon: Ref<boolean> = ref(true);
        let loadInProcess: Ref<boolean> = ref(false)
        const loadLivestreams = async (statuses: LivestreamStatus[], sport?: Sport) => {
            showLoadingIcon.value = true
            await store.dispatch("livestreamPublicStore/getPublicLivestreams", {statuses: completedStatuses, sport }) //TODO: check which subpage is active
            await store.dispatch('livestreamPublicStore/getPublicLivestreams', {statuses: liveStatuses });
            showLoadingIcon.value = false
        }

        const onSportFilterClick = (choosenSport: Sport) => {
            sportFilter.value = choosenSport
            if(scrollTimeout) {
                clearTimeout(scrollTimeout)
            }

            loadInProcess.value = true
            loadLivestreams(completedStatuses, sportFilter.value)
            loadInProcess.value = false
            setUserLastSelectedSport(sportFilter.value)
        }

        const mainWrapper: Ref<any> = ref()

        const onScrollStopped = () => {
            if(!loadInProcess.value) {
                loadInProcess.value = true
                loadLivestreams(completedStatuses, sportFilter.value)
                loadInProcess.value = false
            }
        }

        let scrollTimeout: any
        const onMainWrapperScroll = (event: any) => {
            const scrollTop = event.target.scrollTop;
            const totalScrollHeight = event.target.scrollHeight;
            const offsetHeight = event.target.offsetHeight;
            const margin = 20
            let bottomOfWindow = totalScrollHeight - scrollTop - offsetHeight < margin
            if (bottomOfWindow) {
                if(scrollTimeout) {
                    clearTimeout(scrollTimeout)
                }

                scrollTimeout = setTimeout(onScrollStopped, 200)

            }
        }

        const activeSubPageName: Ref<string> = ref('live')
        const onSubPageMenuItemClick = (subPageName: string) => {
            activeSubPageName.value = subPageName
        }

        loadLivestreams(completedStatuses, sportFilter.value)
        return {
            store,
            authModule,
            sportFilter,
            mainWrapper,
            showLoadingIcon,
            activeSubPageName,
            completedStatuses,
            liveStatuses,
            onSubPageMenuItemClick,
            onMainWrapperScroll,
            onSportFilterClick,
            }
    }
})

</script>
<style>
.archive .watch-livestreams-list {
    --watch-bck-color:  var(--archive-bck-color);
    --loading-icon-moving-color: var(--archive-bck-color);
}

.livestreams-page {
    max-width: 100vw;
    max-height: 100vh;
    overflow-y: scroll;
    scrollbar-width: thin;
}

.admin-section.archive, .livestreams-page.archive {
    scrollbar-color: var(--deep-blue-bck-color) var(--archive-bck-color);
}

.filters-section {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 1em;
}

.filter-by-sport-btn {
    width: 5ch;
    background: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    border: none;
    border-radius: var(--default-border-rad);
    margin-left: 0.5ch;
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-right: 1ch;
}

</style>