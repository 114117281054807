<template>
    <TheOrganizerDashboard>
        <template #heading>
            <router-link :to="{ name: 'Organization Menu', params: { id: organizationId }}">
                <div class="page-heading">{{ organization?.name }}</div>
            </router-link>
            <router-link :to="{ name: 'Container Menu', params: { organizationId: organizationId, id : containerId } }">
                <div class="page-heading">
                    {{ container?.title }}
                </div>
            </router-link>
        </template> 
        <template #default>
            <div class="organizations-wrapper">
                <div class="organization-menu">
                    <router-link :to="{ name: 'Container Properties', params: { organizationId: organizationId, id: containerId }}">
                        <div class="organization-menu-item">
                            Properties
                        </div>
                    </router-link>
                    <router-link v-if="false" :to="{ name: 'Organization Team Management', params: { id: organizationId }}">
                        <div class="organization-menu-item">
                            Teams
                        </div>
                    </router-link>
                    <router-link :to="{ name: 'Organization Containers', params: { organizationId: organizationId }, query: { type: 'Match' } }">
                        <div class="organization-menu-item">
                            Matches
                        </div>
                    </router-link>
                    <router-link :to="{ name: 'Container Views', params: { id: containerId }}">
                        <div class="organization-menu-item">
                            Rankings
                        </div>
                    </router-link>
                </div>
            </div>
        </template>
    </TheOrganizerDashboard>
  </template>
<script setup lang="ts">
import { onMounted, Ref, ref } from "vue";
import { key } from "@/store";
import TheOrganizerDashboard from "@/components/main/TheOrganizerDashboard.vue";
import { Container } from "@/models/main/Container";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import '@vuepic/vue-datepicker/dist/main.css'
import { Organization } from "@/models/main/Organization";

const route = useRoute()
const store = useStore(key);
const organizationId: Ref<number> = ref(parseInt(route.params.organizationId as string));
const organization: Ref<Organization | undefined> = ref()
const containerId: Ref<number> = ref(parseInt(route.params.id as string));
const container: Ref<Container | undefined> = ref()

const loadOrganization = async () => {
    organization.value = await store.dispatch('organizationStore/getOrganization', organizationId.value);
};

const loadContainer = async () => {
    container.value = await store.dispatch('containerStore/getContainer', containerId.value)
}

onMounted(loadOrganization)
onMounted(loadContainer)
</script>
<style>
</style>