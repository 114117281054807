<template>
<div class="main">
  <div class="main__content">
    <slot />
  </div>
</div>
</template>

<script>
import { defineComponent  } from 'vue'

const TheMainSection = defineComponent({
})

export default TheMainSection;
</script>

<style>
.main {
  width: 100%;
  height: 100%;
  z-index: 3;
}

.main__content {
    max-height: 100%;
    overflow: auto;
    min-height: calc(100vh - 200px);
    padding: 1rem;
    color: var(--darker-font-clr);
    border-radius: var(--default-border-rad);
}

.main__content h1 {
  margin: 0;
  display: flex;
  margin-left: 1.2rem;
  color: var(--light-homepage-color);
  float: left;
  font-family: Roboto;
  font-weight: 400;
  font-size: 2em;
  line-height: .85em;
  align-items: flex-end;
  height: 1.15em;
}
</style>