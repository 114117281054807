<template>
    <TheOrganizerDashboard :onUserLoad="onUserLoaded">
        <template #heading>
            <router-link :to="{ name: 'Organizations' }">
                <div class="page-heading">
                    Organizations
                </div>
            </router-link>
            <router-link :to="{ name: 'Organization Menu', params: { id: organizationId }}">
                <div class="page-heading">{{ organization?.name }}</div>
            </router-link>
        </template> 
        <template #default>
            <div class="organizations-wrapper" v-show="organization">
                <div class="organization-menu">
                    <router-link :to="{ name: 'Organization Properties', params: { id: organizationId }}">
                        <div class="organization-menu-item">
                            Properties
                        </div>
                    </router-link>
                    <router-link :to="{ name: 'Organization Members', params: { id: organizationId }}">
                        <div class="organization-menu-item">
                            Individuals
                        </div>
                    </router-link>
                    <router-link :to="{ name: 'Organization Team Management', params: { id: organizationId }}">
                        <div class="organization-menu-item">
                            {{  $c('entity', organization?.sport ?? 'generic') }}s
                        </div>
                    </router-link>
                    <router-link :to="{ name: 'Organization Seasons', params: { organizationId: organizationId }}">
                        <div class="organization-menu-item">
                            Seasons
                        </div>
                    </router-link>
                    <router-link :to="{ name: 'Organization Containers', params: { organizationId: organizationId }, query: { type: 'Tournament' } }">
                        <div class="organization-menu-item">
                            Tournaments
                        </div>
                    </router-link>
                    <router-link :to="{ name: 'Sub Organizations', params: { id: organizationId }}">
                        <div class="organization-menu-item">
                            Sub Organizations
                        </div>
                    </router-link>
                </div>
            </div>
        </template>
    </TheOrganizerDashboard>
  </template>
  <script lang="ts">
  import { Ref, defineComponent, ref} from "vue";
import { createNamespacedHelpers, useStore } from "vuex";
import { key } from "@/store";
import TheOrganizerDashboard from "@/components/main/TheOrganizerDashboard.vue";
import BowlingIcon from '@/components/bowling/BowlingIcon.vue'
import VolleyballIcon from '@/components/volleyball/VolleyballIcon.vue'
import TableTennisIcon from '@/components/tableTennis/TableTennisIcon.vue'
import BadmintonIcon from "@/components/badminton/BadmintonIcon.vue"
import TennisIcon from '@/components/tennis/TennisIcon.vue'
import { useRoute, useRouter } from "vue-router";
import { User } from "@/models/main/User";
import { SPORT_TYPES, Sport } from "@/models/main/CommonTypes";

import { getUserLastSelectedSport } from "@/composables/common/Util";

import '@vuepic/vue-datepicker/dist/main.css'
import { Organization } from "@/models/main/Organization";

const organizationStoreHelpers = createNamespacedHelpers('organizationStore');

export default defineComponent({
    components: {
        TheOrganizerDashboard,
        VolleyballIcon,
        TableTennisIcon,
        TennisIcon,
        BadmintonIcon,
        BowlingIcon,
    },
    computed: {
        ...organizationStoreHelpers.mapGetters(
            ['getOrganization'],
        ),
    },
    setup() {
        const route = useRoute()
        const store = useStore(key);
        const organizationId: Ref<number> = ref(parseInt(route.params.id as string));
        const organization: Ref<Organization | undefined> = ref()
        const initialSportValue = getUserLastSelectedSport() ?? SPORT_TYPES[0]
        

        const onUserLoaded = (user: User) => {
        }

        const loadOrganization = async () => {
            //showLoadingIcon.value = true;
            organization.value = await store.dispatch('organizationStore/getOrganization', organizationId.value)
        };

        loadOrganization();


        return {
            onUserLoaded,
            SPORT_TYPES,
            organization,
            organizationId
            
        }
    }
})
</script>
<style>
.organization-menu-item {
    width: var(--organization-menu-item-width);
    height: var(--organization-menu-item-height);
    background-image: url('/sportzone-green-triangle.svg');
    background-repeat: no-repeat;
    background-size: contain;
    font-size: 2.25em;
    font-weight: bold;
    color: var(--bright-font-clr);
    display: flex;
    justify-content: center;
    align-items: center;
    background-position-x: center;
    transition: ease-in-out all 200ms;
    text-align: center;
    background-position-x: 0.75em;
}

.organization-menu-item:hover {
  background-image: url(/rounded-deep-blue-triangle.svg);
  transform: scale(1.1);
}
.organization-menu {
    --organization-menu-item-width: 18rem;
    --organization-menu-item-height: 18rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    max-width: calc((var(--organization-menu-item-width) * 3) + 2 * 2rem);
    margin-inline: auto;
}
</style>