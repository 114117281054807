<template>
    <ActionBox @click="$emit('click', index)" v-for="(pointType, index) in pointTypes" :key="index">{{ pointType }}
    </ActionBox>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import ActionBox from '@/components/volleyball/ActionBox.vue';
import { SNOOKER_POINT_TITLE_PAIRS } from '@/composables/snooker/SnookerEventTypes';
export default defineComponent({
    components: {
        ActionBox,
    },
    emits: [
        'click',
    ],
    setup() {
        return {
            pointTypes: SNOOKER_POINT_TITLE_PAIRS,
        };
    },
});
</script>