<template>
    <TheOrganizerDashboard>
        <template #heading>
            <router-link :to="{ name: 'Organizations' }">
                <div class="page-heading">
                    Organizations
                </div>
            </router-link>
            <router-link :to="{ name: 'Organization Menu', params: { id: organizationId } }">
                <div class="page-heading">
                    {{ organization?.name }}
                </div>
            </router-link>
            <router-link :to="{ name: 'Organization Containers', params: { organizationId: organizationId } }">
                <div class="page-heading">
                    {{ containerType }}
                </div>
            </router-link>
        </template>
        <template #default>
            <div class="container-actions">
                <div class="filter-group">
                    <SportzoneSelect @selectOptionChange="loadContainers" id="matches-status"
                        :options="['Status', ...CONTAINER_STATUSES]" v-model:selectedOption="containerStatus">
                    </SportzoneSelect>
                    <SportzoneSelect @selectOptionChange="loadContainers" id="matches-type"
                        :options-map="containerTypesMap" v-model:selectedOption="containerType">
                    </SportzoneSelect>
                    <SportzoneSelect @selectOptionChange="onSeasonFilterChange" id="season-filter"
                        v-model:selectedOption="seasonFilter"
                        :options-map="organizationSeasonsMapByName" />
                    
                    <!-- <SportzoneSelect @selectOptionChange="loadContainers" id="matches-sport"
                        :options="['Sport', ...SPORT_TYPES]" v-model:selectedOption="newContainer.sport">
                    </SportzoneSelect> -->
                </div>
                <template v-if="getUser && userCan(getUser, organizerRole)">
                    <button @click="matchPopup.openModal(), onCreateButtonClicked()" class="btn add-btn"
                        title="Create Match">{{ containerType == 'Type' ? 'Match' : containerType }}</button>
                </template>
            </div>
            <div v-infinite-scroll="[loadContainers, { interval: 400, canLoadMore: () => { return !showLoadingIcon && thereIsNextContainersPage } }]" class="matches-container">
                <template v-for="container in listContainers"
                :key="container.id" >
                    <ContainerItem @click="selectedMatchId = container.id" @edit="matchPopup.openModal(), onEditButtonClicked()"
                        @delete="onDeleteButtonClicked()" :container="container" :isSelected="selectedMatchId === container.id"
                        />
                </template>
                <LoadingIcon v-show="showLoadingIcon" />
            </div>
            <StepBasedModal ref="matchPopup" @onApplyClick="onFormSubmit" :start-step="containerProcessStep"
            :step-validators="stepValidators" :action-process-total-steps="containerProcessTotalSteps" :pre-step-action-executors="preStepActionExecutors">
                <template #header>
                    <h1>{{ isCreate ? 'Create Match' : 'Edit Match' }}</h1>
                </template>
                <template #popup-step-1>
                    <div class="popup-step">
                        <div class="spz-text-input-group narrow">
                            <label v-show="false"  for="search-team">Search Team:</label>
                            <input @input="onEntitySearchInput" :value="entitySearchTerm" id="search-team" type="text" placeholder="Search">
                        </div>
                        <div class="spz-select-group narrow">
                            <SportzoneSelect @selectOptionChange="onSeasonFilterChange" id="home-entity-season-filter"
                                v-model:selectedOption="seasonFilter"
                                :options-map="organizationSeasonsMapByName" />
                        </div>
                        <div class="team-type">
                            <div class="side-description">Home {{ $c('entity', newContainer.sport) }}</div>
                        </div>
                        <div class="selected-teams">
                            <div class="selected-home-team">
                                <EntityItem @click="removeSelectedHomeEntity()" v-if="selectedHomeEntityId !== 0"
                                    :entity="getEntity({ entityId: selectedHomeEntityId })" />
                                <div class="already-selected" v-else>Please select "Home {{ $c('entity', newContainer.sport) }}"</div>
                            </div>
                        </div>
                        <div v-infinite-scroll="[loadMoreEntities, { interval: 400, canLoadMore: () => { return thereIsNextEntitiesPage } }]" class="teams-container">
                            <div class="home-entities" :class="{ 'entities-hide': selectedHomeEntityId !== 0 }">
                                <EntityItem @click="onHomeEntityItemClick(entity.id)"
                                    :isSelected="entity.id === selectedHomeEntityId"
                                    v-for="entity in getEntities(entitySearchTerm, organizationId)" :key="entity.id" :entity="entity"> {{
                                        entity.name }}
                                </EntityItem>
                            </div>
                        </div>
                        <LoadingIcon v-show="showLoadingIcon" />
                        </div>
                </template>
                <template #popup-step-2>
                    <div class="popup-step">
                            <div class="spz-text-input-group narrow">
                                <label v-show="false" for="search-team">Search Team:</label>
                                <input @input="onEntitySearchInput" :value="entitySearchTerm" id="search-team" type="text" placeholder="Search">
                            </div>
                            <div class="spz-select-group narrow">
                                <SportzoneSelect @selectOptionChange="onSeasonFilterChange" id="away-entity-season-filter"
                                    v-model:selectedOption="seasonFilter"
                                    :options-map="organizationSeasonsMapByName" />
                            </div>
                            <div class="team-type">
                                <div class="side-description">Away {{ $c('entity', newContainer.sport) }}</div>
                            </div>
                            <div class="selected-teams">
                                <div class="selected-away-team">
                                    <EntityItem @click="removeSelectedAwayEntity()" v-if="selectedAwayEntityId !== 0"
                                        :entity="getEntity({ entityId: selectedAwayEntityId })" />
                                    <div class="already-selected" v-else>Please select "Away {{ $c('entity', newContainer.sport) }}"</div>
                                </div>
                            </div>
                            <div v-infinite-scroll="[loadMoreEntities, { interval: 400, canLoadMore: () => { return !showLoadingIcon && thereIsNextEntitiesPage } }]" class="teams-container">
                                <div class="away-entities" :class="{ 'entities-hide': selectedAwayEntityId !== 0 }">
                                    <EntityItem @click="onAwayEntityItemClick(entity.id)"
                                        :isSelected="entity.id === selectedAwayEntityId"
                                        v-for="entity in getEntities(entitySearchTerm, organizationId)" :key="entity.id" :entity="entity"> {{
                                            entity.name }}
                                    </EntityItem>
                                </div>
                            </div>
                            <LoadingIcon v-show="showLoadingIcon" />
                        </div>
                </template>
                <template #popup-step-3>
                    <div class="popup-step">
                            <div class="match-details">
                                <div class="spz-text-input-group">
                                    <label for="match_title">Title</label>
                                    <input id="match_title" v-model="newContainer.title" type="text" placeholder="Title" required />
                                    <div v-show="!validTitle" class="spz-form-err-msg">Please provide a title.</div>
                                </div>
                                <div class="spz-text-input-group narrow">
                                    <label for="dp-match_start">Starts</label>
                                    <VueDatePicker uid="match_start" v-model="newContainer.startTime" time-picker-inline />
                                </div>
                                <div class="spz-text-input-group narrow">
                                    <label for="dp-match_end">Ends</label>
                                    <VueDatePicker uid="match_end" v-model="newContainer.endTime" time-picker-inline />
                                </div>
                                <div class="popup__input-group spz-checkbox-group narrow">
                                    <label class="spz-toggle">
                                        <span class="spz-toggle-label">Stream</span>
                                        <input class="spz-toggle-checkbox" type="checkbox" v-model="streamContainer">
                                        <div class="spz-toggle-switch"></div>
                                    </label>
                                </div>
                                <div class="spz-select-group narrow">
                                    <label for="container-season">Season</label>
                                    <SportzoneSelect @selectOptionChange="onSeasonFilterChange" id="container-season"
                                        v-model:selectedOption="seasonFilter"
                                        :options="[...organizationSeasonNames]" />
                                </div>
                        <div class="popup__input-group spz-select-group narrow" v-show="streamContainer">
                            <label for="new-stream-channel">Channel</label>
                            <select id="new-stream-channel" v-model="newLivestream.channelId">
                                <option disabled value="">Please select one</option>
                                <option v-for="channel in getUserChannels" :key="channel.id" :value="channel.id">
                                    {{ channel.name }}
                                </option>
                            </select>
                        </div>
                        <div class="popup__input-group spz-select-group narrow" v-show="streamContainer">
                            <label for="new-stream-privacy">Privacy</label>
                            <select id="new-stream-privacy" v-model="newLivestream.privacy">
                                <option v-for="privacy in LIVESTREAM_PRIVACIES" :key="privacy" :value="privacy">{{ privacy }}
                                </option>
                            </select>
                        </div>
                        <div class="popup__input-group spz-checkbox-group" v-show="streamContainer">
                            <input id="useYoutubeStream" v-model="useYoutubeStream" type="checkbox" />
                            <label for="useYoutubeStream">Use YouTube Stream</label>
                        </div>
                        <div class="livestream-form" v-show="streamContainer">
                            <div v-show="!validLivestream" class="spz-form-err-msg">Please choose a stream.</div>
                            <div class="live-broadcasts" v-if="useYoutubeStream">
                                <YoutubeLiveBroadcastItem @click="onYoutubeBroadcastClick(livebroadcast)"
                                    v-for="livebroadcast in youtubeLiveBroadcasts" :key="livebroadcast.id"
                                    :yt-live-broadcast="livebroadcast"
                                    :is-selected="selectedYTLiveBroadcast?.id === livebroadcast.id" />
                            </div>
                            <div class="popup__input-group spz-text-input-group" v-show="!newLivestream.isAutomatic && !useYoutubeStream">
                                <label for="new-stream-platform-id">Livestream ID</label>
                                <input id="new-stream-platform-id" v-model="newLivestream.atStreamingPlatformId" />
                            </div>
                        </div>
                            </div>
                        </div>
                </template>
                <template #popup-step-4>
                    <div class="popup-step">
                        <div class="container-preview" v-if="selectedHomeEntityId !== 0 && selectedAwayEntityId !== 0">
                            <div class="container-preview__title">{{ newContainer.title }}</div>
                            <div class="container-preview__start-time">{{ formatStringDate(newContainer.startTime) }}</div>
                            <div class="container-preview__entities">
                                <div class="container-preview__entity home">
                                    <div class="container-preview__entity-icon">
                                        <img :src="'/api/entity/' + selectedHomeEntityId + '/logo'" alt="Home Team Logo"/>
                                    </div>
                                    <div class="container-preview__entity-name">{{ getEntity({ entityId: selectedHomeEntityId }).name }}</div>
                                </div> 
                                <div class="container-preview__entity away">
                                    <div class="container-preview__entity-icon">
                                        <img :src="'/api/entity/' + selectedAwayEntityId + '/logo'" alt="Away Team Logo" />
                                    </div>
                                    <div class="container-preview__entity-name">{{ getEntity({ entityId: selectedAwayEntityId }).name }}</div>
                                </div> 
                            </div>
                        </div>
                        <div class="livestream-preview" v-if="streamContainer && selectedYTLiveBroadcast">
                            <div class="livestream-preview__title">
                                Stream
                            </div>
                            <div class="livestream-preview_stream">
                                <template v-for="livebroadcast in youtubeLiveBroadcasts" :key="livebroadcast.id">
                                    <YoutubeLiveBroadcastItem v-if="selectedYTLiveBroadcast?.id === livebroadcast.id" :yt-live-broadcast="livebroadcast" />
                                </template>
                            </div>
                        </div>  
                    </div>
                </template>
                <template #action-btn >
                    <button class="btn add-btn">
                        {{ isCreate ? 'Create' : 'Edit'}}
                    </button>
                </template>
            </StepBasedModal>
            <SportzoneDialog ref="confirmContainerDialog" :show="showConfirmContainerDialog" class="mini"
                @close="showConfirmContainerDialog = false">
                <template #header>
                    Delete Match
                </template>
                <template #body>
                    Match: <strong>{{ containerDialog }}</strong> will be deleted. Do you confirm this action?
                </template>
                <template #footer>
                    <button class="btn delete-btn"
                        @click="$refs.confirmContainerDialog.closeModal(), confirmContainerDialogCallback()">
                        Confirm
                    </button>
                    <button class="btn btn-inverse" @click="$refs.confirmContainerDialog.closeModal()">Cancel</button>
                </template>
            </SportzoneDialog>
        </template>
    </TheOrganizerDashboard>
</template>
<script setup lang="ts">
import TheOrganizerDashboard from '@/components/main/TheOrganizerDashboard.vue';
import SportzoneSelect from '@/components/util/SportzoneSelect.vue';
import { CONTAINER_STATUSES, ContainerStatus, CONTAINER_TYPES, ContainerType, Container } from '@/models/main/Container';
import { SPORT_TYPES, STREAM_QUALITIES } from '@/models/main/CommonTypes';
import ContainerItem from '@/components/main/ContainerItem.vue';
import LoadingIcon from '@/components/util/LoadingIcon.vue';
import StepBasedModal from '@/components/main/StepBasedModal.vue';
import { ref, Ref, computed, onMounted, watch } from 'vue';
import EntityItem from '@/components/main/EntityItem.vue';
import { useStore } from 'vuex';
import { key } from '@/store';
import { SportzoneRole } from '@/models/main/User';
import moment from 'moment';
import { useRoute, useRouter } from 'vue-router';
import { Entity } from '@/models/main/Entity';
import { formatStringDate, getDateRoundedToNearestAheadQuarter, getUserLastSelectedSport, 
    getUserLastVisitedOrganizationId, setUserLastSelectedSport, userCan, getUserLastUsedOpponentNameSplitter, setUserLastUsedOpponentNameSplitter } from '@/composables/common/Util';
import SportzoneDialog from '@/components/util/SportzoneDialog.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { Organization } from '@/models/main/Organization';
import { LIVESTREAM_BROADCAST_TOOLS, LIVESTREAM_PRIVACIES, LIVESTREAM_STATUSES, SaveLivestream } from '@/models/main/Livestream';
import YTLiveBroadcast from '@/models/main/YoutubeLiveBroadcast';
import Channel from '@/models/main/Channel';
import YoutubeLiveBroadcastItem from '@/components/main/YoutubeLiveBroadcastItem.vue';
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import useSeasonComposables from '@/composables/generic/UseSeasonComposables';
import useEntityComposables from '@/composables/generic/UseEntityComposables';
import useContainerComposables from '@/composables/generic/UseContainerComposables';
import { vInfiniteScroll } from '@vueuse/components'
import Season from "@/models/main/Season"

const route = useRoute();
const $toast = useToast();
const routeOrgId: string | undefined = route.params.id ? route.params.id as string : undefined;
const lastVisitedOrgId = getUserLastVisitedOrganizationId() ?? 0
const organizationId: Ref<number | undefined> = ref(routeOrgId ? parseInt(routeOrgId) : lastVisitedOrgId);
const organization: Ref<Organization | undefined> = ref();
const showConfirmContainerDialog = ref(false);
const containerDialog: Ref<Container | undefined> = ref();
const confirmContainerDialogCallback: Ref<Function> = ref(() => { });
let isCreate: Ref<boolean> = ref(true);
const showLoadingIcon: Ref<boolean> = ref(true);
const store = useStore(key);

const {         
    seasonFilter,
    seasonFilterId,
    organizationSeasonNames,
    organizationSeasons,
    loadSeasons,
} = useSeasonComposables(organizationId);

onMounted(loadSeasons)

const {
    containerStatus,
    containerType,
    loadContainers,
    listContainers,
    thereIsNextContainersPage
} = useContainerComposables(organization, seasonFilterId, showLoadingIcon);

watch(route, 
    (to) => {
        let containerTypeFromQuery: ContainerType | undefined = to.query?.type as ContainerType;
        if(containerTypeFromQuery === undefined) {
            containerTypeFromQuery = 'Type'
        }
        containerType.value = containerTypeFromQuery
    }, 
    { flush: 'pre', immediate: true, deep: true }
)
const dateTimeFormat = 'YYYY-MM-DD HH:mm';
const initialSportValue = getUserLastSelectedSport() ?? SPORT_TYPES[0]
const currentTimeRounded = getDateRoundedToNearestAheadQuarter(new Date())
const formattedRoundedTime = moment(currentTimeRounded).format(dateTimeFormat)
const myMatch: Container = {
    type: containerType.value,
    status: 'NotStarted',
    startTime: formattedRoundedTime,
    endTime: formattedRoundedTime,
    sport: initialSportValue,
    events: {},
    livestreams: {},
    entities: {},
    organizationId: organizationId.value ?? 0,
    seasons: []
    //parentId: 4543 // TODO: fixed value
};
const newContainer: Ref<Container> = ref(myMatch);
const selectedHomeEntityId: Ref<number> = ref(0);
const selectedAwayEntityId: Ref<number> = ref(0);
const organizerRole: SportzoneRole = 'Organizer';
const matchPopup: Ref<any> = ref(null);
const selectedMatchId: Ref<number> = ref(0);
const containerProcessStep: Ref<number> = ref(1);
const containerProcessTotalSteps = ref(4);
const router = useRouter();
const defaultOpponentSplitter = '-';
let userLastUsedSplitter = getUserLastUsedOpponentNameSplitter()
const containerOpponentNameSplitter: Ref<string> = ref(userLastUsedSplitter ? userLastUsedSplitter : defaultOpponentSplitter);
/* Container proccess steps */
const validTitle: Ref<boolean> = ref(true);
const validStartTime: Ref<boolean> = ref(true)
const validEndTime: Ref<boolean> = ref(true)
const validLivestream: Ref<boolean> = ref(true)
const mainDetailsValidator = () => {
    validTitle.value = newContainer.value.title !== undefined && newContainer.value.title?.length > 0
    validStartTime.value = newContainer.value.startTime !== undefined
    validEndTime.value = true // TODO: add valid checks to date times
    if(streamContainer.value) {
        if(useYoutubeStream.value) {
            validLivestream.value = selectedYTLiveBroadcast.value !== undefined
        } else if(newLivestream.value.atStreamingPlatformId){
            validLivestream.value = true
        }
    } else {
        validLivestream.value = true
    }

    const isValid = validTitle.value && validStartTime.value && validEndTime.value && validLivestream.value
    if(isValid) {
        const regex = /:|-|vs/g;
        const splitterFound = newContainer.value.title?.match(regex);
        if(splitterFound && splitterFound.length > 0) {
            userLastUsedSplitter = splitterFound[0]
            containerOpponentNameSplitter.value = userLastUsedSplitter
            setUserLastUsedOpponentNameSplitter(userLastUsedSplitter)
        }
    }
    return isValid
}
const validHomeEntity: Ref<boolean> = ref(true)
const homeEntityValidator = () => {
    if(newContainer.value.type === 'Tournament') {
        return true
    }
    validHomeEntity.value = selectedHomeEntityId.value !== 0
    return validHomeEntity.value
}

const validAwayEntity: Ref<boolean> = ref(true)
const awayEntityValidator = () => {
    if(newContainer.value.type === 'Tournament') {
        return true
    }
    validAwayEntity.value = selectedAwayEntityId.value !== 0;
    return validAwayEntity.value
}


const prepMainDetailsStep = () => {
    const homeEntity: Entity = store.getters['entityStore/getEntity']({ entityId: selectedHomeEntityId.value })
    const awayEntity: Entity = store.getters['entityStore/getEntity']({ entityId: selectedAwayEntityId.value })
    if(homeEntity && awayEntity && homeEntity.name && awayEntity.name) {
        newContainer.value.title = homeEntity.name + ' ' + containerOpponentNameSplitter.value + ' ' + awayEntity?.name
    }

    prepareLivestreamModal();
}


const loadOrganization = async () => {
    if(organizationId.value === null) {
        const organizations = await store.dispatch('organizationStore/fetchOrganizations', { sport: myMatch.sport })
        if(organizations && organizations.items.length > 0 && organizations.items[0])
        {
            organization.value = organizations.items[0]
            organizationId.value = organizations.items[0].id
        }
    } else {
        organization.value = await store.dispatch('organizationStore/getOrganization', organizationId.value)
    }
    if(organization.value) {
        myMatch.sport = organization.value.sport
        setUserLastSelectedSport(myMatch.sport);
    }
}

// onMounted(loadContainers)
onMounted(loadOrganization)

const removeSelectedHomeEntity = () => {
    selectedHomeEntityId.value = 0;
};
const removeSelectedAwayEntity = () => {
    selectedAwayEntityId.value = 0;
};

const onHomeEntityItemClick = (entityId: number) => {
    selectedHomeEntityId.value = entityId;
};
const onAwayEntityItemClick = (entityId: number) => {
    selectedAwayEntityId.value = entityId;
};
const onFormSubmit = async () => {
    newContainer.value.startTime = moment(newContainer.value.startTime).utc().format(dateTimeFormat)
    newLivestream.value.startTime = newContainer.value.startTime
    if(newContainer.value.endTime) {
        newContainer.value.endTime = moment(newContainer.value.endTime).utc().format(dateTimeFormat)
        newLivestream.value.endTime = newContainer.value.endTime
    }
    const selectedSeason = organizationSeasons.value.get(seasonFilter.value);
    newContainer.value.seasons = selectedSeason ? [selectedSeason] : []
    if (isCreate.value) {
        newContainer.value.type = containerType.value
        const createdMatch = await store.dispatch('containerStore/createContainer', {
            container: newContainer.value,
            homeEntityId: selectedHomeEntityId.value,
            awayEntityId: selectedAwayEntityId.value,
        });
        if(streamContainer.value) {
            newLivestream.value.containerId = createdMatch.id
            await store.dispatch('livestreamStore/saveLivestream', {
                    livestream: newLivestream.value,
                    scheduleStart: scheduleStart.value,
            });
        }
        $toast.success('Created');
    }
    else {
        const editedMatch = await store.dispatch('containerStore/editContainer', {
            container: newContainer.value,
            homeEntityId: selectedHomeEntityId.value,
            awayEntityId: selectedAwayEntityId.value,
        });

        if(streamContainer.value) {
            if(newContainer.value.livestreams && Object.prototype.hasOwnProperty.call(newContainer.value.livestreams, 0)) { //edit
                newLivestream.value.id = newContainer.value.livestreams[0].id
                await store.dispatch('livestreamStore/editLivestream', {
                    livestream: newLivestream.value,
                    livestreamId: newLivestream.value.id,
                });
            } else if(newContainer.value.id) { // add new
                newLivestream.value.containerId = newContainer.value.id
                newLivestream.value.title = newContainer.value.title ?? null
                await store.dispatch('livestreamStore/saveLivestream', {
                        livestream: newLivestream.value,
                        scheduleStart: scheduleStart.value,
                });
            }
        }
        $toast.success('Edited'); 
    }
};
const onCreateButtonClicked = () => {
    containerProcessStep.value = 1;
    isCreate.value = true;
    newContainer.value.title = '';
    removeSelectedHomeEntity();
    removeSelectedAwayEntity();
};
const onEditButtonClicked = async () => {
    containerProcessStep.value = 1;
    isCreate.value = false;
    const container: Container = store.getters['containerStore/getContainer'](selectedMatchId.value);
    let homeEntity: Entity | undefined;
    let awayEntity: Entity | undefined;
    for (let i in container.entities) {
        if (container.entities[i]) {
            if (container.entities[i].type === 'Home') {
                homeEntity = container.entities[i].entity;
            }
            else if (container.entities[i].type === 'Away') {
                awayEntity = container.entities[i].entity;
            }
            else {
                console.error('Inavlid data!');
            }
        }
    }

    if (container) {
        newContainer.value.id = container.id;
        newContainer.value.title = container.title;
        newContainer.value.startTime = formatStringDate(container.startTime, dateTimeFormat);
        newContainer.value.endTime = formatStringDate(container.endTime, dateTimeFormat)
        if(container.seasons && container.seasons.length > 0) {
            seasonFilter.value = container.seasons[0].name
        }
        
        newContainer.value.seasons = container.seasons
    }
    if (homeEntity && homeEntity.id) {
        selectedHomeEntityId.value = homeEntity?.id;
    }
    if (awayEntity && awayEntity.id) {
        selectedAwayEntityId.value = awayEntity?.id;
    }
    if(container.livestreams && Object.prototype.hasOwnProperty.call(container.livestreams, 0)) {
        newLivestream.value = container.livestreams[0] as unknown as SaveLivestream
    }
};
const onDeleteButtonClicked = async () => {
    showConfirmContainerDialog.value = true;
    const container = store.getters['containerStore/getContainer'](selectedMatchId.value);
    containerDialog.value = container.title;
    confirmContainerDialogCallback.value = async () => {
        await store.dispatch('containerStore/deleteContainer', selectedMatchId.value);
        selectedMatchId.value = 0;
        $toast.success('Deleted');
    };
};

/* LIVESTREAM */
const livestream: SaveLivestream = {
    title: null,
    startTime: moment().format(dateTimeFormat),
    endTime: moment().format(dateTimeFormat),
    quality: STREAM_QUALITIES[0],
    status: LIVESTREAM_STATUSES[0],
    atStreamingPlatformId: null,
    containerId: 0,
    cameraId: null,
    container: null,
    channelId: null,
    digestEndpoint: null,
    isAutomatic: false,
    sport: initialSportValue,
    privacy: LIVESTREAM_PRIVACIES[0],
    broadcastTool: LIVESTREAM_BROADCAST_TOOLS[1],
};
const newLivestream: Ref<SaveLivestream> = ref(livestream);
const youtubeLiveBroadcasts: Ref<YTLiveBroadcast[]> = ref([]);
const selectedYTLiveBroadcast: Ref<YTLiveBroadcast | undefined> = ref();
const scheduleStart: Ref<boolean> = ref(false);
const streamContainer: Ref<boolean> = ref(true)
const useYoutubeStream: Ref<boolean> = ref(true)
const onYoutubeBroadcastClick = (livebroadcast: YTLiveBroadcast) => {
    selectedYTLiveBroadcast.value = livebroadcast;
    // newLivestream.value.title = newContainer.value.title ?? null
    // newLivestream.value.startTime = newContainer.value.startTime ?? null
    // newLivestream.value.endTime = newContainer.value.endTime ?? null
    newLivestream.value.atStreamingPlatformId = livebroadcast.id;
};

const prepareLivestreamModal = () => {
    const userChannels: Channel[] = store.getters['userStore/getUserChannels'];
    if (userChannels.length >= 1) {
        newLivestream.value.channelId = userChannels[0].id;
        store
            .dispatch('channelStore/getYoutubeBroadcasts', userChannels[0].id)
            .then((broadcasts) => {
                youtubeLiveBroadcasts.value = broadcasts;
            });
    }
    if(isCreate.value) {
        newLivestream.value.atStreamingPlatformId = null;
        newLivestream.value.title = newContainer.value.title ?? null
        newLivestream.value.startTime = newContainer.value.startTime ?? null
        newLivestream.value.endTime = newContainer.value.endTime ?? null
        newLivestream.value.sport = newContainer.value.sport ?? initialSportValue
        selectedYTLiveBroadcast.value = undefined
    }
};

const fetchUserChannels = () => {
    showLoadingIcon.value = true;
    store.dispatch('userStore/getUserChannels', store.getters['userStore/getId']);
    showLoadingIcon.value = false;
};

onMounted(fetchUserChannels);
/* LIVESTREAM */

const stepValidators: Array<Function> = [homeEntityValidator, awayEntityValidator, mainDetailsValidator, ()=>{ return true}, () => { return true }]
const preStepActionExecutors: Array<Function> = [() => {}, () => {}, prepMainDetailsStep, () => {}]

const toggleStreamContainer = () => {
    streamContainer.value = !streamContainer.value
    console.log(streamContainer.value)
}

const getUser = computed(() => {
    return store.getters['userStore/getUser'];
})
const getUserChannels = computed(() => {
    return store.getters['userStore/getUserChannels'];
})
const getEntity = computed(() => {
    return store.getters['entityStore/getEntity'];
})


const getContainer = computed(() => {
    return store.getters['containerStore/getContainer'];
})
const getPlayers = computed(() => {
    return store.getters['playerStore/getPlayers'];
})

/* Season Filters */
const {         
    entitySearchTerm,
    thereIsNextEntitiesPage,
    getEntities,
    getEntityById,
    loadEntities,
    onEntitySearchInput 
} = useEntityComposables(organizationId, seasonFilterId, showLoadingIcon)

const loadMoreEntities = () => {
    loadEntities(organizationId.value ?? undefined, seasonFilterId.value)
}

const organizationSeasonsMapByName = computed(() => {
    const seasonNamesMap = new Map<string, string>()
    seasonNamesMap.set('', 'All Time Season')
    organizationSeasons.value.forEach((season: Season | null, key: string) => {
        if(season) {
            seasonNamesMap.set(season.id.toString(), season.name)
        }
    })

    return seasonNamesMap
})

const onSeasonFilterChange = async() => {
    const modalIsOpen: boolean = matchPopup.value.basicModal.show
    if(modalIsOpen && organizationSeasons.value) {
        loadMoreEntities()
    } else if(!modalIsOpen) {
        loadContainers()
    }
}

const containerTypesMap = computed(() => {
    const typesMap = new Map<string, string>()
    typesMap.set('Type', 'Type')
    CONTAINER_TYPES.forEach((cType: ContainerType) => {
        let displayName: ContainerType = cType
        switch(cType) {
            case "VolleyballMatch":
            case "TennisMatch":
            case "TableTennisMatch":
            displayName = "Match"
                break;
            case "VolleyballPhase":
            case "TennisPhase":
            case "TableTennisPhase":
            displayName = "Phase"
                break;
        }

        typesMap.set(displayName, displayName)
    });

    return typesMap
})
</script>
<style>
</style>