import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "organization-wrapper" }
const _hoisted_2 = { class: "organization-badge" }
const _hoisted_3 = { class: "organization-image-wrapper" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "organization-info-wrapper" }
const _hoisted_6 = { class: "organization-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: { name: 'Organization Public', params: { id: _ctx.organization.id }}
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              src: '/api/organization/' + _ctx.organization.id + '/logo',
              alt: _ctx.organization.name
            }, null, 8, _hoisted_4)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.organization.name), 1)
          ])
        ])
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}