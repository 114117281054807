<template>
    <TheUserDashboard :onUserLoad="onUserLoaded">
        <template #heading>
            <router-link :to="{ name: 'Organizations Search' }">
                <div class="page-heading">
                    Organizations
                </div>
            </router-link>
            <router-link :to="{ name: 'Organization Public', params: { id: organization?.id } }">
                <div class="page-heading">
                    {{ organization?.name }}
                </div>
            </router-link>
        </template>
        <template #default>
            <div class="organization-properties-wrapper" v-if="organization">
                <div class="organization-properties-left">
                    <div class="org-logo-edit-wrapper">
                        <label for="org-logo">
                            <div class="org-default-logo">       
                                <img v-show="!showDefaultUploadLogoImg" :src="imageUrl" :alt="organization.name" @error="showDefaultUploadLogoImg = true"/>
                                <img src="/grey-camera.svg" alt="Logo" v-show="showDefaultUploadLogoImg"/>                         
                            </div>
                        </label>
                    </div>
                </div>
                <div class="organization-properties-right">
                    <div class="spz-text-input-group">
                        <label for="orgName">Name</label>
                        <input v-model="organization.name" type="text" id="orgName" placeholder="Organization Name" required />
                    </div>
                    <div class="spz-text-input-group">
                        <label for="dp-orgEstablishedOn">Established on</label>
                        <VueDatePicker uid="orgEstablishedOn" v-model="organization.establishedOn" time-picker-inline utc="true"/>
                    </div>
                </div>
                <div class="buttons-wrapper">
                    <button class="btn apply-btn" @click="onApplyClick">{{ applyButtonText }}</button>
                    <button class="btn apply-btn" @click="onFollowClick">{{ followButtonText }}</button>
                </div>
            </div>
        </template>
    </TheUserDashboard>
</template>
<script lang="ts">
import { createNamespacedHelpers, useStore } from 'vuex';
import { computed, defineComponent, onMounted, Ref, ref, watch } from 'vue';
import TheUserDashboard from '@/components/main/TheUserDashboard.vue';
import { ORGANIZATION_PRIVACIES, Organization } from '@/models/main/Organization';
import { key } from '@/store';
import { useRoute, useRouter } from 'vue-router';
import { User } from '@/models/main/User';
import { formatStringDate } from '@/composables/common/Util';
import SportzoneSelect from '@/components/util/SportzoneSelect.vue';
import SportzoneDialog from '@/components/util/SportzoneDialog.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { APPLICATION_STATUSES, OrganizationApplication } from '@/models/main/OrganizationApplication';
import { ORGANIZATION_FOLLOW_TYPES, OrganizationFollow } from '@/models/main/OrganizationFollow';
import useUserOrganizationComputeds from '@/composables/user/UseUserOrganizationComputeds';
const languages = require('language-list')();
export default defineComponent({
    components: {
        TheUserDashboard,
        VueDatePicker,
        SportzoneSelect,
        SportzoneDialog,
    },
    computed: {
    },
    setup() {
        const organization: Ref<Organization | undefined> = ref();
        const store = useStore(key);
        const route = useRoute();
        const router = useRouter();
        const organizationId: Ref<number> = ref(parseInt(route.params.id as string));
        const currentUser: Ref<User | undefined> = ref()
        const onUserLoaded = (user: User) => {
            currentUser.value = user
        };
        const {
            loadUserOrganizationsAsFollower,
            getFollowerOrganizationsGroupedBySport,
        }  = useUserOrganizationComputeds()
        const loadOrganization = async () => {
            organization.value = await store.dispatch('organizationStore/getOrganization', organizationId.value);
            if(organization.value) {
                imageUrl.value = '/api/organization/' + organization.value.id + '/logo'
            }
        };

        const applyButtonText: Ref<string> = ref('Apply')
        const onApplyClick = async () => {
            const application: OrganizationApplication = {
                message: 'User Application',
                dateApplied: new Date(),
                organizationId: organization.value?.id ?? 0,
                userId: currentUser.value?.id ?? 0,
                rejectMessage: '',
                status: APPLICATION_STATUSES[0],
                id: 0,
            }
            applyButtonText.value = 'Applying'
            await store.dispatch('organizationApplicationStore/createOrganizationApplication', application)
            applyButtonText.value = 'Apply'
        }

        const followText = 'Follow'
        const alreadyFollowingText = 'Unfollow'
        const followButtonText: Ref<string> = ref(followText)
        const followedOrganization = computed((): Organization | undefined => {
            if(organization.value?.id) {
                return store.getters['followerOrganizationStore/getOrganization'](organization.value.id)
            }
        })
        watch(followedOrganization, (organization) => {
            if(organization) {
                followButtonText.value = alreadyFollowingText
            }
        })
        
        const onFollowClick = async () => {
            if(currentUser.value?.id && organization.value?.id) {
                const follow: OrganizationFollow = {
                    userId: currentUser.value.id,
                    organizationId: organization.value.id,
                    type: ORGANIZATION_FOLLOW_TYPES[0]
                }
                const createdFollow = await store.dispatch('userStore/followOrganization', follow)
                if(createdFollow) {
                    followButtonText.value = alreadyFollowingText
                }
            }
        }

        const languagesComputed = computed(() => {
            const list = languages.getData()
            const result = Object
                .keys(list)
                .map((key) => (list[key]['language']));
            return result;
        });

        const showDefaultUploadLogoImg = ref(false);
        const imageUrl: Ref<any> = ref('')
        onMounted(loadOrganization)
        onMounted(loadUserOrganizationsAsFollower)
        return {
            showDefaultUploadLogoImg,
            organization,
            organizationId,
            languagesComputed,
            ORGANIZATION_PRIVACIES,
            applyButtonText,
            imageUrl,
            followButtonText,
            onUserLoaded,
            formatStringDate,
            onApplyClick,
            onFollowClick
        };
    }
});
</script>
<style>
</style>