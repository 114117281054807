import { ContainerDataModel } from "@/composables/dataModels/ContainerDataModel"
import GenericDictionary from "@/components/util/GenericDictionary"
import DataModel from "@/models/main/DataModel"
import LiveDataModel from "./LiveDataModel"
import EventDataModel from "./EventDataModel"
import PlayerDataModel from "./PlayerDataModel"
import UserDataModel from "./UserDataModel"
import EntityDataModel from "./EntityDataModel"
import EntityContainerDataModel from "./EntityContainerDataModel"
import PhaseRankingModel from "./bowling/PhaseRankingDataModel"
import PhaseEntityDataModel from "./bowling/PhaseEntityDataModel"
import PhaseDetailsDataModel from "./bowling/PhaseDetailsDataModel"
import PhaseSerieDataModel from "./bowling/PhaseSerieDataModel"
import PhasePlayerDataModel from "./bowling/PhasePlayerDataModel"
import PhaseGameDataModel from "./bowling/PhaseGameDataModel"
import FrameDataModel from "./bowling/FrameDataModel"
import LivestreamDataModel from "./bowling/LivestreamDataModel"
import BowlingCameraDataModel from "./bowling/BowlingCameraDataModel"
import OrganizationDataModel from "./OrganizationDataModel"

export const DataModelStore: GenericDictionary<new(modelID: string, modelData: any) => DataModel> = {}

DataModelStore[ContainerDataModel.MODEL_NAME] = ContainerDataModel
DataModelStore[LiveDataModel.MODEL_NAME] = LiveDataModel
DataModelStore[EventDataModel.MODEL_NAME] = EventDataModel
DataModelStore[PlayerDataModel.MODEL_NAME] = PlayerDataModel
DataModelStore[UserDataModel.MODEL_NAME] = UserDataModel
DataModelStore[EntityDataModel.MODEL_NAME] = EntityDataModel
DataModelStore[EntityContainerDataModel.MODEL_NAME] = EntityContainerDataModel
DataModelStore[OrganizationDataModel.MODEL_NAME] = OrganizationDataModel


DataModelStore[PhaseRankingModel.MODEL_NAME] = PhaseRankingModel
DataModelStore[PhaseDetailsDataModel.MODEL_NAME] = PhaseDetailsDataModel
DataModelStore[PhaseEntityDataModel.MODEL_NAME] = PhaseEntityDataModel
DataModelStore[PhaseSerieDataModel.MODEL_NAME] = PhaseSerieDataModel
DataModelStore[PhasePlayerDataModel.MODEL_NAME] = PhasePlayerDataModel
DataModelStore[PhaseGameDataModel.MODEL_NAME] = PhaseGameDataModel
DataModelStore[FrameDataModel.MODEL_NAME] = FrameDataModel
DataModelStore[LivestreamDataModel.MODEL_NAME] = LivestreamDataModel
DataModelStore[BowlingCameraDataModel.MODEL_NAME] = BowlingCameraDataModel




