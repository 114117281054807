import GenericDictionary from "@/components/util/GenericDictionary"
export const TENNIS_ACE = 'Tenn_A'
export const TENNIS_POINT = 'Tenn_P'
export const TENNIS_FOREHAND_WINNER = 'Tenn_FW'
export const TENNIS_BACKHAND_WINNER = 'Tenn_BW'
export const TENNIS_FORCED_ERROR = 'Tenn_FE'
export const TENNIS_FOREHAND_UNFORCED = 'Tenn_FU'
export const TENNIS_BACKHAND_UNFORCED = 'Tenn_BU'
export const TENNIS_DOUBLE_FAULT = 'Tenn_DF'


export type TennisPointType =         
typeof TENNIS_ACE | typeof TENNIS_POINT | typeof TENNIS_FOREHAND_WINNER | typeof TENNIS_BACKHAND_WINNER | 
typeof TENNIS_FORCED_ERROR | typeof TENNIS_FOREHAND_UNFORCED |
typeof TENNIS_BACKHAND_UNFORCED | typeof TENNIS_DOUBLE_FAULT

export const TENNIS_POINT_TITLE_PAIRS: GenericDictionary<string> =  {}
TENNIS_POINT_TITLE_PAIRS[TENNIS_ACE] = 'ACE'
TENNIS_POINT_TITLE_PAIRS[TENNIS_POINT] = '?'
TENNIS_POINT_TITLE_PAIRS[TENNIS_FOREHAND_WINNER] = 'FORH'
TENNIS_POINT_TITLE_PAIRS[TENNIS_BACKHAND_WINNER] = 'BACKH'
TENNIS_POINT_TITLE_PAIRS[TENNIS_DOUBLE_FAULT] = 'DBL'
TENNIS_POINT_TITLE_PAIRS[TENNIS_FORCED_ERROR] = 'FORCE'
TENNIS_POINT_TITLE_PAIRS[TENNIS_FOREHAND_UNFORCED] = 'FORH'
TENNIS_POINT_TITLE_PAIRS[TENNIS_BACKHAND_UNFORCED] = 'BACKH'

export const TENNIS_POINT_BEAUTIFUL_TITLE_PAIRS: GenericDictionary<string> =  {}
TENNIS_POINT_BEAUTIFUL_TITLE_PAIRS[TENNIS_ACE] = 'Ace'
TENNIS_POINT_BEAUTIFUL_TITLE_PAIRS[TENNIS_POINT] = 'Point'
TENNIS_POINT_BEAUTIFUL_TITLE_PAIRS[TENNIS_FOREHAND_WINNER] = 'Forhand Winner'
TENNIS_POINT_BEAUTIFUL_TITLE_PAIRS[TENNIS_BACKHAND_WINNER] = 'Backhand Winner'
TENNIS_POINT_BEAUTIFUL_TITLE_PAIRS[TENNIS_DOUBLE_FAULT] = 'Double Fault'
TENNIS_POINT_BEAUTIFUL_TITLE_PAIRS[TENNIS_FORCED_ERROR] = 'Forced Error'
TENNIS_POINT_BEAUTIFUL_TITLE_PAIRS[TENNIS_FOREHAND_UNFORCED] = 'Forehand UE'
TENNIS_POINT_BEAUTIFUL_TITLE_PAIRS[TENNIS_BACKHAND_UNFORCED] = 'Backhand UE'


export const TENNIS_IS_POSITIVE_POINT: GenericDictionary<boolean> = {}
TENNIS_IS_POSITIVE_POINT[TENNIS_ACE] = true
TENNIS_IS_POSITIVE_POINT[TENNIS_POINT] = true
TENNIS_IS_POSITIVE_POINT[TENNIS_FOREHAND_WINNER] = true
TENNIS_IS_POSITIVE_POINT[TENNIS_BACKHAND_WINNER] = true
TENNIS_IS_POSITIVE_POINT[TENNIS_FORCED_ERROR] = false
TENNIS_IS_POSITIVE_POINT[TENNIS_FOREHAND_UNFORCED] = false
TENNIS_IS_POSITIVE_POINT[TENNIS_BACKHAND_UNFORCED] = false
TENNIS_IS_POSITIVE_POINT[TENNIS_DOUBLE_FAULT] = false

const gameEnd = 'Tenn_G'
const setEnd = 'Tenn_S'
const matchEnd = 'Tenn_M'
const serveChange = 'Tenn_Srv'
const setGameAsTiebreak = 'Tenn_TG'
const override = 'Tenn_O'

export const TENNIS_GAME_CONTROL_TYPES: GenericDictionary<string> = {}

TENNIS_GAME_CONTROL_TYPES[setEnd] = 'End of Set'
TENNIS_GAME_CONTROL_TYPES[gameEnd] = 'End of Game'
TENNIS_GAME_CONTROL_TYPES[matchEnd] = 'End of Match'
TENNIS_GAME_CONTROL_TYPES[serveChange] = 'Change Serve'
TENNIS_GAME_CONTROL_TYPES[setGameAsTiebreak] = 'Tiebreak Game'
TENNIS_GAME_CONTROL_TYPES[override] = 'Override'



export type TennisGameControlType = typeof gameEnd | typeof setEnd | typeof matchEnd | typeof serveChange | typeof setGameAsTiebreak
        | typeof override
export type TennisScore = number | "15" | "30" | "40" | "Ad" | "Ad-In" | "Ad-Out" | "G"

export type TennisOverrideData = {
        overrideSet?: number | undefined,
        overrideGame?: number | undefined,
        overridePoint?: TennisScore | undefined,
};