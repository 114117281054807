<template>
<ThePublicHomeLayout>
    <div class="login-wrapper">
        <div class="greet-section">
            <h1 class="greet-header">Welcome</h1>
            <div class="greet-text">So happy to see you with us! Lets start our journey together into elevating your game to the next level!</div>
        </div>
        <div class="login-links">
            <button @click="handleAuthClick" id="authorize-button" class="g-signin-button"><img src="/google_icon.svg" alt="Google Logo" /> Sign in with Google</button>
            <button class="facebook-button" @click="onFacebookLogin">
                <img src="/fb_icon.svg" alt="Facebook Logo" /> Sign in with Facebook
                </button>
            <button @click="signoutButton" ref="signoutButton" id="signout-button" style="display: none;">Sign Out</button>
        </div>
    </div>
</ThePublicHomeLayout>
</template>

<script lang="ts">

import { useRouter } from 'vue-router';
import { defineComponent, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { key } from '@/store'
import { IdentityProvider } from '@/models/main/ProviderUser';
import SportzoneGoogleClient from '@/components/util/GoogleOAuth'
import ThePublicHomeLayout from '@/components/main/ThePublicHomeLayout.vue';
import { initFacebookSdk } from '@/composables/common/FacebookLoginHelper';

export default defineComponent({
    components: {
        ThePublicHomeLayout
    },
    setup() {
        initFacebookSdk().then((authResponse: any) => {
            console.log("ready to be clicked")
        })

        const router = useRouter();
        const store = useStore(key)
        const facebookLogin = (accessToken: string) => {
            const provider: IdentityProvider = "facebook";
            store.dispatch('userStore/getAPIAccessToken', { authBody: {access_token: accessToken}, provider})
            .then((token) => {
                if(token) {
                    store.dispatch('userStore/loginUser').then(() => {
                    router.push({ name: 'Organizations' });
                    })
                } else {
                    console.error("Could not get auth token for Sportzone API")
                }
            })
        }

        const onFacebookLogin = async() => {
            window.FB.getLoginStatus(function({ authResponse }) {
                if (authResponse && authResponse.accessToken) {
                    facebookLogin(authResponse.accessToken)
                } else {
                    window.FB.login(function(response) {
                        if(response.status === "connected" && response.authResponse.accessToken) {
                            facebookLogin(response.authResponse.accessToken)
                        }
                    });
                }
            });
            //
            //if (!authResponse) return;
            //console.log(response)
        }

        const signoutButton = ref(null)
        const sportzoneGoogleAuthHelper: SportzoneGoogleClient = SportzoneGoogleClient.instance

        const onSignedInCallbak = () => {
            const client = sportzoneGoogleAuthHelper.client 
            const isSignedIn  = client.isSignedIn
            if(isSignedIn.get()) {
                const currentUser = client.currentUser
                const idToken = currentUser.get().getAuthResponse().id_token
                const provider: IdentityProvider = "google";
                store.dispatch('userStore/getAPIAccessToken', { authBody: {id_token: idToken}, provider})
                .then((token) => {
                    console.log(token)
                    if(token) {
                        store.dispatch('userStore/loginUser').then(() => {
                        router.push({ name: 'Organizations' });
                        })
                    } else {
                        console.error("Could not get auth token for Sportzone API")
                    }
                })
            }
        }

        const handleSignoutClick = () => {
            sportzoneGoogleAuthHelper.client.signOut()
        }

        const handleAuthClick = () => {
            console.log("Handle Auth Click")
            if (sportzoneGoogleAuthHelper.client.isSignedIn.get()) {
            // User is authorized and has clicked "Sign out" button.
                sportzoneGoogleAuthHelper.client.signOut();
                sportzoneGoogleAuthHelper.client.signIn();
            } else {
            // User is not signed in. Start Google auth flow.
                sportzoneGoogleAuthHelper.client.signIn();
            }
        }

        onMounted(() => sportzoneGoogleAuthHelper.googleClient("profile email", [], onSignedInCallbak))


        return { 
            store,
            router,
            signoutButton,
            handleSignoutClick,
            handleAuthClick,
            sportzoneGoogleAuthHelper,
            onFacebookLogin,
           // checkLoginState 
            }
        }
})

</script>

<style>
.login-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    --fb-background-clr: #00adef;
    padding-bottom: 10%;
    justify-content: center;
}

.login-links {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-links button {
  margin-bottom: 1rem;
}

.login-links button:last-child {
  margin-bottom: 0;
}

.greet-section {
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  max-width: 40%;
  margin-right: 5%;
}

.greet-text {
  max-width: 30ch;
}

.greet-header {
  margin: 0 0 2.4rem 0;
  font-size: 3rem;
}

.g-signin-button, .facebook-button {
  font-family: Roboto;
  font-size: 1em;
  padding: 0.5em 1em;
  border: none;
  border-radius: var(--default-border-rad);
  cursor: pointer;
  display: flex;
  justify-content: left;
  align-items: center;
  width: 28ch;
  font-weight: bold;
  text-transform: uppercase;
}

.facebook-button {
    background-color: var(--fb-background-clr);
    color: var(--light-homepage-color);
}

.g-signin-button {
  background-color: var(--light-homepage-color);
  color: var(--fb-background-clr);
}


.g-signin-button img, .facebook-button img {
  width: 24px;
  margin-right: 0.5em;
}
</style>