import GenericDictionary from '@/components/util/GenericDictionary';
import { Sport } from '@/models/main/CommonTypes';
import { Player } from '@/models/main/Player';
import { Organization } from './Organization';
import Season from './Season';

export const ENTITY_TYPES = ["Solo", "Double", "Trio", "Team"]
export type EntityType = typeof ENTITY_TYPES[number]

export interface Entity {
    id?: number,
    name?: string,
    type?: EntityType,
    sport?: Sport,
    additionalData?: string,
    organizationId?: number,
    organization?: Organization,
    seasons?: Season[],
    players?: GenericDictionary<Player>
}