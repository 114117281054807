<template>
    <Modal ref="basicModal">
        <template v-slot:header>
            <slot name="header" />
        </template>
        <template v-slot:body>
            <div class="popup-steps" :class="['show-' + actionProcessStep]">
                <slot v-for="i in actionProcessTotalSteps" class="popup-step" :name="'popup-step-' + i "/>
            </div>
        </template>
        <template v-slot:footer>
            <div v-show="actionProcessStep !== 1" @click="processStepHandler(actionProcessStep - 1)">
                <slot name="back-btn">
                    <button class="btn btn-inverse">Back</button>
                </slot>
            </div>
            <div v-show="actionProcessStep === actionProcessTotalSteps" @click="basicModal.closeModal(), onApplyClick()">
                <slot name="action-btn">
                    <button class="btn">Apply</button>
                </slot>
            </div>
            <div v-show="actionProcessStep !== actionProcessTotalSteps" @click="processStepHandler(actionProcessStep + 1)">
                <slot name="next-btn">
                    <button class="btn btn-inverse">Next</button>
                </slot>
            </div>
            <div @click="basicModal.closeModal()">
                <slot name="cancel-btn">
                    <button class="btn btn-inverse">Cancel</button>
                </slot>
            </div>

        </template>
    </Modal>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import Modal from '@/components/main/Modal.vue';

const StepBasedModal = defineComponent({
    props: {
        startStep: {
            type: Number,
            required: true
        },
        actionProcessTotalSteps: {
            type: Number,
            required: true
        },
        preStepActionExecutors: {
            type: Array
        },
        postStepActionExecutors: {
            type: Array
        },
        stepValidators: {
            type: Array
        }
    },
    emits: ['onApplyClick'],
    components: {
        Modal
    },
    setup(props, { emit }) {
        const actionProcessStep = ref(props.startStep);
        const basicModal: any = ref(null)
        const openModal = () => {
            if(basicModal.value) {
                actionProcessStep.value = props.startStep;
                basicModal.value.openModal()
            }
        }

        const closeModal = () => {
            if(basicModal.value) {
                basicModal.value.closeModal()
            }
        }

        const stepValidators = props.stepValidators as Array<Function>;
        const postStepActionExecutors = props.postStepActionExecutors as Array<Function>;
        const preStepActionExecutors = props.preStepActionExecutors as Array<Function>;
        const processStepHandler = (nextStep: number) => {
            if(nextStep > actionProcessStep.value) {
                const isValid = props.stepValidators && stepValidators[actionProcessStep.value - 1]()
                if(isValid) {
                    if(props.postStepActionExecutors) {
                        postStepActionExecutors[actionProcessStep.value - 1]()
                    }
                    
                    actionProcessStep.value = nextStep
                    
                    if(props.preStepActionExecutors) {
                        preStepActionExecutors[nextStep - 1]()
                    }
                }
            } else {
                actionProcessStep.value = nextStep
            }
        }

        const onApplyClick = () => {
            emit('onApplyClick');
        }
        return {
            openModal,
            closeModal,
            processStepHandler,
            onApplyClick,
            basicModal,
            actionProcessStep,
        }
    }
})

export default StepBasedModal
</script>