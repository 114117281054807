import DataModelMapper from "@/components/util/DataModelMapper";
import DataModel from "@/models/main/DataModel";
import PhaseDetailsDataModel from "@/composables/dataModels/bowling/PhaseDetailsDataModel";
import PhaseEntityDataModel from "./PhaseEntityDataModel";
import GenericDictionary from "@/components/util/GenericDictionary";

export default class PhaseRankingModel extends DataModel {
    public static readonly MODEL_NAME: string = "PhaseRankingModel"

    private _phaseId?: number;
    private _isPredicted?: number;
    private _entities?: Array<PhaseEntityDataModel>;
    private _cut?: PhaseEntityDataModel;
    private _details?: PhaseDetailsDataModel;

    // #region Fields Keys
    static get phaseRankingPhaseIDKey() {
        return "phase_id";
    }

    static get phaseRankingIsPredictedKey() {
        return "predicted";
    }

    static get rankingKey() {
        return "ranking";
    }

    static get cutKey() {
        return "cut";
    }

    static get detailsKey() {
        return "details";
    }
    // #endregion

    // #region accessors/mutations 
    get phaseID() {
        return this._phaseId;
    }

    set phaseID(phaseID) {
        this._phaseId = phaseID;
    }

    get isPredicted(){
        return this._isPredicted;
    }

    set isPredicted(isPredicted) {
        this._isPredicted = isPredicted;
    }

    /**
     *
     * @returns {PhaseEntityDataModel}
     */
    get entities() {
        return this._entities;
    }

    set entities(entities) {
        this._entities = entities;
    }

    /**
     *
     * @returns {PhaseEntityDataModel}
     */
    get cut() {
        return this._cut;
    }

    set cut(cut) {
        this._cut = cut;
    }

    /**
     *
     * @returns {PhaseDetails}
     */
    get details() {
        return this._details;
    }

    set details(details) {
        this._details = details;
    }

    //#endregion

    constructor(modelID?: string, modelData?: any) {
        super();
        this.entities = [];
        this.parseMinifiedData(modelData);
        if(this.phaseID !== undefined) {
            const modelId = DataModel.generateModelID(this, {"phaseID": this.phaseID});
            if(modelId !== false) {
                this.modelID = modelId
            }
        }
    }

    // #region FieldMappings Getters/Setters
    dataModelName() {
        return PhaseRankingModel.MODEL_NAME
    }

    getKeyByMinifiedKey(minKey: string) {
        switch (minKey) {
            case "0":
                return PhaseRankingModel.phaseRankingPhaseIDKey;
            case "1" :
                return PhaseRankingModel.phaseRankingIsPredictedKey;
            case "2" :
                return PhaseRankingModel.rankingKey;
            case "3" :
                return PhaseRankingModel.cutKey;
            case "4" :
                return PhaseRankingModel.detailsKey;
            default:
                return ""
        }
    }

    setProperty(propertyKey: string, value: any) {
        let ranking
        switch (propertyKey) {
            case PhaseRankingModel.phaseRankingPhaseIDKey:
                this.phaseID = parseInt(value);
                break;
            case PhaseRankingModel.phaseRankingIsPredictedKey:
                this.isPredicted = value;
                break;
            case PhaseRankingModel.rankingKey:
                ranking = value;
                for(const rankingKey in ranking) {
                    if(Object.prototype.hasOwnProperty.call(ranking, rankingKey)) {
                        const phaseEntityData = ranking[rankingKey];
                        let entityModel = null;
                        if(phaseEntityData instanceof DataModel) {
                            entityModel = phaseEntityData;
                        } else {
                            entityModel =  DataModelMapper.createFromObject(phaseEntityData);
                        }
                        if(entityModel instanceof PhaseEntityDataModel) {
                            if(this.entities === undefined) {
                                this.entities = []
                            }

                            const entityPosition = parseInt(rankingKey)
                            this.entities[entityPosition] = entityModel;
                        }
                    }
                }
                break;
            case PhaseRankingModel.cutKey:
                if(value !== null) {
                    let entityModel = null;
                    if(value instanceof DataModel) {
                        entityModel = value;
                    } else {
                        entityModel = DataModelMapper.createFromObject(value);
                    }

                    if(entityModel instanceof PhaseEntityDataModel) {
                        this.cut = entityModel;
                    }
                }
                break;
            case PhaseRankingModel.detailsKey:
                if(value !== null) {
                    let phaseDetailsModel = null;
                    if(value instanceof DataModel) {
                        phaseDetailsModel = value;
                    } else {
                        phaseDetailsModel = DataModelMapper.createFromObject(value);
                    }
                    if(phaseDetailsModel instanceof PhaseDetailsDataModel) {
                        this.details = phaseDetailsModel;
                    }
                }
                break;
        }
    }

    getProperty(propertyKey: string) {
        switch (propertyKey) {
            case PhaseRankingModel.phaseRankingPhaseIDKey:
                return this.phaseID;
            case PhaseRankingModel.phaseRankingIsPredictedKey:
                return this.isPredicted;
            case PhaseRankingModel.rankingKey:
                return this.entities;
            case PhaseRankingModel.cutKey:
                return this.cut;
            case PhaseRankingModel.detailsKey:
                return this.details;
        }
    }

    //#endregion
}
