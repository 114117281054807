import GenericDictionary from '@/components/util/GenericDictionary';
import authModule from '@/main';
import { Player } from '@/models/main/Player';
import { IRootState } from '@/store';
import { ActionContext, Module } from 'vuex'

export interface PlayerStore {
    players: GenericDictionary<Player>
}

const state: PlayerStore = {
    players: {}
}

const getters = {
    getPlayers(state: PlayerStore) {
        return state.players;
    },
}

const mutations = {
    addPlayer(state: PlayerStore, player: Player) {
        if(player.id !== undefined)
            state.players[player.id] = player
    },
    removePlayer(state: PlayerStore, playerId: number) {
        if(Object.prototype.hasOwnProperty.call(state.players, playerId)) {
            delete state.players[playerId]
        }
    }
}

const actions = {
    getPlayers({ commit, }: ActionContext<PlayerStore, IRootState>) {
        return authModule.get(`/api/player`)
            .then((response) => {
                const players: Player[] = response.data as unknown as Player[]
                for(let i = 0; i < players.length; i++) {
                    commit('addPlayer', players[i])
                }

                return players
            }, (err) => {
                if (err.response.status !== 404) {
                    console.error(err);
                    return null;
                }

                console.log("Players not found!");
                return null;
            })
    },
    createPlayer({ commit }: ActionContext<PlayerStore, IRootState>, player: Player) {
        return authModule.post(`/api/player`, player)
            .then((response) => {
                const createdPlayer: Player = response.data as unknown as Player
                player.id = createdPlayer.id
                commit('addPlayer', createdPlayer)
                commit('entityStore/addPlayer', {player: createdPlayer, entityId: player.entityId}, { root: true})
                return player
            })
    },
    deletePlayer({ commit }: ActionContext<PlayerStore, IRootState>, {playerId, entityId}: { playerId: number, entityId: number}) {
        return authModule.delete(`/api/player/${playerId}`)
            .then((response) => {
                const deletedPlayerId: number = response.data as unknown as number
                commit('removePlayer', deletedPlayerId)
                commit('entityStore/removePlayer', {playerId, entityId}, { root: true})
            })
    },
    uploadPicture({ }: ActionContext<PlayerStore, IRootState>, { playerId, playerPicture }: { playerId: number, playerPicture: File }) {
        return authModule
            .post(`/api/player/${playerId}/picture`, playerPicture, {
                headers: {
                    'Content-Type': playerPicture.type,
                },
            });
    },
}

const playerStore: Module<PlayerStore, IRootState> = 
{
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}

export default playerStore;