<template>
    <div class="administrate-container-view-btns" v-if="authModule.isAuthenticated && autoScroll != 1">
        <button class="btn save-btn" @click="saveContainerResultFormerResultChanges" title="Save" role="button">Save</button>
        <button class="btn" :class="{ 'edit-btn' : !isEditMode }" @click="isEditMode = !isEditMode">{{ isEditMode ? 'Cancel' : 'Edit' }}</button>
        <router-link class="btn" :to="{ name: 'Container Public View', params: { id: containerRankingViewId }, query: { scroll: '1' } }" target="_blank">
            Auto Scroll View
        </router-link>
        <!-- <button @click="changeMode(), scrollUpAndDown()" class="btn" :class="{ 'stop-btn': isStartMode }">{{ isStartMode ? 'Stop' : 'Start' }}</button> -->
    </div>
    <div class="container-ranking-view-wrapper" ref="rankingViewWrapper">
        <div class="ranking-view-rounds">
            <template v-for="(roundEFCGroupedByContainer, key) in entityCollectionsGoupedByRoundAndContainer.values()">
                <button @click="clearRound(key)" class="btn btn-inverse" :class="{'selected-round' : key == hideRoundRef}">Round {{ key + 1 }}</button>
            </template>
        </div>
        <div class="ranking-view elimination">
            <template v-for="(roundEFCGroupedByContainer, key) in entityCollectionsGoupedByRoundAndContainer.values()">
                <div class="elimination__round-connector" v-show="key >= hideRoundRef + 1">
                    <div class="match-connector" v-for="matchContainerResultFormers in roundEFCGroupedByContainer.size"></div>
                </div>
                <div class="elimination__round" v-show="key >= hideRoundRef">
                    <button v-if="false" @click="clearRound(key)" class="elimination-round-btn btn btn-inverse">Round {{ key + 1 }}</button>
                    <div class="match-container" v-for="matchContainerEntityCollectionFormers in roundEFCGroupedByContainer">
                        <button v-if="isEditMode" class="add-entity-collection-btn btn btn-inverse" @click="entitySelectDialog.open(), selectedEntityCollectionFormerId = matchContainerEntityCollectionFormers[1][0]?.id">+E</button>
                        <div class="match-sequence">{{ containersMap.get(matchContainerEntityCollectionFormers[0]).sequence }}</div>
                        <div class="match__entity entity-one">
                            <div class="match__entity-info" v-if="matchContainerEntityCollectionFormers[1][0]?.entities[0]">
                                <div class="entity-flag" v-for="player in [matchContainerEntityCollectionFormers[1][0]?.entities[0].players[0]]">
                                    <img v-if="player?.nationality == 'Bulgarian'" src="https://www.bowlzone.eu/app/plugins/bowlzone-plugin/public/images/flags/Bulgaria.png" alt="BG flag" />
                                    <img v-if="player?.nationality == 'Turkish'" src="https://www.bowlzone.eu/app/plugins/bowlzone-plugin/public/images/flags/Turkey.png" alt="TR flag" />
                                    <img v-if="player?.nationality == 'Polish'" src="https://www.bowlzone.eu/app/plugins/bowlzone-plugin/public/images/flags/Poland.png" alt="PL flag" />
                                    <img v-if="player?.nationality == 'Romanian'" src="https://www.bowlzone.eu/app/plugins/bowlzone-plugin/public/images/flags/Romania.png" alt="RO flag" />
                                    <img v-if="player?.nationality == 'Moldovan'" src="https://www.bowlzone.eu/app/plugins/bowlzone-plugin/public/images/flags/Moldova.png" alt="MO flag" />
                                    <img v-if="player?.nationality == 'Israeli'" src="https://www.bowlzone.eu/app/plugins/bowlzone-plugin/public/images/flags/Israel.png" alt="IL flag" />
                                    <img v-if="player?.nationality == 'Austrian'" src="https://www.bowlzone.eu/app/plugins/bowlzone-plugin/public/images/flags/Austria.png" alt="AU flag" />
                                </div>
                                <div class="match__entity-name">{{ matchContainerEntityCollectionFormers[1][0]?.entities[0]?.name }}</div>
                            </div>
                            <div class="match__entity-result">
                                <template v-for="containerGroup in [groupedByContainer.get(matchContainerEntityCollectionFormers[0])]">
                                    <div class="match__entity-sets" :class="{'max-5-sets': containerGroup?.length == 5, 'max-7-sets' : containerGroup?.length == 7}">
                                        <template v-for="containerResultFormer in containerGroup">
                                            <div class="match-set" v-if="containerResultFormer">
                                                <template v-for="entityContainerResult in [findEntityResult(matchContainerEntityCollectionFormers[1][0]?.entities[0]?.id, containerResultFormer?.containerResults)]">
                                                    <input v-show="isEditMode" class="container-result-cell" type="text" :name="'cr-' + entityContainerResult?.id"  
                                                    @change="updateContainerResult($event, containerResultFormer, entityContainerResult, matchContainerEntityCollectionFormers[1][0]?.entities[0])" 
                                                    :value="entityContainerResult?.result ?? 0" />
                                                    <span v-show="!isEditMode">{{ entityContainerResult?.result }}</span>
                                                </template>
                                            </div>
                                        </template>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="match__entity entity-two">
                            <div class="match__entity-info" v-if="matchContainerEntityCollectionFormers[1][0]?.entities[1]">
                                <div class="entity-flag" v-for="player in [matchContainerEntityCollectionFormers[1][0]?.entities[1].players[0]]">
                                    <img v-if="player?.nationality == 'Bulgarian'" src="https://www.bowlzone.eu/app/plugins/bowlzone-plugin/public/images/flags/Bulgaria.png" alt="BG flag" />
                                    <img v-if="player?.nationality == 'Turkish'" src="https://www.bowlzone.eu/app/plugins/bowlzone-plugin/public/images/flags/Turkey.png" alt="TR flag" />
                                    <img v-if="player?.nationality == 'Polish'" src="https://www.bowlzone.eu/app/plugins/bowlzone-plugin/public/images/flags/Poland.png" alt="PL flag" />
                                    <img v-if="player?.nationality == 'Romanian'" src="https://www.bowlzone.eu/app/plugins/bowlzone-plugin/public/images/flags/Romania.png" alt="RO flag" />
                                    <img v-if="player?.nationality == 'Moldovan'" src="https://www.bowlzone.eu/app/plugins/bowlzone-plugin/public/images/flags/Moldova.png" alt="MO flag" />
                                    <img v-if="player?.nationality == 'Israeli'" src="https://www.bowlzone.eu/app/plugins/bowlzone-plugin/public/images/flags/Israel.png" alt="IL flag" />
                                    <img v-if="player?.nationality == 'Austrian'" src="https://www.bowlzone.eu/app/plugins/bowlzone-plugin/public/images/flags/Austria.png" alt="AU flag" />
                                </div>
                                <div class="match__entity-name">{{ matchContainerEntityCollectionFormers[1][0]?.entities[1]?.name }}</div>
                            </div>
                            <div class="match__entity-result">
                                <template v-for="containerGroup in [groupedByContainer.get(matchContainerEntityCollectionFormers[0])]">
                                    <div class="match__entity-sets" :class="{'max-5-sets': containerGroup?.length == 5, 'max-7-sets' : containerGroup?.length == 7}">
                                        <template v-for="containerResultFormer in groupedByContainer.get(matchContainerEntityCollectionFormers[0])">
                                            <div class="match-set" v-if="containerResultFormer">
                                                <template v-for="entityContainerResult in [findEntityResult(matchContainerEntityCollectionFormers[1][0]?.entities[1]?.id, containerResultFormer?.containerResults)]">
                                                    <input v-show="isEditMode" class="container-result-cell" type="text" :name="'cr-' + entityContainerResult?.id"
                                                    @change="updateContainerResult($event, containerResultFormer, entityContainerResult, matchContainerEntityCollectionFormers[1][0]?.entities[1])" 
                                                    :value="entityContainerResult?.result ?? 0"/>
                                                    <span  v-show="!isEditMode">{{ entityContainerResult?.result }}</span>
                                                </template>

                                            </div>
                                        </template>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
    <EntitySelectDialog ref="entitySelectDialog" v-model="selectedEntityId" @onChoose="addEntityToCollectionFormer()" />
</template>
<script setup lang="ts">
import { onMounted, Ref, ref } from 'vue'
import { useStore } from 'vuex'
import { key } from '@/store'
import { useRoute } from 'vue-router'
import authModule from '@/main'
import EntitySelectDialog from '@/components/main/entity/EntitySelectDialog.vue'
import useContainerRankingViewComposables from '@/composables/generic/UseContainerRankingViewComposables'

const store = useStore(key);
const route = useRoute()
const containerRankingViewId: Ref<number> = ref(parseInt(route.params.id as string));

/* Elimination View related */
let hideRoundDefault: number = parseInt(route.query.r as string);
const hideRoundRef: Ref<number> = ref(hideRoundDefault)
if(isNaN(hideRoundDefault)) {
    hideRoundDefault = 0
}
const clearRound = (hideRound: number) => {
    hideRoundRef.value = hideRound
}
/* End Elimination View */


/* Auto-scroll related */
let playTimeout: number = parseInt(route.query.t as string);
let autoScroll: number = parseInt(route.query.scroll as string);
const defaultPlayTimoutInSeconds = 30;
if(isNaN(playTimeout)) {
    playTimeout = defaultPlayTimoutInSeconds;
}
const isStartMode: Ref<boolean> = ref(autoScroll === 1)
const rankingViewWrapper: Ref<HTMLDivElement | undefined> = ref()
let position: number = 10000
const changeMode = () => {
    isStartMode.value = !isStartMode.value
}
const scrollUpAndDown = () => {
    if (isStartMode.value) {
        position *= -1
        rankingViewWrapper.value?.scrollTo(0, position)
        setTimeout(scrollUpAndDown, playTimeout * 1000)
    }
}
onMounted(scrollUpAndDown)
/* End Auto-scroll */


/* Entity Select Popup related */

const entitySelectDialog: Ref<any> = ref()
const selectedEntityId: Ref<number> = ref(0);

/* End Entity Select Popup */


/* Ranking View Related */

const {
    selectedEntityCollectionFormerId,
    isEditMode,
    groupedByContainer,
    containersMap,
    entityCollectionsGoupedByRoundAndContainer,
    saveContainerResultFormerResultChanges,
    findEntityResult,
    updateContainerResult,
    addEntityToCollectionFormer,
    loadContainerRankingView, 

} = useContainerRankingViewComposables(containerRankingViewId, selectedEntityId, autoScroll)

onMounted(loadContainerRankingView)
/* End Ranking View */
</script>
<style>
.container-ranking-view-wrapper {
 container-type:inline-size;
    width:100%;
    height:100%;
    overflow: auto;
}

.administrate-container-view-btns + .container-ranking-view-wrapper {
  margin-top: 0.75rem;
}

.ranking-view-rounds {
    display: flex;
    gap: 2rem;
    justify-content: flex-start;
    align-items: center;
    max-width: 85rem;
}

.stop-btn {
    background-color: var(--watch-bck-color);
}

.container-ranking-view-wrapper {
    scroll-behavior: smooth;
}

.ranking-view.elimination {
    display: flex;
    gap: 5ch;
    width: max-content;
}

.administrate-container-view-btns {
    position: fixed;
    top: var(--nav-height, 1ch);
    z-index: 10;
}

.elimination__round {
    width: -moz-max-content;
    width: max-content;
    display: flex;
    flex-direction: column;
    gap: 1.5ch;
    justify-content: space-around;
}
.match-container {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 3ch 1fr;
    background-color: var(--main-font-clr);
    padding: 1ch;
    border-radius: var(--default-border-rad);
    font-weight: 500;
    color: var(--background-clr);
    position: relative;
    height: 8ch;
}

.match-container::before, .match-container::after {
  content: '';
  height: calc(50% + 1ch);
  width: 2.5ch;
  position: absolute;
  top: 50%;
  border-top: 2px solid black;
}

.match-container::before {
  left: -2.5ch;
  z-index: 4;
}

.match-container::after {
  right: -2.5ch;
}

.add-entity-collection-btn {
    position:absolute;
    top:-1rem;
    right:0;
}

.btn.btn-inverse.selected-round {
  color: var(--btn-error-background-color);
}

/* .match-container:nth-child(2n)::after {
    width: calc(2.5ch + 2px);
    background-color: var(--background-clr);
    margin-right: -2px;
    z-index: 3;
} */

.match-sequence {
    grid-row: span 2;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.container-result-cell, .match-set 
{
  max-width: 4ch;
  min-width: 3ch;
  text-align: right;
}

.match-container:nth-child(2n+1)::after {
  z-index: 3;
  box-shadow: 1ch -4.5ch 0 -4px var(--background-clr);
}

.elimination__round:nth-child(2) .match-container::before {
    display: none;
}

.elimination__round:first-child .match-container::before, .elimination__round:last-child .match-container::after {
  display: none;
}

.match__entity {
    --_entity-name-max-width: 16ch;
    display: grid;
    grid-template-columns: calc(var(--_entity-name-max-width) + 5ch) 1fr;
    max-height: 2lh;
    place-content: center;
    width: max-content;
}

.match__entity-info {
    display: flex;
    gap: 2ch;
}
.match__entity-name {
    min-width: var(--_entity-name-max-width);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.entity-flag {
    display: flex;
    width: 3ch;
    justify-content: center;
    align-items: center;
}
.entity-flag img {
    max-width: 100%;
}
.match__entity-sets {
    display: grid;
    gap: 1.5ch;
    justify-content: flex-start;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
}
.match__entity-result {
    display: flex;
}

.match__entity-sets.max-5-sets {
  grid-template-columns: repeat(5, 1fr);
}

.match__entity-sets.max-7-sets {
  grid-template-columns: repeat(7, 1fr);
}

.elimination__round-connector {
    display: flex;
    gap: 1.5ch;
    flex-direction: column;
    justify-content: space-around;
    margin-left: -2.5ch;
    margin-right: -2.5ch;
}

.elimination__round-connector:first-child {
  display: none;
}

.match-connector {
    width: 2px;
    background-color: black;
    position: relative;
    box-shadow: 4px 6.5ch 0px 2px var(--background-clr);
    z-index: 2;
}

/* .match-connector::before, .match-connector::after {
    content: '';
    width: 2px;
    height: 1ch;
    background-color: black;
    position: absolute;
    left: 0;
}


.match-connector::before {
    top: -0.5ch;
}

.match-connector::after {
    bottom: -0.75ch;
} */

div.elimination__round-connector:nth-last-child(11) .match-connector, div.elimination__round-connector:nth-last-child(12) .match-connector {
    height: calc((100% / 64) + 4px);
}
div.elimination__round-connector:nth-last-child(9) .match-connector, div.elimination__round-connector:nth-last-child(10) .match-connector {
    height: calc((100% / 32) + 4px);
}
div.elimination__round-connector:nth-last-child(7) .match-connector, div.elimination__round-connector:nth-last-child(8) .match-connector {
    height: calc((100% / 16) + 4px);
}
div.elimination__round-connector:nth-last-child(5) .match-connector, div.elimination__round-connector:nth-last-child(6) .match-connector {
    height: calc((100% / 8) + 6px);
}
div.elimination__round-connector:nth-last-child(3) .match-connector, div.elimination__round-connector:nth-last-child(4) .match-connector {
    height: calc((100% / 4) + 7px);
}
div.elimination__round-connector:nth-last-child(1) .match-connector, div.elimination__round-connector:nth-last-child(2) .match-connector {
    height: calc((100% / 2) + 10px);
}

@media screen and (min-width: 1000px) {
    .ranking-view-rounds {
        justify-content: space-evenly;
    }
}

</style>