import { PaginatedItemsStore, PagedResponse, PaginatedItemsStoreGetters, PaginatedItemsStoreMutations } from '@/composables/common/Pagination';
import Livestream, { LivestreamStatus, SaveLivestream, StreamPrivacy } from '@/models/main/Livestream';
import { Sport } from '@/models/main/CommonTypes';
import { ActionContext, Module } from 'vuex';
import { IRootState } from '@/store';
import authModule from '@/main';
import StationJob from '@/models/main/StationJob';
import { UserLivestreamClip } from '@/models/main/UserLivestreamClip';
export interface LivestreamStore extends PaginatedItemsStore<Livestream> {
    paginatedItems: PagedResponse<Livestream>,
    status?: LivestreamStatus,
    sport?: Sport,
    privacy?: StreamPrivacy,
};
const state: LivestreamStore = {
    paginatedItems: {
        items: {},
    },
};
const getters = {
    getLivestream: (state: LivestreamStore) => (livestreamId: number) => {
        return state.paginatedItems.items[livestreamId];
    },
    getLivestreams: (state: LivestreamStore) => ({ sort = 'DESC', statuses, sport, privacy }: { sort: 'ASC' | 'DESC', statuses: LivestreamStatus[] | undefined, sport: Sport | undefined, privacy: StreamPrivacy | undefined }) => {
        const filteredLivestreams: Array<Livestream> = [];
        for (const livestreamId in state.paginatedItems.items) {
            if (Object.prototype.hasOwnProperty.call(state.paginatedItems.items, livestreamId)) {
                const livestream = state.paginatedItems.items[livestreamId];
                if (statuses === undefined || statuses.includes(livestream.status ?? '')) {
                    if (sport === undefined || livestream.sport === sport) {
                        if (privacy === undefined || livestream.privacy === privacy) {
                            filteredLivestreams.push(livestream);
                        }
                    }
                }
            }
        }
        const sortedLivestreams = filteredLivestreams.sort((a, b) => {
            if (a.startTime && b.startTime) {
                const aDate = new Date(a.startTime);
                const bDate = new Date(b.startTime);
                let aDateValue;
                let bDateValue;
                return isFinite(aDateValue = aDate.valueOf()) && isFinite(bDateValue = bDate.valueOf())
                    ? (sort === 'ASC'
                        ? aDateValue - bDateValue
                        : bDateValue - aDateValue)
                    : 0;
            }
            else {
                return 0;
            }
        });
        return sortedLivestreams;
    },
    getStatus(state: LivestreamStore) {
        return state.status;
    },
    getSport(state: LivestreamStore) {
        return state.sport;
    },
    getPrivacy(state: LivestreamStore) {
        return state.privacy;
    },
    ...PaginatedItemsStoreGetters(),
};
const mutations = {
    addLivestream(state: LivestreamStore, livestream: Livestream) {
        if (livestream.id !== undefined) {
            if(livestream.startTime) {
                livestream.startTime = new Date(livestream.startTime)
            }
            // if(livestream.endTime) {
            //     livestream.endTime = new Date(livestream.endTime)
            // }
            if(livestream.streamActualStartTime) {
                livestream.streamActualStartTime = new Date(livestream.streamActualStartTime)
            }
            state.paginatedItems.items[livestream.id] = livestream;
        }
    },
    removeLivestream(state: LivestreamStore, livestreamId: number) {
        for (const i in state.paginatedItems.items) {
            const livestream = state.paginatedItems.items[i];
            if (livestream.id === livestreamId) {
                delete state.paginatedItems.items[i];
                return;
            }
        }
        console.error('Tryed to delete not existing livestream!');
    },
    setStatus(state: LivestreamStore, status: LivestreamStatus) {
        state.status = status;
    },
    setSport(state: LivestreamStore, sport: Sport) {
        state.sport = sport;
    },
    setPrivacy(state: LivestreamStore, privacy: StreamPrivacy) {
        state.privacy = privacy;
    },
    updateLivestream(state: LivestreamStore, { livestream, livestreamId }: { livestream: Livestream, livestreamId: number }) {
        if (livestreamId !== undefined) {
            state.paginatedItems.items[livestreamId] = livestream;
        }
    },
    ...PaginatedItemsStoreMutations(),
};
const actions = {
    getLivestream({ commit }: ActionContext<LivestreamStore, IRootState>, livestreamId: number) {
        return authModule
            .get(`/api/livestream/${livestreamId}`)
            .then((response) => {
                const livestream: Livestream = response.data as unknown as Livestream;
                commit('addLivestream', livestream);
                return livestream;
            });
    },
    getLivestreams({ commit, getters }: ActionContext<LivestreamStore, IRootState>, { status, sport, privacy }: { status: LivestreamStatus, sport?: Sport, privacy?: StreamPrivacy }) {
        const currentStatus = getters['getStatus'];
        const currentSport = getters['getSport'];
        const currentPrivacy = getters['getPrivacy'];
        let filtersChanged = false;
        if (currentStatus !== status) {
            commit('setStatus', status);
            filtersChanged = true;
        }
        if (currentSport !== sport) {
            commit('setSport', sport);
            filtersChanged = true;
        }
        if (currentPrivacy !== privacy) {
            commit('setPrivacy', privacy);
            filtersChanged = true;
        }
        let pageToRequest = getters['getNextPage'];
        const pageSize = getters['getPageSize'];
        if (filtersChanged) {
            pageToRequest = 0;
        }
        const statusFilterQuery = status
            ? `&status=${status}`
            : '';
        const sportFilterQuery = sport
            ? `&sport=${sport}`
            : '';
        const privacyFilterQuery = privacy
            ? `&privacy=${privacy}`
            : '';
        if (!filtersChanged && getters['getNextPage'] === 0 && getters['getCurrentPage'] > 0) {
            return;
        }
        return authModule
            .get(`/api/livestream?page=${pageToRequest}&pageSize=${pageSize}${statusFilterQuery}${sportFilterQuery}${privacyFilterQuery}`)
            .then((response) => {
                const pagedResponse: PagedResponse<Livestream> = response.data as unknown as PagedResponse<Livestream>;
                commit('setCurrentPage', pagedResponse.currentPage);
                commit('setPageSize', pagedResponse.pageSize);
                commit('setNextPage', pagedResponse.nextPage);
                commit('setPreviousPage', pagedResponse.previousPage);
                for (const livestreamIndex in pagedResponse.items) {
                    commit('addLivestream', pagedResponse.items[livestreamIndex]);
                }
            });
    },
    saveLivestream({ commit }: ActionContext<LivestreamStore, IRootState>, { livestream, scheduleStart }: { livestream: SaveLivestream, scheduleStart: boolean }) {
        return authModule
            .post(`/api/livestream`, { scheduleStart, ...livestream })
            .then((response) => {
                const createdLivestream: Livestream = response.data as unknown as Livestream;
                commit('addLivestream', createdLivestream);
            });
    },
    startLivestream({ commit }: ActionContext<LivestreamStore, IRootState>, livestreamId: number) {
        authModule
            .post(`/api/livestream/${livestreamId}/start`, { livestreamId })
            .then((response) => {
                const createdJob: StationJob = response.data as unknown as StationJob;
            });
    },
    stopLivestream({ commit }: ActionContext<LivestreamStore, IRootState>, livestreamId: number) {
        authModule
            .post(`/api/livestream/${livestreamId}/stop`, { livestreamId })
            .then((response) => {
                const createdJob: StationJob = response.data as unknown as StationJob;
            });
    },
    deleteLivestream({ commit }: ActionContext<LivestreamStore, IRootState>, livestreamId: number) {
        authModule
            .delete(`/api/livestream/${livestreamId}`)
            .then((response) => {
                const deleteLivestreamId: number = response.data as number;
                commit('removeLivestream', deleteLivestreamId);
            });
    },
    saveUserLivestreamClip({ commit }: ActionContext<LivestreamStore, IRootState>, userLivestreamClip: UserLivestreamClip) {
        authModule
            .post(`/api/livestream/clip`, userLivestreamClip)
            .then((response) => {
                const createdClip: UserLivestreamClip = response.data as unknown as UserLivestreamClip;
                return createdClip;
            });
    },
    editLivestream({ commit }: ActionContext<LivestreamStore, IRootState>, { livestream, livestreamId }: { livestream: SaveLivestream, livestreamId: number }) {
        return authModule
            .patch(`/api/livestream/${livestreamId}`, { ...livestream, livestreamId })
            .then((response) => {
                const editedLivestream: Livestream = response.data as unknown as Livestream;
                commit('updateLivestream', { livestream: editedLivestream, livestreamId });
            });
    },
};
const livestreamStore: Module<LivestreamStore, IRootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
export default livestreamStore;