<template>
    <ActionBox :isDisabled="isDisabled" @click="$emit('click', index)" v-for="(pointType, index) in pointTypes" :key="index">{{pointType}}</ActionBox>
</template>
<script lang="ts">
import ActionBox from '@/components/volleyball/ActionBox.vue'
import { VOLLEYBALL_POINT_TITLE_PAIRS } from '@/composables/volleyball/VolleyballEventTypes'
import { defineComponent } from "vue";

export default defineComponent({
    props: ['isSelected', 'isDisabled'],
    data() {
        return {
            pointTypes: VOLLEYBALL_POINT_TITLE_PAIRS 
        }
    },
    emits: ['click'],
    components: {
        ActionBox
    }
})
</script>