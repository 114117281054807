<template>
    <TheOrganizerDashboard ref="mainWrapper" v-if="authModule.isAuthenticated" @scroll="onMainWrapperScroll" class="archive" :class="{ 'bwl': sportFilter === 'Bowling', 'tbltenn': sportFilter === 'TableTennis', 'tenn': sportFilter === 'Tennis', 'vly': sportFilter === 'Volleyball' }">
    <div class="watch-livestreams-list" @scroll="onMainWrapperScroll">
        <div class="filters-section">
        <button @click="onSportFilterClick('Volleyball')" class="filter-by-sport-btn vly-filter" title="Volleyball Only">
            <VolleyballIcon :class="{'active-filter': sportFilter === 'Volleyball'}" />
        </button>
        <button @click="onSportFilterClick('Bowling')" :class="{'active-filter': sportFilter === 'Bowling'}" class="filter-by-sport-btn bwl-filter" title="Bowling Only">
            <BowlingIcon />
        </button>
        <button @click="onSportFilterClick('Badminton')" :class="{'active-filter': sportFilter === 'Badminton'}" class="filter-by-sport-btn badminton-filter" title="Badminton Only">
            <BadmintonIcon />
        </button>
        <button @click="onSportFilterClick('TableTennis')" :class="{'active-filter': sportFilter === 'TableTennis'}" class="filter-by-sport-btn tbltenn-filter" title="Table Tennis Only">
            <TableTennisIcon />
        </button>
        <button @click="onSportFilterClick('Tennis')" :class="{'active-filter': sportFilter === 'Tennis'}" class="filter-by-sport-btn tenn-filter" title="Tennis Only">
            <TennisIcon />
        </button>
    </div>
        <WatchLivestreamItem v-for="livestream in getLivestreams({statuses: ['Completed', 'Stopping'], sport: sportFilter})" :key="livestream.id" :livestream="livestream" />
        <LoadingIcon v-show="showLoadingIcon"/>
    </div>
</TheOrganizerDashboard>
    <ThePublicHomeLayout ref="mainWrapper" v-else @scroll="onMainWrapperScroll" class="archive" :class="{ 'bwl': sportFilter === 'Bowling', 'tbltenn': sportFilter === 'TableTennis', 'tenn': sportFilter === 'Tennis', 'vly': sportFilter === 'Volleyball' }">
    <div class="watch-livestreams-list">
        <div class="filters-section">
            <button @click="onSportFilterClick('Volleyball')" class="filter-by-sport-btn vly-filter" title="Volleyball Only">
                <VolleyballIcon :class="{'active-filter': sportFilter === 'Volleyball'}" />
            </button>
            <button @click="onSportFilterClick('Bowling')" :class="{'active-filter': sportFilter === 'Bowling'}" class="filter-by-sport-btn bwl-filter" title="Bowling Only">
                <BowlingIcon />
            </button>
            <button @click="onSportFilterClick('Badminton')" :class="{'active-filter': sportFilter === 'Badminton'}" class="filter-by-sport-btn badminton-filter" title="Badminton Only">
            <BadmintonIcon />
        </button>
            <button @click="onSportFilterClick('TableTennis')" :class="{'active-filter': sportFilter === 'TableTennis'}" class="filter-by-sport-btn tbltenn-filter" title="Table Tennis Only">
                <TableTennisIcon />
            </button>
            <button @click="onSportFilterClick('Tennis')" :class="{'active-filter': sportFilter === 'Tennis'}" class="filter-by-sport-btn tenn-filter" title="Tennis Only">
                <TennisIcon />
            </button>
        </div>
        <WatchLivestreamItem v-for="livestream in getLivestreams({statuses: ['Completed', 'Stopping'], sport: sportFilter, privacy: 'Public'})" :key="livestream.id" :livestream="livestream" />
        <LoadingIcon v-show="showLoadingIcon"/>
    </div>
</ThePublicHomeLayout>
</template>
<script lang="ts">
import TheOrganizerDashboard from '@/components/main/TheOrganizerDashboard.vue'
import BowlingIcon from '@/components/bowling/BowlingIcon.vue'
import VolleyballIcon from '@/components/volleyball/VolleyballIcon.vue'
import TableTennisIcon from '@/components/tableTennis/TableTennisIcon.vue'
import BadmintonIcon from "@/components/badminton/BadmintonIcon.vue"
import TennisIcon from '@/components/tennis/TennisIcon.vue'
import LoadingIcon from '@/components/util/LoadingIcon.vue'
import { defineComponent, ref, Ref } from 'vue'
import { useStore, createNamespacedHelpers} from 'vuex'
import { key } from '@/store'
import authModule from '@/main';
import WatchLivestreamItem from '@/components/main/WatchLivestreamItem.vue';
import ThePublicHomeLayout from '@/components/main/ThePublicHomeLayout.vue';
import { Sport } from '@/models/main/CommonTypes';
import { LIVESTREAM_STATUSES, LivestreamStatus } from '@/models/main/Livestream'
import { getUserLastSelectedSport, setUserLastSelectedSport } from '@/composables/common/Util'

const livestreamStoreHelpers = createNamespacedHelpers('livestreamPublicStore')
const userStoreHelpers = createNamespacedHelpers('userStore')

export default defineComponent({
    components: {
        TheOrganizerDashboard,
        WatchLivestreamItem,
        ThePublicHomeLayout,
        BowlingIcon,
        VolleyballIcon,
        LoadingIcon,
        TableTennisIcon,
        TennisIcon,
        BadmintonIcon,
    },
    computed: {
        ...livestreamStoreHelpers.mapGetters([
            'getLivestreams',
        ]),
        ...userStoreHelpers.mapGetters([
            'getId',
            'getUser',
        ])
    },
    setup() {
        const initialSportValue = getUserLastSelectedSport() ?? "Volleyball"
        const sportFilter: Ref<Sport | undefined> = ref(initialSportValue)
        const store = useStore(key)
        const statuses: LivestreamStatus[] = [LIVESTREAM_STATUSES[3], LIVESTREAM_STATUSES[4]]
        const showLoadingIcon: Ref<boolean> = ref(true);
        let loadInProcess: Ref<boolean> = ref(false)
        const loadLivestreams = async (statuses: LivestreamStatus[], sport?: Sport) => {
            showLoadingIcon.value = true
            await store.dispatch("livestreamPublicStore/getPublicLivestreams", { statuses, sport })
            showLoadingIcon.value = false
        }

        const onSportFilterClick = (choosenSport: Sport) => {
            sportFilter.value = choosenSport
            if(scrollTimeout) {
                clearTimeout(scrollTimeout)
            }

            loadInProcess.value = true
            loadLivestreams(statuses, sportFilter.value)
            loadInProcess.value = false
            setUserLastSelectedSport(sportFilter.value)
        }

        const mainWrapper: Ref<any> = ref()

        const onScrollStopped = () => {
            if(!loadInProcess.value) {
                loadInProcess.value = true
                loadLivestreams(statuses, sportFilter.value)
                loadInProcess.value = false
            }
        }

        let scrollTimeout: any
        const onMainWrapperScroll = (event: any) => {
            const scrollTop = event.target.scrollTop;
            const totalScrollHeight = event.target.scrollHeight;
            const offsetHeight = event.target.offsetHeight;
            const margin = 20
            let bottomOfWindow = totalScrollHeight - scrollTop - offsetHeight < margin
            if (bottomOfWindow) {
                if(scrollTimeout) {
                    clearTimeout(scrollTimeout)
                }

                scrollTimeout = setTimeout(onScrollStopped, 200)

            }
        }

        loadLivestreams(statuses, sportFilter.value)
        return {
            store,
            authModule,
            sportFilter,
            mainWrapper,
            showLoadingIcon,
            onMainWrapperScroll,
            onSportFilterClick,
            }
    }
})

</script>
<style>
.archive .watch-livestreams-list {
    --watch-bck-color:  var(--archive-bck-color);
    --loading-icon-moving-color: var(--archive-bck-color);
}

.livestreams-page {
    max-width: 100vw;
    max-height: 100vh;
    overflow-y: scroll;
    scrollbar-width: thin;
}

.admin-section.archive, .livestreams-page.archive {
    scrollbar-color: var(--deep-blue-bck-color) var(--archive-bck-color);
}

.filters-section {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 1em;
}

.filter-by-sport-btn {
    width: 5ch;
    background: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    border: none;
    border-radius: var(--default-border-rad);
    margin-left: 0.5ch;
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-right: 1ch;
}

</style>