import DataModelMapper from "@/components/util/DataModelMapper";
import GenericDictionary from "@/components/util/GenericDictionary";
import DataModel from "@/models/main/DataModel";
import PhaseSerieDataModel from "./PhaseSerieDataModel";

export default class PhaseEntityDataModel extends DataModel {
    public static readonly MODEL_NAME: string = "PhaseEntity"

    private _id?: number;
    private _name?: string;
    private _startScore?: number;
    private _handicap?: number;
    private _isPlaying?: boolean;
    private _result?: number;
    private _phaseResult?: number;
    private _maxSerieID?: number;
    private _series?: GenericDictionary<PhaseSerieDataModel>;
    private _qualifiedForPhase?: any;
    private _qualifiedFromPhase?: any;
    private _qualifiedForPhaseName?: string;
    private _qualifiedFromPhaseName?: string;
    private _resultTiebreak1?: any;
    private _resultTiebreak2?: any;
    private _resultTiebreak3?: any;

    // #region Fields Keys
    static get IDKey() {
        return "entity_id";
    }

    static get nameKey() {
        return "entity_name";
    }

    static get startScoreKey() {
        return "start_score";
    }

    static get handicapKey() {
        return "handycap";
    }

    static get isPlayingKey() {
        return "is_playing";
    }

    static get resultKey() {
        return "result";
    }

    static get phaseResultKey() {
        return "entity_phase_result";
    }

    static get seriesKey() {
        return "seriesData";
    }

    static get maxSerieIDKey() {
        return "entity_phase_max_serie_id";
    }

    static get qualifiedForPhaseKey() {
        return "qualified_for_phase";
    }

    static get qualifiedFromPhaseKey() {
        return "qualified_from_phase"
    }

    static get qualifiedForPhaseNameKey() {
        return "qualified_for_phase_name";
    }

    static get qualifiedFromPhaseNameKey() {
        return "qualified_from_phase_name";
    }

    static get resultTiebreak1Key() {
        return "result_tiebreak1";
    }

    static get resultTiebreak2Key() {
        return "result_tiebreak2";
    }

    static get resultTiebreak3Key() {
        return "result_tiebreak3";
    }
    // #endregion

    // #region accessors/mutations 
    get ID() {
        return this._id;
    }

    set ID(ID) {
        this._id = ID;
    }

    get name() {
        return this._name;
    }

    set name(name) {
        this._name = name;
    }

    get startScore() {
        return this._startScore;
    }

    set startScore(startScore) {
        this._startScore = startScore;
    }

    get handicap() {
        return this._handicap;
    }

    set handicap(handicap) {
        this._handicap = handicap;
    }

    get isPlaying() {
        return this._isPlaying;
    }

    set isPlaying(isPlaying) {
        this._isPlaying = isPlaying;
    }

    get result() {
        return this._result;
    }

    set result(result) {
        this._result = result;
    }

    get phaseResult(){
        return this._phaseResult;
    }

    set phaseResult(phaseResult){
        this._phaseResult = phaseResult;
    }

    get maxSerieID() {
        return this._maxSerieID;
    }

    set maxSerieID(maxSerieID) {
        this._maxSerieID = maxSerieID;
    }

    /**
     *
     * @returns {[PhaseSerie]}
     */
    get series() {
        return this._series;
    }

    set series(series) {
        this._series = series;
    }

    get qualifiedForPhase() {
        return this._qualifiedForPhase;
    }

    set qualifiedForPhase(qualifiedForPhaseID) {
        this._qualifiedForPhase = qualifiedForPhaseID;
    }

    get qualifiedFromPhase() {
        return this._qualifiedFromPhase;
    }

    set qualifiedFromPhase(qualifiedFromPhaseID) {
        this._qualifiedFromPhase = qualifiedFromPhaseID;
    }

    get qualifiedFroPhaseName() {
        return this._qualifiedForPhaseName;
    }

    set qualifiedFroPhaseName(qualifiedForPhaseName) {
        this._qualifiedForPhaseName = qualifiedForPhaseName;
    }

    get qualifiedFromPhaseName() {
        return this._qualifiedFromPhaseName;
    }

    set qualifiedFromPhaseName(qualifiedFromPhaseName) {
        this._qualifiedFromPhaseName = qualifiedFromPhaseName;
    }

    get resultTiebreak1() {
        return this._resultTiebreak1;
    }

    set resultTiebreak1(resultTiebreak1) {
        this._resultTiebreak1 = resultTiebreak1;
    }

    get resultTiebreak2() {
        return this._resultTiebreak2;
    }

    set resultTiebreak2(resultTiebreak2) {
        this._resultTiebreak2 = resultTiebreak2;
    }

    get resultTiebreak3() {
        return this._resultTiebreak3;
    }

    set resultTiebreak3(resultTiebreak3) {
        this._resultTiebreak3 = resultTiebreak3;
    }
    //#endregion

    constructor(modelID?: string, modelData?: any) {
        super();
        this.series = {};
        this.parseMinifiedData(modelData);
        if(this.ID !== undefined) {
            const modelId = DataModel.generateModelID(this, {"id": this.ID});
            if(modelId !== false) {
                this.modelID = modelId
            }
        } /*else { // create default entity TODO: NOT SURE IF NEEDED
            this.ID = 0;
            this.startScore = 0;
            this.result = 0;
        }*/
    }

    // #region FieldMappings Getters/Setters

    dataModelName() {
        return PhaseEntityDataModel.MODEL_NAME
    }

    getKeyByMinifiedKey(minKey: string) {
        switch (minKey) {
            case "0":
                return PhaseEntityDataModel.IDKey;
            case "1":
                return PhaseEntityDataModel.nameKey;
            case "2":
                return PhaseEntityDataModel.startScoreKey;
            case "3":
                return PhaseEntityDataModel.handicapKey;
            case "4":
                return PhaseEntityDataModel.isPlayingKey;
            case "5":
                return PhaseEntityDataModel.resultKey;
            case "6":
                return PhaseEntityDataModel.phaseResultKey; //FIXME
            case "7":
                return PhaseEntityDataModel.maxSerieIDKey;
            case "8":
                return PhaseEntityDataModel.seriesKey;
            case "9":
                return PhaseEntityDataModel.qualifiedForPhaseKey;
            case "10":
                return PhaseEntityDataModel.qualifiedFromPhaseKey;
            case "11":
                return PhaseEntityDataModel.qualifiedForPhaseNameKey;
            case "12":
                return PhaseEntityDataModel.qualifiedFromPhaseNameKey;
            case "13":
                return PhaseEntityDataModel.resultTiebreak1Key;
            case "14":
                return PhaseEntityDataModel.resultTiebreak2Key;
            case "15":
                return PhaseEntityDataModel.resultTiebreak3Key;
            default: 
                return ""
        }
    }

    setProperty(propertyKey: string, value: any) {
        let seriesCollection
        switch (propertyKey) {
            case PhaseEntityDataModel.IDKey:
                this.ID = parseInt(value);
                break;
            case PhaseEntityDataModel.nameKey:
                this.name = value;
                break;
            case PhaseEntityDataModel.startScoreKey:
                this.startScore = parseInt(value);
                break;
            case PhaseEntityDataModel.handicapKey:
                this.handicap = parseInt(value);
                break;
            case PhaseEntityDataModel.isPlayingKey:
                this.isPlaying = value;
                break;
            case PhaseEntityDataModel.resultKey:
                this.result = value;
                break;
            case PhaseEntityDataModel.phaseResultKey:
                this.phaseResult = value;
                break;
            case PhaseEntityDataModel.maxSerieIDKey:
                this.maxSerieID = value;
                break;
            case PhaseEntityDataModel.seriesKey:
                seriesCollection = value;
                for(const serieID in seriesCollection) {
                    if(Object.prototype.hasOwnProperty.call(seriesCollection,serieID)) {
                        const serieData = seriesCollection[serieID];
                        if(typeof serieData === "object") {
                            serieData['id'] = serieID;
                            let entitySerieModel = null;
                            if(serieData instanceof DataModel) {
                                entitySerieModel = serieData;
                            } else {
                                entitySerieModel = DataModelMapper.createFromObject(serieData);
                            }
                            if(entitySerieModel instanceof PhaseSerieDataModel) {
                                if(this.series === undefined) {
                                    this.series = {}
                                }
                                this.series[serieID] = entitySerieModel;
                            }
                        }
                    }
                }
                break;
            case PhaseEntityDataModel.qualifiedForPhaseKey:
                this.qualifiedForPhase = value;
                break;
            case PhaseEntityDataModel.qualifiedFromPhaseKey:
                this.qualifiedFromPhase = value;
                break;
            case PhaseEntityDataModel.qualifiedForPhaseNameKey:
                this.qualifiedFroPhaseName = value;
                break;
            case PhaseEntityDataModel.qualifiedFromPhaseNameKey:
                this.qualifiedFromPhaseName = value;
                break;
            case PhaseEntityDataModel.resultTiebreak1Key:
                this.resultTiebreak1 = value;
                break;
            case PhaseEntityDataModel.resultTiebreak2Key:
                this.resultTiebreak2 = value;
                break;
            case PhaseEntityDataModel.resultTiebreak3Key:
                this.resultTiebreak3 = value;
                break;
        }
    }

    getProperty(propertyKey: string) {
        switch (propertyKey) {
            case PhaseEntityDataModel.IDKey:
                return this.ID;
            case PhaseEntityDataModel.nameKey:
                return this.name;
            case PhaseEntityDataModel.startScoreKey:
                return this.startScore;
            case PhaseEntityDataModel.handicapKey:
                return this.handicap;
            case PhaseEntityDataModel.isPlayingKey:
                return this.isPlaying;
            case PhaseEntityDataModel.resultKey:
                return this.result;
            case PhaseEntityDataModel.phaseResultKey:
                return this.phaseResult;
            case PhaseEntityDataModel.maxSerieIDKey:
                return this.maxSerieID;
            case PhaseEntityDataModel.seriesKey:
                return this.series;
            case PhaseEntityDataModel.qualifiedForPhaseKey:
                return this.qualifiedForPhase;
            case PhaseEntityDataModel.qualifiedFromPhaseKey:
                return this.qualifiedFromPhase;
            case PhaseEntityDataModel.qualifiedForPhaseNameKey:
                return this.qualifiedFroPhaseName;
            case PhaseEntityDataModel.qualifiedFromPhaseNameKey:
                return this.qualifiedFromPhaseName;
            case PhaseEntityDataModel.resultTiebreak1Key:
                return this.resultTiebreak1;
            case PhaseEntityDataModel.resultTiebreak2Key:
                return this.resultTiebreak2;
            case PhaseEntityDataModel.resultTiebreak3Key:
                return this.resultTiebreak3;
        }
    }
    
    //#endregion
}