import GenericDictionary from '@/components/util/GenericDictionary';
export const SNOOKER_RED = 'Snoo_R';
export const SNOOKER_YELLOW = 'Snoo_Y';
export const SNOOKER_GREEN = 'Snoo_G';
export const SNOOKER_BROWN = 'Snoo_Br';
export const SNOOKER_BLUE = 'Snoo_Blu';
export const SNOOKER_PINK = 'Snoo_P';
export const SNOOKER_BLACK = 'Snoo_Bla';
export const SNOOKER_FAULT4 = 'Snoo_F4';
export const SNOOKER_FAULT5 = 'Snoo_F5';
export const SNOOKER_FAULT6 = 'Snoo_F6';
export const SNOOKER_FAULT7 = 'Snoo_F7';
export const SNOOKER_POINT_TITLE_PAIRS: GenericDictionary<string> = {};
SNOOKER_POINT_TITLE_PAIRS[SNOOKER_RED] = 'Red';
SNOOKER_POINT_TITLE_PAIRS[SNOOKER_YELLOW] = 'Yellow';
SNOOKER_POINT_TITLE_PAIRS[SNOOKER_GREEN] = 'Green';
SNOOKER_POINT_TITLE_PAIRS[SNOOKER_BROWN] = 'Brown';
SNOOKER_POINT_TITLE_PAIRS[SNOOKER_BLUE] = 'Blue';
SNOOKER_POINT_TITLE_PAIRS[SNOOKER_PINK] = 'Pink';
SNOOKER_POINT_TITLE_PAIRS[SNOOKER_BLACK] = 'Black';
SNOOKER_POINT_TITLE_PAIRS[SNOOKER_FAULT4] = 'Fault 4';
SNOOKER_POINT_TITLE_PAIRS[SNOOKER_FAULT5] = 'Fault 5';
SNOOKER_POINT_TITLE_PAIRS[SNOOKER_FAULT6] = 'Fault 6';
SNOOKER_POINT_TITLE_PAIRS[SNOOKER_FAULT7] = 'Fault 7';
export type SnookerPointType = typeof SNOOKER_RED | typeof SNOOKER_YELLOW | typeof SNOOKER_GREEN | typeof SNOOKER_BROWN | typeof SNOOKER_BLUE | typeof SNOOKER_PINK | typeof SNOOKER_BLACK | typeof SNOOKER_FAULT4 | typeof SNOOKER_FAULT5 | typeof SNOOKER_FAULT6 | typeof SNOOKER_FAULT7;
export const SNOOKER_IS_POSITIVE_POINT: GenericDictionary<boolean> = {};
SNOOKER_IS_POSITIVE_POINT[SNOOKER_RED] = true;
SNOOKER_IS_POSITIVE_POINT[SNOOKER_YELLOW] = true;
SNOOKER_IS_POSITIVE_POINT[SNOOKER_GREEN] = true;
SNOOKER_IS_POSITIVE_POINT[SNOOKER_BROWN] = true;
SNOOKER_IS_POSITIVE_POINT[SNOOKER_BLUE] = true;
SNOOKER_IS_POSITIVE_POINT[SNOOKER_PINK] = true;
SNOOKER_IS_POSITIVE_POINT[SNOOKER_BLACK] = true;
SNOOKER_IS_POSITIVE_POINT[SNOOKER_FAULT4] = false;
SNOOKER_IS_POSITIVE_POINT[SNOOKER_FAULT5] = false;
SNOOKER_IS_POSITIVE_POINT[SNOOKER_FAULT6] = false;
SNOOKER_IS_POSITIVE_POINT[SNOOKER_FAULT7] = false;
export const SNOOKER_POINT_INCREMENT: GenericDictionary<number> = {};
SNOOKER_POINT_INCREMENT[SNOOKER_RED] = 1;
SNOOKER_POINT_INCREMENT[SNOOKER_YELLOW] = 2;
SNOOKER_POINT_INCREMENT[SNOOKER_GREEN] = 3;
SNOOKER_POINT_INCREMENT[SNOOKER_BROWN] = 4;
SNOOKER_POINT_INCREMENT[SNOOKER_BLUE] = 5;
SNOOKER_POINT_INCREMENT[SNOOKER_PINK] = 6;
SNOOKER_POINT_INCREMENT[SNOOKER_BLACK] = 7;
SNOOKER_POINT_INCREMENT[SNOOKER_FAULT4] = 4;
SNOOKER_POINT_INCREMENT[SNOOKER_FAULT5] = 5;
SNOOKER_POINT_INCREMENT[SNOOKER_FAULT6] = 6;
SNOOKER_POINT_INCREMENT[SNOOKER_FAULT7] = 7;
export const frameEnd = 'Snoo_FE';
export const matchEnd = 'Snoo_ME';
export const turnChange = 'Snoo_TC';
export const breakingChange = 'Snoo_BC';
export const SNOOKER_GAME_CONTROL_TYPES: GenericDictionary<string> = {};
SNOOKER_GAME_CONTROL_TYPES[frameEnd] = 'End of Frame';
SNOOKER_GAME_CONTROL_TYPES[matchEnd] = 'End of Match';
SNOOKER_GAME_CONTROL_TYPES[turnChange] = 'Change Turn';
SNOOKER_GAME_CONTROL_TYPES[breakingChange] = 'Change Breaking';
export type SnookerGameControlType = typeof frameEnd | typeof matchEnd | typeof turnChange | typeof breakingChange;