import GenericDictionary from "@/components/util/GenericDictionary";
import { PagedResponse, PaginatedItemsStoreGetters, PaginatedItemsStoreMutations } from "@/composables/common/Pagination";
import authModule from "@/main";
import { Sport } from "@/models/main/CommonTypes";
import { Organization } from "@/models/main/Organization";
import Season from "@/models/main/Season";
import { IRootState } from "@/store";
import { ActionContext, Module } from "vuex";

const state: OrganizationSeasonStore = {
    paginatedItems: {
        items: {},
        pageSize: 30,
        currentPage: 1,
    }
};
export interface OrganizationSeasonStore {
    paginatedItems: PagedResponse<Season>,
    searchedTeam?: string,
};

const getters = {
    getOrganizationSeasons: (state: OrganizationSeasonStore) => (organizationId?: number, searchTerm?: string) => {
        let filteredOrganizationSeasons: Array<Season> = [];
        const lowerCaseSearchTerm = searchTerm?.toLowerCase().toLowerCase();
        for (const id in state.paginatedItems.items) {
            if (Object.prototype.hasOwnProperty.call(state.paginatedItems.items, id)) {
                const organizationSeason: Season = state.paginatedItems.items[id] as Season;
                const seasonNameLowerCased = organizationSeason.name.toLowerCase();
                const searchedTeamIsOk = lowerCaseSearchTerm === undefined || seasonNameLowerCased.includes(lowerCaseSearchTerm);
                const organizationIsOk = organizationId == undefined || organizationId == organizationSeason.organizationId
                if (searchedTeamIsOk && organizationIsOk) {
                    filteredOrganizationSeasons.push(organizationSeason);
                }
            }
        }
        filteredOrganizationSeasons = filteredOrganizationSeasons.sort((a, b) => {
            // const startTimeCompare = (new Date(a.startTime ?? 0).getMilliseconds() ?? 0) - (new Date(b.startTime ?? 0).getMilliseconds() ?? 0)
            const startTimeCompare = Number(a.startTime) - Number(b.startTime)
            if(startTimeCompare == 0) {
                return a.name.localeCompare(b.name)
            }

            return startTimeCompare;
        });
        return filteredOrganizationSeasons;
    },
    getOrganizationSeason: (state: OrganizationSeasonStore) => (organizationSeasonId: number) => {
        return state.paginatedItems.items[organizationSeasonId];
    },
    ...PaginatedItemsStoreGetters(),
}

const mutations = {
    addOrganizationSeason(state: OrganizationSeasonStore, organizationSeason: Season) {
        state.paginatedItems.items[organizationSeason.id] = organizationSeason;
    },
    editOrganizationSeason(state: OrganizationSeasonStore, organizationSeason: Season) {
        state.paginatedItems.items[organizationSeason.id] = organizationSeason;   
    },
    removeOrganizationSeason(state: OrganizationSeasonStore, organizationSeasonId: number) {
        if (Object.prototype.hasOwnProperty.call(state.paginatedItems.items, organizationSeasonId)) {
            delete state.paginatedItems.items[organizationSeasonId];
        }
    },
    ...PaginatedItemsStoreMutations(),
}

const actions = {
    addOrganizationSeason({ commit }: ActionContext<OrganizationSeasonStore, IRootState>, organizationSeason: Season) {
        commit('addOrganizationSeason', organizationSeason);
    },
    createOrganizationSeason({ dispatch }: ActionContext<OrganizationSeasonStore, IRootState>, organizationSeason: Season) {
        return authModule.post(`/api/organization/${organizationSeason.organization.id}/seasons`, organizationSeason)
            .then((response) => {
                const organizationSeason: Season = response.data as unknown as Season;
                dispatch('addOrganizationSeason', organizationSeason);
                return organizationSeason;
            });
    },
    editOrganizationSeason({ commit }: ActionContext<OrganizationSeasonStore, IRootState>, organizationSeason: Season) {
        return authModule.patch(`/api/organization/${organizationSeason.organization.id}/seasons/${organizationSeason.id}`, organizationSeason)
            .then((response) => {
                const organizationSeason: Season = response.data as unknown as Season;
                commit('editOrganizationSeason', organizationSeason);
                return organizationSeason;
            });
    },
    deleteOrganizationSeason({ commit }: ActionContext<OrganizationSeasonStore, IRootState>, organizationSeason: Season) {
        return authModule.delete(`/api/organization/${organizationSeason.organization.id}/seasons/${organizationSeason.id}`)
            .then((response) => {
                const deletedOrganizationSeasonId: number = response.data as unknown as number;
                commit('removeOrganizationSeason', deletedOrganizationSeasonId);
            });
    },
    fetchOrganizationSeasons({ commit, dispatch, getters }: ActionContext<OrganizationSeasonStore, IRootState>, organizationId: number ) {
        let filtersChanged = false;
        let pageToRequest = getters['getNextPage'];
        const pageSize = getters['getPageSize'];
        if (filtersChanged) {
            pageToRequest = 1;
        }
        if (!filtersChanged && getters['getNextPage'] === 1 && getters['getCurrentPage'] > 1) {
            return;
        }
        return authModule.get(`/api/organization/${organizationId}/seasons`, {
            params: {
                page: pageToRequest,
                pageSize: pageSize,
            },
        })
        .then((response) => {
            const pagedResponse: PagedResponse<Season> = response.data as unknown as PagedResponse<Season>;
            commit('setCurrentPage', pagedResponse.currentPage);
            commit('setPageSize', pagedResponse.pageSize);
            commit('setNextPage', pagedResponse.nextPage);
            commit('setPreviousPage', pagedResponse.previousPage);
            for (const i in pagedResponse.items) {
                dispatch('addOrganizationSeason', pagedResponse.items[i]);
            }
        })
        .catch((error) => {
            console.error(error);
        });
    },
}

const organizationSeasonStore: Module<OrganizationSeasonStore, IRootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
export default organizationSeasonStore;