<template>
    <div v-if="getCurrentRankingPageInfo" class="table-wrapper">
        <table class="table table-condensed">
            <thead>
                <tr>
                    <th>#</th>
                    <th class="ranking-player-name">Participant</th>
                    <th style="display:none" v-if="localPhaseRanking?.details.isMaxSeriesOnly === 1">Country</th>
                    <th v-if="localPhaseRanking?.details.isMaxSeriesOnly === 1">Sqs</th>
                    <th v-if="getCurrentRankingPageInfo?.hasStartScore">Start</th>
                    <th v-for="index in localPhaseRanking?.details.maxSeriesGames" :key="index">{{index}}</th>
                    <th v-if="getCurrentRankingPageInfo?.hasHandicap">Hcp</th>
                    <th>Total</th>
                    <th>Avg</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(singleEntity, i) in singleEntitiesToDisplay" :key="i"> 
                    <tr class="pos-row">
                        <td class="pos-cell" 
                        :class="{'multi-rows': localPhaseRanking?.details.isMaxSeriesOnly !== 1 && getCurrentRankingPageInfo?.maxSeriesForPlayer > 1}"
                        :rowspan="localPhaseRanking?.details.isMaxSeriesOnly !== 1 && getCurrentRankingPageInfo?.maxSeriesForPlayer > 1 ? singleEntity.seriesCount + 2 : 1"
                        >{{singleEntity.position}}</td>
                        <td class="ranking-player-name" :colspan="localPhaseRanking?.details.isMaxSeriesOnly !== 1 && getCurrentRankingPageInfo?.maxSeriesForPlayer > 1 ? 
                            localPhaseRanking?.details.maxSeriesGames + 3 + (getCurrentRankingPageInfo?.hasHandycap ? 1 : 0) + (getCurrentRankingPageInfo?.hasStartScore ? 1 : 0) : 1"
                        >{{singleEntity.name}}</td>
                        <td style="display:none" v-if="localPhaseRanking?.details.isMaxSeriesOnly === 1">{{singleEntity.country}}</td>
                        <td v-if="localPhaseRanking?.details.isMaxSeriesOnly === 1">{{singleEntity.seriesCount}}</td>
                        <td v-if="getCurrentRankingPageInfo?.hasStartScore">{{singleEntity?.startScore}}</td>
                        <template v-if="localPhaseRanking?.details.isMaxSeriesOnly === 1 || getCurrentRankingPageInfo?.maxSeriesForPlayer === 1">
                            <td v-for="(game, gIndex) in singleEntity.series[0].games" :key="gIndex" :class="{ 'predicted-score' : game.isPredicted}">{{game.text}}</td>
                            <td v-if="getCurrentRankingPageInfo?.hasHandicap">{{ (singleEntity.handicap === 0 ? '' : singleEntity.handicap ) ?? ''}}</td>
                            <td :class="{'predicted-score' : singleEntity.hasPredictedScore}">{{singleEntity.totalResult}}</td>
                            <td :class="{'predicted-score' : singleEntity.hasPredictedScore}">{{singleEntity.average.toFixed(1)}}</td>
                        </template>
                    </tr>
                    <template v-if="localPhaseRanking?.details.isMaxSeriesOnly !== 1 && getCurrentRankingPageInfo?.maxSeriesForPlayer > 1">
                            <tr v-for="(serie, serieIndex) in singleEntity.series" :key="serieIndex">
                                <td>{{serie.name}}</td>
                                <td v-if="getCurrentRankingPageInfo?.hasStartScore">-</td>
                                <td v-for="(game, gIndex) in serie.games" :key="gIndex" :class="{ 'predicted-score' : game.isPredicted}">{{game.text}}</td>
                            </tr>
                            <tr class="total-row">
                                <td>Total</td>
                                <td v-if="getCurrentRankingPageInfo?.hasStartScore">{{singleEntity.startScore}}</td>
                                <td :colspan="localPhaseRanking?.details.maxSeriesGames + (getCurrentRankingPageInfo?.hasHandicap ? 1 : 0)"></td>
                                <td :class="{'predicted-score' : singleEntity.hasPredictedScore}">{{singleEntity.totalResult}}</td>
                                <td :class="{'predicted-score' : singleEntity.hasPredictedScore}">{{singleEntity.average.toFixed(1)}}</td>
                            </tr>
                    </template>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script lang="ts">
import PhaseRankingModel from "@/composables/dataModels/bowling/PhaseRankingDataModel";
import useParsePhaseRankingForRender from "@/composables/bowling/UseParsePhaseRankingForRender";
import { computed, defineComponent, onMounted, PropType, ref, Ref, watch } from "vue";

export default defineComponent ({ 
    props: {
        phaseRanking: {
            type: Object as PropType<PhaseRankingModel> | null,
            required: false,
        }
    },
    setup(props) {
        const pageNumber: Ref<number> = ref(0)
        const pageSize: Ref<number> = ref(20)
        const localPhaseRanking: Ref<PhaseRankingModel | undefined> = ref()
        onMounted(() => {
            if(props.phaseRanking) {
                localPhaseRanking.value = props.phaseRanking
                changeRankingPage()
            }
        })
        watch(props, (newProps) => {
            if(newProps.phaseRanking) {
                localPhaseRanking.value = newProps.phaseRanking
                changeRankingPage()
            }
        })

        const {
            getCurrentRankingPageInfo
            } = useParsePhaseRankingForRender(localPhaseRanking, pageNumber, pageSize)
        
        let refreshTimeout: any | null = null
        const changeRankingPage = () => {
            if(!getCurrentRankingPageInfo.value || localPhaseRanking.value?.entities === undefined) {
                return 
            }

            if (getCurrentRankingPageInfo.value.playersCountPerEntity > 1) {
                let suitablePageSize = 24 / (getCurrentRankingPageInfo.value.playersCountPerEntity + 2);
                if(suitablePageSize > 0) {
                    pageSize.value = suitablePageSize;
                }
            }

            //PhaseRankingPanel.colorRows($table, legend);
            //PhaseRankingPanel.renderLegend($legendContainer, legend);

            if (localPhaseRanking.value.entities.length > (pageNumber.value + 1) * pageSize.value) { // we have entities to show
                pageNumber.value++;
            } else {
                pageNumber.value = 0; // reset
            }
            if(refreshTimeout !== null) {
                clearTimeout(refreshTimeout)
                refreshTimeout = null
            }
            refreshTimeout = setTimeout(() => {
                changeRankingPage();
            }, 10000);
        }

        const singleEntitiesToDisplay = computed(() => {
            if(localPhaseRanking.value === undefined 
            || getCurrentRankingPageInfo.value === undefined 
            || localPhaseRanking.value.entities === undefined 
            || localPhaseRanking.value.details === undefined) {
                return
            }

            const phaseRanking = localPhaseRanking.value.entities;
            const phaseDetails = localPhaseRanking.value.details;
            const maxGamesPerSeries = phaseDetails.maxSeriesGames ? phaseDetails.maxSeriesGames : 0;
            const limit = (pageNumber.value * pageSize.value) + pageSize.value;
            type DisplayGameModel = { score: number, isPredicted: boolean, currentFrame?: number, text: string }
            type DisplaySerieModel = { hasPredictedGame: boolean, games: Array<DisplayGameModel>, name: string, startScore?: number | null, handicap?: number | null, totalScore: number, average: number }
            type SingleEntityDisplayModel = { position: number, name: string, startScore?: number, hasPredictedScore: boolean, handicap?: number, totalResult: number, average: number, seriesCount: number, country: string, series: Array<DisplaySerieModel> }

            const entitiesToDisplay: Array<SingleEntityDisplayModel> = []

            for (let i = pageNumber.value * pageSize.value; i < limit && i < phaseRanking.length; i++) {
                const entity = phaseRanking[i];
                let currentEntitySeriesCount = 0;

                const entityGames = [];
                const displaySerieModels: Array<DisplaySerieModel> = []
                let entityNonZeroGames = 0;
                let playerCountry: string | undefined;
                let hasPredictedScore = false
                if (entity.series) {
                    if (phaseDetails.isMaxSeriesOnly === 1) { // no serie info
                        for (let serieId in entity.series) {
                            if (Object.prototype.hasOwnProperty.call(entity.series,serieId) && !isNaN(parseInt(serieId))) {
                                currentEntitySeriesCount++;
                            }
                        }

                        const maxSerieId = entity.maxSerieID;
                        if (maxSerieId !== undefined && Object.prototype.hasOwnProperty.call(entity.series, maxSerieId?.toString()) && !isNaN(maxSerieId)) {
                            const players = entity.series[maxSerieId].players;
                            for (const playerId in players) {
                                const player = players[playerId];
                                playerCountry = player.country;
                                let playerTotalScore = 0;
                                let playerNonZeroGames = 0;
                                console.log(playerTotalScore, playerNonZeroGames)
                                const games = player.games;
                                if(games) {
                                for (let j = 0; j < maxGamesPerSeries; j++) {
                                    if (games[j]) {
                                        if (!entityGames[j]) {
                                            entityGames[j] = {
                                                "predicted": games[j].isPredicted ?? false,
                                                "score": games[j].score ?? 0,
                                                "current_frame": games[j].currentFrame ?? 0
                                            };
                                        } else {
                                            entityGames[j]["score"] += games[j].score ?? 0;
                                            if (!entityGames[j]["predicted"]) {
                                                entityGames[j]["predicted"] = games[j].isPredicted ?? false;
                                            }
                                        }
                                        if ((games[j].score ?? 0) > 0) {
                                            playerTotalScore += games[j].score ?? 0;
                                            playerNonZeroGames++;
                                        }
                                    }
                                }
                                }

                            }
                        }

                    } else { // series are summed, looks like the view of doubles and teams
                        for (const serieId in entity.series) {
                            if (Object.prototype.hasOwnProperty.call(entity.series, serieId) && !isNaN(parseInt(serieId))) {
                                currentEntitySeriesCount++;

                                const serieName = entity.series[serieId].squadName;
                                const displaySerieModel: DisplaySerieModel = {
                                    name: serieName ?? "Unset Name",
                                    games: [],
                                    totalScore: 0,
                                    average: 0.0,
                                    hasPredictedGame: false
                                }

                                if (getCurrentRankingPageInfo.value.hasStartScore) {
                                    displaySerieModel.startScore = null
                                }
                                
                                const players = entity.series[serieId]["players"];
                                for (const playerId in players) {
                                    hasPredictedScore = false;
                                    const player = players[playerId];
                                    playerCountry = player.country;
                                    let playerTotalScore = 0;
                                    let playerNonZeroGames = 0;
                                    const games = player.games;
                                    if(games) {
                                        for (let j = 0; j < maxGamesPerSeries; j++) {
                                            if (games[j]) {
                                                if (!entityGames[j]) {
                                                    entityGames[j] = {
                                                        "predicted": games[j].isPredicted ?? false,
                                                        "score": games[j].score ?? 0,
                                                        "current_frame": games[j].currentFrame ?? 0
                                                    };
                                                } else {
                                                    entityGames[j]["score"] += games[j].score;
                                                    if (!entityGames[j]["predicted"]) {
                                                        entityGames[j]["predicted"] = games[j].isPredicted;
                                                    }
                                                }

                                                if (games[j].score > 0) {
                                                    playerTotalScore += games[j].score;
                                                    playerNonZeroGames++;
                                                }

                                                const gameIsPredicted: boolean = games[j].isPredicted
                                                const displayGameModel: DisplayGameModel = {
                                                    score: games[j].score,
                                                    isPredicted: gameIsPredicted,
                                                    text: games[j].score.toString()
                                                }

                                                if (gameIsPredicted) {
                                                    hasPredictedScore = true;
                                                    if ((games[j].currentFrame ?? 0) > 0) {
                                                        displayGameModel.currentFrame = games[j].currentFrame
                                                        displayGameModel.text = games[j].score + " (" + games[j].currentFrame + ")"
                                                    }
                                                }
                                                displaySerieModel.games.push(displayGameModel)
                                            } else {
                                                const emptyDisplayGameModel: DisplayGameModel = {
                                                    score: 0,
                                                    isPredicted: false,
                                                    text: "-",
                                                }
                                                displaySerieModel.games.push(emptyDisplayGameModel)
                                            }
                                        }
                                    }

                                    if (getCurrentRankingPageInfo.value.hasHandicap) {
                                        displaySerieModel.handicap = (entity.handicap ?? 0) > 0 ? entity.handicap : null 
                                    }
                                    
                                    displaySerieModel.totalScore = playerTotalScore
                                    displaySerieModel.average = playerNonZeroGames === 0 ? 0 : (playerTotalScore / playerNonZeroGames)
                                    entityNonZeroGames += playerNonZeroGames;
                                    if (hasPredictedScore) {
                                        displaySerieModel.hasPredictedGame = true
                                    }
                                }

                                displaySerieModels.push(displaySerieModel)
                            }
                        }
                    }
                } else {
                    continue;
                }

                //const rowClass = (entity.qualifiedForPhase ? phasePageInfo.legend.for[entity.qualifiedForPhase].rowClass : '');//+ " "
                //+ (entity.qualified_from_phase ? legend.from[entity.qualified_from_phase].rowClass : '');
                //var $row = $("<tr>", {"class": rowClass + " pos-row"});

                const singleEntityDisplayModel: SingleEntityDisplayModel = {
                    position: i + 1,
                    name: entity.name ?? "Unset Name",
                    series: displaySerieModels,
                    totalResult: entity.result ?? 0,
                    average: entityNonZeroGames === 0 ? 0.0 : ((entity.result ?? 0) - (entity.startScore ? entity.startScore : 0)) / entityNonZeroGames,
                    hasPredictedScore: false,
                    country: playerCountry ?? 'Unset',
                    seriesCount: currentEntitySeriesCount
                }
                if (phaseDetails.isMaxSeriesOnly !== 1 && getCurrentRankingPageInfo.value.maxSeriesForPlayer > 1) { // should show series info
                    if (getCurrentRankingPageInfo.value.hasStartScore) {
                        singleEntityDisplayModel.startScore = entity.startScore
                    }

                    if (hasPredictedScore) {
                        singleEntityDisplayModel.hasPredictedScore = true
                    }

                    entitiesToDisplay.push(singleEntityDisplayModel)
                } else {
                    const maxSerieDisplayModel: DisplaySerieModel = {
                        hasPredictedGame: false,
                        games: [],
                        name: "",
                        totalScore: 0,
                        average: 0.0,
                    }
                    entityNonZeroGames = 0;
                    hasPredictedScore = false;
                    for (let k = 0; k < maxGamesPerSeries; k++) {
                        if (Object.prototype.hasOwnProperty.call(entityGames, k)) {
                            if (entityGames[k]["score"] > 0) {
                                entityNonZeroGames++;
                            }

                            const gameIsPredicted = entityGames[k]["predicted"]
                            const displayGameModel: DisplayGameModel = {
                                score: entityGames[k]["score"],
                                isPredicted: gameIsPredicted,
                                text: entityGames[k]["score"].toString()
                            }

                            if (gameIsPredicted) {
                                hasPredictedScore = true;
                                if (entityGames[k]["current_frame"] > 0) {
                                    displayGameModel.currentFrame = entityGames[k]["current_frame"]
                                    displayGameModel.text = entityGames[k]["score"] + " (" + entityGames[k]["current_frame"] + ")"
                                }
                            }
                            if(!maxSerieDisplayModel.hasPredictedGame && hasPredictedScore) {
                                maxSerieDisplayModel.hasPredictedGame = hasPredictedScore
                            }

                            maxSerieDisplayModel.games.push(displayGameModel)
                        } else {
                            const emptyDisplayGameModel: DisplayGameModel = {
                                score: 0,
                                isPredicted: false,
                                text: "-",
                            }
                            maxSerieDisplayModel.games.push(emptyDisplayGameModel)
                        }

                    }

                    if (getCurrentRankingPageInfo.value.hasStartScore) {
                        singleEntityDisplayModel.startScore = entity.startScore
                    }
                    if (getCurrentRankingPageInfo.value.hasHandicap) {
                        singleEntityDisplayModel.handicap = entity.handicap
                    }
                    if (hasPredictedScore) {
                        singleEntityDisplayModel.hasPredictedScore = true
                    }

                    singleEntityDisplayModel.average = entityNonZeroGames === 0 ? 0.0 : ((entity.result ?? 0 ) - (entity.startScore ? entity.startScore : 0)) / entityNonZeroGames
                    singleEntityDisplayModel.series = [ maxSerieDisplayModel ]
                }

                entitiesToDisplay.push(singleEntityDisplayModel)
            }

            return entitiesToDisplay
        })
        return {
            getCurrentRankingPageInfo,
            localPhaseRanking,
            singleEntitiesToDisplay
        }
    }
})
</script>

<style>
    .entity-title, tr > td:nth-child(3) {
        text-align: left;
    }

    .pos-cell, tr > td:nth-child(4), tr > td:nth-child(5), tr > td:nth-child(6), tr > td:nth-child(7),
    tr > td:nth-child(8), tr > td:nth-child(9), tr > td:nth-child(10), tr > td:nth-child(11), tr > td:nth-child(12),
    tr > th:nth-child(4), tr > th:nth-child(5), tr > th:nth-child(6), tr > th:nth-child(7), tr > th:nth-child(8),
    tr > th:nth-child(9), tr > th:nth-child(10), tr > th:nth-child(11), tr > th:nth-child(12) {
        text-align: right;
    }

    tr > td:nth-child(3) {
        width: 45px;
    }
    .legend-container > div {
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 12px;
    }

    .legend-color-box {
        width: 10px;
        height: 8px;
        margin-right: 3px;
        margin-bottom: 2px;
        border: 1px outset #B3B3B3;
    }

    .bowling-watch-wrapper .table-wrapper {
        display: inline-block;
        padding-top: 2ch;
        max-height: 467px;
        font-size: 0.85em;
        min-height: 47vh;
    }

    .bowling-watch-wrapper .ranking-player-name {
        text-align: left;
        white-space: nowrap;
        max-width: 18ch;
        overflow: hidden;
        text-overflow: clip;
    }

    .predicted-score {
        color: #3ABCDF;
    }
</style>