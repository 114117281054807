import { Container } from "@/models/main/Container";
import { EventType, Event } from "@/models/main/Event";
import { key } from "@/store";
import { Ref, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";

export type ApiAction = { action: () => Promise<any>, order: number, event?: Event }
export default function userContainerOfflineEventManipulations(match: Ref<Container | undefined>, accessKey?: string) {
    const store = useStore(key);
    const tempEventId: Ref<number> = ref(5000) //TODO: Need a better way to come up with non-coliding fake id
    const apiStoreActionsQueue: Ref<ApiAction[]> = ref([])
    const apiRemoveActionsQueue: Ref<ApiAction[]> = ref([])
    const apiActionOrder = ref(0);
    const saveRetryWaitTime = 1000;
    let timeoutObj: any = null;
    const pushChangesToAPI = true; // for debug purposes
    const createEvent = (containerId: number, type: EventType, entityId: number, description: string, playerId?: number, eventToCopy?: Event): Event => {
        const currentTimeStamp = new Date()
        const event: Event = {
            id: eventToCopy?.id ?? tempEventId.value++,
            type: type,
            entityId: entityId,
            playerId: playerId ?? null,
            description: description,
            containerId: containerId,
            startTimestamp: eventToCopy?.startTimestamp ?? currentTimeStamp,
            endTimestamp: eventToCopy?.endTimestamp ?? currentTimeStamp
        }

        return event;
    }

    const addEvent = async (newEvent: Event): Promise<boolean> => {
        const result = addEventToActionsQueue(newEvent)
        if(timeoutObj === null) {
            timeoutObj = setTimeout(tryStoreEventsQueue, saveRetryWaitTime)
        }

        return result
    }

    const editEvent = async (event: Event) => {
        if(event.id !== undefined) {
            let patchEvent = () => {
                return store.dispatch('containerEventStore/patchEvent', {
                    event
                });
            }
            if(accessKey !== undefined) {
                patchEvent = () => {
                    return store.dispatch('containerEventStore/patchEventAsGuest', {
                        event,
                        accessKey,
                    });
                }
            }
            
            apiStoreActionsQueue.value.push({ action: patchEvent, event: event, order: apiActionOrder.value++})
            store.commit('containerEventStore/editEvent', event) // replacing event right away before sending for API store
            if(timeoutObj === null) {
                timeoutObj = setTimeout(tryStoreEventsQueue, saveRetryWaitTime)
            }
            return true
        }

        return false
    }

    const addEventToActionsQueue = (event: Event) => {
        if(event && event.id !== undefined) {
            let storeEvent = () => {
                return store.dispatch('containerEventStore/storeEvent', {
                    event
                });
            }
            if(accessKey !== undefined) {
                storeEvent = () => {
                    return store.dispatch('containerEventStore/storeEventAsGuest', {
                        event,
                        accessKey,
                    });
                }
            }
            
            apiStoreActionsQueue.value.push({ action: storeEvent, event: event, order: apiActionOrder.value++})
            store.commit('containerEventStore/addEvent', event) // adding event right away before sending for API store
            return true
        }

        return false
    }

    const addRemoveEventToActionsQueue = (eventId: number) => {
        store.commit('containerEventStore/removeEvent', eventId)
        let removeLastEventAction = () => {
            return store.dispatch('containerEventStore/removeEvent', eventId)
        }
        if(accessKey !== undefined) {
            removeLastEventAction = () => {
                return store.dispatch('containerEventStore/removeEventAsGuest', {
                    eventId: eventId,
                    accessKey,
                });
            }
        }

        apiRemoveActionsQueue.value.push({ action: removeLastEventAction, order: apiActionOrder.value++})
        if(timeoutObj === null) {
            timeoutObj = setTimeout(tryStoreEventsQueue, saveRetryWaitTime)
        }
    }

    const addRemoveLastEventToActionsQueue = (containerId: number) => {
        if(apiStoreActionsQueue.value.length > 0) { // just need to remove from pending/temp ones
            const lastAction = apiStoreActionsQueue.value.pop()
            if(lastAction && lastAction.event) {
                store.commit('containerEventStore/removeEvent', lastAction.event.id)
                return true
            }
        } else { // we have no pending - store events so we need to queue remove last event from container action
            const lastEvent: Event | undefined = store.getters['containerEventStore/getLastEvent'](containerId)
            if(lastEvent && lastEvent.id) {
                addRemoveEventToActionsQueue(lastEvent.id)
                return true
            }

            return false
        }
    }

    const getAllActionsOrdered = () => {
        let allActionsOrdered: Array<ApiAction & { isStore: boolean, originalIndex: number }> = []
        for(let i = 0; i < apiStoreActionsQueue.value.length; i++) { 
            const currentApiAction = apiStoreActionsQueue.value[i]
            allActionsOrdered.push(
                {
                    action: currentApiAction.action, 
                    isStore: true, 
                    originalIndex: i,
                    order: currentApiAction.order,
                    event: currentApiAction.event
                });
        }
        for(let i = 0; i < apiRemoveActionsQueue.value.length; i++) {
            const currentApiAction = apiRemoveActionsQueue.value[i]
            allActionsOrdered.push(
                {
                    action: currentApiAction.action, 
                    isStore: false, 
                    originalIndex: i,
                    order: currentApiAction.order
                });
        }
        allActionsOrdered = allActionsOrdered.sort( (a, b) => {
            return a.order - b.order
        })

        return allActionsOrdered
    }

    let retriesAmount: number = 0
    const maxRetriesAmount: number = 45
    const tryStoreEventsQueue = async () => {
        if(retriesAmount > maxRetriesAmount) {
            timeoutObj = null
            retriesAmount = 0
            return
        }

        const allActionsOrdered = getAllActionsOrdered()
        const succesfullApiStoreActions: number[] = []
        const succesfullApiRemoveActions: number[] = []
        for(let i = 0; i < allActionsOrdered.length; i++) {
            const apiAction = allActionsOrdered[i];
            let apiActionResult = false
            if(pushChangesToAPI) {
                const apiActionPromise = apiAction.action()
                apiActionResult = await apiActionPromise
            } else {
                apiActionResult = true
            }
            if(apiActionResult) { 
                if(apiAction.isStore) {
                    succesfullApiStoreActions.push(apiAction.originalIndex)
                } else {
                    succesfullApiRemoveActions.push(apiAction.originalIndex)
                }
            }
        }

        for(let i = 0; i < succesfullApiStoreActions.length; i++) {
            const indexToRemove = succesfullApiStoreActions[i]
            apiStoreActionsQueue.value.splice(indexToRemove - i, 1)
        }
        for(let i = 0; i < succesfullApiRemoveActions.length; i++) {
            const indexToRemove = succesfullApiRemoveActions[i]
            apiRemoveActionsQueue.value.splice(indexToRemove - i, 1)
        }

        createOfflineActionsCsv()
        if(apiStoreActionsQueue.value.length > 0 || apiRemoveActionsQueue.value.length > 0) {
            timeoutObj = setTimeout(tryStoreEventsQueue, saveRetryWaitTime * ++retriesAmount)
        } else {
            timeoutObj = null
            retriesAmount = 0
        }
    }
    const warnIfPendingApiActions = (event: any) => {
        if(apiStoreActionsQueue.value.length > 0 || apiRemoveActionsQueue.value.length > 0) {
            event.preventDefault()
            return "There are still api actions being executed!"
        }
    }
    onMounted(() => {
        window.addEventListener("beforeunload", warnIfPendingApiActions);
    })

    onUnmounted(() => {
        window.removeEventListener("beforeunload", warnIfPendingApiActions);
    })

    const offlineDataDownloadLinkUri: Ref<string> = ref("")
    const createOfflineActionsCsv = () => {
        const headerRow = ["action", "container", "event", "type", "entity", "player", "description", "startTimestamp", "endTimestamp"]
        const rows: Array<Array<any>> = [headerRow];
        const allActionsOrdered = getAllActionsOrdered()
        let csvContent = ""
        if(allActionsOrdered.length > 0) {
            for(let i = 0; i < allActionsOrdered.length; i++) {
                const apiAction = allActionsOrdered[i];
                const actionRow: Array<any> = 
                [
                    apiAction.isStore ? "storeEvent" : "removeEvent",
                    match.value?.id,
                    apiAction.event?.id,
                    apiAction.event?.type,
                    apiAction.event?.entityId,
                    apiAction.event?.playerId,
                    apiAction.event?.description,
                    apiAction.event?.startTimestamp,
                    apiAction.event?.endTimestamp
                ]

                rows.push(actionRow)
            }
            csvContent = "data:text/csv;charset=utf-8," 
                + rows.map(e => e.join(",")).join("\r\n");
        }

        offlineDataDownloadLinkUri.value = encodeURI(csvContent);
    }

    return {
        offlineDataDownloadLinkUri,
        addEvent,
        editEvent,
        createEvent,
        addRemoveEventToActionsQueue,
        addRemoveLastEventToActionsQueue,
        tryStoreEventsQueue,
    }
}