<template>
    <TheOrganizerDashboard :onUserLoad="onUserLoaded">
        <template #heading>
            <router-link :to="{ name: 'Organizations' }">
                <div class="page-heading">
                    Organizations
                </div>
            </router-link>
            <router-link :to="{ name: 'Organization Menu', params: { id: organization?.id } }">
                <div class="page-heading">
                    {{ organization?.name }}
                </div>
            </router-link>
            <router-link :to="{ name: 'Organization Properties', params: { id: organization?.id } }">
                <div class="page-heading">
                    Properties
                </div>
            </router-link>
        </template>
        <template #default>
            <div class="organization-properties-wrapper" v-if="organization">
                <div class="organization-properties-left">
                    <div class="org-logo-edit-wrapper">
                        <label for="org-logo">
                            <div class="org-default-logo">       
                                <img v-show="!showDefaultUploadLogoImg" :src="imageUrl" :alt="organization.name" @error="showDefaultUploadLogoImg = true"/>
                                <img src="/grey-camera.svg" alt="Logo" v-show="showDefaultUploadLogoImg"/>                         
                            </div>
                        </label>
                        <input v-show="false" type="file" id="org-logo" @change="onLogoChange" accept="image/jpeg, image/png, image/webp, image/avif" />
                    </div>
                </div>
                <div class="organization-properties-right">
                    <div class="spz-text-input-group">
                        <label for="orgName">Name</label>
                        <input v-model="organization.name" type="text" id="orgName" placeholder="Organization Name" required />
                    </div>
                    <div class="spz-select-group narrow">
                        <SportzoneSelect id="orgLanguage" label="Language"
                            :options="['Language', ...languagesComputed]" v-model:selectedOption="organization.language">
                        </SportzoneSelect>
                    </div>
                    <div class="spz-text-input-group">
                        <label for="orgWebsite">Website</label>
                        <input v-model="organization.website" type="text" id="orgWebsite" placeholder="Website" />
                    </div>
                    <div class="spz-select-group narrow">
                        <SportzoneSelect id="orgPrivacy" label="Privacy"
                            :options="ORGANIZATION_PRIVACIES" v-model:selectedOption="organization.privacy">
                        </SportzoneSelect>
                    </div>
                    <div class="spz-text-input-group">
                        <label for="dp-orgEstablishedOn">Established on</label>
                        <VueDatePicker uid="orgEstablishedOn" v-model="organization.establishedOn" time-picker-inline utc="true" />
                    </div>
                    <div class="spz-checkbox-group wrap">
                        <input id="allowApplication" v-model="organization.allowApplication" type="checkbox" />
                        <label for="allowApplication">Allow Application</label>
                    </div>
                    <div class="spz-checkbox-group wrap" >
                        <input id="autoAcceptApplication" v-model="organization.autoAcceptApplication" type="checkbox" />
                        <label for="autoAcceptApplication">Auto Accept Application</label>
                    </div>
                    <div class="spz-checkbox-group wrap" >
                        <input id="acceptSubOrganizationMembers" v-model="organization.acceptSubOrganizationMembers" type="checkbox" />
                        <label for="acceptSubOrganizationMembers">Accept Sub-Organization Members</label>
                    </div>
                    <div class="spz-checkbox-group wrap" >
                        <input id="shareMembersToSubOrganizations" v-model="organization.subOrganizationsCanAccessMembers" type="checkbox" />
                        <label for="shareMembersToSubOrganizations">Sub-Organizations Can Access Members</label>
                    </div>
                </div>
                <div class="buttons-wrapper">
                    <button class="btn delete-btn" @click="onDeleteClick">Delete</button>
                    <button class="btn save-btn" @click="onSaveClick">{{ saveButtonText }}</button>
                </div>
            </div>
            <SportzoneDialog ref="confirmDeleteDialog" @close="showConfirmDeleteDialog = false"
            :show="showConfirmDeleteDialog">
            <template v-slot:body>
                Are you sure you want to delete the organization {{ organization?.name }}?
            </template>
            <template v-slot:footer>
                <button @click="$refs.confirmDeleteDialog.closeModal(), confirmDeleteDialogCallback()"
                    class="btn delete-btn">
                    Confirm
                </button>
                <button @click="$refs.confirmDeleteDialog.closeModal()" class="btn btn-inverse">Cancel</button>
            </template>
        </SportzoneDialog>
        </template>
    </TheOrganizerDashboard>
</template>
<script lang="ts">
import { createNamespacedHelpers, useStore } from 'vuex';
import { computed, defineComponent, Ref, ref } from 'vue';
import TheOrganizerDashboard from '@/components/main/TheOrganizerDashboard.vue';
import { ORGANIZATION_PRIVACIES, Organization } from '@/models/main/Organization';
import { key } from '@/store';
import { useRoute, useRouter } from 'vue-router';
import { User } from '@/models/main/User';
import { formatStringDate } from '@/composables/common/Util';
import SportzoneSelect from '@/components/util/SportzoneSelect.vue';
import SportzoneDialog from '@/components/util/SportzoneDialog.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
const languages = require('language-list')();
export default defineComponent({
    components: {
        TheOrganizerDashboard,
        VueDatePicker,
        SportzoneSelect,
        SportzoneDialog,
    },
    computed: {
    },
    setup() {
        const organization: Ref<Organization | undefined> = ref();
        const store = useStore(key);
        const route = useRoute();
        const router = useRouter();
        const organizationId: Ref<number> = ref(parseInt(route.params.id as string));
        const onUserLoaded = (user: User) => {
        };
        const loadOrganization = async () => {
            organization.value = await store.dispatch('organizationStore/getOrganization', organizationId.value);
            if(organization.value) {
                imageUrl.value = '/api/organization/' + organization.value.id + '/logo'
            }
        };

        const saveButtonText: Ref<string> = ref('Save')
        const onSaveClick = async () => {
            saveButtonText.value = 'Saving'
            if(organizationLogoToUpload.value && organization.value) {
                store.dispatch('organizationStore/uploadLogo', { organizationId: organization.value.id, logo: organizationLogoToUpload.value })
            }
            await store.dispatch('organizationStore/editOrganization', organization.value)
            saveButtonText.value = 'Save'
        }

        const showConfirmDeleteDialog = ref(false);
        const confirmDeleteDialogCallback = async () => {
            if(organization.value) {
                await store.dispatch('organizationStore/deleteOrganization', organization.value.id)
                router.push({name: "Organizations"})
            }
        }
        const onDeleteClick = async() => {
            showConfirmDeleteDialog.value = true
        }

        const languagesComputed = computed(() => {
            const list = languages.getData()
            console.log(list);
            const result = Object
                .keys(list)
                .map((key) => (list[key]['language']));
            return result;
        });

        const showDefaultUploadLogoImg = ref(false);
        const imageUrl: Ref<any> = ref('')
        const organizationLogoToUpload: Ref<File | undefined> = ref()
        const onLogoChange = (e: Event) => {
            if(e.target) {
                const inputElement = e.target as HTMLInputElement
                if(inputElement.files && inputElement.files.length > 0) {
                    const imageFile = inputElement.files[0];
                    const tempImageURL = URL.createObjectURL(imageFile)
                    organizationLogoToUpload.value = imageFile
                    imageUrl.value = tempImageURL
                    showDefaultUploadLogoImg.value = false;
                }
            }
        }
        
        loadOrganization();
        return {
            showDefaultUploadLogoImg,
            organization,
            organizationId,
            languagesComputed,
            ORGANIZATION_PRIVACIES,
            saveButtonText,
            showConfirmDeleteDialog,
            imageUrl,
            onLogoChange,
            confirmDeleteDialogCallback,
            onUserLoaded,
            formatStringDate,
            onSaveClick,
            onDeleteClick,
        };
    }
});
</script>
<style>
.organization-properties-wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
}
.organization-properties-left, .organization-properties-right {
  width: 50%;
  flex-wrap: wrap;
  display: flex;
  height: 100%;
  column-gap: 2em;
}

.organization-properties-wrapper .spz-checkbox-group.wrap {
  margin-right: 1em;
}

.org-logo-edit-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.organization-properties-left {
    display: flex;
    justify-content: center;
    align-items: center;
}
.org-default-logo {
    display: flex;
    width: 20em;
    padding: 3em;
    background-color: var(--bright-font-clr);
    border-radius: var(--default-border-rad);
    height: 20em;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

.org-default-logo img {
  max-height: 100%;
  max-width: 100%;
}

.organization-properties-wrapper .buttons-wrapper {
    display: flex;
    justify-content: flex-start;
    gap: 1em;
}
</style>