import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "admin-section" }
const _hoisted_2 = { class: "central-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheUserBar = _resolveComponent("TheUserBar")!
  const _component_TheNavigationBar = _resolveComponent("TheNavigationBar")!
  const _component_TheMainSection = _resolveComponent("TheMainSection")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TheUserBar, { user: _ctx.getUser }, null, 8, ["user"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TheNavigationBar, {
        user: _ctx.getUser,
        navigationItems: _ctx.navigationItems
      }, null, 8, ["user", "navigationItems"]),
      _createVNode(_component_TheMainSection, null, {
        default: _withCtx(() => [
          _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1),
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      })
    ])
  ]))
}