<template>
  <TheUserDashboard title="Profile" :onUserLoad="onUserLoaded">
    <UserForm v-model:id="currentUser.id" v-model:firstName="currentUser.firstName"
      v-model:middleName="currentUser.middleName" v-model:lastName="currentUser.lastName" v-model:sex="currentUser.sex"
      v-model:email="currentUser.email" v-model:birthday="currentUser.birthday"
      v-model:nationality="currentUser.nationality" @onProfileChange="onProfilePictureChange">
      <button class="profile-button-save" @click="onFormSubmit">Save</button>
    </UserForm>
  </TheUserDashboard>
</template>
<script lang="ts">
import { defineComponent, Ref, ref } from 'vue';
import { createNamespacedHelpers, useStore } from 'vuex';
import { key } from '@/store';
import TheUserDashboard from '@/components/main/TheUserDashboard.vue';
import UserForm from '@/components/main/UserForm.vue';
import { useRouter } from 'vue-router';
import { User } from '@/models/main/User';
import { DefaultUser } from '@/store/modules/main/UserStore';
const userStoreHelpers = createNamespacedHelpers('userStore');
export default defineComponent({
  components: {
    TheUserDashboard,
    UserForm,
  },
  ...userStoreHelpers.mapGetters(['getUser']),
  setup() {
    const router = useRouter();
    const store = useStore(key);
    const user: User = new DefaultUser();
    const currentUser: any = ref(user);
    const onUserLoaded = (user: User) => {
      currentUser.value = user;
    };

    const profilePictureToUpload: Ref<File | undefined> = ref()
    const onProfilePictureChange = (profilePicture: File) => {
      profilePictureToUpload.value = profilePicture
    }

    const onFormSubmit = () => {
      store.dispatch('userStore/saveUser', currentUser.value)
        .then((successfullyCreated) => {
          if (successfullyCreated) {
            router.push({ name: 'Dashboard' });
          }
        });
        if(profilePictureToUpload.value) {
          store.dispatch('userStore/uploadProfilePicture', { userId: currentUser.value.id, profilePicture: profilePictureToUpload.value })
        }
    };
    return {
      store,
      router,
      currentUser,
      onFormSubmit,
      onUserLoaded,
      onProfilePictureChange,
    };
  },
});
</script>
<style>
.profile-button-save {
  --default-btn-radius: 2em;
  cursor: pointer;
  border-radius: var(--default-btn-radius);
  font-weight: 600;
  color: white;
  padding: 0.325em 0.75em;
  background-color: var(--btn-darker-background-color);
  text-transform: uppercase;
  border: none;
  font-family: inherit;
  font-size: inherit;
  width: fit-content;
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>