<template>
    <div v-if="phaseRanking" class="tournament-logo-wrapper">
        <img :src="bowlzoneApiUrl + 'tournamentGroup/logo?api_key=bowling_2017&id=' + phaseRanking?.details?.eventID" alt="Event Logo" class="tournament-logo" />
    </div>
    <div v-if="phaseRanking" class="bowling-ranking-info-section">
        <div class="event-name">{{phaseRanking?.details?.eventName}}</div>
        <div class="phase-name">{{phaseRanking?.details?.stageName}} / {{phaseRanking?.details?.name}}</div>
        <div class="cut">
            <span>Cut </span>
            <span>{{phaseRanking?.cut?.result}} / {{phaseCutAverage}}</span>
        </div>
    </div>
</template>

<script lang="ts">
import useParsePhaseRankingForRender from "@/composables/bowling/UseParsePhaseRankingForRender";
import PhaseRankingModel from "@/composables/dataModels/bowling/PhaseRankingDataModel";
import { defineComponent, onMounted, PropType, ref, Ref, watch } from "vue";

export default defineComponent ({ 
    props: {
        phaseRanking: {
            type: Object as PropType<PhaseRankingModel> | null,
            required: false,
        }
    },
    setup(props) {
        const bowlzoneApiUrl = "https://api.bowlzone.atia.com/bowling/"
        const localPhaseRanking: Ref<PhaseRankingModel | undefined> = ref()
        onMounted(() => {
            if(props.phaseRanking) {
                localPhaseRanking.value = props.phaseRanking
            }
        })
        watch(props, (newProps) => {
            if(newProps.phaseRanking) {
                localPhaseRanking.value = newProps.phaseRanking
            }
        })

        const {
            phaseCutAverage
        } = useParsePhaseRankingForRender(localPhaseRanking)
        return {
            bowlzoneApiUrl,
            phaseCutAverage
        }
    }
})
</script>

<style>
    .cut {
        float: left;
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
    }

    .cut span:first-child {
        font-size: 14px;
    }

    .phase-title {
        font-size: 16px;
        font-weight: bold;
        padding: 10px 0;
    }

    .bowling-ranking-info-section {
        float: left;
        width: 100%;
        margin-top: 10px;
        padding-left: 1ch;
    }

    .event-name {
        display: inline-block;
        font-size: 18px;
        font-weight: bold;
        width: 100%;
        text-align: left;
    }

    .tournament-logo {
        max-width: 100%;
        max-height: 135px;
    }

    .info-footer img {
        float: right;
        margin-right: 15px;
    }

    .phase-name {
        text-align: left;
        font-size: 18px;
        font-weight: bolder;
        float: left;
        width: 100%;
    }

    .tournament-logo-wrapper {
        width: 200px;
        padding-right: 20px;
        max-height: 140px;
        overflow: hidden;
        float: left;
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: center;
    }
</style>