import { DataModelStore } from "@/composables/dataModels/DataModelStore";
import DataModel from "@/models/main/DataModel";

export default abstract class DataModelMapper {

    static mapDataModel(modelID: string, modelData: any): DataModel | null {
        const identifiersRegex = new RegExp('^([a-zA-Z]+(?=' + DataModel._modelClassParamsDelimiter + ')|[a-zA-Z]+$)', "g");
        const identifierMatches = identifiersRegex.exec(modelID);
        if (!identifierMatches) {
            console.error("Invalid Model ID received!");
            return null;
        } else if (identifierMatches.length < 2) {
            console.error("Invalid Model ID received!");
            return null;
        }

        const className = identifierMatches[1];
        try {
            return new DataModelStore[className](modelID, modelData);
        } catch (exception: any) {
            console.error("Could not map data model of " + className + "!");
            console.error(exception.message);
            return null;
        }
        
    }

    static createFromObject(object: any): DataModel | null {
        try {
            if (!Object.prototype.hasOwnProperty.call(object, "_ID")) {
                console.error("Invalid DataModel Object! ModelID property is not set!");
                return null;
            }
            if (!Object.prototype.hasOwnProperty.call(object, "_Data")) {
                console.error("Invalid DataModel Object! ModelData property is not set!");
                return null;
            }

            const mappedModel = DataModelMapper.mapDataModel(object._ID, object._Data);
            if(mappedModel !== null) {
                mappedModel.modelData = null; // not needed info
            }

            return mappedModel;
        } catch (syntaxError: any) {
            console.error(syntaxError.message);
            return null;
        }
    }

}