<template>
    <TheUserDashboard :onUserLoad="onUserLoaded" title="My Organizations" :hasSubPages="true">
        <div class="multiple-subpage-layout-wrapper">
            <ul class="user-content-submenu"> 
                <li class="user-content-submenu__item" :class="{'active' : activeSubPageName == 'applications'}" @click="onSubPageMenuItemClick('applications')">
                    Applications <span v-show="getOrganizationApplicationsTotalCount > 0">{{ getOrganizationApplicationsTotalCount }}</span>
                </li>
                <li class="user-content-submenu__item" :class="{'active' : activeSubPageName == 'invitations'}" @click="onSubPageMenuItemClick('invitations')">
                    Invitations <span v-show="getOrganizationInvitationsTotalCount > 0">{{ getOrganizationInvitationsTotalCount }}</span>
                </li>
                <li class="user-content-submenu__item" :class="{'active' : activeSubPageName == 'organizations'}" @click="onSubPageMenuItemClick('organizations')">
                    Organizations <span v-show="userOrganizationsCount > 0">{{ userOrganizationsCount }}</span>
                </li>
                <li class="user-content-submenu__item" :class="{'active' : activeSubPageName == 'fOrganizations'}" @click="onSubPageMenuItemClick('fOrganizations')">
                    Find Organizations
                </li>
            </ul>
            <div class="user-content-submenu__content-wrapper">
                <div class="user-organizations-wrapper subpage-item" :class="{'active' : activeSubPageName == 'organizations'}">
                    <div class="subpage-title">Organizations</div>
                    <div class="sport-wrapper" v-for="(organizationsGrouped, sport) in getMemberOrganizationsGroupedBySport">
                        <div class="sport-icon-wrapper">
                            <VolleyballIcon class="organization-sport-icon" v-if="sport == 'Volleyball'"/>
                            <TennisIcon class="organization-sport-icon"  v-else-if="sport == 'Tennis'"/>
                            <TableTennisIcon class="organization-sport-icon"  v-else-if="sport == 'TableTennis'"/>
                            <BowlingIcon class="organization-sport-icon"  v-else-if="sport == 'Bowling'"/>
                            <BadmintonIcon class="organization-sport-icon"  v-else-if="sport == 'Badminton'"/>
                        </div>
                        <div class="sport-organizations">                           
                            <OrganizationPublicBadgeItem class="organization-wrapper" v-for="organization in organizationsGrouped" :organization="organization" />
                        </div>
                    </div>
                    <div class="sport-wrapper" v-for="(organizationsGrouped, sport) in getFollowerOrganizationsGroupedBySport">
                        <div class="sport-icon-wrapper">
                            <VolleyballIcon class="organization-sport-icon" v-if="sport == 'Volleyball'"/>
                            <TennisIcon class="organization-sport-icon"  v-else-if="sport == 'Tennis'"/>
                            <TableTennisIcon class="organization-sport-icon"  v-else-if="sport == 'TableTennis'"/>
                            <BowlingIcon class="organization-sport-icon"  v-else-if="sport == 'Bowling'"/>
                            <BadmintonIcon class="organization-sport-icon"  v-else-if="sport == 'Badminton'"/>
                        </div>
                        <div class="sport-organizations">                           
                            <OrganizationPublicBadgeItem class="organization-wrapper" v-for="organization in organizationsGrouped" :organization="organization" />
                        </div>
                    </div>
                </div>
                <div class="user-applications-wrapper subpage-item" :class="{'active' : activeSubPageName == 'applications'}">
                    <div class="subpage-title">Applications</div>
                    <div class="user-org-application-item badge-item" v-for="application in getOrganizationApplications" :key="application.id">
                        <div class="badge-item-col user-org-application-item__org">
                            <div class="badge-info-heading">Organization</div>
                            <div class="badge-info-value">{{application?.organization?.name}}</div>
                        </div>
                        <div class="badge-item-col user-org-application-item__applied-on">
                            <div class="badge-info-heading">Applied on</div>
                            <div class="badge-info-value">{{ formatStringDate(application.dateApplied) }}</div>
                        </div>
                        <div class="badge-item-col badge-status" :class="application.status">{{ application.status }}</div>
                        <div class="badge-item-col">
                            <button class="btn delete-btn" v-show="application.status === 'Pending'" @click="cancelApplication(application)">Cancel</button>
                            <button class="btn delete-btn" v-show="application.status === 'Accepted'" @click="cancelApplication(application)">Delete</button>
                        </div>
                    </div>
                </div>
                <div class="user-invitations-wrapper subpage-item" :class="{'active' : activeSubPageName == 'invitations'}">
                    <div class="subpage-title">Invitations</div>
                    <div class="user-org-invitation-item badge-item" v-for="invitation in getOrganizationInvitations" :key="invitation.id">
                        <div class="badge-item-col user-org-invitation-item__org">
                            <div class="badge-info-heading">Organization</div>
                            <div class="badge-info-value">{{ invitation.organization.name }}</div>
                        </div>
                        <div class="badge-item-col user-org-invitation-item__created-on">
                            <div class="badge-info-heading">Invited on</div>
                            <div class="badge-info-value">{{ formatStringDate(invitation.dateCreated)}}</div>
                        </div>
                        <div class="badge-item-col badge-status" :class="invitation.status">{{ invitation.status }}</div>
                        <div class="badge-item-col" v-show="invitation.status !== 'Accepted' && invitation.status !== 'Expired' && invitation.status !== 'Revoked'">
                            <button class="btn accept-btn"  @click="acceptInvitation(invitation)">Accept</button>
                        </div>
                        <div class="badge-item-col" v-show="invitation.status !== 'Pending'">
                            <button class="btn delete-btn"  @click="deleteInvitation(invitation)">Delete</button>
                        </div>
                        <div class="badge-item-col" v-show="invitation.status === 'Pending'">
                            <button class="btn delete-btn"  @click="rejectInvitation(invitation)">Reject</button>
                        </div>
                    </div>
                </div>
                <div class="user-organizations-wrapper subpage-item" :class="{'active' : activeSubPageName == 'fOrganizations'}">
                    <div class="subpage-title">Find Organization</div>
                    <div class="search-organizations-wrapper">
                        <div class="sport-wrapper" v-for="(organizationsGrouped, sport) in getPublicOrganizationsGroupedBySport">
                            <div class="sport-icon-wrapper">
                                <VolleyballIcon class="organization-sport-icon" v-if="sport == 'Volleyball'"/>
                                <TennisIcon class="organization-sport-icon"  v-else-if="sport == 'Tennis'"/>
                                <TableTennisIcon class="organization-sport-icon"  v-else-if="sport == 'TableTennis'"/>
                                <BowlingIcon class="organization-sport-icon"  v-else-if="sport == 'Bowling'"/>
                                <BadmintonIcon class="organization-sport-icon"  v-else-if="sport == 'Badminton'"/>
                            </div>
                            <div class="sport-organizations">
                                <template v-for="organization in organizationsGrouped">
                                    <OrganizationPublicBadgeItem class="organization-wrapper" v-if="organization" :key="organization.id" :organization="organization"/>
                                </template>                           
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </TheUserDashboard>
  </template>
  <script lang="ts">
  import { Ref, computed, defineComponent, onMounted, ref} from "vue";
import { useStore } from "vuex";
import { key } from "@/store";
import TheUserDashboard from "@/components/main/TheUserDashboard.vue";
import BowlingIcon from '@/components/bowling/BowlingIcon.vue'
import VolleyballIcon from '@/components/volleyball/VolleyballIcon.vue'
import TableTennisIcon from '@/components/tableTennis/TableTennisIcon.vue'
import BadmintonIcon from "@/components/badminton/BadmintonIcon.vue"
import TennisIcon from '@/components/tennis/TennisIcon.vue'
import StepBasedModal from '@/components/main/StepBasedModal.vue';
import SportzoneSelect from '@/components/util/SportzoneSelect.vue';
import { useRouter } from "vue-router";
import { User } from "@/models/main/User";
import { SPORT_TYPES} from "@/models/main/CommonTypes";
import { Organization } from "@/models/main/Organization";
import { formatStringDate } from "@/composables/common/Util";
import useUserOrganizationComputeds from "@/composables/user/UseUserOrganizationComputeds";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import OrganizationPublicBadgeItem from "@/components/main/OrganizationPublicBadgeItem.vue";
import { INVITE_STATUSES, OrganizationInvitation } from "@/models/main/OrganizationInvitation";
import { OrganizationApplication } from "@/models/main/OrganizationApplication";

export default defineComponent({
    components: {
        TheUserDashboard,
        VolleyballIcon,
        TableTennisIcon,
        TennisIcon,
        BadmintonIcon,
        BowlingIcon,
        StepBasedModal,
        VueDatePicker,
        SportzoneSelect,
        OrganizationPublicBadgeItem,
    },
    setup() {
        const store = useStore(key);
        const onUserLoaded = (user: User) => {
        }

        const activeSubPageName: Ref<string> = ref('organizations')
        const onSubPageMenuItemClick = (subPageName: string) => {
            activeSubPageName.value = subPageName
        }
        const {
            loadUserOrganizationsAsMember,
            loadUserOrganizationsAsFollower,
            loadPublicOrganizations,
            loadUserOrganizationApplications,
            loadUserOrganizationInvitations,
            rejectInvitation,
            acceptInvitation,
            cancelApplication,
            deleteInvitation,
            getMemberOrganizationsGroupedBySport,
            getFollowerOrganizationsGroupedBySport,
            getPublicOrganizationsGroupedBySport,
            userOrganizationsCount,
            getOrganizationApplications,
            getOrganizationApplicationsTotalCount,
            getOrganizationInvitations,
            getOrganizationInvitationsTotalCount
        }  = useUserOrganizationComputeds()

        const loadOrganizations = () => {
            loadUserOrganizationsAsMember()
            loadUserOrganizationsAsFollower()
            loadPublicOrganizations()
            loadUserOrganizationApplications()
            loadUserOrganizationInvitations() 
        };

        onMounted(loadOrganizations)
        return {
            onUserLoaded,
            onSubPageMenuItemClick,
            formatStringDate,
            rejectInvitation,
            acceptInvitation,
            cancelApplication,
            deleteInvitation,
            getPublicOrganizationsGroupedBySport,
            getMemberOrganizationsGroupedBySport,
            getFollowerOrganizationsGroupedBySport,
            getOrganizationApplications,
            getOrganizationInvitations,
            getOrganizationApplicationsTotalCount,
            getOrganizationInvitationsTotalCount,
            userOrganizationsCount,
            SPORT_TYPES,
            activeSubPageName
        }
    }
})
</script>
<style>
.user-organizations-wrapper{
    width: 100%;
    --organization-badge-width: 8em;
    --organization-badge-height: 8em;
    height: 100%;
}

.user-org-invitation-item.badge-item, .user-org-application-item.badge-item {
  max-width: 60em;
  justify-content: flex-start;
}

.user-org-application-item__org, .user-org-invitation-item__org {
  min-width: 28ch;
}

.user-org-application-item__applied-on, .user-org-invitation-item__created-on {
  min-width: 27ch;
}

/* Content submenu */
.multiple-subpage-layout-wrapper {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: var(--submenu-width) 1fr;
    gap: 1rem;
    --_background-clr: var(--background-clr);
}

.user-content-submenu__item.active, .user-content-submenu__item:hover, .user-content-submenu__item:focus-visible {
  background-color: var(--background-clr);
  border-radius: var(--default-border-rad) 0 0 var(--default-border-rad);
}

.user-content-submenu {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    background-color: var(--bright-font-clr);
    padding-left: 1.5rem;
    padding-top: 2.5rem;
    border-radius: var(--default-border-rad);
    margin-top: calc(1.5rem * -1);
    margin-left: calc(1.5rem * -1);
    margin-bottom: calc(1.5rem * -1);
}
.user-content-submenu__item {
    --_edge-radius: 1.5rem;
    --_edge-shadow-offset: calc(var(--_edge-radius) * 0.207 );
    position: relative;
    padding: 0.5rem 1rem;
    border-radius: 100vw;
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr 3ch;
    place-content: center;
    gap: 0.5rem;
    align-items: center;
    min-height: 2.5em;
}

.user-content-submenu__item > span {
  background-color: var(--_background-clr);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.25em;
  width: 4ch;
}

.user-content-submenu__item > span:empty {
  display: none;
}

.user-content-submenu::after {
    --_edge-radius: 1rem;
    --_edge-shadow-offset: 0.3rem;
    --_edge-shadow-position: calc(var(--_edge-radius) * -0.4);
    content: '';
    position: absolute;
    width: var(--_edge-radius);
    height: var(--_edge-radius);
    right: calc(var(--_edge-radius) * -1);
    bottom: calc(var(--_edge-radius) * -1);
    box-shadow: var(--_edge-shadow-position) var(--_edge-shadow-position) 0 var(--_edge-shadow-offset) var(--bright-font-clr);
    border-radius: var(--default-border-rad) 0;
    top: 0;
}

.user-content-submenu__content-wrapper {
    height: 100%;
}

.subpage-item {
    display: none;
}
.subpage-item.active {
    display: block;
}
.subpage-item > * {
    margin-inline: auto;
}

.subpage-title {
  width: 100%;
  height: 2.5rem;
  font-size: 1.125rem;
  font-weight: 500;
  display: none;
  align-items: center;
  padding-left: 1rem;
}

.user-content-submenu__item:hover::before, .user-content-submenu__item:hover::after, 
.user-content-submenu__item.active::before, .user-content-submenu__item.active::after {
    content: '';
    width: var(--_edge-radius);
    height: var(--_edge-radius);
    position: absolute;
    right: 0;
}
.user-content-submenu__item:before {
    top: calc(var(--_edge-radius) * -1);
    box-shadow: var(--_edge-shadow-offset) var(--_edge-shadow-offset) 0 var(--_edge-shadow-offset) var(--_background-clr);
    border-radius: 0 0 var(--default-border-rad) 0;
}
.user-content-submenu__item::after {
    bottom: calc(var(--_edge-radius) * -1);
    box-shadow: var(--_edge-shadow-offset) calc(var(--_edge-shadow-offset) * -1) 0 var(--_edge-shadow-offset) var(--_background-clr);
    border-radius: 0 var(--default-border-rad) 0 0;
}
/* submenu*/
</style>