<template>
  <router-link :active="$route.matched.some(({ name }) => name === navigationItem.routerLinkName)" class="menu-link" :to="{name: navigationItem.routerLinkName}">{{navigationItem.title}}</router-link>
</template>

<script lang="ts">
import { SportzoneRole } from '@/models/main/User'
import { defineComponent, PropType } from 'vue'

export interface NavigationItem {
    title: string,
    routerLinkName: string,
    params: Record<string, unknown>|null,
    iconLink: string|null,
    allowedRoles: SportzoneRole[]
}

const NavigationBarItem = defineComponent({
    props: {
        navigationItem: {
            type: Object as PropType<NavigationItem>,
            required: true
        }
    },
})

export default NavigationBarItem
</script>

<style>

</style>