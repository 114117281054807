<template>
    <div class="org-dashboard-wrapper">
        <TheNavigationBar :user="getUser" :navigationItems="navigationItems" />
        <main class="organizer-main-container" @scroll="onScroll">
            <div class="nav-history">
                <div class="page-heading" v-if="title">{{title}}</div>
                <slot name="heading" />
            </div>
            <slot />
        </main>
    </div>
</template>
<script lang="ts">
import { Ref, defineComponent, onMounted, reactive, ref, watch } from 'vue';
import { NavigationItem } from './NavigationBarItem.vue';
import { useStore, createNamespacedHelpers } from 'vuex';
import { key } from '@/store';
import { useRouter } from 'vue-router';
import { User } from '@/models/main/User';
import authModule from '@/main';
import TheNavigationBar from '@/components/main/TheNavigationBar.vue';
import { InteractionType } from '@azure/msal-browser';
import { organizerLoginRequest } from '../util/AuthConfig';
import { useMsalAuthentication } from '@/composables/auth/UseMSALAuthentication';

const userStoreMapper = createNamespacedHelpers('userStore');
const defaultNavigationItems: NavigationItem[] = [
    //{title: "Home", routerLinkName: "Home", params: null, iconLink: null, allowedRoles: [] } as NavigationItem,
    {title: "Channels", routerLinkName: "Channels", params: null, iconLink: null, allowedRoles: ["Admin", "Organizer"] } as NavigationItem,
    {title: "Stations", routerLinkName: "Stations", params: null, iconLink: null, allowedRoles: ["Admin", "Organizer"] } as NavigationItem,
    {title: "My Livestreams", routerLinkName: "User Livestream", params: null, iconLink: null, allowedRoles: ['Player'] } as NavigationItem,
   // {title: "User Management", routerLinkName: "UserManagement", params: null, iconLink: null, allowedRoles: ["Admin"] } as NavigationItem,
    {title: "Teams", routerLinkName: "Team Management", params: null, iconLink: null, allowedRoles: ["Admin", "Organizer"] } as NavigationItem,
   // {title: "Tournaments", routerLinkName: "Volleyball Tournament", params: null, iconLink: null, allowedRoles: ["Admin"] } as NavigationItem,
    {title: "Matches", routerLinkName: "Volleyball Match", params: null, iconLink: null, allowedRoles: ["Admin", "Organizer", "Operator"] } as NavigationItem,
    {title: "Livestreams", routerLinkName: "Livestreams", params: null, iconLink: null, allowedRoles: ["Admin", "Organizer"] } as NavigationItem,
    {title: "Live", routerLinkName: "Livestreams Dashboard", params: null, iconLink: null, allowedRoles: ["Admin", "Organizer", "Operator", "Player"] } as NavigationItem,
    {title: "Archive", routerLinkName: "Livestreams Archive", params: null, iconLink: null, allowedRoles: ["Admin", "Organizer", "Operator", "Player"] } as NavigationItem,
    //{title: "Profile", routerLinkName: "Profile", params: null, iconLink: null, allowedRoles: ["Admin", "Organizer", "Operator", "Player"] } as NavigationItem,
    // {title: "Rally Score", routerLinkName: "Rally Score", params: null, iconLink: null, allowedRoles: ["Admin", "Organizer", "Operator"] } as NavigationItem,

]
const TheOrganizerDashboard = defineComponent({
    props: {
        title: {
            type: String,
        },
        onUserLoad: {
            type: Function,
            required: false,
        },
    },
    computed: {
        ...userStoreMapper.mapGetters([
            'getUser',
        ]),
    },
    emits: ['scroll'],
    setup(props: any, context) {
        const store = useStore(key);
        const router = useRouter();
        const {result, inProgress} = useMsalAuthentication(InteractionType.Redirect, { ...organizerLoginRequest, forceRefresh: false,  })
        watch(inProgress, (newProgress: boolean) => {
            if(!newProgress) {
                getLoggedInUser()
            }
        })
        const getLoggedInUser = () => {
            if(result.value && result.value.accessToken) {
                authModule.token = result.value.accessToken
            }

            store.dispatch('userStore/getCurrentUser').then((user: User) => {
                if (authModule.isAuthenticated && user === null) {
                   router.push({ name: 'Profile' });
                }
                else if (props?.onUserLoad) {
                    props.onUserLoad(user);
                }
            });
        };
        const logoutUser = () => {
            authModule.logout();
            router.push({ name: 'Login' });
        };
        const onScroll = (event: any) => {
            context.emit('scroll', event);
        };
        const publicPath = process.env.BASE_URL;
        const defaultProfilePicRelativePath = 'default-profile-pic.svg';
        const navigationItems = reactive<Array<NavigationItem>>(defaultNavigationItems);
        return {
            navigationItems,
            publicPath,
            defaultProfilePicRelativePath,
            logoutUser,
            onScroll,
        };
    },
    components: {
        TheNavigationBar
    },
});
export default TheOrganizerDashboard;
</script>
<style>
.org-dashboard-wrapper {
    --sky-blue-clr: #bae9fb;
    --background-clr: #f2f4f7;
    --nav-contour-clr: #283380;
    --bright-font-clr: white;
    --darker-font-clr: #283380;
    --nav-height: 4rem;
    --nav-line-margin-btm: 0.25rem;
    --nav-contour-height: 1rem;
    --max-width: min(100vw, 1680px);
    --max-content-width: min(100vw, 1280px);
    --sportzone-logo-width: clamp(8rem, 25vw, 16rem);
    font-size: 12px;
    font-family: Roboto;
    background-color: var(--background-clr);
}

.org-dashboard-wrapper .nav-history {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 4em;
    column-gap: 0.6em;
}

.org-dashboard-wrapper .page-heading {
    color: var(--main-font-clr);
    font-size: 1.5em;
    font-weight: bold;
    position: relative;
    padding-left: 1.2em;
    line-height: 1.2em;
}

.org-dashboard-wrapper .page-heading::before {
    content: '';
    width: 1em;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-image: url('/sportzone-green-triangle.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

.org-dashboard-wrapper.bwl {
    background: url('../../../public/grey_bowling_icon.svg');
}

.org-dashboard-wrapper.tbltenn {
    background: url('../../../public/grey_table_tennis_icon.svg');
}

.org-dashboard-wrapper.tenn {
    background: url('../../../public/grey_tennis_icon.svg');
}

.org-dashboard-wrapper.vly {
    background: url('../../../public/grey_volleyball_icon.svg');
}

.org-dashboard-wrapper.bwl,
.org-dashboard-wrapper.tbltenn,
.org-dashboard-wrapper.tenn,
.org-dashboard-wrapper.vly {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 35%;
}

.org-dashboard-wrapper .modal__dialog {
    background-color: var(--background-clr);
}

.org-dashboard-wrapper .modal__header {
    color: var(--darker-font-clr);
}

h1 {
    text-align: center;
}

.btn.add-btn,
.btn.delete-btn,
.btn.edit-btn,
.btn.download-btn,
.btn.manage-btn,
.btn.accept-btn,
.btn.send-btn,
.btn.add-usr-btn,
.btn.save-btn {
    position: relative;
    padding-left: 2.3em;
    min-width: 12ch;
}

.btn.add-btn::before,
.btn.delete-btn::before,
.btn.edit-btn::before,
.btn.download-btn::before,
.btn.manage-btn::before,
.btn.accept-btn::before,
.btn.send-btn::before,
.btn.add-usr-btn::before,
.btn.save-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0.5em;
    width: 1.25em;
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-size: auto;
    background-size: 1.25em;
    bottom: 0;
    background-repeat: no-repeat;
    background-position: center;
}

.btn.btn-link {
  margin-bottom: 1em;
  display: block;
  width: max-content;
}

.btn.add-btn::before {
    background-image: url(/plus-add-sign.svg);
}

.btn.delete-btn::before {
    background-image: url(/transparent-cross.svg);
}

.btn.edit-btn::before {
    background-image: url(/pencil.svg);
}

.btn.manage-btn::before {
    background-image: url(/cog.svg);
}

.btn.accept-btn::before {
  background-image: url(/white-tick.svg);
}

.btn.send-btn::before {
    background-image: url(/send-transparent-icon.svg);
}

.btn.add-usr-btn::before {
    background-image: url(/add-users.svg);
}

.btn.save-btn::before {
    background-image: url(/download-file.svg);
}

.btn.manage-btn {
    background-color: var(--sky-blue-clr);
    color: var(--darker-font-clr);
    margin-right: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn.manage-btn * {
    color: var(--darker-font-clr);
}

.download-btn::before {
  background-image: url(/download-file.svg);
}
.btn.delete-btn {
    background-color: var(--btn-error-background-color);
}

.btn.btn-inverse {
    background: transparent;
    color: var(--btn-darker-background-color);
}

.btn.accept-btn {
    background-color: #00bc40;
}


.btn.mini.delete-btn {
    width: 2.25em;
    min-width: 0;
    color: transparent;
    padding: 0;
    aspect-ratio: 1;
    background-color: transparent;
}

.btn.mini.delete-btn::before {
    background-image: url(/red-cross.svg);
}

.btn.mini.delete-btn:hover {
    background-color: transparent;
    transform: scale(1.1);
}
.btn.mini.edit-btn {
    width: 1.25em;
    min-width: 0;
    color: transparent;
    padding: 0;
    aspect-ratio: 1;
}
.btn.mini.edit-btn::before {
  left: 0;
  border: 1px solid var(--btn-darker-background-color);
  border-radius: 50%;
  transform: scale(1.1);
}

.btn.mini.edit-btn:hover {
  transform: scale(1.1);
}

.dashboard-nav {
    --main-font-clr: #283380;
    height: var(--nav-height);
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 6;
    background-color: var(--background-clr);
}

.dashboard-container {
    max-width: var(--max-width);
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    height: 100%;
    margin: 0 auto;
    background-color: inherit;
}

.dashboard-nav::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  background-color: var(--nav-contour-clr);
  height: var(--nav-contour-height);
  bottom: 0;
  z-index: 1;
}

.dashboard-nav .sportzone-logo {
    display: inline-block;
    margin-bottom: 0;
    z-index: 2;
    padding-right: min(2rem, 2vw);
    margin-left: 0;
    background-color: var(--background-clr);
    padding-left: 0.5rem;
}

.org-dashboard-wrapper .sportzone-logo {
  margin-bottom: -0.075rem;
}

.org-dashboard-wrapper .sportzone-logo > a {
  display: flex;
}

.sportzone-logo img {
    width: 100%;
}

.org-section-name a {
    color: var(--main-font-clr);
}

.org-section-name {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
}



.organizer-main-container {
    color: var(--main-font-clr);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: var(--max-width);
    position: relative;
    margin: 0 auto;
    text-align: left;
    padding: 0.5em;
    width: var(--max-width);
    height: calc(100vh - 5rem);
    align-items: center;
    overflow-y: scroll;
    scrollbar-width: none;
    --scrollbar-width: 0;
    margin-top: calc(1.5 * var(--nav-height));
}

.org-section-name a {
    color: var(--main-font-clr);
}

.org-section-name {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
}



.spz-select-group,
.spz-text-input-group,
.spz-checkbox-group,
.spz-img-input-group,
.spz-multi-toggle-group {
    display: flex;
    margin-bottom: 1.25rem;
    font-weight: 600;
    font-size: 1em;
    --form-element-padding: 0.5em 1em;
    width: 100%;
}

.spz-checkbox-group.wrap {
  width: fit-content;
  padding: 0.25em 0.5em;
  background-color: var(--bright-font-clr);
  border-radius: var(--default-border-rad);
}

.spz-select-group,
.spz-text-input-group,
.spz-multi-toggle-group {
    flex-direction: column;
}

.spz-select-group label,
.spz-text-input-group label,
.spz-checkbox-group label,
.spz-multi-toggle-group .spz-multi-toggle-name {
    margin-bottom: 0.2em;
    font-weight: 400;
}

.spz-checkbox-group label {
    font-weight: 500;
}

.spz-text-input-group {
    border-radius: var(--default-border-rad);
    border-color: var(--select-border);
}

.spz-text-input-group input {
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    font-weight: inherit;
    border-radius: var(--default-border-rad);
    border: none;
}

.spz-text-input-group > input {
    padding: var(--form-element-padding);
}

.spz-form-err-msg {
  color: var(--watch-bck-color);
  margin-top: 0.25em;
}

.spz-select-group select {
    background-color: white;
    border: none;
    border-radius: var(--default-border-rad);
    color: inherit;
    padding: var(--form-element-padding);
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
}


.spz-text-input-group.narrow, .spz-select-group.narrow, .spz-checkbox-group.narrow, .spz-img-input-group.narrow {
    width: calc(50% - 1em);
    max-width: 40ch;
}

.spz-img-input-group label {
    cursor: pointer;
    position: relative;
    background-color: var(--bright-font-clr);
    height: 100%;
    padding: 0.5em;
    border-radius: var(--default-border-rad);
    aspect-ratio: 1;
}

.spz-img-input-group label::before {
    content: '';
    bottom: 0.25em;
    right: 0.25em;
    position: absolute;
    height: 2em;
    width: 2em;
    background: no-repeat 0 0 / contain url('/grey-camera.svg');
}

.spz-img-input-group object, .spz-img-input-group img {
    height: 100%;
    max-width: 100%;
    border-radius: 100vw;
    margin-inline: auto;
    width: 100%;
}


.badge-info-heading {
  font-weight: 400;
  color: var(--select-border);
  margin-bottom: 0.25em;
  height: 33.333%;
}

.spz-wrapper-heading {
    font-size: 1.5em;
    font-weight: bold;
    display: inline-block;
    margin-bottom: 1em;
}

.selectable-item {
    position: relative;
    margin-left: 2em;
}

.selectable-item::before {
    content: '';
    width: 1.5em;
    height: 1.5em;
    left: -2em;
    position: absolute;
    border: 2px solid var(--darker-font-clr);
    border-radius: var(--default-border-rad);
}

.selectable-item.selected-item::before {
  background-image: url(/blue-tick.svg);
  background-size: 120%;
  background-position: center;
  background-repeat: no-repeat;
}

@media screen and (min-width: 375px) {
    .sportzone-logo {
        width: var(--sportzone-logo-width);
    }
}

@media screen and (min-width: 680px) {
    .organizer-main-container { 
        font-size: 1.333em;
        padding: 3em 0.5em 0.5em 0.5em;
        margin-top: var(--nav-height);
    }

    .org-dashboard-wrapper {
        --nav-height: 5rem;
        --nav-contour-height: 1.4rem;
    }

}

@media screen and (min-width: 1000px) {
    main {
        padding: 0;
    }
}
</style>