import GenericDictionary from "@/components/util/GenericDictionary"
import { Sport } from "./CommonTypes"
import { User } from "./User"

export const ORGANIZATION_PRIVACIES = ['Public', 'Private']
export type OrganizationPrivacy = typeof ORGANIZATION_PRIVACIES[number]

export interface Organization {
    id: number,
    name: string,
    dateCreated: Date,
    establishedOn: Date | null,
    sport: Sport,
    owner?: User,
    ownerId?: number,
    parentOrganization?: Organization,
    parentOrganizationId?: number,
    allowApplication: boolean,
    autoAcceptApplication: boolean,
    acceptSubOrganizationMembers: boolean,
    subOrganizationsCanAccessMembers: boolean,
    isSystemGenerated: boolean,
    subOrganizations: GenericDictionary<Organization>,
    language: string,
    website?: string,
    privacy: OrganizationPrivacy
}