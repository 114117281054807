import * as signalR from "@microsoft/signalr";
import * as msgpack from "@microsoft/signalr-protocol-msgpack"

export default class DefaultWebsocketClient {
    private _userId = ''
    private _serverURI = ''
    private _socketURL = '';
    private _onNewDataEventHandler: (... args: any[])=> void;
    private _onCloseEventHandler: (error?: Error | undefined)=> void;
    private _onConnectEventHandler: (value: void)=> void;
    
    constructor(serverURI: string, userId: string) {
        this._userId = userId;
        this._serverURI = serverURI;
        this._onNewDataEventHandler = DefaultWebsocketClient._defaultNewDataEventHandler;
        this._onCloseEventHandler = DefaultWebsocketClient._defaultOnCloseEventHandler;
        this._onConnectEventHandler = DefaultWebsocketClient._defaultOnConnectEventHandler;
    }

    set socketURL(url) {
        this._socketURL = url;
    }

    get socketURL() {
        return this._socketURL;
    }

    get onNewDataEventHandler() {
        return this._onNewDataEventHandler;
    }

    set onNewDataEventHandler(eventHandler: (... args: any[])=> void) {
        this._onNewDataEventHandler = eventHandler;
    }

    get onCloseEventHandler() {
        return this._onCloseEventHandler;
    }

    set onClose(eventHandler: (error?: Error | undefined)=> void) {
        this._onCloseEventHandler = eventHandler;
    }

    get onConnect() {
        return this._onConnectEventHandler;
    }

    set onConnect(eventHandler: (value: void)=> void) {
        this._onConnectEventHandler = eventHandler;
    }

    static _defaultNewDataEventHandler(data: any) {
        console.log(data);
    }

    static _defaultOnCloseEventHandler() {
        console.log("Connection disconnected!");
    }

    static _defaultOnConnectEventHandler() {
        console.log("Connection started!");
    }

    _connect() {
        const connection = new signalR.HubConnectionBuilder()
        .withUrl(`${this._serverURI}/api/live?UserId=${this._userId}`, {
            withCredentials: false
        })
        .withHubProtocol(new msgpack.MessagePackHubProtocol())
        .withAutomaticReconnect()
        .build();
        connection.on('newData', this.onNewDataEventHandler);
        connection.onclose(this.onCloseEventHandler);
        console.log('Connecting...');
        connection.start()
        .then(this.onConnect)
        .catch(console.error);
    }
}