<template>
<router-view/>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
})
</script>
<style>
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700,regular,bold');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700');

*, *:before, *:after {
  box-sizing: border-box;
}

button {
    padding: 0;
}

img {
  display: block;
  max-width: 100%;
  object-fit: contain;
}

:root {
    --min-central-section-width: 100vw;
    --max-central-section-width: 1000px;
    --nav-width: 30vw;
    --nav-main-section-margin: 15px;
    --white-shade-bck-clr: #eee;
    --darker-font-clr: #4d406c;
    --default-btn-margin-inline: 0.325em;
    --light-bckgrnd-color: #5ca5e2;
    --darker-bckgrnd-color: #00539c;
    --deep-blue-bck-color: #044781;
    --light-homepage-color: white;
    --default-border-rad: 4px;
    --scrollbar-width: 8px;
    --new-background-color: #f2f4f7;
    --btn-darker-background-color: #00AEF0;
    --btn-error-background-color: #FF5349;
    --watch-bck-color: #FF5349;
    --archive-bck-color: #0ab4b9;
    --contour-light-color: #abc6de;
    --main-font-clr: #283380;
    --spz-yellow-clr: #dade49;
    --badge-item-padding: 1em 1.25em;
    --badge-item-margin: 0 0 0.5em 0;
    --green-clr: #00bc40;
    --light-blue-triangle-background: no-repeat 0.12em 0/contain url(/rounded-light-blue-triangle.svg);
    --deep-blue-triangle-background: no-repeat 60% 0/contain url(/rounded-deep-blue-triangle.svg);
    --colorful-triangle-background: no-repeat 0.12em 0/contain url('/sportzone-green-triangle.svg');
    --badge-item-height: 5.25em;
    overflow: auto;
    scrollbar-width: none;
}

* {
    scrollbar-width: thin;
    scrollbar-color: var(--deep-blue-bck-color) var(--bright-font-clr);
}

::-webkit-scrollbar {
    width: var(--scrollbar-width);
    height: 8px;
    background: var(--darker-bckgrnd-color);
}

::-webkit-scrollbar-thumb {
    background: var(--archive-bck-color);
}

::-webkit-scrollbar-corner {
    background: var(--darker-bckgrnd-color);
}

a {
    text-decoration: none;
    color: var(--light-homepage-color);
}

#app {
  font-family: Roboto, Montserrat, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  --sx-color-primary: var(--deep-blue-bck-color);
  --sx-color-on-primary-container: var(--main-font-clr);
  --sx-color-primary-container: var(--contour-light-color);
}
/* CALENDAR CHANGES */
#app .sx__month-agenda-events {
  max-height: 22.25em;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--deep-blue-bck-color) var(--bright-font-clr);
}

body {
  padding: 0;
  margin: 0;
  background-color: var(--new-background-color);
  /*background-color: var(--darker-bckgrnd-color);*/
}


ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
li {
  margin: 0;
}

h1 {
    margin: 0;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.btn {
  --default-btn-radius: 2em;
  cursor: pointer;
  border-radius: var(--default-btn-radius);
  font-weight: 600;
  color: white;
  padding: 0.5em 1em;
  background-color: var(--btn-darker-background-color);
  text-transform: uppercase;
  border: none;
  font-family: inherit;
  font-size: inherit;
}

.btn:hover, .btn.delete-btn:hover, .btn.accept-btn:hover {
  background-color: var(--main-font-clr);
}

.btn.manage-btn:hover {
    background-color: var(--main-font-clr);
    color: var(--bright-font-clr);
}
.btn.manage-btn:hover::before {
    background-image: url('/cog-white.svg');
}

/* Custom Checkbox */
.spz-checkbox-group--disabled {
  color: var(--form-control-disabled);
  cursor: not-allowed;
}

.spz-checkbox-group label {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.spz-checkbox-group input[type="checkbox"] {
  margin-right: 0.5em;
  cursor: pointer;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: white;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.3em;
  height: 1.3em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em white;
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:checked {
  background-color: var(--main-font-clr);
}

input[type="checkbox"]:focus {
  outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em);
}

.spz-system-message {
  font-size: 1.5rem;
  font-weight: 500;
}

/* V-Toast Specific CSS */
.v-toast__item {
    min-width: min(90vw, 25ch);
    font-family: Roboto, sans-serif;
}

.v-toast__item.v-toast__item--success {
    background-color: var(--archive-bck-color);
}
</style>
