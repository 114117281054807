<template>
    <div class="public-layout-wrapper">
        <nav class="dashboard-nav">
            <div class="dashboard-container">
                <div class="sportzone-logo">
                    <a href="https://www.sportzone.live">
                        <img src="/sportzone_deep_blue_logo.svg" alt="Sportzone Logo" />
                    </a>  
                </div>
                <div class="menu-section">
                    <div class="links-section">
                        <router-link class="archive-link"  :to="{name: 'Livestreams Archive'}">
                            Archive
                        </router-link>
                        <router-link class="watch-link"  :to="{name: 'Livestreams Dashboard'}">
                            Watch Live
                        </router-link>
                    </div>
                </div>
            </div>
        </nav>
        <main class="public-layout-main" @scroll="onMainContentScroll">
            <slot />
        </main>
    </div>
</template>

<script lang="ts">
import { defineComponent  } from 'vue'

const ThePublicHomeLayout = defineComponent({
    emits: ['scroll'],
    setup(props, context) {
        const onMainContentScroll = (event: any) => {
            context.emit('scroll', event)
        }

        return {
            onMainContentScroll
        }
    }
})

export default ThePublicHomeLayout
</script>

<style>

.public-layout-wrapper {
    --nav-container-max-width: 1000px;
    --nav-contour-clr: #283380;
    --nav-logo-left-margin: 2em;
    --max-central-section-width: var(--nav-container-max-width);
    --nav-height: 4rem;
    --nav-line-margin-btm: 0.25rem;
    --nav-contour-height: 1em;
    --max-width: min(100vw, 1680px);
    --max-content-width: min(100vw, 1280px);
    --sportzone-logo-width: clamp(8rem, 25vw, 16rem);
    --background-clr: #f2f4f7;
    font-family: Roboto;
    font-size: 12px;/*clamp(0.75rem, 1.9vw, 1rem);*/
    background-color: var(--background-clr);
}
.sportzone-nav {
  height: 8rem;
  background-color: var(--darker-bckgrnd-color);
  position: relative;
}

.public-layout-main {
    width: 100vw;
    height: calc(100vh - var(--nav-height));
    color: var(--main-font-clr);
    font-size: 1.333em;
    scrollbar-width: none;
    --scrollbar-width: 0;
    display: flex;
    align-items: center;
    margin: var(--nav-height) 0 0 0;
    max-width: 100vw;
}

.public-layout-main::before {
  position: fixed;
  top: calc(var(--nav-height) + 2rem);
  left: 0;
  content: '';
  background-image: url(/rounded-triangle.svg);
  width: 100vw;
  background-size: cover;
  height: 100%;
}

.sportzone-container {
    max-width: var(--max-central-section-width);
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    height: 100%;
    margin: 0 auto;
    background-color: inherit;
}


.sportzone-nav::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  background-color: var(--contour-light-color);
  height: 2px;
  bottom: var(--nav-line-margin-btm);
  z-index: 1;
}

.sportzone-logo {
  display: inline-block;
  margin-bottom: 1rem;
  z-index: 2;
  background-color: inherit;
  padding-right: min(2rem, 4vw);
  padding-left: 0.5rem;
  width: var(--sportzone-logo-width);
  padding-top: 1rem;
}

.public-layout-wrapper .sportzone-logo > a {
  display: flex;
}

.sportzone-logo img {
  width: 100%;
}

.links-section {
    font-size: 1em;
    font-weight: 600;
    text-transform: uppercase;
    margin-left: 0.8em;
    font-family: Roboto;
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.links-section a {
  color: var(--light-homepage-color);
  padding: 0.325rem min(1.325rem, 2vw);
  border-radius: var(--default-border-rad);
  border: 2px solid var(--light-homepage-color);
}


.archive-link {
    background-color: var(--archive-bck-color);
    margin-right: min(0.5rem, max(1rem, 8vw));
    position: relative;
}

.watch-link {
    background-color: var(--watch-bck-color);
    position: relative;
}

/* .links-section a.router-link-exact-active::after {
  content: '';
  width:  90%;
  height: 2px;
  display: block;
  position: absolute;
  left: 3%;
  background-color: white;
  bottom: 0.325rem;
} */

main {
    color: var(--light-homepage-color);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: var(--nav-container-max-width);
    position: relative;
    margin: 0 auto;
    text-align: left;
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.public-layout-wrapper.bwl {
    background: url('../../../public/grey_bowling_icon.svg');
}

.public-layout-wrapper.tbltenn {
    background: url('../../../public/grey_table_tennis_icon.svg');
}

.public-layout-wrapper.tenn {
    background: url('../../../public/grey_tennis_icon.svg');
}

.public-layout-wrapper.vly {
    background: url('../../../public/grey_volleyball_icon.svg');
}

.public-layout-wrapper.bwl,
.public-layout-wrapper.tbltenn,
.public-layout-wrapper.tenn,
.public-layout-wrapper.vly {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 35%;
}

.public-layout-main > h1 {
    background-color: var(--background-clr);
    z-index: 1;
}

@media screen and (min-width: 400px) {
    .links-section {
        font-size: 1.25em;
    }
}


@media screen and (min-width: 480px) {
    .links-section {
        font-size: 1.5em;
        margin-left: max(1em, min(6em, 6.2vw));
        padding-bottom: 0.5rem;
    }
}

@media screen and (min-width: 680px) {

    .public-layout-wrapper {
        --nav-height: 5rem;
        --nav-contour-height: 1.4rem;
    }

}

@media screen and (min-width: 1000px) {
    main {
        padding: 0;
    }
}
</style>