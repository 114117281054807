<template>
    <div class="tennis-watch-wrapper"
        :class="{ 'fallback-fullscreen': isFallbackFullScreenMode, 'fullscreen': isFullScreenMode }">
        <div v-if="!livestream" class="loading-livestream-icon-div">
            <LoadingIcon />
        </div>
        <div class="live-central-panel" v-if="livestream">
            <div class="container-heading-section">
                <div class="partner-logo-wrapper">
                        <a :href="SPORTZONE_MAIN_PAGE_URL"><img src="/sportzone-logo.svg" style="width: 100%;"
                            alt="Sportzone"></a>
                </div>
                <div class="container-title">{{ livestream?.title }}</div>
                <div class="partner-logo-wrapper">
                        <a :href="SPORTZONE_MAIN_PAGE_URL"><img src="/sportzone-logo.svg" style="width: 100%;"
                            alt="Sportzone"></a>
                </div>
            </div>
            <LivePlayer :startPlayingAt="seekPlayerTo" :livePlayerShouldSeekToggle="livePlayerShouldSeekToggle" :endPlayingAt="endVideoTime" :livestream="livestream" :isRecordMode="isRecordModeOn"
                @playerDurationChange="playerDurationChange" @fullscreenToggle="onFullScreenToggle" v-if="livestream"
                :startFullScreen="isFullScreenMode">
                <div class="result-board_main" v-if="getCurrentMatchStats && getCurrentHomeEntity && getCurrentAwayEntity && getCurrentHomeEntity?.id && getCurrentAwayEntity?.id">
                    <div class="result-board_main__home-team">
                        <div class="result-board_main__cell result-board_main__team-name" :class="{ 'is-serving-entity': getCurrentHomeEntity.id === getCurrentMatchStats.matchScores.servingEntityId }">{{
                            getCurrentHomeEntity?.name
                        }}
                        </div>
                            <div class="result-board_main__cell result-board_main__games_cell "  v-for="(setStat, key) in getCurrentMatchStats.matchStats.setsStats" :key="key">
                                <template v-if="setStat.entities[getCurrentHomeEntity.id]">
                                    {{ setStat.entities[getCurrentHomeEntity.id]?.points ?? 0 }}
                                </template>
                                <template v-else>0</template>
                            </div>
                        <div class="result-board_main__cell result-board_main__points_cell">
                            {{ getCurrentHomeEntityPoints }}
                        </div>
                    </div>
                    <div class="result-board_main__away-team">
                        <div class="result-board_main__cell result-board_main__team-name" :class="{ 'is-serving-entity': getCurrentAwayEntity.id === getCurrentMatchStats.matchScores.servingEntityId }">{{
                            getCurrentAwayEntity?.name
                        }}
                        </div>
                        <div class="result-board_main__cell result-board_main__games_cell"  v-for="(setStat, key) in getCurrentMatchStats.matchStats.setsStats" :key="key">
                            <template v-if="setStat.entities[getCurrentAwayEntity.id]">
                                {{ setStat.entities[getCurrentAwayEntity.id]?.points ?? 0 }}
                            </template>
                            <template v-else>0</template>
                        </div>
                        <div class="result-board_main__cell result-board_main__points_cell">
                            {{ getCurrentAwayEntityPoints }}</div>
                    </div>
                </div>
                <div class="break-pane">
                    <div class="break-pane__stats">Statistics</div>
                </div>
            </LivePlayer>
        </div>
        <div class="collapse-btn-wrapper" :class="{'collapsed': isCollapsedRightPanel}" @click="toggleCollapsed"></div>
        <div class="live-right-panel hide-on-full"  :class="{'collapsed': isCollapsedRightPanel}"
        v-if="getCurrentMatchStatsSpoiled && getCurrentMatchStats && getCurrentHomeEntity && getCurrentAwayEntity && getCurrentHomeEntity?.id && getCurrentAwayEntity?.id">
            <div class="main-tennis-stats-wrapper">
                <div class="entity-stat">
                    <div class="home-entity-name">{{ getCurrentHomeEntity?.name }}</div>
                    <div class="away-entity-name">{{ getCurrentAwayEntity?.name }}</div>
                </div>
                <template v-for="(pointTypeFullName, pointTypeAbrv) in TENNIS_POINT_BEAUTIFUL_TITLE_PAIRS" :key="pointTypeAbrv">
                    <div class="entity-stat"  v-if="pointTypeFullName !== 'Point'">
                        <div class="home-entity-stat">
                            {{ getCurrentMatchStats.matchStats.entities[getCurrentHomeEntity.id]?.pointsGroupedByType[pointTypeAbrv]?.count ?? 0 }}
                        </div>
                        <div class="entity-stat-heading" :class="{'selected-type': selectedPointType === pointTypeAbrv}"
                            @click="selectPointTypeClick(pointTypeAbrv)">
                                {{pointTypeFullName}}
                        </div>
                        <div class="away-entity-stat">
                            {{ getCurrentMatchStats.matchStats.entities[getCurrentAwayEntity.id]?.pointsGroupedByType[pointTypeAbrv]?.count ?? 0 }}
                        </div>
                    </div>
                </template>
            </div>
            <div class="tennis-events-section-heading">Interactive Set Timeline</div>
            <div class="tennis-events-section">
                <div class="tennis-game-events" v-for="(gameEventSnapshotScores, key) in getCurrentMatchStatsSpoiled.matchScores.eventsScores[chosenSet > 0 ? chosenSet : getCurrentMatchStats.matchScores.currentSet]" :key="key">
                <div class="tennis-game-event-score" @click="onScoreBoxClick(Object.values(gameEventSnapshotScores)[0]?.event)">
                    <div class="tennis-game-event-score-home">
                        {{ Object.values(gameEventSnapshotScores)[0].gameScore.entities[getCurrentHomeEntity.id].score }}
                    </div>
                    <div class="tennis-game-event-score-separator">:</div>
                    <div class="tennis-game-event-score-away">
                        {{ Object.values(gameEventSnapshotScores)[0].gameScore.entities[getCurrentAwayEntity.id].score }}
                    </div>
                </div>
                    <div class="tennis-game-event" v-for="(eventSnpashotScore, eventID) in gameEventSnapshotScores" 
                    :key="eventID" @click="onScoreBoxClick(eventSnpashotScore.event)" >
                        <div class="tennis-game-event-home" :class="{'selected-type' :  eventSnpashotScore.event.entityId === getCurrentHomeEntity.id && eventIsOfSelectedPointType(eventSnpashotScore.event)}">
                            {{ eventSnpashotScore.pointsScore.entities[getCurrentHomeEntity.id].score }}
                        </div>
                        <div class="tennis-game-event-separator">:</div>
                        <div class="tennis-game-event-away" :class="{'selected-type' :  eventSnpashotScore.event.entityId === getCurrentAwayEntity.id && eventIsOfSelectedPointType(eventSnpashotScore.event)}">
                            {{ eventSnpashotScore.pointsScore.entities[getCurrentAwayEntity.id].score }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent } from "@vue/runtime-core";
import LivePlayer from "@/components/main/LivePlayer.vue";
import PinIcon from "@/components/main/PinIcon.vue";
import Modal from '@/components/main/Modal.vue';
import { Ref, ref} from "vue";
import { ContainerDataModel } from "@/composables/dataModels/ContainerDataModel";
import LiveDataModel from "@/composables/dataModels/LiveDataModel";
import { useRoute } from "vue-router";
import useCurrentContainerComputeds from "@/composables/tennis/UseCurrentContainerComputeds";
import { useStore } from "vuex";
import { key } from '@/store'
import { Event } from '@/models/main/Event';
import { TennisPointType, TENNIS_ACE, TENNIS_POINT_BEAUTIFUL_TITLE_PAIRS, TENNIS_POINT_TITLE_PAIRS } from "@/composables/tennis/TennisEventTypes";
import { SportzoneAuth } from "@/components/util/SportzoneAuth";
import useDataManagerHelpers from "@/composables/dataModels/UseDataManagerHelpers";
import { shouldSeekToValue } from "@/composables/common/Util";
import { SPORTZONE_MAIN_PAGE_URL } from "@/composables/common/Constants";
import LoadingIcon from '@/components/util/LoadingIcon.vue';

export default defineComponent({
    components: {
        LivePlayer,
        PinIcon,
        Modal,
        LoadingIcon,
    },
    setup() {
        const route = useRoute()
        const store = useStore(key)

        const apiUri = SportzoneAuth.apiUri
        const currentContainerId: Ref<number> = ref(0)
        const livestream: Ref<LiveDataModel | null> = ref(null)
        const liveId = parseInt(route.params.id as string);
        const isSpoiledModeOn: Ref<boolean> = ref(route.query.unspoiled !== "1")
        const isFullScreenMode: Ref<boolean> = ref(false)
        const isFallbackFullScreenMode: Ref<boolean> = ref(false) 
        const livePlayerShouldSeekToggle: Ref<boolean> = ref(false)
        const isCollapsedRightPanel: Ref<boolean> = ref(true)
        const isRecordModeOn: Ref<boolean> = ref(route.query.record == "1")

        let startReplayEventId: number | undefined = parseInt(route.query?.startEvent as string)
        let endReplayEventId: number | undefined = parseInt(route.query?.endEvent as string)
        let startVideoTime: number | undefined = parseInt(route.query?.start as string)
        let endVideoTime: number | undefined = parseInt(route.query?.end as string)
        if (isNaN(startVideoTime)) {
            startVideoTime = undefined
        }
        if (isNaN(endVideoTime)) {
            endVideoTime = undefined
        }
        if (startVideoTime !== undefined || isNaN(startReplayEventId)) {
            startReplayEventId = undefined
        }
        if (endVideoTime !== undefined || isNaN(endReplayEventId)) {
            endReplayEventId = undefined
        }
        const playerCurrentUnixTime: Ref<number> = ref(0)
        const seekPlayerTo: Ref<number> = ref(startVideoTime !== undefined ? startVideoTime : 0)
        const advanceSecs = 8

        const {
            initDataManager
        } = 
        useDataManagerHelpers(store)
        initDataManager(liveId).then((result) => {
            if(result?.livestreamDataModel) {
                livestream.value = result.livestreamDataModel
            }
            if (result?.containerDataModel !== undefined && result.containerDataModel.id !== undefined) {
                currentContainerId.value = result.containerDataModel.id
            }

            console.log(getCurrentMatchStatsSpoiled.value)
        })

        const {
            getCurrentMatchStats,
            getCurrentMatchStatsSpoiled,
            getCurrentContainer,
            getCurrentHomeEntity,
            getCurrentAwayEntity,
            getCurrentHomeEntityPoints,
            getCurrentAwayEntityPoints,
            currentHomeEntityGamePoints,
            currentAwayEntityGamePoints,
        } = useCurrentContainerComputeds(store, currentContainerId, livestream, playerCurrentUnixTime, isSpoiledModeOn)

        const chosenSet: Ref<number> = ref(getCurrentMatchStats.value?.matchScores.currentSet ?? 0)
        const onChooseSetClick = (setNum: string) => {
            chosenSet.value = parseInt(setNum) ?? getCurrentMatchStats.value?.matchScores.currentSet
            if(chosenSet.value === getCurrentMatchStats.value?.matchScores.currentSet) {
                chosenSet.value = 0
            }
        }

        const selectedPointType: Ref<TennisPointType | undefined> = ref()
        const eventIsOfSelectedPointType = (event: Event) => {
            const pointType = event.description as TennisPointType
            if(pointType === selectedPointType.value) {
                return true
            }

            return false
        }

        const selectPointTypeClick = (pointType: TennisPointType) => {
            selectedPointType.value = pointType
        }

        const seekPlayer = (event: Event) => {
            if(livestream.value) {
                const shouldSeekTo = shouldSeekToValue(event, livestream.value, advanceSecs)
                if(shouldSeekTo) {
                    if(seekPlayerTo.value === shouldSeekTo) {
                        livePlayerShouldSeekToggle.value = !livePlayerShouldSeekToggle.value // trick to force Live player to seek even if the value is the same
                    }
                    seekPlayerTo.value = shouldSeekTo

                }
            }
        }

        const onFullScreenToggle = (isFullScreen: boolean, isFallbackFullScreen: boolean) => {
            isFullScreenMode.value = isFullScreen
            isFallbackFullScreenMode.value = isFallbackFullScreen
        }

        const onScoreBoxClick = (event: Event) => {
            seekPlayer(event)
        }

        const playerDurationChange = (currentDuration: number) => {
            let liveStarttime = 0
            if (livestream.value?.streamActualStartTime) {
                liveStarttime = new Date(livestream.value.streamActualStartTime).getTime() / 1000
            }

            playerCurrentUnixTime.value = currentDuration + liveStarttime - advanceSecs
        }

        const toggleCollapsed = () => {
            isCollapsedRightPanel.value = !isCollapsedRightPanel.value
        }

        return {
            store,
            livestream,
            currentContainerId,
            isFullScreenMode,
            isFallbackFullScreenMode,
            chosenSet,
            livePlayerShouldSeekToggle,
            selectedPointType,
            isRecordModeOn,
            eventIsOfSelectedPointType,
            playerDurationChange,
            onChooseSetClick,
            onScoreBoxClick,
            onFullScreenToggle,
            selectPointTypeClick,
            toggleCollapsed,
            endVideoTime,
            seekPlayerTo,
            getCurrentMatchStats,
            getCurrentMatchStatsSpoiled,
            getCurrentContainer,
            getCurrentHomeEntity,
            getCurrentAwayEntity,
            getCurrentHomeEntityPoints,
            getCurrentAwayEntityPoints,
            currentHomeEntityGamePoints,
            currentAwayEntityGamePoints,
            TENNIS_POINT_TITLE_PAIRS,
            TENNIS_POINT_BEAUTIFUL_TITLE_PAIRS,
            apiUri,
            SPORTZONE_MAIN_PAGE_URL,
            isCollapsedRightPanel,
        }
    }
})
</script>
<style>
.tennis-watch-wrapper {
    --vly-watch-min-height: 105vh;
    height: var(--vly-watch-min-height);
    color: var(--light-homepage-color);
    --player-def-width: 1584px;
    --player-def-height: 891px;
    --right-panel-width: 21em;
    overflow-y: scroll;
    scrollbar-width: none;
    background-color: var(--darker-bckgrnd-color);
    font-size: 0.9em;
}

.tennis-watch-wrapper .live-central-panel {
    width: 100%;
}

.tennis-watch-wrapper .stream-player {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.tennis-watch-wrapper::-webkit-scrollbar {
    display: none;
}

.tennis-watch-wrapper::after {
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
}

.tennis-watch-wrapper .logos-wrapper {
    display: none;
}

.tennis-watch-wrapper .result-board_main {
  bottom: 7ch;
  left: 3ch;
  font-size: 1em;
  top: auto;
  display: flex;
}

.collapse-btn-wrapper {
    position: absolute;
    top: min(25%, 5rem);
    right: var(--right-panel-width);
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    background-image: url(/rounded-light-blue-triangle.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.collapse-btn-wrapper.collapsed {
    transform: rotate(180deg);
    right: 0;
}

.tennis-watch-wrapper .container-heading-section {
  display: none;
}

.partner-logo-wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25em 0.5em;
    width: 6em;
}
.partner-logo-wrapper a {
    height: min(2em, 100%);
}
.partner-logo-wrapper img {
    max-height: 100%;
    max-width: 100%;
}

.tennis-watch-wrapper .result-board_main__team-name.is-serving-entity {
    position: relative;
}

.tennis-watch-wrapper .result-board_main__team-name.is-serving-entity::after {
    content: '';
    position: absolute;
    width: 1ch;
    height: 1ch;
    top: 0.5ch;
    right: 0.5ch;
    border-radius: 50%;
    background-color: var(--archive-bck-color);
}

.tennis-watch-wrapper .live-right-panel {
    display: flex;
    position: absolute;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: calc(100% - 6em);
    right: 0;
    background-color: var(--darker-bckgrnd-color);
    opacity: 0.7;
    overflow-y: auto;
}

.tennis-watch-wrapper .live-right-panel.collapsed {
  width: 0;
  font-size: 0;
}

.tennis-watch-wrapper.fullscreen.fallback-fullscreen .stream-player {
    width: 100%;
    height: var(--vly-watch-min-height);
}

.tennis-watch-wrapper.fallback-fullscreen.fullscreen .live-central-panel {
  width: 100%;
}

.tennis-watch-wrapper .result-board_main__team-name {
    width: 20ch;
    text-align: left;
    padding: 0.5ch 1ch;
    background-color: var(--deep-blue-bck-color);
    border-right: 2px solid #cecccc;
}

.tennis-watch-wrapper .result-board_main__games_cell {
    background-color: var(--deep-blue-bck-color);
    width: 4ch;
    padding: 0.5ch 0;
}

.tennis-watch-wrapper .result-board_main__points_cell {
    background-color: var(--archive-bck-color);
    color: var(--light-homepage-color);
    width: 4ch;
    padding: 0.5ch 0;
}

.tennis-player-scoreboard {
    display: flex;
}
.tennis-player-scoreboard .player-score {
    display: flex;
}
.tennis-player-scoreboard .player-game-score {
    width: 4ch;
    border-left: 1px solid #cecccc;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.tennis-player-scoreboard .team-name {
    width: 20ch;
    padding: 0.5ch 1ch;
    background-color: var(--deep-blue-bck-color);
    color: white;
    border: 1px solid #cecccc;
    font-size: 0.9em;
}

.tennis-player-scoreboard .player-sets {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #cecccc;
    border-bottom: 1px solid #cecccc;
    background-color: var(--deep-blue-bck-color);
}

.tennis-player-scoreboard .player-current-game-score {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cecccc;
  width: 4ch;
  background-color: var(--archive-bck-color);
}

.tennis-watch-wrapper .main-scoreboard {
  font-size: 1.25em;
  width: 35ch;
}


.main-tennis-stats-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 53.25ch;
    width: 100%;
    flex-direction: column;
}

.main-tennis-stats-wrapper .entity-stat {
    width: 100%;
    background-color: var(--deep-blue-bck-color);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4ch;
    font-size: 1.25em;
}

.main-tennis-stats-wrapper .entity-stat-headings {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 7.1ch;
  font-size: 0.9em;
}

.main-tennis-stats-wrapper .entity-stat-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  font-size: 0.85em;
}

.main-tennis-stats-wrapper .entity-stat-heading.selected-type {
  color: var(--btn-error-background-color);
  font-weight: bold;
}

.main-tennis-stats-wrapper .entity-stat-heading, .main-tennis-stats-wrapper .home-entity-stat, .main-tennis-stats-wrapper .away-entity-stat {
    width: 33.33%;
}

.main-tennis-stats-wrapper .home-entity-name, .main-tennis-stats-wrapper .away-entity-name {
  width: 50%;
  text-align: center;
}

.main-tennis-stats-wrapper .home-entity-stat, .main-tennis-stats-wrapper .away-entity-stat {
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-tennis-stats-wrapper .entity-stat + .entity-stat {
  margin-top: 1.5ch;
}

.team-name.is-serving-entity {
    position: relative;
}

.team-name.is-serving-entity::after {
    content: '';
    position: absolute;
    width: 1ch;
    height: 1ch;
    top: 0.5ch;
    right: 0.5ch;
    border-radius: 50%;
    background-color: var(--archive-bck-color);
}

.tennis-game-event-score {
  background-color: var(--archive-bck-color);
  font-size: 1.25em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--light-homepage-color);
  font-weight: bold;
  padding: 0.25ch 0;
}

.tennis-events-section-heading {
  font-size: 1.25em;
  height: 4ch;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: black;
  color: var(--archive-bck-color);
  font-weight: 500;
}

.tennis-events-section {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  scrollbar-width: thin;
  font-size: 1em;
}

.tennis-game-events {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.tennis-game-event {
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tennis-game-event:nth-child(2n) {
  background: var(--deep-blue-bck-color);
}

.tennis-game-event-score {
  background-color: var(--archive-bck-color);
  font-size: 1.25em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tennis-game-event-score-home, .tennis-game-event-score-away, .tennis-game-event-home, .tennis-game-event-away {
  width: calc((100% - 1ch) / 2);
  display: flex;
  justify-content: center;
}

.tennis-game-event-home.selected-type, .tennis-game-event-away.selected-type {
  position: relative;
}

.tennis-game-event-home.selected-type::before {
  content: '';
  background-color: var(--btn-error-background-color);
  width: 2ch;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.tennis-game-event-away.selected-type::before {
  content: '';
  background-color: var(--btn-error-background-color);
  width: 2ch;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}



.tennis-game-event.active {
    position: relative;
}

.tennis-game-event:hover {
  background: #38778E;
}

.tennis-game-event-home, .tennis-game-event-score-home {
  justify-content: flex-end;
  padding-right: 1ch;
}

.tennis-game-event-away, .tennis-game-event-score-away {
  justify-content: flex-start;
  padding-left: 1ch;
}
.tennis-game-event.active::before {
    content: '';
    width: 3ch;
    top: 0;
    bottom: 0;
    left: 0ch;
    background-color: var(--spz-yellow-clr);
    position: absolute;
}

/* SHARE MODAL CSS */
.tennis-watch-wrapper .modal__dialog {
    background-color: var(--darker-bckgrnd-color);
}

.tennis-watch-wrapper .modal__body {
    min-height: auto;
}

.tennis-watch-wrapper .modal__footer .btn {
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    font-size: 1em;
}

.tennis-watch-wrapper .link-section {
    padding: 1em;
    background-color: var(--light-bckgrnd-color);
    border-radius: 5px;
}
/*END */
@media screen and (min-height: 450px) {
    .tennis-watch-wrapper {
        --vly-watch-min-height: 100vh;
    }
}

@media screen and (min-width: 620px) {
    .tennis-watch-wrapper .live-right-panel {
        height: calc(100% - 1em);
    }
}

@media screen and (min-height: 620px)  {
    .tennis-events-section {
        height: calc(100% - 57.25ch);
        overflow-y: auto;
    }
}

@media screen and (min-width: 920px) {

    .collapse-btn-wrapper {
        display: none;
    }

    .tennis-watch-wrapper .live-right-panel.collapsed {
        width: var(--right-panel-width);
        font-size: 1em;
    }

    .tennis-watch-wrapper .live-right-panel {
        display: flex;
        width: var(--right-panel-width);
        opacity: 1;
        height: 100%;
    }

    .tennis-watch-wrapper .live-central-panel {
        width: calc(100% - var(--right-panel-width));
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        background-color: black;
        flex-direction: column;
    }

    .tennis-watch-wrapper .container-heading-section {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        height: calc(100% - ((100vw - var(--right-panel-width)) * 0.5625));
        min-height: 2.25em;
    }

    .partner-logo-wrapper {
        width: 10em;
    }

    .tennis-watch-wrapper .container-title {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        font-size: 1.5em;
        font-weight: bold;
        width: calc(100% - 20em);
        line-height: 1.5em;
        height: min(100%, 3em);
        align-self: center;
    }

    .tennis-watch-wrapper .stream-player {
        height: calc((100vw - var(--right-panel-width)) * 0.5625);
    }

    .tennis-watch-wrapper {
        font-size: 1em;
        --vly-watch-min-width: 100vw;
    }

    .tennis-watch-wrapper .main-tennis-stats-wrapper {
        display: flex;
    }
}

@media screen and (min-width: 1080px) {
    .tennis-watch-wrapper .result-board_main {
        bottom: 4.5ch;
        left: 4.5ch;
    }

    .tennis-watch-wrapper .result-board_main {
        font-size: 1.25em;
    }
    .tennis-watch-wrapper .main-scoreboard {
        font-size: 1.8em;
    }

    .home-entity-stats, .away-entity-stats {
        font-size: 1.5em;
    }

    .main-tennis-stats-wrapper .entity-stat-headings {
        height: 4.5ch;
        font-size: 1em;
    }
}

@media screen and (min-width: 1280px) {
    .tennis-watch-wrapper .main-scoreboard-wrapper {
        width: 100%;
    }

    .tennis-watch-wrapper .logos-wrapper {
        display: flex;
    }
}
</style>