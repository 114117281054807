<template>
    <transition name="fade">
        <div class="modal" v-if="show">
            <div class="modal__backdrop" @click="closeModal()" />

            <div class="modal__dialog">
                <div class="modal__header">
                    <slot name="header" />
                    <button type="button" class="btn modal__close" @click="closeModal()">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                            <path fill="currentColor"
                                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z">
                            </path>
                        </svg>
                    </button>
                </div>

                <div class="modal__body">
                    <slot name="body" />
                </div>

                <div class="modal__footer">
                    <slot name="footer" />
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
    name: "Modal",
    data() {
        return {
            show: false
        };
    },
    methods: {
        closeModal() {
            this.show = false;
        },
        openModal() {
            this.show = true;
        }
    }
});
</script>


<style>
.modal {
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
}

.modal__backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.modal__dialog {
    background-color: #59a49d;
    position: fixed;
    width: 800px;
    margin: auto;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    z-index: 2;
    top: 1rem;
    left: calc((100% - 800px) / 2);
    max-height: calc(100dvh - 2rem);
}

.modal-large .modal__dialog {
    width: 90vw;
    left: calc((100% - 90vw) / 2);
}

@media screen and (max-width: 992px) {
    .modal__dialog {
        width: 90%;
        left: 5%;
    }
}

.modal__close {
    width: 30px;
    height: 30px;
    padding: 0.325em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--bright-font-clr);
    color: var(--btn-darker-background-color);
}

button.modal__close>svg {
    max-height: 100%;
}

.modal__header {
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ece6e6;
}

.modal__header h1 {
    margin: 0;
}

.modal__body {
    padding: 10px 20px 10px;
    overflow: auto;
    display: flex;
    /*! flex-direction: column; */
    /*! align-items: stretch; */
    max-height: 80vh;
    min-height: min(100%, 580px);
    flex-wrap: wrap;
    justify-content: space-between;
}

.modal__body>*:last-child {
    margin-bottom: 0;
}

.modal__footer {
    padding: 10px 20px 20px;
    display: flex;
    justify-content: flex-end;
}


.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>