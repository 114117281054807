<template>
    <TheOrganizerDashboard title="Channels">
        <div class="controls">
            <button @click="$refs.channelPopup.openModal()" class="controls__create-channel-btn btn add-btn"
                title="Create">Channel</button>
        </div>
        <div class="channels-list">
            <template v-if="getUserChannels">
                <div class="channels-list__item" v-for="channel in getUserChannels" :key="channel.id">
                    <div class="channels-list__item_name">{{ channel.name }}</div>
                    <div class="channels-list__item_controls">
                        <button role="button" @click="deleteChannel(channel.id)"
                            class="channels-list__item_controls_delete btn delete-btn" title="Delete">Delete</button>
                    </div>
                </div>
            </template>
        </div>
        <Modal ref="channelPopup">
            <template v-slot:header>
                <h1>Create Channel</h1>
            </template>
            <template v-slot:body>
                <div class="popup__input-group spz-text-input-group">
                    <label for="channel-name">Channel Name</label>
                    <input id="channel-name" v-model="newChannel.name" type="text" placeholder="Name" />
                </div>
                <div class="popup__input-group">
                    <label for="use-default-channel">Use Sportzone Channel</label>
                    <input type="checkbox" id="use-default-channel" v-model="useSportzoneChannel" />
                </div>
                <div v-show="!useSportzoneChannel">
                    <button v-show="!accessCode" @click="handleAuthClick" id="authorize-button" class="g-signin-button">Sign
                        in with Google</button>
                        <button v-show="!accessCode" @click="onSignedIn" id="authorize-button" class="g-signin-button">Get Code</button>
                    <div class="popup__input-group">
                        <div class="spz-select-group" v-show="userYoutubeChannels">
                            <label for="youtube-channel">Youtube Channel</label>
                            <select id="youtube-channel" v-model="newChannel.channelPlatformUId">
                                <option disabled value="">Select Channel</option>
                                <option v-for="youtubeChannel in userYoutubeChannels" :key="youtubeChannel.id"
                                    :value="youtubeChannel.id">{{ youtubeChannel.snippet.title }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div>
                    <button @click="$refs.channelPopup.closeModal(), saveChannel()" class="btn add-btn">Create</button>
                    <button @click="$refs.channelPopup.closeModal()" class="btn btn-inverse">Cancel</button>
                </div>
            </template>
        </Modal>
    </TheOrganizerDashboard>
</template>
<script lang="ts">
import TheOrganizerDashboard from '@/components/main/TheOrganizerDashboard.vue';
import Modal from '@/components/main/Modal.vue';
import { ref, defineComponent, Ref, onMounted } from 'vue';
import { createNamespacedHelpers, useStore } from 'vuex';
import { key } from '@/store';
import SportzoneGoogleClient from '@/components/util/GoogleOAuth';
import Channel from '@/models/main/Channel';
const userStoreHelpers = createNamespacedHelpers('userStore');
export default defineComponent({
    components: {
        TheOrganizerDashboard,
        Modal,
    },
    computed: {
        ...userStoreHelpers.mapGetters([
            'getId',
            'getUserChannels',
            'getUser',
        ]),
    },
    watch: {
        getId(userId: number) {
            this.store.dispatch('userStore/getUserChannels', userId);
        },
    },
    setup() {
        const store = useStore(key);
        const sportzoneGoogleAuthHelper: SportzoneGoogleClient = SportzoneGoogleClient.instance;
        const scopesForYoutubeChannel = 'https://www.googleapis.com/auth/youtube.readonly'//''https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.force-ssl https://www.googleapis.com/auth/youtube.readonly';
        const discoveryDocs: string[] = [];
        let accessCode: Ref<string | null> = ref(null);
        const userYoutubeChannels: Ref<Array<any>> = ref([]);
        const useSportzoneChannel: Ref<boolean> = ref(true);
        const channel: Channel = {
            id: null,
            name: null,
            channelPlatformUId: '',
            streamingEndpoints: [],
        };
        const newChannel: any = ref(channel);
        let streamingPlatform = 'YouTube';
        const signoutButton = ref(null);
        const deleteChannel = (channelId: number) => {
            store.dispatch('userStore/deleteChannel', channelId);
        };
        const handleAuthClick = () => {
            sportzoneGoogleAuthHelper.googleClient(scopesForYoutubeChannel, discoveryDocs, onSignedIn);
        };
        const onSignedIn = () => {
            console.info('On signed in');
            const googleClient = sportzoneGoogleAuthHelper.client;
            googleClient.grantOfflineAccess()
                .then((response) => {
                    if (response.code) {
                        accessCode.value = response.code;
                    }
                    else {
                        console.error('Something wrong with token from Google!');
                    }
                    console.log(response);
                    fetchYoutubeChannels();
                });
        };
        const fetchYoutubeChannels = () => {
            sportzoneGoogleAuthHelper
                .listChannels()
                .then((result) => {
                    const channels = result
                        ? (result.items
                            ? result.items
                            : [])
                        : [];
                    userYoutubeChannels.value = channels;
                });
        };
        const saveChannel = () => {
            const accessCodeToPass = useSportzoneChannel.value ? '' : accessCode.value;
            store.dispatch('userStore/saveChannel', {
                channel: newChannel.value,
                accessCode: accessCodeToPass,
                streamingPlatform,
            })
                .then(() => {
                    fetchChannels();
                    accessCode.value = null;
                });
        };
        const fetchChannels = () => {
            store.dispatch('userStore/getUserChannels', store.getters['userStore/getId']);
        };
        onMounted(fetchChannels);
        const handleSignoutClick = () => {
            sportzoneGoogleAuthHelper.client.signOut();
        };
        return {
            deleteChannel,
            newChannel,
            useSportzoneChannel,
            accessCode,
            handleAuthClick,
            onSignedIn,
            userYoutubeChannels,
            saveChannel,
            store,
            scopesForYoutubeChannel,
            signoutButton,
            fetchYoutubeChannels,
            handleSignoutClick,
        };
    },
});
</script>
<style>
.channels-list {
    width: 100%;
    float: left;
    margin-top: 1em;
}

.channels-list__item {
    padding: 10px;
    border-radius: var(--default-border-rad);
    text-align: left;
    background-color: var(--bright-font-clr);
    margin: 0.25em;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    margin: var(--badge-item-margin);
}

.channels-list__item.selected {
    color: white;
    font-weight: bold;
}

.controls {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
}
</style>