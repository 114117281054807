import { Store } from 'vuex';
import { IRootState } from '@/store';
import { Ref, computed } from '@vue/runtime-core';
import { Container } from '@/models/main/Container';
import { Entity } from '@/models/main/Entity';
export default function useCurrentContainerComputeds(store: Store<IRootState>, currentContainerId: Ref<number>) {
    const getCurrentContainer = computed((): Container | undefined => {
        return store.getters['containerStore/getContainer'](currentContainerId.value);
    });
    const getCurrentHomeEntity = computed((): Entity | undefined => {
        return store.getters['containerStore/getHomeEntity'](currentContainerId.value);
    });
    const getCurrentAwayEntity = computed((): Entity | undefined => {
        return store.getters['containerStore/getAwayEntity'](currentContainerId.value);
    });
    return {
        getCurrentContainer,
        getCurrentHomeEntity,
        getCurrentAwayEntity,
    };
};