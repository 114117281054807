import { Event } from '@/models/main/Event';
import GenericDictionary from '@/components/util/GenericDictionary';
import { BADMINTON_COEAR, BADMINTON_DRIVE, BADMINTON_DROP, BADMINTON_FAULT, BADMINTON_IS_POSITIVE_POINT, BADMINTON_OTHER, BADMINTON_SHORT, BADMINTON_SMASH, BADMINTON_SMASH_NET, BADMINTON_SRV_FAULT, BadmintonGameControlType, BadmintonPointType } from './BadmintonEventTypes';

export type BasicBadmintonStat = GenericDictionary<{ pointType: BadmintonPointType, count: number }>

export default function useInterpretTableTennisEvents() {
    const calculateEntityPoints = (entityId: number, events: GenericDictionary<Event>, eventsBefore?: number, forSpecificGameOnly?: number): number => {
        let points = 0
        let currentGame = 1
        for(const i in events) {
            const event = events[i]
            const eventTimeStartInUnixSeconds = new Date(event.startTimestamp ?? 0).getTime() / 1000
            if(eventsBefore === undefined || eventTimeStartInUnixSeconds <= eventsBefore) {
                const eventEntityId = event.entityId
                if(event.type === "Point" && (forSpecificGameOnly === undefined || currentGame === forSpecificGameOnly)) {
                    const pointType = event.description as unknown as BadmintonPointType | null
                    const pointIsPoisitive = pointType === null ? true : BADMINTON_IS_POSITIVE_POINT[pointType];
                    if(pointIsPoisitive && eventEntityId == entityId) {
                        points++
                    }
                    if(!pointIsPoisitive && eventEntityId !== entityId) {
                        points++
                    }
                } else if (event.type === "GameControl") {
                    const gameControlType = event.description as unknown as BadmintonGameControlType
                    if(gameControlType === "Bdm_G" || gameControlType === "TblTenn_G") {
                        if(forSpecificGameOnly !== undefined && currentGame === forSpecificGameOnly) {
                            return points
                        } else {
                            points = 0
                            currentGame++
                        }
                    }
                }
            }

        }

        return points
    }

    const groupEventsByGames = (homeEntityId: number, awayEntityId: number, events: GenericDictionary<Event>, currentTime?: number, eventsBefore?: number) => {
        let currentGame = 1
        let homePoints = 0
        let awayPoints = 0
        let prevEventWasGame = false
        const groupedEvents: GenericDictionary<{ isLive: boolean, events: Array<{event: Event, homeTeamScore: number, awayTeamScore: number, isLive: boolean}> }> = {}
        groupedEvents[currentGame] = { isLive: false, events: [] }
        let eventsArrIndex = 0
        let closestToLiveGame = 1
        let closestToLiveEventIndex = 0
        for(const i in events) {
            const eventIsLive = false
            const event = events[i]
            const eventTimeStartInUnixSeconds = new Date(event.startTimestamp ?? 0).getTime() / 1000
            if(eventsBefore === undefined || eventTimeStartInUnixSeconds <= eventsBefore) {
                const eventEntityId = event.entityId
                if(event.type === "Point") {
                    if(prevEventWasGame) {
                        currentGame++
                        homePoints = 0
                        awayPoints = 0
                        prevEventWasGame = false
    
                        if(!Object.prototype.hasOwnProperty.call(groupedEvents, currentGame)) {
                            groupedEvents[currentGame] = { isLive: false, events: [] }
                            eventsArrIndex = 0
                        }
                    }
                    const pointType = event.description as unknown as BadmintonPointType | null
                    const pointIsPoisitive = pointType === null ? true : BADMINTON_IS_POSITIVE_POINT[pointType];
                    if(pointIsPoisitive) {
                        if(eventEntityId == homeEntityId) {
                            homePoints++
                        } else {
                            awayPoints++
                        }
                    } else {
                        if(eventEntityId == homeEntityId) {
                            awayPoints++

                        } else {
                            homePoints++
                        }
                    }

                    if(currentTime !== undefined && (currentGame > 1 || eventsArrIndex > 0)) { // we skip first of first game as it is default one
                        const untilNowBestLiveEvent = groupedEvents[closestToLiveGame] && groupedEvents[closestToLiveGame].events[closestToLiveEventIndex] ? groupedEvents[closestToLiveGame].events[closestToLiveEventIndex].event : null
                        if(untilNowBestLiveEvent  !== null) {
                            const bestLiveEventTime = new Date(untilNowBestLiveEvent.startTimestamp ?? 0).getTime() / 1000
                            if(eventTimeStartInUnixSeconds <= currentTime && eventTimeStartInUnixSeconds > bestLiveEventTime) {
                                closestToLiveGame = currentGame
                                closestToLiveEventIndex = eventsArrIndex
                            }
                        }
                    }

                    groupedEvents[currentGame].events.push({event, homeTeamScore: homePoints, awayTeamScore: awayPoints, isLive: eventIsLive})
                    eventsArrIndex = groupedEvents[currentGame].events.length
                } else if (event.type === "GameControl") {
                    const gameControlType = event.description as unknown as BadmintonGameControlType
                    if(gameControlType === "Bdm_G" || gameControlType === "TblTenn_G") {
                        prevEventWasGame = true
                    }
                }
            }
        }
        groupedEvents[closestToLiveGame].isLive = true
        if(groupedEvents[closestToLiveGame].events.length > 0) {
            groupedEvents[closestToLiveGame].events[closestToLiveEventIndex].isLive = true
        }


        return groupedEvents
    }

    const countBasicStats = (homeEntityId: number, awayEntityId: number, events: GenericDictionary<Event>, eventsBefore?: number) => {

        type BasciStatsTuple = {
            homeEntity: GenericDictionary<{ entityStats: BasicBadmintonStat, playersStats: GenericDictionary<BasicBadmintonStat> }>,
            awayEntity: GenericDictionary<{ entityStats: BasicBadmintonStat, playersStats: GenericDictionary<BasicBadmintonStat> }>,
        }

        const homeEntityStat = basicStatObj()

        
        const awayEntityStat = basicStatObj()
        
        const basicStatsGroupedByGames: BasciStatsTuple = {
            homeEntity: { 0 : { entityStats: homeEntityStat, playersStats: {} } },
            awayEntity: { 0 : { entityStats: awayEntityStat, playersStats: {} } }
        }

        let currentGame = 1
        for(const i in events) {
            const event = events[i]
            const eventTimeStartInUnixSeconds = new Date(event.startTimestamp ?? 0).getTime() / 1000
            if(eventsBefore === undefined || eventTimeStartInUnixSeconds <= eventsBefore) {
                const eventEntityId = event.entityId
                if(event.type === "Point") {
                    let pointType = event.description as unknown as BadmintonPointType | null
                    if(pointType === undefined || pointType === null) {
                        pointType = BADMINTON_OTHER
                    }
    
                    if(eventEntityId == homeEntityId) {
                        basicStatsGroupedByGames.homeEntity[0].entityStats[pointType].count++

                        if(!Object.prototype.hasOwnProperty.call(basicStatsGroupedByGames.homeEntity, currentGame)) {
                            const homeEnStat = basicStatObj()
                            basicStatsGroupedByGames.homeEntity[currentGame] = { entityStats: homeEnStat, playersStats: {} }
                        }
    
                        basicStatsGroupedByGames.homeEntity[currentGame].entityStats[pointType].count++
    
                        if(event.playerId) {
                            const currentPlayerId = event.playerId

                            if(!Object.prototype.hasOwnProperty.call(basicStatsGroupedByGames.homeEntity[0].playersStats, currentPlayerId)) { //match stat (total)
                                const playerStat = basicStatObj()
                                basicStatsGroupedByGames.homeEntity[0].playersStats[currentPlayerId] = playerStat
                            }
    
                            if(!Object.prototype.hasOwnProperty.call(basicStatsGroupedByGames.homeEntity[currentGame].playersStats, currentPlayerId)) {
                                const playerStat = basicStatObj()
                                basicStatsGroupedByGames.homeEntity[currentGame].playersStats[currentPlayerId] = playerStat
                            }
    
                            basicStatsGroupedByGames.homeEntity[0].playersStats[currentPlayerId][pointType].count++
                            basicStatsGroupedByGames.homeEntity[currentGame].playersStats[currentPlayerId][pointType].count++
                        }
                    } else if(eventEntityId == awayEntityId) {
                        basicStatsGroupedByGames.awayEntity[0].entityStats[pointType].count++
    
                        if(!Object.prototype.hasOwnProperty.call(basicStatsGroupedByGames.awayEntity, currentGame)) {
                            const awayEnStat = basicStatObj()
                            basicStatsGroupedByGames.awayEntity[currentGame] = { entityStats: awayEnStat, playersStats: {} }
                        }
    
                        basicStatsGroupedByGames.awayEntity[currentGame].entityStats[pointType].count++
    
                        if(event.playerId) {
                            const currentPlayerId = event.playerId
    
                            if(!Object.prototype.hasOwnProperty.call(basicStatsGroupedByGames.awayEntity[0].playersStats, currentPlayerId)) { // match stat (total)
                                const playerStat = basicStatObj()
                                basicStatsGroupedByGames.awayEntity[0].playersStats[currentPlayerId] = playerStat
                            }
                            if(!Object.prototype.hasOwnProperty.call(basicStatsGroupedByGames.awayEntity[currentGame].playersStats, currentPlayerId)) {
                                const playerStat = basicStatObj()
                                basicStatsGroupedByGames.awayEntity[currentGame].playersStats[currentPlayerId] = playerStat
                            }
    
                            basicStatsGroupedByGames.awayEntity[0].playersStats[currentPlayerId][pointType].count++
                            basicStatsGroupedByGames.awayEntity[currentGame].playersStats[currentPlayerId][pointType].count++
                        }
                    } else {
                        console.error("Something is not right!")
                    }
                } else if (event.type === "GameControl") {
                    const gameControlType = event.description as unknown as BadmintonGameControlType
                    if((gameControlType === "Bdm_G" || gameControlType === "TblTenn_G")) {
                        currentGame++
                    }
                }
            }
        }
        return basicStatsGroupedByGames
        
    }

    const basicStatObj = () => {
        const basicStatObj: BasicBadmintonStat = {}
        basicStatObj[BADMINTON_SMASH] = { pointType: BADMINTON_SMASH, count: 0 }
        basicStatObj[BADMINTON_COEAR] = { pointType: BADMINTON_COEAR, count: 0 }
        basicStatObj[BADMINTON_DROP] = { pointType: BADMINTON_DROP, count: 0 }
        basicStatObj[BADMINTON_SHORT] = { pointType: BADMINTON_SHORT, count: 0 }
        basicStatObj[BADMINTON_SMASH_NET] = { pointType: BADMINTON_SMASH_NET, count: 0 }
        basicStatObj[BADMINTON_DRIVE] = { pointType: BADMINTON_DRIVE, count: 0 }
        basicStatObj[BADMINTON_OTHER] = {pointType: BADMINTON_OTHER, count: 0}
        basicStatObj[BADMINTON_FAULT] = {pointType: BADMINTON_FAULT, count: 0}
        basicStatObj[BADMINTON_SRV_FAULT] = {pointType: BADMINTON_SRV_FAULT, count: 0}

        return basicStatObj
    }

    const calculateEntityGames = (entityId: number, events: GenericDictionary<Event>, eventsBefore?: number): number => {
        let games = 0
        for(const i in events) {
            const event = events[i]
            const eventTimeStartInUnixSeconds = new Date(event.startTimestamp ?? 0).getTime() / 1000
            if(eventsBefore === undefined || eventTimeStartInUnixSeconds <= eventsBefore) {
                const eventEntityId = event.entityId
                if (event.type === "GameControl") {
                    const gameControlType = event.description as unknown as BadmintonGameControlType
                    if((gameControlType === "Bdm_G" || gameControlType === "TblTenn_G") && eventEntityId === entityId) {
                        games++
                    }
                }
            }
        }

        return games
    }

    return {
        calculateEntityPoints,
        calculateEntityGames,
        groupEventsByGames,
        countBasicStats,
    }
}