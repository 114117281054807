<template>
    <TheOrganizerDashboard>
        <template #heading>
            <router-link :to="{ name: 'Organizations' }">
                <div class="page-heading">
                    Organizations
                </div>
            </router-link>
            <router-link :to="{ name: 'Organization Menu', params: { id: organizationId } }">
                <div class="page-heading">
                    {{ organization?.name }}
                </div>
            </router-link>
            <router-link :to="{ name: 'Organization Containers', params: { organizationId: organizationId } }">
                <div class="page-heading">
                    Matches
                </div>
            </router-link>
        </template>
        <template #default>
            <div class="container-actions">
                <div class="filter-group">
                    <SportzoneSelect @selectOptionChange="loadContainers" id="matches-status"
                        :options="['Status', ...CONTAINER_STATUSES]" v-model:selectedOption="containerStatus">
                    </SportzoneSelect>
                    <SportzoneSelect v-if="false" @selectOptionChange="loadContainers" id="matches-type"
                        :options="['Type', ...CONTAINER_TYPES]" v-model:selectedOption="containerType">
                    </SportzoneSelect>
                    <!-- <SportzoneSelect @selectOptionChange="loadContainers" id="matches-sport"
                        :options="['Sport', ...SPORT_TYPES]" v-model:selectedOption="newContainer.sport">
                    </SportzoneSelect> -->
                </div>
                <template v-if="getUser && userCan(getUser, organizerRole)">
                    <button @click="matchPopup.openModal(), onCreateButtonClicked()" class="btn add-btn"
                        title="Create Match">Match</button>
                </template>
            </div>
            <div @scroll="onMainWrapperScroll" class="matches-container">
                <template v-for="container in listContainers({ status: containerStatus, type: containerType, sport: newContainer.sport, organizationId: organizationId })"
                :key="container.id" >
                    <ContainerItem v-if="container.type != 'Tournament' && container.type != 'TableTennisPhase'" @click="selectedMatchId = container.id" @edit="matchPopup.openModal(), onEditButtonClicked()"
                        @delete="onDeleteButtonClicked()" :container="container" :isSelected="selectedMatchId === container.id"
                        />
                </template>
                <LoadingIcon v-show="showLoadingIcon" />
            </div>
            <StepBasedModal ref="matchPopup" @onApplyClick="onFormSubmit" :start-step="containerProcessStep"
            :step-validators="stepValidators" :action-process-total-steps="containerProcessTotalSteps" :pre-step-action-executors="preStepActionExecutors">
                <template #header>
                    <h1>{{ isCreate ? 'Create Match' : 'Edit Match' }}</h1>
                </template>
                <template #popup-step-1>
                    <div class="popup-step">
                        <div class="spz-text-input-group">
                            <label for="search-team">Search Team:</label>
                            <input @input="onEntitySearchInput" :value="entitySearchTerm" id="search-team" type="text" placeholder="Search">
                        </div>
                        <div class="team-type">
                            <div class="side-description">Home {{ $c('entity', newContainer.sport) }}</div>
                        </div>
                        <div class="selected-teams">
                            <div class="selected-home-team">
                                <EntityItem @click="removeSelectedHomeEntity()" v-if="selectedHomeEntityId !== 0"
                                    :entity="getEntity({ entityId: selectedHomeEntityId })" />
                                <div class="already-selected" v-else>Please select "Home {{ $c('entity', newContainer.sport) }}"</div>
                            </div>
                        </div>
                        <div @scroll="onModalWrapperScroll" class="teams-container">
                            <div class="home-entities" :class="{ 'entities-hide': selectedHomeEntityId !== 0 }">
                                <EntityItem @click="onHomeEntityItemClick(entity.id)"
                                    :isSelected="entity.id === selectedHomeEntityId"
                                    v-for="entity in getEntities(newContainer.sport, entitySearchTerm)" :key="entity.id" :entity="entity"> {{
                                        entity.name }}
                                </EntityItem>
                            </div>
                        </div>
                        <LoadingIcon v-show="showLoadingIcon" />
                        </div>
                </template>
                <template #popup-step-2>
                    <div class="popup-step">
                            <div class="spz-text-input-group">
                                <label for="search-team">Search Team:</label>
                                <input @input="onEntitySearchInput" :value="entitySearchTerm" id="search-team" type="text" placeholder="Search">
                            </div>
                            <div class="team-type">
                                <div class="side-description">Away {{ $c('entity', newContainer.sport) }}</div>
                            </div>
                            <div class="selected-teams">
                                <div class="selected-away-team">
                                    <EntityItem @click="removeSelectedAwayEntity()" v-if="selectedAwayEntityId !== 0"
                                        :entity="getEntity({ entityId: selectedAwayEntityId })" />
                                    <div class="already-selected" v-else>Please select "Away {{ $c('entity', newContainer.sport) }}"</div>
                                </div>
                            </div>
                            <div @scroll="onModalWrapperScroll" class="teams-container">
                                <div class="away-entities" :class="{ 'entities-hide': selectedAwayEntityId !== 0 }">
                                    <EntityItem @click="onAwayEntityItemClick(entity.id)"
                                        :isSelected="entity.id === selectedAwayEntityId"
                                        v-for="entity in getEntities(newContainer.sport, entitySearchTerm)" :key="entity.id" :entity="entity"> {{
                                            entity.name }}
                                    </EntityItem>
                                </div>
                            </div>
                            <LoadingIcon v-show="showLoadingIcon" />
                        </div>
                </template>
                <template #popup-step-3>
                    <div class="popup-step">
                            <div class="match-details">
                                <div class="spz-text-input-group">
                                    <label for="match_title">Title</label>
                                    <input id="match_title" v-model="newContainer.title" type="text" placeholder="Title" required />
                                    <div v-show="!validTitle" class="spz-form-err-msg">Please provide a title.</div>
                                </div>
                                <div class="spz-text-input-group narrow">
                                    <label for="dp-match_start">Starts</label>
                                    <VueDatePicker uid="match_start" v-model="newContainer.startTime" time-picker-inline />
                                </div>
                                <div class="spz-text-input-group narrow">
                                    <label for="dp-match_end">Ends</label>
                                    <VueDatePicker uid="match_end" v-model="newContainer.endTime" time-picker-inline />
                                </div>
                                <div class="popup__input-group spz-checkbox-group ">
                                    <label class="spz-toggle">
                                        <span class="spz-toggle-label">Stream</span>
                                        <input class="spz-toggle-checkbox" type="checkbox" v-model="streamContainer">
                                        <div class="spz-toggle-switch"></div>
                                    </label>
                                </div>
                        <div class="popup__input-group spz-select-group narrow" v-show="streamContainer">
                            <label for="new-stream-channel">Channel</label>
                            <select id="new-stream-channel" v-model="newLivestream.channelId">
                                <option disabled value="">Please select one</option>
                                <option v-for="channel in getUserChannels" :key="channel.id" :value="channel.id">
                                    {{ channel.name }}
                                </option>
                            </select>
                        </div>
                        <div class="popup__input-group spz-select-group narrow" v-show="streamContainer">
                            <label for="new-stream-privacy">Privacy</label>
                            <select id="new-stream-privacy" v-model="newLivestream.privacy">
                                <option v-for="privacy in LIVESTREAM_PRIVACIES" :key="privacy" :value="privacy">{{ privacy }}
                                </option>
                            </select>
                        </div>
                        <div class="popup__input-group spz-checkbox-group" v-show="streamContainer">
                            <input id="useYoutubeStream" v-model="useYoutubeStream" type="checkbox" />
                            <label for="useYoutubeStream">Use YouTube Stream</label>
                        </div>
                        <div class="livestream-form" v-show="streamContainer">
                            <div v-show="!validLivestream" class="spz-form-err-msg">Please choose a stream.</div>
                            <div class="live-broadcasts" v-if="useYoutubeStream">
                                <YoutubeLiveBroadcastItem @click="onYoutubeBroadcastClick(livebroadcast)"
                                    v-for="livebroadcast in youtubeLiveBroadcasts" :key="livebroadcast.id"
                                    :yt-live-broadcast="livebroadcast"
                                    :is-selected="selectedYTLiveBroadcast?.id === livebroadcast.id" />
                            </div>
                            <div class="popup__input-group spz-text-input-group" v-show="!newLivestream.isAutomatic && !useYoutubeStream">
                                <label for="new-stream-platform-id">Livestream ID</label>
                                <input id="new-stream-platform-id" v-model="newLivestream.atStreamingPlatformId" />
                            </div>
                        </div>
                            </div>
                        </div>
                </template>
                <template #popup-step-4>
                    <div class="popup-step">
                        <div class="container-preview" v-if="selectedHomeEntityId !== 0 && selectedAwayEntityId !== 0">
                            <div class="container-preview__title">{{ newContainer.title }}</div>
                            <div class="container-preview__start-time">{{ formatStringDate(newContainer.startTime) }}</div>
                            <div class="container-preview__entities">
                                <div class="container-preview__entity home">
                                    <div class="container-preview__entity-icon">
                                        <img :src="'/api/entity/' + selectedHomeEntityId + '/logo'" alt="Home Team Logo"/>
                                    </div>
                                    <div class="container-preview__entity-name">{{ getEntity({ entityId: selectedHomeEntityId }).name }}</div>
                                </div> 
                                <div class="container-preview__entity away">
                                    <div class="container-preview__entity-icon">
                                        <img :src="'/api/entity/' + selectedAwayEntityId + '/logo'" alt="Away Team Logo" />
                                    </div>
                                    <div class="container-preview__entity-name">{{ getEntity({ entityId: selectedAwayEntityId }).name }}</div>
                                </div> 
                            </div>
                        </div>
                        <div class="livestream-preview" v-if="streamContainer && selectedYTLiveBroadcast">
                            <div class="livestream-preview__title">
                                Stream
                            </div>
                            <div class="livestream-preview_stream">
                                <template v-for="livebroadcast in youtubeLiveBroadcasts" :key="livebroadcast.id">
                                    <YoutubeLiveBroadcastItem v-if="selectedYTLiveBroadcast?.id === livebroadcast.id" :yt-live-broadcast="livebroadcast" />
                                </template>
                            </div>
                        </div>  
                    </div>
                </template>
                <template #action-btn >
                    <button class="btn add-btn">
                        {{ isCreate ? 'Create' : 'Edit'}}
                    </button>
                </template>
            </StepBasedModal>
            <SportzoneDialog ref="confirmContainerDialog" :show="showConfirmContainerDialog" class="mini"
                @close="showConfirmContainerDialog = false">
                <template #header>
                    Delete Match
                </template>
                <template #body>
                    Match: <strong>{{ containerDialog }}</strong> will be deleted. Do you confirm this action?
                </template>
                <template #footer>
                    <button class="btn delete-btn"
                        @click="$refs.confirmContainerDialog.closeModal(), confirmContainerDialogCallback()">
                        Confirm
                    </button>
                    <button class="btn btn-inverse" @click="$refs.confirmContainerDialog.closeModal()">Cancel</button>
                </template>
            </SportzoneDialog>
        </template>
    </TheOrganizerDashboard>
</template>
<script lang="ts">
import TheOrganizerDashboard from '@/components/main/TheOrganizerDashboard.vue';
import SportzoneSelect from '@/components/util/SportzoneSelect.vue';
import { CONTAINER_STATUSES, ContainerStatus, CONTAINER_TYPES, ContainerType, Container } from '@/models/main/Container';
import { SPORT_TYPES, STREAM_QUALITIES } from '@/models/main/CommonTypes';
import ContainerItem from '@/components/main/ContainerItem.vue';
import LoadingIcon from '@/components/util/LoadingIcon.vue';
import StepBasedModal from '@/components/main/StepBasedModal.vue';
import { ref, defineComponent, Ref, computed, onMounted } from 'vue';
import EntityItem from '@/components/main/EntityItem.vue';
import { createNamespacedHelpers, useStore } from 'vuex';
import GenericDictionary from '@/components/util/GenericDictionary';
import { Player } from '@/models/main/Player';
import { key } from '@/store';
import { SportzoneRole } from '@/models/main/User';
import moment from 'moment';
import { useRoute, useRouter } from 'vue-router';
import { Entity } from '@/models/main/Entity';
import { formatStringDate, getDateRoundedToNearestAheadQuarter, getUserLastSelectedSport, formatDate,
    getUserLastVisitedOrganizationId, setUserLastSelectedSport, userCan, getUserLastUsedOpponentNameSplitter, setUserLastUsedOpponentNameSplitter } from '@/composables/common/Util';
import SportzoneDialog from '@/components/util/SportzoneDialog.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { Organization } from '@/models/main/Organization';
import { LIVESTREAM_BROADCAST_TOOLS, LIVESTREAM_PRIVACIES, LIVESTREAM_STATUSES, LivestreamStatus, SaveLivestream } from '@/models/main/Livestream';
import YTLiveBroadcast from '@/models/main/YoutubeLiveBroadcast';
import Channel from '@/models/main/Channel';
import YoutubeLiveBroadcastItem from '@/components/main/YoutubeLiveBroadcastItem.vue';
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

const userStoreMapper = createNamespacedHelpers('userStore');
const entityStoreHelpers = createNamespacedHelpers('entityStore');
const containerStoreHelpers = createNamespacedHelpers('containerStore');
const playerStoreHelpers = createNamespacedHelpers('playerStore');
export default defineComponent({
    components: {
        TheOrganizerDashboard,
        SportzoneSelect,
        ContainerItem,
        LoadingIcon,
        StepBasedModal,
        EntityItem,
        SportzoneDialog,
        VueDatePicker,
        YoutubeLiveBroadcastItem,
    },
    computed: {
        ...userStoreMapper.mapGetters(
            [
                'getUser',
                'getUserChannels'
            ],
        ),
        ...entityStoreHelpers.mapGetters(
            ['getEntity', 'getEntities'],
        ),
        ...containerStoreHelpers.mapGetters(
            ['listContainers', 'getContainer'],
        ),
        ...playerStoreHelpers.mapGetters(
            ['getPlayers'],
        ),
        getEntityPlayers(): GenericDictionary<Player> {
            if (this.getEntities && Object.prototype.hasOwnProperty.call(this.getEntities, this.selectedHomeEntityId)) {
                return this.getEntities[this.selectedHomeEntityId].players;
            }
            else {
                return {};
            }
        },
    },
    setup() {
        const route = useRoute();
        const $toast = useToast();
        const routeOrgId: string | undefined = route.params.id ? route.params.id as string : undefined;
        const lastVisitedOrgId = getUserLastVisitedOrganizationId() ?? 0
        const organizationId: Ref<number | null> = ref(routeOrgId ? parseInt(routeOrgId) : lastVisitedOrgId);
        const organization: Ref<Organization | undefined> = ref();
        const showConfirmContainerDialog = ref(false);
        const containerDialog: Ref<Container | undefined> = ref();
        const confirmContainerDialogCallback: Ref<Function> = ref(() => { });
        let isCreate: Ref<boolean> = ref(true);
        let scrollTimeout: any;
        let loadInProcess: Ref<boolean> = ref(false);
        const showLoadingIcon: Ref<boolean> = ref(true);
        const store = useStore(key);
        const containerStatus: Ref<ContainerStatus | 'Status'> = ref('Status');
        const containerType: Ref<ContainerType | 'Type'> = ref('Type');
        const dateTimeFormat = 'YYYY-MM-DD HH:mm';
        const initialSportValue = getUserLastSelectedSport() ?? SPORT_TYPES[0]
        const currentTimeRounded = getDateRoundedToNearestAheadQuarter(new Date())
        const formattedRoundedTime = moment(currentTimeRounded).format(dateTimeFormat)
        const myMatch: Container = {
            type: 'TableTennisMatch',
            status: 'NotStarted',
            startTime: formattedRoundedTime,
            endTime: formattedRoundedTime,
            sport: initialSportValue,
            events: {},
            livestreams: {},
            entities: {},
            seasons: [],
            organizationId: organizationId.value ?? 0,
            //parentId: 4543 // TODO: fixed value
        };
        const newContainer: Ref<Container> = ref(myMatch);
        const selectedHomeEntityId: Ref<number> = ref(0);
        const selectedAwayEntityId: Ref<number> = ref(0);
        const organizerRole: SportzoneRole = 'Organizer';
        const matchPopup: Ref<any> = ref(null);
        const selectedMatchId: Ref<number> = ref(0);
        const containerProcessStep: Ref<number> = ref(1);
        const containerProcessTotalSteps = ref(4);
        const router = useRouter();
        const defaultOpponentSplitter = '-';
        let userLastUsedSplitter = getUserLastUsedOpponentNameSplitter()
        const containerOpponentNameSplitter: Ref<string> = ref(userLastUsedSplitter ? userLastUsedSplitter : defaultOpponentSplitter);
        /* Container proccess steps */
        const validTitle: Ref<boolean> = ref(true);
        const validStartTime: Ref<boolean> = ref(true)
        const validEndTime: Ref<boolean> = ref(true)
        const validLivestream: Ref<boolean> = ref(true)
        const mainDetailsValidator = () => {
            validTitle.value = newContainer.value.title !== undefined && newContainer.value.title?.length > 0
            validStartTime.value = newContainer.value.startTime !== undefined
            validEndTime.value = true // TODO: add valid checks to date times
            if(streamContainer.value) {
                if(useYoutubeStream.value) {
                    validLivestream.value = selectedYTLiveBroadcast.value !== undefined
                } else if(newLivestream.value.atStreamingPlatformId){
                    validLivestream.value = true
                }
            } else {
                validLivestream.value = true
            }

            const isValid = validTitle.value && validStartTime.value && validEndTime.value && validLivestream.value
            if(isValid) {
                const regex = /:|-|vs/g;
                const splitterFound = newContainer.value.title?.match(regex);
                if(splitterFound && splitterFound.length > 0) {
                    userLastUsedSplitter = splitterFound[0]
                    containerOpponentNameSplitter.value = userLastUsedSplitter
                    setUserLastUsedOpponentNameSplitter(userLastUsedSplitter)
                }
            }
            return isValid
        }
        const validHomeEntity: Ref<boolean> = ref(true)
        const homeEntityValidator = () => {
            validHomeEntity.value = selectedHomeEntityId.value !== 0
            return validHomeEntity.value
        }

        const validAwayEntity: Ref<boolean> = ref(true)
        const awayEntityValidator = () => {
            validAwayEntity.value = selectedAwayEntityId.value !== 0;
            return validAwayEntity.value
        }

       
        const prepMainDetailsStep = () => {
            const homeEntity: Entity = store.getters['entityStore/getEntity']({ entityId: selectedHomeEntityId.value })
            const awayEntity: Entity = store.getters['entityStore/getEntity']({ entityId: selectedAwayEntityId.value })
            if(homeEntity && awayEntity && homeEntity.name && awayEntity.name) {
                newContainer.value.title = homeEntity.name + ' ' + containerOpponentNameSplitter.value + ' ' + awayEntity?.name
            }

            prepareLivestreamModal();
        }
        const entitySearchTerm: Ref<string | undefined> = ref()
        const onEntitySearchInput = (event: any) => {
            entitySearchTerm.value = event.target.value;
            if (entitySearchTerm.value !== undefined && (entitySearchTerm.value.length >= 3 || entitySearchTerm.value.length === 0)) {
                loadEntities()           
            }
        }
        const onMainWrapperScroll = (event: any) => {
            const totalScrollHeight = event.target.scrollHeight;
            const scrollTop = event.target.scrollTop;
            const offsetHeight = event.target.offsetHeight;
            const margin = 20;
            let bottomOfWindow = totalScrollHeight - scrollTop - offsetHeight < margin;
            if (bottomOfWindow) {
                if (scrollTimeout) {
                    clearTimeout(scrollTimeout);
                }
                scrollTimeout = setTimeout(onScrollStopped, 200);
            }
        };
        const onScrollStopped = () => {
            if (!loadInProcess.value) {
                loadInProcess.value = true;
                loadContainers()
                    .then(() => {
                        loadInProcess.value = false;
                    });
            }
        };
        const loadContainers = async () => {
            showLoadingIcon.value = true;
            await store.dispatch('containerStore/fetchContainers', {
                status: containerStatus.value,
                type: containerType.value,
                sport: newContainer.value.sport,
                organizationId: organizationId.value,
            });
            setUserLastSelectedSport(newContainer.value.sport ?? "Sport")
            showLoadingIcon.value = false;
        };

        const loadOrganization = async () => {
            if(organizationId.value === null) {
                const organizations = await store.dispatch('organizationStore/fetchOrganizations', { sport: myMatch.sport })
                if(organizations && organizations.items.length > 0 && organizations.items[0])
                {
                    organization.value = organizations.items[0]
                    organizationId.value = organizations.items[0].id
                }
            } else {
                organization.value = await store.dispatch('organizationStore/getOrganization', organizationId.value)
            }
            if(organization.value) {
                myMatch.sport = organization.value.sport
                setUserLastSelectedSport(myMatch.sport);
            }
        }

        onMounted(loadContainers)
        onMounted(loadOrganization)
        
        const loadEntities = async () => {
            showLoadingIcon.value = true;
            await store.dispatch('entityStore/getEntities', { sport: newContainer.value.sport, searchedTeam: entitySearchTerm.value });
            showLoadingIcon.value = false;
        };
        const onSelectOptionChange = () => {
            loadEntities();
        };
        const removeSelectedHomeEntity = () => {
            selectedHomeEntityId.value = 0;
        };
        const removeSelectedAwayEntity = () => {
            selectedAwayEntityId.value = 0;
        };
        const onModalWrapperScroll = (event: any) => {
            const totalScrollHeight = event.target.scrollHeight;
            const scrollTop = event.target.scrollTop;
            const offsetHeight = event.target.offsetHeight;
            const margin = 20;
            let bottomOfWindow = totalScrollHeight - scrollTop - offsetHeight < margin;
            if (bottomOfWindow) {
                if (scrollTimeout) {
                    clearTimeout(scrollTimeout);
                }
                scrollTimeout = setTimeout(onModalScrollStopped, 200);
            }
        };
        const onModalScrollStopped = () => {
            if (!loadInProcess.value) {
                loadInProcess.value = true;
                loadEntities()
                    .then(() => {
                        loadInProcess.value = false;
                    });
            }
        };
        const onHomeEntityItemClick = (entityId: number) => {
            selectedHomeEntityId.value = entityId;
        };
        const onAwayEntityItemClick = (entityId: number) => {
            selectedAwayEntityId.value = entityId;
        };
        const onFormSubmit = async () => {
            newContainer.value.startTime = moment(newContainer.value.startTime).utc().format(dateTimeFormat)
            newLivestream.value.startTime = newContainer.value.startTime
            if(newContainer.value.endTime) {
                newContainer.value.endTime = moment(newContainer.value.endTime).utc().format(dateTimeFormat)
                newLivestream.value.endTime = newContainer.value.endTime
            }
            if (isCreate.value) {
                const createdMatch = await store.dispatch('containerStore/createContainer', {
                    container: newContainer.value,
                    homeEntityId: selectedHomeEntityId.value,
                    awayEntityId: selectedAwayEntityId.value,
                });
                if(streamContainer.value) {
                    newLivestream.value.containerId = createdMatch.id
                    await store.dispatch('livestreamStore/saveLivestream', {
                            livestream: newLivestream.value,
                            scheduleStart: scheduleStart.value,
                    });
                }
                $toast.success('Created');
            }
            else {
                const editedMatch = await store.dispatch('containerStore/editContainer', {
                    container: newContainer.value,
                    homeEntityId: selectedHomeEntityId.value,
                    awayEntityId: selectedAwayEntityId.value,
                });

                if(streamContainer.value) {
                    if(newContainer.value.livestreams && Object.prototype.hasOwnProperty.call(newContainer.value.livestreams, 0)) { //edit
                        newLivestream.value.id = newContainer.value.livestreams[0].id
                        await store.dispatch('livestreamStore/editLivestream', {
                            livestream: newLivestream.value,
                            livestreamId: newLivestream.value.id,
                        });
                    } else if(newContainer.value.id) { // add new
                        newLivestream.value.containerId = newContainer.value.id
                        newLivestream.value.title = newContainer.value.title ?? null
                        await store.dispatch('livestreamStore/saveLivestream', {
                                livestream: newLivestream.value,
                                scheduleStart: scheduleStart.value,
                        });
                    }
                }
                $toast.success('Edited'); 
            }
        };
        const onCreateButtonClicked = () => {
            containerProcessStep.value = 1;
            isCreate.value = true;
            newContainer.value.title = '';
            removeSelectedHomeEntity();
            removeSelectedAwayEntity();
            loadEntities();
        };
        const onEditButtonClicked = async () => {
            containerProcessStep.value = 1;
            isCreate.value = false;
            const container: Container = store.getters['containerStore/getContainer'](selectedMatchId.value);
            let homeEntity: Entity | undefined;
            let awayEntity: Entity | undefined;
            for (let i in container.entities) {
                if (container.entities[i]) {
                    if (container.entities[i].type === 'Home') {
                        homeEntity = container.entities[i].entity;
                    }
                    else if (container.entities[i].type === 'Away') {
                        awayEntity = container.entities[i].entity;
                    }
                    else {
                        console.error('Inavlid data!');
                    }
                }
            }
            if (container) {
                newContainer.value.id = container.id;
                newContainer.value.title = container.title;
                newContainer.value.startTime = formatStringDate(container.startTime, dateTimeFormat);
                newContainer.value.endTime = formatStringDate(container.endTime, dateTimeFormat)
            }
            if (homeEntity && homeEntity.id) {
                selectedHomeEntityId.value = homeEntity?.id;
            }
            if (awayEntity && awayEntity.id) {
                selectedAwayEntityId.value = awayEntity?.id;
            }
            if(container.livestreams && Object.prototype.hasOwnProperty.call(container.livestreams, 0)) {
                newLivestream.value = container.livestreams[0] as unknown as SaveLivestream
            }
        };
        const onDeleteButtonClicked = async () => {
            showConfirmContainerDialog.value = true;
            const container = store.getters['containerStore/getContainer'](selectedMatchId.value);
            containerDialog.value = container.title;
            confirmContainerDialogCallback.value = async () => {
                await store.dispatch('containerStore/deleteContainer', selectedMatchId.value);
                selectedMatchId.value = 0;
                $toast.success('Deleted');
            };
        };

        /* LIVESTREAM */
        const livestream: SaveLivestream = {
            title: null,
            startTime: moment().format(dateTimeFormat),
            endTime: moment().format(dateTimeFormat),
            quality: STREAM_QUALITIES[0],
            status: LIVESTREAM_STATUSES[0],
            atStreamingPlatformId: null,
            containerId: 0,
            cameraId: null,
            container: null,
            channelId: null,
            digestEndpoint: null,
            isAutomatic: false,
            sport: initialSportValue,
            privacy: LIVESTREAM_PRIVACIES[0],
            broadcastTool: LIVESTREAM_BROADCAST_TOOLS[1],
        };
        const newLivestream: Ref<SaveLivestream> = ref(livestream);
        const youtubeLiveBroadcasts: Ref<YTLiveBroadcast[]> = ref([]);
        const selectedYTLiveBroadcast: Ref<YTLiveBroadcast | undefined> = ref();
        const scheduleStart: Ref<boolean> = ref(false);
        const streamContainer: Ref<boolean> = ref(true)
        const useYoutubeStream: Ref<boolean> = ref(true)
        const onYoutubeBroadcastClick = (livebroadcast: YTLiveBroadcast) => {
            selectedYTLiveBroadcast.value = livebroadcast;
            // newLivestream.value.title = newContainer.value.title ?? null
            // newLivestream.value.startTime = newContainer.value.startTime ?? null
            // newLivestream.value.endTime = newContainer.value.endTime ?? null
            newLivestream.value.atStreamingPlatformId = livebroadcast.id;
        };

        const prepareLivestreamModal = () => {
            const userChannels: Channel[] = store.getters['userStore/getUserChannels'];
            if (userChannels.length >= 1) {
                newLivestream.value.channelId = userChannels[0].id;
                store
                    .dispatch('channelStore/getYoutubeBroadcasts', userChannels[0].id)
                    .then((broadcasts) => {
                        youtubeLiveBroadcasts.value = broadcasts;
                    });
            }
            if(isCreate.value) {
                newLivestream.value.atStreamingPlatformId = null;
                newLivestream.value.title = newContainer.value.title ?? null
                newLivestream.value.startTime = newContainer.value.startTime ?? null
                newLivestream.value.endTime = newContainer.value.endTime ?? null
                newLivestream.value.sport = newContainer.value.sport ?? initialSportValue
                selectedYTLiveBroadcast.value = undefined
            }
        };

        const fetchUserChannels = () => {
            showLoadingIcon.value = true;
            store.dispatch('userStore/getUserChannels', store.getters['userStore/getId']);
            showLoadingIcon.value = false;
        };
        
        onMounted(fetchUserChannels);
        /* LIVESTREAM */

        const stepValidators: Array<Function> = [homeEntityValidator, awayEntityValidator, mainDetailsValidator, ()=>{ return true}, () => { return true }]
        const preStepActionExecutors: Array<Function> = [() => {}, () => {}, prepMainDetailsStep, () => {}]

        const toggleStreamContainer = () => {
            streamContainer.value = !streamContainer.value
            console.log(streamContainer.value)
        }
        return {
            organization,
            organizationId,
            isCreate,
            onCreateButtonClicked,
            onEditButtonClicked,
            onDeleteButtonClicked,
            showConfirmContainerDialog,
            containerDialog,
            confirmContainerDialogCallback,
            onMainWrapperScroll,
            loadContainers,
            CONTAINER_STATUSES,
            containerStatus,
            CONTAINER_TYPES,
            containerType,
            SPORT_TYPES,
            newContainer,
            organizerRole,
            matchPopup,
            loadMorePlayers: loadEntities,
            selectedMatchId,
            showLoadingIcon,
            onSelectOptionChange,
            onModalWrapperScroll,
            removeSelectedHomeEntity,
            selectedHomeEntityId,
            removeSelectedAwayEntity,
            selectedAwayEntityId,
            containerProcessStep,
            containerProcessTotalSteps,
            validTitle,
            stepValidators,
            onHomeEntityItemClick,
            onAwayEntityItemClick,
            onFormSubmit,
            onEntitySearchInput,
            store,
            router,
            entitySearchTerm,
            userCan,
            preStepActionExecutors,
            newLivestream,
            scheduleStart,
            useYoutubeStream,
            selectedYTLiveBroadcast,
            youtubeLiveBroadcasts,
            LIVESTREAM_PRIVACIES,
            onYoutubeBroadcastClick,
            formatStringDate,
            dateTimeFormat,
            validLivestream,
            streamContainer,
            toggleStreamContainer,
        };
    },
});
</script>
<style>

/* Livestream ADD question step WIP */
.positive-answer-wrapper, .negative-answer-wrapper {
width: 50%;
padding: 1em 0;
display: flex;
justify-content: center;
align-items: center;
font-size: 2em;
}
.livestream-create-step {
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
flex-wrap: wrap;
}
.positive-answer-wrapper {
background-color: var(--archive-bck-color);
}
.positive-answer-wrapper, .negative-answer-wrapper {
border-radius: var(--default-border-rad);
}
.negative-answer-wrapper {
background-color: var(--btn-error-background-color);
margin-left: 2%;
}
.positive-answer-wrapper, .negative-answer-wrapper {
width: 50%;
width: 48%;
cursor: pointer;
}
/* END */

.container-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.container-actions .filter-group .spz-select:last-child {
    margin-right: 0;
}

.container-actions .filter-group .spz-select {
    margin-right: 1em;
}

.filter-group {
    display: flex;
    height: 100%;
}

.matches-container {
    margin-top: 1em;
    min-width: 50ch;
    width: 100%;
    overflow-y: auto;
    max-height: 100%;
    scrollbar-width: thin;
    scrollbar-color: var(--main-font-clr) var(--bright-font-clr);
    min-height: 50%;
}

.match-details {
    text-align: left;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
}

.match-detail {
    margin-bottom: var(--entity-item-margin-bottom);
}

.match-detail label {
    display: block;
    margin-right: var(--user-secondary-info-padding);
    width: 5em;
    float: left;
}

.team-type {
    display: flex;
    justify-content: space-between;
}

.team-type, .selected-teams, .teams-container {
    width: 100%;
}

.side-description {
    margin-bottom: var(--entity-item-margin-bottom);
    font-size: var(--entity-item-size);
    width: 100%;
}

.selected-teams {
    display: flex;
    justify-content: space-between;
}

.selected-home-team,
.selected-away-team {
    width: 100%;
    height: var(--badge-item-height);
    position: relative;
    padding-top: 0.75em;
}

.selected-home-team .entity-item::after, .selected-away-team .entity-item::after {
    content: '';
    top: 0.75em;
    right: 0;
    position: absolute;
    width: 1.5em;
    height: 1.5em;
    background-color: var(--btn-error-background-color);
    background-image: url(/transparent-cross.svg);
    background-size: 150%;
    background-position: center;
}

.teams-container {
    display: flex;
    max-height: calc(70vh - 10em);
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: var(--main-font-clr) var(--bright-font-clr);
    justify-content: space-between;
}

.teams-container>.entities-hide {
    height: 0;
    overflow: hidden;
}

.teams-container+.loading-icon {
    height: 1em;
    width: 1em;
}

.already-selected {
    margin-bottom: var(--entity-item-margin-bottom);
    text-align: left;
    color: var(--btn-darker-background-color);
    height: 100%;
}

.home-entities,
.away-entities {
    display: flex;
    margin: 0 calc(var(--entity-item-margin-bottom) / 2);
    width: 100%;
    flex-direction: column;
}

/* STEP BY STEP POPUP */
.popup-step {
    display: none;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

.popup-steps.show-1 .popup-step:nth-child(1),
.popup-steps.show-2 .popup-step:nth-child(2),
.popup-steps.show-3 .popup-step:nth-child(3),
.popup-steps.show-4 .popup-step:nth-child(4),
.popup-steps.show-5 .popup-step:nth-child(5),
.popup-steps.show-6 .popup-step:nth-child(6),
.popup-steps.show-7 .popup-step:nth-child(7),
.popup-steps.show-8 .popup-step:nth-child(8),
.popup-steps.show-9 .popup-step:nth-child(9),
.popup-steps.show-10 .popup-step:nth-child(10) {
    display: flex;
} 

.preview-group-text {
  font-weight: 600;
  font-size: 1.2em;
}

.process-preview .preview-group {
  margin-top: 0;
}

.preview-group ~ .preview-group {
  margin-top: 1em;
}

.process-preview-heading {
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 1.5em;
}

/*END */


/* Container Preview */
.container-preview {
    --_entity-icon-height: 10em;
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
}
.container-preview__title {
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 1.5em;
}
.container-preview__start-time {
    width: 100%;
    text-align: center;
    font-weight: bold;
}

.container-preview__entities {
  display: flex;
  gap: 1em;
  width: 100%;
}

.container-preview__entity {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    width: 50%;
    padding: 2rem;
}

.container-preview__entity-icon {
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--_entity-icon-height);
}

.container-preview__entity-icon object, .container-preview__entity-icon img {
    height: 100%;
    object-fit: contain;
}

.container-preview__entity-name {
  font-size: 1.5em;
  text-align: center;
}
/* END */

/* Livestream Preview */

.livestream-preview {
display: flex;
flex-direction: column;
}
.popup-step .livestream-preview {
margin-top: 1;
margin-top: 1e;
margin-top: 1em;
}


.livestream-preview {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    width: 100%;
}

.popup-step .livestream-preview {
  margin-top: 3em;
}

.livestream-preview__title {
  font-weight: bold;
  font-size: 1.5em;
  text-align: center;
  width: 100%;
}
</style>