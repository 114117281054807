import GenericDictionary from "@/components/util/GenericDictionary";
import { Sport } from "@/models/main/CommonTypes"
import { EntityContainer } from "@/models/main/EntityContainer"
import { Event } from "@/models/main/Event";
import Livestream from "./Livestream";
import { Organization } from "./Organization";
import Season from "@/models/main/Season";

export const CONTAINER_TYPES = ["Event", "Tournament", "Stage", "Phase", "Match", "VolleyballPhase", "VolleyballMatch", "TennisPhase", "TennisMatch", "TableTennisPhase", "TableTennisMatch" ]
export type ContainerType = typeof CONTAINER_TYPES[number]
export const CONTAINER_STATUSES = ["NotStarted", "Open", "Finished"]
export type ContainerStatus = typeof CONTAINER_STATUSES[number]
export const CONTAINER_PRIVACIES = [ "Public", "Unlisted", "Private" ]
export type ContainerPrivacy = typeof CONTAINER_PRIVACIES[number]

export interface Container {
    id?: number,
    title?: string,
    type?: ContainerType,
    status?: ContainerStatus,
    startTime?: string
    endTime?: string
    sport?: Sport
    organizationId?: number,
    organization?: Organization,
    events: GenericDictionary<Event>,
    livestreams: GenericDictionary<Livestream>,
    entities: GenericDictionary<EntityContainer>,
    parentId?: number,
    privacy?: ContainerPrivacy,
    seasons: Array<Season>,
}