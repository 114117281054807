<template>
<div ref="watchViewWrapper" class="tbltenn-watch-wrapper badminton-watch-wrapper" :class="{'fallback-fullscreen': isFallbackFullScreenMode, 'fullscreen' : isFullScreenMode}">
    <div v-if="!livestream" class="loading-livestream-icon-div">
        <LoadingIcon />
    </div>
    <div class="live-central-panel">
        <LivePlayer :startPlayingAt="seekPlayerTo" :livestream="livestream" @playerDurationChange="playerDurationChange" @fullscreenToggle="onFullScreenToggle" v-if="livestream">
            <div class="result-board_main" v-if="false && getCurrentHomeEntity && getCurrentAwayEntity">
                <div class="result-board_main__home-team">
                    <div class="result-board_main__cell result-board_main__team-name">{{getCurrentHomeEntity?.name}}</div>
                    <div class="result-board_main__cell result-board_main__games_cell ">{{currentHomeEntityGamePoints}}</div>
                    <div class="result-board_main__cell result-board_main__points_cell">{{getCurrentHomeEntityPoints}}</div>
                </div>
                <div class="result-board_main__away-team">
                    <div class="result-board_main__cell result-board_main__team-name">{{getCurrentAwayEntity?.name}}</div>
                    <div class="result-board_main__cell result-board_main__games_cell">{{currentAwayEntityGamePoints}}</div>
                    <div class="result-board_main__cell result-board_main__points_cell">{{getCurrentAwayEntityPoints}}</div>
                </div>
            </div>
            <div class="break-pane">
                <div class="break-pane__ad">
                    <div class="break-pane__add__img_container">
                        <img src="/the-spirit-of-bg.jpg" alt="The Spirit Of BG" />
                    </div>
                </div>
                <div class="break-pane__stats">Statistics</div>
            </div>
            <div class="stream-title">{{livestream?.title}}</div>
        </LivePlayer>
        <div class="main-scoreboard-wrapper hide-on-full" v-if="getCurrentHomeEntity && getCurrentAwayEntity">
            <div class="central-score-section">
                <div class="main-scoreboard">
                    <div v-if="sportIsBadminton" class="player-picture">
                            <img src="/player-1.jpg" />
                        </div>
                    <div class="home-scoreboard">
                        <div class="scoreboard-top-side">
                            <div class="team-name">{{getCurrentHomeEntity?.name}}</div>
                            <div class="team-score">{{getCurrentHomeEntityPoints}}</div>
                        </div>
                        <div class="scoreboard-bottom-side">
                            <div class="team-games-score">{{currentHomeEntityGamePoints}}</div>
                            <div class="team-timeouts"></div>
                        </div>
                    </div>
                    <div class="main-splitter">:</div>
                    <div class="away-scoreboard">
                        <div class="scoreboard-top-side">
                            <div class="team-name">{{getCurrentAwayEntity?.name}}</div>
                            <div class="team-score">{{getCurrentAwayEntityPoints}}</div>
                        </div>
                        <div class="scoreboard-bottom-side">
                            <div class="team-games-score">{{currentAwayEntityGamePoints}}</div>
                            <div class="team-timeouts"></div>
                        </div>
                    </div>
                    <div v-if="sportIsBadminton" class="player-picture">
                            <img src="/player-2.jpg" />
                    </div>
                </div>
            </div>

            <div class="interactable-info-section" :class="{'show-personal-stats' : showPersonalStats}">
                <div class="logos-wrapper">
                    <div class="logo-wrapper">
                        <img src="/sportzone-logo.svg" style="width: 100%;" alt="Sportzone">
                    </div>
                </div>
                <div class="personal-stat-panel" >
                    <button @click="togglePinPersonalStats" class="personal-stat-pin-btn" :class="{'active' : personalStatsPinned }" :title="personalStatsPinned ? 'Unpin' : 'Pin'" >
                        <PinIcon />
                    </button>
                    <div class="player-img-section">
                        <div class="player-img-wrapper">
                            <img :src="apiUri + '/api/player/' + selectedPersonalStatPlayer?.id + '/picture'"  onerror="this.src='/default-profile.png';" alt="Player Img" />
                        </div>
                    </div>
                    <div class="player-info-section">
                        <div class="player-name">{{selectedPersonalStatPlayer?.firstName}} {{selectedPersonalStatPlayer?.lastName}}</div>
                        <div class="player-secondary-info">
                            <div class="vly-player-details">
                                <div class="player-detail">
                                    <div class="player-detail-description">Team</div>
                                    <div class="player-detail-value">{{selectedPersonalStatPlayersEntity?.name}}</div>
                                </div>
                                <div class="player-detail">
                                    <div class="player-detail-description">Number</div>
                                    <div class="player-detail-value">{{selectedPersonalStatPlayer?.number}}</div>
                                </div>
                                <div class="player-detail">
                                    <div class="player-detail-description">Nationality</div>
                                    <div class="player-detail-value">{{selectedPersonalStatPlayer?.nationality}}</div>
                                </div>
                                <div class="player-detail">
                                    <div class="player-detail-description">Age</div>
                                    <div class="player-detail-value">{{calculateAge(selectedPersonalStatPlayer?.birthday)}}</div>
                                </div>
                                <!-- <div class="player-detail">
                                    <div class="player-detail-description">Height</div>
                                    <div class="player-detail-value">199</div>
                                </div>
                                <div class="player-detail">
                                    <div class="player-detail-description">Position</div>
                                    <div class="player-detail-value">Captain</div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="test-controls">
        <button @click="toggleShow('break-pane__stats')" title="Show Stats">Toggle Stats</button>
        <button @click="toggleShow('break-pane__ad')" title="Show Ad">Toggle Ad</button>
    </div>
    <h2>{{getCurrentContainer?.title}}</h2>
    <div class="live-right-panel hide-on-full">
        <div class="events-sec" v-if="getCurrentHomeEntity && getCurrentAwayEntity">
            <div class="info-section">
                <div id="home-entity-name">{{getCurrentHomeEntity?.name}}</div>
                <div id="away-entity-name">{{getCurrentAwayEntity?.name}}</div>
                <div @click="onChoseGameClick(i)" class="game-num" v-for="i in getHighestNumGameInContainerEvents" :key="i" :class="{'current-game' : i === currentGame, 'is-live' : getCurrentContainerEventsGroupedByGames[i].isLive}">{{(i)}}</div>
            </div>
            <div class="game-wrapper" v-for="(gameArray, g) in getCurrentContainerEventsGroupedByGames" :key="g" v-show="currentGame == g">
                <div class="event-row" v-for="(eventRow, e) in gameArray.events" :key="e" :class="{'is-live' : eventRow.isLive}" :set="player = getEventPlayer(eventRow.event)" :setEventEntity="eventEntity = getTheEntityThatTheEventBelongsTo(eventRow.event)">
                    <div class="score-box" @click="onScoreBoxClick(eventRow.event)">
                        <div class="home-point-type vly-point-type">
                            <div  v-if="eventEntity?.id === getCurrentHomeEntity?.id" class="tbltenn-point-icon"></div>
                        </div>
                        <div class="home-team-score">{{eventRow.homeTeamScore}}</div>
                        <div class="away-team-score">{{eventRow.awayTeamScore}}</div>
                        <div class="away-point-type vly-point-type">
                            <div  v-if="eventEntity?.id === getCurrentAwayEntity?.id" class="tbltenn-point-icon"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script lang="ts">
import { computed, defineComponent } from "@vue/runtime-core";
import LivePlayer from "@/components/main/LivePlayer.vue";
import PinIcon from "@/components/main/PinIcon.vue";
import DataManager from "@/components/util/DataManager"
import { onMounted, Ref, ref } from "vue";
import { ContainerDataModel } from "@/composables/dataModels/ContainerDataModel";
import LiveDataModel from "@/composables/dataModels/LiveDataModel";
import DataModel from "@/models/main/DataModel";
import { useRoute } from "vue-router";
import useCurrentContainerComputeds from "@/composables/badminton/UseCurrentContainerComputeds";
import { useStore, createNamespacedHelpers } from "vuex";
import { key } from '@/store'
import { Event } from '@/models/main/Event';
import { Player } from "@/models/main/Player";
import { Entity } from "@/models/main/Entity";
import { BadmintonPointType, BADMINTON_IS_POSITIVE_POINT } from "@/composables/badminton/BadmintonEventTypes";
import { SportzoneAuth } from "@/components/util/SportzoneAuth";
import LoadingIcon from '@/components/util/LoadingIcon.vue';

const { mapGetters } = createNamespacedHelpers('containerStore')

export default defineComponent ({
    components: {
        LivePlayer,
        PinIcon,
        LoadingIcon,
    },
    computed: {
        ...mapGetters([
            'getContainer',
            'getHomeEntity',
            'getAwayEntity',
        ]),
    },
    setup() {
        const route = useRoute()
        const store = useStore(key)
        const onLiveModelChange = (live: LiveDataModel) => {
            if(live.containerId !== container.value?.id) {
                //should change container! unsubscribe previous subscribe to new one
            }
        };

        const chosenGame: Ref<number> = ref(0)
        const onChoseGameClick = (gameNum: number) => {
            chosenGame.value = gameNum
        }


        const onContainerModelChange = (updatedContainer: ContainerDataModel) => {
            container.value = updatedContainer

            const testContainer: ContainerDataModel = new ContainerDataModel () 
            testContainer.id = updatedContainer.id
            testContainer.title = updatedContainer.title
            testContainer.type = updatedContainer.type
            testContainer.status = updatedContainer.status
            testContainer.startTime = updatedContainer.startTime
            testContainer.endTime = updatedContainer.endTime
            testContainer.sport = updatedContainer.sport
            testContainer.events = updatedContainer.events,
            testContainer.livestreams = updatedContainer.livestreams
            testContainer.entities = updatedContainer.entities
            
            store.commit('containerStore/setContainer', testContainer)
        }

        const container: Ref<ContainerDataModel | null> = ref(null)
        const currentContainerId: Ref<number> = ref(0)
        const livestream: Ref<LiveDataModel | null> = ref(null)
        const liveId = parseInt(route.params.id as string);
        const isSpoiledModeOn: Ref<boolean> = ref(route.query.unspoiled !== "1")
        const onDataManagerInstanced = async () => {
            console.info("Data manager is ready.")
        };
        

        let dataManagerInstance: DataManager | null = null
        const initDataManager = async () => {
            dataManagerInstance = DataManager.instance(onDataManagerInstanced);
                        if (dataManagerInstance !== null) {
                dataManagerInstance.modelUrl = "https://portal.sportzone.live/api/dataModel";


                const liveDataModelID = DataModel
                .generateModelID(new LiveDataModel(), {"id": liveId}) //TODO this is bad
                if(liveDataModelID === false) {
                    console.error("Could not generate proper Model ID!")
                    return null;
                }

                await dataManagerInstance.subscribeToDataModel(
                    liveDataModelID,
                    onLiveModelChange,
                    false
                );
                const subscribedLiveDataModel = await dataManagerInstance.dataModels[liveDataModelID]
                livestream.value = subscribedLiveDataModel as LiveDataModel
                if(livestream.value?.containerId) {
                    const containerDataModelId = DataModel
                        .generateModelID(new ContainerDataModel(), {"id": livestream.value.containerId}) //TODO this is bad 
                    if(containerDataModelId === false) {
                        console.error("Could not generate proper Model ID!")
                        return null;
                    }

                    await dataManagerInstance.subscribeToDataModel(
                        containerDataModelId,
                        onContainerModelChange,
                        false
                    );
                    const subscribedContainerDataModel = await dataManagerInstance.dataModels[containerDataModelId]
                    console.log(subscribedContainerDataModel)
                    container.value = subscribedContainerDataModel as ContainerDataModel
                    if(container.value.id !== undefined) {
                        //const copiedContainer = deepCopy(subscribedContainerDataModel)
                        store.commit('containerStore/setContainer', subscribedContainerDataModel)
                        currentContainerId.value = container.value.id
                    }
                } else {
                    //isFullScreenMode.value = true
                }
            } else {
                console.error("Data manager is not initalized")
            }
        };

        onMounted(initDataManager);

        const toggleShow = (targetClass: string) => {
            const target = document.querySelector('.' + targetClass)
            if(target !== null) {
                target.classList.toggle('show');
                //target.classList.contains('show')
                const pane = document.querySelector('.break-pane')
                if(pane !== null) {
                    pane.classList.toggle('show')
                }
            }
            
        }

        const playerCurrentUnixTime: Ref<number> = ref(0)
        
        const { calculateEntityPoints,
        calculateEntityGames,
        getCurrentContainerEventsGroupedByGames,
        getCurrentContainer,
        getCurrentHomeEntity,
        getCurrentAwayEntity,
        getCurrentHomeEntityPoints,
        getCurrentAwayEntityPoints,
        currentHomeEntityGamePoints,
        currentAwayEntityGamePoints,
        } = useCurrentContainerComputeds(store, currentContainerId, livestream, playerCurrentUnixTime, isSpoiledModeOn)
        
        const getHighestNumGameInContainerEvents = computed(() => {
            let highestNum = 1
            for(const g in getCurrentContainerEventsGroupedByGames.value) {
                const game = parseInt(g)
                if(highestNum < game) {
                    highestNum = game
                }
            }

            return highestNum
        })


        const getEventPlayer = (event: Event): Player | undefined => {
            if(event.playerId === undefined || event.playerId === null) {
                return undefined
            }

            const eventEntityId = event.entityId
            const eventPlayerId = event.playerId
            return getPlayer(eventEntityId, eventPlayerId)
        }

        const getPlayer = (entityId: number | undefined, playerId: number | undefined) => {
            if(playerId === undefined) {
                return playerId
            }
            if(entityId !== undefined) {
                let currentEntity: Entity | undefined
                switch(entityId) {
                    case getCurrentHomeEntity.value?.id:
                        currentEntity = getCurrentHomeEntity.value
                        break;
                    case getCurrentAwayEntity.value?.id:
                        currentEntity = getCurrentAwayEntity.value
                        break;
                }


                for(const i in currentEntity?.players) {
                    const currentPlayer = currentEntity?.players[i];
                    if(currentPlayer?.id === playerId) {
                        return currentEntity?.players[i]
                    }   
                }   
            }

            return undefined
        }

        const currentGame = computed(() => {
            if(chosenGame.value !== 0) {
                if(chosenGame.value === getHighestNumGameInContainerEvents.value) { //reset when selected highest game
                    chosenGame.value = 0
                    return getHighestNumGameInContainerEvents.value
                }

                return chosenGame.value
            }

            return getHighestNumGameInContainerEvents.value
        })
        const isFullScreenMode: Ref<boolean> = ref(false)
        const isFallbackFullScreenMode: Ref<boolean> = ref(false)
        const onFullScreenToggle = (isFullScreen: boolean, isFallbackFullScreen: boolean) => {
            isFullScreenMode.value = isFullScreen
            isFallbackFullScreenMode.value = isFallbackFullScreen
        }

        const selectedPersonalStatPlayer: Ref<Player | undefined> = ref()
        const selectedPersonalStatPlayersEntity: Ref<Entity | undefined> = ref()
        const showPersonalStats: Ref<boolean> = ref(false)
        let timeout: any | null = null
        const displayPersonsalStat = (entityId: number | undefined, playerId: number | undefined) => {
            console.log(getCurrentHomeEntity.value)
            if(getCurrentHomeEntity.value?.id === entityId) {
                selectedPersonalStatPlayersEntity.value = getCurrentHomeEntity.value
            }
            if(getCurrentAwayEntity.value?.id === entityId) {
                selectedPersonalStatPlayersEntity.value = getCurrentAwayEntity.value
            }

            console.log(selectedPersonalStatPlayersEntity)

            selectedPersonalStatPlayer.value = getPlayer(entityId, playerId)
            showPersonalStats.value = true
            if(timeout !== null) {
                clearTimeout(timeout)
                timeout = null
            }
            if(!personalStatsPinned.value) {
                timeout = setTimeout(() => {
                    showPersonalStats.value = false
                    selectedPersonalStatPlayer.value = undefined
                    timeout = null
                }, 20000)
            }
        }

        const personalStatsPinned: Ref<boolean> = ref(false)
        const togglePinPersonalStats = () => {
            personalStatsPinned.value = !personalStatsPinned.value
            if(personalStatsPinned.value && timeout !== null) {
                clearTimeout(timeout)
                timeout = null
            } else if(!personalStatsPinned.value && timeout === null) {
                timeout = setTimeout(() => {
                    showPersonalStats.value = false
                    selectedPersonalStatPlayer.value = undefined //FIXME Copied from top should not be copied
                    timeout = null
                }, 20000)
            }
        }

        const calculateAge = (birthday: Date | undefined): string => {
            if(!birthday) {
                return "-"
            }
            
            const today = new Date();
            const birthDate = new Date(birthday);
            let age = today.getFullYear() - birthDate.getFullYear();
            const m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }

            return age.toString();
        }

        const seekPlayerTo: Ref<number> = ref(0)
        const advanceSecs = 8
        const onScoreBoxClick = (event: Event) => {
            if(event.startTimestamp && livestream.value?.streamActualStartTime) {
                const eventStartTime = new Date(event.startTimestamp).getTime() / 1000
                const liveStarttime = new Date(livestream.value.streamActualStartTime).getTime() / 1000 

                let shouldSeekTo = eventStartTime - liveStarttime - advanceSecs;
                if(shouldSeekTo < 0) {
                    shouldSeekTo = 0
                }
                seekPlayerTo.value = shouldSeekTo;
            }
        }



        const apiUri = SportzoneAuth.apiUri

        const watchViewWrapper: Ref<any> = ref()
        const isFallbackFullScreen: Ref<boolean> = ref(false)
        const goWatchViewFullscreen = () => {
            const currentDoc: any = document
            if(currentDoc.fullscreenElement === undefined &&
                currentDoc.webkitFullscreenElement === undefined &&
                currentDoc.mozFullScreenElement === undefined &&
                currentDoc.msFullscreenElement === undefined) { //fallback for not supported devices
                isFallbackFullScreen.value = true
            }

            if (
                currentDoc.fullscreenElement ||
                currentDoc.webkitFullscreenElement ||
                currentDoc.mozFullScreenElement ||
                currentDoc.msFullscreenElement
            ) {
                if (currentDoc.exitFullscreen) {
                    currentDoc.exitFullscreen();
                } else if (currentDoc.mozCancelFullScreen) {
                    currentDoc.mozCancelFullScreen();
                } else if (currentDoc.webkitExitFullscreen) {
                    currentDoc.webkitExitFullscreen();
                } else if (currentDoc.msExitFullscreen) {
                    currentDoc.msExitFullscreen();
                }
            } else {
                if (watchViewWrapper.value.requestFullscreen) {
                    watchViewWrapper.value.requestFullscreen();
                } else if (watchViewWrapper.value.mozRequestFullScreen) {
                    watchViewWrapper.value.mozRequestFullScreen();
                } else if (watchViewWrapper.value.webkitRequestFullscreen) {
                    watchViewWrapper.value.webkitRequestFullscreen((Element as any).ALLOW_KEYBOARD_INPUT);
                } else if (watchViewWrapper.value.msRequestFullscreen) {
                    watchViewWrapper.value.msRequestFullscreen();
                }
            }
        }


        const playerDurationChange = (currentDuration: number) => {
            let liveStarttime = 0
            if(livestream.value?.streamActualStartTime) {
                liveStarttime = new Date(livestream.value.streamActualStartTime).getTime() / 1000 
            }
            
            playerCurrentUnixTime.value = currentDuration + liveStarttime - advanceSecs
        } 

        const sportIsBadminton = computed(() => {
            // if(getCurrentContainer?.value?.sport) {
            //     const sport = getCurrentContainer.value.sport;
            //     return sport.startsWith("Badminton");
            // }

            return false;
        })

        
        const getTheEntityThatTheEventBelongsTo = (event: Event): Entity | undefined => {
            const pointType = event.description as unknown as BadmintonPointType || null
            const pointIsPoisitive = pointType === null ? true : BADMINTON_IS_POSITIVE_POINT[pointType];
            if(pointIsPoisitive) {
                if(getCurrentHomeEntity.value?.id == event.entityId) {
                    return getCurrentHomeEntity.value
                } else {
                    return getCurrentAwayEntity.value
                }
            } else {
                if(getCurrentHomeEntity.value?.id == event.entityId) {
                    return getCurrentAwayEntity.value
                } else {
                    return getCurrentHomeEntity.value
                }
            }
        }

        return {
            store,
            livestream,
            container,
            currentContainerId,
            currentGame,
            isFullScreenMode,
            isFallbackFullScreenMode,
            watchViewWrapper,
            sportIsBadminton,
            goWatchViewFullscreen,
            playerDurationChange,
            onFullScreenToggle,
            toggleShow,
            calculateEntityPoints,
            calculateEntityGames,
            getEventPlayer,
            getPlayer,
            displayPersonsalStat,
            calculateAge,
            onChoseGameClick,
            togglePinPersonalStats,
            onScoreBoxClick,
            getTheEntityThatTheEventBelongsTo,
            seekPlayerTo,
            personalStatsPinned,
            showPersonalStats,
            selectedPersonalStatPlayersEntity,
            selectedPersonalStatPlayer,
            getCurrentContainerEventsGroupedByGames,
            getHighestNumGameInContainerEvents,
            getCurrentContainer,
            getCurrentHomeEntity,
            getCurrentAwayEntity,
            getCurrentHomeEntityPoints,
            getCurrentAwayEntityPoints,
            currentHomeEntityGamePoints,
            currentAwayEntityGamePoints,
            apiUri
        }
    }
})
</script>
<style>
.badminton-watch-wrapper .main-scoreboard-wrapper {
    --team-scoreboard-width: 18ch;
}

.badminton-watch-wrapper .stream-title {
    left: auto;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    bottom: auto;
    padding: 1ch;
}

@media screen and (min-width: 780px) {
    .badminton-watch-wrapper .main-scoreboard-wrapper {
        --team-scoreboard-width: 36ch;
    }

    .badminton-watch-wrapper .interactable-info-section {
        width: calc(100% - 4ch - (var(--team-scoreboard-width) * 2));
    }
}

@media screen and (min-width: 1480px) {
    .badminton-watch-wrapper .main-scoreboard-wrapper {
        --team-scoreboard-width: 60ch;
    }
}
</style>