import GenericDictionary from "@/components/util/GenericDictionary";
import { PagedResponse, PaginatedItemsStoreGetters, PaginatedItemsStoreMutations, commitPagedResponseDetails, prepForPagedCall } from "@/composables/common/Pagination";
import { setUserLastVisitedOrganizationId } from "@/composables/common/Util";
import authModule from "@/main";
import { Sport } from "@/models/main/CommonTypes";
import { Organization } from "@/models/main/Organization";
import { IRootState } from "@/store";
import { ActionContext, Module } from "vuex";

const state: OrganizationPublicStore = {
    paginatedItems: {
        items: {},
        pageSize: 30,
        currentPage: 1,
    },
    organizationsGroupedBySport: {}
};
export interface OrganizationPublicStore {
    paginatedItems: PagedResponse<Organization>,
    sport?: Sport,
    searchedTeam?: string,
    organizationsGroupedBySport: GenericDictionary<Organization[]>
};

const getters = {
    getOrganizations: (state: OrganizationPublicStore) => (sport?: Sport, searchedTeam?: string) => {
        let filteredOrganizations: Array<Organization> = [];
        const lowerCaseSearchTerm = searchedTeam?.toLowerCase();
        for (const organizationId in state.paginatedItems.items) {
            if (Object.prototype.hasOwnProperty.call(state.paginatedItems.items, organizationId)) {
                const organization: Organization = state.paginatedItems.items[organizationId] as Organization;
                const sportIsOk = sport === undefined || sport === 'Sport' || organization.sport === sport;
                const organizationNameLowerCased = organization.name?.toLowerCase();
                const searchedTeamIsOk = lowerCaseSearchTerm === undefined || organizationNameLowerCased?.startsWith(lowerCaseSearchTerm);
                if (sportIsOk && searchedTeamIsOk) {
                    filteredOrganizations.push(organization);
                }
            }
        }
        filteredOrganizations = filteredOrganizations.sort((a, b) => {
            return (a.name ?? '').localeCompare(b.name ?? '');
        });
        return filteredOrganizations;
    },
    getOrganization: (state: OrganizationPublicStore) => (organizationId: number) => {
        return state.paginatedItems.items[organizationId];
    },
    getOrganizationsGroupedBySport: (state: OrganizationPublicStore) => () => {
        return state.organizationsGroupedBySport
    },
    getSportFilter(state: OrganizationPublicStore) {
        return state.sport;
    },
    getSearchTermFilter(state: OrganizationPublicStore) {
        return state.searchedTeam;
    },
    ...PaginatedItemsStoreGetters(),
}

const mutations = {
    addOrganization(state: OrganizationPublicStore, organization: Organization) {
        state.paginatedItems.items[organization.id] = organization;
        if(!Object.prototype.hasOwnProperty.call(state.organizationsGroupedBySport, organization.sport)) {
            state.organizationsGroupedBySport[organization.sport] = []
        }

        if(state.organizationsGroupedBySport[organization.sport].length > 0) {
            const orgIndex = state.organizationsGroupedBySport[organization.sport].findIndex((org => org.id === organization.id))
            if(orgIndex > -1) {
                state.organizationsGroupedBySport[organization.sport][orgIndex] = organization
                return
            } 
        }

        state.organizationsGroupedBySport[organization.sport].push(organization);
    },
    editOrganization(state: OrganizationPublicStore, organization: Organization) {
        state.paginatedItems.items[organization.id] = organization;
        const groupedOrgs = state.organizationsGroupedBySport[organization.id]
        if(groupedOrgs) {
            for(let i = 0; i < groupedOrgs.length; i++) {
                if(groupedOrgs[i].id === organization.id) {
                    groupedOrgs[i] = organization
                    break;
                }
            }
    
            state.organizationsGroupedBySport[organization.id] = groupedOrgs   
        }
    },
    removeOrganization(state: OrganizationPublicStore, organizationId: number) {
        if (Object.prototype.hasOwnProperty.call(state.paginatedItems.items, organizationId)) {
            const organization = state.paginatedItems.items[organizationId]
            let groupedOrganizations = state.organizationsGroupedBySport[organization.sport]
            state.organizationsGroupedBySport[organization.sport] = groupedOrganizations.filter(org => org.id !== organization.id)
            delete state.paginatedItems.items[organizationId];
        }
    },
    setSportFilter(state: OrganizationPublicStore, sport: Sport) {
        state.sport = sport;
    },
    setSearchTermFilter(state: OrganizationPublicStore, searchTerm: string) {
        state.searchedTeam = searchTerm;
    },
    ...PaginatedItemsStoreMutations(),
}

const actions = {
    addOrganization({ commit }: ActionContext<OrganizationPublicStore, IRootState>, organization: Organization) {
        commit('addOrganization', organization);
    },
    getPublicOrganization({ dispatch }: ActionContext<OrganizationPublicStore, IRootState>, organizationId: number) {
        return authModule.get('/api/organization/' + organizationId)
            .then((response) => {
                const organization: Organization = response.data as unknown as Organization;
                dispatch('addOrganization', organization);
                setUserLastVisitedOrganizationId(organization.id)
                return organization;
            })
            .catch((error) => {
                console.error(error);
            });
    },
    fetchPublicOrganizations({ commit, dispatch, getters }: ActionContext<OrganizationPublicStore, IRootState>, { sport, searchTerm }: { sport: Sport | null, searchTerm?: string }) {
        let filtersChanged = false;
        const currentSport = getters['getSportFilter'];
        if (currentSport !== sport) {
            commit('setSportFilter', sport);
            filtersChanged = true;
        }
        const currentSearchTerm = getters['getSearchTermFilter']
        if(currentSearchTerm !== searchTerm) {
            commit('setSearchTermFilter', searchTerm);
            filtersChanged = true;
        }
        const { pageToRequest, pageSize, canMakeNextPageCall }  = prepForPagedCall(getters, filtersChanged)
        if(canMakeNextPageCall) {
            return authModule.get(`/api/organization/public`, {
                params: {
                    sport: sport,
                    searchTerm,
                    page: pageToRequest,
                    pageSize: pageSize,
                },
            })
            .then((response) => {
                const pagedResponse: PagedResponse<Organization> = response.data as unknown as PagedResponse<Organization>;
                commitPagedResponseDetails(commit, pagedResponse)
                for (const containerIndex in pagedResponse.items) {
                    dispatch('addOrganization', pagedResponse.items[containerIndex]);
                }
    
                return pagedResponse
            })
            .catch((error) => {
                console.error(error);
            });
        }
    },
    getEntities({ commit, dispatch, getters }: ActionContext<OrganizationPublicStore, IRootState>, { sport, searchedTeam }: { sport?: Sport, searchedTeam?: string }) {
        const currentSport = getters['getSport'];
        const currentSearchedTeam = getters['getSearchedTeam'];
        let filtersChanged = false;
        if (currentSport !== sport) {
            commit('setSport', sport);
            filtersChanged = true;
        }
        if (currentSearchedTeam !== searchedTeam) {
            commit('setSearchedTeam', searchedTeam);
            filtersChanged = true;
        }
        let pageToRequest = getters['getNextPage'];
        const pageSize = getters['getPageSize'];
        if (filtersChanged) {
            pageToRequest = 1;
        }
        if (!filtersChanged && getters['getNextPage'] === 1 && getters['getCurrentPage'] > 1) {
            return;
        }
        return authModule.get(`/api/organization`, {
            params: {
                sport,
                searchedTeam: searchedTeam !== undefined && searchedTeam !== '' ? searchedTeam : null,
                page: pageToRequest,
                pageSize: pageSize,
            },
        }).then((response) => {
            const pagedResponse: PagedResponse<Organization> = response.data as unknown as PagedResponse<Organization>;
            commit('setCurrentPage', pagedResponse.currentPage);
            commit('setPageSize', pagedResponse.pageSize);
            commit('setNextPage', pagedResponse.nextPage);
            commit('setPreviousPage', pagedResponse.previousPage);
            for (const organizationIndex in pagedResponse.items) {
                dispatch('addOrganization', pagedResponse.items[organizationIndex]);
            }
            return pagedResponse.items;
        }, (err) => {
            if (err.response.status !== 404) {
                console.error(err);
                return null;
            }
            console.log('Could not get organizations!');
            return null;
        });
    },
}

const organizationPublicStore: Module<OrganizationPublicStore, IRootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
export default organizationPublicStore;