<template>
  <form @submit.prevent="onSubmit" class="profile-form">
    <div class="profile-form__item spz-img-input-group">
        <div class="profile__preview">
            <label for="profile_picture">
                <object :data="imageUrl">
                    <img src="/default-profile-pic.svg" />
                </object>
            </label>
            <input v-show="false" type="file" id="profile_picture" @change="onProfileImageChange" accept="image/jpeg, image/png, image/webp, image/avif" />
        </div>
    </div>
    <div class="profile-form__item spz-text-input-group">
      <label for="first_name">First Name</label>
      <input v-model.lazy="localFirstName" type="text" id="first_name" placeholder="First Name" />
    </div>
    <div class="profile-form__item spz-text-input-group">
      <label for="middle_name">Middle Name</label>
      <input v-model.lazy="localMiddleName" type="text" id="middle_name" placeholder="Middle Name" />
    </div>
    <div class="profile-form__item spz-text-input-group">
      <label for="last_name">Last Name</label>
      <input v-model.lazy="localLastName" type="text" id="last_name" placeholder="Last Name" />
    </div>
    <div class="profile-form__item spz-text-input-group">
      <label for="birthday">Birthday</label>
      <input v-model.lazy="localBirthday" type="date" id="birthday" />
    </div>
    <div class="spz-multi-toggle-group">
        <div class="spz-multi-toggle-name">Sex</div>
        <div class="spz-multi-toggle_options">
            <div class="spz-multi-toggle_option">
                <input type="radio" id="sex-female" value="Female" v-model="localSex" />
                <label for="sex-female">Female</label>
            </div>
            <div class="spz-multi-toggle_option">
                <input type="radio" id="sex-male" value="Male" v-model="localSex" />
                <label for="sex-male">Male</label>
            </div>
            <div class="spz-multi-toggle_option">
                <input type="radio" id="sex-other" value="Other" v-model="localSex" />
                <label for="sex-other">Other</label>    
            </div>
        </div>
    </div>
    <div class="profile-form__item spz-text-input-group" v-if="false">
      <label for="email">Email</label>
      <input v-model.lazy="localEmail" type="text" id="email" placeholder="Email" />
    </div>
    <div class="profile-form__item spz-text-input-group">
      <label for="nationality">Nationality</label>
      <input v-model.lazy="localNationality" type="text" id="nationality" placeholder="Nationality" />
    </div>
    <slot />
  </form>
</template>
<script lang="ts">
import { defineComponent, onMounted, onUpdated, Ref, ref } from 'vue';
import { Sex } from '@/models/main/User';
export default defineComponent({
  components: {},
  props: [
    "id",
    "firstName",
    "middleName",
    "lastName",
    "sex",
    "birthday",
    "email",
    "nationality",
    "profilePictureUrl"
  ],
  emits: [
    "update:id",
    "update:firstName",
    "update:middleName",
    "update:lastName",
    "update:sex",
    "update:email",
    "update:birthday",
    "update:nationality",
    "onFormSubmit",
    "onProfileChange"
  ],
  computed: {
    localId: {
      get(): number {
        return this.id;
      },
      set(value: number) {
        this.$emit('update:id', value);
      },
    },
    localFirstName: {
      get(): string {
        return this.firstName;
      },
      set(value: string) {
        this.$emit('update:firstName', value);
      },
    },
    localMiddleName: {
      get(): string {
        return this.middleName;
      },
      set(value: string) {
        this.$emit('update:middleName', value);
      },
    },
    localLastName: {
      get(): string {
        return this.lastName;
      },
      set(value: string) {
        this.$emit('update:lastName', value);
      },
    },
    localSex: {
      get(): Sex {
        return this.sex;
      },
      set(value: Sex) {
        this.$emit('update:sex', value);
      },
    },
    localBirthday: {
      get(): Date | null {
        return this.birthday;
      },
      set(value: Date) {
        this.$emit('update:birthday', value);
      },
    },
    localEmail: {
      get(): string {
        return this.email;
      },
      set(value: string) {
        this.$emit('update:email', value);
      },
    },
    localNationality: {
      get(): string {
        return this.nationality;
      },
      set(value: string) {
        this.$emit('update:nationality', value);
      },
    },
  },
  setup(props, context) {
    const onSubmit = () => {
      context.emit('onFormSubmit');
    };

    const imageUrl: Ref<any> = ref('/default-profile-pic.svg')

    const onProfileImageChange = (e: Event) => {
      if(e.target) {
        const inputElement = e.target as HTMLInputElement
        if(inputElement.files && inputElement.files.length > 0) {
          const imageFile = inputElement.files[0];
          const tempImageURL = URL.createObjectURL(imageFile)
          imageUrl.value = tempImageURL
          context.emit('onProfileChange', imageFile);
        }
      }
    }

    onMounted(() => {
      if(props.id > 0) {
        imageUrl.value = `/api/user/${props.id}/picture`
      } else {
        imageUrl.value = ``
      }
    })
    onUpdated(() => {
      imageUrl.value = `/api/user/${props.id}/picture`
    });
    return {
      onSubmit,
      onProfileImageChange,
      imageUrl
    };
  },
});
</script>
<style>
.profile-form {
  width: min(28rem, 95vw);
  margin: auto;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  height: -moz-max-content;
  height: max-content;
  --form-element-padding: 0.2em 1em;
}

.profile-form .profile__preview {
    height: calc(var(--badge-item-height) * 2);
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: calc(var(--badge-item-height) * 2);
}
</style>