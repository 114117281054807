<template>
  <h1>HI AUTH</h1>
</template>
<script lang="ts" >
import { defineComponent } from 'vue'

const AuthorizationResponse = defineComponent({
    setup() {
        
    }
})

export default AuthorizationResponse
</script>

<style></style>
