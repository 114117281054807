<template>
    <TheOrganizerDashboard ref="mainWrapper" @scroll="onMainWrapperScroll" v-if="authModule.isAuthenticated">
        <div class="watch-livestreams-list">
            <WatchLivestreamItem v-if="isAvailableLivestreams" v-for="livestream in getLivestreams({ sort: 'ASC', statuses: ['Pending', 'Starting', 'Streaming'] })" :key="livestream.id" :livestream="livestream" />
            <h1 v-else>No livestreams at the moment</h1>
            <LoadingIcon v-show="showLoadingIcon" />
        </div>
    </TheOrganizerDashboard>
    <ThePublicHomeLayout ref="mainWrapper" @scroll="onMainWrapperScroll" v-else>
        <div class="watch-livestreams-list">
            <WatchLivestreamItem v-if="isAvailableLivestreams" v-for="livestream in getLivestreams({ sort: 'ASC', statuses: ['Pending', 'Starting', 'Streaming'], privacy: 'Public' })" :key="livestream.id" :livestream="livestream" />
            <h1 v-else>No livestreams at the moment</h1>
            <LoadingIcon v-show="showLoadingIcon" />
        </div>
    </ThePublicHomeLayout>
</template>
<script lang="ts">
import TheOrganizerDashboard from '@/components/main/TheOrganizerDashboard.vue';
import { ref, defineComponent, Ref } from 'vue';
import authModule from '@/main'
import WatchLivestreamItem from '@/components/main/WatchLivestreamItem.vue';
import { key } from '@/store';
import LoadingIcon from '@/components/util/LoadingIcon.vue';
import ThePublicHomeLayout from '@/components/main/ThePublicHomeLayout.vue';
import { createNamespacedHelpers, useStore } from 'vuex';
import { LIVESTREAM_STATUSES, LivestreamStatus } from '@/models/main/Livestream';
const livestreamStoreHelpers = createNamespacedHelpers('livestreamPublicStore');
const userStoreHelpers = createNamespacedHelpers('userStore');
export default defineComponent({
    components: {
        TheOrganizerDashboard,
        WatchLivestreamItem,
        LoadingIcon,
        ThePublicHomeLayout,
    },
    computed: {
        ...livestreamStoreHelpers.mapGetters([
            'getLivestreams',
        ]),
        ...userStoreHelpers.mapGetters([
            'getId',
            'getUser',
        ]),
    },
    setup() {
        const mainWrapper: Ref<any> = ref();
        const showLoadingIcon: Ref<boolean> = ref(true);
        const store = useStore(key);
        let loadInProcess: Ref<boolean> = ref(false);
        let scrollTimeout: any;
        const isAvailableLivestreams: Ref<boolean> = ref(true);
        const statuses: LivestreamStatus[] = [LIVESTREAM_STATUSES[0], LIVESTREAM_STATUSES[1], LIVESTREAM_STATUSES[2]];
        const loadLivestreams = async () => {
            showLoadingIcon.value = true;
            const livestreams = await store.dispatch('livestreamPublicStore/getPublicLivestreams', { statuses });
            console.log(livestreams)
            showLoadingIcon.value = false;
            if (!livestreams) {
                isAvailableLivestreams.value = false;
            }
        };
        const onScrollStopped = () => {
            if (!loadInProcess.value) {
                loadInProcess.value = true;
                loadLivestreams();
                loadInProcess.value = false;
            }
        };
        const onMainWrapperScroll = (event: any) => {
            const totalScrollHeight = event.target.scrollHeight;
            const scrollTop = event.target.scrollTop;
            const offsetHeight = event.target.offsetHeight;
            const margin = 20;
            let bottomOfWindow = totalScrollHeight - scrollTop - offsetHeight < margin;
            if (bottomOfWindow) {
                if (scrollTimeout) {
                    clearTimeout(scrollTimeout);
                }
                scrollTimeout = setTimeout(onScrollStopped, 200);
            }
        };
        loadLivestreams();
        return {
            authModule,
            mainWrapper,
            showLoadingIcon,
            store,
            isAvailableLivestreams,
            onMainWrapperScroll,
        };
    },
});
</script>
<style>
.livestreams-page {
    background-color: var(--darker-bckgrnd-color);
    max-width: 100vw;
    max-height: 100vh;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: var(--deep-blue-bck-color) var(--watch-bck-color);
}

.watch-livestreams-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    scrollbar-width: none;
    margin: 0 auto;
    padding-top: 1.67em;
    justify-content: flex-start;
    position: relative;
    width: clamp(22rem, 80vw, 58rem);
    max-width: none;
}

.watch-livestreams-list > h1 {
    display: none;
}
.watch-livestreams-list:empty > h1 {
    display: block;
}

.organizer-main-container .watch-livestreams-list {
  padding-top: 0;
}

.admin-section .watch-livestreams-list {
    width: clamp(320px, 100%, 58rem);
}

.livestreams-page .watch-livestreams-list {
    height: 100%;
    min-height: calc(100vh - 8rem);
}

.livestreams-page main::after,
.central-section::after {
    /* The css for the background image */
    content: '';
    background-image: url(/live-background.svg);
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    z-index: 1;
    background-size: contain;
}
</style>