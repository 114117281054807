<template>
    <button v-if="!isEditMode" @click="toggleEdit" class="btn edit-btn" type="button" title="Edit">Edit</button>
    <button v-else @click="saveChanges" class="btn edit-btn" type="button" title="Save">Save</button>
    <div class="container-result-wrapper">
        <div class="container-result-row position-row">
            <div class="container-result-box">Position</div>
            <div v-for="entity in entityIdPositionKVPair" class="container-result-box">
                <div>{{ getEntityContainerResult(entity.id ?? 0, mainContainerResultFormer)?.position }}</div>
            </div>
        </div>
        <div class="container-result-row team-row visible-column">
            <div class="container-result-box">Teams</div>
            <div v-for="entity in entityIdPositionKVPair" class="container-result-box">
                <div>{{ entity?.name }}</div>
            </div>
        </div>
        <div v-for="(entity, index) in entityIdPositionKVPair" class="container-result-row team-row">
            <div class="container-result-box">{{ entity?.name }}</div>
            <template v-if="true" v-for="(innerEntity, innerIndex) in entityIdPositionKVPair">
                <div v-if="innerIndex == index" class="container-result-box match-result-wrapper empty-box"></div>
                <div v-else class="container-result-box match-result-wrapper">
                    <div class="normal-result-box">
                        <div class="game-result-wrapper" v-for="commonContainerResultFormer in 
                                getCommonContainerResultFormersOfEntities(entity?.id ?? 0, innerEntity?.id ?? 0)">
                            <div class="game-number">S {{ commonContainerResultFormer?.container?.sequence }}</div>
                            <div class="game-point"
                                v-for="current in [{ entityResult: getEntityContainerResult(innerEntity?.id ?? 0, commonContainerResultFormer) }]"
                                :class="{ 'result-pos-1': current.entityResult?.position == 1 }">
                                <input v-show="isEditMode" :name="'cr-' + current.entityResult?.id"
                                    :value="current.entityResult?.result" type="text"
                                    @change="updateEntityContainerResult($event, current, commonContainerResultFormer)" />
                                <span v-show="!isEditMode">{{ current.entityResult?.result }}</span>
                            </div>
                            <div class="game-point"
                                v-for="current in [{ entityResult: getEntityContainerResult(entity?.id ?? 0, commonContainerResultFormer) }]"
                                :class="{ 'result-pos-1': current.entityResult?.position == 1 }">
                                <input v-show="isEditMode" :name="'cr-' + current.entityResult?.id"
                                    :value="current.entityResult?.result" type="text"
                                    @change="updateEntityContainerResult($event, current, commonContainerResultFormer)" />
                                <span v-show="!isEditMode">{{ current.entityResult?.result }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <div v-if="false" v-for="innerTeamNumber in teamsCount" class="container-result-box match-result-wrapper"
                :class="{ 'empty-box': innerTeamNumber == index }">
                <TableTennisMatchContainerResult v-if="innerTeamNumber != index" v-show="sport == 'TableTennis'" />
                <TennisMatchContainerResult v-if="innerTeamNumber !== index" v-show="sport == 'Tennis'" />
                <VolleyballMatchContainerResult v-if="innerTeamNumber != index" v-show="sport == 'Volleyball'" />
            </div>
        </div>
        <Modal ref="infoPopup" class="match-info-modal">
            <template v-slot:header>
                <h1>Match Info</h1>
            </template>
            <template v-slot:body>
                <TableTennisMatchOverview :containerInfo="containerInfo" v-if="sport == 'TableTennis'" />
                <TennisMatchOverview :containerInfo="containerInfo" v-if="sport == 'Tennis'" />
                <VolleyballMatchOverview :containerInfo="containerInfo" v-if="sport == 'Volleyball'" />
            </template>
            <template v-slot:footer>
                <div>
                    <button @click="infoPopup.closeModal()" class="btn btn-inverse">Close</button>
                </div>
            </template>
        </Modal>
        <div class="container-result-row point-row"
            v-for="additionalResultFormerId in additionalResultFormerIdsAsColumn">
            <div class="container-result-box">{{ resultFormersDisplayNames.get(additionalResultFormerId) ?? "Points" }}
            </div>
            <template v-for="entity in entityIdPositionKVPair">
                <div v-for="containerResultFormer in [additionalResultFormers.get(additionalResultFormerId)]"
                    class="container-result-box">
                    <div>{{ getEntityContainerResult(entity?.id ?? 0, containerResultFormer)?.result }}</div>
                </div>
            </template>
        </div>
        <div v-show="additionalResultFormerIdsAsColumn.length == 0" class="container-result-row point-row">
            <div class="container-result-box">{{ resultFormersDisplayNames.get(mainContainerResultFormer?.id ?? 0) ??
        "Points"
                }}</div>
            <div v-for="containerResult in mainContainerResultFormer?.containerResults" class="container-result-box">
                <div>{{ containerResult.result }}</div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { Ref, ref } from 'vue'
import Modal from '@/components/main/Modal.vue'
import TableTennisMatchContainerResult from '@/components/tableTennis/TableTennisMatchContainerResult.vue'
import TableTennisMatchOverview from '@/components/tableTennis/TableTennisMatchOverview.vue'
import TennisMatchContainerResult from '@/components/tennis/TennisMatchContainerResult.vue'
import TennisMatchOverview from '@/components/tennis/TennisMatchOverview.vue'
import VolleyballMatchContainerResult from '@/components/volleyball/VolleyballMatchContainerResult.vue'
import VolleyballMatchOverview from '@/components/volleyball/VolleyballMatchOverview.vue'
import { useStore } from 'vuex'
import { key } from '@/store'
import { Entity } from '@/models/main/Entity'
import { ContainerResult } from '@/models/main/ContainerResult'
const store = useStore(key);
let teamsCount: number = 4
const sport: Ref<string> = ref('TableTennis')
const infoPopup: Ref<any> = ref(null)
const containerInfo: Ref<any> = ref(null)
const isEditMode: Ref<boolean> = ref(false)
let containerResultsArray: ContainerResult[] = []
const toggleModal = (containerResultFormersOfEntitiesArray: Array<any>) => {
    infoPopup.value.openModal()
    const containerResultFormersOfEntities = containerResultFormersOfEntitiesArray[0]
    containerInfo.value = containerResultFormersOfEntities
    sport.value = containerInfo.value.container.sport
}
const toggleEdit = () => {
    isEditMode.value = true
}
const updateEntityContainerResult = (event: any, containerResultInfo: any, commonContainerResultFormer: any) => {
    let newValue = event.target.value
    if (event.target.value.trim() == '' || event.target.value.trim() == '0' || event.target.value.trim() == 0) {
        newValue = 0
    } else {
        newValue = parseInt(event.target.value)
    }
    const containerResultObj: ContainerResult = {
        id: containerResultInfo.entityResult.id,
        containerResultFormerId: commonContainerResultFormer.id,
        containerId: commonContainerResultFormer.container.id,
        entityId: containerResultInfo.entityResult.entity.id,
        result: newValue,
        position: containerResultInfo.entityResult.position,
        dataType: containerResultInfo.entityResult.dataType,
        type: containerResultInfo.entityResult.type,
        resultFormerId: commonContainerResultFormer.resultFormer.id
    }
    containerResultsArray.push(containerResultObj)
}
const saveChanges = async () => {
    isEditMode.value = false
    await store.dispatch('containerStore/updateContainerResults', containerResultsArray)
    containerResultsArray = []
}
const containerRankingViewId = 10;
let mainContainerResultFormer: Ref<any> = ref(null)
const entitiesContainerResultFormers = new Map<number, Map<number, any>>()
const sortedEntityContainerResultFormers = ref(new Map<number, Map<number, any>>());
const entityIdPositionKVPair = ref(new Array<Entity>());
const additionalResultFormers = ref(new Map<number, any>())
const getCommonContainerResultFormersOfEntities = (entityId: number, opponentEntityId: number) => {
    const commonContainerResultFormers: Array<any> = [];
    const mainEntityContainerResultFormers = sortedEntityContainerResultFormers.value.get(entityId)
    if (mainEntityContainerResultFormers) {
        for (const iterator of mainEntityContainerResultFormers) {
            const containerResultFormer = iterator[1]
            const containerResults: Array<any> = containerResultFormer.containerResults;
            for (let i = 0; i < containerResults.length; i++) {
                const containerResult = containerResults[i];
                const containerResultEntity = containerResult.entity;
                if (containerResultEntity.id === opponentEntityId) {
                    commonContainerResultFormers.push(containerResultFormer);
                }
            }
        }
    }

    commonContainerResultFormers.sort((a, b) => {
        return (a?.container?.sequence ?? 0) - (b?.container?.sequence ?? 0)
    })
    return commonContainerResultFormers;
}
const getEntityContainerResult = (entityId: number, containerResultFormer: any) => {
    if (!containerResultFormer) {
        return;
    }

    const containerResults: Array<any> = containerResultFormer.containerResults;
    for (let i = 0; i < containerResults.length; i++) {
        const containerResult = containerResults[i];
        const containerResultEntity = containerResult.entity;
        if (containerResultEntity.id === entityId) // found
        {
            return containerResult;
        }
    }
}
let additionalResultFormerIdsAsColumn: number[]
const resultFormersDisplayNames = new Map<number, string>()
resultFormersDisplayNames.set(1466, "G Wins")
resultFormersDisplayNames.set(1467, "G Loses")
//resultFormersDisplayNames.set(1465, "Loses")
resultFormersDisplayNames.set(1464, "Wins")
additionalResultFormerIdsAsColumn = [...resultFormersDisplayNames.keys()]
store.dispatch('containerStore/getContainerRankingView', containerRankingViewId)
    .then((containerRankingView) => {
        console.log(containerRankingView)
        const viewSchemaParsed = JSON.parse(containerRankingView.viewSchema)
        const mainContainerResultFormerId = viewSchemaParsed.mainContainerResultFormerId //559 // 486
        const displayChildResultFormerId = viewSchemaParsed.displayChildResultFormerId;
        const containerResultFormers: Array<any> = containerRankingView.containerResultFormers;
        for (let i = 0; i < containerResultFormers.length; i++) {
            const containerResultFormer = containerResultFormers[i];
            if (additionalResultFormerIdsAsColumn.includes(containerResultFormer.id)) {
                additionalResultFormers.value.set(containerResultFormer.id, containerResultFormer);
            }
            if (containerResultFormer.id == mainContainerResultFormerId) {
                mainContainerResultFormer.value = containerResultFormer;
                continue; // skipping mainContainerResultFormer, won't store it in grouped Map for now
            }

            const resultFormer = containerResultFormer.resultFormer;
            if (resultFormer.id == displayChildResultFormerId) {
                const containerResults: Array<any> = containerResultFormer.containerResults;
                for (let k = 0; k < containerResults.length; k++) {
                    const containerResult = containerResults[k];
                    const entity = containerResult.entity;
                    if (!entitiesContainerResultFormers.has(entity.id)) {
                        entitiesContainerResultFormers.set(entity.id, new Map<number, any>());
                    }
                    const entityContainerResultFormersGrouped = entitiesContainerResultFormers.get(entity.id);
                    if (entityContainerResultFormersGrouped) {
                        entityContainerResultFormersGrouped.set(containerResultFormer.id, containerResultFormer);
                    }
                }
            }
        }

        const mainContainerResultFormerContainerResults: Array<any> = mainContainerResultFormer.value.containerResults;
        mainContainerResultFormerContainerResults.sort((a, b) => {
            return a.position - b.position
        })
        for (let id = 0; id < mainContainerResultFormerContainerResults.length; id++) {
            const containerResult = mainContainerResultFormerContainerResults[id];
            const entity = containerResult.entity;
            const groupedEntityContainerResultFormers = entitiesContainerResultFormers.get(entity.id);
            if (groupedEntityContainerResultFormers) {
                entityIdPositionKVPair.value.push(entity)
                sortedEntityContainerResultFormers.value.set(entity.id, groupedEntityContainerResultFormers)
            }
        }
        console.log(entityIdPositionKVPair.value);
        console.log(sortedEntityContainerResultFormers.value);
    })
</script>
<style>
</style>