import { Entity } from "@/models/main/Entity"
import Season from "@/models/main/Season"
import { computed, Ref, ref } from "vue"
import { key } from "@/store";
import { useStore } from "vuex";
import useEntityComposables from "@/composables/generic/UseEntityComposables";
import { ContainerStatus, ContainerType } from "@/models/main/Container";
import { Sport } from "@/models/main/CommonTypes";
import { Organization } from "@/models/main/Organization";

export default function useContainerComposables(organization: Ref<Organization | undefined>, seasonFilterId: Ref<number | undefined>, showLoadingIcon: Ref<boolean>) {
    const store = useStore(key);
    const containerStatus: Ref<ContainerStatus | 'Status'> = ref('Status')
    const containerType: Ref<ContainerType | 'Type'> = ref('Type');
    const loadContainers = async () => {
        showLoadingIcon.value = true;
        await store.dispatch('containerStore/fetchContainers', {
            status: containerStatus.value,
            type: containerType.value === 'Match' ? organization.value?.sport + containerType.value : containerType.value,
            organizationId: organization.value?.id,
            seasonId: seasonFilterId.value,
        });

        // setUserLastSelectedSport(newContainer.value.sport ?? "Sport")

        showLoadingIcon.value = false;
    };

    const listContainers = computed(() => {
        return store.getters['containerStore/listContainers']({ status: containerStatus.value, type: containerType.value === 'Match' ? organization.value?.sport + containerType.value : containerType.value, organizationId: organization.value?.id, seasonId: seasonFilterId.value });
    })

    const thereIsNextContainersPage = computed(() => {
        const nextPage: number | undefined = store.getters['containerStore/getNextPage']
        return  nextPage !== undefined
    })


    return {
        containerStatus,
        containerType,
        loadContainers,
        listContainers,
        thereIsNextContainersPage
    }
}