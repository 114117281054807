import { Sport, StreamQuality } from "@/models/main/CommonTypes";
import { Container } from "@/models/main/Container";
import { Camera } from "@/models/main/Camera";
import StreamingEndpoint from "./StreamingEndpoint";

export const LIVESTREAM_STATUSES = ["Pending", "Starting", "Streaming", "Stopping", "Completed"]
export type LivestreamStatus = typeof LIVESTREAM_STATUSES[number]

export const LIVESTREAM_PRIVACIES = ["Public", "Unlisted", "Private"]
export type StreamPrivacy = typeof LIVESTREAM_PRIVACIES[number]

export const LIVESTREAM_BROADCAST_TOOLS = ["Sportzone Station", "Streaming Software"]
export type BroadcastTool = typeof LIVESTREAM_BROADCAST_TOOLS[number]

export default interface Livestream {
    id?: number,
    title?: string,
    streamLink?: string,
    atStreamingPlatformId?: string,
    startTime?: Date,
    endTime?: string,
    streamActualStartTime?: Date,
    quality?: StreamQuality,
    status?: LivestreamStatus,
    containerId?: number,
    container?: Container,
    cameraId?: number,
    camera?: Camera,
    streamingEndpointId?: number,
    streamingEndpoint?: StreamingEndpoint,
    sport?: Sport,
    sportSpecificId?: number,
    privacy?: StreamPrivacy,
    broadcastTool?: BroadcastTool
}

export interface SaveLivestream {
    id?: number,
    title: string | null,
    atStreamingPlatformId: string | null,
    startTime: string | null,
    endTime: string | null,
    quality: StreamQuality | null,
    status: LivestreamStatus | null,
    containerId: number | null,
    container: Container | null,
    cameraId: number | null,
    channelId: number | null,
    digestEndpoint: string | null,
    isAutomatic: boolean | null,
    sport: Sport | null,
    privacy: StreamPrivacy | null,
    broadcastTool: BroadcastTool | null,
}