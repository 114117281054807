<template>
    <ThePublicHomeLayout class="home">
      <div class="home-wrapper">
          <div class="home-menu">
            <!-- <div class="menu-items">
              <div class="menu-item services">Services</div>
              <div class="menu-item about-us">About us</div>
              <div class="menu-item story">Story</div>
              <div class="menu-item contact-us">Contact us</div>
            </div> -->
          </div>
          <div class="home-page-heading">Improve Your Game</div>
          <div class="home-page-sections">
            <div class="section log-in">
              <div class="section-text" @click="redirectToLogin">Log in</div>
            </div>
            <label for="services-toggle">
              <input type="checkbox" name="services-checkbox" id="services-toggle">
              <div class="section services active">
                  <!-- <div class="section-text"></div> -->
                  <div class="section-img">
                    <img src="/male-playing-basketball.svg" alt="Basketball" />
                  </div>
              </div>
            </label>            
            <!-- <div class="section bowlzone">
              <div class="section-text">Bowlzone</div>
            </div> -->
            <label for="about-us-toggle">
              <input type="checkbox" name="about-us-checkbox" id="about-us-toggle">
              <div class="section about-us">
                <div class="section-img">
                  <img src="/male-playing-football.svg" alt="Football" />
                </div>
                <!-- <div class="section-text"></div> -->
              </div>
            </label>
          </div>
      </div>
    </ThePublicHomeLayout>
</template>
<script lang="ts">
import ThePublicHomeLayout from '@/components/main/ThePublicHomeLayout.vue';
import { getUserSpecificLoginRequest } from '@/components/util/AuthConfig';
import { defineComponent } from 'vue';
import { useMsal } from "@/composables/auth/UseMSAL";

const Home = defineComponent({
  components: {
    ThePublicHomeLayout
  },
  setup() {
    const { instance } = useMsal();
    const redirectToLogin = () => {
        instance.loginRedirect(getUserSpecificLoginRequest())
    }

    return {
        redirectToLogin
    }
  }
})

export default Home
</script>

<style>

.home {
  position: relative;
}

.home-wrapper {
  width: 100%;
  padding-top: 1em;
}

.home-menu {
  width: 100%;
  display: flex;
}

.menu-items {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-right: 13em;
}

.menu-item.services:before {
  background-color: #fbb03b;
}
.menu-item.about-us:before {
  background-color: #000;
}
.menu-item.story:before {
  background-color: #6165d7;
}
.menu-item.contact-us:before {
  background-color: #6eceb3;
}

.home-page-sections {
  font-size: 2em;
  --log-in-section-width: 6em;
}

.section-text {
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-img img {
  max-width: 100%;
}

.section {
  display: flex;
  position: relative;
}

.home-page-heading {
  font-size: 2em;
  font-weight: 500;
  width: 5em;
}



.section.log-in {
  float: right;
  width: var(--log-in-section-width);
  background-color: var(--watch-bck-color);
  border-radius: 50%;
  height: var(--log-in-section-width);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3em;
  color: white;
}

.section.log-in > .section-text {
    cursor: pointer;
}

.section.services {
  --services-img-width: 100%;
  width: 100%;
  padding: 0;
  float: left;
  margin-top: 1em;
}



.section.services:before {
  background-image: url(/basketball-ball.svg);
  position: absolute;
  background-repeat: no-repeat;
  top: -2em;
  left: 0;
  background-position: center center;
  z-index: 5;
  bottom: 0;
  right: 0;
  height: 30em;
  transform-origin: 70% 17.3%;
}

@keyframes shrink-services {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  99% {
    transform: scale(0.13);
    opacity: 1;
  }

  100% {
    transform: scale(0.13);
    opacity: 0;
  }
}

#services-toggle {
  display: none;
}

#services-toggle:checked ~ .section.services:before {
  animation: shrink-services 1s 1 both;
}

.section.services .section-img {
  width: var(--services-img-width);
}

.section.services .section-text {
  width: calc(100% - var(--services-img-width));
  z-index: 6;
}

.section.bowlzone::before {
  background-image: url('/bowling-pin.svg');
  width: 100%;
  height: 32em;
  top: 0;
  right: 0;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
}

.section.bowlzone {
  float: right;
  width: 9em;
  justify-content: center;
}

.section.bowlzone .section-text {
  margin-top: 4em;
  font-size: 0.6em;
  color: var(--deep-blue-bck-color);
  font-weight: 600;
}

.section.about-us {
  float: left;
  width: 70%;
  margin-top: 5em;
}

.section.about-us:before {
  top: -5em;
  bottom: 0;
  left: 0em;
  right: 0;
  position: absolute;
  height: 29em;
  background-image: url(/football-ball.svg);
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 7;
    transform-origin: 44.4% 27%;
}

.section.about-us .section-img {
  margin-left: 1em;
}

@keyframes shrink-about-us {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  99% {
    transform: scale(0.047);
    opacity: 1;
  }

  100% {
    transform: scale(0.047);
    opacity: 0;
  }
}

#about-us-toggle {
  display: none;
}

#about-us-toggle:checked ~ .section.about-us:before {
  animation: shrink-about-us 1s 1 both;
}

.section.about-us .section-text {
  z-index: 8;
}

.triangle {
	position: absolute;
	background-color: #0f65a7;
	text-align: left;
	top: 30vw;
	left: -2vw;
}
.triangle:before,
.triangle:after {
	content: '';
	position: absolute;
	background-color: inherit;
}
.triangle,
.triangle:before,
.triangle:after {
	width:  60vw;
	height: 60vw;
	border-top-right-radius: 30%;
}

.triangle {
	transform: rotate(30deg) skewX(-30deg) scale(1,.866);
}
.triangle:before {
	transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
}
.triangle:after {
	transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
}

.home {
  --top-margin: 30%; /*3.67em;*/
}

.landing-page-video {
  position: absolute;
  width: calc(100% - 20ch);
  top: var(--top-margin);
  left: 20ch;
}

/* .home main::after {
  content: '';
  background-image: url(/sportzone-home-img.svg);
  top: var(--top-margin);
  right: 0;
  bottom: calc(var(--top-margin) * -1);
  left: 0;
  display: block;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
} */

.home h1 {
  width: 20ch;
  margin-bottom: .325em;
  font-size: 1.8em;
  line-height: 1.111em;
  font-weight: 500;
  margin-top: 1.67em;
}

.home h4 {
  margin-top: 0.5em;
  font-weight: 300;
}

.bowlzone-section {
  margin-top: 2em;
  line-height: 1.5em;
}

.bowlzone-section p {
  width: 13em;
  color: #E2E042;
  margin-bottom: 3em;
  font-size: 1.25em;
  line-height: 1em;
  font-weight: 400;
}

.bowlzone-section a {
  border-radius: 50vh;
  border: 2px solid var(--light-homepage-color);
  padding: 0.5em 1em;
  text-transform: uppercase;
  font-family: Roboto;
  font-weight: 600;
  font-size: 1.125em;
}

@media screen and (min-width: 374px) {
    .home h1 {
        font-size: 2.25em;
    }
}

@media screen and (min-width: 1000px) {
  .section.services {
    --services-img-width: 6em;
    width: calc(100% - 3em - var(--log-in-section-width));
    padding: 3em;
    display: flex;
    justify-content: flex-end;
  }

  .section.bowlzone::before {
    content: '';
  }
  .section.about-us:before {
    content: '';
  }
  .section.services:before {
    content: '';
  }
    .home main::after {
        background-size: auto;
    }
}

</style>
