<template>
  <TheOrganizerDashboard>
    <button class="btn delete-btn" @click="deleteUserData">Delete my data</button>
  </TheOrganizerDashboard>
</template>
<script lang="ts">
import { defineComponent, Ref, ref } from 'vue';
import { createNamespacedHelpers, useStore } from 'vuex';
import { key } from '@/store';
import TheOrganizerDashboard from '@/components/main/TheOrganizerDashboard.vue';
import { useRouter } from 'vue-router';
import authModule from '@/main';
const userStoreHelpers = createNamespacedHelpers('userStore');
export default defineComponent({
  components: {
    TheOrganizerDashboard,
  },
  ...userStoreHelpers.mapGetters(['getUser']),
  setup() {
    const router = useRouter();
    const store = useStore(key);
    const deleteUserData = async () => {
      await store.dispatch('userStore/deleteData')
      authModule.logout()
      router.push({name: "Login"})
    }

    return {
      store,
      router,
      deleteUserData
    };
  },
});
</script>
<style>
.profile-button-save {
  --default-btn-radius: 2em;
  cursor: pointer;
  border-radius: var(--default-btn-radius);
  font-weight: 600;
  color: white;
  padding: 0.325em 0.75em;
  background-color: var(--btn-darker-background-color);
  text-transform: uppercase;
  border: none;
  font-family: inherit;
  font-size: inherit;
  width: fit-content;
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>