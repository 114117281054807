import { Sport } from '@/models/main/CommonTypes';
import { Entity, EntityType } from '@/models/main/Entity';
import { PaginatedItemsStoreGetters, PaginatedItemsStoreMutations, PagedResponse } from '@/composables/common/Pagination';
import { ActionContext, Module } from 'vuex';
import { IRootState } from '@/store';
import authModule from '@/main';
import { Player } from '@/models/main/Player';
import GenericDictionary from '@/components/util/GenericDictionary';
const state: EntityStore = {
    paginatedItems: {
        items: {},
        pageSize: 30,
        currentPage: 1,
        nextPage: 1,
    },
};
export interface EntityStore {
    paginatedItems: PagedResponse<Entity>,
    sport?: Sport,
    searchedTeam?: string,
    organizationId?: number,
    seasonId?: number,
    type?: EntityType,
};
const getters = {
    getEntity: (state: EntityStore) => ({ entityId }: { entityId: number }) => {
        return state.paginatedItems.items[entityId] ?? null;
    },
    getEntities: (state: EntityStore) => (sport?: Sport, searchedTeam?: string, type?: EntityType, organizationId?: number, seasonId?: number) => {
        let filteredEntities: Array<Entity> = [];
        const lowerCaseSearchTerm = searchedTeam?.toLowerCase();
        for (const entityId in state.paginatedItems.items) {
            if (Object.prototype.hasOwnProperty.call(state.paginatedItems.items, entityId)) {
                const entity: Entity = state.paginatedItems.items[entityId] as Entity;
                const sportIsOk = organizationId !== undefined || sport === undefined || sport === 'Sport' || entity.sport === sport;
                const entityNameLowerCased = entity.name?.toLowerCase();
                const searchedTeamIsOk = lowerCaseSearchTerm === undefined || entityNameLowerCased?.startsWith(lowerCaseSearchTerm);
                const typeIsOk = type === undefined || entity.type === type;
                const organizationIsOk = organizationId === undefined || entity.organizationId === organizationId
                const seasonIsOk = seasonId === undefined || entity.seasons?.find(s => s.id === seasonId)
                if (sportIsOk && searchedTeamIsOk && organizationIsOk && typeIsOk && seasonIsOk) {
                    filteredEntities.push(entity);
                }
            }
        }
        filteredEntities = filteredEntities.sort((a, b) => {
            return (a.name ?? '').localeCompare(b.name ?? '');
        });
        return filteredEntities;
    },
    getSport(state: EntityStore) {
        return state.sport;
    },
    getSearchedTeam(state: EntityStore) {
        return state.searchedTeam;
    },
    getOrganizationId(state: EntityStore) {
        return state.organizationId;
    },
    getType(state: EntityStore) {
        return state.type;
    },
    ...PaginatedItemsStoreGetters(),
};
const mutations = {
    addEntity(state: EntityStore, entity: Entity) {
        if (entity.id !== undefined) {
            if (entity.players) {
                const playerDictionary: GenericDictionary<Player> = {};
                for (let p in entity.players) {
                    const playerId = entity.players[p].id;
                    if (playerId) {
                        playerDictionary[playerId] = entity.players[p];
                    }
                }
                entity.players = playerDictionary;
            }
            state.paginatedItems.items[entity.id] = entity;
        }
    },
    editEntity(state: EntityStore, entity: Entity) {
        if (entity.id !== undefined) {
            state.paginatedItems.items[entity.id] = entity;
        }
    },
    removeEntity(state: EntityStore, entityId: number) {
        if (Object.prototype.hasOwnProperty.call(state.paginatedItems.items, entityId)) {
            delete state.paginatedItems.items[entityId];
        }
    },
    addPlayer(state: EntityStore, { player, entityId }: { player: Player, entityId: number }) {
        if (player.id) {
            let currentPlayers = state.paginatedItems.items[entityId].players;
            if (!currentPlayers) {
                currentPlayers = {};
            }
            currentPlayers[player.id] = player;
            state.paginatedItems.items[entityId].players = currentPlayers;
        }
    },
    editPlayer(state: EntityStore, { entityId, editedPlayer }: { entityId: number, editedPlayer: Player }) {
        let currentPlayers = state.paginatedItems.items[entityId].players;
        for (let player in currentPlayers) {
            if (Number(player) === editedPlayer.id) {
                currentPlayers[player] = editedPlayer;
            }
        }
    },
    removePlayer(state: EntityStore, { playerId, entityId }: { playerId: number, entityId: number }) {
        if (Object.prototype.hasOwnProperty.call(state.paginatedItems.items, entityId)) {
            let currentPlayers = state.paginatedItems.items[entityId].players;
            if (currentPlayers && Object.prototype.hasOwnProperty.call(currentPlayers, playerId)) {
                delete currentPlayers[playerId];
                state.paginatedItems.items[entityId].players = currentPlayers;
            }
        }
    },
    setSport(state: EntityStore, sport: Sport) {
        state.sport = sport;
    },
    resetEntities(state: EntityStore) {
        state.paginatedItems.items = {};
    },
    setSearchedTeam(state: EntityStore, searchedTeam: string) {
        state.searchedTeam = searchedTeam;
    },
    setOrganizationId(state: EntityStore, organizationId: number) {
        state.organizationId = organizationId;
    },
    setType(state: EntityStore, type: EntityType) {
        state.type = type
    },
    setSeasonId(state: EntityStore, seasonId: number) {
        state.seasonId = seasonId;
    },
    ...PaginatedItemsStoreMutations(),
};
const actions = {
    addEntity({ commit }: ActionContext<EntityStore, IRootState>, entity: Entity) {
        commit('addEntity', entity);
        if (entity.players) {
            for (let p in entity.players) {
                if (entity.players[p]) {
                    commit('playerStore/addPlayer', entity.players[p], { root: true });
                }
            }
        }
    },
    createEntity({ dispatch }: ActionContext<EntityStore, IRootState>, entity: Entity) {
        return authModule.put(`/api/entity`, entity)
            .then((response) => {
                const entity: Entity = response.data as unknown as Entity;
                dispatch('addEntity', entity);
                return entity;
            });
    },
    editEntity({ commit }: ActionContext<EntityStore, IRootState>, entity: Entity) {
        return authModule.patch(`/api/entity/${entity.id}`, entity)
            .then((response) => {
                const entity: Entity = response.data as unknown as Entity;
                commit('editEntity', entity);
                return entity;
            });
    },
    deleteEntity({ commit }: ActionContext<EntityStore, IRootState>, entityId: number) {
        return authModule.delete(`/api/entity/${entityId}`)
            .then((response) => {
                const deletedEntityId: number = response.data as unknown as number;
                commit('removeEntity', deletedEntityId);
            });
    },
    getEntities({ state, commit, dispatch, getters }: ActionContext<EntityStore, IRootState>, { sport, searchedTeam, type, organizationId, seasonId }
        : { sport?: Sport, searchedTeam?: string, type?: EntityType,  organizationId?: number, seasonId?: number }) {
        const currentSport = getters['getSport'];
        const currentSearchedTeam = getters['getSearchedTeam'];
        const currentOrganizationId = getters['getOrganizationId'];
        const currentType = getters['getType']
        const currentSeasonId = getters['getSeasonId'];
        let filtersChanged = false;
        if (currentSport !== sport) {
            commit('setSport', sport);
            filtersChanged = true;
        }
        if (currentSearchedTeam !== searchedTeam) {
            commit('setSearchedTeam', searchedTeam);
            filtersChanged = true;
        }
        if(currentOrganizationId != organizationId) {
            commit('setOrganizationId', organizationId)
            filtersChanged = true
        }
        if(currentType != type) {
            commit('setType', type)
            filtersChanged = true
        }
        if(currentSeasonId != seasonId) {
            commit('setSeasonId', seasonId)
            filtersChanged = true
        }
        let pageToRequest = getters['getNextPage'];
        const pageSize = getters['getPageSize'];
        if (filtersChanged) {
            pageToRequest = 1;
        }
        if (!filtersChanged && getters['getNextPage'] === undefined && getters['getCurrentPage'] > 1) {
            return;
        }
        return authModule.get(`/api/entity`, {
            params: {
                sport: organizationId === undefined ? sport : undefined,
                searchedTeam: searchedTeam !== undefined && searchedTeam !== '' ? searchedTeam : null,
                type,
                organizationId,
                seasonId,
                page: pageToRequest,
                pageSize: pageSize,
            },
        }).then((response) => {
            const pagedResponse: PagedResponse<Entity> = response.data as unknown as PagedResponse<Entity>;
            commit('setCurrentPage', pagedResponse.currentPage);
            commit('setPageSize', pagedResponse.pageSize);
            commit('setNextPage', pagedResponse.nextPage);
            commit('setPreviousPage', pagedResponse.previousPage);
            for (const entityIndex in pagedResponse.items) {
                dispatch('addEntity', pagedResponse.items[entityIndex]);
            }
            return pagedResponse.items;
        }, (err) => {
            if (err.response.status !== 404) {
                console.error(err);
                return null;
            }
            console.log('Could not get entities!');
            return null;
        });
    },
    editPlayer({ commit }: ActionContext<EntityStore, IRootState>, { entityId, player }: { entityId: number, player: Player }) {
        return authModule.patch(`/api/player/${player.id}`, { entityId, ...player })
            .then((response) => {
                const editedPlayer: Player = response.data as unknown as Player;
                commit('editPlayer', { entityId, editedPlayer });
                return editedPlayer;
            });
    },
    uploadLogo({ }: ActionContext<EntityStore, IRootState>, { entityId, entityLogo }: { entityId: number, entityLogo: File }) {
        return authModule
            .post(`/api/entity/${entityId}/logo`, entityLogo, {
                headers: {
                    'Content-Type': entityLogo.type,
                },
            });
    },
};
const entityStore: Module<EntityStore, IRootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
export default entityStore;