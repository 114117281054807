<template>
<svg style="width:100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 480">
<g><path class="tbltenn-icon" d="M438.7,278.7c9.5-24.3,13.9-49.9,13.2-76c-1.5-53.8-24.5-103.3-64.8-139.4C313.4-2.9,198.6-2,125.8,65.4
		c-61.5,57-79.7,146.5-45.2,222.7c5.3,11.7,2.4,25.1-7.2,33.5L73,322c0,0,0,0,0,0l-0.3,0.3l0,0c-0.3,0.3-0.7,0.6-1,0.9l-53.9,48.5
		C6.7,381.8,0.4,395.5,0,410.4c-0.4,14.4,4.8,27.9,14.7,38.3l0,0l1.2,1.2c10.2,10.2,23.7,15.8,38.1,15.8c0.5,0,1,0,1.4,0
		C70.3,465.4,84,459,94,447.9l40.4-44.8c9.2-10.2,9.2-10.2,9.6-10.6l0.3-0.3c8.4-9.5,21.7-12.4,33.4-7.1
		c32.8,14.8,69.2,20.1,105.5,15.3c1,0.2,2.3,0.3,4.3-0.6c41.4-6.4,78.6-25.2,107.7-54.3C414.2,326.3,428.9,303.8,438.7,278.7z M95.4,281.5c-11.5-25.5-16.9-53.7-15.5-81.8l186.1,186.1c-28.2,1.4-56.4-3.9-81.7-15.4c-18.2-8.2-39.1-3.7-52.2,11.2l-11,12.2
		l-49-49l12.1-10.9C99.1,320.7,103.6,299.7,95.4,281.5z M28.7,383.8L60,355.5l50.2,50.2L82,437.1c-7,7.8-16.6,12.2-27,12.5
		c-10.3,0.3-20.4-3.8-27.7-11c-7.4-7.4-11.3-17.2-11.1-27.7C16.5,400.4,20.9,390.8,28.7,383.8z M383.6,334
		c-26.3,26.3-59.8,43.4-97,49.5L82.1,179c6.5-38.9,25.9-75,54.8-101.7c66.7-61.7,171.9-62.6,239.5-2c37,33.1,58.1,78.5,59.4,127.8
		C437.1,252.6,418.6,299,383.6,334z"/><path class="tbltenn-icon" d="M424.4,353.8c-30.7,0-55.6,24.9-55.6,55.6s24.9,55.6,55.6,55.6c30.6,0,55.6-24.9,55.6-55.6
		S455.1,353.8,424.4,353.8z M463.8,409.4c0,21.7-17.7,39.4-39.4,39.4c-21.7,0-39.4-17.7-39.4-39.4c0-21.7,17.7-39.4,39.4-39.4
		c0,0,0,0,0,0c10.5,0,20.4,4.1,27.8,11.6C459.7,389,463.8,398.9,463.8,409.4z"/></g>
</svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({

})
</script>

<style>
.tbltenn-icon {
    fill: var(--_icon-fill, #283380);
}

.active-filter .tbltenn-icon {
  fill: var(--archive-bck-color);
}
</style>