<template>
    <div class="watch-livestream-item-v2 compact" v-if="livestream" >
        <div class="watch-livestream_item-v2-foreground">
            <div class="watch-livestream_item-v2__status live">
                {{ livestream.status === LIVESTREAM_STATUSES[0] ? "Soon" :
                            (livestream.status === LIVESTREAM_STATUSES[1] || livestream.status === LIVESTREAM_STATUSES[2] ? "Watch" : "Completed")}}
            </div>
            <div class="watch-livestream_item-v2__action live">
                <router-link class="watch-livestream-item-v2__btn" :to="{ name: getSportWatchViewName(livestream.sport), params: { id: livestream.id },}">
                    Watch
                </router-link>
            </div>
            <div class="watch-livestream-item-v2__entity">
                <div class="watch-livestream-item-v2__entity__logo">
                    <img :src="'/api/entity/' +  getHomeEntity?.id + '/logo'" :alt="getHomeEntity?.name"/>
                </div>
                <div class="watch-livestream-item-v2__entity__name">{{ getHomeEntity?.name }}</div>
            </div>
            <div class="watch-livestream-item-v2__entities-splitter">
                <img src="/volleyball.png" alt="Volleyball Icon" />
            </div>
            <div class="watch-livestream-item-v2__entity">
                <div class="watch-livestream-item-v2__entity__logo">
                    <img :src="'/api/entity/' +  getAwayEntity?.id + '/logo'" :alt="getAwayEntity?.name"/>
                </div>
                <div class="watch-livestream-item-v2__entity__name">{{ getAwayEntity?.name }}</div>
            </div>
            <div class="watch-livestream-item-v2__start-time">{{ formatDate(livestream.startTime, 'Do MMMM, HH:mm') }}</div>
        </div>
    </div>
</template>

<script lang="ts">
import Livestream, { LIVESTREAM_STATUSES } from "@/models/main/Livestream";
import { computed, defineComponent, PropType } from "vue";
import TableTennisIcon from "@/components/tableTennis/TableTennisIcon.vue"
import TennisIcon from "@/components/tennis/TennisIcon.vue"
import BadmintonIcon from "@/components/badminton/BadmintonIcon.vue"
import { getSportWatchViewName, formatDate } from "@/composables/common/Util";
import GenericDictionary from "../util/GenericDictionary";
import { Entity, EntityType } from "@/models/main/Entity";
import { EntityContainer } from "@/models/main/EntityContainer";

const WatchLivestreamItemv2 = defineComponent({
  props: {
    livestream: {
      type: Object as PropType<Livestream>,
      required: true,
    }
  },
  components: {
      TableTennisIcon,
      TennisIcon,
      BadmintonIcon
  },
  setup(props) {
    const getEntityOfType = (entities: GenericDictionary<EntityContainer>, type: EntityType) => {
        if(entities) {
            for(let eIndex in entities) {
                const entityContainer = entities[eIndex]
                console.log(entityContainer.type, type)
                if(entityContainer.type === type) {
                    return entityContainer.entity;
                }
            }
        }
    }

    const getHomeEntity = computed(() => {
        const container = props.livestream.container
        if(container) {
            const entityContainers = container.entities
            return getEntityOfType(entityContainers, "Home")
        }
    })

    const getAwayEntity = computed(() => {
        const container = props.livestream.container
        if(container) {
            const entityContainers = container.entities
            return getEntityOfType(entityContainers, "Away")
        }
    })
      return {
        getHomeEntity,
        getAwayEntity,
        formatDate,
        getSportWatchViewName,
        LIVESTREAM_STATUSES
      }
  },
});

export default WatchLivestreamItemv2;
</script>

<style>
/* v2 styles*/

.watch-livestream-item-v2 {
    display: grid;
    aspect-ratio: 16 / 9;
    border: 0.25rem solid var(--background-clr);
    border-radius: var(--default-border-rad);
    max-height: 100%;
    max-width: 100%;
}
.watch-livestream-item-v2.compact {
    font-size: 0.75rem;
}
.watch-livestream_item-v2-foreground {
    display: grid;
    grid-template: repeat(6, 1fr) / repeat(7, 1fr);
    width: 100%;
    align-items: center;
    height: 100%;
    background-color: var(--bright-font-clr);
    padding: 1em;
    gap: 0.5em;
    min-width: 0;
    min-height: 0;
}

.watch-livestream-item-v2.compact > .watch-livestream_item-v2-foreground {
  gap: 0.25em;
}

.watch-livestream-item-v2__entity {
    grid-column: span 3;
    grid-row-start: 2;
    grid-row-end: 6;
    display: flex;
    align-items: center;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    overflow: clip;
}
.watch-livestream-item-v2__entities-splitter {
    grid-column: span 1;
    grid-row-start: 2;
    grid-row-end: 5;
    text-transform: uppercase;
    font-size: 1.5em;
    text-align: center;
    font-weight: 500;
}
.watch-livestream-item-v2__entities-splitter > img {
    max-width: min(100%, 2rem);
}

.watch-livestream-item-v2__start-time {
    grid-column: span 7;
    grid-row-start: 6;
    font-size: 1.5em;
    text-align: center;
}
.watch-livestream-item-v2__entity__logo {
  height: calc(100% - 6ch);
  display: flex;
  justify-content: center;
  align-items: center;
}
.watch-livestream-item-v2__entity__logo > img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}
.watch-livestream-item-v2__entity__name {
    font-size: 1.5em;
    font-weight: 500;
    width: 100%;
    overflow: clip;
    min-height: 2.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-wrap: balance;
    text-align: center;
}

.watch-livestream_item-v2__status {
    grid-column-start: 1;
    grid-row-start: 1;
    text-transform: uppercase;
    font-size: 0.875em;
    font-weight: bold;
    padding: 0.25em;
    text-align: center;
}

.watch-livestream_item-v2__status.live, .watch-livestream_item-v2__status.soon {
    color: var(--btn-error-background-color);
}

.watch-livestream_item-v2__action.live {
    grid-column-start: 6;
    grid-column-end: -1;
    grid-row-start: 1;
    display: flex;
    justify-content: flex-end;
}

.watch-livestream-item-v2__btn {
  background-color: var(--btn-error-background-color);
  padding: 0.25em 0.5em;
  border-radius: var(--default-border-rad);
}
</style>