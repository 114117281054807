<template>
<svg style="width:100%"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 111.1 110.9" xml:space="preserve">
<g>
	<path class="tenn-icon" d="M108.9,98.2L79.2,68.4l-2.9-19.2c1-7.1-0.1-14.6-3.3-21.8c0,0,0,0,0-0.1c-2.2-5-5.5-9.8-9.7-14c0,0,0,0,0,0
		c0,0,0,0,0,0c-4.1-4.1-8.9-7.4-14-9.7c0,0,0,0-0.1,0C43.9,1.3,38.2,0,32.6,0c0,0,0,0,0,0c-9,0-17.1,3.3-23,9.1c0,0,0,0,0,0
		c0,0,0,0,0,0l0,0c-6.7,6.7-9.9,16-9,26.2c1,10.5,5.7,20.3,13.3,27.7c7.4,7.6,17.2,12.3,27.7,13.3c1.1,0.1,2.1,0.1,3.2,0.1v0
		c0.2,0,0.4,0,0.6,0c1.5,0,3-0.1,4.5-0.3l19.1,3.1l29.6,29.6c1.4,1.4,3.3,2.2,5.3,2.2c0,0,0,0,0,0c2,0,3.9-0.8,5.3-2.2
		c1.4-1.4,2.2-3.3,2.2-5.3C111.1,101.5,110.4,99.6,108.9,98.2z M46.1,5.6l-7.5,7.5l-9.9-9.9C29.8,3.1,30.9,3,32,3c0.2,0,0.4,0,0.6,0
		C37.3,3.1,41.8,4,46.1,5.6z M3.6,28.3l9.9,9.9L6,45.7c-1.3-3.4-2.1-6.9-2.5-10.6C3.3,32.8,3.3,30.5,3.6,28.3z M38.6,17.3l9.4,9.4
		l-9.4,9.4l-9.4-9.4L38.6,17.3z M50.1,47.5l-9.4-9.4l9.4-9.4l9.4,9.4L50.1,47.5z M36.4,38.2l-9.4,9.4l-9.4-9.4l9.4-9.4L36.4,38.2z
		 M29.2,49.7l9.4-9.4l9.4,9.4L38.6,59L29.2,49.7z M52.2,49.7l9.4-9.4l11.3,11.3c-1.1,4.6-3.2,8.8-6.4,12.3L52.2,49.7z M40.7,61.2
		l9.3-9.3l14.3,14.3c-3.5,3.1-7.7,5.3-12.3,6.4L40.7,61.2z M73.5,48l-9.8-9.8l7.5-7.5C73.4,36.4,74.2,42.4,73.5,48z M50.1,24.6
		l-9.4-9.4l8.4-8.4c4.1,1.9,7.8,4.4,11.1,7.6L50.1,24.6z M36.4,15.2l-9.4,9.4L12.8,10.3c3.5-3.1,7.8-5.3,12.3-6.4L36.4,15.2z
		 M15.6,40.3l9.4,9.4L14.8,59.8c-3.1-3.3-5.7-7-7.6-11.1L15.6,40.3z M27.1,51.8l9.4,9.4l-8.4,8.4c-4.1-1.9-7.8-4.5-11.1-7.6
		L27.1,51.8z M61.6,36l-9.4-9.4l10.1-10.1c3.2,3.4,5.7,7.2,7.6,11.1L61.6,36z M24.9,26.7L15.6,36L4.2,24.7c1.1-4.7,3.3-8.9,6.4-12.3
		L24.9,26.7z M38.6,63.3l9.9,9.9c-2.2,0.3-4.4,0.3-6.8,0.1c-3.7-0.4-7.2-1.2-10.6-2.5L38.6,63.3z M67.6,67.2
		c3.1-3.1,5.3-6.7,6.9-10.6l1.8,12.1l-7.4,7.4l-12.1-1.9C60.8,72.6,64.5,70.3,67.6,67.2z M77.9,71.4l28.9,28.9
		c0.8,0.8,1.3,2,1.3,3.2c0,1.2-0.5,2.3-1.3,3.2c-0.9,0.8-2,1.3-3.2,1.3c-1.2,0-2.3-0.5-3.2-1.3L71.6,77.7L77.9,71.4z"/>
	<path class="tenn-icon" d="M19.2,82.6c-7.8,0-14.2,6.4-14.2,14.2s6.4,14.2,14.2,14.2c7.8,0,14.2-6.4,14.2-14.2c0-3.8-1.5-7.3-4.1-10
		C26.6,84.1,23,82.6,19.2,82.6z M27.1,104.9c-2.1,2.1-4.9,3.2-7.9,3.2c-3,0-5.7-1.1-7.9-3.2c2.2-2.1,4.9-3.3,7.9-3.3
		C22.1,101.7,25,102.9,27.1,104.9z M30.6,96.8c0,2.1-0.6,4.2-1.7,6c-2.7-2.5-6.1-3.9-9.6-3.9c-3.6,0-7,1.4-9.6,3.9
		c-1.1-1.8-1.7-3.9-1.7-6c0-1.8,0.4-3.5,1.3-5.2c2.7,2.7,6.3,4.3,10,4.3c3.7,0,7.3-1.6,10-4.3C30.1,93.2,30.6,95,30.6,96.8z
		 M10.8,89.2c2.2-2.4,5.2-3.8,8.4-3.8c3.2,0,6.2,1.4,8.4,3.8c-2.2,2.4-5.2,3.8-8.4,3.8C16.1,93.1,13,91.7,10.8,89.2z"/>
</g>
</svg>

</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({

})
</script>

<style>
.tenn-icon {
    fill: var(--_icon-fill, #283380);
}

.active-filter .tenn-icon {
  fill: var(--archive-bck-color);
}
</style>