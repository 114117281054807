import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "yt-broadcast-item__thumbnail" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "yt-broadcast-item__info" }
const _hoisted_4 = { class: "yt-broadcast-item__info_primary" }
const _hoisted_5 = { class: "container-title" }
const _hoisted_6 = { class: "yt-broadcast-item__info_secondary" }
const _hoisted_7 = { class: "yt-broadcast-item__start" }
const _hoisted_8 = { class: "yt-broadcast-item__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click'))),
    class: _normalizeClass(["yt-broadcast-item", { selected: _ctx.isSelected }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: _ctx.ytLiveBroadcast.snippet.thumbnails.default.url
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.ytLiveBroadcast.snippet.title), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.formatDate(_ctx.ytLiveBroadcast.snippet.scheduledStartTime, 'MMMM Do, YYYY HH:mm')), 1),
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.ytLiveBroadcast.snippet.description), 1)
      ])
    ])
  ], 2))
}