import DataModelMapper from "@/components/util/DataModelMapper";
import DataModel from "@/models/main/DataModel";
import { EntityContainer, EntityContainerType } from "@/models/main/EntityContainer";
import { ContainerDataModel } from "./ContainerDataModel";
import EntityDataModel from "./EntityDataModel";

export default class EntityContainerDataModel extends DataModel implements EntityContainer {
    public static readonly MODEL_NAME = "EnC"
    private _type?: EntityContainerType;
    private _container?: ContainerDataModel
    private _containerId?: number
    private _entity?: EntityDataModel

    //#region Fields Keys

    static get entityKey() {
        return "entity";
    }

    static get containerIdKey() {
        return "containerId";
    }

    static get typeKey() {
        return "type";
    }
    //#endregion

    // #region accessors/mutations 
    get entity() {
        return this._entity
    }

    set entity(entity) {
        this._entity = entity
    }

    get container() {
        console.error("This should not be called as it is not populated for now!")
        return this._container
    }

    set container(container) {
        this._container = container
    }

    get containerId() {
        return this._containerId
    }

    set containerId(containerId) {
        this._containerId = containerId
    }

    get type() {
        return this._type;
    }

    set type(type) {
        this._type = type;
    }


    //#endregion

    constructor(modelID:string, modelData: any) {
        super();
        this.parseMinifiedData(modelData);
        if(this.entity !== undefined &&  this.entity?.id !== undefined) {
            const modelId = DataModel.generateModelID(this, {"entityId": this.entity?.id});
            if(modelId !== false) {
                this.modelID = modelId
            }
        }
    }

    // #region FieldMappings Getters/Setters

    dataModelName() {
        return EntityDataModel.MODEL_NAME
    }

    getKeyByMinifiedKey(minKey: string) {
        switch (minKey) {
            case "1":
                return EntityContainerDataModel.entityKey;
            case "2":
                return EntityContainerDataModel.containerIdKey;
            case "3":
                return EntityContainerDataModel.typeKey;
            default: 
                return ""
        }
    }

    getProperty(propertyKey: string) {
        switch (propertyKey) {
            case EntityContainerDataModel.entityKey:
                return this.entity;
            case EntityContainerDataModel.containerIdKey:
                return this.containerId;
            case EntityContainerDataModel.typeKey:
                return this.type;
        }
    }

    setProperty(propertyKey: string, value: any) {
        const entity = value;
        let entityModel = null;
        switch (propertyKey) {
            case EntityContainerDataModel.entityKey:               
                if (entity instanceof DataModel) {
                    entityModel = entity;
                } else if(entity !== null) {
                    entityModel = DataModelMapper.createFromObject(entity);
                }
                if (entityModel instanceof EntityDataModel) {
                    this.entity = entityModel;
                } else if (entity === null) {
                    this.entity = undefined
                }
                break;
            case EntityContainerDataModel.containerIdKey:
                this.containerId = value;
                break;
            case EntityContainerDataModel.typeKey:
                this.type = value;
                break
        }
    }

    //#endregion
}