import GenericDictionary from "@/components/util/GenericDictionary"
export const VLY_POINT = 'Vly_P'
export const VLY_ACE = 'Vly_A'
export const VLY_ATTACK = 'Vly_At'
export const VLY_BLOCK = 'Vly_Bl'
export const VLY_ERROR = 'Vly_Err'
export const VLY_SERVICE_ERROR = 'Vly_SE'
export const VLY_RECIEVE_ERROR = 'Vly_RE'
export const VLY_FOUL = 'Vly_F'


export type VolleyballPointType = typeof VLY_POINT |        
typeof VLY_ACE | typeof VLY_ATTACK | typeof VLY_BLOCK | 
typeof VLY_ERROR | typeof VLY_SERVICE_ERROR | typeof VLY_RECIEVE_ERROR |
typeof VLY_FOUL

export const VOLLEYBALL_POINT_TITLE_PAIRS: GenericDictionary<string> =  {}
VOLLEYBALL_POINT_TITLE_PAIRS[VLY_POINT] = 'Point'
VOLLEYBALL_POINT_TITLE_PAIRS[VLY_ACE] = 'Ace'
VOLLEYBALL_POINT_TITLE_PAIRS[VLY_ATTACK] = 'Attack'
VOLLEYBALL_POINT_TITLE_PAIRS[VLY_BLOCK] = 'Block'
VOLLEYBALL_POINT_TITLE_PAIRS[VLY_ERROR] = 'Error'
VOLLEYBALL_POINT_TITLE_PAIRS[VLY_SERVICE_ERROR] = 'Service Error'
//VOLLEYBALL_POINT_TITLE_PAIRS[VLY_RECIEVE_ERROR] = 'Recieve Error'
VOLLEYBALL_POINT_TITLE_PAIRS[VLY_FOUL] = 'Foul'


export const VOLLEYBALL_IS_POSITIVE_POINT: GenericDictionary<boolean> = {}
VOLLEYBALL_IS_POSITIVE_POINT[VLY_POINT] = true
VOLLEYBALL_IS_POSITIVE_POINT[VLY_ACE] = true
VOLLEYBALL_IS_POSITIVE_POINT[VLY_ATTACK] = true
VOLLEYBALL_IS_POSITIVE_POINT[VLY_BLOCK] = true
VOLLEYBALL_IS_POSITIVE_POINT[VLY_ERROR] = false
VOLLEYBALL_IS_POSITIVE_POINT[VLY_SERVICE_ERROR] = false
VOLLEYBALL_IS_POSITIVE_POINT[VLY_RECIEVE_ERROR] = false
VOLLEYBALL_IS_POSITIVE_POINT[VLY_FOUL] = false

const gameEnd = 'Vly_G'
const timeout = 'Vly_T'
const matchEnd = 'Vly_M'

export const VOLLEYBALL_GAME_CONTROL_TYPES: GenericDictionary<string> = {}
VOLLEYBALL_GAME_CONTROL_TYPES[gameEnd] = 'End of Game'
VOLLEYBALL_GAME_CONTROL_TYPES[timeout] = 'Time Out'
VOLLEYBALL_GAME_CONTROL_TYPES[matchEnd] = 'End of Match'

export type VolleyballGameControlType = typeof gameEnd | typeof timeout | typeof matchEnd