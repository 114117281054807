import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VolleyballIcon = _resolveComponent("VolleyballIcon")!
  const _component_BowlingIcon = _resolveComponent("BowlingIcon")!
  const _component_BadmintonIcon = _resolveComponent("BadmintonIcon")!
  const _component_TableTennisIcon = _resolveComponent("TableTennisIcon")!
  const _component_TennisIcon = _resolveComponent("TennisIcon")!

  return (_ctx.sport === 'Volleyball')
    ? (_openBlock(), _createBlock(_component_VolleyballIcon, { key: 0 }))
    : (_ctx.sport === 'Bowling')
      ? (_openBlock(), _createBlock(_component_BowlingIcon, { key: 1 }))
      : (_ctx.sport === 'Badminton')
        ? (_openBlock(), _createBlock(_component_BadmintonIcon, { key: 2 }))
        : (_ctx.sport === 'TableTennis')
          ? (_openBlock(), _createBlock(_component_TableTennisIcon, { key: 3 }))
          : (_ctx.sport === 'Tennis')
            ? (_openBlock(), _createBlock(_component_TennisIcon, { key: 4 }))
            : _createCommentVNode("", true)
}