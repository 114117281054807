<template>
    <TheOrganizerDashboard>
        <template #heading v-if="container">
            <router-link :to="{ name: 'Organization Menu', params: { id: container?.organizationId }}">
                <div class="page-heading">{{ organization?.name }}</div>
            </router-link>
            <router-link :to="{ name: 'Container Menu', params: { organizationId: container?.organizationId, id : container?.id } }">
                <div class="page-heading">
                    {{ container?.title }}
                </div>
            </router-link>
            <div class="page-heading">
                Rankings
            </div>
        </template> 
         <div class="container-views-container">
            <template v-for="containerRankingView in containerRankingViews">
                <router-link :to="{ name: 'Container Public View', params: { id: containerRankingView.id } }">
                    <div class="container-view-card">
                        <div class="container-view-card__title">{{ containerRankingView.title }}</div>
                        <div class="container-view-card__description">{{ containerRankingView.description }}</div>
                        <div class="container-view-card__type-logo">
                            <span>{{ containerRankingView.viewType }}</span>
                            <div class="elimination-example" v-if="containerRankingView.viewType == 'Elimination'">
                                <div class="elimination-example__r1">
                                    <div class="elimination-example__m1"></div>
                                    <div class="elimination-example__m2"></div>
                                </div>
                                <div class="elimination-example__r2">
                                    <div class="elimination-example__m1"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </router-link>
            </template>
            <div class="container-view-card">
                <button class="container-view-card--add" role="button" title="Create Ranking" @click="rankingFormPopup.openModal()">
                    <div class="container-view-card__title">Create Ranking</div>
                    <img src="/plus-add-sign-green.svg" :alt="'Create Ranking'" width="36" height="36" />
                </button>
            </div>
         </div>
         <Modal ref="rankingFormPopup">
            <template v-slot:header>
                <h3>Create Ranking</h3>
            </template>
            <template v-slot:body>
                <div class="spz-text-input-group">
                    <label for="ranking-title">Title:</label>
                    <input type="text" id="ranking-title" v-model="rankingTitle"/>
                </div>
            </template>
            <template v-slot:footer>
                <div>
                    <button @click="rankingFormPopup.closeModal(), createRanking()" class="btn add-btn">Create</button>
                    <button @click="rankingFormPopup.closeModal()" class="btn btn-inverse">Cancel</button>
                </div>
            </template>
        </Modal>
    </TheOrganizerDashboard>
</template>
<script setup lang="ts">
import TheOrganizerDashboard from '@/components/main/TheOrganizerDashboard.vue'
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { computed, ComputedRef, onMounted, ref, Ref } from 'vue'
import { key } from '@/store';
import { Container } from '@/models/main/Container';
import { Organization } from '@/models/main/Organization';
import Modal from '@/components/main/Modal.vue'
import { useToast } from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

const store = useStore(key);
const route = useRoute()
const $toast = useToast()
const containerId: Ref<number> = ref(parseInt(route.params.id as string));
const containerRankingViews: Ref<Array<any>> = ref([])
const container: ComputedRef<Container> = computed(() => {
    return store.getters['containerStore/getContainer'](containerId.value)
})
const organization: ComputedRef<Organization | undefined> = computed(() => {
    return store.getters['organizationStore/getOrganization'](container.value?.organizationId)
})

const loadOrganization = async () => {
    if(!organization.value) {
        await store.dispatch('organizationStore/getOrganization', container.value?.organizationId);
    }
};

const rankingFormPopup: Ref<any> = ref(null)
const rankingTitle: Ref<string> = ref('')
const createRanking = async () => {
    console.info(rankingTitle.value)
    await store.dispatch('containerStore/createContainerRankingView', { containerId: containerId.value, title: rankingTitle.value, type : "Standard"})
    .then((createdRankingView: any) => {
        $toast.success("Created Ranking: "  + rankingTitle.value)
        containerRankingViews.value.push(createdRankingView)
    })
}
onMounted(() => {
    store.dispatch('containerStore/getContainer', containerId.value).then(() => {
            loadOrganization()
        }
    )

    store.dispatch('containerStore/listContainerRankingViews', containerId.value)
    .then((response) => {
        containerRankingViews.value = response
        containerRankingViews.value.sort((a, b) => {
            return (a?.sequence ?? 0) - (b?.sequence ?? 0)
        })
    })
    if(container.value) {
        loadOrganization()
    }
})

</script>
<style>
.container-view-card--add {
    display: flex;
    grid-row: 1 / -1;
    grid-column: 1 / -1;
    justify-content: space-evenly;
    align-items: center;
    border: none;
    background: none;
    cursor: pointer;
    flex-direction: column;
}
.container-view-card--add img {
    width: 2.25rem;
    text-align: center;
}
</style>