import DataModel from "@/models/main/DataModel";
import { Event, EventType } from '@/models/main/Event';

export default class EventDataModel extends DataModel implements Event {
    public static readonly MODEL_NAME = "E"

    private _id?: number;
    private _type: EventType | null =  null;
    private _startTimestamp: Date | null = null;
    private _endTimestamp: Date | null = null;
    private _description: string | null = null;
    private _additionalData?: string;
    private _entityId?: number;
    private _playerId?: number;

    //#region Fields Keys
    static get IDKey() {
        return "id";
    }

    static get typeKey() {
        return "type";
    }

    static get startTimestampKey() {
        return "startTimestamp";
    }

    static get endTimestampKey() {
        return "endTimestamp";
    }

    static get descriptionKey() {
        return "description";
    }

    static get additionalData() {
        return "additionalData";
    }

    static get entityIDKey() {
        return "entityId";
    }

    static get playerIDKey() {
        return "playerId";
    }
    //#endregion

    // #region accessors/mutations 
    get id() {
        return this._id;
    }

    set id(id) {
        this._id = id;
    }

    get type() {
        return this._type;
    }

    set type(type) {
        this._type = type;
    }

    get startTimestamp() {
        return this._startTimestamp;
    }

    set startTimestamp(startTimestamp) {
        this._startTimestamp = startTimestamp;
    }

    get endTimestamp() {
        return this._endTimestamp;
    }

    set endTimestamp(endTimestamp) {
        this._endTimestamp = endTimestamp;
    }

    get description() {
        return this._description;
    }

    set description(description) {
        this._description = description;
    }

    get additionalData() {
        return this._additionalData;
    }

    set additionalData(additionalData) {
        this._additionalData = additionalData;
    }

    get entityId() {
        return this._entityId;
    }

    set entityId(entityId) {
        this._entityId = entityId;
    }

    get playerId() {
        return this._playerId;
    }

    set playerId(playerId) {
        this._playerId = playerId;
    }

    //#endregion

    constructor(modelID:string, modelData: any) {
        super();
        this.parseMinifiedData(modelData);
        if(this.id !== undefined) {
            const modelId = DataModel.generateModelID(this, {"id": this.id});
            if(modelId !== false) {
                this.modelID = modelId
            }
        }
    }
    

    // #region FieldMappings Getters/Setters

    dataModelName() {
        return EventDataModel.MODEL_NAME
    }

    getKeyByMinifiedKey(minKey: string) {
        switch (minKey) {
            case "1":
                return EventDataModel.IDKey;
            case "2":
                return EventDataModel.typeKey;
            case "3":
                return EventDataModel.startTimestampKey;
            case "4":
                return EventDataModel.endTimestampKey;
            case "5":
                return EventDataModel.descriptionKey;
            case "6":
                return EventDataModel.additionalData;
            case "7":
                return EventDataModel.entityIDKey;
            case "8":
                return EventDataModel.playerIDKey;
            default: 
                return ""
        }
    }

    getProperty(propertyKey: string) {
        switch (propertyKey) {
            case EventDataModel.IDKey:
                return this.id;
            case EventDataModel.typeKey:
                return this.type;
            case EventDataModel.startTimestampKey:
                return this.startTimestamp;
            case EventDataModel.endTimestampKey:
                return this.endTimestamp;
            case EventDataModel.descriptionKey:
                return this.description;
            case EventDataModel.additionalData:
                return this.additionalData;
            case EventDataModel.entityIDKey:
                return this.entityId;
            case EventDataModel.playerIDKey:
                return this.playerId;
        }
    }

    setProperty(propertyKey: string, value: any) {
        switch (propertyKey) {
            case EventDataModel.IDKey:
                this.id = value;
                break;
            case EventDataModel.typeKey:
                this.type = value;
                break;
            case EventDataModel.startTimestampKey:
                this.startTimestamp = value;
                break;
            case EventDataModel.endTimestampKey:
                this.endTimestamp = value;
                break;
            case EventDataModel.descriptionKey:
                this.description = value;
                break;
            case EventDataModel.additionalData:
                this.additionalData = value;
                break;
            case EventDataModel.entityIDKey:
                this.entityId = value;
                break;
            case EventDataModel.playerIDKey:
                this.playerId = value;
                break;
        }
    }

    //#endregion
}