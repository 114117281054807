<template>
    <ThePublicHomeLayout>
        <div class="vly-score">
            <div class="container-title">{{ getCurrentContainer?.title }}</div>
            <div class="score-form" :class="{ 'disabled': formIsDisabled }">
                <div class="break-controls">
                </div>
                <div class="match-controls">
                    <div class="left-control">
                        <a v-show="offlineDataDownloadLinkUri"
                            :download="getCurrentContainer?.title + '_offline' + '.csv'" class="btn" type="button"
                            :href="offlineDataDownloadLinkUri" title="Save Stats">Download Changes</a>
                    </div>
                    <div class="middle-control">
                        <button @click="onReverseClick" class="btn delete-btn" type="button" title="Undo"
                            :disabled="formIsDisabled">Undo</button>
                    </div>
                    <div class="right-control">

                    </div>
                </div>
                <div class="match-scoreboard">
                    <div class="home-team">
                        <div class="main-scoreboard">
                            <div class="scoreboard-top" style="margin-left:10px;">
                                <div class="team-name default-box">{{ getCurrentHomeEntity?.name }}</div>
                                <ActionBox :isDisabled="formIsDisabled" class="game-point" @click="onHomePointBoxClick">
                                    {{ getCurrentHomeEntityPoints }}</ActionBox>
                            </div>
                            <div class="scoreboard-bottom">
                                <ActionBox :isDisabled="formIsDisabled"
                                    @click="onGameBoxClick(getCurrentHomeEntity?.id)" style="float:left;">
                                    {{ currentHomeEntityGamePoints }}</ActionBox>
                                <div v-if="false" class="timeouts-box">
                                    <input type="checkbox" value="1" v-model="homeTimeouts" />
                                    <input type="checkbox" value="2" v-model="homeTimeouts" />
                                </div>
                            </div>
                        </div>
                        <div class="detail-points-board">
                            <div class="home-team-competitors">
                                <template v-for="player in getCurrentHomePlayersOrdered" :key="player.id">
                                    <CompetitorNameToggle v-if="player.isActive" :isDisabled="formIsDisabled"
                                        @click="toggleHomePlayerSelect(player.id)"
                                        :isSelected="selectedHomePlayerId === player.id" :number="player.number"
                                        :firstName="player.firstName" :middleName="player.middleName"
                                        :lastName="player.lastName" />
                                </template>

                            </div>
                            <div class="home-point-types">
                                <PointTypesSet :isDisabled="formIsDisabled" @click="onHomePointBoxClick" />
                            </div>
                        </div>
                    </div>
                    <div class="splitter">:</div>
                    <div class="away-team">
                        <div class="main-scoreboard">
                            <div class="scoreboard-top" style="margin-right: 10px;">
                                <div class="team-name default-box">{{ getCurrentAwayEntity?.name }}</div>
                                <ActionBox :isDisabled="formIsDisabled" class="game-point" @click="onAwayPointBoxClick">
                                    {{ getCurrentAwayEntityPoints }}</ActionBox>
                            </div>
                            <div class="scoreboard-bottom">
                                <div v-if="false" class="timeouts-box">
                                    <input type="checkbox" value="1" v-model="awayTimeouts" />
                                    <input type="checkbox" value="2" v-model="awayTimeouts" />
                                </div>
                                <ActionBox :isDisabled="formIsDisabled"
                                    @click="onGameBoxClick(getCurrentAwayEntity?.id)" style="float:right;">
                                    {{ currentAwayEntityGamePoints }}</ActionBox>
                            </div>
                        </div>
                        <div class="detail-points-board">
                            <div class="away-team-competitors">
                                <template v-for="player in getCurrentAwayPlayersOrdered" :key="player.id">
                                    <CompetitorNameToggle v-if="player?.isActive" :isDisabled="formIsDisabled"
                                        @click="toggleAwayPlayerSelect(player.id)"
                                        :isSelected="selectedAwayPlayerId === player.id" :number="player.number"
                                        :firstName="player.firstName" :middleName="player.middleName"
                                        :lastName="player.lastName" />
                                </template>

                            </div>
                            <div class="away-point-types">
                                <PointTypesSet :isDisabled="formIsDisabled" @click="onAwayPointBoxClick" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ThePublicHomeLayout>
</template>
<script lang="ts">
import ThePublicHomeLayout from '@/components/main/ThePublicHomeLayout.vue'
import CompetitorNameToggle from '@/components/volleyball/CompetitorNameToggle.vue'
import PointTypesSet from '@/components/volleyball/PointTypesSet.vue'
import ActionBox from '@/components/volleyball/ActionBox.vue'
import { useStore, createNamespacedHelpers } from 'vuex'
import { defineComponent, onMounted } from 'vue'
import { key } from '@/store'
import { useRoute } from 'vue-router'
import Modal from '@/components/main/Modal.vue';
import { copyToClipboard } from '@/composables/common/Util';
import useVolleyballContainerActions from '@/composables/volleyball/UseVolleyballContainerActions'
const { mapGetters } = createNamespacedHelpers('containerStore')

export default defineComponent({
    computed: {
        ...mapGetters([
            'getContainer',
            'getHomeEntity',
            'getAwayEntity',
        ]),
    },
    setup() {
        const store = useStore(key);
        const route = useRoute()

        const {
            calculateEntityPoints,
            calculateEntityGames,
            getCurrentContainer,
            getCurrentHomeEntity,
            getCurrentAwayEntity,
            getCurrentHomeEntityPoints,
            getCurrentAwayEntityPoints,
            currentHomeEntityGamePoints,
            currentAwayEntityGamePoints,
            getCurrentHomePlayersOrdered,
            getCurrentAwayPlayersOrdered,
            offlineDataDownloadLinkUri,
            homeTimeouts,
            awayTimeouts,
            selectedHomePlayerId,
            selectedAwayPlayerId,
            toggleHomePlayerSelect,
            toggleAwayPlayerSelect,
            onHomePointBoxClick,
            onAwayPointBoxClick,
            onMatchFinishedClick,
            onReverseClick,
            onGameBoxClick,
            onTimeoutClick,
            loadContainer,
            formIsDisabled,
            accessKey,
            shareLink,
            infoPopUp,
        } = useVolleyballContainerActions()

        onMounted(loadContainer);
        return {
            homeTimeouts,
            awayTimeouts,
            selectedHomePlayerId,
            selectedAwayPlayerId,
            calculateEntityPoints,
            calculateEntityGames,
            getCurrentContainer,
            getCurrentHomeEntity,
            getCurrentAwayEntity,
            getCurrentHomeEntityPoints,
            getCurrentAwayEntityPoints,
            currentHomeEntityGamePoints,
            currentAwayEntityGamePoints,
            getCurrentHomePlayersOrdered,
            getCurrentAwayPlayersOrdered,
            toggleHomePlayerSelect,
            toggleAwayPlayerSelect,
            onHomePointBoxClick,
            onAwayPointBoxClick,
            onMatchFinishedClick,
            onReverseClick,
            onGameBoxClick,
            onTimeoutClick,
            formIsDisabled,
            accessKey,
            shareLink,
            infoPopUp,
            copyToClipboard,
            offlineDataDownloadLinkUri,
        }
    },
    components: {
        ThePublicHomeLayout,
        CompetitorNameToggle,
        PointTypesSet,
        ActionBox,
        Modal,
    }
})
</script>

<style>
.public-layout-main .vly-score {
    background-color: var(--background-clr);
    z-index: 10;
    padding: 1em;
}
</style>