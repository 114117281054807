import DataManager from "@/components/util/DataManager";
import DataModel from "@/models/main/DataModel";
import LiveDataModel from "@/composables/dataModels/LiveDataModel";
import { ContainerDataModel } from "@/composables/dataModels/ContainerDataModel";
import { IRootState } from "@/store";
import { Store } from "vuex";

export default function useDataManagerHelpers(store: Store<IRootState>) {
    const initDataManager = async (liveId: number,
        onInitializedCallback: ((value: void) => void) | undefined = defaultOnDataManagerInstanced, 
        onContainerChangeCallback:((container: ContainerDataModel) => void) | undefined = defaultOnContainerChangeCallback, 
        onLiveModelChangeCallback: ((live: LiveDataModel) => void) | undefined = defaultOnLiveModelChange
        ) => {
        const dataManagerInstance = DataManager.instance(onInitializedCallback);
        if (dataManagerInstance !== null) {
            dataManagerInstance.modelUrl = "https://portal.sportzone.live/api/dataModel";
            const liveDataModelID = DataModel
                .generateModelID(new LiveDataModel(), { "id": liveId }) //TODO this is bad
            if (liveDataModelID === false) {
                console.error("Could not generate proper Model ID!")
                return;
            }
    
            await dataManagerInstance.subscribeToDataModel(
                liveDataModelID,
                onLiveModelChangeCallback,
                false
            );
            const subscribedLiveDataModel = await dataManagerInstance.dataModels[liveDataModelID]
            const livestreamDataModel = subscribedLiveDataModel as LiveDataModel
            if (livestreamDataModel?.containerId) {
                const containerDataModelId = DataModel
                    .generateModelID(new ContainerDataModel(), { "id": livestreamDataModel.containerId }) //TODO this is bad 
                if (containerDataModelId === false) {
                    console.error("Could not generate proper Model ID!")
                    return;
                }
    
                await dataManagerInstance.subscribeToDataModel(
                    containerDataModelId,
                    onContainerChangeCallback,
                    false
                );
                const subscribedContainerDataModel = await dataManagerInstance.dataModels[containerDataModelId]
                const containerDataModel = subscribedContainerDataModel as ContainerDataModel
                if (containerDataModel.id !== undefined) {
                    store.commit('containerStore/setContainer', containerDataModel)
                }

                return { dataManager: dataManagerInstance, livestreamDataModel, containerDataModel }
            } else {
                return { dataManager: dataManagerInstance, livestreamDataModel, containerDataModel: undefined }
            }
        } else {
            console.error("Data manager is not initalized")
        }
    }

    const defaultOnContainerChangeCallback = (updatedContainer: ContainerDataModel) => {
        //container.value = updatedContainer
        const testContainer: ContainerDataModel = new ContainerDataModel()
        testContainer.id = updatedContainer.id
        testContainer.title = updatedContainer.title
        testContainer.type = updatedContainer.type
        testContainer.status = updatedContainer.status
        testContainer.startTime = updatedContainer.startTime
        testContainer.endTime = updatedContainer.endTime
        testContainer.sport = updatedContainer.sport
        testContainer.events = updatedContainer.events,
        testContainer.livestreams = updatedContainer.livestreams
        testContainer.entities = updatedContainer.entities
    
        store.commit('containerStore/setContainer', testContainer)
    }

    const defaultOnLiveModelChange = (live: LiveDataModel) => {
        console.info("Live changed!", live)
        // if (live.containerId !== container.value?.id) {
        //     //should change container! unsubscribe previous subscribe to new one
        // }
    };

    const defaultOnDataManagerInstanced = async () => {
        console.info("Data manager is ready.")
    };

    return {
        initDataManager
    }
}

