<template>
    <TheUserDashboard :onUserLoad="onUserLoaded" title="Player Dashboard">
        <UserPlayerDashboard v-if="currentUser && currentPlayer" :user="currentUser" :player="currentPlayer" />
    </TheUserDashboard>
</template>
<script lang='ts'>
import { defineComponent, Ref, ref } from 'vue'
import { useRouter/*, useRoute*/ } from 'vue-router'
import { useStore } from 'vuex'
import TheUserDashboard from '@/components/main/TheUserDashboard.vue'
import UserPlayerDashboard from '@/components/user/UserPlayerDashboard.vue'
import { Player } from '@/models/main/Player'
import { User } from '@/models/main/User'
import { key } from '@/store'
export default defineComponent({
    components: {
        TheUserDashboard,
        UserPlayerDashboard
    },
    setup() {
        const router = useRouter()
        // const route = useRoute()
        const store = useStore(key)
        const currentUser: Ref<User | undefined> = ref()
        const currentPlayer: Ref<Player | undefined> = ref()
        // const currentPlayerId = parseInt(route.params.id as string)
        const onUserLoaded = (user: User) => {
            currentUser.value = user
        }
        const loadUserStats = async () => {
            const stats = await store.dispatch('userStatStore/fetchUserStats', 2409)
            currentPlayer.value = stats.players[1]
        }
        loadUserStats()
        return {
            router,
            store,
            currentUser,
            currentPlayer,
            onUserLoaded
        }
    }
})
</script>