import { PagedResponse, PaginatedItemsStoreGetters, PaginatedItemsStoreMutations, commitPagedResponseDetails, prepForPagedCall } from "@/composables/common/Pagination";
import authModule from "@/main";
import { OrganizationApplication } from "@/models/main/OrganizationApplication";
import { IRootState } from "@/store";
import { ActionContext, Module } from "vuex";

const state: OrganizationApplicationStore = {
    paginatedItems: {
        items: {},
        pageSize: 30,
        currentPage: 1,
    }
};
export interface OrganizationApplicationStore {
    paginatedItems: PagedResponse<OrganizationApplication>,
    searchedTeam?: string,
};

const getters = {
    getOrganizationApplications: (state: OrganizationApplicationStore) => (searchTerm?: string) => {
        let filteredOrganizationApplications: Array<OrganizationApplication> = [];
        const lowerCaseSearchTerm = searchTerm?.toLowerCase();
        for (const organizationApplicationId in state.paginatedItems.items) {
            if (Object.prototype.hasOwnProperty.call(state.paginatedItems.items, organizationApplicationId)) {
                const organizationApplication: OrganizationApplication = state.paginatedItems.items[organizationApplicationId] as OrganizationApplication;
                const applicant = organizationApplication.user;
                if(applicant) {
                    const applicantName = applicant.firstName + (applicant.middleName ? ' ' + applicant.middleName + ' ' : ' ') + applicant.lastName;
                    const inviteeNameLowerCased = applicantName.toLowerCase();
                    const searchedTeamIsOk = lowerCaseSearchTerm === undefined || inviteeNameLowerCased.startsWith(lowerCaseSearchTerm);
                    if (searchedTeamIsOk) {
                        filteredOrganizationApplications.push(organizationApplication);
                    }
                }
            }
        }
        filteredOrganizationApplications = filteredOrganizationApplications.sort((a, b) => {
            const aUser = a.user;
            const bUser = b.user;
            if(aUser && bUser) {
                const aName = aUser.firstName + (aUser.middleName ? ' ' + aUser.middleName + ' ' : ' ') + aUser.lastName;
                const bName = bUser.firstName + (bUser.middleName ? ' ' + bUser.middleName + ' ' : ' ') + bUser.lastName;
                return (aName).localeCompare(bName);
            }

            return 0
        });
        return filteredOrganizationApplications;
    },
    getOrganizationApplication: (state: OrganizationApplicationStore) => (organizationApplicationId: number) => {
        return state.paginatedItems.items[organizationApplicationId];
    },
    ...PaginatedItemsStoreGetters(),
}

const mutations = {
    addOrganizationApplication(state: OrganizationApplicationStore, organizationApplication: OrganizationApplication) {
        state.paginatedItems.items[organizationApplication.id] = organizationApplication;
    },
    editOrganizationApplication(state: OrganizationApplicationStore, organizationApplication: OrganizationApplication) {
        state.paginatedItems.items[organizationApplication.id] = organizationApplication;   
    },
    removeOrganizationApplication(state: OrganizationApplicationStore, organizationApplicationId: number) {
        if (Object.prototype.hasOwnProperty.call(state.paginatedItems.items, organizationApplicationId)) {
            delete state.paginatedItems.items[organizationApplicationId];
        }
    },
    ...PaginatedItemsStoreMutations(),
}

const actions = {
    addOrganizationApplication({ commit }: ActionContext<OrganizationApplicationStore, IRootState>, organizationApplication: OrganizationApplication) {
        commit('addOrganizationApplication', organizationApplication);
    },
    createOrganizationApplication({ dispatch }: ActionContext<OrganizationApplicationStore, IRootState>, organizationApplication: OrganizationApplication) {
        return authModule.post(`/api/organization/${organizationApplication.organizationId}/application`, organizationApplication)
            .then((response) => {
                const organizationApplication: OrganizationApplication = response.data as unknown as OrganizationApplication;
                dispatch('addOrganizationApplication', organizationApplication);
                return organizationApplication;
            });
    },
    editOrganizationApplication({ commit }: ActionContext<OrganizationApplicationStore, IRootState>, organizationApplication: OrganizationApplication) {
        return authModule.patch(`/api/organization/${organizationApplication.organizationId}/application/${organizationApplication.id}`, organizationApplication)
            .then((response) => {
                const organizationApplicationEdited: OrganizationApplication = response.data as unknown as OrganizationApplication;
                commit('editOrganizationApplication', organizationApplication);
                return organizationApplication;
            });
    },
    deleteOrganizationApplication({ commit }: ActionContext<OrganizationApplicationStore, IRootState>, organizationApplication: OrganizationApplication) {
        return authModule.delete(`/api/organization/${organizationApplication.organizationId}/application/${organizationApplication.id}`)
            .then((response) => {
                const deletedOrganizationApplicationId: number = response.data as unknown as number;
                commit('removeOrganizationApplication', deletedOrganizationApplicationId);
            });
    },
    fetchOrganizationApplications({ commit, dispatch, getters }: ActionContext<OrganizationApplicationStore, IRootState>, organizationId: number ) {
        const { pageToRequest, pageSize, canMakeNextPageCall }  = prepForPagedCall(getters)
        if(canMakeNextPageCall) {
            return authModule.get(`/api/organization/${organizationId}/application`, {
                params: {
                    page: pageToRequest,
                    pageSize: pageSize,
                },
            })
            .then((response) => {
                const pagedResponse: PagedResponse<OrganizationApplication> = response.data as unknown as PagedResponse<OrganizationApplication>;
                commitPagedResponseDetails(commit, pagedResponse)
                for (const containerIndex in pagedResponse.items) {
                    dispatch('addOrganizationApplication', pagedResponse.items[containerIndex]);
                }
            })
            .catch((error) => {
                console.error(error);
            });
        }
    },
    fetchUserOrganizationApplications({ commit, dispatch, getters }: ActionContext<OrganizationApplicationStore, IRootState>) {
        const { pageToRequest, pageSize, canMakeNextPageCall }  = prepForPagedCall(getters)
        if(canMakeNextPageCall) {
            return authModule.get(`/api/user/organization/application`, {
                params: {
                    page: pageToRequest,
                    pageSize: pageSize,
                },
            })
            .then((response) => {
                const pagedResponse: PagedResponse<OrganizationApplication> = response.data as unknown as PagedResponse<OrganizationApplication>;
                commitPagedResponseDetails(commit, pagedResponse)
                for (const containerIndex in pagedResponse.items) {
                    dispatch('addOrganizationApplication', pagedResponse.items[containerIndex]);
                }
            })
            .catch((error) => {
                console.error(error);
            });
        }
    },
    getOrganizationApplication({ commit }: ActionContext<OrganizationApplicationStore, IRootState>, organizationApplication: OrganizationApplication) {
        return authModule
            .get(`/api/organization/${organizationApplication.organization?.id}/application/${organizationApplication.id}`)
            .then((response) => {
                const organizationApplication: OrganizationApplication = response.data as unknown as OrganizationApplication;
                commit('addOrganizationApplication', organizationApplication);
            });
    },
    acceptUserApplication({ commit }: ActionContext<OrganizationApplicationStore, IRootState>, organizationApplication: OrganizationApplication) {
        return authModule.post(`/api/organization/application/${organizationApplication.id}/accept`)
            .then((response) => {
                const organizationApplicationEdited: OrganizationApplication = response.data as unknown as OrganizationApplication;
                commit('editOrganizationApplication', organizationApplicationEdited);
                return organizationApplicationEdited;
            });
    },
}

const organizationApplicationStore: Module<OrganizationApplicationStore, IRootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
export default organizationApplicationStore;