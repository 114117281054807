import GenericDictionary from '@/components/util/GenericDictionary';
import { PointOfInterest } from '@/models/main/PointOfInterest';
import { ActionContext, Module } from 'vuex';
import { IRootState } from '@/store';
import authModule from '@/main';
export interface PointOfInterestStore {
    pointOfInterests: GenericDictionary<PointOfInterest>,
};
const state: PointOfInterestStore = {
    pointOfInterests: {},
};
const mutations = {
    addPointOfInterest(state: PointOfInterestStore, pointOfInterest: PointOfInterest) {
        if (pointOfInterest.id !== undefined) {
            state.pointOfInterests[pointOfInterest.id] = pointOfInterest;
        }
    },
    removePointOfInterest(state: PointOfInterestStore, pointOfInterestId: number) {
        if (Object.prototype.hasOwnProperty.call(state.pointOfInterests, pointOfInterestId)) {
            delete state.pointOfInterests[pointOfInterestId];
        }
    },
};
const actions = {
    getPointOfInterest({ commit }: ActionContext<PointOfInterestStore, IRootState>, { livestreamId, pointOfInterestId }: { livestreamId: number, pointOfInterestId: number }) {
        return authModule
            .get(`/api/livestream/${livestreamId}/interest/${pointOfInterestId}`)
            .then((response) => {
                const pointOfInterest: PointOfInterest = response.data as unknown as PointOfInterest;
                commit('addPointOfInterest', pointOfInterest);
            });
    },
    listPointOfInterests({ commit }: ActionContext<PointOfInterestStore, IRootState>, livestreamId: number) {
        return authModule
            .get(`/api/livestream/${livestreamId}/interest`)
            .then((response) => {
                const pointOfInterests: PointOfInterest[] = response.data as unknown as PointOfInterest[];
                for (let i = 0; i < pointOfInterests.length; i++) {
                    commit('addPointOfInterest', pointOfInterests[i]);
                }
            });
    },
    savePointOfInterest({ commit }: ActionContext<PointOfInterestStore, IRootState>, pointOfInterest: PointOfInterest) {
        return authModule
            .post(`/api/livestream/${pointOfInterest.livestreamId}/interest`, pointOfInterest)
            .then((response) => {
                const createdPointOfInterest: PointOfInterest = response.data as unknown as PointOfInterest;
                commit('addPointOfInterest', createdPointOfInterest);
            });
    },
    editPointOfInterest({ commit }: ActionContext<PointOfInterestStore, IRootState>, pointOfInterest: PointOfInterest) {
        return authModule
            .patch(`/api/livestream/${pointOfInterest.livestreamId}/interest/${pointOfInterest.id}`, pointOfInterest)
            .then((response) => {
                const editedPointOfInterest: PointOfInterest = response.data as unknown as PointOfInterest;
                commit('addPointOfInterest', editedPointOfInterest);
            });
    },
    deletePointOfInterest({ commit }: ActionContext<PointOfInterestStore, IRootState>, { livestreamId, pointOfInterestId }: { livestreamId: number, pointOfInterestId: number }) {
        return authModule
            .delete(`/api/livestream/${livestreamId}/interest/${pointOfInterestId}`)
            .then((response) => {
                const deletePointOfInterestId: number = response.data as number;
                commit('removePointOfInterest', deletePointOfInterestId);
            });
    },
};
const pointOfInterestStore: Module<PointOfInterestStore, IRootState> = {
    namespaced: true,
    state,
    mutations,
    actions,
};
export default pointOfInterestStore;