<template>
  <TheAdminDashboard title="Tournaments">
    <button
      @click="$refs.tournamentPopup.openModal()"
      class="btn"
      title="Create Tournament"
    >
      Tournament +
    </button>
    <div class="tournaments-container">
        <ContainerItem @click="selectedTournamentId = container.id" v-for="container in listContainers({type: containerType})" :key="container.id"
            :container="container" :isSelected="selectedTournamentId === container.id">
        </ContainerItem>
    </div>
    <Modal ref="tournamentPopup">
      <template v-slot:header>
        <h1>Create Tournament</h1>
      </template>

      <template v-slot:body>
        <div class="match-details">   
            <div class="match-detail">
                <label for="match_title">Title</label>
                <input
                    v-model="newTournament.title"
                    type="text"
                    id="match_title"
                    placeholder="Title"
                    required
                />
            </div>
            <div class="match-detail">
                <label for="match_start">Starts:</label>
                <input
                    v-model="newTournament.startTime"
                    type="text"
                    id="match_start"
                    placeholder="Starts at"
                    required
                />
            </div>
            <div class="match-detail">
                <label for="match_end">Ends:</label>
                <input
                    v-model="newTournament.endTime"
                    type="text"
                    id="match_end"
                    placeholder="Ends at"
                    required
                />
            </div>
        </div>
      </template>

      <template v-slot:footer>
        <div>
          <button class="btn" @click="$refs.tournamentPopup.closeModal(), onFormSubmit()">
            Save
          </button>
          <button class="btn" @click="$refs.tournamentPopup.closeModal()">Cancel</button>
        </div>
      </template>
    </Modal>
  </TheAdminDashboard>
</template>
<script lang="ts">
import { defineComponent, Ref, ref } from "vue";
import { createNamespacedHelpers, useStore } from "vuex";
import { key } from "@/store";
import Modal from "@/components/main/Modal.vue";
import ContainerItem from "@/components/main/ContainerItem.vue";
import TheAdminDashboard from "@/components/main/TheAdminDashboard.vue";
import { useRouter } from "vue-router";

import GenericDictionary from "@/components/util/GenericDictionary";
import { Player } from "@/models/main/Player";
import { Container, ContainerType } from "@/models/main/Container";
import moment from "moment";

const entityStoreHelpers = createNamespacedHelpers('entityStore')
const containerStoreHelpers = createNamespacedHelpers('containerStore')
const playerStoreHelpers = createNamespacedHelpers('playerStore')

export default defineComponent({
    components: {
        TheAdminDashboard,
        Modal,
        ContainerItem,
    },
    computed: {
        ...entityStoreHelpers.mapGetters(
            ['getEntities']
        ),
        ...containerStoreHelpers.mapGetters(
            ['listContainers']
        ),
        ...playerStoreHelpers.mapGetters(
            ['getPlayers']
        ),
        getEntityPlayers():  GenericDictionary<Player> {
            console.log(this.getEntities)
            if(this.getEntities && Object.prototype.hasOwnProperty.call(this.getEntities, this.currentEntityId)) {
                return this.getEntities[this.currentEntityId].players
            } else {
                console.log(this.getEntities)
                return {}
            }
        }
    },
    setup() {
        const router = useRouter();
        const store = useStore(key);
        const onFormSubmit = async () => {
            const createdTournament = await store
                .dispatch("containerStore/createContainer", 
                { 
                    container: newTournament.value, 
                })
            console.log(createdTournament)
        };
        
        const loadContainers = async() => {
            await store.dispatch("containerStore/fetchContainers", { status: null})
        }

        loadContainers()
        const dateTimeFormat = 'YYYY-MM-DD HH:mm'
        
        const myTournament: Container = {
            type: "Tournament",
            status: "NotStarted",
            startTime: moment().format(dateTimeFormat),
            endTime: moment().format(dateTimeFormat),
            sport: "Volleyball",
            events: {},
            livestreams: {},
            entities: {},
            seasons: []
        }

        const newTournament: any = ref(myTournament);
        const currentEntityId: Ref<number> = ref(0)
        const selectedTournamentId: Ref<number> = ref(0)
        const containerType: ContainerType = "Tournament"
        return {
            store,
            router,
            newTournament,
            currentEntityId,
            selectedTournamentId,
            containerType,
            onFormSubmit,
        };
    },
});
</script>

<style>

.tournaments-container {
  margin-top: 1em;
}

</style>
