import DataModelMapper from "@/components/util/DataModelMapper";
import GenericDictionary from "@/components/util/GenericDictionary";
import DataModel from "@/models/main/DataModel";
import PhasePlayerDataModel from "./PhasePlayerDataModel";

export default class PhaseSerieDataModel extends DataModel {
    public static readonly MODEL_NAME: string = "PhaseSerie"

    private _id?: number;
    private _squadID?: number;
    private _squadName?: string;
    private _scratchSerieScore?: number;
    private _clearScratchScore?: number;
    private _serieHandicap?: number;
    private _sequence?: number;
    private _maxGamesPerSerie?: number;
    private _positionPoints?: number;
    private _scorePoints?: number;
    private _points?: number;
    private _players?: GenericDictionary<PhasePlayerDataModel>;

    // #region Fields Keys
    static get IDKey() {
        return "id";
    }

    static get squadIDKey() {
        return "squad_id";
    }

    static get squadNameKey() {
        return "squad_name";
    }

    static get scratchSerieScoreKey() {
        return "scrach_serie_score";
    }

    static get clearScratchScoreKey() {
        return "clear_scrach_score";
    }

    static get serieHandicapKey() {
        return "serie_handycap";
    }

    static get playersKey() {
        return "players";
    }

    static get sequenceKey() {
        return "serie_sequence";
    }

    static get maxGamesPerSerieKey() {
        return "max_games_per_series";
    }

    static get positionPointsKey() {
        return "possition_points";
    }

    static get scorePointsKey() {
        return "score_points";
    }

    static get pointsKey() {
        return "serie_points";
    }
    // #endregion

    // #region accessors/mutations 
    get ID() {
        return this._id;
    }

    set ID(ID) {
        this._id = ID;
    }

    get squadID() {
        return this._squadID;
    }

    set squadID(squadID) {
        this._squadID = squadID;
    }

    get squadName() {
        return this._squadName;
    }

    set squadName(squadName) {
        this._squadName = squadName;
    }

    get scratchSerieScore() {
        return this._scratchSerieScore;
    }

    set scratchSerieScore(scratchSerieScore) {
        this._scratchSerieScore = scratchSerieScore;
    }

    get clearScratchScore() {
        return this._clearScratchScore;
    }

    set clearScratchScore(clearScratchScore) {
        this._clearScratchScore = clearScratchScore;
    }

    get serieHandicap() {
        return this._serieHandicap;
    }

    set serieHandicap(serieHandicap) {
        this._serieHandicap = serieHandicap;
    }

    get sequence() {
        return this._sequence;
    }

    set sequence(sequence) {
        this._sequence = sequence;
    }

    get maxGamesPerSerie() {
        return this._maxGamesPerSerie;
    }

    set maxGamesPerSerie(maxGamesPerSerie) {
        this._maxGamesPerSerie = maxGamesPerSerie;
    }

    get positionPoints() {
        return this._positionPoints;
    }

    set positionPoints(positionPoints) {
        this._positionPoints = positionPoints;
    }

    get scorePoints() {
        return this._scorePoints;
    }

    set scorePoints(scorePoints) {
        this._scorePoints = scorePoints;
    }

    get points() {
        return this._points;
    }

    set points(points) {
        this._points = points;
    }

    /**
     *
     * @returns {[PhasePlayer]}
     */
    get players() {
        return this._players;
    }

    set players(players) {
        this._players = players;
    }
    //#endregion


    constructor(modelID?: string, modelData?: any) {
        super();
        this.players = {};
        this.parseMinifiedData(modelData);
        if(this.ID !== undefined) {
            const modelId = DataModel.generateModelID(this, {"id": this.ID});
            if(modelId !== false) {
                this.modelID = modelId
            }
        } else if(modelID !== undefined){
            this.modelID = modelID;
        }
    }

    // #region FieldMappings Getters/Setters
    dataModelName() {
        return PhaseSerieDataModel.MODEL_NAME
    }

    getKeyByMinifiedKey(minKey: string) {
        switch (minKey) {
            case "0":
                return PhaseSerieDataModel.IDKey;
            case "1":
                return PhaseSerieDataModel.squadIDKey;
            case "2":
                return PhaseSerieDataModel.squadNameKey;
            case "3":
                return PhaseSerieDataModel.scratchSerieScoreKey;
            // case "4":
            //     return PhaseSerie.clearScratchScoreKey;
            case "4":
                return PhaseSerieDataModel.serieHandicapKey;
            case "5":
                return PhaseSerieDataModel.sequenceKey;
            case "6":
                return PhaseSerieDataModel.maxGamesPerSerieKey;
            case "7":
                return PhaseSerieDataModel.positionPointsKey;
            case "8":
                return PhaseSerieDataModel.scorePointsKey;
            case "9":
                return PhaseSerieDataModel.pointsKey;
            case "10":
                return PhaseSerieDataModel.playersKey;
            default:
                return ""
        }
    }

    setProperty(propertyKey: string, value: any) {
        let playersData;
        switch (propertyKey) {
            case PhaseSerieDataModel.IDKey:
                this.ID = value;
                break;
            case PhaseSerieDataModel.squadIDKey:
                this.squadID = parseInt(value);
                break;
            case PhaseSerieDataModel.squadNameKey:
                this.squadName = value;
                break;
            case PhaseSerieDataModel.scratchSerieScoreKey:
                this.scratchSerieScore = value;
                break;
            case PhaseSerieDataModel.clearScratchScoreKey:
                this.clearScratchScore = value;
                break;
            case PhaseSerieDataModel.serieHandicapKey:
                this.serieHandicap = value;
                break;
            case PhaseSerieDataModel.sequenceKey:
                this.sequence = parseInt(value);
                break;
            case PhaseSerieDataModel.maxGamesPerSerieKey:
                this.maxGamesPerSerie = value;
                break;
            case PhaseSerieDataModel.positionPointsKey:
                this.positionPoints = value;
                break;
            case PhaseSerieDataModel.scorePointsKey:
                this.scorePoints = value;
                break;
            case PhaseSerieDataModel.pointsKey:
                this.points = value;
                break;
            case PhaseSerieDataModel.playersKey:
                playersData = value;
                for(const playerId in playersData) {
                    if(Object.prototype.hasOwnProperty.call(playersData, playerId)) {
                        if(playersData[playerId] !== null) {
                            let phasePlayerModel = null;
                            if(playersData[playerId] instanceof DataModel) {
                                phasePlayerModel = playersData[playerId];
                            } else {
                                phasePlayerModel = DataModelMapper.createFromObject(playersData[playerId]);
                            }
                            if(this.players === undefined) {
                                this.players = {}
                            }
                            if(phasePlayerModel instanceof PhasePlayerDataModel) {
                                this.players[playerId] = phasePlayerModel;
                            }
                        }
                    }
                }
                break;
        }
    }

    getProperty(propertyKey: string) {
        switch (propertyKey) {
            case PhaseSerieDataModel.IDKey:
                return this.ID;
            case PhaseSerieDataModel.squadIDKey:
                return this.squadID;
            case PhaseSerieDataModel.squadNameKey:
                return this.squadName;
            case PhaseSerieDataModel.scratchSerieScoreKey:
                return this.scratchSerieScore;
            case PhaseSerieDataModel.clearScratchScoreKey:
                return this.clearScratchScore;
            case PhaseSerieDataModel.serieHandicapKey:
                return this.serieHandicap;
            case PhaseSerieDataModel.sequenceKey:
                return this.sequence;
            case PhaseSerieDataModel.maxGamesPerSerieKey:
                return this.maxGamesPerSerie;
            case PhaseSerieDataModel.positionPointsKey:
                return this.positionPoints;
            case PhaseSerieDataModel.scorePointsKey:
                return this.scorePoints;
            case PhaseSerieDataModel.pointsKey:
                return this.points;
            case PhaseSerieDataModel.playersKey:
                return this.players;
        }
    }

    //#endregion
}