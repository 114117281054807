<template>
    <div class="organization-wrapper">
        <router-link :to="{ name: 'Organization Menu', params: { id: organization.id }}">
            <div class="organization-badge">
                <div class="organization-image-wrapper">
                    <img decoding="async" @error="setDefaultOrganizationLogo" :src="getOrganizationLogoSrc(organization)" :alt="organization.name" width="136">
                </div>
                <div class="organization-info-wrapper">
                    <div class="organization-name">{{organization.name}}</div>
                </div>
            </div>
        </router-link>
    </div>
</template>
<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { Organization } from '@/models/main/Organization';
import { setDefaultOrganizationLogo, getOrganizationLogoSrc } from '@/composables/common/Util';

 const OrganizationBadgeItem = defineComponent({
    props: {
        organization: {
            type: Object as PropType<Organization>,
            required: true
        }
    },
    setup() {
        return { setDefaultOrganizationLogo, getOrganizationLogoSrc }
    }
 })

 export default OrganizationBadgeItem;
</script>
<style>
.organization-wrapper {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.organization-badge {
  width: var(--organization-badge-width, 5em);
  height: var(--organization-badge-height, 5em);
  position: relative;
  transition: ease-in-out all 200ms;
  border-radius: var(--default-border-rad);
  overflow: clip;
}

.organization-badge:hover {
  transform: scale(1.1);
}



.organization-badge .organization-image-wrapper {
    width: 100%;
    height: 100%;
    background-color: var(--bright-font-clr);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.75em;
}

.organization-image-wrapper img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    border-radius: var(--default-border-rad);
}

.organization-info-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.organization-badge:hover .organization-name {
  display: flex;
}

.organization-name {
    font-size: 1.25em;
    font-weight: 500;
    color: var(--main-font-clr);
    background-color: rgba(255, 255, 255, 0.9);
    text-align: center;
    width: 100%;
    display: none;
    height: 100%;
    justify-content: center;
    align-items: center;
}
</style>