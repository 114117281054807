<template>
    <label for="id" v-if="label">{{ label }}</label>
    <div class="spz-select">
        <select :id="id" class="spz-select-native" v-model="localSelectedOption" @change="onSelectOptionChange">
            <template v-if="options && options.length > 0">
                <option v-for="(option, index) in options" :value="option" :key="index">{{ option }}</option>
            </template>
            <template v-else-if="optionsMap && optionsMap.size > 0">
                <option v-for="optionMapKeyValue in optionsMap" :value="optionMapKeyValue[0]" :key="optionMapKeyValue[0]">{{ optionMapKeyValue[1] }}</option>
            </template>
        </select>
    </div>
</template>
<script setup lang="ts">
import { computed } from '@vue/reactivity';
const emit = defineEmits([
    'update:selectedOption',
    'selectOptionChange',
    ])
        
const props = defineProps<{
    options?: Array<string>,
    optionsMap?: Map<string, string>,
    selectedOption?: string,
    id?: string,
    label?: string
}>()

const localSelectedOption = computed({
    get(): string {
        if(props.selectedOption) {
            return props.selectedOption
        } else if(props.options) {
            return props.options[0];
        }

        return ""
    },
    set(value: string) {
        emit('update:selectedOption', value);
    },
});

const onSelectOptionChange = () => {
    emit('selectOptionChange', localSelectedOption.value);
};
</script>
<style>
:root {
    --select-border: #707070;
    --select-focus: var(--main-font-clr);
    --select-arrow: var(--select-border);
    --select-background-color: #d7d9dc;
    --select-font-color: var(--main-font-clr);
}

.spz-select {
    position: relative;
    min-width: 10ch;
    /* border: 1px solid var(--select-border); */
    cursor: pointer;
    background-color: var(--bright-font-clr);
    color: var(--select-font-color);
    border-radius: var(--default-border-rad);
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.spz-select option {
    cursor: inherit;
    background-color: white;
}

.spz-select::after {
    content: "";
    position: absolute;
    width: 0.8em;
    height: 0.5em;
    background-color: var(--main-font-clr);
    -webkit-clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    right: 0.8em;
    top: calc((100% - 0.5em) / 2);
}

select.spz-select-native {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    background: transparent;
    border: none;
    padding: 0.5em 0.65em;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    width: 100%;
    margin: 0;
    padding-right: 1.75em;
    font-weight: inherit;
    color: var(--main-font-clr);
    background-color: inherit;
    border-radius: var(--default-border-rad);
}
</style>