import DataModelMapper from "@/components/util/DataModelMapper";
import GenericDictionary from "@/components/util/GenericDictionary";
import { DataModelStore } from "@/composables/dataModels/DataModelStore";

export interface IDataModel {
    setProperty(propertyKey: string, value: any): void

    getProperty(propertyKey: string): any

    getKeyByMinifiedKey(minKey: string): string
}

export default abstract class DataModel implements IDataModel {
    private _modelID = '';
    private _modelData: any;

    static get _modelClassParamsDelimiter() {
        return '_';
    }

    static get _paramKeyValueDelimiter() {
        return '_';
    }

    set modelID(modelID) {
        this._modelID = modelID;
    }

    get modelID(): string {
        return this._modelID;
    }

    set modelData(modelData: any) {
        this._modelData = modelData;
    }

    get modelData() {
        return this._modelData;
    }

    parseMinifiedData(minifiedData: any) {
        if (minifiedData) {
            for (const minKey in minifiedData) {
                if (Object.prototype.hasOwnProperty.call(minifiedData, minKey)) {
                    const fullKey = this.getKeyByMinifiedKey(minKey);
                    const value = minifiedData[minKey];
                    this.setProperty(fullKey, value);
                }
            }
        }
    }

    parseData(data: any) {
        if (data) {
            for (const propertyKey in data) {
                if (Object.prototype.hasOwnProperty.call(data, propertyKey)) {
                    const propertyValue = data[propertyKey];
                    this.setProperty(propertyKey, propertyValue);
                }
            }
        }
    }

    static generateModelID(modelClass: DataModel, params: GenericDictionary<number>) {
        const classIdentifier = modelClass.dataModelName();
        let paramsIdentifier = '';
        for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                const value = params[key];
                if (key.toString().indexOf(DataModel._modelClassParamsDelimiter) >= 0) { //delimiter found
                    console.error("And parameter key contains forbidden symbol: " + DataModel._modelClassParamsDelimiter);
                    return false;
                }
                if (key.toString().indexOf(DataModel._paramKeyValueDelimiter) >= 0) { //delimiter found
                    console.error("And parameter key contains forbidden symbol: " + DataModel._paramKeyValueDelimiter);
                    return false;
                }

                if (value.toString().indexOf(DataModel._modelClassParamsDelimiter) >= 0) { //delimiter found
                    console.error("And parameter value contains forbidden symbol: " + DataModel._modelClassParamsDelimiter);
                    return false;
                }
                if (value.toString().indexOf(DataModel._paramKeyValueDelimiter) >= 0) { //delimiter found
                    console.error("And parameter value contains forbidden symbol: " + DataModel._paramKeyValueDelimiter);
                    return false;
                }

                paramsIdentifier += value + DataModel._paramKeyValueDelimiter;
            }
        }

        let modelID = classIdentifier;
        if (paramsIdentifier !== '') {
            paramsIdentifier = paramsIdentifier.substring(0, paramsIdentifier.length - DataModel._paramKeyValueDelimiter.length); // remove last modelClassParamsDelimiter
            modelID += DataModel._modelClassParamsDelimiter + paramsIdentifier;
        }

        return modelID;
    }

    // static mapDataModel(modelID: string, modelData: any): DataModel | any {
    //     const identifiersRegex = new RegExp('^([a-zA-Z]+(?=' + DataModel._modelClassParamsDelimiter + ')|[a-zA-Z]+$)', "g");
    //     const identifierMatches = identifiersRegex.exec(modelID);
    //     if (!identifierMatches) {
    //         console.error("Invalid Model ID received!");
    //         return false;
    //     } else if (identifierMatches.length < 2) {
    //         console.error("Invalid Model ID received!");
    //         return false;
    //     }

    //     const className = identifierMatches[1];
    //     try {
    //         return new DataModelStore[className](modelID, modelData);
    //     } catch (exception: any) {
    //         console.error("Could not map data model of " + className + "!");
    //         console.error(exception.message);
    //         return false;
    //     }
        
    // }

    abstract dataModelName(): string 

    abstract setProperty(propertyKey: string, value: any): void

    abstract getProperty(propertyKey: string): any

    abstract getKeyByMinifiedKey(minKey: string): string

}