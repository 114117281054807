import GenericDictionary from "@/components/util/GenericDictionary"
export const BADMINTON_SMASH = 'Bdm_Smash'
export const BADMINTON_COEAR = 'Bdm_C'
export const BADMINTON_DROP = 'Bdm_Drop'
export const BADMINTON_SHORT = 'Bdm_Short'
export const BADMINTON_SMASH_NET = 'Bdm_Smash_N'
export const BADMINTON_DRIVE = 'Bdm_D'
export const BADMINTON_OTHER = "Bdm_O"
export const BADMINTON_FAULT = 'Bdm_F'
export const BADMINTON_SRV_FAULT = 'Bdm_Srv_F'



export type BadmintonPointType =         
typeof BADMINTON_SMASH | typeof BADMINTON_COEAR | typeof BADMINTON_DROP | typeof BADMINTON_SHORT | 
typeof BADMINTON_SMASH_NET | typeof BADMINTON_DRIVE |
typeof BADMINTON_OTHER | typeof BADMINTON_FAULT | typeof BADMINTON_SRV_FAULT

export const BADMINTON_POINT_TITLE_PAIRS: GenericDictionary<string> =  {}
BADMINTON_POINT_TITLE_PAIRS[BADMINTON_SMASH] = 'Smash'
BADMINTON_POINT_TITLE_PAIRS[BADMINTON_COEAR] = 'Coear'
BADMINTON_POINT_TITLE_PAIRS[BADMINTON_DROP] = 'Drop'
BADMINTON_POINT_TITLE_PAIRS[BADMINTON_SHORT] = 'Short'
BADMINTON_POINT_TITLE_PAIRS[BADMINTON_SMASH_NET] = 'Smash From Net'
BADMINTON_POINT_TITLE_PAIRS[BADMINTON_DRIVE] = 'Drive'
BADMINTON_POINT_TITLE_PAIRS[BADMINTON_OTHER] = 'Other'
BADMINTON_POINT_TITLE_PAIRS[BADMINTON_FAULT] = 'Fault'
BADMINTON_POINT_TITLE_PAIRS[BADMINTON_SRV_FAULT] = 'Service Fault'

export const BADMINTON_POINT_BEAUTIFUL_TITLE_PAIRS: GenericDictionary<string> =  {}
BADMINTON_POINT_BEAUTIFUL_TITLE_PAIRS[BADMINTON_SMASH] = 'Smash'
BADMINTON_POINT_BEAUTIFUL_TITLE_PAIRS[BADMINTON_COEAR] = 'Coear'
BADMINTON_POINT_BEAUTIFUL_TITLE_PAIRS[BADMINTON_DROP] = 'Drop'
BADMINTON_POINT_BEAUTIFUL_TITLE_PAIRS[BADMINTON_SHORT] = 'Short'
BADMINTON_POINT_BEAUTIFUL_TITLE_PAIRS[BADMINTON_SMASH_NET] = 'Smash From Net'
BADMINTON_POINT_BEAUTIFUL_TITLE_PAIRS[BADMINTON_DRIVE] = 'Drive'
BADMINTON_POINT_BEAUTIFUL_TITLE_PAIRS[BADMINTON_OTHER] = 'Other'
BADMINTON_POINT_BEAUTIFUL_TITLE_PAIRS[BADMINTON_FAULT] = 'Fault'
BADMINTON_POINT_BEAUTIFUL_TITLE_PAIRS[BADMINTON_SRV_FAULT] = 'Service Fault'


export const BADMINTON_IS_POSITIVE_POINT: GenericDictionary<boolean> = {}
BADMINTON_IS_POSITIVE_POINT[BADMINTON_SMASH] = true
BADMINTON_IS_POSITIVE_POINT[BADMINTON_COEAR] = true
BADMINTON_IS_POSITIVE_POINT[BADMINTON_DROP] = true
BADMINTON_IS_POSITIVE_POINT[BADMINTON_SHORT] = true
BADMINTON_IS_POSITIVE_POINT[BADMINTON_SMASH_NET] = true
BADMINTON_IS_POSITIVE_POINT[BADMINTON_DRIVE] = true
BADMINTON_IS_POSITIVE_POINT[BADMINTON_OTHER] = true
BADMINTON_IS_POSITIVE_POINT[BADMINTON_FAULT] = false
BADMINTON_IS_POSITIVE_POINT[BADMINTON_SRV_FAULT] = false

const gameEnd = 'Bdm_G'
const gameEndLegacy = 'TblTenn_G'
const setEnd = 'Bdm_S'
const matchEnd = 'Bdm_M'
const serveChange = 'Bdm_Srv'
const setGameAsTiebreak = 'Bdm_TG'
const override = 'Bdm_O'

export const BADMINTON_GAME_CONTROL_TYPES: GenericDictionary<string> = {}

BADMINTON_GAME_CONTROL_TYPES[setEnd] = 'End of Set'
BADMINTON_GAME_CONTROL_TYPES[gameEnd] = 'End of Game'
BADMINTON_GAME_CONTROL_TYPES[matchEnd] = 'End of Match'
BADMINTON_GAME_CONTROL_TYPES[serveChange] = 'Change Serve'
BADMINTON_GAME_CONTROL_TYPES[setGameAsTiebreak] = 'Tiebreak Game'
BADMINTON_GAME_CONTROL_TYPES[override] = 'Override'



export type BadmintonGameControlType = typeof gameEnd | typeof setEnd | typeof matchEnd | typeof serveChange | typeof setGameAsTiebreak
        | typeof override | typeof gameEndLegacy
