import DataModelMapper from "@/components/util/DataModelMapper";
import DataModel from "@/models/main/DataModel";
import FrameDataModel from "./FrameDataModel";

export default class PhaseGameDataModel extends DataModel {
    public static readonly MODEL_NAME: string = "PhaseGame"

    private _id?: number;
    private _score?: number;
    private _clearScore?: number;
    private _lane?: string;
    private _sequence?: number;
    private _isPredicted?: boolean;
    private _currentFrame?: number;
    private _frames?: any;
    private _predictedFrames?: any;

    
    // #region Fields Keys
    static get IDKey() {
        return "game_id";
    }

    static get scoreKey() {
        return "game_score";
    }

    static get clearScoreKey() {
        return "clear_game_score";
    }

    static get laneKey() {
        return "game_lane";
    }

    static get sequenceKey() {
        return "game_sequence";
    }

    static get isPredictedKey() {
        return "predicted";
    }

    static get currentFrameKey() {
        return "current_frame";
    }

    static get framesKey() {
        return "game_details";
    }

    static get predictedFramesKey() {
        return "predicted_frames";
    }

    get ID() {
        return this._id;
    }

    set ID(ID) {
        this._id = ID;
    }

    get score(): number {
        return this._score ?? 0;
    }

    set score(score: number){
        this._score = score;
    }

    get clearScore() {
        return this._clearScore;
    }

    set clearScore(clearScore) {
        this._clearScore = clearScore;
    }

    get lane() {
        return this._lane;
    }

    set lane(lane) {
        this._lane = lane;
    }

    get sequence() {
        return this._sequence;
    }

    set sequence(sequence) {
        this._sequence = sequence;
    }

    get isPredicted(): boolean {
        return this._isPredicted ?? false;
    }

    set isPredicted(isPredicted: boolean) {
        this._isPredicted = isPredicted;
    }

    get currentFrame() {
        return this._currentFrame;
    }

    set currentFrame(currentFrame) {
        this._currentFrame = currentFrame;
    }

    get frames() {
        return this._frames;
    }

    set frames(frames) {
        this._frames = frames;
    }

    get predictedFrames() {
        return this._predictedFrames;
    }

    set predictedFrames(predictedFrames) {
        this._predictedFrames = predictedFrames;
    }
    // #endregion


    constructor(modelID?: string, modelData?: any) {
        super();
        this.frames = [];
        this.parseMinifiedData(modelData);
        if(this.ID !== undefined) {
            const modelId = DataModel.generateModelID(this, {"id": this.ID});
            if(modelId !== false) {
                this.modelID = modelId
            }
        } else if(modelID !== undefined){
            this.modelID = modelID;
        }
    }

    
    // #region FieldMappings Getters/Setters
    dataModelName() {
        return PhaseGameDataModel.MODEL_NAME
    }

    getKeyByMinifiedKey(minKey: string) {
        switch (minKey) {
            case "0":
                return PhaseGameDataModel.IDKey;
            case "1":
                return PhaseGameDataModel.scoreKey;
            // case "2":
            //     return PhaseGame.clearScoreKey;
            case "2":
                return PhaseGameDataModel.laneKey;
            case "3":
                return PhaseGameDataModel.sequenceKey;
            case "4":
                return PhaseGameDataModel.framesKey;
            case "5":
                return PhaseGameDataModel.isPredictedKey;
            case "6":
                return PhaseGameDataModel.currentFrameKey;
            case "7":
                return PhaseGameDataModel.predictedFramesKey;
            default: 
                return ""
        }
    }

    setProperty(propertyKey: string, value: any) {
        let frames
        switch (propertyKey) {
            case PhaseGameDataModel.IDKey:
                this.ID = parseInt(value);
                break;
            case PhaseGameDataModel.scoreKey:
                this.score = value;
                break;
            case PhaseGameDataModel.clearScoreKey:
                this.clearScore = value;
                break;
            case PhaseGameDataModel.laneKey:
                this.lane = value;
                break;
            case PhaseGameDataModel.sequenceKey:
                this.sequence = parseInt(value);
                break;
            case PhaseGameDataModel.isPredictedKey:
                this.isPredicted = value;
                break;
            case PhaseGameDataModel.currentFrameKey:
                this.currentFrame = value;
                break;
            case PhaseGameDataModel.framesKey:
                frames = value;
                if(frames) {
                    for(const frameIndex in frames) {
                        if(Object.prototype.hasOwnProperty.call(frames, frameIndex)) {
                            let frameModel = null;
                            if(frames[frameIndex] instanceof DataModel) {
                                frameModel = frames[frameIndex];
                            } else if(frames[frameIndex] !== null){
                                frameModel = DataModelMapper.createFromObject(frames[frameIndex]);
                            }

                            if(frameModel instanceof FrameDataModel && frameModel.frameNumber !== undefined) {
                                this.frames[frameModel.frameNumber - 1] = frameModel;
                            }
                        }
                    }
                }
                break;
            case PhaseGameDataModel.predictedFramesKey:
                this.predictedFrames = value;
                break;
        }
    }

    getProperty(propertyKey: string) {
        switch (propertyKey) {
            case PhaseGameDataModel.IDKey:
                return this.ID;
            case PhaseGameDataModel.scoreKey:
                return this.score;
            case PhaseGameDataModel.clearScoreKey:
                return this.clearScore;
            case PhaseGameDataModel.laneKey:
                return this.lane;
            case PhaseGameDataModel.sequenceKey:
                return this.sequence;
            case PhaseGameDataModel.isPredictedKey:
                return this.isPredicted;
            case PhaseGameDataModel.currentFrameKey:
                return this.currentFrame;
            case PhaseGameDataModel.framesKey:
                return this.frames;
            case PhaseGameDataModel.predictedFramesKey:
                return this.predictedFrames;
        }
    }

    //#endregion
}