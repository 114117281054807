<template>
        <nav class="dashboard-nav">
            <div class="dashboard-container">
                <div class="sportzone-logo">
                    <router-link :to="{ name: 'Organizations' }">
                        <img src="/sportzone_deep_blue_logo.svg" alt="Sportzone Logo" />
                    </router-link>
                </div>
                <div class="menu-section">
                    <div class="menu-row">
                        <div  class="menu" :class="{'active-nav': expandMenu}">
                                <div v-if="false" class=" menu-column">
                                    <router-link :to="{ name: 'Channels'}">
                                        <div class="menu-item-wrapper">
                                            <div class="menu-item">
                                                <div class="menu-item-title">
                                                    Channels
                                                </div>
                                                <div class="menu-item-summary">
                                                    Create channels which you can use to manage your YouTube channels
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                    <router-link :to="{ name: 'Stations'}">
                                        <div class="menu-item-wrapper">
                                            <div class="menu-item">Stations</div>
                                        </div>
                                    </router-link>
                                    <router-link :to="{ name: ''}">
                                        <div class="menu-item-wrapper">
                                            <div class="menu-item">Users</div>
                                        </div>
                                    </router-link>
                                    <router-link :to="{name: 'Team Management'}">
                                        <div class="menu-item-wrapper">
                                            <div class="menu-item">Teams</div>
                                        </div>
                                    </router-link>
                            </div>
                            <div v-if="false" class="menu-column">
                                <router-link :to="{ name: 'Organizations'}" class="menu-item-link">
                                    <div class="menu-item-wrapper">
                                        <div class="menu-item">Organizations</div>
                                    </div>
                                </router-link>
                                <router-link :to="{ name: 'Livestreams'}" class="menu-item-link">
                                    <div class="menu-item-wrapper">
                                        <div class="menu-item">Livestreams</div>
                                    </div>
                                </router-link>
                                <router-link :to="{ name: 'Dashboard'}" class="menu-item-link">
                                    <div class="menu-item-wrapper">
                                        <div class="menu-item">Dashboard</div>
                                    </div>
                                </router-link>
                            </div>
                            <div class="menu-column">
                            <router-link :to="{name: 'Team Management'}" class="menu-item-link">
                                    <div class="menu-item-wrapper">
                                        <div class="menu-item">
                                            <div class="menu-item-title">
                                                Teams
                                            </div>
                                            <div class="menu-item-summary">
                                                Create and manage your teams and players
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                                <router-link :to="{ name: 'Organization Containers', query: { type : 'Match'}}" class="menu-item-link">
                                    <div class="menu-item-wrapper">
                                        <div class="menu-item">
                                            <div class="menu-item-title">
                                                Matches
                                            </div>
                                            <div class="menu-item-summary">
                                                Create and manage your matches
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                                <router-link :to="{ name: 'Livestreams'}" class="menu-item-link">
                                    <div class="menu-item-wrapper">
                                        <div class="menu-item">
                                            <div class="menu-item-title">
                                                Livestreams
                                            </div>
                                            <div class="menu-item-summary">
                                                Create and manage your livestreams
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                            <div class="menu-column">
                                <router-link :to="{ name: 'Livestreams Dashboard'}" class="menu-item-link" >
                                    <div class="menu-item-wrapper">
                                        <div class="menu-item">
                                            <div class="menu-item-title">
                                                Live
                                            </div>
                                            <div class="menu-item-summary">
                                                Check out upcoming and currently active streams
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                                <router-link :to="{ name: 'Livestreams Archive'}" class="menu-item-link">
                                    <div class="menu-item-wrapper">
                                        <div class="menu-item">
                                            <div class="menu-item-title">
                                                Archive
                                            </div>
                                            <div class="menu-item-summary">
                                                Check out past streams
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                            <div class="menu-column">
                                <router-link :to="{ name: 'Organizations'}" class="menu-item-link">
                                    <div class="menu-item-wrapper">
                                        <div class="menu-item">
                                            <div class="menu-item-title">
                                                Organizations
                                            </div>
                                            <div class="menu-item-summary">
                                                Create and manage your organizations
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                                <router-link :to="{ name: 'Channels'}" class="menu-item-link">
                                    <div class="menu-item-wrapper">
                                        <div class="menu-item">
                                            <div class="menu-item-title">
                                                Channels
                                            </div>
                                            <div class="menu-item-summary">
                                                Create channels which you can use to manage your YouTube channels
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                                <router-link :to="{ name: 'Stations'}" class="menu-item-link">
                                    <div class="menu-item-wrapper">
                                        <div class="menu-item">
                                            <div class="menu-item-title">
                                                Stations
                                            </div>
                                            <div class="menu-item-summary">
                                                Create and manage stations to take remote control of your streaming devices
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                            <div class="menu-invis-background" @click="menuToggle"></div>
                        </div>
                        <div class="menu-toggle-wrapper" @click="menuToggle">
                            <div class="menu-toggle">Menu</div>
                        </div>
                        <!-- <div class="menu-links-wrapper">
                            <template v-for="navigationItem in navigationItems" :key="navigationItem.routerLinkName" >
                                <NavigationBarItem v-if="navigationItemIsAllowedToUser(navigationItem.allowedRoles)" :navigationItem="navigationItem" />
                                
                            </template>

                        </div> -->
                        <div class="user-profile-section" v-if="user && user.id !== 0">
                            <input type="radio" id="dropdown-radio-hide" hidden name="org-profile-dropdown-radio"/>
                            <input type="radio" id="org-profile-dropdown-radio" hidden name="org-profile-dropdown-radio"/>
                            <div class="dropdown">
                                <label for="dropdown-radio-hide" class="dropdown-hide-btn"></label>
                                <div class="dropdown-item">
                                    <router-link :to="{ name: 'Profile' }">My Profile</router-link>
                                </div>
                                <div class="dropdown-item">
                                    <router-link :to="{ name: 'Organizations' }">Organize</router-link>
                                </div>
                                <div class="dropdown-item">
                                    <a @click.prevent="logoutUser()">Log Out</a>
                                </div>
                            </div>
                            <label for="org-profile-dropdown-radio">
                                <div class="user-section-icon">
                                    <div class="user-section-icon__image">
                                        <img :src="`/api/user/${user.id}/picture`" :alt="user.firstName" />
                                    </div>
                                </div>
                                <div class="user-section-name">
                                    {{ user.firstName }} {{ user.lastName }}
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
</template>

<script lang="ts">
import authModule from '@/main'
import { SportzoneRole, User } from '@/models/main/User'
import { defineComponent, onBeforeMount, PropType, ref  } from 'vue'
import { useRouter } from 'vue-router'
import NavigationBarItem, { NavigationItem } from './NavigationBarItem.vue'
import { useMsal } from '@/composables/auth/UseMSAL'
import { EndSessionRequest } from '@azure/msal-browser'

// const defaultNavigationItems: NavigationItem[] = [{
//     title: 'Organizations',
//     routerLinkName: 'Organizations',
//     params: null,
//     iconLink: null,
//     allowedRoles: ['Admin', 'Organizer'],
// } as NavigationItem, {
//     title: 'Events',
//     routerLinkName: 'Events',
//     params: null,
//     iconLink: null,
//     allowedRoles: ['Admin', 'Organizer'],
// } as NavigationItem,
// ];

const TheNavigationBar = defineComponent({
    data() {
        return {
            publicPath: process.env.BASE_URL,
            logoutNavItem: { title: "Log out", routerLinkName: "/.auth/logout", params: null, iconLink: null, allowedRoles: ["Admin", "Organizer", "Operator", "Player"]} as NavigationItem,
        }
    },
    props: {
        navigationItems: {
            type: Object as PropType<Array<NavigationItem>>,
            required: true
        },
        user: {
          type: Object as PropType<User>,
          required: true
        }
    },
    components: {
        NavigationBarItem
    },
    setup(props) {
        const defaultProfilePicRelativePath = 'default-profile-pic.svg';
        const { instance } = useMsal();
        const logoutUser = () => {
            let request: EndSessionRequest = {
                postLogoutRedirectUri: "/"
            }
            instance.logoutRedirect(request);
        }

        const preLoadImages = () => {
            const imagesToLoad = [ 
                '/rounded-light-blue-triangle.svg', 
                '/rounded-deep-blue-triangle.svg',
                '/sportzone_deep_blue_logo.svg' ]
            for(let i = 0; i < imagesToLoad.length; i++) {
                const img = new Image();
                img.src = imagesToLoad[i];
            }
        }

        onBeforeMount(preLoadImages);
        const navigationItemIsAllowedToUser = (allowedRoles: SportzoneRole[]) : boolean => {
            if(allowedRoles.length === 0) {
                return true // empty allowedRoles means everybody,
            }
            if(props.user && allowedRoles.some(r => props.user.userRoles.includes(r))) { // user has at least one of the allowedRoles
                return true
            }

            return false
        }

        const expandMenu = ref(false)
        const menuToggle = () => {
            expandMenu.value = !expandMenu.value
        }

        return {
            defaultProfilePicRelativePath,
            expandMenu,
        logoutUser,
        navigationItemIsAllowedToUser,
        menuToggle,
        }
    }
})
export default TheNavigationBar
</script>

<style>
/* EXPERIMENT SECTION */

body::after{
   position:absolute; width:0; height:0; overflow:hidden; z-index:-1; /* hide */
   content: url(/rounded-deep-blue-triangle.svg) url(/rounded-deep-blue-triangle.svg);   /* preload images */
}

.menu {
    position: fixed;
    --menu-item-width: 100%;
    --menu-max-width: calc(var(--max-width) - var(--sportzone-logo-width));
    display: flex;
    flex-direction: row;
    left: 0;
    width: 100%;
    top: calc(0.5rem + var(--nav-height));
    z-index: 4;
    max-height: 90vh;
    font-size: 1.1em;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    background: var(--main-font-clr);
    border-radius: var(--default-border-rad);
    height: 0;
    padding: 0;
    overflow: auto;
    --column-gap: 1em;
    column-gap: var(--column-gap);
    animation: menu-padding-animation linear;
}

@keyframes menu-padding-animation {
    1% {
        padding: 1.5em;
    }
}


.menu-column {
  display: flex;
  flex-direction: column;
  z-index: 4;
  height: 0;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  width: calc((100% - (var(--column-gap) * (var(--column-count) - 1))) / var(--column-count));
}

.menu-item-wrapper {
  width: var(--menu-item-width);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-repeat: no-repeat;
  color: var(--bright-font-clr);
  letter-spacing: 0.1ch;
  cursor: pointer;
  transition: scale ease-in-out 200ms;
}

.menu-item {
    position: relative;
    padding-left: calc(var(--icon-width) + 0.5em);
    --icon-width: 1.5em;
}

.menu-item:before {
    content: '';
    top: calc((100% - var(--icon-width)) / 2);
    left: 0;
    width: var(--icon-width);
    height: var(--icon-width);
    position: absolute;
    background: var(--colorful-triangle-background);
}

.menu-item-link {
    width: 100%;
    padding: 1em;
    border-radius: var(--default-border-rad);
}

.menu-item-link:hover {
  background-color: var(--btn-darker-background-color);
}

.menu-item-link + .menu-item-link {
    margin-top: 1em;
}


.org-dashboard-wrapper .menu-item-title {
    margin-left: 0;
}

.org-dashboard-wrapper .router-link-active.menu-item-link .menu-item-title {
  color: var(--spz-yellow-clr);
}

.menu-item-summary {
    --menu-item-summary-lh: 1.3em;
    --allowed-lines: 3;
    line-height: var(--menu-item-summary-lh);
    height: calc(var(--allowed-lines) * var(--menu-item-summary-lh));
    text-align: left;
    margin-top: 0.5em;
    overflow: hidden;
}

.active-nav .menu-column {
    height: auto;
    overflow: visible;
}

.menu-toggle-wrapper {
    position: relative;
    top: 1rem;
    width: 5rem;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
    font-size: 1.4em;
    bottom: -3.5ch;
    color: var(--bright-font-clr);
    cursor: pointer;
    margin-left: -0.5rem;
    transition: all ease-in-out 200ms;
}

.menu.active-nav + .menu-toggle-wrapper:before {
    transform: rotate(-90deg);
    background-position-x: 25%;
    scale: 1;
}

.menu.active-nav {
  height: auto;
  padding-top: 1.5em;
}

.menu-toggle-wrapper::before {
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--deep-blue-triangle-background);
    position: absolute;
    z-index: 2;
    transition: all ease-in-out 200ms;
}

.menu.active-nav + .menu-toggle-wrapper {
  height: 2rem;
  top: 2.5rem;
  overflow: hidden;
}

.menu-toggle {
    position: relative;
    z-index: 3;
}

.menu-invis-background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
}

.menu.active-nav .menu-invis-background {
    left: 0;
}

.navigation {
  width: var(--nav-width); /* :TODO: actually is 241px check img sometime*/
  color: var(--darker-font-clr);
  background-color: var(--darker-bckgrnd-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: fixed;
  left: calc(((100vw - var(--max-central-section-width)) / 2) - var(--nav-width) - var(--scrollbar-width));
}

.navigation__header {
    margin: 15px;
    min-height: 59px;
}

.navigation__header_icon > img {
    width: 100%;
}


.navigation__list {
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.navigation__list_item {
  height: 2.25em;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: var(--white-shade-bck-clr);
  padding: 0 15px;
  cursor: pointer;
  border-radius: var(--default-border-rad);
}

.navigation__list_item:first-child {
  margin-top: 0;
}

.navigation__list_item .router-link-exact-active {
  color: #55c2d4;
}

.navigation__list_item a {
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  font-weight: bold;
}

.navigation__list_item:hover {
  background-color: #e8f2f3;
}

.user-section-icon__image {
    position: relative;
    display: flex;
    height: 3.5rem;
    width: 3.5rem;
    justify-content: center;
    align-items: center;
    border-radius: 100vw;
    padding: 0.4rem;
}

.user-section-icon__image:hover {
  background: var(--background-clr);
}

.user-section-icon__image > img {
    max-height: 100%;
    object-fit: cover;
    border-radius: 100vw;
    background: radial-gradient(closest-side, var(--background-clr), var(--main-font-clr));
}

.user-section-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

#org-profile-dropdown-radio:checked + .dropdown {
    display: flex;
}

.menu-section {
    font-family: Roboto;
    width: calc(100% - var(--sportzone-logo-width));
    height: 100%;
}

.menu-section .menu-row {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    color: var(--main-font-clr);
    --column-count: 1;
}

.menu-section .menu-links-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-left: 5rem;
}

.menu-section .menu-link {
    margin-right: 4rem;
    color: inherit;
    font-weight: bold;
}

.menu-section .menu-link:last-child {
    margin-right: 0;
}

.menu-section .user-section {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}


.menu-section .user-section-link {
    margin-right: 1rem;
    cursor: pointer;
    color: var(--darker-font-clr);
    font-weight: 500;
}

.menu-section .user-section-link>a {
    color: inherit;
}

.menu-section .org-section-name {
    display: none;
}

.menu-section .menu-link {
    position: relative;
}

.menu-link:hover::after, .menu-link.router-link-active::after {
  content: '';
  height: 1.1em;
  width: 50%;
  background-color: #e7f847;
  position: absolute;
  z-index: 10;
  bottom: -1.7rem;
  left: 25%;
  margin: 0 auto;
}

.menu-toggle-wrapper:hover::before {
  scale: 1.1;
}

@media  screen and (min-width: 420px) {
    .menu {
        left: calc((100vw - var(--menu-max-width)) / 2);
        width: var(--menu-max-width);
    }

    .menu.active-nav {
        padding: 1.5em 1.25em 1.25em 1.25em;
    }

}

@media  screen and (min-width: 680px) {
    .menu-toggle-wrapper {
        top: 1.25rem;
        width: 6rem;
        height: 6rem;
        font-size: 1.5em;
    }

    .menu.active-nav + .menu-toggle-wrapper {
        height: 2.5rem;
        top: 3rem;
    }

    .menu.active-nav + .menu-toggle-wrapper:before {
        background-position-x: 30%;
    }
}

@media  screen and (min-width: 875px) {

    .menu.active-nav {
        padding: 1.5em 1.25em 1.25em 1.25em;
    }

    .menu-section .menu-row {
        --column-count: 2;
    }
}

@media  screen and (min-width: 1000px) {
    .menu {
        font-size: 1.5em;
    }

    .menu-section .menu-link {
        font-size: 1.666em;
    }

    /* .menu-section {
        width: calc(100% - var(--sportzone-logo-width) - 1ch);
    } */
}

@media  screen and (min-width: 1400px) {
    .menu {
        font-size: 1.5em;
    }

    .menu-section .menu-row {
        --column-count: 3;
    }
}

</style>