import Home from '@/views/Home.vue';
import WatchBowlingLivestream from '@/views/bowling/WatchLivestream.vue';
import WatchGenericLivestream from '@/views/generic/GenericWatchLivestream.vue';
import Channels from '@/views/main/Channels.vue';
import Dashboard from '@/views/main/Dashboard.vue';
import LivestreamPanel from '@/views/main/LivestreamPanel.vue';
import Livestreams from '@/views/main/Livestreams.vue';
import LivestreamsArchive from '@/views/main/LivestreamsArchive.vue';
import LivestreamsDashboard from '@/views/main/LivestreamsDashboard.vue';
import Login from '@/views/main/Login.vue';
import OrganizerDashboard from '@/views/main/OrganizerDashboard.vue';
import PrivacyPolicy from '@/views/main/PrivacyPolicy.vue';
import Profile from '@/views/main/Profile.vue';
import ServiceUnavailable from '@/views/main/ServiceUnavailable.vue';
import Stations from '@/views/main/Stations.vue';
import TeamManagement from '@/views/main/TeamManagement.vue';
import UserDelete from '@/views/main/UserDelete.vue';
import UserLivestream from '@/views/main/UserLivestream.vue';
import UserManagement from '@/views/main/UserManagement.vue';
import RallyScore from '@/views/rally/RallyScore.vue';
import SnookerScore from '@/views/snooker/Score.vue';
import WatchSnookerLivestream from '@/views/snooker/WatchLivestream.vue';
import TableTennisScore from '@/views/tableTennis/Score.vue';
import BadmintonScore from '@/views/badminton/Score.vue';
import BadmintonGuestScore from '@/views/badminton/GuestScore.vue';
import WatchBadmintonLivestream from '@/views/badminton/WatchLivestream.vue'; 
import WatchTableTennisLivestream from '@/views/tableTennis/WatchLivestream.vue'; 
import TennisScore from '@/views/tennis/Score.vue';
import WatchTennisLivestream from '@/views/tennis/WatchLivestream.vue';
import VolleyballMatch from '@/views/volleyball/Matches.vue';
import VolleyballScore from '@/views/volleyball/Score.vue';
import VolleyballGuestScore from '@/views/volleyball/GuestScore.vue';
import VolleyballTournament from '@/views/volleyball/Tournaments.vue';
import WatchVolleyballLivestream from '@/views/volleyball/WatchLivestream.vue';
import UsersAndRoles from '@/views/main/UsersAndRoles.vue';
import AuthorizationResponse from '@/views/auth/AuthorizationResponse.vue';
import { createRouter, createWebHistory } from 'vue-router';
import TableTennisGuestScore from '@/views/tableTennis/GuestScore.vue';
import Organizations from '@/views/main/Organizations.vue';
import OrganizationMenu from '@/views/main/OrganizationMenu.vue';
import OrganizationMembers from '@/views/main/OrganizationMembers.vue';
import OrganizationInvitations from '@/views/main/OrganizationInvitations.vue';
import OrganizationApplications from '@/views/main/OrganizationApplications.vue';
import OrganizationProperties from '@/views/main/OrganizationProperties.vue';
import OrganizationSubOrganizations from '@/views/main/OrganizationSubOrganizations.vue';
import OrganizationRoles from '@/views/main/OrganizationRoles.vue';
import UserLivestreamsArchive from '@/views/main/UserLivestreamsArchive.vue';
import UserLivestreamsDashboard from '@/views/main/UserLivestreamsDashboard.vue';
import OrganizationSearch from '@/views/main/OrganizationSearch.vue';
import UserOrganizations from '@/views/main/UserOrganizations.vue';
import OrganizationPublic from '@/views/main/OrganizationPublic.vue';
import { registerGuard } from './Guard';
import UserPreferences from '@/views/user/UserPreferences.vue';
import UserDashboard from '@/views/user/UserDashboard.vue';
import UserCalendar from '@/views/user/UserCalendar.vue';
import UserRequest from '@/views/main/UserRequest.vue'
import UserPlayerDashboard from '@/views/user/UserPlayerDashboard.vue'
import ContainerResultView from '@/views/generic/ContainerResultView.vue';
import ContainerViewsList from '@/views/generic/ContainerViewsList.vue';
import ContainerViewsListPublic from '@/views/generic/ContainerViewsListPublic.vue';
import ContainerMenu from '@/views/main/ContainerMenu.vue';
import Containers from '@/views/main/Containers.vue';
import ContainerProperties from '@/views/main/ContainerProperties.vue';
import OrganizationSeasons from '@/views/main/OrganizationSeasons.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/authorization-response',
    component: AuthorizationResponse,
  },
  {
    path: '/bowling/live/:id',
    name: 'Watch Bowling Livestream',
    component: WatchBowlingLivestream,
  },
  {
    path: '/generic/live/:id',
    name: 'Watch Generic Livestream',
    component: WatchGenericLivestream,
  },
  {
    path: '/channels',
    name: 'Channels',
    component: Channels,
    meta: { requiresAuth: true },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuth: false },
  },
  {
    path: '/livestream/:id/panel',
    name: 'Livestream Panel',
    component: LivestreamPanel,
    meta: { requiresAuth: true },
  },
  {
    path: '/livestream',
    name: 'Livestreams',
    component: Livestreams,
    meta: { requiresAuth: true },
  },
  {
    path: '/archive',
    name: 'Livestreams Archive',
    component: LivestreamsArchive,
  },
  {
    path: '/live',
    name: 'Livestreams Dashboard',
    component: LivestreamsDashboard,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/organizer/dashboard',
    name: 'Organizer Dashboard',
    component: OrganizerDashboard,
    meta: { requiresAuth: true },
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: PrivacyPolicy,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: { requiresAuth: true },
  },
  {
    path: '/unavailable',
    name: 'Service Unavailable',
    component: ServiceUnavailable,
    meta: { requiresAuth: true },
  },
  {
    path: '/stations',
    name: 'Stations',
    component: Stations,
    meta: { requiresAuth: true },
  },
  {
    path: '/organizer/organizations/:id?/teams',
    name: 'Organization Team Management',
    component: TeamManagement,
    meta: { requiresAuth: true },
  },
  {
    path: '/organizer/organizations/teams',
    name: 'Team Management',
    component: TeamManagement,
    meta: { requiresAuth: true },
  },
  {
    path: '/user/delete',
    name: 'User Delete',
    component: UserDelete,
    meta: { requiresAuth: true },
  },
  {
    path: '/user/livestream',
    name: 'User Livestream',
    component: UserLivestream,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/user-management',
    name: 'UserManagement',
    component: UserManagement,
    meta: { requiresAuth: true },
  },
  {
    path: '/rally/score',
    name: 'Rally Score',
    component: RallyScore,
    meta: { requiresAuth: true },
  },
  {
    path: '/snooker/score/:id',
    name: 'Snooker Score',
    component: SnookerScore,
    meta: { requiresAuth: false },
  },
  {
    path: '/snooker/live/:id',
    name: 'Watch Snooker Livestream',
    component: WatchSnookerLivestream,
  },
  {
    path: '/table-tennis/score/:id',
    name: 'Table Tennis Score',
    component: TableTennisScore,
    meta: { requiresAuth: false },
  },
  {
    path: '/table-tennis/live/:id',
    name: 'Watch Table Tennis Livestream',
    component: WatchTableTennisLivestream,
  },
  {
    path: '/tennis/score/:id',
    name: 'Tennis Score',
    component: TennisScore,
    meta: { requiresAuth: false },
  },
  {
    path: '/tennis/live/:id',
    name: 'Watch Tennis Livestream',
    component: WatchTennisLivestream,
  },
  {
    path: '/badminton/score/:id',
    name: 'Badminton Score',
    component: BadmintonScore,
    meta: { requiresAuth: false },
  },
  {
    path: '/guest/badminton/score/:id',
    name: 'Badminton Guest Score',
    component: BadmintonGuestScore,
    meta: { requiresAuth: false },
  },
  {
    path: '/badminton/live/:id',
    name: 'Watch Badminton Livestream',
    component: WatchBadmintonLivestream,
  },
  {
    path: '/volleyball/match',
    name: 'Volleyball Match',
    component: VolleyballMatch,
    meta: { requiresAuth: false },
  },
  {
    path: '/volleyball/score/:id',
    name: 'Volleyball Score',
    component: VolleyballScore,
    meta: { requiresAuth: true },
  },
  {
    path: '/guest/volleyball/score/:id',
    name: 'Volleyball Guest Score',
    component: VolleyballGuestScore,
    meta: { requiresAuth: false },
  },
  {
    path: '/volleyball/tournament',
    name: 'Volleyball Tournament',
    component: VolleyballTournament,
    meta: { requiresAuth: true },
  },
  {
    path: '/volleyball/live/:id',
    name: 'Watch Voleyball Livestream',
    component: WatchVolleyballLivestream,
  },
  {
    path: '/users-and-roles',
    name: 'Users And Roles',
    component: UsersAndRoles,
    meta: { requiresAuth: true },
  },
  {
    path: '/guest/table-tennis/score/:id',
    name: 'Table Tennis Guest Score',
    component: TableTennisGuestScore,
    meta: { requiresAuth: false },
  },
  {
    path: '/organizer/organizations/:id/members',
    name: 'Organization Members',
    component: OrganizationMembers,
    meta: { requiresAuth: true }
  },
  {
    path: '/organizer/organizations/:id/invitations',
    name: 'Organization Invitations',
    component: OrganizationInvitations,
    meta: { requiresAuth: true }
  },
  {
    path: '/organizer/organizations/:id/applications',
    name: 'Organization Applications',
    component: OrganizationApplications,
    meta: { requiresAuth: true }
  },
  {    
    path: '/organizer/organizations/:id/properties',
    name: 'Organization Properties',
    component: OrganizationProperties,
    meta: { requiresAuth: true }
  },
  {
    path: '/organizer/organizations/:id/organizations',
    name: 'Sub Organizations',
    component: OrganizationSubOrganizations,
    meta: { requiresAuth: true }
  },
  {
    path: '/organizer/organizations/:id/roles',
    name: 'Organization Roles',
    component: OrganizationRoles,
    meta: { requiresAuth: true }
  },
//   {
//     path: '/organizer/organizations/containers',
//     name: 'Containers',
//     component: VolleyballMatch,
//     meta: { requiresAuth: true }
//   },
{
    path: '/organizer/organizations/:organizationId?/containers',
    name: 'Organization Containers',
    component: Containers,
    meta: { requiresAuth: true }
  },
  {
    path: '/user/dashboard',
    name: 'User Dashboard',
    component: UserDashboard,
    meta: { requiresAuth: true },
  },
  {
    path: '/user/calendar',
    name: 'User Calendar',
    component: UserCalendar,
    meta: { requiresAuth: true },
  },
  {
    path: '/user/stream',
    name: 'User Livestreams Dashboard',
    component: UserLivestreamsDashboard,
    meta: { requiresAuth: true },
  },
  {
    path: '/user/archive',
    name: 'User Livestreams Archive',
    component: UserLivestreamsArchive,
    meta: { requiresAuth: true },
  },
  {
    path: '/user/organizations/search',
    name: 'Organizations Search',
    component: OrganizationSearch,
    meta: { requiresAuth: true }
  },
  {
    path: '/user/organizations',
    name: 'User Organizations',
    component: UserOrganizations,
    meta: { requiresAuth: true }
  },
  {    
    path: '/user/organizations/:id/public',
    name: 'Organization Public',
    component: OrganizationPublic,
    meta: { requiresAuth: true }
  },
  {
    path: '/organizer/organizations/',
    name: 'Organizations',
    component: Organizations,
    meta: { requiresAuth: true }
  },
  {
    path: '/organizer/organizations/:id/menu',
    name: 'Organization Menu',
    component: OrganizationMenu,
    meta: { requiresAuth: true }
  },
  {
    path: '/user/preferences',
    name: 'User Preferences',
    component: UserPreferences,
    meta: { requiresAuth: true },
  },
  {
    path: '/user/request',
    name: 'User Request',
    component: UserRequest,
    meta: { requiresAuth: true }
  },
  {
    path: '/user/player/:id',
    name: 'User Player Dashboard',
    component: UserPlayerDashboard,
    meta: { requiresAuth: true }
  },
  {
    path: '/container/result/:id',
    name: 'Container Result',
    component: ContainerResultView,
    meta: { requiresAuth: true }
  },
  {
    path: '/container/:id/views/public',
    name: 'Container Views Public',
    component: ContainerViewsListPublic,
    meta: { requiresAuth: false }
  },
  {
    path: '/container/:id/views',
    name: 'Container Views',
    component: ContainerViewsList,
    meta: { requiresAuth: true }
  },
  {
    path: '/container/view/:id/public',
    name: 'Container Public View',
    component: ContainerResultView,
    meta: { requiresAuth: false }
  },
  {
    path: '/organizer/organizations/:organizationId/containers/:id/menu',
    name: 'Container Menu',
    component: ContainerMenu,
    meta: { requiresAuth: true }
  },
  {
    path: '/organizer/organizations/:organizationId/containers/:id/properties',
    name: 'Container Properties',
    component: ContainerProperties,
    meta: { requiresAuth: true }
  },
  {
    path: '/organizer/organizations/:organizationId/seasons',
    name: 'Organization Seasons',
    component: OrganizationSeasons,
    meta: { requiresAuth: true }
  }
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

registerGuard(router);

export default router;