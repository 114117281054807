<template>
    <ActionBox @click="$emit('click', index)" v-for="(pointType, index) in pointTypes" :key="index" :class="{'is-negative': !TENNIS_IS_POSITIVE_POINT[index]}"
        :isDisabled="isDisabled">{{ pointType }}</ActionBox>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import ActionBox from '@/components/tennis/ActionBox.vue';
import { TENNIS_POINT_TITLE_PAIRS, TENNIS_IS_POSITIVE_POINT } from '@/composables/tennis/TennisEventTypes';
export default defineComponent({
    components: {
        ActionBox,
    },
    emits: [
        'click',
    ],
    props: [
        'isDisabled',
    ],
    data() {
        return {
            pointTypes: TENNIS_POINT_TITLE_PAIRS,
            TENNIS_IS_POSITIVE_POINT
        };
    },
});
</script>
<style>

/* .tennis-action-box.is-negative ~ .tennis-action-box.is-negative {
    margin-top: 0;
} */
</style>