import GenericDictionary from '@/components/util/GenericDictionary';
import { Advertisement } from '@/models/main/Advertisement';
import { ActionContext, Module } from 'vuex';
import { IRootState } from '@/store';
import authModule from '@/main';
export interface AdvertisementStore {
    advertisements: GenericDictionary<Advertisement>,
};
const state: AdvertisementStore = {
    advertisements: {},
};
const getters = {
    getAdvertisement(state: AdvertisementStore, advertisementId: number) {
        return state.advertisements[advertisementId];
    },
    getAdvertisements(state: AdvertisementStore) {
        const advertisements: Array<Advertisement> = [];
        for (const advertisementId in state.advertisements) {
            if (Object.prototype.hasOwnProperty.call(state.advertisements, advertisementId)) {
                const advertisement = state.advertisements[advertisementId];
                advertisements.push(advertisement);
            }
        }
        return advertisements;
    },
};
const mutations = {
    addAdvertisement(state: AdvertisementStore, advertisement: Advertisement) {
        if (advertisement.id !== undefined) {
            state.advertisements[advertisement.id] = advertisement;
        }
    },
    removeAdvertisement(state: AdvertisementStore, deletedAdvertisementId: number) {
        if (Object.prototype.hasOwnProperty.call(state.advertisements, deletedAdvertisementId)) {
            delete state.advertisements[deletedAdvertisementId];
        }
    },
};
const actions = {
    getAdvertisement({ commit }: ActionContext<AdvertisementStore, IRootState>, advertisementId: number) {
        return authModule
            .get(`/api/advertisement/${advertisementId}`)
            .then((response) => {
                const advertisement: Advertisement = response.data as unknown as Advertisement;
                commit('addAdvertisement', advertisement);
            });
    },
    listAdvertisements({ commit }: ActionContext<AdvertisementStore, IRootState>) {
        return authModule
            .get('/api/advertisement')
            .then((response) => {
                const advertisements: Advertisement[] = response.data as unknown as Advertisement[];
                for (let i = 0; i < advertisements.length; i++) {
                    commit('addAdvertisement', advertisements[i]);
                }
            });
    },
    saveAdvertisement({ commit }: ActionContext<AdvertisementStore, IRootState>, advertisement: Advertisement) {
        return authModule
            .post(`/api/advertisement`, advertisement)
            .then((response) => {
                const createdAdvertisement: Advertisement = response.data as unknown as Advertisement;
                commit('addAdvertisement', createdAdvertisement);
            });
    },
    editAdvertisement({ commit }: ActionContext<AdvertisementStore, IRootState>, advertisement: Advertisement) {
        return authModule
            .patch(`/api/advertisement/${advertisement.id}`, advertisement)
            .then((response) => {
                const editedAdvertisement: Advertisement = response.data as unknown as Advertisement;
                commit('addAdvertisement', editedAdvertisement);
            });
    },
    deleteAdvertisement({ commit }: ActionContext<AdvertisementStore, IRootState>, advertisementId: number) {
        return authModule
            .delete(`/api/advertisement/${advertisementId}`)
            .then((response) => {
                const deletedAdvertisementId: number = response.data as number;
                commit('removeAdvertisement', deletedAdvertisementId);
            });
    },
};
const advertisementStore: Module<AdvertisementStore, IRootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
export default advertisementStore;