import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "watch-livestream-item" }
const _hoisted_2 = { class: "watch-livestream__left-section" }
const _hoisted_3 = { class: "start-word" }
const _hoisted_4 = { class: "start-hour" }
const _hoisted_5 = { class: "watch-livestream__icon-section" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "watch-livestream__mid-section" }
const _hoisted_8 = { class: "watch-livestream__primary-info" }
const _hoisted_9 = { class: "primary-info__item" }
const _hoisted_10 = { class: "watch-livestream__secondary-info" }
const _hoisted_11 = { class: "secondary-info__item" }
const _hoisted_12 = { class: "watch-livestream__right-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableTennisIcon = _resolveComponent("TableTennisIcon")!
  const _component_TennisIcon = _resolveComponent("TennisIcon")!
  const _component_BadmintonIcon = _resolveComponent("BadmintonIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.livestream.status === "Completed" || _ctx.livestream.status === "Stopping" ? "STREAMED" : "START"), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.getDateHours(_ctx.livestream.startTime)) + ":" + _toDisplayString(_ctx.getDateMinutes(_ctx.livestream.startTime)) + " (EET)", 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.livestream.sport === 'TableTennis')
        ? (_openBlock(), _createBlock(_component_TableTennisIcon, {
            key: 0,
            class: "tbltenn-icon-wrapper"
          }))
        : (_ctx.livestream.sport === 'Tennis')
          ? (_openBlock(), _createBlock(_component_TennisIcon, {
              key: 1,
              class: "tenn-icon-wrapper"
            }))
          : (_ctx.livestream.sport === 'Badminton')
            ? (_openBlock(), _createBlock(_component_BadmintonIcon, {
                key: 2,
                class: "badminton-icon-wrapper"
              }))
            : (_openBlock(), _createElementBlock("img", {
                key: 3,
                src: _ctx.livestream.sport === 'Bowling' ? '/bowling.png' : '/volleyball.png',
                alt: "Sport Icon"
              }, null, 8, _hoisted_6))
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.livestream.title), 1)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.getFromattedDate(_ctx.livestream.startTime)), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createVNode(_component_router_link, {
        class: "watch-livestream-btn",
        to: { name: _ctx.getSportWatchViewName(_ctx.livestream.sport), params: { id: _ctx.livestream.id },}
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.livestream.status === "Completed" || _ctx.livestream.status === "Stopping" ? "Watch" : (_ctx.livestream.status === "Pending" ? "Soon" : "Live")), 1)
        ]),
        _: 1
      }, 8, ["to"])
    ])
  ]))
}