import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { Router } from 'vue-router';

export class SportzoneAuth {
    private static readonly authOrigins: string[] =  ["https://sportzonelive.azurewebsites.net", "https://localhost:7071", "https://localhost:4280"] 
    private static _token: string;
    private originalAxiosInstance: AxiosInstance;
    private static readonly LocalStorageTokenKey = "SPZ_TOKEN";
    public static readonly prodApiUri: string = process.env.VUE_APP_PROD_API_URI ??  "https://sportzonelive.azurewebsites.net" /*"http://localhost:7071"*/
    public static readonly apiUri: string = process.env.VUE_APP_API_URI ?? "https://sportzonelive.azurewebsites.net"

    constructor(router: Router) {
        SportzoneAuth._token = localStorage.getItem(SportzoneAuth.LocalStorageTokenKey) ?? "";
        this.originalAxiosInstance = axios.create();
            this.originalAxiosInstance.interceptors.request.use(
                async (config: InternalAxiosRequestConfig) => {
                    const destOrigin = process.env.VUE_APP_API_URI ?? "";
                    if(SportzoneAuth._token && SportzoneAuth.authOrigins.includes(destOrigin)) {
                        config.headers['Authorization'] = `Bearer ${SportzoneAuth._token}`
                    }
                    return config;
                }
            )
    }

    set token(newToken: string) {
        //check token if okay for now token is invalidated (called logout) when 401 is received
        SportzoneAuth._token = newToken
        localStorage.setItem(SportzoneAuth.LocalStorageTokenKey, newToken)
    }

    get isAuthenticated(): boolean {
        return SportzoneAuth._token.length > 0
    }

    logout(): void {
        this.token = ""
    }

    get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.originalAxiosInstance.get(url, config)
    }
    delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.originalAxiosInstance.delete(url, config)
    }
    head<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.originalAxiosInstance.head(url, config)
    }
    options<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.originalAxiosInstance.options(url, config)
    }
    post<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
        return this.originalAxiosInstance.post(url, data, config)
    }
    put<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
        return this.originalAxiosInstance.put(url, data, config)
    }
    patch<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
        return this.originalAxiosInstance.patch(url, data, config)
    }
}