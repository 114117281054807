import GenericDictionary from "@/components/util/GenericDictionary";
import { PagedResponse, PaginatedItemsStoreGetters, PaginatedItemsStoreMutations, commitPagedResponseDetails, prepForPagedCall } from "@/composables/common/Pagination";
import authModule from "@/main";
import { Sport } from "@/models/main/CommonTypes";
import { Organization } from "@/models/main/Organization";
import { IRootState } from "@/store";
import { ActionContext, Module } from "vuex";

const state: MemberOrganizationStore = {
    paginatedItems: {
        items: {},
        pageSize: 30,
        currentPage: 1,
    },
    organizationsGroupedBySport: {}
};
export interface MemberOrganizationStore {
    paginatedItems: PagedResponse<Organization>,
    sport?: Sport,
    searchedTeam?: string,
    organizationsGroupedBySport: GenericDictionary<Organization[]>
};

const getters = {
    getOrganizations: (state: MemberOrganizationStore) => (sport?: Sport, searchedTeam?: string) => {
        let filteredOrganizations: Array<Organization> = [];
        const lowerCaseSearchTerm = searchedTeam?.toLowerCase();
        for (const organizationId in state.paginatedItems.items) {
            if (Object.prototype.hasOwnProperty.call(state.paginatedItems.items, organizationId)) {
                const organization: Organization = state.paginatedItems.items[organizationId] as Organization;
                const sportIsOk = sport === undefined || sport === 'Sport' || organization.sport === sport;
                const organizationNameLowerCased = organization.name?.toLowerCase();
                const searchedTeamIsOk = lowerCaseSearchTerm === undefined || organizationNameLowerCased?.startsWith(lowerCaseSearchTerm);
                if (sportIsOk && searchedTeamIsOk) {
                    filteredOrganizations.push(organization);
                }
            }
        }
        filteredOrganizations = filteredOrganizations.sort((a, b) => {
            return (a.name ?? '').localeCompare(b.name ?? '');
        });
        return filteredOrganizations;
    },
    getOrganization: (state: MemberOrganizationStore) => (organizationId: number) => {
        return state.paginatedItems.items[organizationId];
    },
    getOrganizationsGroupedBySport: (state: MemberOrganizationStore) => () => {
        return state.organizationsGroupedBySport
    },
    getSportFilter(state: MemberOrganizationStore) {
        return state.sport;
    },
    getSearchTermFilter(state: MemberOrganizationStore) {
        return state.searchedTeam;
    },
    ...PaginatedItemsStoreGetters(),
}

const mutations = {
    addOrganization(state: MemberOrganizationStore, organization: Organization) {
        state.paginatedItems.items[organization.id] = organization;
        if(!Object.prototype.hasOwnProperty.call(state.organizationsGroupedBySport, organization.sport)) {
            state.organizationsGroupedBySport[organization.sport] = []
        }

        if(state.organizationsGroupedBySport[organization.sport].length > 0) {
            const orgIndex = state.organizationsGroupedBySport[organization.sport].findIndex((org => org.id === organization.id))
            if(orgIndex > -1) {
                state.organizationsGroupedBySport[organization.sport][orgIndex] = organization
                return
            } 
        }

        state.organizationsGroupedBySport[organization.sport].push(organization);
    },
    editOrganization(state: MemberOrganizationStore, organization: Organization) {
        state.paginatedItems.items[organization.id] = organization;
        const groupedOrgs = state.organizationsGroupedBySport[organization.id]
        if(groupedOrgs) {
            for(let i = 0; i < groupedOrgs.length; i++) {
                if(groupedOrgs[i].id === organization.id) {
                    groupedOrgs[i] = organization
                    break;
                }
            }
    
            state.organizationsGroupedBySport[organization.id] = groupedOrgs   
        }
    },
    removeOrganization(state: MemberOrganizationStore, organizationId: number) {
        if (Object.prototype.hasOwnProperty.call(state.paginatedItems.items, organizationId)) {
            const organization = state.paginatedItems.items[organizationId]
            let groupedOrganizations = state.organizationsGroupedBySport[organization.sport]
            state.organizationsGroupedBySport[organization.sport] = groupedOrganizations.filter(org => org.id !== organization.id)
            delete state.paginatedItems.items[organizationId];
        }
    },
    setSportFilter(state: MemberOrganizationStore, sport: Sport) {
        state.sport = sport;
    },
    setSearchTermFilter(state: MemberOrganizationStore, searchTerm: string) {
        state.searchedTeam = searchTerm;
    },
    ...PaginatedItemsStoreMutations(),
}

const actions = {
    addOrganization({ commit }: ActionContext<MemberOrganizationStore, IRootState>, organization: Organization) {
        commit('addOrganization', organization);
    },
    fetchUserOrganizationsAsMember({ commit, dispatch, getters }: ActionContext<MemberOrganizationStore, IRootState>, { sport, searchTerm }: { sport: Sport | null, searchTerm?: string }) {
        let filtersChanged = false;
        const currentSport = getters['getSportFilter'];
        if (currentSport !== sport) {
            commit('setSportFilter', sport);
            filtersChanged = true;
        }
        const currentSearchTerm = getters['getSearchTermFilter']
        if(currentSearchTerm !== searchTerm) {
            commit('setSearchTermFilter', searchTerm);
            filtersChanged = true;
        }
        const { pageToRequest, pageSize, canMakeNextPageCall }  = prepForPagedCall(getters, filtersChanged)
        if(canMakeNextPageCall) {
            return authModule.get(`/api/user/organization`, {
                params: {
                    sport: sport,
                    searchTerm,
                    page: pageToRequest,
                    pageSize: pageSize,
                },
            })
            .then((response) => {
                const pagedResponse: PagedResponse<Organization> = response.data as unknown as PagedResponse<Organization>;
                commitPagedResponseDetails(commit, pagedResponse)
                for (const containerIndex in pagedResponse.items) {
                    dispatch('addOrganization', pagedResponse.items[containerIndex]);
                }
    
                return pagedResponse
            })
            .catch((error) => {
                console.error(error);
            });
        }
    }
}

const memberOrganizationStore: Module<MemberOrganizationStore, IRootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
export default memberOrganizationStore;