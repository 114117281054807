import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheUserNavigationBar = _resolveComponent("TheUserNavigationBar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["usr-dashboard-wrapper", {'subpages-wrapper' : _ctx.hasSubPages}])
  }, [
    _createVNode(_component_TheUserNavigationBar, {
      user: _ctx.getUser,
      navigationItems: _ctx.navigationItems,
      "page-heading": _ctx.title
    }, null, 8, ["user", "navigationItems", "page-heading"]),
    _createElementVNode("main", {
      class: "usr-main-container",
      onScroll: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onScroll && _ctx.onScroll(...args)))
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 32)
  ], 2))
}