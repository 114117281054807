import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "watch-livestreams-list" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "watch-livestreams-list" }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WatchLivestreamItem = _resolveComponent("WatchLivestreamItem")!
  const _component_LoadingIcon = _resolveComponent("LoadingIcon")!
  const _component_TheOrganizerDashboard = _resolveComponent("TheOrganizerDashboard")!
  const _component_ThePublicHomeLayout = _resolveComponent("ThePublicHomeLayout")!

  return (_ctx.authModule.isAuthenticated)
    ? (_openBlock(), _createBlock(_component_TheOrganizerDashboard, {
        key: 0,
        ref: "mainWrapper",
        onScroll: _ctx.onMainWrapperScroll
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.isAvailableLivestreams)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.getLivestreams({ sort: 'ASC', statuses: ['Pending', 'Starting', 'Streaming'] }), (livestream) => {
                  return (_openBlock(), _createBlock(_component_WatchLivestreamItem, {
                    key: livestream.id,
                    livestream: livestream
                  }, null, 8, ["livestream"]))
                }), 128))
              : (_openBlock(), _createElementBlock("h1", _hoisted_2, "No livestreams at the moment")),
            _withDirectives(_createVNode(_component_LoadingIcon, null, null, 512), [
              [_vShow, _ctx.showLoadingIcon]
            ])
          ])
        ]),
        _: 1
      }, 8, ["onScroll"]))
    : (_openBlock(), _createBlock(_component_ThePublicHomeLayout, {
        key: 1,
        ref: "mainWrapper",
        onScroll: _ctx.onMainWrapperScroll
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.isAvailableLivestreams)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.getLivestreams({ sort: 'ASC', statuses: ['Pending', 'Starting', 'Streaming'], privacy: 'Public' }), (livestream) => {
                  return (_openBlock(), _createBlock(_component_WatchLivestreamItem, {
                    key: livestream.id,
                    livestream: livestream
                  }, null, 8, ["livestream"]))
                }), 128))
              : (_openBlock(), _createElementBlock("h1", _hoisted_4, "No livestreams at the moment")),
            _withDirectives(_createVNode(_component_LoadingIcon, null, null, 512), [
              [_vShow, _ctx.showLoadingIcon]
            ])
          ])
        ]),
        _: 1
      }, 8, ["onScroll"]))
}