<template>
    <ThePublicHomeLayout>
        <div class="tbl-tenn-score">
            <div class="container-title">{{getCurrentContainer?.title}}</div>
            <div class="score-form" :class="{'disabled': formIsDisabled}">
                <div class="break-controls">
                </div>
                <div class="match-controls">
                <button @click="onReverseClick" class="btn delete-btn" type="button" title="Undo" :disabled="formIsDisabled">Undo</button>
                </div>
                <div class="match-scoreboard">
                <div class="home-team" v-if="getCurrentHomeEntity?.id">
                    <div class="main-scoreboard">
                        <div class="scoreboard-top">
                            <div class="score-form__entity-color">
                                <input type="color" id="home-color" name="home-color" value="#ffffff" />
                                <label for="home-color" aria-hidden="true" v-show="false">Kit color</label>
                            </div>
                            <div class="score-form__entity-name">{{getCurrentHomeEntity?.name}}</div>
                            <ActionBox :isDisabled="formIsDisabled" class="point-box" @click="onHomePointBoxClick">{{getCurrentHomeEntityPoints}}</ActionBox>
                        </div>
                        <div class="scoreboard-bottom">
                            <ActionBox :isDisabled="formIsDisabled" @click="onGameBoxClick(getCurrentHomeEntity?.id)" style="float:left;">{{currentHomeEntityGamePoints}}</ActionBox>
                        </div>
                    </div>
                </div>
                <div class="away-team" v-if="getCurrentAwayEntity?.id">
                    <div class="main-scoreboard">
                        <div class="scoreboard-top">
                            <div class="score-form__entity-color">
                                <input type="color" id="away-color" name="away-color" value="#ffffff" />
                                <label for="away-color" aria-hidden="true" v-show="false">Kit color</label>
                            </div>
                            <div class="score-form__entity-name">{{getCurrentAwayEntity?.name}}</div>
                            <ActionBox :isDisabled="formIsDisabled" class="point-box" @click="onAwayPointBoxClick">{{getCurrentAwayEntityPoints}}</ActionBox>
                        </div>
                        <div class="scoreboard-bottom">
                            <ActionBox :isDisabled="formIsDisabled" @click="onGameBoxClick(getCurrentAwayEntity?.id)" style="float:right;">{{currentAwayEntityGamePoints}}</ActionBox>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </ThePublicHomeLayout>
    </template>
 <script setup lang="ts">
 import ThePublicHomeLayout from '@/components/main/ThePublicHomeLayout.vue' 
 import ActionBox from '@/components/volleyball/ActionBox.vue'
 import { useStore, createNamespacedHelpers} from 'vuex'
 import { defineComponent, onMounted, ref, Ref } from 'vue'
 import { key } from '@/store'
 import { CONTAINER_STATUSES, Container } from '@/models/main/Container'
 import { useRoute } from 'vue-router'
 import { Event, EventType } from '@/models/main/Event';
 import { TableTennisGameControlType } from '@/composables/tableTennis/TableTennisEventTypes'
 import useTableTennisContainerComputeds  from '@/composables/tableTennis/UseCurrentContainerComputeds'
 import Modal from '@/components/main/Modal.vue';
 import { copyToClipboard, addAccessKeyQueryParam } from '@/composables/common/Util';
 
 const store = useStore(key);
 const route = useRoute()
 const matchId: Ref<number> = ref(parseInt(route.params.id as string));
 const match: Ref<Container | undefined> = ref()
 
 const { 
     getCurrentContainer,
     getCurrentHomeEntity,
     getCurrentAwayEntity,
     formIsDisabled,
     onOpenMatch,
     onCompleteMatch,
     onReopenMatch,
     loadContainer,
     onShareLinkButtonClicked,
     accessKey,
     infoPopUp,
     shareLink,
     offlineDataDownloadLinkUri,
     onReverseClick,
     createEvent,
     addEvent,
     editEvent,
     onEventEdit,
     removeEvent,
     onHomePointBoxClick,
     onAwayPointBoxClick,
     toggleHomePlayerSelect,
     toggleAwayPlayerSelect,
     selectedHomePlayerId,
     selectedAwayPlayerId,
     selectedEventToEdit,
     advancedEditEnabled,
     calculateEntityPoints,
     calculateEntityGames,
     groupEventsByGames,
     getCurrentContainerEventsGroupedByGames,
     onMatchFinishedClick,
     onGameBoxClick,
     getCurrentHomeEntityPoints,
     getCurrentAwayEntityPoints,
     currentHomeEntityGamePoints,
     currentAwayEntityGamePoints,
 } = useTableTennisContainerComputeds(matchId)
 
 onMounted(loadContainer);
 
 </script>
    
<style>
/* TABLE TENNIS CUSTOM */
.public-layout-main .tbl-tenn-score {
    background-color: var(--background-clr);
    z-index: 1;
}

.public-layout-main .match-controls {
    justify-content: center;
}
</style>