<template>
    <ActionBox :isDisabled="isDisabled" @click="$emit('click')" :isSelected="isSelected">
        <span class="cmp-number">{{number}}</span>
        <span>{{firstName.charAt(0)}} {{lastName}}</span>
    </ActionBox>
</template>
<script>
import ActionBox from '@/components/volleyball/ActionBox.vue'

export default {
    name: 'CompetitorNameToggle',
    data() {
        return {}
    },
    props: ['number', 'firstName', 'middleName', 'lastName', 'isSelected', 'isDisabled'],
    emits: ['click'],
    methods: {
        click() {
            this.isClicked = !this.isClicked;
        }
    },
    components: {
        ActionBox
    }
}
</script>
<style>
span.cmp-number {
  padding-right: 10px;
}

</style>