<template>
    <TheUserDashboard @scroll="onMainWrapperScroll" title="My Livestreams">
        <div v-if="getFavouriteLivestreams()" class="watch-livestreams-list">
            <WatchLivestreamItem v-for="favouriteLivestream in getFavouriteLivestreams()"
                :key="favouriteLivestream.id" :livestream="favouriteLivestream">
            </WatchLivestreamItem>
            <LoadingIcon v-show="showLoadingIcon" />
        </div>
        <h1 v-else>
            You do not have a favourite livestream yet
        </h1>
    </TheUserDashboard>
</template>
<script lang="ts">
import TheUserDashboard from '@/components/main/TheUserDashboard.vue';
import WatchLivestreamItem from '@/components/main/WatchLivestreamItem.vue';
import LoadingIcon from '@/components/util/LoadingIcon.vue';
import { createNamespacedHelpers, useStore } from 'vuex';
import { defineComponent, Ref, ref } from 'vue';
import { key } from '@/store';
import { useRouter } from 'vue-router';
const userStoreHelpers = createNamespacedHelpers('userStore');
export default defineComponent({
    components: {
        TheUserDashboard,
        WatchLivestreamItem,
        LoadingIcon,
    },
    computed: {
        ...userStoreHelpers.mapGetters([
            'getFavouriteLivestreams',
        ]),
    },
    setup() {
        let scrollTimeout: any;
        let loadInProcess: Ref<boolean> = ref(false);
        const showLoadingIcon: Ref<boolean> = ref(true);
        const store = useStore(key);
        const router = useRouter();
        const onMainWrapperScroll = (e: any) => {
            const totalScrollHeight = e.target.scrollHeight;
            const scrollTop = e.target.scrollTop;
            const offsetHeight = e.target.offsetHeight;
            const margin = 20;
            let bottomOfWindow = totalScrollHeight - scrollTop - offsetHeight < margin;
            if (bottomOfWindow) {
                if (scrollTimeout) {
                    clearTimeout(scrollTimeout);
                }
                scrollTimeout = setTimeout(onScrollStopped, 500);
            }
        };
        const onScrollStopped = () => {
            if (!loadInProcess.value) {
                loadInProcess.value = true;
                loadFavouriteLivestreams();
                loadInProcess.value = false;
            }
        };
        const loadFavouriteLivestreams = async () => {
            showLoadingIcon.value = true;
            await store.dispatch('userStore/getFavouriteLivestreams');
            showLoadingIcon.value = false;
        };
        loadFavouriteLivestreams();
        return {
            showLoadingIcon,
            store,
            router,
            onMainWrapperScroll,
            loadFavouriteLivestreams,
        };
    },
});
</script>
<style>
.list-livestreams {
    width: 100%;
    --watch-bck-color: var(--archive-bck-color);
    --loading-icon-moving-color: var(--archive-bck-color);
}
</style>