import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "organizations-wrapper" }
const _hoisted_2 = { class: "sport-wrapper" }
const _hoisted_3 = { class: "sport-icon-wrapper" }
const _hoisted_4 = { class: "sport-organizations" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VolleyballIcon = _resolveComponent("VolleyballIcon")!
  const _component_TennisIcon = _resolveComponent("TennisIcon")!
  const _component_TableTennisIcon = _resolveComponent("TableTennisIcon")!
  const _component_BowlingIcon = _resolveComponent("BowlingIcon")!
  const _component_BadmintonIcon = _resolveComponent("BadmintonIcon")!
  const _component_OrganizationPublicBadgeItem = _resolveComponent("OrganizationPublicBadgeItem")!
  const _component_TheUserDashboard = _resolveComponent("TheUserDashboard")!

  return (_openBlock(), _createBlock(_component_TheUserDashboard, {
    onUserLoad: _ctx.onUserLoaded,
    title: "Organizations"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getOrganizationsGroupedBySport(), (organizationsGrouped, sport) => {
          return (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (sport == 'Volleyball')
                ? (_openBlock(), _createBlock(_component_VolleyballIcon, {
                    key: 0,
                    class: "organization-sport-icon"
                  }))
                : (sport == 'Tennis')
                  ? (_openBlock(), _createBlock(_component_TennisIcon, {
                      key: 1,
                      class: "organization-sport-icon"
                    }))
                  : (sport == 'TableTennis')
                    ? (_openBlock(), _createBlock(_component_TableTennisIcon, {
                        key: 2,
                        class: "organization-sport-icon"
                      }))
                    : (sport == 'Bowling')
                      ? (_openBlock(), _createBlock(_component_BowlingIcon, {
                          key: 3,
                          class: "organization-sport-icon"
                        }))
                      : (sport == 'Badminton')
                        ? (_openBlock(), _createBlock(_component_BadmintonIcon, {
                            key: 4,
                            class: "organization-sport-icon"
                          }))
                        : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(organizationsGrouped, (organization) => {
                return (_openBlock(), _createBlock(_component_OrganizationPublicBadgeItem, {
                  class: "organization-wrapper",
                  organization: organization
                }, null, 8, ["organization"]))
              }), 256))
            ])
          ]))
        }), 256))
      ])
    ]),
    _: 1
  }, 8, ["onUserLoad"]))
}