<template>
    <TheUserDashboard :onUserLoad="onUserLoaded" title="Dashboard">
        <div class="user-dashboard-wrapper">
            <section class="user-stat-graphs-panel component-panel">
                <h3 class="component-panel__title">Total Points</h3>
                <div class="user-board__player-stat-graphs">
                    <Line v-if="chartIsReadyToRender" :data="chartData" :options="chartOptions"/>
                </div>
            </section>
            <section class="user-stat-graphs-panel second component-panel">
                <h3 class="component-panel__title">Points Distribution</h3>
                <div class="user-board__player-stat-graphs ">
                    <Doughnut v-if="pieIsReadyToRender" :data="pieData" :options="doughnutOptions"/>
                </div>
            </section>
            <section class="user-stats-panel component-panel">
                <h3 class="component-panel__title">Stats</h3>
                <div class="user-board__player-stats">
                    <div class="user-board__player-stat positive">
                        <div class="user-board__player-stat-heading">Aces</div>
                        <div class="user-board__player-stat-val">{{ getPlayerStatsSummed[VLY_ACE] }}</div>
                    </div>
                    <div class="user-board__player-stat positive">
                        <div class="user-board__player-stat-heading">Attacks</div>
                        <div class="user-board__player-stat-val">{{ getPlayerStatsSummed[VLY_ATTACK] }}</div>
                    </div>
                    <div class="user-board__player-stat positive">
                        <div class="user-board__player-stat-heading">Blocks</div>
                        <div class="user-board__player-stat-val">{{ getPlayerStatsSummed[VLY_BLOCK] }}</div>
                    </div>
                    <div class="user-board__player-stat positive">
                        <div class="user-board__player-stat-heading">Other</div>
                        <div class="user-board__player-stat-val">{{ getPlayerStatsSummed[VLY_POINT] }}</div>
                    </div>
                    <div class="user-board__player-stat negative">
                        <div class="user-board__player-stat-heading">Service Errors</div>
                        <div class="user-board__player-stat-val">{{ getPlayerStatsSummed[VLY_SERVICE_ERROR] }}</div>
                    </div>
                    <div class="user-board__player-stat negative">
                        <div class="user-board__player-stat-heading">Fouls</div>
                        <div class="user-board__player-stat-val">{{ getPlayerStatsSummed[VLY_FOUL] }}</div>
                    </div>
                    <div class="user-board__player-stat negative">
                        <div class="user-board__player-stat-heading">Other</div>
                        <div class="user-board__player-stat-val">{{ getPlayerStatsSummed[VLY_ERROR] }}</div>
                    </div>
                </div>
            </section>
            <section class="user-board-panel component-panel">
                <div class="component-panel-content-wrapper" >
                    <div class="user-board__player-tabs" v-if="false">
                        <div class="user-board__player-tab">{{ currentUser?.firstName }}</div>
                    </div>
                    <div class="user-board__player-wrapper">
                        <div class="user-board__player-info">
                            <div class="user-board__player-info__picture">
                                <img :src="'/api/user/' + currentUser?.id + '/picture'" v-if="currentUser"/>
                            </div>
                            <div class="user-board__player-info__details">
                                <div class="user-board__player-info__names">{{ currentUser?.firstName }} {{ currentUser?.lastName }}</div>
                                <div class="user-board__player-info__location">
                                    <div class="user-board__player-info__flag">
                                        <img src="https://www.bowlzone.eu/app/plugins/bowlzone-plugin/public/images/flags/Bulgaria.png" alt="BG flag"/>
                                    </div>
                                    {{ currentUser?.nationality }}</div>
                                <div class="user-board__player-info__age">{{ currentUser?.birthday ? getAgeFromBirthday(currentUser?.birthday) : '' }}</div>
                                <div class="user-board__player-info__team" :class="{'active' : playerStats.id === currentActivePlayerId}" v-for="(playerStats, i) in getUserStats" :key="playerStats.id">
                                    <div class="user-board__player-info__team-logo">
                                        <img :src="'/api/entity/' +  playerStats.entity?.id + '/logo'" />
                                    </div>
                                    <div class="user-board__player-info__team-name">{{ playerStats.entity?.name }}</div>
                                    <div class="user-board__player-info__team-number">{{ playerStats.number}}</div>
                                    <div class="user-board__player-info__team-role">Captain</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="user-calendar-panel component-panel">
                <h3 class="component-panel__title">Calendar</h3>
                <ScheduleXCalendar :calendar-app="calendarApp" />
            </section>
            <section class="lives-panel component-panel">
                <h3 class="component-panel__title">Live</h3>
                <div class="live-panel__livestreams" >
                    <WatchLivestreamItemv2 v-if="getLivestreams({ sort: 'ASC', statuses: isNonCompletedStatuses, privacy: 'Public' }).length > 0" 
                        :livestream="getLivestreams(({ sort: 'ASC', statuses: isNonCompletedStatuses, privacy: 'Public' }))[0]" />
                </div>
            </section>
            <section class="user-org-panel component-panel">
                <h3 class="component-panel__title">Organizations</h3>
                <div class="component-panel-content-wrapper">
                    <template class="sport-wrapper" v-for="(organizationsGrouped, sport) in getMemberOrganizationsGroupedBySport">
                        <OrganizationPublicBadgeItem v-for="organization in organizationsGrouped" :organization="organization"/>
                    </template>
                </div>
            </section>
        </div>
  </TheUserDashboard>
</template>
<script lang="ts">
import { Ref, computed, defineComponent, onMounted, ref, watch } from "vue";
import { createNamespacedHelpers, useStore } from "vuex";
import { key } from "@/store";
import TheUserDashboard from "@/components/main/TheUserDashboard.vue";
import { useRouter } from "vue-router";
import { User } from "@/models/main/User";
import { default  as SportzoneTooltip } from '@/components/main/Tooltip.vue';
import { ScheduleXCalendar } from '@schedule-x/vue'
import {
  createCalendar,
  viewDay,
  viewWeek,
  viewMonthGrid,
  viewMonthAgenda,
} from '@schedule-x/calendar'
import '@schedule-x/theme-default/dist/index.css'
import { Container } from "@/models/main/Container";
import OrganizationPublicBadgeItem from "@/components/main/OrganizationPublicBadgeItem.vue";
import { LIVESTREAM_STATUSES, LivestreamStatus } from "@/models/main/Livestream";
import WatchLivestreamItem from "@/components/main/WatchLivestreamItem.vue";
import WatchLivestreamItemv2 from "@/components/main/WatchLivestreamItemv2.vue";
import { getSportWatchViewName, getAgeFromBirthday } from "@/composables/common/Util";
import { Bar, Line, Doughnut } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineController, LineElement, PointElement, ArcElement } from 'chart.js'
import useUserOrganizationComputeds from "@/composables/user/UseUserOrganizationComputeds";
import { VLY_ACE, VLY_ATTACK, VLY_BLOCK, VLY_ERROR, VLY_FOUL, VLY_POINT, VLY_SERVICE_ERROR, VOLLEYBALL_IS_POSITIVE_POINT, VolleyballPointType } from "@/composables/volleyball/VolleyballEventTypes";
import { TennisPointType } from "@/composables/tennis/TennisEventTypes";
import GenericDictionary from "@/components/util/GenericDictionary";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineController, LineElement, PointElement, ArcElement)

const organizationStoreHelpers = createNamespacedHelpers('organizationStore');
const livestreamStoreHelpers = createNamespacedHelpers('livestreamPublicStore');
export default defineComponent({
    components: {
    TheUserDashboard,
    SportzoneTooltip,
    ScheduleXCalendar,
    OrganizationPublicBadgeItem,
    WatchLivestreamItem,
    WatchLivestreamItemv2,
    Line,
    Bar,
    Doughnut,
    },
    computed: {
        ...livestreamStoreHelpers.mapGetters([
            'getLivestreams',
        ]),
    },
  setup() {
    const calendarApp = createCalendar({
        views: [viewMonthGrid, viewMonthAgenda],
        defaultView: viewMonthAgenda.name,
        events: [
        ],
        callbacks : {
            onEventClick: (event) => {
                console.log(event);
            }
        }
    })
    const router = useRouter();
    const store = useStore(key);
    const currentUser: Ref<User | undefined> = ref();
    const onUserLoaded = async (user: User) => {
        currentUser.value = user
        if(currentUser.value?.id) {
            await loadUserStats(currentUser.value.id)
            const userStats = getUserStats.value
            if(userStats && userStats[1]) {
                currentActivePlayerId.value = userStats[1].id
            }
        }
    }

    const currentActivePlayerId: Ref<number | undefined> = ref()
    const getUserStats = computed(() => {
        return store.getters['userStatStore/getUserStats']
    })
    type PointType = VolleyballPointType & TennisPointType
    const getPlayerStatsSummed = computed(() => {
        const userStats = getUserStats.value
        const pointTypesSummed: GenericDictionary<number> = {}
        for(let pIndex in userStats) {
            const player = userStats[pIndex]
            if(player.id === currentActivePlayerId.value) { // only Volleyball
                const entity = player.entity
                const entityContainers = entity.entityContainers
                for(let ecIndex in entityContainers) {
                    const entityContainer = entityContainers[ecIndex]
                    const container = entityContainer.container
                    const volleyballPlayerStats = container.volleyballPlayerStats
                    if(volleyballPlayerStats) {
                        for(let vpsIndex in volleyballPlayerStats) {
                            const pointType: PointType = volleyballPlayerStats[vpsIndex].type as unknown as PointType
                            const statCount = volleyballPlayerStats[vpsIndex].statCount
                            if(!pointTypesSummed[pointType]) {
                                pointTypesSummed[pointType] = statCount
                            } else {
                                pointTypesSummed[pointType] += statCount
                            }
                        }
                    }
                }
            }
        }

        return pointTypesSummed
    })

    const getPlayerMonthlyMovingTotalPoints = computed(() => {
        const userStats = getUserStats.value

        const totalPointsGroupedMonthly: GenericDictionary<number> = {}
        for(let pIndex in userStats) {
            const player = userStats[pIndex]
            if(player.id === currentActivePlayerId.value) { // only Volleyball
                const entity = player.entity
                const entityContainers = entity.entityContainers
                for(let ecIndex in entityContainers) {
                    const entityContainer = entityContainers[ecIndex]
                    const container = entityContainer.container
                    const containerMonth = new Date(container.startTime).toLocaleString('default', { month: 'long' })
                    const volleyballPlayerStats = container.volleyballPlayerStats
                    let containerTotalPositivePointsByPlayer = 0
                    if(volleyballPlayerStats) {
                        for(let vpsIndex in volleyballPlayerStats) {
                            const pointType: PointType = volleyballPlayerStats[vpsIndex].type as unknown as PointType
                            if(VOLLEYBALL_IS_POSITIVE_POINT[pointType]) {
                                const statCount = volleyballPlayerStats[vpsIndex].statCount
                                containerTotalPositivePointsByPlayer += statCount
                            }
                        }
                    }

                    if(!totalPointsGroupedMonthly[containerMonth]) {
                        totalPointsGroupedMonthly[containerMonth] = containerTotalPositivePointsByPlayer
                    } else {
                        totalPointsGroupedMonthly[containerMonth] += containerTotalPositivePointsByPlayer
                    }
                }
            }
        }

        console.log(totalPointsGroupedMonthly)
        return totalPointsGroupedMonthly
    })

    const loadUserStats = (userId: number) => {
        return store.dispatch('userStatStore/fetchUserStats', userId)
    }

    const pieInitialData = {
        labels: [ 'Ace', 'Attack', 'Block', 'Other'],
        datasets: [
            {
            label: 'Points Distribution',
            backgroundColor: ['#283380', '#abc6de', '#0ab4b9', '#00AEF0'],
            data: [0.0]
            }
        ]
    }
    const pieData = ref(pieInitialData)
    const pieIsReadyToRender: Ref<boolean> = ref(false)
    watch(getPlayerStatsSummed, (playerStatsSummed) => {
        if(Object.keys(playerStatsSummed).length > 0) {
            const aceCount = playerStatsSummed[VLY_ACE] ?? 0
            const attackCount = playerStatsSummed[VLY_ATTACK] ?? 0
            const blockCount = playerStatsSummed[VLY_BLOCK] ?? 0
            const pointCount = playerStatsSummed[VLY_POINT] ?? 0
            const totalPoints = aceCount + attackCount + blockCount + pointCount
            pieData.value.datasets[0].data[0] = aceCount * 100.0 / totalPoints
            pieData.value.datasets[0].data[1] = attackCount * 100.0 / totalPoints
            pieData.value.datasets[0].data[2] = blockCount * 100.0 / totalPoints
            pieData.value.datasets[0].data[3] = pointCount * 100.0 / totalPoints
            pieIsReadyToRender.value = true
        }
    })

    const chartInitialData = {
        labels: [ 'January', 'February', 'March', 'April', 'May'], // :TODO make these dynamic too
        datasets: [
          {
            label: 'Points',
            backgroundColor: '#abc6de',
            borderColor: '#283380',
            tension: 0.5,
            data: [0]
          }
        ]
      }

    const chartData = ref(chartInitialData)
    const chartIsReadyToRender = ref(false)
    watch(getPlayerMonthlyMovingTotalPoints, (playerMonthlyMovingTotalPoints) => {
        if(Object.keys(playerMonthlyMovingTotalPoints).length > 0) {
            chartData.value.datasets[0].data[0] = playerMonthlyMovingTotalPoints['January'] ?? 0
            chartData.value.datasets[0].data[1] = playerMonthlyMovingTotalPoints['February'] ?? 0
            chartData.value.datasets[0].data[2] = playerMonthlyMovingTotalPoints['March'] ?? 0
            chartData.value.datasets[0].data[3] = playerMonthlyMovingTotalPoints['April'] ?? 0
            chartData.value.datasets[0].data[4] = playerMonthlyMovingTotalPoints['May'] ?? 0
            chartIsReadyToRender.value = true
        }
    })

    const loadContainers = async () => {
        await store.dispatch('containerStore/fetchContainers', {
            status: null,
            type: "Match",
            sport: null,
            organizationId: 17,
        });

        const containersList: Container[] = store.getters['containerStore/listContainers']({})
        for(let i = 0; i < containersList.length; i++) {
            const container = containersList[i]
            const containerAsEvent = {
                    id: container.id ?? 0,
                    title: container.title,
                    start: container.startTime ?? '',//,
                    end: container.endTime ?? container.startTime ?? '', 
                }
            calendarApp.events.add(containerAsEvent)
        }
    };

    const {
            loadUserOrganizationsAsMember,
            getMemberOrganizationsGroupedBySport
        }  = useUserOrganizationComputeds()

    const isAvailableLivestreams: Ref<boolean> = ref(true);
    const isNonCompletedStatuses: LivestreamStatus[] = [LIVESTREAM_STATUSES[0], LIVESTREAM_STATUSES[1], LIVESTREAM_STATUSES[2]];
    const loadLivestreams = async () => {
        const livestreams = await store.dispatch('livestreamPublicStore/getPublicLivestreams', { statuses: isNonCompletedStatuses });
        if (!livestreams) {
            isAvailableLivestreams.value = false;
        }
    };





    const doughnutOptions = {
        aspectRatio: 2.1,
        plugins: {
            legend: {
                position: 'chartArea',
            },
        }
    }

    const chartOptions = {
        plugins: {
            legend: {
                display: false
            }
        }
    }

    onMounted(loadContainers)
    onMounted(loadLivestreams)
    onMounted(loadUserOrganizationsAsMember)

    return {
      store,
      router,
      currentUser,
      calendarApp,
      isAvailableLivestreams,
      isNonCompletedStatuses,
      chartData,
      pieData,
      pieIsReadyToRender,
      chartOptions,
      doughnutOptions,
      onUserLoaded,
      getSportWatchViewName,
      getAgeFromBirthday,
      getMemberOrganizationsGroupedBySport,
      getUserStats,
      currentActivePlayerId,
      getPlayerStatsSummed,
      chartIsReadyToRender,
      VLY_POINT,
      VLY_ACE,
      VLY_ATTACK,
      VLY_BLOCK,
      VLY_ERROR,
      VLY_SERVICE_ERROR,
      VLY_FOUL
    };
  },
})

</script>

<style>
.component-panel {
    background-color: var(--bright-font-clr);
    padding: 1em;
    border-radius: var(--default-border-rad);
    display: flex;
    gap: 0.5em;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: flex-start;
    min-width: 0;
    min-height: 0;
    overflow: clip;
}
.user-dashboard-wrapper {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-areas: 
    "user graph calendar"
    "user graph calendar"
    "user graph calendar"
    "user graph-two calendar"
    "stats graph-two calendar"
    "stats graph-two calendar"
    "live organizations calendar"
    "live organizations calendar"
    "live organizations calendar";
    gap: 1em;
    grid-template-rows: repeat(9, 1fr);
    grid-template-columns: repeat(3, 1fr);
}

.lives-panel.component-panel {
    grid-area: live;
}
.user-org-panel.component-panel {
    grid-area: organizations;
}
.user-stat-graphs-panel {
    grid-area: graph;
}
.user-stat-graphs-panel.second {
  grid-area: graph-two;
}
.user-stats-panel.component-panel {
    grid-area: stats;
}

.user-board-panel.component-panel > .component-panel-content-wrapper {
  height: 100%;
}

.user-board-panel.component-panel {
    grid-area: user;
}
.user-board-panel.component-panel img {
    object-fit: contain;
}

.user-board__player-info__picture img {
    max-height: calc(var(--badge-item-height) * 2);
    min-width: calc(var(--badge-item-height) * 2);
    border-radius: 50%;
}

.user-calendar-panel.component-panel {
    grid-area: calendar;
    flex-direction: column;
}

.user-calendar-panel.component-panel > .sx-vue-calendar-wrapper {
    min-width: 100%;
    max-height: calc(100% - 2.5rem);
}

.component-panel__title {
  margin: 0;
  width: 100%;
}

.component-panel-content-wrapper {
  height: calc(100% - 2.5rem);
  display: grid;
}

.user-org-panel .component-panel-content-wrapper {
    grid-template: 1fr 1fr / repeat(4, 1fr);
    font-size: 1.15em;
    aspect-ratio: 16 / 6;
    place-self: center;
    margin-inline: auto;
    overflow: clip;
}

.live-panel__livestreams {
    height: calc(100% - 2rem);
    overflow: clip;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.user-board__player-info__picture {
    display: flex;
    align-items: flex-start;
    grid-column: span 1;
}

.user-board__player-info__names, .user-board__player-info__team-name {
  font-weight: 500;
}

.user-board__player-info__names {
  font-size: 1.5em;
  min-height: 1lh;
}

.user-board__player-info__team-name {
  font-size: 1.25em;
}

.user-board__player-wrapper {
    width: 100%;
    max-height: 100%;
    display: grid;
    min-height: 0;
    gap: 1rem;
    min-width: 0;
}

.user-board__player-wrapper.active {
  display: grid;
}

.user-board__player-info {
    display: grid;
    grid-template: repeat(2, 1fr) / repeat(2, 1fr);
    height: 100%;
    gap: 1rem;
    width: 100%;
    min-height: 0;
    min-width: 0;
}

.user-board__player-info__details {
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  grid-column: span 1;
}

.user-board__player-info__team {
    display: none;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0.75rem;
    gap: 0.75rem;
    grid-template: repeat(4, 1fr) / repeat(3, 1fr);
    width: 100%;
}

.user-board__player-info__team.active {
    display: grid;
}

.user-board__player-info__team-logo {
  grid-row: span 2;
}

.user-board__player-info__team-logo img {
  height: calc(var(--badge-item-height) * 0.75);
}

.user-board__player-info__team-name {
  font-size: 1.25em;
  grid-column: span 3;
}

.user-board__player-info__team-number {
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: span 2;
  font-weight: 500;
  width: fit-content;
  padding: 0.5rem;
  border-radius: 100vw;
}

.user-board__player-info__location {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 0.75rem;
}

.user-board__player-info__flag {
    height: 1.5rem;
    border-radius: var(--default-border-rad);
    border: 1px solid var(--select-border);
    overflow: clip;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-board-panel.component-panel .user-board__player-info__flag img {
    height: 160%;
    border-radius: 0;
}


.user-board__player-info__age {
    position: relative;
    padding-left: 4ch;
}
.user-board__player-info__age::before {
    content: 'Age';
    position: absolute;
    left: 0;
    top: 0;
}

.user-board__player-stat-graphs {
    gap: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 2.5rem);
}

.user-board__player-stats {
    display: grid;
    grid-template: repeat(2, 1fr) / repeat(12, 1fr);
    gap: 0.25rem;
    height: calc(100% - 2.5rem);
}

.user-board__player-stat {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    position: relative;
    padding: 0.25rem;
    background-color: var(--background-clr);
    border-radius: var(--default-border-rad);
}
.user-board__player-stat.positive {
  grid-column: span 3;
}
.user-board__player-stat.negative {
  grid-column: span 4;
}

.negative > .user-board__player-stat-val {
  color: var(--btn-error-background-color);
}

.user-board__player-stat-val {
    font-weight: 500;
}
</style>