import GenericDictionary from "@/components/util/GenericDictionary";
import { SPORT_TYPES, Sport } from "@/models/main/CommonTypes";
import DataModel from "@/models/main/DataModel";
import { ORGANIZATION_PRIVACIES, Organization, OrganizationPrivacy } from "@/models/main/Organization";
import { User } from "@/models/main/User";

export default class OrganizationDataModel extends DataModel implements Organization {
    public static readonly MODEL_NAME = "Org"
    private _id: number = 0;
    private _name: string = ''
    private _dateCreated: Date = new Date()
    private _establishedOn: Date = new Date()
    private _isSystemGenerated: boolean = false
    private _language: string = ''
    private _privacy: OrganizationPrivacy = ORGANIZATION_PRIVACIES[0]
    private _sport: Sport = SPORT_TYPES[0]

    owner?: User | undefined;
    ownerId?: number | undefined;
    parentOrganization?: Organization | undefined;
    parentOrganizationId?: number | undefined;
    allowApplication: boolean = false
    autoAcceptApplication: boolean = false
    acceptSubOrganizationMembers: boolean = false
    subOrganizationsCanAccessMembers: boolean = false
    subOrganizations: GenericDictionary<Organization> = {}
    //#region Fields Keys
    static get IDKey() {
        return "id";
    }

    static get nameKey() {
        return "name";
    }

    static get dateCreatedKey() {
        return "dateCreated";
    }

    static get establishedOnKey() {
        return "establishedOn";
    }

    static get isSystemGeneratedKey() {
        return "isSystemGenerated";
    }

    static get languageKey() {
        return "language";
    }

    static get privacyKey() {
        return "privacy";
    }

    static get sportKey() {
        return "sport"
    }

    //#endregion

    // #region accessors/mutations 
    get id() {
        return this._id;
    }

    set id(id) {
        this._id = id;
    }

    get name() {
        return this._name
    }

    set name(name) {
        this._name = name
    }

    get dateCreated() {
        return this._dateCreated;
    }

    set dateCreated(dateCreated) {
        this._dateCreated = dateCreated;
    }

    get establishedOn() {
        return this._establishedOn;
    }

    set establishedOn(establishedOn) {
        this._establishedOn = establishedOn;
    }

    get isSystemGenerated() {
        return this._isSystemGenerated;
    }

    set isSystemGenerated(isSystemGenerated) {
        this._isSystemGenerated = isSystemGenerated;
    }

    get language() {
        return this._language;
    }

    set language(language) {
        this._language = language;
    }

    get privacy() {
        return this._privacy;
    }

    set privacy(privacy) {
        this._privacy = privacy;
    }

    get sport() {
        return this._sport
    }

    set sport(sport) {
        this._sport = sport
    }

    //#endregion

    constructor(modelID?: string, modelData?: any) {
        super();
        this.parseMinifiedData(modelData);
        if(this.id !== undefined) {
            const modelId = DataModel.generateModelID(this, {"id": this.id});
            if(modelId !== false) {
                this.modelID = modelId
            }
        }
    }


    // #region FieldMappings Getters/Setters

    dataModelName() {
        return OrganizationDataModel.MODEL_NAME
    }

    getKeyByMinifiedKey(minKey: string) {
        switch (minKey) {
            case "1":
                return OrganizationDataModel.IDKey;
            case "2":
                return OrganizationDataModel.nameKey;
            case "3":
                return OrganizationDataModel.dateCreatedKey;
            case "4":
                return OrganizationDataModel.establishedOnKey;
            case "5":
                return OrganizationDataModel.isSystemGeneratedKey;
            case "6":
                return OrganizationDataModel.languageKey;
            case "7":
                return OrganizationDataModel.privacyKey;
            case "8":
                return OrganizationDataModel.sportKey;
            default:
                return ""
        }
    }

    getProperty(propertyKey: string) {
        switch (propertyKey) {
            case OrganizationDataModel.IDKey:
                return this.id;
            case OrganizationDataModel.nameKey:
                return this.name;
            case OrganizationDataModel.dateCreatedKey:
                return this.dateCreated;
            case OrganizationDataModel.establishedOnKey:
                return this.establishedOn;
            case OrganizationDataModel.isSystemGeneratedKey:
                return this.isSystemGenerated;
            case OrganizationDataModel.languageKey:
                return this.language;
            case OrganizationDataModel.privacyKey:
                return this.privacy;
            case OrganizationDataModel.sportKey:
                return this.sport;
        }
    }

    setProperty(propertyKey: string, value: any) {
        switch (propertyKey) {
            case OrganizationDataModel.IDKey:
                this.id = value;
                break;
            case OrganizationDataModel.nameKey:
                this.name = value
                break
            case OrganizationDataModel.dateCreatedKey:
                this.dateCreated = value;
                break;
            case OrganizationDataModel.establishedOnKey:
                this.establishedOn = value;
                break;
            case OrganizationDataModel.isSystemGeneratedKey:
                this.isSystemGenerated = value;
                break;
            case OrganizationDataModel.languageKey:
                this.language = value;
                break;
            case OrganizationDataModel.privacyKey:
                this.privacy = value;
                break;
            case OrganizationDataModel.sportKey:
                this.sport = value;
                break;
        }
    }

    //#endregion
}