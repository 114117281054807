import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScheduleXCalendar = _resolveComponent("ScheduleXCalendar")!
  const _component_TheUserDashboard = _resolveComponent("TheUserDashboard")!

  return (_openBlock(), _createBlock(_component_TheUserDashboard, {
    onUserLoad: _ctx.onUserLoaded,
    title: "Dashboard"
  }, {
    default: _withCtx(() => [
      _createElementVNode("a", {
        href: _ctx.downloadLinkUri,
        download: "sync_calendar.ics",
        class: "btn download-btn sync-calendar-btn",
        type: "button",
        title: "Download Calendar"
      }, "Download Calendar", 8, _hoisted_1),
      _createVNode(_component_ScheduleXCalendar, { "calendar-app": _ctx.calendarApp }, null, 8, ["calendar-app"])
    ]),
    _: 1
  }, 8, ["onUserLoad"]))
}