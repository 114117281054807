<template>
    <TheOrganizerDashboard :onUserLoad="onUserLoaded">
        <template #heading>
            <router-link :to="{ name: 'Organization Containers', params: { organizationId: organization?.id } }">
                <div class="page-heading">
                    Tournaments
                </div>
            </router-link>
            <router-link :to="{ name: 'Container Menu', params: { id: containerId } }">
                <div class="page-heading">
                    {{ container?.title }}
                </div>
            </router-link>
            <router-link :to="{ name: 'Container Properties', params: { id: containerId } }">
                <div class="page-heading">
                    Properties
                </div>
            </router-link>
        </template>
        <template #default>
            <div class="organization-properties-wrapper" v-if="container">
                <div class="organization-properties-left">
                    <div class="org-logo-edit-wrapper">
                        <label for="org-logo">
                            <div class="org-default-logo">       
                                <img v-show="!showDefaultUploadLogoImg" :src="imageUrl" :alt="container.title" @error="showDefaultUploadLogoImg = true"/>
                                <img src="/grey-camera.svg" alt="Logo" v-show="showDefaultUploadLogoImg"/>                         
                            </div>
                        </label>
                        <input v-show="false" type="file" id="org-logo" @change="onLogoChange" accept="image/jpeg, image/png, image/webp, image/avif" />
                    </div>
                </div>
                <div class="organization-properties-right">
                    <div class="spz-text-input-group">
                        <label for="containerTitle">Title</label>
                        <input v-model="container.title" type="text" id="containerTitle" placeholder="Container Title" required />
                    </div>
                    <div class="spz-select-group narrow">
                        <SportzoneSelect id="containerPrivacy" label="Privacy"
                            :options="CONTAINER_PRIVACIES" v-model:selectedOption="container.privacy">
                        </SportzoneSelect>
                    </div>
                    <div class="spz-select-group narrow">
                        <SportzoneSelect id="containerStatus" label="Status"
                            :options="CONTAINER_STATUSES" v-model:selectedOption="container.status">
                        </SportzoneSelect>
                    </div>
                    <div class="spz-text-input-group narrow">
                        <label for="dp-containerStart">Start</label>
                        <VueDatePicker uid="containerStart" v-model="container.startTime" time-picker-inline utc="true" />
                    </div>
                    <div class="spz-text-input-group narrow">
                        <label for="dp-containerEnd">End</label>
                        <VueDatePicker uid="containerEnd" v-model="container.endTime" time-picker-inline utc="true" />
                    </div>
                </div>
                <div class="buttons-wrapper">
                    <button class="btn delete-btn" @click="onDeleteClick">Delete</button>
                    <button class="btn save-btn" @click="onSaveClick">{{ saveButtonText }}</button>
                </div>
            </div>
            <SportzoneDialog ref="confirmDeleteDialog" @close="showConfirmDeleteDialog = false"
            :show="showConfirmDeleteDialog">
            <template v-slot:body>
                Are you sure you want to delete {{ container?.title }}?
            </template>
            <template v-slot:footer>
                <button @click="$refs.confirmDeleteDialog.closeModal(), confirmDeleteDialogCallback()"
                    class="btn delete-btn">
                    Confirm
                </button>
                <button @click="$refs.confirmDeleteDialog.closeModal()" class="btn btn-inverse">Cancel</button>
            </template>
        </SportzoneDialog>
        </template>
    </TheOrganizerDashboard>
</template>
<script setup lang="ts">
import { createNamespacedHelpers, useStore } from 'vuex';
import { computed, defineComponent, onMounted, Ref, ref } from 'vue';
import TheOrganizerDashboard from '@/components/main/TheOrganizerDashboard.vue';
import { Organization } from '@/models/main/Organization';
import { key } from '@/store';
import { useRoute, useRouter } from 'vue-router';
import { User } from '@/models/main/User';
import { Container, CONTAINER_PRIVACIES, CONTAINER_STATUSES } from '@/models/main/Container';
import { formatStringDate } from '@/composables/common/Util';
import SportzoneSelect from '@/components/util/SportzoneSelect.vue';
import SportzoneDialog from '@/components/util/SportzoneDialog.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const languages = require('language-list')();

const organization: Ref<Organization | undefined> = ref();
const store = useStore(key);
const route = useRoute();
const router = useRouter();
const organizationId: Ref<number> = ref(parseInt(route.params.organizationId as string));
const containerId: Ref<number> = ref(parseInt(route.params.id as string));
const container: Ref<Container | undefined> = ref()

const onUserLoaded = (user: User) => {
};
const loadOrganization = async () => {
    organization.value = await store.dispatch('organizationStore/getOrganization', organizationId.value);
};

const loadContainer = async () => {
    container.value = await store.dispatch('containerStore/getContainer', containerId.value)
    if(container.value) {
        imageUrl.value = '/api/container/' + container.value.id + '/logo'
    }
}

const saveButtonText: Ref<string> = ref('Save')
const onSaveClick = async () => {
    saveButtonText.value = 'Saving'
    if(containerLogoToUpload.value && container.value) {
        store.dispatch('containerStore/uploadLogo', { containerId: container.value.id, logo: containerLogoToUpload.value })
    }
    await store.dispatch('containerStore/editContainer', { container: container.value })
    saveButtonText.value = 'Save'
}

const showConfirmDeleteDialog = ref(false);
const confirmDeleteDialogCallback = async () => {
    if(container.value) {
        await store.dispatch('containerStore/deleteContainer', container.value.id)
        router.push({ name: 'Organization Containers', params: { organizationId: organization.value?.id } })
    }
}
const onDeleteClick = async() => {
    showConfirmDeleteDialog.value = true
}

const languagesComputed = computed(() => {
    const list = languages.getData()
    console.log(list);
    const result = Object
        .keys(list)
        .map((key) => (list[key]['language']));
    return result;
});

const showDefaultUploadLogoImg = ref(false);
const imageUrl: Ref<any> = ref('')
const containerLogoToUpload: Ref<File | undefined> = ref()
const onLogoChange = (e: Event) => {
    if(e.target) {
        const inputElement = e.target as HTMLInputElement
        if(inputElement.files && inputElement.files.length > 0) {
            const imageFile = inputElement.files[0];
            const tempImageURL = URL.createObjectURL(imageFile)
            containerLogoToUpload.value = imageFile
            imageUrl.value = tempImageURL
            showDefaultUploadLogoImg.value = false;
        }
    }
}

onMounted(loadOrganization)
onMounted(loadContainer)
</script>
<style>
</style>