<template>
    <TheOrganizerDashboard>
        <h1>Snooker Score</h1>
        <div v-if="getCurrentHomeEntity?.id && getCurrentAwayEntity?.id && match" class="snooker-scoreboard-wrapper">
            <div class="snooker-home-player">
                <div class="snooker-player-names snooker-home-player-name">
                    {{ getCurrentHomeEntity?.name }}
                </div>
                <div class="snooker-player-points">
                    {{ calculateEntityPoints(getCurrentHomeEntity.id, match?.events) }}
                </div>
            </div>
            <div class="snooker-frames">
                <div>
                    {{ calculateEntityFrames(getCurrentHomeEntity.id, match?.events) }}
                </div>
                <div>
                    {{ calculateEntityFrames(getCurrentAwayEntity.id, match?.events) }}
                </div>
            </div>
            <div class="snooker-away-player">
                <div class="snooker-player-names snooker-away-player-name">
                    {{ getCurrentAwayEntity?.name }}
                </div>
                <div class="snooker-player-points">
                    {{ calculateEntityPoints(getCurrentAwayEntity.id, match?.events) }}
                </div>
            </div>
        </div>
        <div>
            <div class="snooker-buttons">
                <button @click="onReverseClick" class="match-control-btn default-box" type="button"
                    title="Reverse">Reverse</button>
                <button @click="onShareLinkButtonClicked" class="match-control-btn default-box" type="button"
                    title="Share">Share</button>
                <Modal ref="infoPopUp">
                    <template v-slot:header>
                        <h1>Share</h1>
                    </template>
                    <template v-slot:body>
                        <div class="link-section">
                            {{ shareLink }}
                        </div>
                    </template>
                    <template v-slot:footer>
                        <div>
                            <button @click="infoPopUp.closeModal(), copyToClipboard(shareLink)" class="btn">Copy</button>
                            <button @click="infoPopUp.closeModal()" class="btn btn-inverse">Cancel</button>
                        </div>
                    </template>
                </Modal>
            </div>
            <div class="snooker-game-controls">
                <div>
                    <div class="snooker-home-player-point-types">
                        <PointTypesSet @click="onHomePointBoxClick" />
                    </div>
                    <div class="snooker-home-player-control-actions">
                        <ActionBox @click="onHomeFrameBoxClick">End of Frame</ActionBox>
                        <ActionBox @click="onHomeMatchClick">End of Match</ActionBox>
                        <ActionBox @click="onHomeChangeTurnClick">Change Turn</ActionBox>
                        <ActionBox @click="onHomeChangeBreakingClick">Change Breaking</ActionBox>
                    </div>
                </div>
                <div>
                    <div class="snooker-away-player-point-types">
                        <PointTypesSet @click="onAwayPointBoxClick" />
                    </div>
                    <div class="snooker-away-player-control-actions">
                        <ActionBox @click="onAwayFrameBoxClick">End of Frame</ActionBox>
                        <ActionBox @click="onAwayMatchClick">End of Match</ActionBox>
                        <ActionBox @click="onAwayChangeTurnClick">Change Turn</ActionBox>
                        <ActionBox @click="onAwayChangeBreakingClick">Change Breaking</ActionBox>
                    </div>
                </div>
            </div>
        </div>
    </TheOrganizerDashboard>
</template>
<script lang="ts">
import { defineComponent, onMounted, Ref, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import ActionBox from '@/components/volleyball/ActionBox.vue';
import PointTypesSet from '@/components/snooker/PointTypesSet.vue';
import TheOrganizerDashboard from '@/components/main/TheOrganizerDashboard.vue';
import { SnookerGameControlType, SnookerPointType } from '@/composables/snooker/SnookerEventTypes';
import useCurrentContainerComputeds from '@/composables/snooker/UseCurrentContainerComputeds';
import useInterpretSnookerEvents from '@/composables/snooker/UseInterpretSnookerEvents';
import { Container } from '@/models/main/Container';
import { Event, EventType } from '@/models/main/Event';
import { key } from '@/store';
import Modal from '@/components/main/Modal.vue';
import { copyToClipboard, addAccessKeyQueryParam } from '@/composables/common/Util';
export default defineComponent({
    components: {
        ActionBox,
        PointTypesSet,
        TheOrganizerDashboard,
        Modal,
    },
    setup() {
        const route = useRoute();
        const store = useStore(key);
        const match: Ref<Container | undefined> = ref();
        const matchId: Ref<number> = ref(parseInt(route.params.id as string));
        let accessKey: string | undefined = route.query?.accessKey as string;
        const shareLink: Ref<string | null> = ref(null);
        const infoPopUp: Ref<any> = ref(null);
        const {
            getCurrentHomeEntity,
            getCurrentAwayEntity,
        } = useCurrentContainerComputeds(store, matchId);
        const {
            calculateEntityPoints,
            calculateEntityFrames,
        } = useInterpretSnookerEvents();
        const getMatch = async () => {
            match.value = await store.dispatch('containerStore/getContainer', matchId.value);
        };
        onMounted(getMatch);
        const onReverseClick = async () => {
            if (match.value !== undefined) {
                const result = await store.dispatch('containerStore/removeLastEvent', match.value.id);
                return result;
            }
        };
        const addEvent = async (type: EventType, entityId: number, description: string, playerId?: number): Promise<boolean> => {
            if (getCurrentHomeEntity.value && getCurrentHomeEntity.value && match.value !== undefined && match.value.id !== null) {
                const currentTimeStamp = new Date();
                const event: Event = {
                    type: type,
                    entityId: entityId,
                    description: description,
                    playerId: playerId,
                    containerId: match.value?.id,
                    startTimestamp: currentTimeStamp,
                    endTimestamp: currentTimeStamp,
                };
                const result = await store.dispatch('containerStore/storeEvent', {
                    event,
                    accessKey,
                });
                return result;
            }
            return false;
        };
        const onHomePointBoxClick = async (pointType: SnookerPointType) => {
            if (getCurrentHomeEntity.value?.id !== undefined) {
                await addEvent('Point', getCurrentHomeEntity.value.id, pointType);
            }
        };
        const onHomeFrameBoxClick = async () => {
            if (getCurrentHomeEntity.value?.id !== undefined) {
                const frameControlPoint: SnookerGameControlType = 'Snoo_FE';
                await addEvent('GameControl', getCurrentHomeEntity.value.id, frameControlPoint);
            }
        };
        const onHomeMatchClick = async () => {
            if (getCurrentHomeEntity.value?.id !== undefined) {
                const matchControl: SnookerGameControlType = 'Snoo_ME';
                await addEvent('GameControl', getCurrentHomeEntity.value.id, matchControl);
            }
        };
        const onHomeChangeTurnClick = async () => {
            if (getCurrentHomeEntity.value?.id !== undefined) {
                const turnControl: SnookerGameControlType = 'Snoo_TC';
                await addEvent('GameControl', getCurrentHomeEntity.value.id, turnControl);
            }
        };
        const onHomeChangeBreakingClick = async () => {
            if (getCurrentHomeEntity.value?.id !== undefined) {
                const breakingControl: SnookerGameControlType = 'Snoo_BC';
                await addEvent('GameControl', getCurrentHomeEntity.value.id, breakingControl);
            }
        };
        const onAwayPointBoxClick = async (pointType: SnookerPointType) => {
            if (getCurrentAwayEntity.value?.id !== undefined) {
                await addEvent('Point', getCurrentAwayEntity.value.id, pointType);
            }
        };
        const onAwayFrameBoxClick = async () => {
            if (getCurrentAwayEntity.value?.id !== undefined) {
                const frameControlPoint: SnookerGameControlType = 'Snoo_FE';
                await addEvent('GameControl', getCurrentAwayEntity.value.id, frameControlPoint);
            }
        };
        const onAwayMatchClick = async () => {
            if (getCurrentAwayEntity.value?.id !== undefined) {
                const matchControl: SnookerGameControlType = 'Snoo_ME';
                await addEvent('GameControl', getCurrentAwayEntity.value.id, matchControl);
            }
        };
        const onAwayChangeTurnClick = async () => {
            if (getCurrentAwayEntity.value?.id !== undefined) {
                const turnControl: SnookerGameControlType = 'Snoo_TC';
                await addEvent('GameControl', getCurrentAwayEntity.value.id, turnControl);
            }
        };
        const onAwayChangeBreakingClick = async () => {
            if (getCurrentAwayEntity.value?.id !== undefined) {
                const breakingControl: SnookerGameControlType = 'Snoo_BC';
                await addEvent('GameControl', getCurrentAwayEntity.value.id, breakingControl);
            }
        };
        const onShareLinkButtonClicked = async () => {
            accessKey = await store.dispatch('containerStore/createOperateAccess', matchId.value);
            if (accessKey !== undefined) {
                shareLink.value = addAccessKeyQueryParam(route.query, accessKey);
            }
            infoPopUp.value.openModal();
        };
        return {
            match,
            matchId,
            getCurrentHomeEntity,
            getCurrentAwayEntity,
            accessKey,
            shareLink,
            infoPopUp,
            calculateEntityPoints,
            calculateEntityFrames,
            onReverseClick,
            onHomePointBoxClick,
            onHomeFrameBoxClick,
            onHomeMatchClick,
            onHomeChangeTurnClick,
            onHomeChangeBreakingClick,
            onAwayPointBoxClick,
            onAwayFrameBoxClick,
            onAwayMatchClick,
            onAwayChangeTurnClick,
            onAwayChangeBreakingClick,
            onShareLinkButtonClicked,
            copyToClipboard,
        };
    },
});
</script>
<style>
.snooker-player-names {
    background-color: var(--spz-yellow-clr);
}

.snooker-home-player-name {
    text-align: start;
}

.snooker-player-points {
    background-color: white;
    text-align: center;
}

.snooker-frames {
    background-color: var(--deep-blue-bck-color);
    color: white;
}

.snooker-away-player {
    flex-direction: row-reverse;
}

.snooker-away-player-name {
    text-align: end;
}

.snooker-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1em;
}

.snooker-game-controls {
    display: flex;
}

.snooker-scoreboard-wrapper,
.snooker-home-player,
.snooker-frames,
.snooker-away-player {
    display: flex;
}

.snooker-player-names,
.snooker-player-points {
    color: black;
}

.snooker-player-names,
.snooker-player-points,
.snooker-frames {
    padding: 10px;
}

.snooker-home-player-name,
.snooker-away-player-name {
    width: 25ch;
}

.snooker-home-player-point-types,
.snooker-away-player-point-types {
    margin-top: 1em;
}

.snooker-home-player-control-actions,
.snooker-away-player-control-actions {
    margin-top: 1em;
}
</style>