import { Sport } from "@/models/main/CommonTypes"
import { key } from "@/store";
import { computed, ref, Ref } from "vue"
import { useStore } from "vuex";
import { useDebounceFn } from '@vueuse/core'

export default function useEntityComposables(organizationId: Ref<number | undefined>, seasonId: Ref<number | undefined>, showLoadingIcon: Ref<boolean>) {
    const store = useStore(key);
    const entitySearchTerm: Ref<string | undefined> = ref()
    const defaultSport = 'Sport'
    const sportFilter: Ref<Sport | 'Sport'> = ref(defaultSport);
    
    const getEntities = (searchTerm?: string, organizationId?: number) => {
        return store.getters['entityStore/getEntities'](undefined, searchTerm, undefined, organizationId, undefined)
    }
    const getEntityById = (entityId: number) => {
        return store.getters['entityStore/getEntity']({ entityId })
    }

    const loadEntities = async (organizationId?: number, seasonId?: number) => {
        await store.dispatch('entityStore/getEntities', { sport: sportFilter.value, searchedTeam: entitySearchTerm.value, organizationId: organizationId, seasonId : seasonId });
    };

    const thereIsNextEntitiesPage = computed(() => {
            const nextPage: number | undefined = store.getters['entityStore/getNextPage']
            return  nextPage !== undefined
        }
    )

    const onEntitySearchInput = useDebounceFn(async (event: any) => {
        entitySearchTerm.value = event.target.value;
        if (entitySearchTerm.value !== undefined && (entitySearchTerm.value.length >= 3 || entitySearchTerm.value.length === 0)) {
            showLoadingIcon.value = true
            await loadEntities(organizationId.value, seasonId.value)        
            showLoadingIcon.value = false   
        }
    }, 400, { maxWait: 4000 })

    return {
        entitySearchTerm,
        sportFilter,
        thereIsNextEntitiesPage,
        getEntities,
        getEntityById,
        loadEntities,
        onEntitySearchInput
    }
}