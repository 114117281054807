import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "filters-section" }
const _hoisted_2 = { class: "watch-livestreams-list" }
const _hoisted_3 = { class: "filters-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VolleyballIcon = _resolveComponent("VolleyballIcon")!
  const _component_BowlingIcon = _resolveComponent("BowlingIcon")!
  const _component_BadmintonIcon = _resolveComponent("BadmintonIcon")!
  const _component_TableTennisIcon = _resolveComponent("TableTennisIcon")!
  const _component_TennisIcon = _resolveComponent("TennisIcon")!
  const _component_WatchLivestreamItem = _resolveComponent("WatchLivestreamItem")!
  const _component_LoadingIcon = _resolveComponent("LoadingIcon")!
  const _component_TheOrganizerDashboard = _resolveComponent("TheOrganizerDashboard")!
  const _component_ThePublicHomeLayout = _resolveComponent("ThePublicHomeLayout")!

  return (_ctx.authModule.isAuthenticated)
    ? (_openBlock(), _createBlock(_component_TheOrganizerDashboard, {
        key: 0,
        ref: "mainWrapper",
        onScroll: _ctx.onMainWrapperScroll,
        class: _normalizeClass(["archive", { 'bwl': _ctx.sportFilter === 'Bowling', 'tbltenn': _ctx.sportFilter === 'TableTennis', 'tenn': _ctx.sportFilter === 'Tennis', 'vly': _ctx.sportFilter === 'Volleyball' }])
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "watch-livestreams-list",
            onScroll: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onMainWrapperScroll && _ctx.onMainWrapperScroll(...args)))
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSportFilterClick('Volleyball'))),
                class: "filter-by-sport-btn vly-filter",
                title: "Volleyball Only"
              }, [
                _createVNode(_component_VolleyballIcon, {
                  class: _normalizeClass({'active-filter': _ctx.sportFilter === 'Volleyball'})
                }, null, 8, ["class"])
              ]),
              _createElementVNode("button", {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSportFilterClick('Bowling'))),
                class: _normalizeClass([{'active-filter': _ctx.sportFilter === 'Bowling'}, "filter-by-sport-btn bwl-filter"]),
                title: "Bowling Only"
              }, [
                _createVNode(_component_BowlingIcon)
              ], 2),
              _createElementVNode("button", {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onSportFilterClick('Badminton'))),
                class: _normalizeClass([{'active-filter': _ctx.sportFilter === 'Badminton'}, "filter-by-sport-btn badminton-filter"]),
                title: "Badminton Only"
              }, [
                _createVNode(_component_BadmintonIcon)
              ], 2),
              _createElementVNode("button", {
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onSportFilterClick('TableTennis'))),
                class: _normalizeClass([{'active-filter': _ctx.sportFilter === 'TableTennis'}, "filter-by-sport-btn tbltenn-filter"]),
                title: "Table Tennis Only"
              }, [
                _createVNode(_component_TableTennisIcon)
              ], 2),
              _createElementVNode("button", {
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onSportFilterClick('Tennis'))),
                class: _normalizeClass([{'active-filter': _ctx.sportFilter === 'Tennis'}, "filter-by-sport-btn tenn-filter"]),
                title: "Tennis Only"
              }, [
                _createVNode(_component_TennisIcon)
              ], 2)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getLivestreams({statuses: ['Completed', 'Stopping'], sport: _ctx.sportFilter}), (livestream) => {
              return (_openBlock(), _createBlock(_component_WatchLivestreamItem, {
                key: livestream.id,
                livestream: livestream
              }, null, 8, ["livestream"]))
            }), 128)),
            _withDirectives(_createVNode(_component_LoadingIcon, null, null, 512), [
              [_vShow, _ctx.showLoadingIcon]
            ])
          ], 32)
        ]),
        _: 1
      }, 8, ["onScroll", "class"]))
    : (_openBlock(), _createBlock(_component_ThePublicHomeLayout, {
        key: 1,
        ref: "mainWrapper",
        onScroll: _ctx.onMainWrapperScroll,
        class: _normalizeClass(["archive", { 'bwl': _ctx.sportFilter === 'Bowling', 'tbltenn': _ctx.sportFilter === 'TableTennis', 'tenn': _ctx.sportFilter === 'Tennis', 'vly': _ctx.sportFilter === 'Volleyball' }])
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("button", {
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onSportFilterClick('Volleyball'))),
                class: "filter-by-sport-btn vly-filter",
                title: "Volleyball Only"
              }, [
                _createVNode(_component_VolleyballIcon, {
                  class: _normalizeClass({'active-filter': _ctx.sportFilter === 'Volleyball'})
                }, null, 8, ["class"])
              ]),
              _createElementVNode("button", {
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onSportFilterClick('Bowling'))),
                class: _normalizeClass([{'active-filter': _ctx.sportFilter === 'Bowling'}, "filter-by-sport-btn bwl-filter"]),
                title: "Bowling Only"
              }, [
                _createVNode(_component_BowlingIcon)
              ], 2),
              _createElementVNode("button", {
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onSportFilterClick('Badminton'))),
                class: _normalizeClass([{'active-filter': _ctx.sportFilter === 'Badminton'}, "filter-by-sport-btn badminton-filter"]),
                title: "Badminton Only"
              }, [
                _createVNode(_component_BadmintonIcon)
              ], 2),
              _createElementVNode("button", {
                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.onSportFilterClick('TableTennis'))),
                class: _normalizeClass([{'active-filter': _ctx.sportFilter === 'TableTennis'}, "filter-by-sport-btn tbltenn-filter"]),
                title: "Table Tennis Only"
              }, [
                _createVNode(_component_TableTennisIcon)
              ], 2),
              _createElementVNode("button", {
                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.onSportFilterClick('Tennis'))),
                class: _normalizeClass([{'active-filter': _ctx.sportFilter === 'Tennis'}, "filter-by-sport-btn tenn-filter"]),
                title: "Tennis Only"
              }, [
                _createVNode(_component_TennisIcon)
              ], 2)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getLivestreams({statuses: ['Completed', 'Stopping'], sport: _ctx.sportFilter, privacy: 'Public'}), (livestream) => {
              return (_openBlock(), _createBlock(_component_WatchLivestreamItem, {
                key: livestream.id,
                livestream: livestream
              }, null, 8, ["livestream"]))
            }), 128)),
            _withDirectives(_createVNode(_component_LoadingIcon, null, null, 512), [
              [_vShow, _ctx.showLoadingIcon]
            ])
          ])
        ]),
        _: 1
      }, 8, ["onScroll", "class"]))
}