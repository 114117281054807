<template>
<div class="user-preferrences-wizard">
    <div class="user-preferrence" :class="{'visible' : currentStep === 1 }" >
        <div class="user-preferrence-heading">Which sports are you interested in?</div>
        <div class="user-preferrence-options">
            <div class="user-preferrence-option" v-for="sport in SPORT_TYPES" :key="sport" :class=" { 'selected' : userPreferedSports[sport], 'no-icon' : !sportHasIcon(sport)}" 
            @click=onPreferedSportClick(sport)>
                <div class="user-preference-option-icon">
                    <SportIcon :sport="sport" />
                </div>
                <div class="user-preference-option-title">{{ sport }}</div>
            </div>
        </div>
    </div>
    <div class="user-preferrence" :class="{'visible' : currentStep === 2 }">
        <div class="user-preferrence-heading">Please tell us more about yourself</div>
        <div class="user-preferrence-form">
            <div class="spz-img-input-group user-preference__profile-pic">
                <label for="profile-picture">
                    <img :src="imageUrl" />
                </label>
                <input v-show="false" type="file" id="profile-picture" @change="onProfileImageChange" accept="image/jpeg, image/png, image/webp, image/avif" />
            </div>
            <div class="spz-text-input-group">
                <label for="dp-user-birthday">Birthday</label>
                <VueDatePicker uid="user-birthday" v-model="localUser.birthday" time-picker-inline utc="true" :enable-time-picker="false" />
            </div>
            <div class="spz-multi-toggle-group">
                <div class="spz-multi-toggle-name">Sex</div>
                <div class="spz-multi-toggle_options">
                    <div class="spz-multi-toggle_option">
                        <input type="radio" id="sex-female" value="Female" name="sex" v-model="localUser.sex"/>
                        <label for="sex-female">Female</label>
                    </div>
                    <div class="spz-multi-toggle_option">
                        <input type="radio" id="sex-male" value="Male"  name="sex" v-model="localUser.sex"/>
                        <label for="sex-male">Male</label>
                    </div>
                    <div class="spz-multi-toggle_option">
                        <input type="radio" id="sex-other" value="Other" name="sex" v-model="localUser.sex"/>
                        <label for="sex-other">Other</label>    
                    </div>
                </div>
            </div>
            <div class="spz-text-input-group">
                <label for="user-phone-number">Phone number</label>
                <input id="user-phone-number" type="text" v-model="localUser.phoneNumber"/>
            </div>
            <div class="spz-select-group">
                <label for="user-nationality">Nationality</label>
                <SportzoneSelect  id="user-nationality"
                    :options="['Nationality', ...nationalitiesComputed]" v-model:selectedOption="localUser.nationality">
                </SportzoneSelect>
            </div>
            <div class="spz-text-input-group">
                <label for="user-city">City</label>
                <input id="user-city" type="text" v-model="localUser.city"/>
            </div>
        </div>
    </div>
    <div class="wizard-action-btns">
        <button class="btn btn-inverse" title="Back" @click="processStepHandler(currentStep - 1)" v-show="currentStep > 1">Back</button>
        <button class="btn btn-inverse" title="Next" @click="processStepHandler(currentStep + 1)" v-show="currentStep < totalSteps">Next</button>
        <button class="btn accept-btn" title="Done" @click="onPreferencesWizardDone" v-show="currentStep === totalSteps">Done</button>
    </div>
</div>
</template>
<script lang="ts">
import { PropType, Ref, computed, defineComponent, onMounted, onUpdated, ref, watch } from 'vue';
import { User } from '@/models/main/User';
import { SPORT_TYPES, Sport } from '@/models/main/CommonTypes';
import SportIcon from '@/components/main/SportIcon.vue';
import GenericDictionary from '../util/GenericDictionary';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { useRouter } from 'vue-router';
import SportzoneSelect from '@/components/util/SportzoneSelect.vue';
import { key } from '@/store';
import { useStore } from 'vuex';
const nationalities = require('i18n-nationality');
nationalities.registerLocale(require('i18n-nationality/langs/en.json'));

const UserPreferences = defineComponent({
    props: {
        user: {
            type: Object as PropType<User>,
            required: true
        },
    },
    components: {
        SportIcon,
        VueDatePicker,
        SportzoneSelect,
    },
    setup(props, { emit }) {
        const router = useRouter();
        const store = useStore(key);
        const currentStep = ref(1)
        const totalSteps = 2
        const basicModal: any = ref(null)
        const localUser: Ref<User> = ref(props.user)
        watch(props.user, (newUser) => {
            localUser.value = newUser // TODO: not sure if this works fine
        })
        const stepValidators = [] as Array<Function>;
        const postStepActionExecutors = [] as Array<Function>;
        const preStepActionExecutors = [] as Array<Function>;
        const processStepHandler = (nextStep: number) => {
            currentStep.value = nextStep
            // if(nextStep > actionProcessStep.value) {
            //     const isValid = props.stepValidators && stepValidators[actionProcessStep.value - 1]()
            //     if(isValid) {
            //         if(props.postStepActionExecutors) {
            //             postStepActionExecutors[actionProcessStep.value - 1]()
            //         }
                    
            //         actionProcessStep.value = nextStep
                    
            //         if(props.preStepActionExecutors) {
            //             preStepActionExecutors[nextStep - 1]()
            //         }
            //     }
            // } else {
            //     actionProcessStep.value = nextStep
            // }
        }

        const nationalitiesComputed = computed(() => {
            const list = nationalities.getNames('en');
            const result = Object
                .keys(list)
                .map((key) => (list[key]));
            return result;
        });

        const userPreferedSportsDictionary: GenericDictionary<Sport> = {}
        if(props.user.sportPreferences) {
            for(let i in props.user.sportPreferences) {
                const sportPreference = props.user.sportPreferences[i]
                userPreferedSportsDictionary[sportPreference.sport] = sportPreference.sport
            }
        }

        const userPreferedSports: Ref<GenericDictionary<Sport>> = ref(userPreferedSportsDictionary) 
        let sportNames: string[] = []
        const onPreferedSportClick = (sport: Sport) => {
            sportNames = Object.keys(userPreferedSports.value)
            if (Object.prototype.hasOwnProperty.call(userPreferedSports.value, sport)) {
                const indexToRemove = sportNames.indexOf(sport)
                sportNames.splice(indexToRemove, 1)
                delete userPreferedSports.value[sport]
            } else {
                sportNames.push(sport)
                userPreferedSports.value[sport] = sport
            }
        }

        const onPreferencesWizardDone = async () => {
            const editPromises: Promise<any>[] = []
            editPromises.push(store.dispatch('userStore/updateUserSportPreferences', {
                sportNames: sportNames,
                defaultSport: sportNames[0]
            }))
            editPromises.push(store.dispatch('userStore/editUser', localUser.value))
            if (profilePictureToUpload.value) {
                editPromises.push(store.dispatch('userStore/uploadProfilePicture', {
                    userId: localUser.value.id,
                    profilePicture: profilePictureToUpload.value
                }))
            }
            const [
                userSportPreferences,
                user
            ] = await Promise.all(editPromises)
            localUser.value.sportPreferences = userSportPreferences
            router.push({ name: 'User Dashboard' })
        }

        const defaultProfilePicPath = '/default-profile-pic.svg'
        const imageUrl: Ref<any> = ref(defaultProfilePicPath)
        const profilePictureToUpload: Ref<File | undefined> = ref()
        const onProfileImageChange = (e: Event) => {
            if(e.target) {
                const inputElement = e.target as HTMLInputElement
                if(inputElement.files && inputElement.files.length > 0) {
                    const imageFile = inputElement.files[0];
                    const tempImageURL = URL.createObjectURL(imageFile)
                    imageUrl.value = tempImageURL
                    profilePictureToUpload.value = imageFile
                }
            }
        }

        onMounted(() => {
            if(props.user) {
                imageUrl.value = `/api/user/${props.user.id}/picture`
            } else {
                imageUrl.value = defaultProfilePicPath
            }
        })

        const sportHasIcon = (sport: Sport) => {
            switch (sport) {
                case "Volleyball":
                case "Bowling":
                case "Badminton":
                case "TableTennis":
                case "Tennis":
                    return true;
                default:
                    return false;
            }
        }

        return {
            processStepHandler,
            onPreferedSportClick,
            onPreferencesWizardDone,
            onProfileImageChange,
            sportHasIcon,
            nationalitiesComputed,
            userPreferedSports,
            currentStep,
            totalSteps,
            SPORT_TYPES,
            localUser,
            imageUrl,
        }
    }
})

export default UserPreferences
</script>
<style>
.user-preferrences-wizard {
    display: flex;
    width: 100%;
    flex-direction: column;
    --wizard-content-max-width: clamp(20em, 50%, min(100vw, 32em));
}
.user-preferrence {
    display: none;
    width: 100%;
    flex-direction: column;
    max-width: var(--wizard-content-max-width);
}
.user-preferrence.visible {
    display: flex;
}
.user-preferrence-heading {
    font-size: 1.5em;
    font-weight: 500;
    margin-bottom: 1em;
}
.user-preferrence-options {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 1rem;
}

.user-preferrence-option {
    display: grid;
    border-radius: var(--default-border-rad);
    cursor: pointer;
    background-color: var(--bright-font-clr);
    grid-template-areas: "badge";
    width: 8rem;
    aspect-ratio: 1;
    transition: transform ease-in 200ms;
}

.user-preferrence-option:hover {
  transform: scale(1.1);
}

.user-preferrence-option.selected {
    background-color: var(--main-font-clr);
    color: var(--bright-font-clr);
}

.user-preferrence-option.selected.no-icon >  .user-preference-option-title {
    color: var(--bright-font-clr);
    background-color: transparent;
}

.user-preference-option-icon {
    grid-area: badge;
    max-width: 100%;
    max-height: 100%;
    min-height: 0;
    padding: 0.5rem;
}

.user-preference-option-icon > svg, .user-preference-option-icon > img {
  max-width: 100%;
  max-height: 100%;
  --_icon-fill: currentColor;
}

.user-preference-option-title {
  grid-area: badge;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  opacity: 0;
  padding: 0.5rem;
  max-width: 100%;
  max-height: 100%;
  min-height: 0;
  min-width: 0;
}

.user-preferrence-option:hover > .user-preference-option-title, 
.user-preferrence-option.no-icon > .user-preference-option-title, 
.user-preferrence-option.selected.no-icon:hover > .user-preference-option-title {
  opacity: 1;
  color: var(--main-font-clr);
  background-color: rgba(255, 255, 255, 0.9);
}

.wizard-action-btns {
    display: flex;
    margin-top: 1em;
    max-width: var(--wizard-content-max-width);
    justify-content: flex-end;
}

/* Multi option toggle */
.spz-multi-toggle-group {
    --_toggle-border-radius: 2em;
}

.spz-multi-toggle-group input {
  visibility: hidden;
  position: absolute;
}

.spz-multi-toggle_option label {
  width: 100%;
  padding: 0.5em 1em;
  position: relative;
  background-color: var(--bright-font-clr);
  text-align: center;
  cursor: pointer;
}

.spz-multi-toggle_option input:checked + label, .spz-multi-toggle_option label:hover, .spz-multi-toggle_option label:focus {
  background-color: var(--main-font-clr);
  color: var(--bright-font-clr);
}


.spz-multi-toggle_options {
    display: flex;
    width: fit-content;
}
.spz-multi-toggle_option {
    min-width: 5em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--main-font-clr);
    font-weight: 500;
    overflow: hidden;
}

.spz-multi-toggle_option:first-child {
    border-bottom-left-radius: var(--_toggle-border-radius);
    border-top-left-radius: var(--_toggle-border-radius);
}

.spz-multi-toggle_option:last-child {
    border-bottom-right-radius: var(--_toggle-border-radius);
    border-top-right-radius: var(--_toggle-border-radius);
}

.spz-img-input-group.user-preference__profile-pic {
  height: calc(var(--badge-item-height) * 2);
}

</style>