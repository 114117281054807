<template>
<div ref="watchViewWrapper" class="bowling-watch-wrapper" :class="{'fallback-fullscreen': isFallbackFullScreenMode, 'fullscreen' : isFullScreenMode}">
    <div v-if="!livestream" class="loading-livestream-icon-div">
        <LoadingIcon />
    </div>
    <div class="live-central-panel">
        <LivePlayer :startPlayingAt="0" :livestream="livestream" @playerDurationChange="playerDurationChange" @fullscreenToggle="onFullScreenToggle" v-if="livestream">
            <FramesPanel :fromLane="bowlzoneLivestream?.camera?.fromLane" :toLane="bowlzoneLivestream?.camera?.toLane" :squadId="bowlzoneLivestream?.squadID" :phaseRanking="phaseRankingDataModel" />
        </LivePlayer>
        <div class="main-scoreboard-wrapper hide-on-full">
        </div>
    </div>

    <div class="live-right-panel hide-on-full">
        <RankingInfoPanel :phaseRanking="phaseRankingDataModel" />
        <PhaseRankingPanel :phaseRanking="phaseRankingDataModel" />
        <SmallFramesPanel v-if="phaseRankingDataModel" :fromLane="bowlzoneLivestream?.camera?.fromLane" :toLane="bowlzoneLivestream?.camera?.toLane" :squadId="bowlzoneLivestream?.squadID" :phaseRanking="phaseRankingDataModel" />
    </div>
</div>
</template>
<script lang="ts">
import { defineComponent } from "@vue/runtime-core";
import LivePlayer from "@/components/main/LivePlayer.vue";
import RankingInfoPanel from "@/components/bowling/RankingInfoPanel.vue";
import PhaseRankingPanel from "@/components/bowling/PhaseRankingPanel.vue"; 
import FramesPanel from "@/components/bowling/FramesPanel.vue"; 
import SmallFramesPanel from "@/components/bowling/SmallFramesPanel.vue"; 
import DataManager from "@/components/util/DataManager"
import { onMounted, Ref, ref } from "vue";
import LivestreamDataModel from "@/composables/dataModels/bowling/LivestreamDataModel";
import DataModel from "@/models/main/DataModel";
import { useRoute } from "vue-router";
import { useStore, createNamespacedHelpers } from "vuex";
import { key } from '@/store'
import PhaseRankingModel from "@/composables/dataModels/bowling/PhaseRankingDataModel";
import LiveDataModel from "@/composables/dataModels/LiveDataModel";
import LoadingIcon from '@/components/util/LoadingIcon.vue';

const { mapGetters } = createNamespacedHelpers('containerStore')

export default defineComponent ({
    components: {
        LivePlayer,
        RankingInfoPanel,
        PhaseRankingPanel,
        FramesPanel,
        SmallFramesPanel,
        LoadingIcon,
    },
    computed: {
        ...mapGetters([
            'getContainer',
            'getHomeEntity',
            'getAwayEntity',
        ]),
    },
    setup() {
        const route = useRoute()
        const store = useStore(key)
        const onLiveModelChange = (live: LivestreamDataModel) => {
            if(live.phases !== phaseRankingDataModel.value?.phaseID) { //TODO: FIXME
                //should change phaseRankingDataModel! unsubscribe previous subscribe to new one
            }
        };


        //const chosenGame: Ref<number> = ref(0)

        const onContainerModelChange = (updatedContainer: PhaseRankingModel) => {
            phaseRankingDataModel.value = updatedContainer

            const testContainer: PhaseRankingModel = new PhaseRankingModel () 
            testContainer.phaseID = updatedContainer.phaseID
            testContainer.entities = updatedContainer.entities
            testContainer.details = updatedContainer.details
            testContainer.cut = updatedContainer.cut
            testContainer.isPredicted = updatedContainer.isPredicted
            // store.commit('containerStore/setContainer', testContainer)
        }

        const phaseRankingDataModel: Ref<PhaseRankingModel | null> = ref(null)
        const currentContainerId: Ref<number> = ref(0)
        const livestream: Ref<LiveDataModel | null> = ref(null)
        const bowlzoneLivestream: Ref<LivestreamDataModel | null> = ref(null)
        const liveId = parseInt(route.params.id as string);
        //const isSpoiledModeOn: Ref<boolean> = ref(route.query.unspoiled !== "1")
        const onDataManagerInstanced = async () => {
            console.info("Data manager has been loaded!")
        };
        

        let dataManagerInstance: DataManager | null = null
        const initDataManager = async () => {
            dataManagerInstance = DataManager.instance(onDataManagerInstanced);
                        if (dataManagerInstance !== null) {
                dataManagerInstance.modelUrl = "https://api.bowlzone.atia.com/bowling/squad/getmodel";


                const liveDataModelID = DataModel
                .generateModelID(new LivestreamDataModel(), {"id": liveId}) //TODO this is bad
                if(liveDataModelID === false) {
                    console.error("Could not generate proper Model ID!")
                    return null;
                }

                await dataManagerInstance.subscribeToDataModel(
                    liveDataModelID,
                    onLiveModelChange,
                    false
                );
                const subscribedLiveDataModel = await dataManagerInstance.dataModels[liveDataModelID]
                bowlzoneLivestream.value = subscribedLiveDataModel as LivestreamDataModel
                const sportzoneLivestream = new LiveDataModel()
                sportzoneLivestream.id = bowlzoneLivestream.value.ID
                sportzoneLivestream.title = bowlzoneLivestream.value.name
                sportzoneLivestream.streamLink = "https://www.youtube.com/embed/" + bowlzoneLivestream.value.streamID + "?enablejsapi=1&playsinline=1&autoplay=1"
                sportzoneLivestream.status = bowlzoneLivestream.value.squadStatus
                sportzoneLivestream.startTime = bowlzoneLivestream.value.squadDateStarted

                livestream.value = sportzoneLivestream
                if(bowlzoneLivestream.value?.phases && bowlzoneLivestream.value.phases.length > 0) {
                    const containerDataModelId = DataModel
                        .generateModelID(new PhaseRankingModel(), {"id": bowlzoneLivestream.value.phases[0][LivestreamDataModel.phaseIDKey]}) //TODO this is bad 
                    if(containerDataModelId === false) {
                        console.error("Could not generate proper Model ID!")
                        return null;
                    }

                    await dataManagerInstance.subscribeToDataModel(
                        containerDataModelId,
                        onContainerModelChange,
                        false
                    );
                    const subscribedContainerDataModel = await dataManagerInstance.dataModels[containerDataModelId]
                    console.log(subscribedContainerDataModel)
                    phaseRankingDataModel.value = subscribedContainerDataModel as PhaseRankingModel
                    if(phaseRankingDataModel.value.phaseID !== undefined) {
                        //const copiedContainer = deepCopy(subscribedContainerDataModel)
                        //store.commit('containerStore/setContainer', subscribedContainerDataModel)
                        currentContainerId.value = phaseRankingDataModel.value.phaseID
                    }
                } else {
                    //isFullScreenMode.value = true
                }
            } else {
                console.error("Data manager is not initalized")
            }
        };

        onMounted(initDataManager);

        const playerCurrentUnixTime: Ref<number> = ref(0)
        const isFullScreenMode: Ref<boolean> = ref(false)
        const isFallbackFullScreenMode: Ref<boolean> = ref(false)
        const onFullScreenToggle = (isFullScreen: boolean, isFallbackFullScreen: boolean) => {
            isFullScreenMode.value = isFullScreen
            isFallbackFullScreenMode.value = isFallbackFullScreen
        }

        //const seekPlayerTo: Ref<number> = ref(0)
        const advanceSecs = 8

        const watchViewWrapper: Ref<any> = ref()
        const isFallbackFullScreen: Ref<boolean> = ref(false)
        const goWatchViewFullscreen = () => {
            const currentDoc: any = document
            if(currentDoc.fullscreenElement === undefined &&
                currentDoc.webkitFullscreenElement === undefined &&
                currentDoc.mozFullScreenElement === undefined &&
                currentDoc.msFullscreenElement === undefined) { //fallback for not supported devices
                isFallbackFullScreen.value = true
            }

            if (
                currentDoc.fullscreenElement ||
                currentDoc.webkitFullscreenElement ||
                currentDoc.mozFullScreenElement ||
                currentDoc.msFullscreenElement
            ) {
                if (currentDoc.exitFullscreen) {
                    currentDoc.exitFullscreen();
                } else if (currentDoc.mozCancelFullScreen) {
                    currentDoc.mozCancelFullScreen();
                } else if (currentDoc.webkitExitFullscreen) {
                    currentDoc.webkitExitFullscreen();
                } else if (currentDoc.msExitFullscreen) {
                    currentDoc.msExitFullscreen();
                }
            } else {
                if (watchViewWrapper.value.requestFullscreen) {
                    watchViewWrapper.value.requestFullscreen();
                } else if (watchViewWrapper.value.mozRequestFullScreen) {
                    watchViewWrapper.value.mozRequestFullScreen();
                } else if (watchViewWrapper.value.webkitRequestFullscreen) {
                    watchViewWrapper.value.webkitRequestFullscreen((Element as any).ALLOW_KEYBOARD_INPUT);
                } else if (watchViewWrapper.value.msRequestFullscreen) {
                    watchViewWrapper.value.msRequestFullscreen();
                }
            }
        }


        const playerDurationChange = (currentDuration: number) => {
            let liveStarttime = 0
            // if(livestream.value?.streamActualStartTime) { // TODO: Fixme
            //     liveStarttime = new Date(livestream.value.streamActualStartTime).getTime() / 1000 
            // }
            
            playerCurrentUnixTime.value = currentDuration + liveStarttime - advanceSecs
        } 

        return {
            store,
            livestream,
            bowlzoneLivestream,
            phaseRankingDataModel,
            currentContainerId,
            isFullScreenMode,
            isFallbackFullScreenMode,
            watchViewWrapper,
            goWatchViewFullscreen,
            playerDurationChange,
            onFullScreenToggle,
        }
    }
})
</script>
<style>
.bowling-watch-wrapper {
 --vly-watch-min-width:640px;
 --vly-watch-min-height:105vh;
 width:var(--vly-watch-min-width);
 height:var(--vly-watch-min-height);
 color:var(--light-homepage-color);
 --player-def-width:1584px;
  --player-def-height: 891px;
 overflow-y:scroll;
 scrollbar-width:none;
 background-color: var(--darker-bckgrnd-color);
}



.bowling-watch-wrapper::-webkit-scrollbar {
    display: none;
}



.bowling-watch-wrapper::after {
  /* background-image: url(/live-background.svg); */
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
}

.bowling-watch-wrapper.fullscreen.fallback-fullscreen .stream-player {
  width: var(--vly-watch-min-width);
  height: var(--vly-watch-min-height);
}

/* SCOREBOARD */

.bowling-watch-wrapper .team-name {
    color: inherit;
    font-size: 1.1em;
    overflow: hidden;
    width: calc(100% - 2.5ch);
}

.bowling-watch-wrapper .live-central-panel {
  width: calc(100% - 48.5ch - 16px);
}

.bowling-watch-wrapper .live-right-panel {
 width:calc(48.5ch + 16px);
}

@media screen and (min-height: 450px) { 
    .bowling-watch-wrapper {
        --vly-watch-min-height: 100vh;
    }
}

@media screen and (min-width: 640px) {
    .bowling-watch-wrapper {
        --vly-watch-min-width:100vw;
    }
}
</style>