import { createStore, createLogger, Store } from 'vuex'
import  userStore from './modules/main/UserStore'
import containerStore from './modules/main/ContainerStore'
import providerUserStore from './modules/main/ProviderUserStore'
import stationStore from './modules/main/StationStore'
import livestreamStore from './modules/main/LivestreamStore'
import livestreamPublicStore from './modules/main/LivestreamPublicStore'
import channelStore from './modules/main/ChannelStore'
import entityStore from '@/store/modules/main/EntityStore'
import playerStore from '@/store/modules/main/PlayerStore'

import { InjectionKey } from 'vue';
import pointOfInterestStore from '@/store/modules/main/PointOfInterestStore';
import advertisementStore from '@/store/modules/main/AdvertisementStore';
import organizationStore from './modules/main/OrganizationStore'
import organizationPublicStore from './modules/main/OrganizationPublicStore'
import organizationMemberStore from './modules/main/OrganizationMemberStore'
import organizationInvitationStore from './modules/main/OrganizationInvitationStore'
import organizationApplicationStore from './modules/main/OrganizationApplicationStore'
import organizationManagerStore from './modules/main/OrganizationManagerStore'
import userNotificationStore from './modules/main/UserNotificationStore'
import containerEventStore from './modules/main/ContainerEventStore'
import memberOrganizationStore from './modules/main/MemberOrganizationStore'
import userStatStore from './modules/main/UserStatStore'
import followerOrganizationStore from './modules/main/FollowerOrganizationStore'
import phaseStore from './modules/main/PhaseStore'
import organizationSeasonStore from './modules/main/OrganizationSeasonStore'
const debug = false;//process.env.NODE_ENV !== 'production'

export interface IRootState {
    root: boolean;
    version: string;
  }

export const key: InjectionKey<Store<IRootState>> = Symbol()

export const store = createStore<IRootState>({
    modules: {
        userStore,
        entityStore,
        playerStore,
        containerStore,
        providerUserStore,
        stationStore,
        livestreamStore,
        livestreamPublicStore,
        channelStore,
        pointOfInterestStore,
        advertisementStore,
        organizationStore,
        organizationMemberStore,
        organizationInvitationStore,
        organizationApplicationStore,
        organizationManagerStore,
        organizationPublicStore,
        userNotificationStore,
        containerEventStore,
        memberOrganizationStore,
        userStatStore,
        followerOrganizationStore,
        phaseStore,
        organizationSeasonStore
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
  })