import { Organization } from "./Organization"
import { User } from "./User"

export const APPLICATION_STATUSES = ["Pending", "Accepted", "Rejected"]
export type ApplicationStatus = typeof APPLICATION_STATUSES[number]

export interface OrganizationApplication {
    id: number,
    organizationId: number,
    organization?: Organization,
    userId: number,
    user?: User,
    dateApplied: Date,
    status: ApplicationStatus,
    message: string | null,
    rejectMessage: string | null,
}