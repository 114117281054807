<template>
    <VolleyballIcon v-if="sport === 'Volleyball'" />
    <BowlingIcon  v-else-if="sport === 'Bowling'"/>
    <BadmintonIcon v-else-if="sport === 'Badminton'"/>
    <TableTennisIcon v-else-if="sport === 'TableTennis'"/>
    <TennisIcon v-else-if="sport === 'Tennis'"/>
</template>
<script lang="ts">
import { PropType, defineComponent, ref } from 'vue';
import { Sport } from '@/models/main/CommonTypes';
import BowlingIcon from '@/components/bowling/BowlingIcon.vue'
import VolleyballIcon from '@/components/volleyball/VolleyballIcon.vue'
import TableTennisIcon from '@/components/tableTennis/TableTennisIcon.vue'
import BadmintonIcon from "@/components/badminton/BadmintonIcon.vue"
import TennisIcon from '@/components/tennis/TennisIcon.vue'
import LoadingIcon from '@/components/util/LoadingIcon.vue'

const SportIcon = defineComponent({
    props: {
        sport: {
            type: String as PropType<Sport>,
            required: true
        },
    },
    components: {
        BowlingIcon,
        VolleyballIcon,
        LoadingIcon,
        TableTennisIcon,
        TennisIcon,
        BadmintonIcon,
    },
    setup(props, { emit }) {
       
        return {
        }
    }
})

export default SportIcon
</script>