import { useStore } from "vuex";
import { key } from '@/store'
import { IdentityProvider } from "@/models/main/ProviderUser";
import { useRouter } from "vue-router";

const facebookAppId = process.env.VUE_APP_FB_API_ID ?? '242854741243170'

export function initFacebookSdk() {
    return new Promise(resolve => {
        window.fbAsyncInit = function() {
            window.FB.init({
            appId      : facebookAppId,
            cookie     : true,
            xfbml      : true,
            version    : process.env.VUE_APP_FB_API_VERSION ?? "v16.0"
            });
    
            window.FB.AppEvents.logPageView();   
            window.FB.getLoginStatus(function({ authResponse }) {
                if (authResponse) {
                    console.log(authResponse.accessToken)
                    resolve(authResponse)
                } else {
                    resolve(authResponse);
                }
            });
        };

        (function(d, s, id){
            const fjs = d.getElementsByTagName(s)[0];
            let js: HTMLScriptElement = d.getElementsByTagName(s)[0] as HTMLScriptElement;
            if(fjs && js) {
            if (d.getElementById(id)) {return;}
            js = d.createElement(s) as HTMLScriptElement; 
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode?.insertBefore(js, fjs);
        }
        }(document, 'script', 'facebook-jssdk'));
    })
}

