<template>
    <div>
        <h1 id="numpadHeading">NUMPAD</h1>
        <div id="numpadDivs">
            <div id="numpadInput">{{ numpadInputValue }}</div>
            <div id="firstLineDiv">
                <button @click="onNumberClick('9')" class="numpad-button digit">9</button>
                <button @click="onNumberClick('8')" class="numpad-button digit">8</button>
                <button @click="onNumberClick('7')" class="numpad-button digit">7</button>
            </div>
            <div id="secondLineDiv">
                <button @click="onNumberClick('6')" class="numpad-button digit">6</button>
                <button @click="onNumberClick('5')" class="numpad-button digit">5</button>
                <button @click="onNumberClick('4')" class="numpad-button digit">4</button>
            </div>
            <div id="thirdLineDiv">
                <button @click="onNumberClick('3')" class="numpad-button digit">3</button>
                <button @click="onNumberClick('2')" class="numpad-button digit">2</button>
                <button @click="onNumberClick('1')" class="numpad-button digit">1</button>
            </div>
            <div id="fourthLineDiv">
                <button @click="onNumberClick('0')" class="numpad-button digit">0</button>
                <button @click="onCommaClick(',')" class="numpad-button option">,</button>
                <button @click="onDeleteClick" class="numpad-button option">Delete</button>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, Ref, ref } from 'vue';
const NumpadModal = defineComponent({
    emits: ['numpadValueChange'],
    setup(props, { emit }) {
        let numpadInputValue: Ref<string> = ref('');
        const onNumberClick = (number: string) => {
            numpadInputValue.value += number;
            emit('numpadValueChange', numpadInputValue.value);
        };
        const onCommaClick = (comma: string) => {
            if (numpadInputValue.value.includes(',') || numpadInputValue.value === '') {
                return;
            }
            numpadInputValue.value += comma;
            emit('numpadValueChange', numpadInputValue.value);
        };
        const onDeleteClick = () => {
            if (numpadInputValue.value.length === 0) {
                return;
            }
            numpadInputValue.value = numpadInputValue.value.slice(0, numpadInputValue.value.length - 1);
            emit('numpadValueChange', numpadInputValue.value);
        };
        return {
            numpadInputValue,
            onNumberClick,
            onCommaClick,
            onDeleteClick,
        };
    },
});
export default NumpadModal;
</script>
<style>
#numpadHeading {
    font-size: 35px;
}

#numpadDivs {
    width: 560px;
}

#numpadInput {
    padding-top: 10px;
    padding-bottom: 10px;
    border: 2px solid black;
    text-align: center;
}

#firstLineDiv {
    padding-top: 30px;
    padding-bottom: 30px;
    width: 560px;
    display: flex;
    justify-content: space-between;
}

.numpad-button {
    height: 70px;
    width: 70px;
    cursor: pointer;
}

.numpad-button:hover {
    background-color: yellow;
    color: black;
}

.digit {
    border: 2px solid blue;
}

#secondLineDiv {
    padding-bottom: 30px;
    width: 560px;
    display: flex;
    justify-content: space-between;
}

#thirdLineDiv {
    padding-bottom: 30px;
    width: 560px;
    display: flex;
    justify-content: space-between;
}

#fourthLineDiv {
    padding-bottom: 30px;
    width: 560px;
    display: flex;
    justify-content: space-between;
}

.option {
    border: 2px solid red;
}
</style>