import { SPORTZONE_USER_LAST_ROLE_STORAGE_KEY } from '@/composables/common/Constants';
import { SportzoneRole } from '@/models/main/User';
import { Configuration, LogLevel, PublicClientApplication } from '@azure/msal-browser';

export const MSAL_CONFIG: Configuration = {
    auth: {
        clientId: "e2634630-dad7-47fd-833c-4d4963523a78",
        authority: 'https://sportzoneapi.b2clogin.com/sportzoneapi.onmicrosoft.com/B2C_1_sportzone_sign_in', //'https://login.microsoftonline.com/b37bbf4f-a244-416c-8c02-b8e60193d267', //
        knownAuthorities: ['sportzoneapi.b2clogin.com'],
        redirectUri: '/', // Must be registered as a SPA redirectURI on your app registration
        postLogoutRedirectUri: '/' // Must be registered as a SPA redirectURI on your app registrationregistration
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        //console.info(message);
                        return;
                    case LogLevel.Verbose:
                        //console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
            logLevel: LogLevel.Verbose
        }
    }
  };

export const msalInstance = new PublicClientApplication(MSAL_CONFIG);

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['https://sportzoneapi.onmicrosoft.com/sportzone-api/user',
  'https://sportzoneapi.onmicrosoft.com/sportzone-api/read'],
  redirectUri: '/',
  redirectStartPage: '/user/dashboard'
};

export const organizerLoginRequest = {
    scopes: ['https://sportzoneapi.onmicrosoft.com/sportzone-api/user',
    'https://sportzoneapi.onmicrosoft.com/sportzone-api/read'],
    redirectUri: '/',
    redirectStartPage: '/organizer/organizations'
}

export function getUserSpecificLoginRequest() {
    const defaultRole: SportzoneRole = "Player";
    const storedRole = localStorage.getItem(SPORTZONE_USER_LAST_ROLE_STORAGE_KEY);
    const lastRole: SportzoneRole = storedRole as unknown as SportzoneRole ?? defaultRole 
    switch(lastRole) {
        case "Admin":
            return organizerLoginRequest;
        case "Player":
        default:
            return organizerLoginRequest;
    }
}

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};