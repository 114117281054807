<template>
    <TheOrganizerDashboard :onUserLoad="onUserLoaded" title="Organizations">
        <div class="organizations-wrapper">
            <div class="sport-wrapper" v-for="(organizationsGrouped, sport) in getOrganizationsGroupedBySport()">
                <div class="sport-icon-wrapper">
                    <VolleyballIcon class="organization-sport-icon" v-if="sport == 'Volleyball'"/>
                    <TennisIcon class="organization-sport-icon"  v-else-if="sport == 'Tennis'"/>
                    <TableTennisIcon class="organization-sport-icon"  v-else-if="sport == 'TableTennis'"/>
                    <BowlingIcon class="organization-sport-icon"  v-else-if="sport == 'Bowling'"/>
                    <BadmintonIcon class="organization-sport-icon"  v-else-if="sport == 'Badminton'"/>
                </div>
                <div class="sport-organizations">                           
                    <OrganizationBadgeItem class="organization-wrapper" v-for="organization in organizationsGrouped" :organization="organization"/>
                    <div class="organization-add-wrapper" @click="createOrganizationPrompt(sport)">
                        <div class="organization-add-badge">
                            <img src="/plus-add-sign-green.svg" :alt="'Create ' + sport + ' Organization'" />
                        </div>
                    </div>
                </div>
            </div>
            <StepBasedModal ref="organizationPopup" @onApplyClick="onFormSubmit" :start-step="organizationProcessStep"
        :step-validators="stepValidators" :action-process-total-steps="organizationProcessTotalSteps">
            <template #header>
                <h1>Create Organization</h1>
            </template>
            <template #popup-step-1>
                <div class="popup-step">
                        <div class="organization-details">
                            <div class="spz-text-input-group">
                                <label for="organization_name">Name</label>
                                <input id="organization_name" v-model="newOrganization.name" type="text" placeholder="Name" required />
                                <div v-show="!validName" class="spz-form-err-msg">Please provide a Name.</div>
                            </div>
                            <div class="spz-select-group">
                                <SportzoneSelect id="organization-sport"
                                    :options="['Sport', ...SPORT_TYPES]" v-model:selectedOption="newOrganization.sport">
                                </SportzoneSelect>
                            </div>
                            <div class="spz-text-input-group narrow">
                                <label for="dp-organization_established_on">Established on</label>
                                <VueDatePicker uid="organization_established_on" v-model="newOrganization.establishedOn" time-picker-inline utc="true" />
                            </div>
                        </div>
                    </div>
            </template>
            <template #action-btn >
                <button class="btn add-btn">Create</button>
            </template>
        </StepBasedModal>
        </div>
    </TheOrganizerDashboard>
  </template>
  <script lang="ts">
  import { Ref, defineComponent, ref, onMounted} from "vue";
import { createNamespacedHelpers, useStore } from "vuex";
import { key } from "@/store";
import TheOrganizerDashboard from "@/components/main/TheOrganizerDashboard.vue";
import BowlingIcon from '@/components/bowling/BowlingIcon.vue'
import VolleyballIcon from '@/components/volleyball/VolleyballIcon.vue'
import TableTennisIcon from '@/components/tableTennis/TableTennisIcon.vue'
import BadmintonIcon from "@/components/badminton/BadmintonIcon.vue"
import TennisIcon from '@/components/tennis/TennisIcon.vue'
import StepBasedModal from '@/components/main/StepBasedModal.vue';
import SportzoneSelect from '@/components/util/SportzoneSelect.vue';
import { useRouter } from "vue-router";
import { User } from "@/models/main/User";
import { SPORT_TYPES, Sport } from "@/models/main/CommonTypes";
import { ORGANIZATION_PRIVACIES, Organization } from "@/models/main/Organization";
import moment from "moment";
import { getUserLastSelectedSport } from "@/composables/common/Util";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import OrganizationBadgeItem from "@/components/main/OrganizationBadgeItem.vue";

const organizationStoreHelpers = createNamespacedHelpers('organizationStore');

export default defineComponent({
    components: {
        TheOrganizerDashboard,
        VolleyballIcon,
        TableTennisIcon,
        TennisIcon,
        BadmintonIcon,
        BowlingIcon,
        StepBasedModal,
        VueDatePicker,
        SportzoneSelect,
        OrganizationBadgeItem,
    },
    computed: {
        ...organizationStoreHelpers.mapGetters(
            ['getOrganizationsGroupedBySport'],
        ),
    },
    setup() {
        const store = useStore(key);

        const organizationPopup: Ref<any> = ref(null);
        const initialSportValue = getUserLastSelectedSport() ?? SPORT_TYPES[0]
        const defaultOrganization: Organization = {
            id: 0,
            owner: undefined,
            ownerId: undefined,
            parentOrganization: undefined,
            name: '',
            dateCreated: moment().toDate(),
            establishedOn: moment().toDate(),
            allowApplication: true,
            autoAcceptApplication: true,
            isSystemGenerated: false,
            sport: initialSportValue,
            subOrganizations: {},
            language: "English",
            acceptSubOrganizationMembers: true,
            subOrganizationsCanAccessMembers: true,
            privacy: ORGANIZATION_PRIVACIES[0]
        };
        const newOrganization: Ref<Organization> = ref(defaultOrganization);
        const validName: Ref<boolean> = ref(true);
        const validEstablishedDate: Ref<boolean> = ref(true)
        const mainDetailsValidator = () => {
            validName.value = newOrganization.value.name !== undefined && newOrganization.value.name?.length > 0
            validEstablishedDate.value = newOrganization.value.establishedOn !== undefined
            return validName.value && validEstablishedDate.value
        }
        const stepValidators: Array<Function> = [mainDetailsValidator, ()=>{ return true}]
        const organizationProcessStep: Ref<number> = ref(1);
        const organizationProcessTotalSteps = ref(1);
        

        const onUserLoaded = (user: User) => {
            loadOrganizations()
        }

        const loadOrganizations = async () => {
            //showLoadingIcon.value = true;
            await store.dispatch('organizationStore/fetchOrganizations', {
                sport: null,
            });

            const organizationsGroupedBySport = store.getters['organizationStore/getOrganizationsGroupedBySport']();
            //showLoadingIcon.value = false;
        };

        const createOrganizationPrompt = (sport: Sport) => {
            newOrganization.value.sport = sport
            organizationPopup.value.openModal()
        }
        // onMounted(loadOrganizations)

        const onFormSubmit = async () => {
            const createdOrganization = await store.dispatch('organizationStore/createOrganization', newOrganization.value);
            console.log(createdOrganization);
        };

        return {
            createOrganizationPrompt,
            onUserLoaded,
            onFormSubmit,
            SPORT_TYPES,
            newOrganization,
            stepValidators,
            validName,
            organizationProcessStep,
            organizationProcessTotalSteps,
            organizationPopup
            
        }
    }
})
</script>
<style>
.organizations-wrapper {
    width: 100%;
    --organization-badge-width: 10em;
    --organization-badge-height: 10em;
    height: 100%;
}
.sport-organizations {
    display: flex;
    margin-right: 1em;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: calc(100% - 6em);
    gap: 1em;
    margin-bottom: 1em;
}


.sport-wrapper {
    width: 100%;
    display: flex;
    padding-left: 1rem;
}
.sport-icon-wrapper {
    width: 6em;
    margin-right: 2.5em;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sport-wrapper:last-child {
    margin-bottom: 0;
}

.organization-add-wrapper {
  cursor: pointer;
}

.organization-add-badge {
    width: var(--organization-badge-width);
    height: var(--organization-badge-height);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--bright-font-clr);
    border-radius: var(--default-border-rad);
    transition: ease-in-out all 200ms;
}

.organization-add-badge:hover {
  transform: scale(1.1);
}
</style>