<template>
    <div class="tooltip" :class="isVisible ? 'visible' : ''">
        <div class="tooltip-wrapper">

            <div class="tooltip-header">
                <button class="btn tooltip-close-btn">x</button>
                <div class="header-content">
                    <slot name="header" />
                </div>
            </div>
            <div class="tooltip-title">
                <slot name="title"/>
            </div>
            <div class="tooltip-description" :class="position">
                <slot name="body" />
            </div>
            <div class="tooltip-footer">
                <button class="btn btn-inverse" >Back</button>
                <button class="btn btn-inverse">Next</button>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { TooltipType, TOOLTIP_POSITIONS } from '@/models/main/Tooltip';
export default defineComponent({
    props: {
        isVisible: {
            type: Boolean,
            required: false
        },
        isBackBtnVisible: {
            type: Boolean,
            required: false,
            default: false
        },
        isNextBtnVisible: {
            type: Boolean,
            required: false,
            default: false
        },
        position: {
            type: Object as PropType<TooltipType>,
            default: TOOLTIP_POSITIONS[0]
        }
    }
});
</script>
<style>
.tooltip {
    display: none;
}
.tooltip.visible {
    display: none;
}
.tooltip-wrapper {
    position: fixed;
    top: 20px;
    right: 350px;
    background-color: rgba(0, 0, 0, 0.8);
    width: 20em;
    padding: 1em;
    border-radius: var(--default-border-rad);
    z-index: 20
}

.tooltip-header {
    background-color: var(--archive-bck-color);
    margin-bottom: 0.5em;
    margin-left: -1em;
    margin-right: -1em;
    margin-top: -1em;
}

.tooltip-close-btn {
    position: absolute;
    top: 0.1em;
    right: 0.25em;
    padding: 0;
    background-color: transparent;
    font-size: 0.9em;
}
.tooltip-close-btn:hover {
    background-color: transparent;
    color: var(--main-font-clr);
}
.tooltip-title {
    font-weight: bold;
    font-size: 1.1em;
    padding-bottom: 0.5em;
}

.tooltip-wrapper::before {
    content: '';
    position: absolute;
    top: calc(50% - 2px);
    right: -2em;
    width: 2em;
    height: 4px;
    background-color: var(--archive-bck-color);
}
.tooltip-wrapper::after {
    content: '';
    position: absolute;
    top: calc(50% - 0.5em);
    right: -2em;
    width: 1em;
    height: 1em;
    background-color: var(--archive-bck-color);
    border-radius: 50%;
}

.tooltip-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top {
    inset-block-end: 100%;
}

.bottom {
    inset-block-start: 100%;
}

.left {
    inset-block-end: -50%;
    inset-inline-end: 100%;
}

.right {
    inset-block-end: -50%;
    inset-inline-start: 100%;
}

.top-left {
    inset-block-end: 100%;
    inset-inline-end: 100%;
}

.top-right {
    inset-block-end: 100%;
    inset-inline-start: 100%;
}

.bottom-left {
    inset-inline-end: 100%;
}

.bottom-right {
    inset-inline-start: 100%;
}
</style>