import GenericDictionary from "@/components/util/GenericDictionary";
import { Organization } from "@/models/main/Organization";
import { OrganizationApplication } from "@/models/main/OrganizationApplication";
import { INVITE_STATUSES, OrganizationInvitation } from "@/models/main/OrganizationInvitation";
import { key } from "@/store";
import { computed } from "vue";
import { useStore } from "vuex";

export default function useUserOrganizationComputeds() {
    const store = useStore(key);
    const loadUserOrganizationsAsMember = async () => {
        return store.dispatch('memberOrganizationStore/fetchUserOrganizationsAsMember', {
            sport: null,
        });
    }

    const loadUserOrganizationsAsFollower = async () => {
        return store.dispatch('followerOrganizationStore/fetchUserOrganizationsAsFollower', {
            sport: null,
        });
    }


    const loadPublicOrganizations = async () => {
        return store.dispatch('organizationPublicStore/fetchPublicOrganizations', {
            sport: null,
        });
    }

    const loadUserOrganizationApplications = () => {
        return store.dispatch('organizationApplicationStore/fetchUserOrganizationApplications');
    }

    const loadUserOrganizationInvitations = () => {
        return store.dispatch('organizationInvitationStore/fetchUserOrganizationInvitations');
    }



    const rejectInvitation = async (invitation: OrganizationInvitation) => {
        invitation.status = INVITE_STATUSES[3];
        await store.dispatch('organizationInvitationStore/editOrganizationInvitation', invitation);
    };

    const acceptInvitation = async (invitation: OrganizationInvitation) => {
        await store.dispatch('organizationInvitationStore/acceptUserInvitation', invitation);
    }

    const cancelApplication = async (application: OrganizationApplication) => {
        await store.dispatch('organizationApplicationStore/deleteOrganizationApplication', application)
    }

    const deleteInvitation = async (invitation: OrganizationInvitation) => {
        await store.dispatch('organizationInvitationStore/deleteOrganizationInvitation', invitation)
    }



    const getMemberOrganizationsGroupedBySport = computed(() => {
        return store.getters['memberOrganizationStore/getOrganizationsGroupedBySport']()
    })

    const getFollowerOrganizationsGroupedBySport = computed(() => {
        return store.getters['followerOrganizationStore/getOrganizationsGroupedBySport']()
    })

    const getPublicOrganizationsGroupedBySport = computed(() => {
        const publicOrganizations = store.getters['organizationPublicStore/getOrganizationsGroupedBySport']()
        const memberOrganizations = getMemberOrganizationsGroupedBySport.value
        const followerOrganizations = getFollowerOrganizationsGroupedBySport.value
        const organizationsWhereNotMember = filterOrganizationsIn(publicOrganizations, memberOrganizations)
        const organizationsWhereNotMemberAndFollower = filterOrganizationsIn(organizationsWhereNotMember, followerOrganizations)

        return organizationsWhereNotMemberAndFollower
    })

    const filterOrganizationsIn = (excludeFromOrgs: GenericDictionary<Organization[]>, excludeOrgs: GenericDictionary<Organization[]>) => {
        const filteredOrganizations: GenericDictionary<Organization[]> = {}
        for(let excludeFromOrgSport in excludeFromOrgs) {
            const sportExcludeFromOrganizations: Array<Organization> = excludeFromOrgs[excludeFromOrgSport]
            for(let excludeFromOrgI in sportExcludeFromOrganizations) { // cycling through all organizations of Main List
                let currentOrgExistsInExclude = false
                if(excludeOrgs[excludeFromOrgSport]) { // no need to cycle if current sport doesn't exist in Exclude list
                    for(let excludeOrgI in excludeOrgs[excludeFromOrgSport]) { // cycle through all exclude Organizations in current sport
                        if(excludeOrgs[excludeFromOrgSport][excludeOrgI].id === excludeFromOrgs[excludeFromOrgSport][excludeFromOrgI].id) { // we have a match
                            currentOrgExistsInExclude = true
                            break
                        }
                    }
                }

                if(!currentOrgExistsInExclude) { // not found in exclude list we can safely add
                    if(!filteredOrganizations[excludeFromOrgSport]) {
                        filteredOrganizations[excludeFromOrgSport] = []
                    }
                    filteredOrganizations[excludeFromOrgSport].push(excludeFromOrgs[excludeFromOrgSport][excludeFromOrgI])
                }
            }
        }

        return filteredOrganizations
    }



    const userOrganizationsCount = computed(() => {
        return store.getters['memberOrganizationStore/getTotalCount']
    })

    const getOrganizationApplications = computed(() => {
        return store.getters['organizationApplicationStore/getOrganizationApplications']()
    })

    const getOrganizationApplicationsTotalCount = computed(() => {
        return store.getters['organizationApplicationStore/getTotalCount']
    })

    const getOrganizationInvitations = computed(() => {
        return store.getters['organizationInvitationStore/getOrganizationInvitations']()
    })

    const getOrganizationInvitationsTotalCount = computed(() => {
        return store.getters['organizationInvitationStore/getTotalCount']
    })

    return { 
        loadUserOrganizationsAsMember,
        loadUserOrganizationsAsFollower,
        loadPublicOrganizations,
        loadUserOrganizationApplications,
        loadUserOrganizationInvitations,
        rejectInvitation,
        acceptInvitation,
        cancelApplication,
        deleteInvitation,
        getMemberOrganizationsGroupedBySport,
        getFollowerOrganizationsGroupedBySport,
        getPublicOrganizationsGroupedBySport,
        userOrganizationsCount,
        getOrganizationApplications,
        getOrganizationApplicationsTotalCount,
        getOrganizationInvitations,
        getOrganizationInvitationsTotalCount
    }
}