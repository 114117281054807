import { IRootState } from '@/store';
import { Store } from 'vuex';
import { Container } from '@/models/main/Container';
import { computed, Ref } from '@vue/runtime-core';
import { Entity } from '@/models/main/Entity';
import useInterpretBadmintonEvents from './UseInterpretBadmintonEvents';
import Livestream from '@/models/main/Livestream';

export default function useCurrentContainerComputeds(store: Store<IRootState>, currentContainerId: Ref<number>, liveStream?: Ref<Livestream | null>, playerCurrentUnixTime?: Ref<number>, isSpoiledModeOn?: Ref<boolean>) {
    const getCurrentContainer = computed((): Container | undefined => {
        return store.getters['containerStore/getContainer'](currentContainerId.value)
    })

    const getCurrentHomeEntity = computed((): Entity | undefined => {
        return store.getters['containerStore/getHomeEntity'](currentContainerId.value)
    })

    const getCurrentAwayEntity = computed((): Entity | undefined => {
        return store.getters['containerStore/getAwayEntity'](currentContainerId.value)
    })

    const { calculateEntityPoints, calculateEntityGames, groupEventsByGames, countBasicStats } = useInterpretBadmintonEvents()

    const getCurrentContainerBasicStats = computed(() => {
        const homeEntityId = getCurrentHomeEntity.value?.id
        const awayEntityId = getCurrentAwayEntity.value?.id
        const currentContainer = getCurrentContainer.value
        if(homeEntityId !== undefined && awayEntityId !== undefined && currentContainer !== undefined) {
            return countBasicStats(homeEntityId, awayEntityId, currentContainer.events, liveStream?.value?.status === "Stopping" || liveStream?.value?.status === "Completed" ? playerCurrentUnixTime?.value : undefined)
        }
    })

    const getCurrentContainerEventsGroupedByGames = computed(() => {
        const homeEntityId = getCurrentHomeEntity.value?.id
        const awayEntityId = getCurrentAwayEntity.value?.id
        const currentContainer = getCurrentContainer.value
        if(homeEntityId !== undefined && awayEntityId !== undefined && currentContainer !== undefined) {
            return groupEventsByGames(homeEntityId, awayEntityId, currentContainer.events, liveStream?.value?.status === "Stopping" || liveStream?.value?.status === "Completed" ? playerCurrentUnixTime?.value : undefined, isSpoiledModeOn?.value ? undefined : playerCurrentUnixTime?.value)
        }

        return {}
    })

    const getCurrentHomeEntityPoints = computed((): number => {
        const homeEntityId = getCurrentHomeEntity.value?.id
        const currentContainer = getCurrentContainer.value
        if(homeEntityId !== undefined && currentContainer !== undefined) {
            return calculateEntityPoints(homeEntityId, currentContainer.events, liveStream?.value?.status === "Stopping" || liveStream?.value?.status === "Completed" ? playerCurrentUnixTime?.value : undefined)
        }

        return 0
    })

    const getCurrentAwayEntityPoints = computed((): number => {
        const awayEntityId = getCurrentAwayEntity.value?.id
        const currentContainer = getCurrentContainer.value
        if(awayEntityId !== undefined && currentContainer !== undefined) {
            return calculateEntityPoints(awayEntityId, currentContainer.events, liveStream?.value?.status === "Stopping" || liveStream?.value?.status === "Completed" ? playerCurrentUnixTime?.value : undefined)
        }

        return 0
    })

    const currentHomeEntityGamePoints = computed((): number => {
        const homeEntityId = getCurrentHomeEntity.value?.id
        const currentContainer = getCurrentContainer.value
        if(homeEntityId !== undefined && currentContainer !== undefined) {
            return calculateEntityGames(homeEntityId, currentContainer?.events, liveStream?.value?.status === "Stopping" || liveStream?.value?.status === "Completed" ? playerCurrentUnixTime?.value : undefined)
        }
        return 0
    })

    const currentAwayEntityGamePoints = computed((): number => {
        const awayEntityId = getCurrentAwayEntity.value?.id
        const currentContainer = getCurrentContainer.value
        if(awayEntityId !== undefined && currentContainer !== undefined) {
            return calculateEntityGames(awayEntityId, currentContainer.events, liveStream?.value?.status === "Stopping" || liveStream?.value?.status === "Completed" ? playerCurrentUnixTime?.value : undefined)
        }

        return 0
    })

    return {
        calculateEntityPoints,
        calculateEntityGames,
        groupEventsByGames,
        getCurrentContainerBasicStats,
        getCurrentContainerEventsGroupedByGames,
        getCurrentContainer,
        getCurrentHomeEntity,
        getCurrentAwayEntity,
        getCurrentHomeEntityPoints,
        getCurrentAwayEntityPoints,
        currentHomeEntityGamePoints,
        currentAwayEntityGamePoints,
    }
}