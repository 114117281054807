import { Event } from '@/models/main/Event';
import { TENNIS_ACE, TENNIS_POINT, TENNIS_FOREHAND_WINNER, TENNIS_BACKHAND_WINNER, 
    TENNIS_FORCED_ERROR, TENNIS_FOREHAND_UNFORCED, TENNIS_BACKHAND_UNFORCED, 
    TENNIS_DOUBLE_FAULT, TennisPointType, TennisGameControlType, TENNIS_POINT_TITLE_PAIRS, TennisScore, TENNIS_IS_POSITIVE_POINT, TennisOverrideData } from '@/composables/tennis/TennisEventTypes';
import GenericDictionary from '@/components/util/GenericDictionary';

export type BasicTennisStat = GenericDictionary<{ pointType: TennisPointType, count: number }>

export default function useInterpretTennisEvents() {
    type EntitySetGameScore = { entityId: number, score: number }
    type EntityPointScore = { entityId: number, score: TennisScore }
    type TennisSetScore = { entities: GenericDictionary<EntitySetGameScore> }
    type TennisGameScore = { entities: GenericDictionary<EntitySetGameScore> }
    type TennisPointsScore = { entities: GenericDictionary<EntityPointScore> }
    type EventSnapshotScores = 
        { event: Event, setScore: TennisSetScore, gameScore: TennisGameScore, pointsScore: TennisPointsScore, isTiebreakGame: boolean, servingEntityId: number | undefined }
    type EventSnapshotGroupedByGame = GenericDictionary<EventSnapshotScores>
    type EventSnapshotGroupedBySet = GenericDictionary<EventSnapshotGroupedByGame>
    type TennisMatchScores = 
        { eventsScores: GenericDictionary<EventSnapshotGroupedBySet>, setScore: TennisSetScore, 
            gameScore: TennisGameScore, pointsScore: TennisPointsScore, isTiebreakGame: boolean, 
            servingEntityId: number | undefined, currentSet: number, currentGame: number }


    type TennisPlayerStats = { points: number, pointsGroupedByType: BasicTennisStat }
    type TennisEntityStats = { playersStats: GenericDictionary<TennisPlayerStats>, points: TennisScore, pointsGroupedByType: BasicTennisStat }
    type TennisGameStats = { entities: GenericDictionary<TennisEntityStats> , winnerEntityId: number | null}
    type TennisSetStats = { gamesStats: GenericDictionary<TennisGameStats>, entities: GenericDictionary<TennisEntityStats>, winnerEntityId: number | null }
    type TennisMatchStats = { setsStats: GenericDictionary<TennisSetStats>, entities: GenericDictionary<TennisEntityStats>, winnerEntityId: number | null }
    const countBasicStats = (homeEntityId: number, awayEntityId: number, events: GenericDictionary<Event>, eventsBefore?: number) => {
        let currentSet = 1
        let currentGame = 1
        let beforeUpdateCurrentGame = currentGame
        let beforeUpdateCurrentSet = currentSet
        const matchStats: TennisMatchStats = { setsStats : {}, entities: {}, winnerEntityId: null}
        const matchScores: TennisMatchScores = 
            { eventsScores: {}, setScore: { entities: {}}, gameScore: { entities: {}}, pointsScore: { entities: {} }, 
            isTiebreakGame: false, servingEntityId: undefined, currentGame: 1, currentSet: 1 }
        for(const i in events) {
            const event = events[i]
            const eventTimeStartInUnixSeconds = new Date(event.startTimestamp ?? 0).getTime() / 1000
            beforeUpdateCurrentSet = currentSet
            beforeUpdateCurrentGame = currentGame
            if(eventsBefore === undefined || eventTimeStartInUnixSeconds <= eventsBefore) {
                const eventEntityId = event.entityId
                const eventId = event.id
                let pointType = event.description as unknown as TennisPointType | null
                if(pointType === null) {
                    pointType = TENNIS_POINT
                }
                if(eventId && eventEntityId) {
                    if(!Object.prototype.hasOwnProperty.call(matchScores.setScore.entities, homeEntityId)) {
                        matchScores.setScore.entities[homeEntityId] = { entityId: homeEntityId, score: 0 }
                    }
                    if(!Object.prototype.hasOwnProperty.call(matchScores.gameScore.entities, homeEntityId)) {
                        matchScores.gameScore.entities[homeEntityId] = { entityId: homeEntityId, score: 0 }
                    }
                    if(!Object.prototype.hasOwnProperty.call(matchScores.pointsScore.entities, homeEntityId)) {
                        matchScores.pointsScore.entities[homeEntityId] = { entityId: homeEntityId, score: 0 }
                    }
                    if(!Object.prototype.hasOwnProperty.call(matchScores.setScore.entities, awayEntityId)) {
                        matchScores.setScore.entities[awayEntityId] = { entityId: awayEntityId, score: 0 }
                    }
                    if(!Object.prototype.hasOwnProperty.call(matchScores.gameScore.entities, awayEntityId)) {
                        matchScores.gameScore.entities[awayEntityId] = { entityId: awayEntityId, score: 0 }
                    }
                    if(!Object.prototype.hasOwnProperty.call(matchScores.pointsScore.entities, awayEntityId)) {
                        matchScores.pointsScore.entities[awayEntityId] = { entityId: awayEntityId, score: 0 }
                    }

                    if(!Object.prototype.hasOwnProperty.call(matchStats.entities, eventEntityId)) {
                        matchStats.entities[eventEntityId] = { playersStats: {}, points: 0, pointsGroupedByType: {}}
                    }
                    if(!Object.prototype.hasOwnProperty.call(matchStats.entities[eventEntityId].pointsGroupedByType, pointType)) {
                        matchStats.entities[eventEntityId].pointsGroupedByType[pointType] = { count: 0, pointType: pointType }
                    }
                    if(!Object.prototype.hasOwnProperty.call(matchStats.setsStats, currentSet)) {
                        matchStats.setsStats[currentSet] = { gamesStats: {}, entities: {}, winnerEntityId: null }
                    }
                    if(!Object.prototype.hasOwnProperty.call(matchStats.setsStats[currentSet].gamesStats, currentGame)) {
                        matchStats.setsStats[currentSet].gamesStats[currentGame] = { entities: {}, winnerEntityId: null }
                    }
                    if(!Object.prototype.hasOwnProperty.call(matchStats.setsStats[currentSet].entities, eventEntityId)) {
                        matchStats.setsStats[currentSet].entities[eventEntityId] = { playersStats: {}, points: 0, pointsGroupedByType: {}}
                    }
                    if(!Object.prototype.hasOwnProperty.call(matchStats.setsStats[currentSet].entities[eventEntityId].pointsGroupedByType, pointType)) {
                        matchStats.setsStats[currentSet].entities[eventEntityId].pointsGroupedByType[pointType] = { count: 0, pointType: pointType }
                    }
                    if(!Object.prototype.hasOwnProperty.call(matchStats.setsStats[currentSet].gamesStats[currentGame].entities, eventEntityId)) {
                        matchStats.setsStats[currentSet].gamesStats[currentGame].entities[eventEntityId] = { playersStats: {}, points: 0, pointsGroupedByType: {}}
                    }
                    if(!Object.prototype.hasOwnProperty.call(
                        matchStats.setsStats[currentSet].gamesStats[currentGame].entities[eventEntityId].pointsGroupedByType, pointType)) {
                        matchStats.setsStats[currentSet].gamesStats[currentGame].entities[eventEntityId].pointsGroupedByType[pointType] = { count: 0, pointType: pointType }
                    }

                    if(event.type === "Point") {
                        const updatedPointsScore = 
                        calculateCurrentGameScore(matchScores.isTiebreakGame, event, homeEntityId, awayEntityId, matchScores.pointsScore, matchScores.servingEntityId)
                        matchScores.pointsScore = updatedPointsScore
                        
                        matchStats.entities[eventEntityId].pointsGroupedByType[pointType].count++
                        matchStats.setsStats[currentSet].entities[eventEntityId].pointsGroupedByType[pointType].count++
                        matchStats.setsStats[currentSet].gamesStats[currentGame].entities[eventEntityId].pointsGroupedByType[pointType].count++
                        if(event.playerId) {
                            const eventPlayerId = event.playerId;
                            if(!Object.prototype.hasOwnProperty.call(matchStats.entities[eventEntityId].playersStats, eventPlayerId)) {
                                matchStats.entities[eventEntityId].playersStats[eventPlayerId] = { points: 0, pointsGroupedByType: {}}
                            }
                            if(!Object.prototype.hasOwnProperty.call(matchStats.entities[eventEntityId].playersStats[eventPlayerId].pointsGroupedByType, pointType)) {
                                matchStats.entities[eventEntityId].playersStats[eventPlayerId].pointsGroupedByType[pointType] = { count: 0, pointType: pointType }
                            }
                            if(!Object.prototype.hasOwnProperty.call(matchStats.setsStats[currentSet].entities[eventEntityId].playersStats, eventPlayerId)) {
                                matchStats.setsStats[currentSet].entities[eventEntityId].playersStats[eventPlayerId] = { points: 0, pointsGroupedByType: {}}
                            }
                            if(!Object.prototype.hasOwnProperty.call(
                                matchStats.setsStats[currentSet].entities[eventEntityId].playersStats[eventPlayerId].pointsGroupedByType, pointType)) {
                                    matchStats.setsStats[currentSet]
                                    .entities[eventEntityId].playersStats[eventPlayerId].pointsGroupedByType[pointType] = { count: 0, pointType: pointType }
                            }
                            if(!Object.prototype.hasOwnProperty.call(
                                matchStats.setsStats[currentSet].gamesStats[currentGame].entities[eventEntityId].playersStats, eventPlayerId)) {
                                matchStats.setsStats[currentSet].gamesStats[currentGame].entities[eventEntityId].playersStats[eventPlayerId] = { points: 0, pointsGroupedByType: {}} 
                            }
                            if(!Object.prototype.hasOwnProperty.call(
                                matchStats.setsStats[currentSet].gamesStats[currentGame].entities[eventEntityId].playersStats[eventPlayerId].pointsGroupedByType, pointType)) {
                                matchStats.setsStats[currentSet].gamesStats[currentGame]
                                    .entities[eventEntityId].playersStats[eventPlayerId].pointsGroupedByType[pointType] = { count: 0, pointType: pointType }
                            }

                            matchStats.entities[eventEntityId].playersStats[eventPlayerId].pointsGroupedByType[pointType].count++ //match stat
                            matchStats.setsStats[currentSet]
                                    .entities[eventEntityId].playersStats[eventPlayerId].pointsGroupedByType[pointType].count++ //set stat    
                            matchStats.setsStats[currentSet].gamesStats[currentGame]
                                    .entities[eventEntityId].playersStats[eventPlayerId].pointsGroupedByType[pointType].count++ //game stat
                        }

                        matchStats.setsStats[currentSet].gamesStats[currentGame].entities[eventEntityId].points = matchScores.pointsScore.entities[eventEntityId].score

                        const currentEventSetScore: TennisSetScore = {entities: {} }
                        for(let entityId in matchScores.setScore.entities) {
                            currentEventSetScore.entities[entityId] = {entityId: parseInt(entityId), score: matchScores.setScore.entities[entityId].score }
                        }
                        const currentEventGameScore: TennisGameScore = {entities: {} }
                        for(let entityId in matchScores.gameScore.entities) {
                            currentEventGameScore.entities[entityId] = {entityId: parseInt(entityId), score: matchScores.gameScore.entities[entityId].score }
                        }
                        const currentEventPointsScore: TennisPointsScore = {entities: {} }
                        for(let entityId in matchScores.pointsScore.entities) {
                            currentEventPointsScore.entities[entityId] = {entityId: parseInt(entityId), score: matchScores.pointsScore.entities[entityId].score }
                        }
    
                        if(!Object.prototype.hasOwnProperty.call(matchScores.eventsScores, beforeUpdateCurrentSet)) {
                            matchScores.eventsScores[beforeUpdateCurrentSet] = { }
                        }
                        if(!Object.prototype.hasOwnProperty.call(matchScores.eventsScores[beforeUpdateCurrentSet], beforeUpdateCurrentGame)) {
                            matchScores.eventsScores[beforeUpdateCurrentSet][beforeUpdateCurrentGame] = {}
                        }
    
                        matchScores.eventsScores[beforeUpdateCurrentSet][beforeUpdateCurrentGame][eventId] = 
                        {
                            event: event, gameScore: currentEventGameScore, 
                            setScore: currentEventSetScore, 
                            isTiebreakGame: matchScores.isTiebreakGame, 
                            servingEntityId: matchScores.servingEntityId,
                            pointsScore: currentEventPointsScore
                        }
                    } else if (event.type === "GameControl") {
                        const gameControlType = event.description as unknown as TennisGameControlType
                        if(gameControlType === "Tenn_G") {
                            matchScores.isTiebreakGame = false;
                            matchScores.pointsScore.entities[homeEntityId] = { entityId: homeEntityId, score: 0 }
                            matchScores.pointsScore.entities[awayEntityId] = { entityId: awayEntityId, score: 0 }
                            matchScores.gameScore.entities[eventEntityId].score++
                            matchStats.setsStats[currentSet].entities[eventEntityId].points = matchScores.gameScore.entities[eventEntityId].score
                            matchStats.setsStats[currentSet].gamesStats[currentGame].winnerEntityId = eventEntityId
                            currentGame++
                        }
                        if(gameControlType === "Tenn_S") {
                            matchScores.isTiebreakGame = false;
                            matchScores.gameScore.entities[homeEntityId] = { entityId: homeEntityId, score: 0 }
                            matchScores.gameScore.entities[awayEntityId] = { entityId: awayEntityId, score: 0 }
                            matchScores.pointsScore.entities[homeEntityId] = { entityId: homeEntityId, score: 0 }
                            matchScores.pointsScore.entities[awayEntityId] = { entityId: awayEntityId, score: 0 }
                            matchScores.setScore.entities[eventEntityId].score++
                            matchStats.entities[eventEntityId].points = matchScores.setScore.entities[eventEntityId].score
                            matchStats.setsStats[currentSet].winnerEntityId = eventEntityId
                            currentSet++
                            currentGame = 1
                        }
                        if(gameControlType === "Tenn_Srv") {
                            matchScores.servingEntityId = eventEntityId;
                        }
                        if(gameControlType === "Tenn_TG") {
                            matchScores.isTiebreakGame = true;
                        }
                        if(gameControlType === "Tenn_O" && event.additionalData) {
                            const overrideObj = JSON.parse(event.additionalData) as TennisOverrideData
                            let overrideSetValue: number | undefined = overrideObj?.overrideSet
                            let overrideGameValue: number | undefined = overrideObj?.overrideGame
                            let overridePointValue: TennisScore | undefined = overrideObj?.overridePoint
                            if(overrideSetValue !== undefined) {
                                matchStats.entities[eventEntityId].points = overrideSetValue
                                let currentEntityWonSetsCount = 0;
                                for(let set in matchStats.setsStats) {
                                    const currentSetObj = matchStats.setsStats[set]
                                    if(currentSetObj.winnerEntityId === eventEntityId) {
                                        currentEntityWonSetsCount++
                                    }
                                }

                                const overrideDelta = overrideSetValue - currentEntityWonSetsCount
                                if(overrideDelta > 0) { // need to add
                                    // matchScores.isTiebreakGame = false;
                                    // matchStats.entities[eventEntityId].points = overrideSetValue
                                    // matchStats.setsStats[currentSet].winnerEntityId = eventEntityId
                                    // currentSet++
                                } else if (overrideDelta < 0) { // need to remove
                                    const updatedMatchStats: TennisMatchStats = { entities: {}, setsStats: {}, winnerEntityId: null}
                                    let skipFirstNSets = currentEntityWonSetsCount - overrideSetValue
                                    for(let i = 1, newIndex = 1; i <= currentSet; i++) {
                                        if(matchStats.setsStats[i].winnerEntityId === eventEntityId) {
                                            if(skipFirstNSets > 0) {
                                                skipFirstNSets--
                                            } else {
                                                continue
                                            }
                                        }

                                        updatedMatchStats.setsStats[newIndex++] = matchStats.setsStats[i]
                                    }

                                    matchStats.setsStats = updatedMatchStats.setsStats
                                }
                                currentSet += overrideDelta
                                matchScores.setScore.entities[eventEntityId].score = overrideSetValue
                            } 
                            if(overrideGameValue !== undefined) {
                                const currentSetObj = matchStats.setsStats[currentSet]
                                matchStats.setsStats[currentSet].entities[eventEntityId].points = overrideGameValue
                                let currentEntityWonGamesCount = 0;
                                for(let gameI in currentSetObj.gamesStats) {
                                    if(currentSetObj.gamesStats[gameI].winnerEntityId === eventEntityId) {
                                        currentEntityWonGamesCount++
                                    }
                                }

                                const gameOverrideDelta = overrideGameValue - currentEntityWonGamesCount
                                if(gameOverrideDelta > 0) { // add game

                                } else if(gameOverrideDelta < 0) { // remove
                                    const updatedSetStats: TennisSetStats = {gamesStats: {}, entities: {}, winnerEntityId: null }
                                    let skipFirstNGames = currentEntityWonGamesCount - overrideGameValue
                                    for(let g = 1, newGameIndex = 1; g <= currentGame; g++) {
                                        if(currentSetObj.gamesStats[g].winnerEntityId === eventEntityId) {
                                            if(skipFirstNGames > 0) {
                                                skipFirstNGames--
                                            } else {
                                                continue
                                            }
                                        }

                                        updatedSetStats.gamesStats[newGameIndex++] = currentSetObj.gamesStats[g]
                                    }

                                    matchStats.setsStats[currentSet].gamesStats = updatedSetStats.gamesStats
                                }

                                currentGame += gameOverrideDelta
                                matchScores.gameScore.entities[eventEntityId].score = overrideGameValue
                            }
                            if(overridePointValue !== undefined) { // points
                                const currentGameObj = matchStats.setsStats[currentSet].gamesStats[currentGame]
                                matchStats.setsStats[currentSet].gamesStats[currentGame].entities[eventEntityId].points = overridePointValue
                                currentGameObj.entities[eventEntityId].pointsGroupedByType

                                matchScores.pointsScore.entities[eventEntityId].score = overridePointValue
                            }
                        }
                        matchScores.currentGame = currentGame
                        matchScores.currentSet = currentSet
                    }
                }

            }
        }

        return { matchStats, matchScores }
        
    }

    const calculateCurrentGameScore = (isTiebreakGame: boolean, event: Event, homeEntityId: number, awayEntityId: number, currentPointsScore: TennisPointsScore, serverEntityId: number | undefined) => {
        const eventEntityId = event.entityId
        if(eventEntityId && event.type === "Point") { // only if its point and eventEntityId is not undefined
            const pointType = event.description as unknown as TennisPointType | null
            const pointIsPoisitive = pointType === null ? true : TENNIS_IS_POSITIVE_POINT[pointType];
            const isHomeEntity = eventEntityId === homeEntityId;
            const currentScore = currentPointsScore.entities[eventEntityId]?.score ?? 0;
            const enemyEntityId = isHomeEntity ? awayEntityId : homeEntityId;
            const enemyCurrentScore = currentPointsScore.entities[enemyEntityId]?.score ?? 0;
            const isDeuce = currentScore === "40" && enemyCurrentScore === "40";
            const isServer = serverEntityId === undefined ? serverEntityId : eventEntityId === serverEntityId;
            const isEnemyServer = serverEntityId === undefined ? serverEntityId : enemyEntityId === serverEntityId;
            const hasAdvantage = currentScore === "Ad" || currentScore === "Ad-In" || currentScore === "Ad-Out"
            const enemyHasAdvantage = enemyCurrentScore === "Ad" || enemyCurrentScore === "Ad-In" || enemyCurrentScore === "Ad-Out"
            if(pointIsPoisitive) {
                if(enemyHasAdvantage) {
                    currentPointsScore.entities[enemyEntityId].score = "40"
                } else {
                    let nextScore = currentScore
                    if(isTiebreakGame) {
                        nextScore = nextScore as number + 1
                    } else {
                        nextScore = getNextPointScore(currentScore, isDeuce, isServer)
                    }
    
                    currentPointsScore.entities[eventEntityId].score = nextScore
                }
            } else {
                if(hasAdvantage) {
                    currentPointsScore.entities[eventEntityId].score = "40"
                } else {
                    let nextScore = enemyCurrentScore
                    if(isTiebreakGame) {
                        nextScore = nextScore as number + 1
                    } else {
                        nextScore = getNextPointScore(enemyCurrentScore, isDeuce, isEnemyServer)
                    }
    
                    currentPointsScore.entities[enemyEntityId].score = nextScore
                }
            }
        }

        return currentPointsScore;
    }

    const getNextPointScore = (currentScore: TennisScore, isDeuce?: boolean, isServer?: boolean | undefined): TennisScore => { //In non-tiebreak game
        switch (currentScore) {
            case 0: 
                return "15";
            case "15":
                return "30";
            case "30":
                return "40";
            case "40": {
                if(isDeuce) {
                    if(isServer === undefined) {
                        return "Ad"
                    }
                    else if(isServer) {
                        return "Ad";
                    } else {
                        return "Ad";
                    }
                }

                return "G";
            }
            case "Ad":
            case "Ad-In":
            case "Ad-Out":
                return "G";
            default: 
                return 0;
        }
    }
    const getPreviousPointScore = (currentScore: TennisScore): TennisScore => {
        switch (currentScore) {
            case '15':
                return 0;
            case '30':
                return '15';
            case '40':
                return '30';
            case 'G':
                return '40';
            default:
                return 0;
        }
    };

    return {
        countBasicStats,
        getNextPointScore,
        getPreviousPointScore,
    }
}