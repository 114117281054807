<template>
    <ActionBox @click="$emit('click', index)" v-for="(pointType, index) in pointTypes" :key="index" :class="{'is-negative': !BADMINTON_IS_POSITIVE_POINT[index]}"
        :isDisabled="isDisabled">{{ pointType }}</ActionBox>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import ActionBox from '@/components/badminton/ActionBox.vue';
import { BADMINTON_POINT_TITLE_PAIRS, BADMINTON_IS_POSITIVE_POINT } from '@/composables/badminton/BadmintonEventTypes';
export default defineComponent({
    components: {
        ActionBox,
    },
    emits: [
        'click',
    ],
    props: [
        'isDisabled',
    ],
    data() {
        return {
            pointTypes: BADMINTON_POINT_TITLE_PAIRS,
            BADMINTON_IS_POSITIVE_POINT
        };
    },
});
</script>
<style>
.home-point-types .badminton-action-box, .away-point-types .badminton-action-box {
  background-color: #b6f5b7;
}
.home-point-types .badminton-action-box.is-negative, .away-point-types .badminton-action-box.is-negative {
  color: white;
  background-color: var(--watch-bck-color);
}
</style>