<template>
    <TheOrganizerDashboard :onUserLoad="onUserLoaded">
        <template #heading>
            <router-link :to="{ name: 'Organizations' }">
                <div class="page-heading">
                    Organizations
                </div>
            </router-link>
            <router-link :to="{ name: 'Organization Menu', params: { id: organization?.id } }">
                <div class="page-heading">
                    {{ organization?.name }}
                </div>
            </router-link>
            <router-link :to="{ name: 'Organization Members', params: { id: organization?.id } }">
                <div class="page-heading">
                    Members
                </div>
            </router-link>
            <router-link :to="{ name: 'Organization Applications', params: { id: organization?.id } }">
                <div class="page-heading">
                    Applications
                </div>
            </router-link>
        </template>
        <template #default>
            <div class="organizations-wrapper">
                <div class="organization-applications-wrapper">
                    <template v-if="getOrganizationApplications().length > 0" v-for="organizationApplication in getOrganizationApplications()">
                        <OrganizationApplicationItem :application="organizationApplication"
                            @accept="onApplicationAccept(organizationApplication)"
                            @reject="onApplicationEdit(organizationApplication, APPLICATION_STATUSES[2])" 
                            @delete="onApplicationDelete(organizationApplication)" />
                    </template>
                    <template v-else>
                        <div class="spz-system-message">
                            There are no applications yet
                        </div>
                    </template>
                </div>
            </div>
        </template>
    </TheOrganizerDashboard>
</template>
<script lang="ts">
import { createNamespacedHelpers, useStore } from 'vuex';
import { defineComponent, onMounted, Ref, ref } from 'vue';
import TheOrganizerDashboard from '@/components/main/TheOrganizerDashboard.vue';
import OrganizationApplicationItem from '@/components/main/OrganizationApplicationItem.vue';
import { key } from '@/store';
import { Organization } from '@/models/main/Organization';
import { useRoute } from 'vue-router';
import { User } from '@/models/main/User';
import { OrganizationApplication, ApplicationStatus, APPLICATION_STATUSES } from '@/models/main/OrganizationApplication';
const organizationApplicationStoreHelpers = createNamespacedHelpers('organizationApplicationStore');
export default defineComponent({
    components: {
        TheOrganizerDashboard,
        OrganizationApplicationItem
    },
    computed: {
        ...organizationApplicationStoreHelpers.mapGetters(
            ['getOrganizationApplications']
        )
    },
    setup() {
        const store = useStore(key);
        const organization: Ref<Organization | undefined> = ref();
        const route = useRoute();
        const organizationId: Ref<number> = ref(parseInt(route.params.id as string));
        const onUserLoaded = (user: User) => {
        };
        const onApplicationEdit = async (application: OrganizationApplication, status: ApplicationStatus) => {
            application.status = status;
            await store.dispatch('organizationApplicationStore/editOrganizationApplication', application);
        };
        const loadOrganizationApplications = async () => {
            organization.value = await store.dispatch('organizationStore/getOrganization', organizationId.value);
            const applications = await store.dispatch('organizationApplicationStore/fetchOrganizationApplications', organizationId.value);
        };

        const onApplicationAccept = async (application: OrganizationApplication) => {
            await store.dispatch('organizationApplicationStore/acceptUserApplication', application);
            
        }

        const onApplicationDelete = async (application: OrganizationApplication) => {
            await store.dispatch('organizationApplicationStore/deleteOrganizationApplication', application);
            
        }
        onMounted(loadOrganizationApplications)
        return {
            organization,
            organizationId,
            APPLICATION_STATUSES,
            onUserLoaded,
            onApplicationEdit,
            onApplicationAccept,
            onApplicationDelete
        };
    }
});
</script>
<style>

.organization-applications-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
}
</style>