import { Camera } from "@/models/main/Camera";
import { Sport } from "@/models/main/CommonTypes";
import { Container } from "@/models/main/Container";
import DataModel from "@/models/main/DataModel";
import Livestream, { LivestreamStatus } from "@/models/main/Livestream";
import StreamingEndpoint from "@/models/main/StreamingEndpoint";

export default class LiveDataModel extends DataModel implements Livestream {
    public static readonly MODEL_NAME = "Live"

    private _id?: number;
    private _title?: string
    private _streamLink?: string
    private _containerID?: number
    private _startTime?: Date
    private _endTime?: string
    private _streamActualStartTime?: Date
    private _atStreamingPlatformId?: string;
    private _quality?: string
    private _status?: LivestreamStatus
    private _container?: Container
    private _cameraId?: number
    private _camera?: Camera
    private _streamingEndpointId?: number
    private _streamingEndpoint?: StreamingEndpoint
    private _sport?: Sport

    //#region Fields Keys
    static get IDKey() {
        return "id";
    }

    static get titleKey() {
        return "title";
    }

    static get streamLinkKey() {
        return "streamLink";
    }

    static get containerIDKey() {
        return "containerId";
    }

    static get startTimeKey() {
        return "startTime";
    }

    static get endTimeKey() {
        return "endTime";
    }

    static get streamActualStartTimeKey() {
        return "streamActualStartTime";
    }

    static get statusKey() {
        return "status"
    }

    static get sportKey() {
        return "sport"
    }

    //#endregion

    // #region accessors/mutations 
    get id() {
        return this._id;
    }

    set id(id) {
        this._id = id;
    }

    get title() {
        return this._title
    }

    set title(title) {
        this._title = title
    }

    get streamLink() {
        return this._streamLink;
    }

    set streamLink(streamLink) {
        this._streamLink = streamLink;
    }

    get containerId() {
        return this._containerID;
    }

    set containerId(containerId) {
        this._containerID = containerId;
    }

    get startTime() {
        return this._startTime;
    }

    set startTime(startTime) {
        this._startTime = startTime;
    }

    get endTime() {
        return this._endTime;
    }

    set endTime(endTime) {
        this._endTime = endTime;
    }

    get streamActualStartTime() {
        return this._streamActualStartTime;
    }

    set streamActualStartTime(streamActualStartTime) {
        this._streamActualStartTime = streamActualStartTime;
    }
    get status()  {
        return this._status
    } 

    set status(status) {
        this._status = status
    }

    get sport() {
        return this._sport
    }

    set sport(sport) {
        this._sport = sport
    }

    get atStreamingPlatformId()  {
        console.error("Do not use this! It will not be populated!")
        return this._atStreamingPlatformId
    }
    get quality()  {
        console.error("Do not use this! It will not be populated!")
        return this._quality
    } 

    get container()  {
        console.error("Do not use this! It will not be populated!")
        return this._container
    } 
    get cameraId()  {
        console.error("Do not use this! It will not be populated!")
        return this._cameraId
    }    
    get camera()  {
        console.error("Do not use this! It will not be populated!")
        return this._camera
    }  
    get streamingEndpointId()  {
        console.error("Do not use this! It will not be populated!")
        return this._streamingEndpointId
    }  
    get streamingEndpoint()  {
        console.error("Do not use this! It will not be populated!")
        return this._streamingEndpoint
    }  

    //#endregion

    constructor(modelID?: string, modelData?: any) {
        super();
        this.parseMinifiedData(modelData);
        if(this.id !== undefined) {
            const modelId = DataModel.generateModelID(this, {"id": this.id});
            if(modelId !== false) {
                this.modelID = modelId
            }
        }
    }


    // #region FieldMappings Getters/Setters

    dataModelName() {
        return LiveDataModel.MODEL_NAME
    }

    getKeyByMinifiedKey(minKey: string) {
        switch (minKey) {
            case "1":
                return LiveDataModel.IDKey;
            case "2":
                return LiveDataModel.titleKey;
            case "3":
                return LiveDataModel.streamLinkKey;
            case "4":
                return LiveDataModel.startTimeKey;
            case "5":
                return LiveDataModel.endTimeKey;
            case "6":
                return LiveDataModel.containerIDKey;
            case "7":
                return LiveDataModel.streamActualStartTimeKey;
            case "8":
                return LiveDataModel.statusKey;
            case "9":
                return LiveDataModel.sportKey;
            default:
                return ""
        }
    }

    getProperty(propertyKey: string) {
        switch (propertyKey) {
            case LiveDataModel.IDKey:
                return this.id;
            case LiveDataModel.titleKey:
                return this._title;
            case LiveDataModel.streamLinkKey:
                return this.streamLink;
            case LiveDataModel.containerIDKey:
                return this.containerId;
            case LiveDataModel.startTimeKey:
                return this.startTime;
            case LiveDataModel.endTimeKey:
                return this.endTime;
            case LiveDataModel.streamActualStartTimeKey:
                return this.streamActualStartTime;
            case LiveDataModel.statusKey:
                return this.status;
            case LiveDataModel.sportKey:
                return this.sport;
        }
    }

    setProperty(propertyKey: string, value: any) {
        switch (propertyKey) {
            case LiveDataModel.IDKey:
                this.id = value;
                break;
            case LiveDataModel.titleKey:
                this.title = value
                break
            case LiveDataModel.streamLinkKey:
                this.streamLink = value;
                break;
            case LiveDataModel.containerIDKey:
                this.containerId = value;
                break;
            case LiveDataModel.startTimeKey:
                this.startTime = value;
                break;
            case LiveDataModel.endTimeKey:
                this.endTime = value;
                break;
            case LiveDataModel.streamActualStartTimeKey:
                this.streamActualStartTime = value;
                break;
            case LiveDataModel.statusKey:
                this.status = value;
                break;
            case LiveDataModel.sportKey:
                this.sport = value
        }
    }

    //#endregion
}