import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "watch-livestreams-list"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WatchLivestreamItem = _resolveComponent("WatchLivestreamItem")!
  const _component_LoadingIcon = _resolveComponent("LoadingIcon")!
  const _component_TheUserDashboard = _resolveComponent("TheUserDashboard")!

  return (_openBlock(), _createBlock(_component_TheUserDashboard, {
    onScroll: _ctx.onMainWrapperScroll,
    title: "My Livestreams"
  }, {
    default: _withCtx(() => [
      (_ctx.getFavouriteLivestreams())
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFavouriteLivestreams(), (favouriteLivestream) => {
              return (_openBlock(), _createBlock(_component_WatchLivestreamItem, {
                key: favouriteLivestream.id,
                livestream: favouriteLivestream
              }, null, 8, ["livestream"]))
            }), 128)),
            _withDirectives(_createVNode(_component_LoadingIcon, null, null, 512), [
              [_vShow, _ctx.showLoadingIcon]
            ])
          ]))
        : (_openBlock(), _createElementBlock("h1", _hoisted_2, " You do not have a favourite livestream yet "))
    ]),
    _: 1
  }, 8, ["onScroll"]))
}