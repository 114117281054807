import GenericDictionary from '@/components/util/GenericDictionary';
import { ActionContext, Module } from 'vuex';
import { IRootState } from '@/store';
import authModule from '@/main';
import { Event } from '@/models/main/Event';
export interface ContainerEventStore {
    events: GenericDictionary<Event>,
};
const state: ContainerEventStore = {
    events: {},
};
const getters = {
    getEvent: (state: ContainerEventStore) => (eventId: number) => {
        return state.events[eventId];
    },
    getEvents: (state: ContainerEventStore) => (containerId: number) => {
        let events: Array<Event> = [];
        if(containerId) {
            for (const eventId in state.events) {
                if (Object.prototype.hasOwnProperty.call(state.events, eventId)) {
                    const event = state.events[eventId];
                    if(event.containerId === containerId) {
                        events.push(event);
                    }
                }
            }
    
            events = events.sort((a, b) => {
                return (a.startTimestamp?.getTime() ?? 0) - (b.startTimestamp?.getTime() ?? 0);
            })
        }

        return events;
    },
    getLastEvent: (state: ContainerEventStore) => (containerId: number) => {
        let lastEventId = 0;
        for (const i in state.events) {
            const event = state.events[i];
            if(event.id !== undefined && event.containerId == containerId && event.id > lastEventId) {
                lastEventId = event.id
            }
        }

        if(lastEventId > 0) {
            return state.events[lastEventId]
        } else {
            console.error('Could not find the event to delete');
        }
    }
};
const mutations = {
    addEvent(state: ContainerEventStore, event: Event) {
        if (event.id !== undefined) {
            if(event.startTimestamp) {
                event.startTimestamp = new Date(event.startTimestamp)
            }
            if(event.endTimestamp) {
                event.endTimestamp = new Date(event.endTimestamp)
            }
            state.events[event.id] = event;
        }
    },
    editEvent(state: ContainerEventStore, event: Event) {
        if (event.id !== undefined && state.events[event.id]) {
            delete state.events[event.id]
            state.events[event.id] = event
        }
    },
    removeEvent(state: ContainerEventStore, deleteEventId: number) {
        if (Object.prototype.hasOwnProperty.call(state.events, deleteEventId)) {
            delete state.events[deleteEventId];
        }
    },
};
const actions = {
    removeEvent({ commit }: ActionContext<ContainerEventStore, IRootState>, eventId: number) {
        return authModule.delete('/api/event/' + eventId)
            .then((response) => {
                if(response.data) {
                    commit('removeEvent', response.data.id);
                }
                return true;
            })
            .catch((error) => {
                console.error(error);
                return false
            });
    },
    removeEventAsGuest({ commit }: ActionContext<ContainerEventStore, IRootState>, { eventId, accessKey }: {eventId: number, accessKey: string }) {
        return authModule.post('/api/guest/event/' + eventId + '/delete', { 'containerOperateAccessKey' : accessKey })
            .then((response) => {
                if(response.data) {
                    commit('removeEvent', response.data.id);
                }

                return true
            })
            .catch((error) => {
                console.error(error);
                return false
            });
    },
    storeEvent({ commit }: ActionContext<ContainerEventStore, IRootState>, { event, accessKey }: { event: Event, accessKey?: string | undefined }) {
        return authModule
            .post('/api/event/', { ...event, 'containerOperateAccessKey': accessKey })
            .then((response) => {
                const createdEvent: Event = response.data as unknown as Event;
                if(createdEvent) {
                    commit('addEvent', createdEvent);
                    if(event.id !== undefined) {
                        commit('removeEvent', event.id);// remove the temp one
                    }
                    return true;
                }

                return false;
            })
            .catch((error) => {
                console.error(error);
                return false
            });
    },
    storeEventAsGuest({ commit }: ActionContext<ContainerEventStore, IRootState>, { event, accessKey }: { event: Event, accessKey?: string | undefined }) {
        return authModule
            .post('/api/guest/event/', { ...event, 'containerOperateAccessKey': accessKey })
            .then((response) => {
                const createdEvent: Event = response.data as unknown as Event;
                if(createdEvent) {
                    commit('addEvent', createdEvent);
                    if(event.id !== undefined) { // is negative or zero, thus its temp event
                        commit('removeEvent', event.id);// remove the temp one
                    }
                    return true;
                }

                return false;
            })
            .catch((error) => {
                console.error(error);
                return false
            });
    },
    patchEvent({ commit }: ActionContext<ContainerEventStore, IRootState>, { event }: { event: Event}) {
        console.log(event)
        return authModule
            .patch('/api/event/', event)
            .then((response) => {
                const patchedEvent: Event = response.data as unknown as Event;
                return patchedEvent;
            })
            .catch((error) => {
                console.error(error);
                return false
            });
    },
    patchEventAsGuest({ commit }: ActionContext<ContainerEventStore, IRootState>, { event, accessKey }: { event: Event, accessKey?: string | undefined }) {
        return authModule
            .patch('/api/event/', { ...event, 'containerOperateAccessKey': accessKey })
            .then((response) => {
                const patchedEvent: Event = response.data as unknown as Event;
                return patchedEvent;
            })
            .catch((error) => {
                console.error(error);
                return false
            });
    },
};
const containerEventStore: Module<ContainerEventStore, IRootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
export default containerEventStore;