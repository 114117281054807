import authModule from '@/main';
import Channel from '@/models/main/Channel';
import StreamingEndpoint, { SaveStreamingEndpoint } from '@/models/main/StreamingEndpoint';
import YTLiveBroadcast from '@/models/main/YoutubeLiveBroadcast';
import { IRootState } from '@/store';
import { ActionContext, Module } from 'vuex'

const state: Channel = {
    id: null,
    name: null,
    channelPlatformUId: null,
    streamingEndpoints: []
}

const getters = {
    getId(state: Channel) {
        return state.id
    },

    getName(state: Channel) {
        return state.name
    },

    getStreamingEndpoints(state: Channel) {
        return state.streamingEndpoints
    }
}

const mutations = { 
    setId(state: Channel, id: number) {
        state.id = id
    },

    setName(state: Channel, name: string) {
        state.name = name
    },

    setStreamingEndpoints(state: Channel, streamingEndpoints: Array<StreamingEndpoint>) {
        state.streamingEndpoints = streamingEndpoints
    },

    addStreamingEndpoint(state: Channel, streamingEndpoint: StreamingEndpoint) {
        state.streamingEndpoints.push(streamingEndpoint)
    },
}

const actions = {
    saveStreamingEndpoint({ commit }: ActionContext<Channel, IRootState>, streamingEndpoint: SaveStreamingEndpoint ) {
        return authModule.put(`/api/channel/${streamingEndpoint.channelId}/endpoint`, streamingEndpoint)
        .then((response) => {
            const streamingEndpoint: StreamingEndpoint = response.data as unknown as StreamingEndpoint
            commit('addStreamingEndpoint', streamingEndpoint)            
        })
    },
    getYoutubeBroadcasts({ commit, getters }: ActionContext<Channel, IRootState>, channelId: number) {
        return authModule.get(`/api/channel/${channelId}/youtube/broadcast`)
        .then((response) => {
            const youtubeBroadcasts: YTLiveBroadcast[] = response.data as unknown as YTLiveBroadcast[]
            return youtubeBroadcasts
        })
    },
    
}
const channelStore: Module<Channel, IRootState> = 
{
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}

export default channelStore;