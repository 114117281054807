<template>
    <TheOrganizerDashboard>
        <div class="seasons-wrapper">
            <SeasonCardItem v-for="season in listSeasons" @click="editOrganizationSeason(season)"
                :season="season" />
            <div class="season-card-item">
                <button role="button" @click="createOrganizationSeason" title="Add Season"
                    class="season-card-item--add">
                    <img src="/plus-add-sign-green.svg" class="add-season-img" alt="Add Season" />
                    Season
                </button>
            </div>
        </div>
        <Modal ref="updateSeasonPopup">
            <template v-slot:header>
                <h1>{{ modalTitle }} Season</h1>
            </template>
            <template v-slot:body>
                <div class="season-update-body">
                    <div class="spz-text-input-group">
                        <label for="season_name">Name</label>
                        <input id="season_name" type="text" placeholder="Name" v-model="updatedSeason.name" />
                    </div>
                    <div class="spz-text-input-group narrow">
                        <label for="season_starts-at">Starts at</label>
                        <VueDatePicker id="season_starts-at" type="text" placeholder="Starts at"
                            v-model="updatedSeason.startTime" time-picker-inline />
                    </div>
                    <div class="spz-text-input-group narrow">
                        <label for="season_ends-at">Ends at</label>
                        <VueDatePicker id="season_ends-at" type="text" placeholder="Ends at"
                            v-model="updatedSeason.endTime" time-picker-inline />
                    </div>
                    <div class="spz-text-input-group">
                        <label for="season_description">Description</label>
                        <input id="season_description" type="text" placeholder="Description"
                            v-model="updatedSeason.description" />
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div>
                    <button @click="updateSeasonPopup.closeModal(), submitSeasonUpdate()" class="btn add-btn">{{
                modalTitle === 'Create' ? 'Create' : 'Save' }}</button>
                    <button @click="updateSeasonPopup.closeModal()" class="btn btn-inverse">Cancel</button>
                </div>
            </template>
        </Modal>
    </TheOrganizerDashboard>
</template>
<script setup lang="ts">
import { Ref, ref, computed, onMounted, ComputedRef } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useToast } from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import VueDatePicker from '@vuepic/vue-datepicker'
import Modal from '@/components/main/Modal.vue'
import SeasonCardItem from '@/components/main/SeasonCardItem.vue'
import TheOrganizerDashboard from '@/components/main/TheOrganizerDashboard.vue'
import { getUserLastVisitedOrganizationId, formatDate } from '@/composables/common/Util'
import { Organization } from '@/models/main/Organization'
import Season from '@/models/main/Season'
import { key } from '@/store'
const route = useRoute()
const store = useStore(key)
const $toast = useToast()
const updateSeasonPopup: Ref<any> = ref(null)
const modalTitle: Ref<string> = ref('')
const updatedSeason: Ref<any> = ref({})
const routeOrgId: string | undefined = route.params.organizationId ? route.params.organizationId as string : undefined
const lastVisitedOrgId = getUserLastVisitedOrganizationId()
const organizationId: Ref<number | undefined> = ref(routeOrgId ? parseInt(routeOrgId) : lastVisitedOrgId)
const organization: Ref<Organization | undefined> = ref()
const listSeasons: ComputedRef<Array<Season>> = computed(() => {
    return store.getters['organizationSeasonStore/getOrganizationSeasons'](organizationId.value)
})
const editOrganizationSeason = (season: Season) => {
    updateSeasonPopup.value.openModal()
    modalTitle.value = 'Edit'
    updatedSeason.value.id = season.id
    updatedSeason.value.name = season.name
    updatedSeason.value.startTime = season.startTime
    updatedSeason.value.endTime = season.endTime
    updatedSeason.value.organizationId = organizationId.value
    updatedSeason.value.organization = organization.value
    updatedSeason.value.description = season.description
}
const createOrganizationSeason = () => {
    updateSeasonPopup.value.openModal()
    modalTitle.value = 'Create'
    updatedSeason.value.id = 0
    updatedSeason.value.name = ''
    updatedSeason.value.startTime = undefined
    updatedSeason.value.endTime = undefined
    updatedSeason.value.organizationId = organizationId.value
    updatedSeason.value.organization = organization.value
    updatedSeason.value.description = ''
}
const submitSeasonUpdate = async () => {
    if (modalTitle.value === 'Create') {
        const createdSeason: Season = await store.dispatch('organizationSeasonStore/createOrganizationSeason', updatedSeason.value)
        updatedSeason.value.id = createdSeason.id
        $toast.success('Created')
    } else {
        await store.dispatch('organizationSeasonStore/editOrganizationSeason', updatedSeason.value)
        $toast.success('Edited')
    }
}
const loadOrganization = async () => {
    organization.value = await store.dispatch('organizationStore/getOrganization', organizationId.value)
}
const loadSeasons = async () => {
    if (organizationId.value) {
        await store.dispatch('organizationSeasonStore/fetchOrganizationSeasons', organizationId.value)
    }
}
onMounted(async () => {
    Promise.all([
        loadOrganization(),
        loadSeasons()
    ])
})
</script>
<style>
.seasons-wrapper {
    display: flex;
    gap: 2em;
    flex-wrap: wrap;
    width: 100%;
    --season-card-item-width: 18em;
    justify-content: center;
}

.season-card-item--add {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    grid-row: 1 / -1;
    font-size: 1.5rem;
    cursor: pointer;
}

.add-season-img {
    padding-right: 0.5em;
}

.season-update-body {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
</style>